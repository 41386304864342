import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {FormBuilderQuestion, FormBuilderQuestionDTO} from './form-builder-question.model';
import {FormBuilderDTO, FormBuilderModel} from '../../../store/form-builder.model';

export interface FormBuilderState {
  // active: FormBuilderQuestionDTO;
  // list: FormBuilderQuestion[];
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'dynamic-form'})
export class FormBuilderQuestionStore extends EntityStore<FormBuilderState> {

  constructor() {
    super();
  }

}

