<div class="wrapper esx-page">

  <div class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Template Forms
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto esx-content">
    <div class="h-100 mt-2">

      <app-alert
        class="w-100"
        [id]="'templateFormList'"
      ></app-alert>

      <app-spinner [loadingBool]="loading">

        <div class="card-body">
          <ng-container *ngIf="fromForm !== 'link'">
            <div *authenticatedWithRole="['ROLE_ADMIN']" class="d-flex justify-content-end">
              <button class="btn btn-primary mr-2" routerLink="/form-builder/template/details/new">Create</button>
            </div>
          </ng-container>

          <div class="row">
            <div class="col-12 tables">
              <app-mbl-table
                  #table
                  [columns]="columns"
                  [rows]="rows"
              ></app-mbl-table>
            </div>
          </div>
        </div>
      </app-spinner>
    </div>
  </div>
</div>


<ng-template #actionTmpl let-row="row" let-value="value">
  <div class="dropdown btn-group text-center">
    <a ngbDropdown class="nav-item dropdown">
      <div class="nav-link dropdown-toggle user-toggle" ngbDropdownToggle>
        <button class="table-action-btn view">
          <i class="fas fa-ellipsis-h"></i>
        </button>
      </div>

      <div ngbDropdownMenu class="dropdown-menu bg-dark text-light">

        <a class="dropdown-item cursor-pointer text-light" routerLink="/form-builder/render/{{row.id}}">
          <p>Preview</p>
        </a>

        <a
          *ngIf="fromForm === 'link'"
          class="dropdown-item cursor-pointer text-light"
          (click)="convertToForm(row.id)"
        >
          <p>Select</p>
        </a>

        <ng-container *ngIf="fromForm !== 'link'">
          <ng-container *authenticatedWithRole="['ROLE_ADMIN']">
            <a class="dropdown-item cursor-pointer text-light" routerLink="/form-builder/template/details/{{row.id}}">
              <p>Edit</p>
            </a>

            <a class="dropdown-item cursor-pointer text-light" (click)="cloneAsTemplate(row.id)">
              <p>Clone</p>
            </a>
          </ng-container>

          <hr class="dropdown-divider w-75 mx-auto">

          <a class="dropdown-item cursor-pointer text-light" (click)="delete(row)">
            <p>Delete</p>
          </a>
        </ng-container>

      </div>
    </a>
  </div>
</ng-template>
