import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tierName'
})
export class TierNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }

    switch (value.trim()) {
      case 'zeroTo500K': {
        return 'A';
      }
      case '500kToOneMil': {
        return 'B';
      }
      case 'oneMilToTwoMil': {
        return 'C';
      }
      case 'twoMilToFiveMil': {
        return 'D';
      }
      case 'fiveMilToTenMil': {
        return 'E';
      }
      case 'aboveTenMillion': {
        return 'F';
      }
      default: {
        return value;
      }
    }
  }

}
