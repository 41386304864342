import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const typeOfClinicalServicesAnswers: Array<ChoiceInputItem> = [
  {
    label: 'STI screening',
    value: 'stiScreening'
  },
  {
    label: 'TB screening',
    value: 'tbScreening'
  },
  {
    label: `post rape care`,
    value: 'postRapeCare'
  },
  {
    label: `inpatient care`,
    value: 'inpatientCare'
  },
  {
    label: 'primary health screening',
    value: 'phScreening'
  },
  {
    label: 'other',
    value: 'other'
  }
];

export const peopleTargetedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: `men who have sex with men`,
    value: 'msm'
  },
  {
    label: `transgender persons`,
    value: 'tp'
  },
  {
    label: 'injecting drug users',
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: `clients of sex workers`,
    value: 'csw'
  },
  {
    label: `military`,
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: `people living with HIV`,
    value: 'peopleWithHiv'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
  {
    label: 'adults 25+ / general population',
    value: 'adultPopulation'
  },
  {
    label: 'parents',
    value: 'parents'
  },
  {
    label: `caregivers of PLHIV`,
    value: 'caregivers'
  },
  {
    label: `HIV positive children`,
    value: 'hivChildren'
  }
];

export const locationOfServiceAnswers: Array<ChoiceInputItem> = [
  {
    label: 'home based',
    value: 'home'
  },
  {
    label: 'mobile sites',
    value: 'mobile'
  },
  {
    label: `at the organization's facilities`,
    value: 'facilities'
  },
  {
    label: `clinic based`,
    value: 'clinic'
  }
];
