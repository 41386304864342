<div class="dropdown btn-group">
  <a ngbDropdown placement="bottom-right" class="nav-item dropdown">
    <div
      ngbDropdownToggle
      class="nav-link dropdown-toggle user-toggle btn text-light"
      [class]="isImpersonating ? 'btn-secondary btn-outline-danger' : 'btn-primary btn-outline-light'"
    >
      <span *ngIf="isImpersonating">Impersonating &nbsp;</span>
      <br>
      <i class="far fa-user mr-2"></i>
      <p>{{user.username}}</p>
    </div>

    <div ngbDropdownMenu class="dropdown-menu bg-dark text-light text-center">

      <ng-container *ngIf="isImpersonating">
        <a class="dropdown-item cursor-pointer text-light" (click)="unImpersonate()">
          <p>Un-Impersonate</p>
        </a>
      </ng-container>

      <a class="dropdown-item cursor-pointer text-light" routerLink="/authentication/resetPassword">
        <p>Reset Password</p>
      </a>

      <hr class="dropdown-divider w-75 mx-auto">

      <a class="dropdown-item cursor-pointer text-light" (click)="logout()">
        <p>Logout</p>
      </a>
    </div>
  </a>
</div>
