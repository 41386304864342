<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="marketingAndPromotionForm">
    <app-input-select-single
      *ngIf="marketingStrategyAnswers?.length"
      [label]="'Which statement best describes the organisation\'s marketing strategy?'"
      [items]="marketingStrategyAnswers"
      [id]="'marketingStrategy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="marketingStrategy"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="communicationFrequencyAnswers?.length"
      [label]="'How often does the organisation communicate with donors and/or other stakeholders?'"
      [items]="communicationFrequencyAnswers"
      [id]="'communicationFrequency'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="communicationFrequency"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="storyFrequencyAnswers?.length"
      [label]="'On average, how often does the organisation have a story in a local newspaper and/or do an interview on local radio?'"
      [items]="storyFrequencyAnswers"
      [id]="'storyFrequency'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="storyFrequency"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="marketingStaffAnswers?.length"
      [label]="'Does the organisation have a staff member who is dedicated to marketing and fundraising?'"
      [items]="marketingStaffAnswers"
      [id]="'marketingStaff'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="marketingStaff"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="logoAnswers?.length"
      [label]="'Does the organisation have an established and recognized logo?'"
      [items]="logoAnswers"
      [id]="'logo'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="logo"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="brandingPolicyAnswers?.length"
      [label]="'Which statement best describes the organisation\'s branding policy?'"
      [items]="brandingPolicyAnswers"
      [id]="'brandingPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="brandingPolicy"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="brochureAnswers?.length"
      [label]="'Which statement best describes the organisation\'s promotional brochure or flyer (hard copy and/or electronic copy)?'"
      [items]="brochureAnswers"
      [id]="'brochure'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="brochure"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="websiteAnswers?.length"
      [label]="'Which statement best describes the organisation\'s website?'"
      [items]="websiteAnswers"
      [id]="'website'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="website"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="socialMediaAnswers?.length"
      [label]="'Which statement best describes the organisation’s use of social media, such as FaceBook, Twitter and Instagram?'"
      [items]="socialMediaAnswers"
      [id]="'socialMedia'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="socialMedia"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="newsletterAnswers?.length"
      [label]="'Which statement best describes the organisation\'s newsletter?'"
      [items]="newsletterAnswers"
      [id]="'newsletter'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="newsletter"
    ></app-input-select-single>
  </form>
</app-spinner>
