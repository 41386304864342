import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const typeOfCampsAnswers: Array<ChoiceInputItem> = [
  {
    label: 'disclosure camp',
    value: 'disclosureCamp'
  },
  {
    label: 'evidence based life skills camp',
    value: 'evidenceCamp'
  },
  {
    label: `leadership camp`,
    value: 'leadershipCamp'
  },
  {
    label: `other`,
    value: 'other'
  }
];

export const peopleTargetedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: `men who have sex with men`,
    value: 'msm'
  },
  {
    label: `transgender persons`,
    value: 'tp'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: `clients of sex workers`,
    value: 'csw'
  },
  {
    label: `military`,
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: `people living with HIV`,
    value: 'PeopleWithHiv'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
  {
    label: 'parents',
    value: 'parents'
  },
  {
    label: `caregivers of PLHIV`,
    value: 'caregivers'
  },
  {
    label: `HIV positive children`,
    value: 'hivChildren'
  }
];

export const topicsDiscussedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'disclosure of HIV status',
    value: 'disclosureStatus'
  },
  {
    label: 'adherence to HIV treatment',
    value: 'adherenceTreatment'
  },
  {
    label: `stigma and discrimination`,
    value: 'stigmaDiscrimination'
  },
  {
    label: 'hygiene',
    value: 'hygiene'
  },
  {
    label: 'sexual reproductive health',
    value: 'sexualRepHealth'
  },
  {
    label: `nutrition`,
    value: 'nutrion'
  },
  {
    label: 'gender norms',
    value: 'genderNorms'
  },
  {
    label: 'mental health',
    value: 'mentalHealth'
  },
  {
    label: `substance abuse`,
    value: 'substanceAbuse'
  },
  {
    label: 'leadership',
    value: 'leadership'
  },
  {
    label: `education and/or job related skill development`,
    value: 'educationJobSkillDevelopment'
  },
  {
    label: `other`,
    value: 'other'
  }
];
