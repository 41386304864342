import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum FinancialManagementEndpointType {
  BUDGET_CREATION = 'BUDGET_CREATION',
  FINANCIAL_POLICY = 'FINANCIAL_POLICY',
  FINANCIAL_POLICY_ELEMENT = 'FINANCIAL_POLICY_ELEMENT',
  ASSET_INVENTORY = 'ASSET_INVENTORY',
  PETTY_CASH = 'PETTY_CASH',
  TIME_SHEET = 'TIME_SHEET',
  ACCOUNTING_SYSTEM = 'ACCOUNTING_SYSTEM',
  FINANCIAL_TRACKING = 'FINANCIAL_TRACKING',
  FINANCIAL_CONTROL = 'FINANCIAL_CONTROL',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  DURATION_OF_FINANCIAL_RECORDS = 'DURATION_OF_FINANCIAL_RECORDS',
  ANNUAL_AUDIT = 'ANNUAL_AUDIT',
  ANNUAL_FINANCIAL_SYSTEM = 'ANNUAL_FINANCIAL_SYSTEM',
  FINANCIAL_MANAGEMENT_TEAM = 'FINANCIAL_MANAGEMENT_TEAM',
  HEAD_OF_FINANCE_QUALIFICATION = 'HEAD_OF_FINANCE_QUALIFICATION'
}

export interface CSFinancialManagement extends BaseEntity {
  howIsBudgetCreated: string;
  financialPolicy: string;
  financialPolicyElements: string[];
  financialPolicyElementsOther: string; // if financialPolicyElements === 'OTHER'
  assetInventory: string;
  pettyCashAmount: string;
  timeSheet: string;
  financialAccountingSystem: string;
  financialAccountingSystemOther: string; // if financialAccountingSystem === 'OTHER'
  financialTracking: string;
  financialControls: string[];
  financialControlsOther: string; // if financialControls === 'OTHER'
  isVatRegistered: boolean;
  vatRegistrationNumber: string; // if isVatRegistered === true
  numberOfBankAccounts: number;
  paymentMethod: string;
  durationOfFinancialRecords: string;
  hasAnnualAudit: string;

  hasBeenAudited: boolean;
  auditDate: any; // if hasBeenAudited === true

  hasQualifiedAudit: boolean;
  qualifiedAuditDetails: string; // if hasQualifiedAudit === true
  hasAnnualFinancialSystem: string;
  financialManagementTeam: string;
  headOfFinanceQualification: string;
}

export function createCSFinancialManagement(params: Partial<CSFinancialManagement>) {
  return Object.assign({}, createBaseEntity(params), {
    howIsBudgetCreated: params && params.howIsBudgetCreated,
    financialPolicy: params && params.financialPolicy,
    financialPolicyElements: params && params.financialPolicyElements,
    financialPolicyElementsOther: params && params.financialPolicyElementsOther,
    assetInventory: params && params.assetInventory,
    pettyCashAmount: params && params.pettyCashAmount,
    timeSheet: params && params.timeSheet,
    financialAccountingSystem: params && params.financialAccountingSystem,
    financialAccountingSystemOther: params && params.financialAccountingSystemOther,
    financialTracking: params && params.financialTracking,
    financialControls: params && params.financialControls,
    financialControlsOther: params && params.financialControlsOther,
    isVatRegistered: params && params.isVatRegistered,
    vatRegistrationNumber: params && params.vatRegistrationNumber,
    numberOfBankAccounts: params && params.numberOfBankAccounts,
    paymentMethod: params && params.paymentMethod,
    durationOfFinancialRecords: params && params.durationOfFinancialRecords,
    hasAnnualAudit: params && params.hasAnnualAudit,
    hasBeenAudited: params && params.hasBeenAudited,
    auditDate: params && params.auditDate,
    hasQualifiedAudit: params && params.hasQualifiedAudit,
    qualifiedAuditDetails: params && params.qualifiedAuditDetails,
    hasAnnualFinancialSystem: params && params.hasAnnualFinancialSystem,
    financialManagementTeam: params && params.financialManagementTeam,
    headOfFinanceQualification: params && params.headOfFinanceQualification,
  }) as CSFinancialManagement;
}

export function createCSFinancialManagementForm(destroy$: Subject<boolean>,
                                                formBuilder: FormBuilder,
                                                params: Partial<CSFinancialManagement>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    howIsBudgetCreated: [params && params.howIsBudgetCreated, Validators.required],
    financialPolicy: [params && params.financialPolicy, Validators.required],
    financialPolicyElements: [params && params.financialPolicyElements, Validators.required],
    financialPolicyElementsOther: [params && params.financialPolicyElementsOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('financialPolicyElements').value && formGroup.get('financialPolicyElements').value.includes('OTHER');
      })
    ],
    assetInventory: [params && params.assetInventory, Validators.required],
    pettyCashAmount: [params && params.pettyCashAmount, Validators.required],
    timeSheet: [params && params.timeSheet, Validators.required],
    financialAccountingSystem: [params && params.financialAccountingSystem, Validators.required],
    financialAccountingSystemOther: [params && params.financialAccountingSystemOther,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('financialAccountingSystem').value === 'OTHER')
    ],
    financialTracking: [params && params.financialTracking, Validators.required],
    financialControls: [params && params.financialControls, Validators.required],
    financialControlsOther: [params && params.financialControlsOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('financialControls').value && formGroup.get('financialControls').value.includes('OTHER');
      })
    ],
    isVatRegistered: [params && params.isVatRegistered, Validators.required],
    vatRegistrationNumber: [params && params.vatRegistrationNumber,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('isVatRegistered').value === true)
    ],
    numberOfBankAccounts: [params && params.numberOfBankAccounts, Validators.required],
    paymentMethod: [params && params.paymentMethod, Validators.required],
    durationOfFinancialRecords: [params && params.durationOfFinancialRecords, Validators.required],
    hasAnnualAudit: [params && params.hasAnnualAudit, Validators.required],
    hasBeenAudited: [params && params.hasBeenAudited, Validators.required],
    auditDate: [params && params.auditDate,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasBeenAudited').value === true)
    ],
    hasQualifiedAudit: [params && params.hasQualifiedAudit, Validators.required],
    qualifiedAuditDetails: [params && params.qualifiedAuditDetails,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasQualifiedAudit').value === true)
    ],
    hasAnnualFinancialSystem: [params && params.hasAnnualFinancialSystem, Validators.required],
    financialManagementTeam: [params && params.financialManagementTeam, Validators.required],
    headOfFinanceQualification: [params && params.headOfFinanceQualification, Validators.required],
  }));

  formGroup.get('financialPolicyElements').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('financialPolicyElementsOther').updateValueAndValidity();
    });

  formGroup.get('financialAccountingSystem').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('financialAccountingSystemOther').updateValueAndValidity();
    });

  formGroup.get('financialControls').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('financialControlsOther').updateValueAndValidity();
    });

  formGroup.get('isVatRegistered').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('vatRegistrationNumber').updateValueAndValidity();
    });

  formGroup.get('hasBeenAudited').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('auditDate').updateValueAndValidity();
    });

  formGroup.get('hasQualifiedAudit').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('qualifiedAuditDetails').updateValueAndValidity();
    });

  return formGroup;
}
