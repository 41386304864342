export type FilterType = 'submitted' | 'shortlisted' | 'notShortlisted' | 'inReviewShortlisted' | 'inReviewNotShortlisted'
  | 'declined' | 'assessment' | 'recommended' | 'inReview' | 'workPlanSubmitted' | 'workPlanNotSubmitted'
  | 'workPlanApproved' | 'workPlanNotApproved' | 'total' | 'eligible' | 'ineligible' | 'application';

export const filterTypes = [
  {label: 'In Review', value: 'inReview'},
  {label: 'Assessment Submitted', value: 'assessment'},
  {label: 'Final Recommendation', value: 'recommended'},
  // {label: 'Work Plan', value: 'workPlan'},
  {label: 'Declined', value: 'declined'},
  {label: 'Shortlisted (Total)', value: 'shortlisted'},
  {label: 'Not Shortlisted (Total)', value: 'notShortlisted'},
  {label: 'Shortlisted', value: 'inReviewShortlisted'},
  {label: 'Not Shortlisted', value: 'inReviewNotShortlisted'},
  {label: 'Submitted', value: 'workPlanSubmitted'},
  {label: 'Not Submitted', value: 'workPlanNotSubmitted'},
  {label: 'Approved', value: 'workPlanApproved'},
  {label: 'Not Approved', value: 'workPlanNotApproved'},
];

export interface MultiChartData<T = any> {
  name: string;
  series: ChartData<T>[];
}

export interface ChartData<T = any> {
  name: string;
  value: T;
  extra?: object;
}
