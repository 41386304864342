<ng-template #modalContent>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{title || 'Confirm'}}</h4>
        <button type="button" class="close" (click)="closeModal()">&times;</button>
      </div>
      <div class="modal-body" align="center">
        <p>{{message || 'Are you sure you want to continue?'}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirm()">Yes</button>
        <button type="button" class="btn btn-danger" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</ng-template>
