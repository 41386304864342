import {Injectable} from '@angular/core';
import {CoordinatorStore} from './coordinator.store';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';
import {createUser, User} from '../../../shared/stores/user/user.model';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class CoordinatorService {

  private url = `${environment.apiUrl}/user/management`;
  private coordinatorUrl = `${environment.apiUrl}/user/management?type=COORDINATOR`;
  private coordinatorByWindowUrl = `${environment.apiUrl}/user/management/byGrantWindow?type=COORDINATOR`;
  private coordinatorAssignmentUrl = `${environment.apiUrl}/user/management/coordinator-assignment`;

  constructor(protected store: CoordinatorStore,
              private http: HttpClient) {
  }

  setActive(userId: number) {
    this.store.setActive(userId);
  }

  getAll(funderId: number): Observable<User[]> {
    const url = `${this.coordinatorUrl}&funderId=${funderId}`;
    return this.http.get<User[]>(url).pipe(
      tap(list => {
        this.store.set(list);
      })
    );
  }

  create(user: any, funderId: number) {
    const url = `${this.coordinatorUrl}&funderId=${funderId}`;
    return this.http.post(url, user).pipe(
      tap(() => {
        this.store.add(createUser({
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
        }));
      })
    );
  }

  update(user: any, funderId: number) {
    return this.http.put(this.url, user).pipe(
      tap(() => {
        this.getAll(funderId).subscribe();
      })
    );
  }

  addAssignedCoordinators(username: string, windowId: number, orgId: number) {
    const url = `${this.coordinatorAssignmentUrl}?username=${username}&grantWindowId=${windowId}&organisationId=${orgId}`;
    return this.http.put(url, undefined).pipe(
      tap(() => {
        return;
      })
    );
  }

  deleteAssignedCoordinators(windowId: number, orgId: number) {
    const url = `${this.coordinatorAssignmentUrl}?grantWindowId=${windowId}&organisationId=${orgId}`;
    return this.http.delete(url).pipe(
      tap(() => {
        return;
      })
    );
  }

  getAllByWindowId(windowId: number): Observable<User[]> {
    const url = `${this.coordinatorByWindowUrl}&grantWindowId=${windowId}`;
    return this.http.get<User[]>(url).pipe(
      tap(list => {
        return list;
      })
    );
  }
}
