<div class="esx-tabset-container">
  <div class="esx-tabset bg-light">
    <button
      class="btn btn-tab text-capitalize"
      [class.btn-tab-active]="currentTab === 'summary'"
      (click)="currentTab = 'summary'"
    >
      Organisation Profile
    </button>
    <button
      class="btn btn-tab text-capitalize"
      [class.btn-tab-active]="currentTab === 'capacitySpectrum'"
      (click)="currentTab = 'capacitySpectrum'"
    >
      Capacity Spectrum
    </button>
    <button
      class="btn btn-tab text-capitalize"
      [class.btn-tab-active]="currentTab === 'serviceMatrix'"
      (click)="currentTab = 'serviceMatrix'"
    >
      Service Matrix
    </button>
    <button
      class="btn btn-tab text-capitalize"
      [class.btn-tab-active]="currentTab === 'eligibility'"
      (click)="currentTab = 'eligibility'"
    >
      Eligibility
    </button>
    <button
      class="btn btn-tab text-capitalize"
      [class.btn-tab-active]="currentTab === 'application'"
      (click)="currentTab = 'application'"
    >
      Application
    </button>
  </div>
  <p class="mr-4 esx-tabset-item">
      <span
        *ngIf="!reviewStep || reviewStep === 'IN_REVIEW'"
        class="badge badge-pill badge-success">
        In Review
      </span>

    <span
      *ngIf="reviewStep === 'DECLINED'"
      class="badge badge-pill badge-danger">
        Declined
      </span>

    <span
      *ngIf="reviewStep === 'ASSESSMENT_SUBMITTED'"
      class="badge badge-pill badge-primary">
        Assessment Submitted
      </span>

    <span
      *ngIf="reviewStep === 'RECOMMENDED'"
      class="badge badge-pill badge-warning">
        Recommended
      </span>
  </p>
</div>

<div class="flex-center">
  <div class="review-card tabset-body w-100">
    <ng-container [ngSwitch]="currentTab">
      <ng-container *ngSwitchCase="'summary'">
        <app-org-profile-container
          [fromReview]="true"
          [isReadOnly]="true"
          [isEditingProfile]="isEditingSummary"
          [isSavingProfile]="isSavingSummary"
          (completedSaving)="isEditingSummary = false; isSavingSummary = false"
        ></app-org-profile-container>
      </ng-container>
      <ng-container *ngSwitchCase="'capacitySpectrum'">
        <app-capacity-spectrum
          [fromReview]="true"
          [isReadOnly]="true"
        ></app-capacity-spectrum>
      </ng-container>
      <ng-container *ngSwitchCase="'serviceMatrix'">
        <app-service-matrix
          [fromReview]="true"
          [isReadOnly]="true"
        ></app-service-matrix>
      </ng-container>
      <ng-container *ngSwitchCase="'eligibility'">
        <app-eligibility
          [isReadOnly]="true"
          [fromGrantReview]="true"
          [workflowId]="workflowId"
        ></app-eligibility>
      </ng-container>
      <ng-container *ngSwitchCase="'application'">
        <app-custom-application
          [fromGrantReview]="true"
          [isReadOnly]="true"
        ></app-custom-application>
      </ng-container>
    </ng-container>
  </div>
</div>
