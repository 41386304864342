import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../shared/models/base-entity.model';
import {GrantWorkflow} from '../../../store/grant-workflow.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createFormCapture, createFormCaptureForm, FormCapture} from '../components/form-capture/store/form-capture.model';
import {createFundingRequest, FundingRequest} from '../../application/funding-request/model/funding-request.model';
import {
  createFundingRequestFormForCustomApplication
} from '../../application/funding-request/model/create-form.function';
import {QuestionControlService} from '../../../../form-builder/store/question-control.service';
import {Subject} from 'rxjs';
import {SingleScoreDTO} from '../../../../organisation/capacity-spectrum/components/capacity-spectrum/store/capacity-spectrum.model';

export interface CustomApplication extends BaseEntity {
  grantWorkflow: GrantWorkflow;
  status: string;
  formCapture: FormCapture;
  fundingRequest: FundingRequest;
  submittedBy: string;
  submitVerification: boolean;
  applicationScore: SingleScoreDTO;
}

export function createCustomApplication(params: Partial<CustomApplication>) {
  return Object.assign({}, createBaseEntity(params), {
    grantWorkflow: params && params.grantWorkflow,
    status: params && params.status,
    formCapture: params && createFormCapture(params.formCapture),
    fundingRequest: params && createFundingRequest(params.fundingRequest),
    submittedBy: params && params.submittedBy,
    submitVerification: params && params.submitVerification,
    applicationScore: params && params.applicationScore,
  }) as CustomApplication;
}

export function createCustomApplicationForm(destroy$: Subject<boolean>,
                                            formBuilder: FormBuilder,
                                            qcs: QuestionControlService,
                                            params?: Partial<CustomApplication>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    grantWorkflow: [params && params.grantWorkflow || '', Validators.required],
    status: [params && params.status || '', Validators.required],
    submittedBy: [params && params.submittedBy || ''],
    submitVerification: [params && params.submitVerification || ''],
    formCapture: createFormCaptureForm(formBuilder, qcs, params && params.formCapture),
    fundingRequest: createFundingRequestFormForCustomApplication(destroy$, formBuilder, params && params.fundingRequest),
  }));
}
