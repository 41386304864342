<div>
  <form [formGroup]="form">
    <div *ngFor="let question of formTemplate.questions">
      <app-dynamic-form-question
        [question]="question"
        [theme]="'light'"
        [style]="'default'"
        [form]="form"
        [isReadOnly]="isReadOnly"
      ></app-dynamic-form-question>
    </div>
  </form>
</div>
