import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormBuilderDTO} from '../components/form-builder-list/form-builder/store/form-builder.model';

@Injectable({providedIn: 'root'})
export class QuestionControlService {
  constructor() { }

  toFormGroup(form: FormBuilderDTO) {
    const group: any = {};

    form.questions.forEach(question => {
      group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)
        : new FormControl(question.value || '');
    });
    return new FormGroup(group);
  }
}
