import {Funder} from '../../modules/funder/funder-profile/store/funder.model';

export function getFunderName(funder: Funder): string {
  if (funder) {
    switch (funder.investorType) {
      case 'INDIVIDUAL':
        return funder.individual.firstName + ' ' + funder.individual.lastName;
      case 'CORPORATE':
        return funder.corporate.companyName;
      case 'FOUNDATION':
        return funder.foundation.foundationName;
      case 'GOVERNMENT':
        return funder.government.departmentName;
    }
  }
  return 'SoCapX';
}
