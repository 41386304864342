<aside class="side-panel">
  <form [formGroup]="reviewForm">
    <div class="alert alert-warning side-panel-header">
      <h4 class="mr-5">Review Side Panel</h4>
      <button
        class="btn btn-sm btn-success btn-outline-light ml-5"
        (click)="createOrUpdate(true)"
      >
        Save
      </button>
    </div>
    <div class="side-panel-body">
      <div class="bg-light">

        <div class="review-details-item" (click)="dropdownStates.capacitySpectrum = !dropdownStates.capacitySpectrum">
          <div class="flex-between">
            <p class="font-weight-normal">Capacity Spectrum Verification</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div class="side-panel-item-details" *ngIf="dropdownStates.capacitySpectrum">
          <div class="p-4">
            <app-capacity-spectrum-verification
              [capacitySpectrumVerificationForm]="coordinatorReviewForm.controls.capacitySpectrumVerification | asFormGroup"
              [isReadOnly]="isReadOnly"
            ></app-capacity-spectrum-verification>
          </div>
        </div>

        <div class="review-details-item" (click)="dropdownStates.serviceMatrix = !dropdownStates.serviceMatrix">
          <div class="flex-between">
            <p class="font-weight-normal">Service Matrix Verification</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div class="side-panel-item-details" *ngIf="dropdownStates.serviceMatrix">
          <div class="p-4">
            <app-service-matrix-verification
              [serviceMatrixVerificationForm]="coordinatorReviewForm.controls.serviceMatrixVerification | asFormGroup"
              [isReadOnly]="isReadOnly"
            ></app-service-matrix-verification>
          </div>
        </div>

        <div class="review-details-item"
             (click)="dropdownStates.internalVerification = !dropdownStates.internalVerification">
          <div class="flex-between">
            <p class="font-weight-normal">Internal Verification</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div class="side-panel-item-details" *ngIf="dropdownStates.internalVerification">
          <div class="card-body">
            <app-internal-verification
              [internalVerificationForm]="coordinatorReviewForm.controls.internalVerification | asFormGroup"
              [isReadOnly]="isReadOnly"
            ></app-internal-verification>
          </div>
        </div>

        <div class="review-details-item"
             (click)="dropdownStates.telephoneInterview = !dropdownStates.telephoneInterview">
          <div class="flex-between">
            <p class="font-weight-normal">Telephone Interview</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div class="side-panel-item-details" *ngIf="dropdownStates.telephoneInterview">
          <div class="card-body">
            <app-telephone-interview
              [coordinators]="coordinators"
              [telephoneInterviewForm]="coordinatorReviewForm.controls.telephoneInterview | asFormGroup"
              [orgId]="reviewDetails.grantWorkflow.organisation.id"
              [windowCode]="reviewDetails.grantWorkflow.grantWindow.code"
              [isReadOnly]="isReadOnly"
            ></app-telephone-interview>
          </div>
        </div>

        <div class="review-details-item" (click)="dropdownStates.siteVisit = !dropdownStates.siteVisit">
          <div class="flex-between">
            <p class="font-weight-normal">Site Visit</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div class="side-panel-item-details" *ngIf="dropdownStates.siteVisit">
          <div class="p-4">
            <app-site-visit
              [coordinators]="coordinators"
              [siteVisitForm]="coordinatorReviewForm.controls.siteVisit | asFormGroup"
              [orgId]="reviewDetails.grantWorkflow.organisation.id"
              [windowCode]="reviewDetails.grantWorkflow.grantWindow.code"
              [isReadOnly]="isReadOnly"
            ></app-site-visit>
          </div>
        </div>


        <div class="review-details-item"
             (click)="dropdownStates.additionalDocuments = !dropdownStates.additionalDocuments">
          <div class="flex-between">
            <p class="font-weight-normal">Additional Documents</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div *ngIf="dropdownStates.additionalDocuments" class="side-panel-item-details">
          <div class="p-4">
            <app-additional-documents
              [additionalDocumentsForm]="coordinatorReviewForm.controls.additionalDocuments | asFormGroup"
              [orgId]="reviewDetails.grantWorkflow.organisation.id"
              [windowCode]="reviewDetails.grantWorkflow.grantWindow.code"
            ></app-additional-documents>
          </div>
        </div>

        <div class="review-details-item" (click)="dropdownStates.commentHistory = !dropdownStates.commentHistory">
          <div class="flex-between">
            <p class="font-weight-normal">Internal Comment History</p>
            <small>Click to expand</small>
          </div>
        </div>
        <div class="side-panel-item-details" *ngIf="dropdownStates.commentHistory">
          <div class="p-4">
            <app-internal-comment-history
              [internalCommentHistoryList]="reviewDetails.grantWorkflow.internalCommentHistoryList"
            ></app-internal-comment-history>
          </div>
        </div>

      </div>

      <div class="flex-end w-100 mt-3">
        <!-- GM -->
        <ng-container *authenticatedWithRole="['ROLE_GRANT_MANAGER', 'ROLE_FUNDER', 'ROLE_ADMIN']">
          <ng-container *ngIf="reviewDetails?.grantWorkflow?.reviewStep === 'ASSESSMENT_SUBMITTED'">
            <button class="btn btn-sm btn-primary btn-outline-light" (click)="recommend()">Recommend</button>
            <button class="btn btn-sm btn-danger btn-outline-light" (click)="reject()">Reject</button>
          </ng-container>
          <ng-container *ngIf="reviewDetails?.grantWorkflow?.reviewStep === 'DECLINED'">
            <button class="btn btn-sm btn-danger btn-outline-light" (click)="reject()">Reject</button>
          </ng-container>
          <ng-container *ngIf="reviewDetails?.grantWorkflow?.reviewStep === 'RECOMMENDED'">
            <button class="btn btn-sm btn-danger btn-outline-light" (click)="removeFromRecommended()">Remove from
              Recommended
            </button>
          </ng-container>
        </ng-container>

        <!-- GC -->
        <ng-container
          *authenticatedWithRole="['ROLE_GRANT_COORDINATOR', 'ROLE_GRANT_MANAGER', 'ROLE_FUNDER', 'ROLE_ADMIN']"
        >
          <ng-container *ngIf="isInReview()">
            <button class="btn btn-sm btn-primary btn-outline-light" (click)="submit()">Submit for Approval</button>
            <button class="btn btn-sm btn-danger btn-outline-light" (click)="decline()">Decline</button>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </form>
</aside>
