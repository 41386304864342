<div *ngIf="selectedObjective" class="card card-outline-primary mt-3">
  <div class="flex-between mx-3">
    <h4 class="card-title text-primary text-uppercase"><strong>Activities</strong></h4>
    <app-add-activity
      [isReadOnly]="isReadOnly"
      [coordinatorReadOnly]="coordinatorReadOnly"
      [applicantReadOnly]="applicantReadOnly"
      [objective]="selectedObjective"
    ></app-add-activity>
  </div>

  <table
    mat-table
    multiTemplateDataRows
    [dataSource]="dataSource"
    class="mat-elevation-z8"
  >

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Name</th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Description</th>
      <td mat-cell *matCellDef="let row"> {{row.description | truncate: 50}} </td>
    </ng-container>

    <!-- Time Frame Column -->
    <ng-container matColumnDef="timeframe">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Time Frame</th>
      <td mat-cell *matCellDef="let row"> {{row.timeframe}} </td>
    </ng-container>

    <!-- Responsible Person Column -->
    <ng-container matColumnDef="responsiblePerson">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Responsible Person</th>
      <td mat-cell *matCellDef="let row"> {{row.responsiblePerson}} </td>
    </ng-container>

    <!-- Cost Category Column -->
    <ng-container matColumnDef="costCategory">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Budget Category</th>
      <td mat-cell *matCellDef="let row"> {{row.costCategory | singleChoiceInputType: budgetCategoryOptions}} </td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Cost</th>
      <td mat-cell *matCellDef="let row"> {{row.cost | currency: 'R'}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Actions</th>
      <td mat-cell *matCellDef="let row">
        <div class="dropdown btn-group">
          <a ngbDropdown class="nav-item dropdown">
            <div class="nav-link dropdown-toggle user-toggle" ngbDropdownToggle>
              <button class="table-action-btn view">
                <i class="fas fa-ellipsis-h"></i>
              </button>
            </div>

            <div ngbDropdownMenu class="dropdown-menu bg-dark text-light">

              <app-edit-activity
                [activity]="row.item"
                [isReadOnly]="isReadOnly"
                [applicantReadOnly]="applicantReadOnly"
                [coordinatorReadOnly]="coordinatorReadOnly"
              ></app-edit-activity>

              <hr class="dropdown-divider w-75 mx-auto">

              <button
                class="btn btn-link dropdown-item cursor-pointer text-light"
                [class.appReadOnly]="isReadOnly || coordinatorReadOnly || applicantReadOnly"
                [disabled]="isReadOnly || coordinatorReadOnly || applicantReadOnly"
                (click)="deleteActivity(row.item)"
              >
                Delete
              </button>
            </div>
          </a>
        </div>
      </td>
    </ng-container>


    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="expandable-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <!--              <div class="expandable-element-diagram">-->
          <!--                <div class="expandable-element-position"> {{element.position}} </div>-->
          <!--                <div class="expandable-element-symbol"> {{element.symbol}} </div>-->
          <!--                <div class="expandable-element-name"> {{element.name}} </div>-->
          <!--                <div class="expandable-element-weight"> {{element.weight}} </div>-->
          <!--              </div>-->

          <div class="row expandable-element-description">
            <div class="col-2">
              <strong>Description: </strong>
            </div>
            <div class="col-10">
              {{element.description}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>

          <div class="row expandable-element-description">
            <div class="col-2">
              <strong>Monitoring & Evaluation: </strong>
            </div>
            <div class="col-10">
              {{element.monitoringEvaluation}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>

          <div class="row expandable-element-description">
            <div class="col-2">
              <strong>Payment Evidence: </strong>
            </div>
            <div class="col-10">
              {{element.paymentEvidence | multipleChoiceInputType: paymentEvidenceOptions}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>

          <div *ngIf="element.paymentEvidenceOther" class="row expandable-element-description">
            <div class="col-2">
              <strong>Payment Evidence Other: </strong>
            </div>
            <div class="col-10">
              {{element.paymentEvidenceOther}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>

          <div class="row expandable-element-description">
            <div class="col-2">
              <strong>Explanation Of Cost: </strong>
            </div>
            <div class="col-10">
              {{element.explanationOfCost}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="expandable-element-row"
        [class.expandable-expanded-row]="expandedElement === element"
        (click)="expandedElement = element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>
  </table>

</div>
