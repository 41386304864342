import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface ReviewDashboardState {
}

export function createInitialState(): ReviewDashboardState {
  return {
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'review',
})
export class ReviewDashboardStore extends Store<ReviewDashboardState> {

  constructor() {
    super(createInitialState());
  }
}
