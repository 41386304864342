<div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
  <div class="btn-group mr-2" role="group" aria-label="First group">
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="setPage(0)"
      [disabled]="offset === 0"
    >
      &laquo;
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="setPage(offset - 1)"
      [disabled]="offset === 0"
    >
      &lsaquo;
    </button>

    <!--<ng-container *ngFor="let number of arrayOfNumbers(); let i = index;">-->
      <!--<button type="button" class="btn btn-sm btn-secondary" (click)="setPage(i)">{{ i + 1 }}</button>-->
    <!--</ng-container>-->

    <button type="button" class="btn btn-sm btn-info text-light">{{ offset + 1 }} of {{ numberOfPages }}</button>

    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="setPage(offset + 1)"
      [disabled]="offset === numberOfPages - 1"
    >
      &rsaquo;
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="setPage(numberOfPages - 1)"
      [disabled]="offset === numberOfPages - 1"
    >
      &raquo;
    </button>
  </div>
</div>
