<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Programs, process or system development are defined as technical enhancements at an
    organizational level which will improve the organization’s ability to deliver quality
    services to PLHIV and/or other at-risk populations.
  </p>
  <form [formGroup]="systemDevelopmentForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="targetedPopulation"
        [label]="'The program, process or system will benefit which population?'"
        [id]="'sd-targetedPopulation'"
        [name]="'sd-targetedPopulation'"
        [isRequired]="true"
        [theme]="theme"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="targetedPopulation"
      ></app-input-checkbox>

      <app-input-checkbox
        [choices]="aspectBenefited"
        [label]="'The program, process or system will benefit which aspect of the organization?'"
        [id]="'sd-aspectBenefited'"
        [name]="'sd-aspectBenefited'"
        [isRequired]="true"
        [theme]="theme"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="aspectBenefited"
        (selected)="checkOther('other', $event, systemDevelopmentForm.controls.aspectBenefited, systemDevelopmentForm.controls.aspectBenefitedOther)"
      ></app-input-checkbox>

      <div *ngIf="systemDevelopmentForm.value.aspectBenefited?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'sd-aspectBenefitedOther'"
          [theme]="theme"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="aspectBenefitedOther"
        ></app-input-textarea>
      </div>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'sd-durationOfSystemDevelopment'"
            [isRequired]="true"
            [theme]="theme"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfSystemDevelopment"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'sd-durationOfSystemDevelopmentType'"
            [isRequired]="true"
            [name]="'sd-durationOfSystemDevelopmentType'"
            [theme]="theme"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfSystemDevelopmentType"
          ></app-input-radio>
        </div>
      </div>

      <div>
        <p>How much funding do you need to complete this activity?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="systemDevelopmentForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
