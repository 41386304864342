<div>
  <div class="w-100 card p-4">
    <button class="close-modal" type="button" (click)="closeModal()">
      <i class="far fa-times-circle"></i>
    </button>
    <form class="form" [formGroup]="profileForm" (ngSubmit)="createOrUpdateOption(profileForm)">

      <div class="card-body">
        <h4 class="category text-primary text-center">
          {{isCreating ? 'Add' : 'Edit'}} Option
        </h4>

        <hr class="dropdown-divider border-primary w-50 mx-auto mb-2">

        <app-alert
          class="w-100"
          [id]="'formBuilderChoice'"
        ></app-alert>

        <app-input-text
          [label]="'Label'"
          [id]="'label'"
          [style]="'default'"
          [theme]="'light'"
          [isRequired]="true"
          [isReadOnly]="!isEditable"
          formControlName="label"
        ></app-input-text>

        <app-input-text
          [label]="'Value'"
          [id]="'value'"
          [style]="'default'"
          [theme]="'light'"
          [isRequired]="true"
          [isReadOnly]="!isEditable"
          [isUnique]="true"
          [uniqueId]="profileForm.value?.id"
          [urlFragment]="'choice-input-item'"
          [uniqueAdditionalParam]="'&questionId=' + questionId"
          [uniqueError]="'The value already exists. Please try another.'"
          formControlName="value"
        ></app-input-text>

        <app-input-number
          [label]="'Score'"
          [id]="'score'"
          [style]="'default'"
          [theme]="'light'"
          [isReadOnly]="!isEditable"
          formControlName="score"
        ></app-input-number>

      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-danger btn-lg mr-2" type="button" (click)="closeModal()">Close</button>
        <button *ngIf="isEditable" class="btn btn-primary btn-lg" type="submit">Save</button>
      </div>
    </form>
  </div>
</div>
