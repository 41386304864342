import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDateNativeAdapter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticatedWithRoleDirective } from './directives/authenticated-with-role/authenticated-with-role.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FormatDatePipe } from './pipes/format-date/format-date.pipe';
import { AsFormGroupPipe } from './pipes/as-form-group/as-form-group.pipe';
import { UserNavigationComponent } from './components/user-navigation/user-navigation.component';
import { CategoriesPipe } from './pipes/profile-categories/categories.pipe';
import { ProvincePipe } from './pipes/location-codes/province/province.pipe';
import { SubDistrictPipe } from './pipes/location-codes/subdistrict/sub-district.pipe';
import { DistrictPipe } from './pipes/location-codes/district/district.pipe';
import { OrganisationTypePipe } from './pipes/organisation-type/organisation-type.pipe';
import { TierNamePipe } from './pipes/tier-name/tier-name.pipe';
import { ChevronComponent } from './components/chevron/chevron.component';
import { CountryPipe } from './pipes/location-codes/country/country.pipe';
import { SingleChoiceInputTypePipe } from './pipes/choiceInput/single-choice-input-type.pipe';
import { MultipleChoiceInputTypePipe } from './pipes/choiceInput/multiple-choice-input-type.pipe';
import { FunderNamePipe } from './pipes/funder-name/funder-name.pipe';
import { VarDirective } from '../../directive/var.directive';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { FunderSidebarComponent } from './components/funder-sidebar/funder-sidebar.component';
import { ApplicantSidebarComponent } from './components/applicant-sidebar/applicant-sidebar.component';
import { CoordinatorSidebarComponent } from './components/coordinator-sidebar/coordinator-sidebar.component';
import {AuthenticatedWithoutRoleDirective} from './directives/authenticated-without-role/authenticated-without-role.directive';
import {WorkPlanCategoriesPipe} from './pipes/work-plan-categories/work-plan-categories.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';


@NgModule({
  declarations: [
    NavbarComponent,
    AuthenticatedWithRoleDirective,
    AuthenticatedWithoutRoleDirective,
    SpinnerComponent,
    FormatDatePipe,
    AsFormGroupPipe,
    UserNavigationComponent,
    CategoriesPipe,
    WorkPlanCategoriesPipe,
    OrganisationTypePipe,
    SingleChoiceInputTypePipe,
    MultipleChoiceInputTypePipe,
    CountryPipe,
    ProvincePipe,
    DistrictPipe,
    SubDistrictPipe,
    TierNamePipe,
    ChevronComponent,
    FunderNamePipe,
    VarDirective,
    AdminSidebarComponent,
    FunderSidebarComponent,
    CoordinatorSidebarComponent,
    ApplicantSidebarComponent,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbNavModule,
    NgbCollapseModule,
    NgbAccordionModule
  ],
  exports: [
    NavbarComponent,
    AuthenticatedWithRoleDirective,
    AuthenticatedWithoutRoleDirective,
    SpinnerComponent,
    FormatDatePipe,
    AsFormGroupPipe,
    CategoriesPipe,
    WorkPlanCategoriesPipe,
    OrganisationTypePipe,
    SingleChoiceInputTypePipe,
    MultipleChoiceInputTypePipe,
    CountryPipe,
    ProvincePipe,
    DistrictPipe,
    SubDistrictPipe,
    TierNamePipe,
    ChevronComponent,
    FunderNamePipe,
    VarDirective,
    AdminSidebarComponent,
    FunderSidebarComponent,
    CoordinatorSidebarComponent,
    ApplicantSidebarComponent,
    TruncatePipe,
  ],
  providers: [
    NgbDateNativeAdapter
  ]
})
export class SharedCommonModule {
}
