<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Counseling is defined as social workers, psychologists or other trained professionals
    providing one-on-one, couple or small group sessions to PLHIV, gender based violence
    victims and/or other at-risk populations who have been identified as needing additional
    support.
  </p>
  <form [formGroup]="counselingForm">
    <fieldset [disabled]="isReadOnly">
      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be targeted with counseling services?'"
        [id]="'coun-peopleTargeted'"
        [name]="'coun-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
      ></app-input-checkbox>

      <app-input-number
        [label]="'How many people will be targeted for counseling services?'"
        [id]="'coun-numberOfPeopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeopleTargeted"
      ></app-input-number>

      <app-input-number
        [label]="'On average, how many sessions will each client receive?'"
        [id]="'coun-numberOfSessions'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfSessions"
      ></app-input-number>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'coun-durationOfCounseling'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfCounseling"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'coun-durationOfCounselingType'"
            [isRequired]="true"
            [name]="'coun-durationOfCounselingType'"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfCounselingType"
          ></app-input-radio>
        </div>
      </div>

      <app-input-select-single
        [label]="'Who will provide the counseling services?'"
        [items]="counselingServicesProviderOptions"
        [id]="'coun-counselingServicesProvider'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        [isRequired]="true"
        formControlName="counselingServicesProvider"
        (selectedItem)="checkOther('other', $event, counselingForm.controls.counselingServicesProvider, counselingForm.controls.counselingServicesProviderOther)"
      ></app-input-select-single>

      <div *ngIf="counselingForm.value.counselingServicesProvider?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'coun-counselingServicesProviderOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="counselingServicesProviderOther"
        ></app-input-textarea>
      </div>

      <div>
        <p>How much funding do you need to provide these counseling services?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="counselingForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
