<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="sidebar-heading">
    <p class="text-secondary disabled">MENU</p>
  </div>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li *ngIf="user.organisationId && user.isCapacitySpectrumSubmitted && user.serviceMatrixId" [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/implementer/dashboard"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        [routerLink]="'/org-profile/profile/' + (user.organisationId || 'new')"
      >Profile</a>
    </li>

    <li *ngIf="user.organisationId" [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        (click)="routeToCapacitySpectrum()"
      >Capacity Spectrum</a>
    </li>

    <li *ngIf="user.organisationId && user.isCapacitySpectrumSubmitted" [ngbNavItem]="4">
      <a
        ngbNavLink
        class="sidebar-link"
        (click)="routeToServiceMatrix()"
      >Service Matrix</a>
    </li>

    <li *ngIf="user.organisationId && user.isCapacitySpectrumSubmitted && user.serviceMatrixId" [ngbNavItem]="5">
      <a
        ngbNavLink
        class="sidebar-link disabled"
        disabled
      >Wishlist</a>
    </li>

    <li *ngIf="user.organisationId && user.isCapacitySpectrumSubmitted && user.serviceMatrixId">
      <ngb-accordion activeIds="ngb-panel-0">
        <ngb-panel title="Investment Opportunities" class="dropdown-toggle">
          <ng-template ngbPanelContent>

            <div [ngbNavItem]="7">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/applicant/application-history"
              >
                Application History
              </a>
            </div>

            <div [ngbNavItem]="8">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/applicant/active"
              >
                Current
              </a>
            </div>

            <div [ngbNavItem]="9">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/applicant/work-plan"
              >
                Work plan
              </a>
            </div>

          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </li>

  </ul>
</div>
