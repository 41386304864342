<!--<section class="col-lg-8 col-sm-11 mx-auto">-->
  <div class="wrapper esx-page">

    <div class="esx-header">
      <div class="flex-center h-100">
        <div class="card header-card">
          <div class="card-body p-0">
            <div class="header">
              <div class="header-title">
                <h4 class="text-primary text-center">
                  Application Confirmation
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-95 mx-auto">
      <div class="card mt-4">

        <p>
          Your Application has been submitted. A confirmation email will be sent to your
          registered email address. If you do not receive this confirmation email within
          the next 48 hours please contact support.
        </p>

        <p>
          Once an application has been validated and submitted no further edits or
          additions can be made to the application.
        </p>

        <p>
          All organizations, regardless of the outcome, should be notified no later
          than August 31, 2020.
        </p>

        <p>
          Please refer to the <a class="link" (click)="goFaq()">Frequently Asked Questions</a>
          should you have any additional questions regarding the application process.
        </p>

        <app-alert
          class="w-100"
          [id]="'appConfirmation'"
        ></app-alert>

        <button *ngIf="isAdmin" class="btn btn-primary float-right mb-1" (click)="reset()">
          Reset to Application
        </button>
      </div>
    </div>
  </div>
<!--</section>-->
