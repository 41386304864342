import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GrantWorkflowNavigator} from '../../grant-workflow-navigator';
import {GrantWindowService} from '../../../grant-window/store/grant-window.service';
import {EligibilityService} from '../eligibility/store/eligibility.service';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-ineligible',
  templateUrl: './ineligible.component.html',
  styleUrls: ['./ineligible.component.css']
})
export class IneligibleComponent implements OnInit, OnDestroy {

  workflowId: number;
  ineligibleReasons: string[];

  isAdmin: boolean;

  constructor(public route: ActivatedRoute,
              private grantWindowService: GrantWindowService,
              private eligibilityService: EligibilityService,
              private sessionQuery: SessionQuery,
              private grantWorkflowNavigator: GrantWorkflowNavigator,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.workflowId = this.route.snapshot.params.workflowId;
    this.getIneligibleReasons();
    this.setIsAdmin();
    // this.grantWindowService.showOrgProfile = true;
  }

  ngOnDestroy() {
    // this.grantWindowService.showOrgProfile = false;
  }

  private getIneligibleReasons() {
    this.eligibilityService.getIneligibleReasons(this.workflowId).subscribe(results => {
      this.ineligibleReasons = results;
    }, () => {
      this.alertService.setAlert('Unable to fetch Ineligible data', 'error', 'ineligible');
    });
  }

  private setIsAdmin() {
    this.isAdmin = this.sessionQuery.hasRole(['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER']);
  }

  reset() {
    this.eligibilityService.resetIneligible(this.workflowId).subscribe(() => {
      this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
    }, response => {
      this.alertService.setAlert(response, 'error', 'ineligible');
    });
  }
}
