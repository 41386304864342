import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ReviewService} from '../../store/review.service';
import {ReviewQuery} from '../../store/review.query';
import {createReview, ReviewModel} from '../../store/review.model';
import {GrantWorkflowService} from '../../../grant-workflow/store/grant-workflow.service';
import {LoadingService} from '../../../../shared/modules/shared-common/services/loading/loading.service';
import {CoordinatorService} from '../../../coordinator/store/coordinator.service';
import {User} from '../../../../shared/stores/user/user.model';
import {FormBuilder} from '@angular/forms';
import {TruncateUtil} from 'app/shared/utils/truncate-util';
import {ScorePercentageUtil} from 'app/shared/utils/score-percentage-util';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';
import {ReviewStepType} from '../../../grant-workflow/store/grant-workflow.model';
import {SessionQuery} from '../../../../shared/stores/session/session.query';

@Component({
  selector: 'app-review-details-container',
  templateUrl: './review-details-container.component.html',
  styleUrls: ['./review-details-container.component.scss']
})
export class ReviewDetailsContainerComponent implements OnInit, OnDestroy {

  reviewDetails: ReviewModel;
  isLoading: boolean;
  workflowId: number;
  budgetTier: string;
  windowId: number;
  coordinators: User[];

  paramSubscription: Subscription;

  // Using the geared score to derive a percentage out of 374 (total possible score before gearing).
  score = ScorePercentageUtil;
  truncateOrgName = TruncateUtil;

  isReadOnly: boolean;

  constructor(private reviewService: ReviewService,
              private reviewQuery: ReviewQuery,
              private coordinatorService: CoordinatorService,
              private workflowService: GrantWorkflowService,
              private loadingService: LoadingService,
              private route: ActivatedRoute,
              private location: Location,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private sessionQuery: SessionQuery) {
  }

  ngOnInit() {
    this.fetchReviewDetails();
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

  fetchReviewDetails() {
    this.isLoading = true;
    this.paramSubscription = this.route.params.subscribe(params => {
      this.workflowId = params.workflowId;
      this.reviewService.getReviewDetails(params.workflowId).pipe(take(1))
        .subscribe(details => {
          if (details) {
            this.reviewDetails = createReview(details);
            this.windowId = details.grantWorkflow.grantWindow.id;
            this.fetchCoordinatorList();

            // Set Read Only rules:
            if (details.grantWorkflow.reviewStep === ReviewStepType.ASSESSMENT_SUBMITTED || ReviewStepType.RECOMMENDED) {
              if (this.sessionQuery.getUser().roles.includes('ROLE_GRANT_COORDINATOR')) {
                this.isReadOnly = true;
              }
            }
            this.isLoading = false;
          } else {
            this.workflowService.fetchWorkflowById(params.workflowId).subscribe(workflow => {
              this.reviewDetails = createReview({grantWorkflow: workflow});
              this.windowId = workflow.grantWindow.id;
              this.fetchCoordinatorList();
              this.isLoading = false;
            });
          }
        }, error => {
          this.alertService.setAlert('Failed to fetch Review Details', 'error', 'reviewDetailsContainer');
          console.log(error);
        });
    });
  }

  goBack() {
    this.location.back();
  }

  fetchCoordinatorList() {
    this.coordinatorService.getAllByWindowId(this.windowId).subscribe(coordinators => {
      if (coordinators && coordinators.length) {
        this.coordinators = coordinators;
      }
    }, () => {
      // TODO
      this.alertService.setAlert('Failed to fetch Coordinator List', 'error', 'reviewDetailsContainer');
    });
  }
}
