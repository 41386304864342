import {Component, Input, OnInit} from '@angular/core';
import {FundingRequestBudget, FundingRequestType} from '../../model/funding-request.model';
import {CurrencyUtil} from '../../../../../../../shared/utils/currency-util';
import {FormGroup} from '@angular/forms';
import { GrantWindowService } from 'app/modules/grant-window/store/grant-window.service';

@Component({
  selector: 'app-funding-request-budget-total',
  templateUrl: './funding-request-budget-total.component.html',
  styleUrls: ['./funding-request-budget-total.component.scss']
})
export class FundingRequestBudgetTotalComponent implements OnInit {

  @Input() fundingRequest: FormGroup;
  @Input() activeFundingRequests: FormGroup;

  maxBudget: number;

  grantWindow: any;

  constructor(private grantWindowService: GrantWindowService) {
  }

  ngOnInit() {
    this.maxBudget = this.grantWindowService.selectedGrantWindow.maximumBudgetRequest;
  }

  sumTotal(): number {
    let total = 0;

    if (this.fundingRequest) {
      if (this.fundingRequest.value.training && this.activeFundingRequests.value.includes(FundingRequestType.TRAINING)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.training.budget);
      }
      if (this.fundingRequest.value.supportGroups && this.activeFundingRequests.value.includes(FundingRequestType.SUPPORT_GROUPS)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.supportGroups.budget);
      }
      if (this.fundingRequest.value.adherenceClubs && this.activeFundingRequests.value.includes(FundingRequestType.ADHERENCE_CLUBS)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.adherenceClubs.budget);
      }
      if (this.fundingRequest.value.hivTesting && this.activeFundingRequests.value.includes(FundingRequestType.HIV_TESTING)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.hivTesting.budget);
      }
      if (this.fundingRequest.value.homeBasedCare && this.activeFundingRequests.value.includes(FundingRequestType.HOME_BASED_CARE)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.homeBasedCare.budget);
      }
      if (this.fundingRequest.value.counseling && this.activeFundingRequests.value.includes(FundingRequestType.COUNSELING)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.counseling.budget);
      }
      if (this.fundingRequest.value.communityMobilization &&
        this.activeFundingRequests.value.includes(FundingRequestType.COMMUNITY_MOBILIZATION)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.communityMobilization.budget);
      }
      if (this.fundingRequest.value.clinicalServices && this.activeFundingRequests.value.includes(FundingRequestType.CLINICAL_SERVICES)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.clinicalServices.budget);
      }
      if (this.fundingRequest.value.camps && this.activeFundingRequests.value.includes(FundingRequestType.CAMPS)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.camps.budget);
      }
      if (this.fundingRequest.value.targetedPrevention &&
        this.activeFundingRequests.value.includes(FundingRequestType.TARGETED_PREVENTION)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.targetedPrevention.budget);
      }
      if (this.fundingRequest.value.systemDevelopment && this.activeFundingRequests.value.includes(FundingRequestType.SYSTEM_DEVELOPMENT)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.systemDevelopment.budget);
      }
      if (this.fundingRequest.value.otherFundingRequest && this.activeFundingRequests.value.includes(FundingRequestType.OTHER)) {
        total += this.sumBudgetCategories(this.fundingRequest.value.otherFundingRequest.budget);
      }
    } else {
      return 0;
    }

    return total;
  }

  sumBudgetCategories(budget: FundingRequestBudget) {
    if (budget) {
      return this.getBudgetAmount(budget.suppliesAndMaterials)
        + this.getBudgetAmount(budget.equipmentAndFurniture)
        + this.getBudgetAmount(budget.personnel)
        + this.getBudgetAmount(budget.travelOrTransport)
        + this.getBudgetAmount(budget.administration);
    }
    return 0;
  }

  getBudgetAmount(amount: number): number {
    return amount ? parseFloat(CurrencyUtil.cleanupAmount(amount)) : 0;
  }
}
