import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsMneAnswerService} from './services/cs-mne-answer.service';
import {take} from 'rxjs/operators';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-mne',
  templateUrl: './cs-mne.component.html',
  styleUrls: ['./cs-mne.component.scss']
})
export class CsMneComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() mneForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  // Question Answers.
  planAnswers = [];
  planElementAnswers = [];
  monitorAnswers = [];
  programReportingAnswers = [];
  usageOfReportsAnswers = [];
  programBudgetAnswers = [];
  budgetCheckFrequencyAnswers = [];
  trainedMneAnswers = [];
  feedbackProcedureAnswers = [];
  strategicPlanAnswers = [];
  linkedStrategicPlanAnswers = [];

  planSubscription: Subscription;
  planElementSubscription: Subscription;
  monitorSubscription: Subscription;
  programReportingSubscription: Subscription;
  usageOfReportsSubscription: Subscription;
  programBudgetSubscription: Subscription;
  budgetCheckFrequencySubscription: Subscription;
  trainedMneSubscription: Subscription;
  feedbackProcedureSubscription: Subscription;
  strategicPlanSubscription: Subscription;
  linkedStrategicPlanSubscription: Subscription;
  childFormTouched: boolean;

  constructor(private loadingService: LoadingService,
              private csMneAnswerService: CsMneAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.mneForm.touched;

    this.populateAnswers();
  }

  public checkOther(value: string, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy(): void {
    if (this.planSubscription) {
      this.planSubscription.unsubscribe();
    }
    if (this.planElementSubscription) {
      this.planElementSubscription.unsubscribe();
    }
    if (this.monitorSubscription) {
      this.monitorSubscription.unsubscribe();
    }
    if (this.programReportingSubscription) {
      this.programReportingSubscription.unsubscribe();
    }
    if (this.usageOfReportsSubscription) {
      this.usageOfReportsSubscription.unsubscribe();
    }
    if (this.programBudgetSubscription) {
      this.programBudgetSubscription.unsubscribe();
    }
    if (this.budgetCheckFrequencySubscription) {
      this.budgetCheckFrequencySubscription.unsubscribe();
    }
    if (this.trainedMneSubscription) {
      this.trainedMneSubscription.unsubscribe();
    }
    if (this.feedbackProcedureSubscription) {
      this.feedbackProcedureSubscription.unsubscribe();
    }
    if (this.strategicPlanSubscription) {
      this.strategicPlanSubscription.unsubscribe();
    }
    if (this.linkedStrategicPlanSubscription) {
      this.linkedStrategicPlanSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csMneAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.planSubscription = this.csMneAnswerService.$plan.subscribe(answers => {
      if (answers) {
        this.planAnswers = answers.choices;
      }
    });

    this.planElementSubscription = this.csMneAnswerService.$planElement.subscribe(answers => {
      if (answers) {
        this.planElementAnswers = answers.choices;
      }
    });

    this.monitorSubscription = this.csMneAnswerService.$monitor.subscribe(answers => {
      if (answers) {
        this.monitorAnswers = answers.choices;
      }
    });

    this.programReportingSubscription = this.csMneAnswerService.$programReporting.subscribe(answers => {
      if (answers) {
        this.programReportingAnswers = answers.choices;
      }
    });

    this.usageOfReportsSubscription = this.csMneAnswerService.$usageOfReports.subscribe(answers => {
      if (answers) {
        this.usageOfReportsAnswers = answers.choices;
      }
    });

    this.programBudgetSubscription = this.csMneAnswerService.$programBudget.subscribe(answers => {
      if (answers) {
        this.programBudgetAnswers = answers.choices;
      }
    });

    this.budgetCheckFrequencySubscription = this.csMneAnswerService.$budgetCheckFrequency.subscribe(answers => {
      if (answers) {
        this.budgetCheckFrequencyAnswers = answers.choices;
      }
    });

    this.trainedMneSubscription = this.csMneAnswerService.$trainedMne.subscribe(answers => {
      if (answers) {
        this.trainedMneAnswers = answers.choices;
      }
    });

    this.feedbackProcedureSubscription = this.csMneAnswerService.$feedbackProcedure.subscribe(answers => {
      if (answers) {
        this.feedbackProcedureAnswers = answers.choices;
      }
    });

    this.strategicPlanSubscription = this.csMneAnswerService.$strategicPlan.subscribe(answers => {
      if (answers) {
        this.strategicPlanAnswers = answers.choices;
      }
    });

    this.linkedStrategicPlanSubscription = this.csMneAnswerService.$linkedStrategicPlan.subscribe(answers => {
      if (answers) {
        this.linkedStrategicPlanAnswers = answers.choices;
      }
    });
  }
}
