import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {SidebarService} from './shared/modules/shared-common/services/sidebar/sidebar.service';
import {Subscription} from 'rxjs';

// declare ga as a function to set and sent the events
declare let ga;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  toggled: boolean;
  sidebarSubscription: Subscription;

  constructor(public router: Router,
              private renderer: Renderer2,
              private sidebarService: SidebarService,
              @Inject(DOCUMENT) private dom: Document) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Set Google Analytics tracking.
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarService.getToggled().subscribe(toggled => {
      this.toggled = toggled;
    });
  }

  onActivate(top: HTMLElement) {
    top.scrollTo({
      top: 0
    });
  }

  // TODO: Toggle Tawk (Add below to constructor and remove from index.html)
  // const tawkScript = renderer.createElement('script');
  // renderer.setAttribute(tawkScript, 'type', 'text/javascript');
  // const scriptBody = renderer.createText(
  //   `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  //       Tawk_API.onBeforeLoad = function(){
  //         if (event.url !== '/landing') {
  //           Tawk_API.hideWidget();
  //         }
  //       };
  //       (function () {
  //         var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
  //         s1.async = true;
  //         s1.src = 'https://embed.tawk.to/5e9e96b169e9320caac5bbe4/default';
  //         s1.charset = 'UTF-8';
  //         s1.setAttribute('crossorigin', '*');
  //         s0.parentNode.insertBefore(s1, s0);
  //       })();`
  // );
  //   renderer.appendChild(tawkScript, scriptBody);
  //   renderer.appendChild(dom.body, tawkScript);
  // if (this.isSpecifiedRoute(event.url)) {
  //   dom.body.childNodes.forEach(node => {
  //     if (node.nodeName === 'DIV') {
  //       renderer.setStyle(node, 'display', 'block');
  //     }
  //   });
  // } else {
  //   dom.body.childNodes.forEach(node => {
  //     if (node.nodeName === 'DIV') {
  //       renderer.setStyle(node, 'display', 'none');
  //     }
  //   });
  // }

  // isSpecifiedRoute(route: string): boolean {
  //   const pages = [
  //     '/landing'
  //   ];
  //   return pages.includes(route);
  // }
}
