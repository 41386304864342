import {Validators} from '@angular/forms';

export class FormValidationUtil {
  /**
   * makes the field required if the predicate function returns true
   */
  static requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }
}
