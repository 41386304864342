<form [formGroup]="telephoneInterviewForm">
  <app-input-text
    [label]="'Name of person interviewed:'"
    [id]="'personInterviewed'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [hasBorder]="false"
    [isGrey]="false"
    formControlName="personInterviewed"
  ></app-input-text>

  <app-input-date
    [label]="'Date of call:'"
    [id]="'dateOfCall'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [hasBorder]="false"
    [isGrey]="false"
    formControlName="dateOfCall"
  ></app-input-date>

  <app-input-select-single
    [label]="'Select Coordinator'"
    [items]="coordinatorList"
    [id]="'coordinator'"
    [theme]="theme"
    [style]="style"
    [isRequired]="true"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [hasBorder]="false"
    [isGrey]="false"
    formControlName="coordinator"
  ></app-input-select-single>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'contactDetailsIsCorrect'"
    [label]="'Primary and Secondary contact details on the application are correct'"
    [isRequired]="true"
    [id]="'contactDetailsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="contactDetailsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'fundingRequestDiscussed'"
    [label]="'Work plan discussed, at least one activity populated per objective and ready to send to applicant.'"
    [isRequired]="true"
    [id]="'fundingRequestDiscussed'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="fundingRequestDiscussed"
  ></app-widget-check-two>

  <app-input-textarea
    [label]="'Funding request notes'"
    [id]="'fundingRequestNotes'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [hasBorder]="false"
    [isGrey]="false"
    formControlName="fundingRequestNotes"
  ></app-input-textarea>

  <app-upload-document
    [label]="'Telephone notes'"
    [documents]="telephoneInterviewForm.value.servicesAndHistory"
    [additionalParameters]="additionalParameters"
    [isReadOnly]="isReadOnly"
  ></app-upload-document>
</form>
