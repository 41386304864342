import {Component, Input, OnInit} from '@angular/core';
import {Mail, createMailForm} from '../../store/email.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { EmailService } from '../../store/email.service';
import { ModalService } from 'app/shared/modules/shared-common/services/modal/modal.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Observable} from 'rxjs';
import {LoadingService} from '../../../../shared/modules/shared-common/services/loading/loading.service';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  theme: 'dark' | 'light' = 'dark';
  style: 'default' | 'bordered' = 'default';

  @Input() mail: Mail;

  loading: Observable<boolean>;
  mailForm: FormGroup;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              private loadingService: LoadingService,
              private modalService: ModalService,
              private emailService: EmailService,
              private alertService: AlertService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.loading = this.loadingService.getIsLoading();
    this.mailForm = createMailForm(this.formBuilder, this.mail);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  send(mailForm: FormGroup) {
    if (mailForm.valid) {
      this.loadingService.setIsLoading(true);
      this.emailService.sendEmail(this.mailForm.value).subscribe(() => {
        this.loadingService.setIsLoading(false);
        this.alertService.setAlert('Success! Mail sent', 'success', 'email',  {
          timeOut: 5000
        });
        this.closeModal();
      }, () => {
        this.loadingService.setIsLoading(false);
        this.alertService.setAlert('Error! Failed to send mail', 'error', 'email');
      });
    } else {
      this.alertService.setAlert('Please complete all fields', 'error', 'email');
    }
  }
}
