import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GrantReviewRoutingModule} from './grant-review-routing.module';
import {ReviewDetailsContainerComponent} from './components/review-details-container/review-details-container.component';
import {ReviewDetailsComponent} from './components/review-details/review-details.component';
import {ReviewSidePanelComponent} from './components/review-side-panel/review-side-panel.component';
import {SharedCommonModule} from '../../shared/modules/shared-common/shared-common.module';
import {AdditionalDocumentsComponent} from './components/additional-documents/additional-documents.component';
import {DocumentUploadModule} from '../../shared/modules/file-upload/document-upload.module';
import {OrganisationModule} from '../organisation/org-profile/organisation.module';
import {GrantWorkflowModule} from '../grant-workflow/grant-workflow.module';
import {TelephoneInterviewComponent} from './components/review-side-panel/telephone-interview/telephone-interview.component';
import {InternalVerificationComponent} from './components/review-side-panel/internal-verification/internal-verification.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from 'app/shared/modules/form-elements/form-elements.module';
import {SiteVisitComponent} from './components/review-side-panel/site-visit/site-visit.component';
import {
  SiteVisitOrgStructureComponent
} from './components/review-side-panel/site-visit/site-visit-org-structure/site-visit-org-structure.component';
import {
  SiteVisitServiceDeliveryComponent
} from './components/review-side-panel/site-visit/site-visit-service-delivery/site-visit-service-delivery.component';
import {
  SiteVisitCommunityRelationshipsComponent
} from './components/review-side-panel/site-visit/site-visit-community-relationships/site-visit-community-relationships.component';
import {SiteVisitFinancialComponent} from './components/review-side-panel/site-visit/site-visit-financial/site-visit-financial.component';
import {
  SiteVisitRecentChangesComponent
} from './components/review-side-panel/site-visit/site-visit-recent-changes/site-visit-recent-changes.component';
import {
  InternalCommentHistoryComponent
} from './components/review-side-panel/internal-comment-history/internal-comment-history.component';
// import {CopyOfOrgCapacityModule} from '../organisation/copy-of-org-capacity/copy-of-org-capacity.module';
// import {OrgCapacityModule} from '../organisation/org-capacity/org-capacity.module';
import {ReviewMessageModalComponent} from './components/review-side-panel/review-message-modal/review-message-modal.component';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../shared/modules/alert/alert.module';
import {CapacitySpectrumModule} from '../organisation/capacity-spectrum/capacity-spectrum.module';
import {ReviewComponent} from '../grant-window/components/dashboards/review-phase/review/review.component';
import {ReviewUserTableComponent} from '../grant-window/components/dashboards/review-phase/review/review-user-table/review-user-table.component';
import {DataTableModule} from '../../shared/modules/data-table/data-table.module';
import {ChartsModule} from '../../shared/modules/charts/charts.module';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FileUploadModule} from 'ng2-file-upload';
import {AccordionTwoModule} from '../../shared/modules/accordion-two/accordion-two.module';
import {AccordionModule} from '../../shared/modules/accordion/accordion.module';
import {ServiceMatrixModule} from '../organisation/service-matrix/service-matrix.module';
import {AssignCoordinatorComponent} from '../grant-window/components/dashboards/review-phase/review/review-user-table/assign-coordinator/assign-coordinator.component';
import {EmailComponent} from '../email/components/email/email.component';
import { CapacitySpectrumVerificationComponent } from './components/review-side-panel/capacity-spectrum-verification/capacity-spectrum-verification.component';
import { ServiceMatrixVerificationComponent } from './components/review-side-panel/service-matrix-verification/service-matrix-verification.component';

@NgModule({
  declarations: [
    ReviewComponent,
    ReviewUserTableComponent,
    ReviewDetailsContainerComponent,
    ReviewDetailsComponent,
    ReviewSidePanelComponent,
    AssignCoordinatorComponent,
    EmailComponent,
    AdditionalDocumentsComponent,
    TelephoneInterviewComponent,
    InternalVerificationComponent,
    SiteVisitComponent,
    SiteVisitOrgStructureComponent,
    SiteVisitServiceDeliveryComponent,
    SiteVisitCommunityRelationshipsComponent,
    SiteVisitFinancialComponent,
    SiteVisitRecentChangesComponent,
    InternalCommentHistoryComponent,
    ReviewMessageModalComponent,
    CapacitySpectrumVerificationComponent,
    ServiceMatrixVerificationComponent
  ],
  imports: [
    AccordionTwoModule,
    AccordionModule,
    AlertModule,
    CapacitySpectrumModule,
    ServiceMatrixModule,
    ChartsModule,
    CommonModule,
    DataTableModule,
    DocumentUploadModule,
    FileUploadModule,
    FormElementsModule,
    FormsModule,
    GrantWorkflowModule,
    GrantReviewRoutingModule,
    NgbDropdownModule,
    NgbModule,
    OrganisationModule,
    ReactiveFormsModule,
    SharedCommonModule,
    ToastContainerModule,
    // CopyOfOrgCapacityModule,
    // OrgCapacityModule,
  ],
  entryComponents: [
    ReviewMessageModalComponent,
  ]
})
export class GrantReviewModule {
}
