<div class="wrapper" [class.esx-page]="!fromReview">

  <div *ngIf="!fromReview" class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Profile
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto" [class.esx-content]="!fromReview" #pageTop>
    <div class="h-100 p-1" [class.mt-2]="!fromReview">

<!--      <h4 class="text-center" *ngIf="fromReview">-->
<!--        <span class="text-capitalize">Profile</span>-->
<!--      </h4>-->
<!--      <hr class="w-75 mx-auto border-primary" *ngIf="fromReview">-->

      <div *ngIf="!isReadOnly" class="profile-actions float-right">
        <ng-container *ngIf="editing">
          <button
            class="btn btn-sm btn-primary btn-outline-dark profile-btn"
            (click)="createOrUpdate()"
          >
            Save
          </button>
          <button
            *ngIf="currentOrg?.id"
            class="btn btn-sm btn-danger btn-outline-dark profile-btn"
            (click)="toggleEditProfile(pageTop)"
          >
            Cancel
          </button>
        </ng-container>
        <ng-container *ngIf="!editing">
          <button
            class="btn btn-sm btn-primary btn-outline-dark profile-btn"
            (click)="toggleEditProfile(pageTop)"
          >
            Edit Profile
          </button>
        </ng-container>
      </div>

      <nav class="profile-tabs">
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'generalInfo'"
          (click)="changeTab('generalInfo')"
        >
          General Info
        </button>
        |
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'contactDetails'"
          (click)="changeTab('contactDetails')"
        >
          Contact Details
        </button>
        |
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'contactPersons'"
          (click)="changeTab('contactPersons')"
        >
          Contact Persons
        </button>
      </nav>

      <app-alert
        class="w-100"
        [id]="'profileContainer'"
      ></app-alert>

      <app-spinner [loadingObs]="isLoading">

        <ng-container *ngIf="editing && !isReadOnly">
          <app-edit-profile
            [currentTab]="currentTab"
            [organisation]="currentOrg"
            [profileForm]="profileForm"
            [isSaving]="isSaving"
            (tab)="changeTab($event)"
          ></app-edit-profile>
        </ng-container>
        <ng-container *ngIf="!editing || isReadOnly">
          <app-profile
            [currentTab]="currentTab"
            [currentOrg]="profileForm"
            (edit)="toggleEditProfile(pageTop)"
          ></app-profile>
        </ng-container>
      </app-spinner>
    </div>
  </div>
</div>

<ng-template #infoModal>
  <div class="p-3">
    <h4 class="text-center mb-3">Thank you for completing your profile</h4>
    <p class="text-center">
      You will now be asked to complete your Capacity Spectrum which is an organisational assessment that has 10
      sections.
      While you may update it as your organisation evolves, you will only complete this entire assessment once. You will
      not be able to submit your assessment until all mandatory fields are complete.
    </p>
    <div class="w-100 flex-center">
      <button class="btn btn-accent w-60" (click)="closeModal()">Okay</button>
    </div>
  </div>
</ng-template>
