import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const targetedPopulationAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: `men who have sex with men`,
    value: 'msm'
  },
  {
    label: `transgender persons`,
    value: 'tp'
  },
  {
    label: `injecting drug users`,
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: `clients of sex workers`,
    value: 'csw'
  },
  {
    label: `military`,
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: `people living with HIV`,
    value: 'peopleWithHiv'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
  {
    label: 'adults 25+ / general population',
    value: 'adultPopulation'
  },
  {
    label: 'parents',
    value: 'parents'
  },
  {
    label: `caregivers of PLHIV`,
    value: 'caregivers'
  },
  {
    label: `HIV positive children`,
    value: 'hivChildren'
  }
];

export const aspectBenefitedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'monitoring and evaluation',
    value: 'monitoringEvaluation'
  },
  {
    label: 'referrals',
    value: 'referrals'
  },
  {
    label: `case management`,
    value: 'caseManagement'
  },
  {
    label: `program management`,
    value: 'programManagement'
  },
  {
    label: 'other, please specify in the comment field below',
    value: 'other'
  },
];
