import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CapacitySpectrumState, CapacitySpectrumStore} from './capacity-spectrum.store';

@Injectable({providedIn: 'root'})
export class CapacitySpectrumQuery extends QueryEntity<CapacitySpectrumState> {

  constructor(protected store: CapacitySpectrumStore) {
    super(store);
  }

}
