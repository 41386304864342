export interface BaseEntity {
  id?: number;
  version?: number;
  createdBy?: string;
  modifiedBy?: string;
  createdOn?: number;
  modifiedOn?: number;
}

export function createBaseEntity(params: Partial<BaseEntity>) {
  return {
    id: params && params.id,
    version: params && params.version,
    createdBy: params && params.createdBy,
    modifiedBy: params && params.modifiedBy,
    createdOn: params && params.createdOn,
    modifiedOn: params && params.modifiedOn,
  } as BaseEntity;
}

export function createBaseEntityForm(params?: Partial<BaseEntity>) {
  return {
    id: [params && params.id || ''],
    version: [params && params.version || ''],
    createdBy: [params && params.createdBy || ''],
    modifiedBy: [params && params.modifiedBy || ''],
    createdOn: [params && params.createdOn || ''],
    modifiedOn: [params && params.modifiedOn || ''],
  };
}
