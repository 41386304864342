import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum GovernanceEndpointType {
  MANAGEMENT_STRUCTURE = 'MANAGEMENT_STRUCTURE',
  DECISION_MAKING = 'DECISION_MAKING',
  SUCCESSION_PLANNING = 'SUCCESSION_PLANNING',
  FOUNDER_RELATIONSHIP = 'FOUNDER_RELATIONSHIP',
  DIRECTORS = 'DIRECTORS',
  POLICY_ELEMENT = 'POLICY_ELEMENT',
  BOARD_MEMBER_PROCESS = 'BOARD_MEMBER_PROCESS',
  BOARD_MEMBER_TERM = 'BOARD_MEMBER_TERM',
  MEETING_MINUTES = 'MEETING_MINUTES',
  REPORT_FREQUENCY = 'REPORT_FREQUENCY'
}

export interface CSGovernance extends BaseEntity {
  managementStructure: string;
  decisionMakingList: string[];
  decisionMakingListOther: string; // if decisionMakingList contains 'OTHER'
  successionPlanning: string;
  founderRelationship: string;
  directors: string;
  policyElements: string[];
  policyElementsOther: string; // if policyElements contains 'OTHER'
  boardMemberProcess: string;
  boardMemberTerm: string;
  areMembersPaidToServe: boolean;
  meetingMinutes: string;
  reportFrequency: string;
}

export function createCSGovernance(params: Partial<CSGovernance>) {
  return Object.assign({}, createBaseEntity(params), {
    managementStructure: params && params.managementStructure,
    decisionMakingList: params && params.decisionMakingList,
    decisionMakingListOther: params && params.decisionMakingListOther,
    successionPlanning: params && params.successionPlanning,
    founderRelationship: params && params.founderRelationship,
    directors: params && params.directors,
    policyElements: params && params.policyElements,
    policyElementsOther: params && params.policyElementsOther,
    boardMemberProcess: params && params.boardMemberProcess,
    boardMemberTerm: params && params.boardMemberTerm,
    areMembersPaidToServe: params && params.areMembersPaidToServe,
    meetingMinutes: params && params.meetingMinutes,
    reportFrequency: params && params.reportFrequency,
  }) as CSGovernance;
}

export function createCSGovernanceForm(destroy$: Subject<boolean>, formBuilder: FormBuilder, params: Partial<CSGovernance>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    managementStructure: [params && params.managementStructure, Validators.required],
    decisionMakingList: [params && params.decisionMakingList, Validators.required],
    decisionMakingListOther: [params && params.decisionMakingListOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('decisionMakingList').value && formGroup.get('decisionMakingList').value.includes('OTHER');
      })
    ],
    successionPlanning: [params && params.successionPlanning, Validators.required],
    founderRelationship: [params && params.founderRelationship, Validators.required],
    directors: [params && params.directors, Validators.required],
    policyElements: [params && params.policyElements, Validators.required],
    policyElementsOther: [params && params.policyElementsOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('policyElements').value && formGroup.get('policyElements').value.includes('OTHER');
      })
    ],
    boardMemberProcess: [params && params.boardMemberProcess, Validators.required],
    boardMemberTerm: [params && params.boardMemberTerm, Validators.required],
    areMembersPaidToServe: [params && params.areMembersPaidToServe, Validators.required],
    meetingMinutes: [params && params.meetingMinutes, Validators.required],
    reportFrequency: [params && params.reportFrequency, Validators.required],
  }));

  formGroup.get('decisionMakingList').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('decisionMakingListOther').updateValueAndValidity();
    });

  formGroup.get('policyElements').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('policyElementsOther').updateValueAndValidity();
    });

  return formGroup;
}
