<div class="card custom-border-none mb-0">
  <div class="card-body bg-dark">
    <div class="row">

      <div class="col-6">
        <app-input-text
          [id]="'filterInput'"
          [showErrors]="false"
          [theme]="theme"
          [style]="style"
          placeholder="Type to search"
          [ngModel]="filterInput"
          (ngModelChange)="updateFilterSearchValue($event)"
        ></app-input-text>
      </div>

      <div class="col-6">
        <app-input-select-single
          [id]="'filterSelect'"
          [style]="style"
          [items]="items"
          [showErrors]="false"
          [theme]="theme"
          (selectedItem)="filterRows($event)"
        ></app-input-select-single>
        <span>
          <app-spinner [loadingBool]="loadingResults"></app-spinner>
        </span>
      </div>

    </div>
  </div>
</div>
