<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Support groups are defined as regular group meetings of PLHIV, caregivers of PLHIV
    and/or other at-risk populations for the purpose of providing ongoing care and support
    to the members; group activities and attendance must be monitored.
  </p>
  <form [formGroup]="supportGroupsForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="peopleReachedOptions"
        [label]="'Who will be reached with the support groups?'"
        [id]="'sg-peopleReached'"
        [name]="'sg-peopleReached'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleReached"
      ></app-input-checkbox>

      <app-input-number
        [label]="' How many support groups will be implemented?'"
        [id]="'sg-numberOfSupportGroups'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfSupportGroups"
      ></app-input-number>

      <app-input-number
        [label]="'How many people will attend each support group?'"
        [id]="'sg-numberOfPeoplePerGroup'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeoplePerGroup"
      ></app-input-number>

      <app-input-select-single
        [label]="'How often will the support groups meet?'"
        [items]="frequencyOfMeetingsOptions"
        [id]="'sg-frequencyOfMeetings'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        [isRequired]="true"
        formControlName="frequencyOfMeetings"
      ></app-input-select-single>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'sg-durationOfMeeting'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfMeeting"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'sg-durationOfMeetingType'"
            [isRequired]="true"
            [name]="'sg-durationOfMeetingType'"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfMeetingType"
          ></app-input-radio>
        </div>
      </div>

      <div>
        <p>How much funding do you need to conduct these support groups?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="supportGroupsForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
