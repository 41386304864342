<div class="wrapper" [class.esx-page]="!fromReview">

  <div *ngIf="!fromReview" class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Capacity Spectrum
              </h4>
              <button
                type="button"
                class="extra-info"
                placement="bottom"
                [ngbPopover]="progressBarInfo"
                [popoverClass]="'popover-width-700-light'"
              >
                <i class="far fa-question-circle fa-lg text-info text-shadow"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div #pageTop class="w-100 mx-auto" [class.esx-content]="!fromReview">
    <div class="h-100 p-1" [class.mt-2]="!fromReview">

      <!--      <h4 class="text-center" *ngIf="fromReview">-->
      <!--        <span class="text-capitalize">Capacity Spectrum</span>-->
      <!--      </h4>-->
      <!--      <hr class="w-75 mx-auto border-primary" *ngIf="fromReview">-->

      <div class="col-12 profile-actions float-right">

        <div class="action-button-right">
          <div class="d-flex justify-content-around">
            <button
              *ngIf="score && capacitySpectrum.submitted"
              type="button"
              class="btn btn-secondary btn-sm"
              placement="bottom-right"
              [ngbPopover]="scoreInfo"
              [popoverClass]="'popover-width-700-light'"
            >
              Score
              <span class="badge badge-light pt-1">
                {{scorePercentageUtil.scoreDTOAsPercentageString(score.totalScore)}} %
              </span>
            </button>
          </div>
          <ng-container *ngIf="!fromReview">
            <ng-container *ngIf="isAdmin; else applicantButtons">
              <div class="d-flex justify-content-around">
                <button
                  [disabled]="isReadOnly"
                  class="btn btn-default btn-primary btn-outline-primary btn-sm"
                  (click)="save()"
                >
                  Save
                </button>
              </div>
              <div class="d-flex justify-content-around">
                <button
                  *ngIf="capacitySpectrum?.submitted"
                  class="btn btn-sm btn-danger btn-outline-dark profile-btn"
                  (click)="cancel()"
                >
                  Cancel
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>

      </div>

      <!--        *ngIf="!fromReview"-->
      <div
        class="col-12 progress-bar-wrapper mx-2"
      >
        <app-capacity-spectrum-progress-bar
          [capacitySpectrumForm]="capacitySpectrumForm"
          [activeChevron]="selection"
          (activeChevronChange)="changeSection($event)"
          class="w-95 mx-auto"
        ></app-capacity-spectrum-progress-bar>
      </div>


      <div class="h-100 my-3">
        <div class="card-body">
          <app-spinner [loadingObs]="isLoading">
            <ng-container *ngIf="fromReview">
              <h4 class="text-center">
                <span class="text-capitalize">{{selection}}</span>
              </h4>
              <hr class="w-75 mx-auto border-primary">
            </ng-container>
            <ng-container *ngIf="capacitySpectrumForm">
              <app-alert
                class="w-100"
                [id]="'capacitySpectrum'"
              ></app-alert>
              <ng-container [ngSwitch]="selection">
                <ng-container *ngSwitchCase="'organizational structure'">
                  <app-cs-organisational-structure
                    [orgStructureForm]="capacitySpectrumForm.controls.organizationalStructure | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-organisational-structure>
                </ng-container>

                <ng-container *ngSwitchCase="'human resources'">
                  <app-cs-human-resources
                    [humanResourcesForm]="capacitySpectrumForm.controls.humanResources | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-human-resources>
                </ng-container>

                <ng-container *ngSwitchCase="'governance'">
                  <app-cs-governance
                    [governanceForm]="capacitySpectrumForm.controls.governance | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-governance>
                </ng-container>

                <ng-container *ngSwitchCase="'financial management'">
                  <app-cs-financial-management
                    [financialManagementForm]="capacitySpectrumForm.controls.financialManagement | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-financial-management>
                </ng-container>

                <ng-container *ngSwitchCase="'financial situation'">
                  <app-cs-financial-situation
                    [financialSituationForm]="capacitySpectrumForm.controls.financialSituation | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-financial-situation>
                </ng-container>

                <ng-container *ngSwitchCase="'mne'">
                  <app-cs-mne
                    [mneForm]="capacitySpectrumForm.controls.mneReportingAndPlanning | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-mne>
                </ng-container>

                <ng-container *ngSwitchCase="'marketing and promotion'">
                  <app-cs-marketing-and-promotion
                    [marketingAndPromotionForm]="capacitySpectrumForm.controls.marketingAndPromotion | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-marketing-and-promotion>
                </ng-container>

                <ng-container *ngSwitchCase="'network and linkages'">
                  <app-cs-network-and-linkages
                    [networkAndLinkagesForm]="capacitySpectrumForm.controls.networkAndLinkages | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-network-and-linkages>
                </ng-container>

                <ng-container *ngSwitchCase="'innovation'">
                  <app-cs-innovation
                    [innovationForm]="capacitySpectrumForm.controls.innovationAndTechnology | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-innovation>
                </ng-container>

                <ng-container *ngSwitchCase="'aspirational'">
                  <app-cs-aspirational
                    [aspirationalForm]="capacitySpectrumForm.controls.aspirational | asFormGroup"
                    [isReadOnly]="isReadOnly"
                    [formSubmitted]="hasSaved"
                    [fromReview]="fromReview"
                    (validate)="checkApplicationValidity()"
                  ></app-cs-aspirational>
                </ng-container>
              </ng-container>
            </ng-container>

            <!--            (isLoading | async) === false && -->
            <div
              *ngIf="capacitySpectrumForm && !capacitySpectrumForm.value.submitted"
              class="w-80 mx-auto"
            >
              <button *ngIf="hasPreviousSection()" class="btn btn-success" (click)="previousSection()">
                << Previous Section
              </button>
              <button *ngIf="hasNextSection()" class="btn btn-success float-right" (click)="nextSection()">
                Next Section >>
              </button>
            </div>
          </app-spinner>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #applicantButtons>
  <div *ngIf="!capacitySpectrumForm?.value?.submitted" class="d-flex justify-content-around">
    <button
      [disabled]="isReadOnly"
      class="btn btn-primary btn-default btn-outline-primary btn-sm"
      (click)="save()"
    >
      Save
    </button>
  </div>
  <div class="d-flex justify-content-around">
    <button
      [disabled]="isReadOnly"
      class="btn btn-primary btn-default btn-outline-primary btn-sm w-100"
      (click)="submit()"
    >
      Save & Submit
    </button>
  </div>
  <div class="d-flex justify-content-around">
    <button
      *ngIf="capacitySpectrum?.submitted"
      class="btn btn-sm btn-danger btn-outline-dark profile-btn"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #scoreInfo>
  <div class="mr-auto">

    <h4 class="text-shadow">
      Score
      <a class="btn btn-link float-right mr-1 p-0 m-0 mt-2 font-link" (click)="download()">
        Download
        <i class="fa fa-download"></i>
      </a>
    </h4>
  </div>

  <table id="report" class="table table-striped table-bordered">
    <thead class="bg-dark text-light">
    <tr>
      <td>Elements</td>
      <td>Status</td>
      <td>Score</td>
      <td>Percentage</td>
    </tr>
    </thead>
    <tbody>
    <!-- https://stackoverflow.com/questions/38582293/how-to-declare-a-variable-in-a-template-in-angular -->
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.organizationalStructure) as status">
      <td>Organisation Structure</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.organizationalStructure?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.organizationalStructure)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.humanResources) as status">
      <td>Human Resources</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.humanResources?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.humanResources)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.governance) as status">
      <td>Governance</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.governance?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.governance)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.financialManagement) as status">
      <td>Financial Management</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.financialManagement?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.financialManagement)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.financialSituation) as status">
      <td>Financial Situation</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.financialSituation?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.financialSituation)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.mne) as status">
      <td>M&E Reporting and Planning</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.mne?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.mne)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.marketingAndPromotion) as status">
      <td>Marketing and Promotion</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.marketingAndPromotion?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.marketingAndPromotion)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.networkAndLinkages) as status">
      <td>Network and Linkages</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.networkAndLinkages?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.networkAndLinkages)}} %</td>
    </tr>
    <tr *ngVar="capacitySpectrumUtil.getStatus(score.innovationAndTechnology) as status">
      <td>Innovation and Technology</td>
      <td>
        <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
      </td>
      <td>{{score.innovationAndTechnology?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.innovationAndTechnology)}} %</td>
    </tr>
    </tbody>
    <tfoot class="bg-dark text-light">
    <tr *ngVar="capacitySpectrumUtil.getStatus  (score.totalScore) as status">
      <td>Total</td>
      <td><span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span></td>
      <td>{{score.totalScore?.score || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.totalScore)}} %</td>
    </tr>
    </tfoot>
  </table>
</ng-template>

<ng-template #progressBarInfo>
  <h4 class="text-center text-shadow">Progress Bar Legend</h4>
  <div class="progress-bar-info">
    <app-chevron
      class="progress-bar-item"
      [title]="{label: 'Not Started', value: 'notStarted'}"
    ></app-chevron>
    <app-chevron
      class="progress-bar-item"
      [isActive]="true"
      [title]="{label: 'Currently active', value: 'active'}"
    ></app-chevron>
    <app-chevron
      class="progress-bar-item"
      [isValid]="false"
      [title]="{label: 'Invalid (Has errors)', value: 'invalid'}"
    ></app-chevron>
    <app-chevron
      class="progress-bar-item"
      [isValid]="true"
      [title]="{label: 'Valid (Has no errors)', value: 'valid'}"
    ></app-chevron>
  </div>
  <div class="text-center">
    <small>
      Please note that you can still save if your form has errors,
      but you will not be able to submit the application until all of the sections are blue.
    </small>
    <small>
      <br>Click on each section to navigate.
    </small>
  </div>
</ng-template>

<ng-template #saveBeforeLeaving>
  <div class="card card-raised">
    <div class="card-header text-center">
      <h3 class="card-title text-uppercase text-danger font-weight-bold">
        Attention!
      </h3>
    </div>
    <div class="card-body text-center">
      <p class="font-weight-normal">You have not saved your Capacity Spectrum.</p>
      <p class="font-weight-normal">Are you sure you want to leave?</p>
    </div>
    <div class="flex-center">
      <button class="btn btn-outline-success mx-3" (click)="stay()">Stay</button>
      <button class="btn btn-outline-danger mx-3" (click)="leaveApplication()">Leave</button>
    </div>
  </div>
</ng-template>

<ng-template #saveModal>
  <div class="w-100 card card-login p-4">
    <div class="card-body">
      <p>
        All of your answers have been saved, but please be aware that we cannot generate your score
        until all mandatory questions have been answered and submitted.
      </p>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-danger btn-lg mr-2" type="button" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>
