import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CapacitySpectrumRoutingModule} from './capacity-spectrum-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from '../../../shared/modules/form-elements/form-elements.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DocumentUploadModule} from '../../../shared/modules/file-upload/document-upload.module';
import {AccordionModule} from '../../../shared/modules/accordion/accordion.module';
import {SharedCommonModule} from '../../../shared/modules/shared-common/shared-common.module';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../../shared/modules/alert/alert.module';
import {CapacitySpectrumComponent} from './components/capacity-spectrum/capacity-spectrum.component';
import {
  CsOrganisationalStructureComponent
} from './components/capacity-spectrum/components/cs-organisational-structure/cs-organisational-structure.component';
import {
  CapacitySpectrumProgressBarComponent
} from './components/capacity-spectrum/components/capacity-spectrum-progress-bar/capacity-spectrum-progress-bar.component';
import {CsHumanResourcesComponent} from './components/capacity-spectrum/components/cs-human-resources/cs-human-resources.component';
import {CsGovernanceComponent} from './components/capacity-spectrum/components/cs-governance/cs-governance.component';
import {
  CsFinancialManagementComponent
} from './components/capacity-spectrum/components/cs-financial-management/cs-financial-management.component';
import {
  CsFinancialSituationComponent
} from './components/capacity-spectrum/components/cs-financial-situation/cs-financial-situation.component';
import {CsMneComponent} from './components/capacity-spectrum/components/cs-mne/cs-mne.component';
import {
  CsMarketingAndPromotionComponent
} from './components/capacity-spectrum/components/cs-marketing-and-promotion/cs-marketing-and-promotion.component';
import {
  CsNetworkAndLinkagesComponent
} from './components/capacity-spectrum/components/cs-network-and-linkages/cs-network-and-linkages.component';
import {CsAspirationalComponent} from './components/capacity-spectrum/components/cs-aspirational/cs-aspirational.component';
import {
  CsSupportingDocumentationComponent
} from './components/capacity-spectrum/components/cs-supporting-documentation/cs-supporting-documentation.component';
import {CsInnovationComponent} from './components/capacity-spectrum/components/cs-innovation/cs-innovation.component';
// tslint:disable-next-line:max-line-length
import {CsFinancialSituationFunderComponent} from './components/capacity-spectrum/components/cs-financial-situation/components/cs-financial-situation-funder/cs-financial-situation-funder.component';
import { CapacitySpectrumResultsComponent } from './components/capacity-spectrum-results/capacity-spectrum-results.component';
import {ChartsModule} from 'ng2-charts';


@NgModule({
  declarations: [
    CapacitySpectrumComponent,
    CsOrganisationalStructureComponent,
    CapacitySpectrumProgressBarComponent,
    CsHumanResourcesComponent,
    CsGovernanceComponent,
    CsFinancialManagementComponent,
    CsFinancialSituationComponent,
    CsMneComponent,
    CsMarketingAndPromotionComponent,
    CsNetworkAndLinkagesComponent,
    CsAspirationalComponent,
    CsSupportingDocumentationComponent,
    CsInnovationComponent,
    CsFinancialSituationFunderComponent,
    CapacitySpectrumResultsComponent
  ],
  exports: [
    CapacitySpectrumComponent,
    CsOrganisationalStructureComponent,
    CapacitySpectrumProgressBarComponent,
    CsHumanResourcesComponent,
    CsGovernanceComponent,
    CsFinancialManagementComponent,
    CsFinancialSituationComponent,
    CsMneComponent,
    CsMarketingAndPromotionComponent,
    CsNetworkAndLinkagesComponent,
    CsAspirationalComponent,
    CsSupportingDocumentationComponent
  ],
  imports: [
    CommonModule,
    FormElementsModule,
    CapacitySpectrumRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DocumentUploadModule,
    AccordionModule,
    SharedCommonModule,
    ToastContainerModule,
    AlertModule,
    ChartsModule,
  ]
})
export class CapacitySpectrumModule {
}
