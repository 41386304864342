import {Injectable} from '@angular/core';
import {ObjectiveStore} from './objective.store';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Objective} from './objective.model';
import {environment} from '../../../../../../../../../environments/environment';
import {WorkPlanAndTargetsService} from '../../../../../../store/work-plan-and-targets.service';

@Injectable({providedIn: 'root'})
export class ObjectiveService {

  private url = `${environment.apiUrl}/objective`;

  constructor(protected store: ObjectiveStore,
              private http: HttpClient,
              private workPlanAndTargetsService: WorkPlanAndTargetsService) {
  }

  // getAll(workPlanId: number): Observable<Objective[]> {
  //   return this.http.get<Objective[]>(`${this.url}/work-plan/${workPlanId}`).pipe(
  //     tap(list => {
  //       this.store.set(list);
  //     })
  //   );
  // }

  create(objective: Objective) {
    return this.http.post(this.url, objective).pipe(
      tap(response => {
        this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(objective.workPlan.workflow.id).subscribe();
      })
    );
  }

  update(objective: Objective) {
    return this.http.put(`${this.url}/${objective.id}`, objective).pipe(
      tap(() => {
        this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(objective.workPlan.workflow.id).subscribe();
      })
    );
  }

  delete(objective: Objective) {
    return this.http.delete(`${this.url}/${objective.id}`).pipe(
      tap(() => {
        this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(objective.workPlan.workflow.id).subscribe();
      })
    );
  }
}
