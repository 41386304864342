<div class="w-100">
  <ng-container *authenticatedWithoutRole="['ROLE_APPLICANT']">
    <app-edit-objective [objective]="selectedObjective"></app-edit-objective>
  </ng-container>

  <app-input-text-disabled
    class="w-45"
    [label]="'Name'"
    [id]="'name'"
    [style]="'default'"
    [theme]="'light'"
    [hasBorder]="false"
    [isGrey]="false"
    [value]="selectedObjective.name"
  ></app-input-text-disabled>

  <app-input-textarea-disabled
    class="w-45"
    [label]="'Description'"
    [id]="'description'"
    [style]="'default'"
    [theme]="'light'"
    [hasBorder]="false"
    [isGrey]="false"
    [value]="selectedObjective.description"
  ></app-input-textarea-disabled>

  <app-input-textarea-disabled
    class="w-45"
    [label]="'Additional Description'"
    [id]="'additionalDescription'"
    [style]="'default'"
    [theme]="'light'"
    [hasBorder]="false"
    [isGrey]="false"
    [value]="selectedObjective.additionalDescription"
  ></app-input-textarea-disabled>

</div>


<app-activity [selectedObjective]="selectedObjective"></app-activity>
