import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';
import {FormBuilderService} from './store/form-builder.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {createFormBuilderDTO, createFormBuilderForm, FormBuilderDTO, FormBuilderModel} from './store/form-builder.model';
import {Column} from '../../../../../shared/modules/data-table/models/mbl-table.model';
import {choiceInputItems, styleChoices, themeChoices} from '../../../store/dynamic-form.model';
import {booleanChoices} from '../../../../constants/boolean-answers.const';
import {FormBuilderQuery} from './store/form-builder.query';
import {FormBuilderQuestionService} from './components/form-builder-question/store/form-builder-question.service';
import {FormBuilderQuestionDTO} from './components/form-builder-question/store/form-builder-question.model';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../../../../shared/modules/shared-common/services/modal/modal.service';
import {FormBuilderQuestionComponent} from './components/form-builder-question/form-builder-question.component';
import {SessionQuery} from '../../../../../shared/stores/session/session.query';
import {take} from 'rxjs/operators';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {TruncateUtil} from '../../../../../shared/utils/truncate-util';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnDestroy {

  @ViewChild('requiredTmpl', {static: true}) requiredTmpl: TemplateRef<any>;
  @ViewChild('controlTypeTmpl', {static: true}) controlTypeTmpl: TemplateRef<any>;
  @ViewChild('actionTmpl', {static: true}) actionTmpl: TemplateRef<any>;

  formId: number;
  loading = true;
  formBuilderSubscription: Subscription;
  formBuilderForm: FormGroup;
  formEntity: FormBuilderDTO;
  isEditable = true;

  columns: Column[];

  themeChoices: ChoiceInputItem[] = themeChoices;
  styleChoices: ChoiceInputItem[] = styleChoices;
  booleanChoices: ChoiceInputItem[] = booleanChoices;
  controlTypeChoices: ChoiceInputItem[] = choiceInputItems;

  activeSubscription: Subscription;
  componentInstanceSubscription: Subscription;

  options: NgbModalOptions;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalService: ModalService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private formBuilderService: FormBuilderService,
              private formBuilderQuestionService: FormBuilderQuestionService,
              private formBuilderQuery: FormBuilderQuery,
              private sessionQuery: SessionQuery) {
  }

  ngOnInit() {
    // this.formBuilderForm = createFormBuilderForm(this.formBuilder, {});
    const id = this.route.snapshot.params.formBuilderId;
    if (id !== 'new') {
      this.formId = Number(id);
    }
    this.populateColumns();

    this.options = {
      // ariaLabelledBy: `${(this.isCreating ? 'Add' : 'Edit')} Question`,
      centered: true,
      size: 'xl',
      scrollable: true,
    };

    this.fetch();
  }

  ngOnDestroy() {
    if (this.formBuilderSubscription) {
      this.formBuilderSubscription.unsubscribe();
    }
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }
    if (this.componentInstanceSubscription) {
      this.componentInstanceSubscription.unsubscribe();
    }
  }

  private populateColumns() {
    this.columns = [
      {name: 'Order', prop: 'order', width: '15%', sort: true},
      {name: 'Key/Id', prop: 'key', width: '15%', sort: true},
      {name: 'Label', prop: 'label', width: '15%', sort: true},
      {name: 'Mandatory', prop: 'required', width: '15%', sort: true, cellTemplate: this.requiredTmpl},
      {name: 'Type', prop: 'controlType', width: '15%', cellTemplate: this.controlTypeTmpl},
      {name: 'Action', prop: 'action', width: '5%', cellTemplate: this.actionTmpl},
    ];
  }

  private fetch() {
    this.loading = true;

    if (this.formId) {
      this.activeSubscription = this.formBuilderQuery.getActiveObservable().subscribe(item => {
        if (item) {
          if (this.formBuilderForm) {
            this.formBuilderForm.patchValue(item);
          } else {
            this.formBuilderForm = createFormBuilderForm(this.formBuilder, item);
          }
          this.formEntity = item;
          this.isEditable = item.isEditable;
        } else {
          const params = this.getParams();
          if (!this.formBuilderForm) {
            this.formBuilderForm = createFormBuilderForm(this.formBuilder, params);
          }
          this.formEntity = createFormBuilderDTO(params);
        }
      }, error => {
        this.alertService.setAlert('Failed to fetch Form', 'error', 'formBuilder');
      });

      this.formBuilderSubscription = this.formBuilderService.getOne(this.formId)
        .pipe(take(1)).subscribe(() => {
          this.loading = false;
        }, error => {
          this.loading = false;
          this.alertService.setAlert(`Failed to get form with id ${this.formId}`, 'error', 'formBuilder');
        });
    } else {
      const params = this.getParams();
      this.formBuilderForm = createFormBuilderForm(this.formBuilder, params);
      this.formEntity = createFormBuilderDTO(params);
      this.loading = false;
    }
  }

  save(formBuilderForm: FormGroup) {
    this.loading = true;
    if (formBuilderForm.valid) {
      if (this.formId) {
        this.update(formBuilderForm.value);
      } else {
        this.create(formBuilderForm.value);
      }
    } else {
      this.alertService.setAlert('Please complete all fields', 'error', 'formBuilder');
    }
  }

  private update(value: FormBuilderModel) {
    this.formBuilderService.update(value).subscribe(() => {
      this.loading = false;
      this.fetch();
    }, error => {
      this.alertService.setAlert('Failed to update Form', 'error', 'formBuilder');
    });
  }

  private create(value: FormBuilderModel) {
    this.formBuilderService.create(value).subscribe(response => {
      this.loading = false;
      this.formId = response.id;
      this.fetch();
      this.router.navigateByUrl(`/form-builder/details/${response.id}`).then();
    }, error => {
      this.alertService.setAlert('Failed to create Form', 'error', 'formBuilder');
    });
  }

  delete(row) {
    this.formBuilderQuestionService.delete(row.id).subscribe(() => {
        this.alertService.setAlert('Deleted...', 'success', 'formBuilder');
      },
      error => {
        const msg = error && error.error && error.error.message || 'Failed to delete.';
        this.alertService.setAlert(TruncateUtil.truncate(msg, 100), 'error', 'formBuilder');
      });
  }

  openModal(row: FormBuilderQuestionDTO, isCreating: boolean) {
    const order = this.generateNewOrder();
    const modalRef = this.modalService.openStackedModal(FormBuilderQuestionComponent, this.options, [
      {key: 'questionDTO', value: row},
      {key: 'isCreating', value: isCreating},
      {key: 'isEditable', value: this.isEditable},
      {key: 'formId', value: this.formEntity.id},
      {key: 'order', value: order},
    ]);

    // refresh is the @Output
    this.componentInstanceSubscription = modalRef.componentInstance.refresh.subscribe(() => {
      this.fetch();
    });

    modalRef.result.then(reason => {
      // This runs when the modal is closed with the close() function
      if (reason === 'saved') {
        this.fetch();
      } else {
        this.fetch();
      }
    }).catch(() => {
      // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
    });
  }

  private getParams() {
    const funderId = this.sessionQuery.getUser().funderId;
    let params = {};
    if (funderId) {
      params = {
        funder: {id: funderId}
      };
    }
    return params;
  }

  private generateNewOrder() {
    // generate the 'order' and pass it in here
    const questions = this.formEntity.questions;
    if (questions && questions.length) {
      return questions[questions.length - 1].order + 1;
    } else {
      return 0;
    }
  }
}
