<div class="wrapper">
  <div class="page-header page-header-small bg-secondary">
    <div class="rellax-header" data-rellax-speed="-8">
    </div>
    <div class="content-center">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto">
          <h1 class="title text-danger">404</h1>
          <h4>Page Not Found</h4>
        </div>
      </div>
    </div>
  </div>
</div>
