import {FormBuilder, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormBuilderModel} from '../../../store/form-builder.model';
import {QuestionControlType} from '../../../../../../store/question-base.model';
import {createFormBuilderChoiceList, FormBuilderChoice} from '../components/form-builder-choice/store/form-builder-choice.model';

export interface FormBuilderQuestion extends BaseEntity {
  dynamicForm: FormBuilderModel;
  value: string;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: QuestionControlType;
}

export function createFormBuilderQuestion(params: Partial<FormBuilderQuestion>) {
  return Object.assign({}, createBaseEntity(params), {
    dynamicForm: params.dynamicForm,
    value: params.value,
    key: params.key,
    label: params.label,
    required: params.required,
    order: params.order,
    controlType: params.controlType,
  }) as FormBuilderQuestion;
}

export function createFormBuilderQuestionForm(formBuilder: FormBuilder, params: Partial<FormBuilderQuestionDTO>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    value: [params && params.value],
    key: [params && params.key, Validators.required],
    label: [params && params.label, Validators.required],
    required: [params && params.required, Validators.required],
    order: [params && params.order, Validators.required],
    controlType: [params && params.controlType || QuestionControlType.textbox, Validators.required],
  }));
}

export interface FormBuilderQuestionDTO extends BaseEntity {
  value: any;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string | QuestionControlType;
  options: FormBuilderChoice[];
}

export function createFormBuilderQuestionDTOList(params: Partial<FormBuilderQuestionDTO>[]) {
  const list: FormBuilderQuestionDTO[] = [];
  if (params && params.length) {
    params = params.sort((a, b) => a.order - b.order);
    for (const param of params) {
      list.push(createFormBuilderQuestionDTO(param));
    }
  }
  return list;
}

export function createFormBuilderQuestionDTO(params: Partial<FormBuilderQuestionDTO>) {
  return Object.assign({}, createBaseEntity(params), {
    value: params.value,
    key: params.key,
    label: params.label,
    required: params.required,
    order: params.order,
    controlType: QuestionControlType[params.controlType],
    options: createFormBuilderChoiceList(params.options),
  }) as FormBuilderQuestionDTO;
}
