import {FormGroup} from '@angular/forms';

export function validateFormGroup(formGroup: FormGroup): boolean {
  let valid = true;
  if (formGroup && formGroup.controls) {
    (Object as any).values(formGroup.controls).forEach(control => {
      if ((control as FormGroup).controls) {
        validateFormGroup(control as FormGroup);
      }

      if (Array.isArray(control.value) && control.value.length > 0 && (control.value[0] as FormGroup).controls) {
        let arrayValid = true;
        control.value.forEach(item => {
          const itemValid = validateFormGroup(item as FormGroup);
          if (itemValid === false) {
            arrayValid = false;
          }
        });
        if (!arrayValid) {
          valid = false;
        }
      }

      if (control.status === 'INVALID') {
        valid = control.valid;
      }
    });
  }
  return valid;
}

/**
 * Marks all controls in a FormGroup as touched
 * ==> method markAllAsTouched : Available in angular 8 or 8+ version in FormGroup.
 * @param {FormGroup} formGroup - The form group to touch
 * @public
 */
export function markAllAsTouched(formGroup: FormGroup): void {
  (Object as any).values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();
    if (control.controls) {
      markAllAsTouched(control);
    }
  });
}

export function removeUnderscore(value: string): string {
  let valArray = value.split('_');
  valArray = valArray.map((item, index) => {
    if (index === 0) {
      return item;
    }
    const firstLetter = item.split('')[0].toUpperCase();
    const restOfWord = item.slice(1, valArray[1].length);
    item = firstLetter + restOfWord;
    return item;
  });
  return valArray.join('');
}
