import {Pipe, PipeTransform} from '@angular/core';
import {ChoiceInputItem} from '../../../form-elements/models/form-elements.model';

@Pipe({
  name: 'singleChoiceInputType'
})
export class SingleChoiceInputTypePipe implements PipeTransform {

  transform(value: string, choices: ChoiceInputItem[]): string {
    return this.getTypeByValue(value, choices);
  }

  getTypeByValue(code: string, choiceInputItem: ChoiceInputItem[]): string {
    const found = choiceInputItem.find(item => item.value === code);
    if (found) {
      return found.label;
    }

    return 'Unknown';
  }

}
