<nav class="navbar navbar-expand-lg bg-primary sticky-top">
  <div class="navbar-translate">
    <button *authenticatedWithRole="['Any']" class="btn btn-link mr-3" (click)="toggleSidebar($event)">
      <span class="fa fa-bars fa-2x text-light"></span>
    </button>

    <a class="navbar-brand" routerLink="/landing" *authenticatedWithRole="['Any']">
      <img
        alt="logo"
        src="../../../../../../assets/img/solinta/SoCapX No_Logo_Vector(White_Fill) .png"
        height="60"
      >
    </a>
  </div>

  <div class="nav-container">
    <div class="collapse navbar-collapse" data-nav-image="assets/img/blurred-image-1.jpg" data-color="orange">
      <ul class="navbar-nav ml-auto flex-align-center">


        <ng-container *ngIf="sessionQuery.getUserObservable() | async as user">

          <ng-container *authenticatedWithRole="['ROLE_ADMIN']">
            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/dashboard" routerLinkActive="active">
                <i class="fas fa-2x fa-home mr-1"></i>
              </a>
            </li>
          </ng-container>
          <ng-container *authenticatedWithRole="['ROLE_FUNDER']">
            <li class="nav-item" *ngIf="user.funderId && user.isSimSubmitted">
              <a class="nav-link" routerLink="/investor/dashboard" routerLinkActive="active">
                <i class="fas fa-2x fa-home mr-1"></i>
              </a>
            </li>
          </ng-container>
          <ng-container *authenticatedWithRole="['ROLE_APPLICANT']">
            <li class="nav-item"
                *ngIf="user.organisationId && user.isCapacitySpectrumSubmitted && user.serviceMatrixId">
              <a class="nav-link" routerLink="/implementer/dashboard" routerLinkActive="active">
                <i class="fas fa-2x fa-home mr-1"></i>
              </a>
            </li>
          </ng-container>

          <p class="text-light mr-1">|</p>

          <li class="nav-item">
            <a class="nav-link disabled" disabled routerLinkActive="active">
              <p>Directories</p>
            </a>
          </li>

          <p class="text-light mr-1">|</p>

          <li class="nav-item">
            <div class="dropdown btn-group">
              <a ngbDropdown placement="bottom-right" class="nav-item dropdown">
                <div class="nav-link dropdown-toggle cursor-pointer" ngbDropdownToggle>
                  <p>Resources</p>
                </div>

                <div ngbDropdownMenu class="dropdown-menu bg-dark text-light text-center">

                  <a class="dropdown-item cursor-pointer text-light disabled" disabled>
                    <p>Funding</p>
                  </a>

                  <a class="dropdown-item cursor-pointer text-light disabled" disabled>
                    <p>Knowledge Base</p>
                  </a>

                </div>

              </a>
            </div>
          </li>

          <p class="text-light mr-1">|</p>

          <li class="nav-item">
            <a class="nav-link disabled" disabled routerLinkActive="active">
              <p>Events</p>
            </a>
          </li>

          <li class="nav-item">
            <div class="dropdown btn-group">
              <a ngbDropdown placement="bottom" class="nav-item dropdown">
                <div class="nav-link dropdown-toggle notification-toggle cursor-pointer" ngbDropdownToggle>
                  <i class="fas fa-2x fa-bell"></i>
                </div>
                <div ngbDropdownMenu class="dropdown-menu bg-dark text-light text-center">
                  <p>Coming Soon!</p>
                </div>
              </a>
            </div>
          </li>
        </ng-container>

        <li class="nav-item" *authenticatedWithRole="['Unauthenticated']">
          <a routerLink="/authentication/login">
            <button class="nav-link btn btn-primary btn-outline-secondary">
              Login
            </button>
          </a>
        </li>

        <li class="nav-item" *authenticatedWithRole="['Unauthenticated']">
          <a routerLink="/authentication/register">
            <button class="nav-link btn btn-primary btn-outline-light">
              Register
            </button>
          </a>
        </li>

        <li *authenticatedWithRole="['Any']" class="ml-1">
          <app-user-navigation></app-user-navigation>
        </li>
      </ul>
    </div>
  </div>
</nav>
