import {Component, Input, OnInit} from '@angular/core';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {FundingRequestBudget, createFundingRequestBudget} from '../../model/funding-request.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-funding-request-budget',
  templateUrl: './funding-request-budget.component.html',
  styleUrls: ['./funding-request-budget.component.scss']
})
export class FundingRequestBudgetComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() budgetRequestForm: FormGroup;
  @Input() formValid: boolean;
  @Input() isTraining = false;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  numberMask: any;

  constructor() {
  }

  ngOnInit() {
    this.numberMask = createNumberMask({
      prefix: '',
      allowDecimal: true,
    });
  }

}
