import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {QuestionService} from '../../store/question.service';
import {FormBuilderModel} from '../form-builder-list/form-builder/store/form-builder.model';
import {Column} from '../../../../shared/modules/data-table/models/mbl-table.model';
import {MblTableComponent} from '../../../../shared/modules/data-table/components/mbl-table/mbl-table.component';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-form-history',
  templateUrl: './form-history.component.html',
  styleUrls: ['./form-history.component.scss']
})
export class FormHistoryComponent implements OnInit {

  @ViewChild('table', {static: true}) table: MblTableComponent;
  @ViewChild('funderTmpl', {static: true}) funderTmpl: TemplateRef<any>;
  @ViewChild('dateChangedTmpl', {static: true}) dateChangedTmpl: TemplateRef<any>;

  formBuilderId: number;
  loading: boolean;

  rows: FormBuilderModel[];
  cachedRows: FormBuilderModel[];
  columns: Column[];

  constructor(private route: ActivatedRoute,
              public sessionQuery: SessionQuery,
              private service: QuestionService) { }

  ngOnInit() {
    this.formBuilderId = this.route.snapshot.params.formBuilderId;
    this.populateColumns();
    this.fetch();
  }

  private fetch() {
    this.loading = true;
    this.service.getAuditLog(this.formBuilderId).pipe(take(1)).subscribe(items => {
      this.loading = false;
      console.log('Items -> ', items);
      this.rows = items;
      this.cachedRows = [...this.rows];
    });
  }

  private populateColumns() {
    this.columns = [
      {name: 'Form Name', prop: 'name', width: '30%', sort: true},
      {name: 'Changed By', prop: 'modifiedBy', width: '20%', sort: true},
      {name: 'Date of Change', prop: 'modifiedOn', cellTemplate: this.dateChangedTmpl, width: '20%', sort: true},
    ];

    const isAdmin = this.sessionQuery.hasRole(['ROLE_ADMIN']);
    if (isAdmin) {
      const funderColumn = {name: 'Owner', prop: 'funder.id', cellTemplate: this.funderTmpl, width: '30%', sort: true};
      this.columns.splice(1, 0, funderColumn);
    }
  }
}
