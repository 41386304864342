import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../shared/utils/form-validation-util';
import {takeUntil} from 'rxjs/operators';

export interface ContactDetails extends BaseEntity {
  contactNo?: string;
  mobileNo?: string;
  address1?: string;
  address2?: string;
  email?: string;
  website?: string;
  provinceCode?: string;
  districtCode?: string;
  subDistrictCode?: string;
  city?: string;
  postalCode?: string;
  latitude?: string;
  longitude?: string;
}

export interface Person extends BaseEntity {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  position?: string;
  contactDetails?: ContactDetails;
}

export interface Organisation extends BaseEntity {
  legalName: string;
  tradingName: string;
  code: string;
  inceptionDate: any;
  regNumber: string;
  regDate: any;
  // dunsNumber: string;
  categories: Array<string>;
  organisationType: Array<string>;
  orgContact?: ContactDetails;
  primaryContact?: Person;
  secondaryContact?: Person;
  hasConsented: boolean;
}

export function createOrganisation(params: Partial<Organisation>) {
  return Object.assign({}, createBaseEntity(params), {
    legalName: params && params.legalName,
    tradingName: params && params.tradingName,
    code: params && params.code,
    inceptionDate: params && params.inceptionDate,
    regNumber: params && params.regNumber,
    regDate: params && params.regDate,
    // dunsNumber: params && params.dunsNumber,
    categories: params && params.categories,
    organisationType: params && params.organisationType,
    hasConsented: params && params.hasConsented,
    orgContact: createContactDetails(params && params.orgContact),
    primaryContact: createPerson(params && params.primaryContact),
    secondaryContact: createPerson(params && params.secondaryContact)
  }) as Organisation;
}

export function createPerson(params?: Partial<Person>) {
  return Object.assign({}, createBaseEntity(params), {
    firstName: params && params.firstName,
    middleName: params && params.middleName,
    lastName: params && params.lastName,
    position: params && params.position,
    contactDetails: createContactDetails(params && params.contactDetails)
  }) as Person;
}

export function createContactDetails(params?: Partial<ContactDetails>) {
  return Object.assign({}, createBaseEntity(params), {
    contactNo: params && params.contactNo,
    mobileNo: params && params.mobileNo,
    address1: params && params.address1,
    address2: params && params.address2,
    email: params && params.email,
    website: params && params.website,
    provinceCode: params && params.provinceCode,
    districtCode: params && params.districtCode,
    subDistrictCode: params && params.subDistrictCode,
    city: params && params.city,
    postalCode: params && params.postalCode,
    latitude: params && params.latitude,
    longitude: params && params.longitude
  }) as ContactDetails;
}

export function createOrganisationForm(destroy$, formBuilder: FormBuilder, params?: Partial<Organisation>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    legalName: [params && params.legalName, Validators.required],
    tradingName: [params && params.tradingName],
    code: [params && params.code],
    inceptionDate: [params && params.inceptionDate, Validators.required],
    hasConsented: [params && params.hasConsented, Validators.required],
    regNumber: [params && params.regNumber,
      FormValidationUtil.requiredIfValidator(() => {
        const type = formGroup.get('organisationType').value;
        return type.includes('NPO') || type.includes('npoSection21') || type.includes('earlyChildhood');
      })
    ],
    regDate: [params && params.regDate,
      FormValidationUtil.requiredIfValidator(() => {
        const type = formGroup.get('organisationType').value;
        return type.includes('NPO') || type.includes('npoSection21') || type.includes('earlyChildhood');
      })
    ],
    // dunsNumber: [params && params.dunsNumber || ''],
    // categories: [params && params.categories || [], Validators.required],
    organisationType: [params && params.organisationType || [], Validators.required],
    orgContact: formBuilder.group(Object.assign({}, createBaseEntityForm(params.orgContact), {
      contactNo: [params && params.orgContact && params.orgContact.contactNo || '', Validators.required],
      address1: [params && params.orgContact && params.orgContact.address1 || '', Validators.required],
      address2: [params && params.orgContact && params.orgContact.address2 || ''],
      email: [params && params.orgContact && params.orgContact.email || '',
        Validators.required
        // Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{3,}')
      ],
      website: [params && params.orgContact && params.orgContact.website || ''],
      provinceCode: [params && params.orgContact && params.orgContact.provinceCode || '', Validators.required],
      districtCode: [params && params.orgContact && params.orgContact.districtCode || '', Validators.required],
      subDistrictCode: [params && params.orgContact && params.orgContact.subDistrictCode || '', Validators.required],
      city: [params && params.orgContact && params.orgContact.city || '', Validators.required],
      postalCode: [params && params.orgContact && params.orgContact.postalCode || '', Validators.required],
    })),
    primaryContact: formBuilder.group(Object.assign({}, createBaseEntityForm(params.primaryContact), {
      firstName: [params && params.primaryContact && params.primaryContact.firstName || '', Validators.required],
      middleName: [params && params.primaryContact && params.primaryContact.middleName || ''],
      lastName: [params && params.primaryContact && params.primaryContact.lastName || '', Validators.required],
      position: [params && params.primaryContact && params.primaryContact.position || '', Validators.required],
      contactDetails: formBuilder.group(Object.assign({}, createBaseEntityForm(params.primaryContact.contactDetails), {
        contactNo: [params && params.primaryContact && params.primaryContact.contactDetails
        && params.primaryContact.contactDetails.contactNo || '', Validators.required],
        mobileNo: [params && params.primaryContact && params.primaryContact.contactDetails
        && params.primaryContact.contactDetails.mobileNo || '', Validators.required],
        email: [
          params && params.primaryContact && params.primaryContact.contactDetails && params.primaryContact.contactDetails.email || '',
          Validators.required
          // Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{3,}')
        ],
      }))
    })),
    secondaryContact: formBuilder.group(Object.assign({}, createBaseEntityForm(params.secondaryContact), {
      firstName: [params && params.secondaryContact && params.secondaryContact.firstName || '', Validators.required],
      middleName: [params && params.secondaryContact && params.secondaryContact.middleName || ''],
      lastName: [params && params.secondaryContact && params.secondaryContact.lastName || '', Validators.required],
      position: [params && params.secondaryContact && params.secondaryContact.position || '', Validators.required],
      contactDetails: formBuilder.group(Object.assign({}, createBaseEntityForm(params.secondaryContact.contactDetails), {
        contactNo: [params && params.secondaryContact && params.secondaryContact.contactDetails
        && params.secondaryContact.contactDetails.contactNo || '', Validators.required],
        mobileNo: [params && params.secondaryContact && params.secondaryContact.contactDetails
        && params.secondaryContact.contactDetails.mobileNo || '', Validators.required],
        email: [
          params && params.secondaryContact && params.secondaryContact.contactDetails && params.secondaryContact.contactDetails.email || '',
          Validators.required
          // Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{3,}')
        ],
      }))
    }))
  }));

  formGroup.get('organisationType').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('regNumber').updateValueAndValidity();
      formGroup.get('regDate').updateValueAndValidity();
    });

  return formGroup;
}
