import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkPlanStatusBadgeUtil {
  getClass(value: string): string {
    switch (value) {
      case 'ACTIVE':
        return 'badge-warning';
      case 'WITH_APPLICANT':
        return 'badge-secondary';
      case 'SUBMITTED':
        return 'badge-primary';
      case 'APPROVED':
        return 'badge-success';
    }
  }
}

