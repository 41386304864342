import {ChoiceInputItem} from '../../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const paymentEvidenceAnswers: Array<ChoiceInputItem> = [
  {
    label: 'EFT proof of payment',
    value: 'EFT_PROOF_OF_PAYMENT'
  },

  {
    label: 'Original receipt',
    value: 'ORIGINAL_RECEIPT'
  },
  {
    label: 'Invoice',
    value: 'INVOICE'
  },
  {
    label: 'Pay slip/salary payment records',
    value: 'PAY_SLIP'
  },
  {
    label: 'Transport log',
    value: 'TRANSPORT_LOG'
  },
  {
    label: 'Bank statements',
    value: 'BANK_STATEMENTS'
  },
  {
    label: 'Signed attendance registers',
    value: 'SIGNED_ATTENDANCE_REGISTERS'
  },
  {
    label: 'Reimbursement forms',
    value: 'REIMBURSEMENT_FORMS'
  },
  {
    label: 'Petty cash slips',
    value: 'PETTY_CASH_SLIPS'
  },
  {
    label: 'Other',
    value: 'OTHER'
  },
];


export const budgetCategoryAnswers: Array<ChoiceInputItem> = [
  {
    label: 'Training',
    value: 'TRAINING'
  },
  {
    label: 'Supplies & Material',
    value: 'SUPPLIES_MATERIAL'
  },
  {
    label: 'Equipment & Furniture',
    value: 'EQUIPMENT_FURNITURE'
  },
  {
    label: 'Personnel',
    value: 'FACILITATION'
  },
  {
    label: 'Travel & Transport',
    value: 'TRAVEL_TRANSPORT'
  },
  {
    label: 'Administration',
    value: 'ADMINISTRATION'
  },
];
