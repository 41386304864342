import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsInnovationAnswerService} from './services/cs-innovation-answer.service';
import {take} from 'rxjs/operators';
import {booleanChoices} from '../../../../../../constants/boolean-answers.const';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-innovation',
  templateUrl: './cs-innovation.component.html',
  styleUrls: ['./cs-innovation.component.scss']
})
export class CsInnovationComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() innovationForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  // Question Answers.
  computerAccessAnswers = [];
  internetAccessAnswers = [];
  internetConnectionAnswers = [];
  itEquipmentAnswers = [];
  systemSupportAnswers = [];
  itPolicyAnswers = [];
  popiPolicyAnswers = [];
  softwareAnswers = [];
  customSoftwareAnswers = [];
  techTrainingAnswers = [];
  managementSkillsAnswers = [];
  adminSkillsAnswers = [];
  generalStaffSkillsAnswers = [];
  booleanChoices = booleanChoices;

  computerAccessSubscription: Subscription;
  internetAccessSubscription: Subscription;
  internetConnectionSubscription: Subscription;
  itEquipmentSubscription: Subscription;
  systemSupportSubscription: Subscription;
  itPolicySubscription: Subscription;
  popiPolicySubscription: Subscription;
  softwareSubscription: Subscription;
  customSoftwareSubscription: Subscription;
  techTrainingSubscription: Subscription;
  managementSkillsSubscription: Subscription;
  adminSkillsSubscription: Subscription;
  generalStaffSkillsSubscription: Subscription;
  childFormTouched: boolean;

  constructor(private loadingService: LoadingService,
              private csInnovationAnswerService: CsInnovationAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.innovationForm.touched;

    this.populateAnswers();
  }

  public checkOther(value: string, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy(): void {
    if (this.computerAccessSubscription) {
      this.computerAccessSubscription.unsubscribe();
    }

    if (this.internetAccessSubscription) {
      this.internetAccessSubscription.unsubscribe();
    }

    if (this.internetConnectionSubscription) {
      this.internetConnectionSubscription.unsubscribe();
    }

    if (this.itEquipmentSubscription) {
      this.itEquipmentSubscription.unsubscribe();
    }

    if (this.systemSupportSubscription) {
      this.systemSupportSubscription.unsubscribe();
    }

    if (this.itPolicySubscription) {
      this.itPolicySubscription.unsubscribe();
    }

    if (this.popiPolicySubscription) {
      this.popiPolicySubscription.unsubscribe();
    }

    if (this.softwareSubscription) {
      this.softwareSubscription.unsubscribe();
    }

    if (this.customSoftwareSubscription) {
      this.customSoftwareSubscription.unsubscribe();
    }

    if (this.techTrainingSubscription) {
      this.techTrainingSubscription.unsubscribe();
    }

    if (this.managementSkillsSubscription) {
      this.managementSkillsSubscription.unsubscribe();
    }

    if (this.adminSkillsSubscription) {
      this.adminSkillsSubscription.unsubscribe();
    }

    if (this.generalStaffSkillsSubscription) {
      this.generalStaffSkillsSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csInnovationAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.computerAccessSubscription = this.csInnovationAnswerService.$computerAccess.subscribe(answers => {
      if (answers) {
        this.computerAccessAnswers = answers.choices;
      }
    });

    this.internetAccessSubscription = this.csInnovationAnswerService.$internetAccess.subscribe(answers => {
      if (answers) {
        this.internetAccessAnswers = answers.choices;
      }
    });

    this.internetConnectionSubscription = this.csInnovationAnswerService.$internetConnection.subscribe(answers => {
      if (answers) {
        this.internetConnectionAnswers = answers.choices;
      }
    });

    this.itEquipmentSubscription = this.csInnovationAnswerService.$itEquipment.subscribe(answers => {
      if (answers) {
        this.itEquipmentAnswers = answers.choices;
      }
    });

    this.systemSupportSubscription = this.csInnovationAnswerService.$systemSupport.subscribe(answers => {
      if (answers) {
        this.systemSupportAnswers = answers.choices;
      }
    });

    this.itPolicySubscription = this.csInnovationAnswerService.$itPolicy.subscribe(answers => {
      if (answers) {
        this.itPolicyAnswers = answers.choices;
      }
    });

    this.popiPolicySubscription = this.csInnovationAnswerService.$popiPolicy.subscribe(answers => {
      if (answers) {
        this.popiPolicyAnswers = answers.choices;
      }
    });

    this.softwareSubscription = this.csInnovationAnswerService.$software.subscribe(answers => {
      if (answers) {
        this.softwareAnswers = answers.choices;
      }
    });

    this.customSoftwareSubscription = this.csInnovationAnswerService.$customSoftware.subscribe(answers => {
      if (answers) {
        this.customSoftwareAnswers = answers.choices;
      }
    });

    this.techTrainingSubscription = this.csInnovationAnswerService.$techTraining.subscribe(answers => {
      if (answers) {
        this.techTrainingAnswers = answers.choices;
      }
    });

    this.managementSkillsSubscription = this.csInnovationAnswerService.$managementSkills.subscribe(answers => {
      if (answers) {
        this.managementSkillsAnswers = answers.choices;
      }
    });

    this.adminSkillsSubscription = this.csInnovationAnswerService.$adminSkills.subscribe(answers => {
      if (answers) {
        this.adminSkillsAnswers = answers.choices;
      }
    });

    this.generalStaffSkillsSubscription = this.csInnovationAnswerService.$generalStaffSkills.subscribe(answers => {
      if (answers) {
        this.generalStaffSkillsAnswers = answers.choices;
      }
    });
  }

}
