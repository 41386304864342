<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Camps are defined as strategically planned outings which target youth PLHIV and/or
    other high-risk youth for the purpose of disclosure and/or life skills development.
  </p>
  <form [formGroup]="campsForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="typeOfCampsOptions"
        [label]="'What type(s) of camp will you run?'"
        [id]="'camps-typeOfCamps'"
        [name]="'camps-typeOfCamps'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="typeOfCamps"
        (selected)="checkOther('other', $event, campsForm.controls.typeOfCamps, campsForm.controls.typeOfCampsOther)"
      ></app-input-checkbox>

      <div *ngIf="campsForm.value.typeOfCamps?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'camps-typeOfCampsOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="typeOfCampsOther"
        ></app-input-textarea>
      </div>


      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be targeted to attend the camps?'"
        [id]="'camps-peopleTargeted'"
        [name]="'camps-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
      ></app-input-checkbox>

      <app-input-number
        [label]="'How many camps will be conducted?'"
        [id]="'camps-numberOfCamps'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfCamps"
      ></app-input-number>

      <app-input-number
        [label]="'How many children and/or youth will attend each camp?'"
        [id]="'camps-numberOfChildrenOrYouth'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfChildrenOrYouth"
      ></app-input-number>

      <app-input-number
        [label]="'How many days will the camp run?'"
        [id]="'camps-numberOfDays'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfDays"
      ></app-input-number>

      <app-input-checkbox
        [choices]="topicsDiscussedOptions"
        [label]="'Which topics will the camps discuss?'"
        [id]="'camps-topicsDiscussed'"
        [name]="'camps-topicsDiscussed'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="topicsDiscussed"
        (selected)="checkOther('other', $event, campsForm.controls.topicsDiscussed, campsForm.controls.topicsDiscussedOther)"
      ></app-input-checkbox>

      <div *ngIf="campsForm.value.topicsDiscussed?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'camps-topicsDiscussedOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="topicsDiscussedOther"
        ></app-input-textarea>
      </div>


      <div>
        <p>How much funding do you need to provide these camps?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="campsForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
