import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalService} from '../../../../shared/modules/shared-common/services/modal/modal.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  options: NgbModalOptions;
  profileForm: FormGroup;

  theme: 'light' | 'dark' = 'dark';
  style: 'default' | 'bordered' = 'bordered';

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Add Funder',
      centered: true
    };

    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      subscribed: [true, Validators.required],
    });
  }

  openSubscribeModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  createSubscription() {
    if (this.profileForm.valid) {
      const url = `${environment.apiUrl}/subscription`;

      const headers = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');

      this.httpClient.post<any>(url, this.profileForm.value, {headers}).subscribe(() => {
        this.alertService.setAlert('Successfully subscribed.', 'success', 'subscribe', {
          timeOut: 5000
        });
        this.closeModal();
      }, () => {
        this.alertService.setAlert('Failed to subscribe.', 'error', 'subscribe');
        this.closeModal();
      });
    }
  }
}
