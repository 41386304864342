<div class="wrapper">
  <div class="page-header clear-filter">
    <div class="page-header-image">
    </div>
    <div class="w-100 flex-center flex-column">
<!--      <img-->
<!--        class="primary-logo"-->
<!--        alt=""-->
<!--        src="../../../../../../assets/img/solinta/SoCapX_Logo_RGB_NoBG.png"-->
<!--        width="600"-->
<!--      >-->
      <img
        class="primary-logo"
        alt=""
        src="../../../../../../assets/img/solinta/SoCapX%20Logo_Vector(White_FillAndOutline).png"
        width="600"
      >
    </div>
    <div class="p-2 bg-dark">
      <div class="row">
        <div class="w-90 mx-auto text-center">
          <h5 class="text-light">
            Welcome to the SoCapX Platform.
          </h5>
          <p class="text-light font-weight-normal">
            The Social Capital Exchange is a value driven, social enterprise platform
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="section-image">
    <div class="section-content">

      <div class="ml-auto mr-auto text-center">
        <p class="pt-4 w-98 mx-auto text-light font-weight-normal">
          Please complete your Profile before proceeding to complete your Capacity Spectrum

          We will let you know as soon as a Funding Opportunity becomes available that matches your profile, please
          ensure your Profile and Capacity Spectrum are complete, otherwise we will not be able to match your organisation to
          Funding Opportunities.
        </p>
        <div class="flex-around">
          <div class="card card-blog m-4">
            <div class="card-body">
              <h4 class="category text-primary">
                Implementer Profile
              </h4>
            </div>
            <div class="card-image">
              <img
                class="img rounded"
                src="../../../../../../assets/img/solinta/ngo-partnership.jpg"
                width="700"
                height="305"
              >
            </div>
            <div class="p-3 flex-center">
              <button
                class="btn btn-info"
                [routerLink]="'/org-profile/profile/' + (user.organisationId || 'new')"
              >
                <span *ngIf="!user.organisationId">Create</span>
                <span *ngIf="user.organisationId">Edit</span>
                Implementer Profile
              </button>
            </div>
          </div>

          <div class="card card-blog m-4">
            <div class="card-body">
              <h4 class="category text-primary">
                Capacity Spectrum
              </h4>
            </div>
            <div class="card-image">
              <img
                class="img rounded"
                src="../../../../../../assets/img/solinta/community%20partner.jpg"
                width="700"
                height="305"
              >
            </div>
            <div class="p-3 flex-center">
              <button
                *ngIf="user.organisationId; else createOrgProfileText"
                class="btn btn-info"
                [routerLink]="'/capacity-spectrum/edit/' + user.organisationId"
              >
                <span *ngIf="!user.organisationId">Create</span>
                <span *ngIf="user.organisationId">Edit</span>
                Capacity Spectrum
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createOrgProfileText>
  <p class="font-weight-normal">
    Please complete your Profile before completing your Capacity Spectrum
  </p>
</ng-template>
