import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const typeOfAdherenceClubAnswers: Array<ChoiceInputItem> = [
  {
    label: 'IACT',
    value: 'iact'
  },
  {
    label: 'Other - please specify in the comment field below',
    value: 'other'
  },
];

export const peopleReachedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'HIV positive adults',
    value: 'hivAdults'
  },
  {
    label: 'HIV positive children and youth',
    value: 'hivChildren'
  },
  {
    label: 'HIV positive female sex workers',
    value: 'fsw'
  },
  {
    label: 'HIV positive men who have sex with men',
    value: 'msm'
  },
  {
    label: 'HIV positive transgender persons',
    value: 'hivTransgender'
  },
  {
    label: 'HIV positive injecting drug users',
    value: 'hivInjectingUsers'
  },
  {
    label: 'Hiv positive migrant workers',
    value: 'hivMigrantWorkers'
  },
  {
    label: 'HIV positive prisoners',
    value: 'hivPrisoners'
  },
  {
    label: 'HIV positive military',
    value: 'hivMilitary'
  },
  {
    label: 'HIV positive non-injecting drug users',
    value: 'hivNonInjectingUsers'
  },
  {
    label: 'HIV positive gender based violence victims',
    value: 'hivViolenceVictims'
  }
];

export const frequencyOfMeetingsAnswers: Array<ChoiceInputItem> = [
  {
    label: 'daily',
    value: 'daily'
  },
  {
    label: 'weekly',
    value: 'weekly'
  },
  {
    label: `bi-weekly`,
    value: 'biWeekly'
  },
  {
    label: `monthly`,
    value: 'monthly'
  },
  {
    label: 'bi-monthly',
    value: 'biMonthly'
  },
  {
    label: 'quarterly',
    value: 'quartely'
  },
];

