<div class="wrapper" #top>
    <div class="page-header clear-filter">
        <div class="page-header-image"></div>
        <div class="w-100 flex-center flex-column text-center">
            <h1 class="text-light mb-4 mt-4">FAQs</h1>
            <div class="card bg-dark">
                <div class="card-body col-lg-10 col-md-10 col-12 mx-auto">
                    <h4 class="mt-4">Who can Register on SocialCapitalExchange.org?</h4>

                    <p class="text-center mt-4">
                        NGOs, CBOs, NPOs, Faith-based Organisations, ECD Centers,
                        Youth Centers, Not-for-Profit Companies, Funders, Aide Agencies, CSI Managers,
                        SMEs
                    </p>

                    <h4 class="mt-4">Can I email or post my application?</h4>

                    <p class="text-center mt-4">
                        No, emailed or posted applications will not be accepted. The only way to apply
                        for funding is to submit the electronic application through this site.
                    </p>

                    <h4 class="mt-4">How are staff and people placed in the organization, and how are volunteers defined?</h4>

                    <p class="text-center mt-4">
                        We consider all people, whether they are paid or volunteer, as members of your team.
                        We define staff as people who are permanently working in your organisation and receive
                        a regular, fixed salary or stipend based on an employment contract. We define people who
                        are placed at your organisation as individuals funded by EPWP or IDT or a similar agency
                        who have temporary, whether short or long term, contracts to work at your organisation.
                        We define volunteers as people who serve in your organization, whether short or long term,
                        and who do not receive any payment of any kind for their service.
                    </p>

                    <h4 class="mt-4">Do Quotes need to be submitted with Applications?</h4>

                    <p class="text-center mt-4">
                        Yes, for all items that you can obtain quotes for such as training, equipment and
                        supplies. Quotations are one of the required supporting documents that need to be
                        submitted with applications.
                    </p>

                    <h4 class="mt-4">What do I do if I am requesting funding for something that I cannot get a quote for?</h4>

                    <p class="text-center mt-4">
                        If you are requesting funding for items such as transport and administration, you
                        need to submit a cost calculation/explanation which can be done as a simple budget
                        in Excel or as a narrative description in Word and you will need to upload this as
                        one of the required supporting documents. For the cost calculation/explanation, we
                        need to understand how much funding you are requesting, for what purpose, for how
                        many people. For instance, if you are requesting funding for 10 caregivers’
                        transport costs for 12 months and they use public transport, there is no way to
                        get a quote, therefore, you would need to explain that you are paying each
                        caregiver, for example, Z rands per month to visit an average of Y households per
                        day, so Z x Y caregivers x 12 months =  transport costs.
                    </p>

                    <h4 class="mt-4">Why won’t the system let me click on the “submit” button to submit my application?</h4>

                    <p class="text-center mt-4">
                        You will only be able to submit your application when all of the mandatory questions
                        have been answered and all of the required documents have been uploaded. The color-coded
                        progress bar at the top of the page will tell you where you have mandatory questions which
                         have not been answered, as the section on the progress bar will be red. When all sections
                         on the progress bar are green, that means your application is ready to be submitted.
                    </p>

                    <H4 class="mt-4">What should I do if I am having technical problems using this site?</H4>

                    <p class="text-center mt-4">
                        If you are having any problems with this site, please contact help@socialcapitalexchange.org
                        and a technician will respond within 8 working hours.
                    </p>

                    <h4 class="mt-4">What is the best Internet Browser to use with this site?</h4>

                    <p class="text-center mt-4">To ensure the best possible browsing experience, please make sure you are using the
                        latest version of one of the following internet browsers:
                    </p>

                    <ul class="mt-4">
                        <li class="font-weight-normal">
                            <a href="https://www.google.com/chrome/browser/desktop/index.html" class="text-info">Google Chrome </a>
                        </li>

                        <li class="font-weight-normal">
                            <a href="https://www.mozilla.org/en-US/firefox/new/" class="text-info">Mozilla Firefox</a>
                        </li>

                        <li class="font-weight-normal">
                            <a href="https://www.apple.com/safari/" class="text-info">Safari (MacOS)</a>
                        </li>

                        <li class="font-weight-normal">
                            <a href="https://www.microsoft.com/en-za/windows/microsoft-edge" class="text-info">Microsoft Edge (Windows 8 and 10)</a>
                        </li>
                    </ul>

                    <p class="text-center mt-4">
                        If you are unsure of which browser or browser version you are using, you can find out
                        <a href="http://whatbrowser.org/" class="text-info">here.</a>
                    </p>
                </div>
            </div>
        </div>

<!--        <div class="flex-center p-4">-->
<!--            <button class="btn btn-outline-warning" (click)="scrollToTarget(top)">-->
<!--                <span class="flex-center">-->
<!--                    <span class="mr-1">To top</span>-->
<!--                    <i class="fas fa-arrow-alt-circle-up"></i>-->
<!--                </span>-->
<!--            </button>-->
<!--        </div>-->

    </div>
</div>
