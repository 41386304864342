import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadSingleDocumentComponent} from './components/upload-single-document/upload-single-document.component';
import {UploadDocumentComponent} from './components/upload-document/upload-document.component';
import {FileUploadModule} from 'ng2-file-upload';
import {ConfirmationModalModule} from '../confirmation-modal/confirmation-modal.module';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../alert/alert.module';

@NgModule({
  declarations: [
    UploadDocumentComponent,
    UploadSingleDocumentComponent,
  ],
    imports: [
        CommonModule,
        ConfirmationModalModule,
        FileUploadModule,
        ToastContainerModule,
        AlertModule,
    ],
  exports: [
    UploadDocumentComponent,
    UploadSingleDocumentComponent,
  ]
})
export class DocumentUploadModule {
}
