import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {GrantWorkflow} from 'app/modules/grant-workflow/store/grant-workflow.model';
import {WorkPlanAndTargetsStore} from './work-plan-and-targets.store';
import {WorkPlan, WorkPlanBudget} from './work-plan-and-targets.model';
import {Objective} from '../components/work-plan-and-targets/work-plan/funding-request/objective/store/objective.model';

@Injectable({providedIn: 'root'})
export class WorkPlanAndTargetsService {

  private url = `${environment.apiUrl}/work-plan`;
  private workPlanBudgetUrl = `${environment.apiUrl}/work-plan-budget`;

  constructor(protected store: WorkPlanAndTargetsStore,
              private http: HttpClient) {
  }

  fetchWorkflowById(workflowId: number): Observable<GrantWorkflow> {
    const url = `${environment.apiUrl}/grant-workflow/${workflowId}`;
    return this.http.get<GrantWorkflow>(url).pipe(
      tap(item => {
        this.setWorkflow(item);
      })
    );
  }


  fetchWorkPlansByWorkflowId(workflowId: number): Observable<WorkPlan[]> {
    const url = `${this.url}/grant-workflow/${workflowId}`;
    return this.http.get<WorkPlan[]>(url).pipe(
      tap(items => {
        this.setWorkPlans(items);
        const selectedWorkPlan = this.store.getValue().selectedWorkPlan;
        if (items && items.length) {
          if (!selectedWorkPlan) {
            this.setSelectedWorkPlan(items[0]);
            if (items[0].objectives) {
              this.setSelectedObjective(items[0].objectives[0]);
            } else {
              this.setSelectedObjective(undefined);
            }
          } else {
            const ix = items.findIndex(item => item.id === selectedWorkPlan.id);
            if (ix >= 0) {
              this.setSelectedWorkPlan(items[ix]);

              const objectives = items[ix].objectives;
              if (objectives) {
                const selectedObjective = this.store.getValue().selectedObjective;
                if (objectives && objectives.length) {
                  if (!selectedObjective) {
                    this.setSelectedObjective(objectives[0]);
                  } else {
                    const ixObj = objectives.findIndex(item => item.id === selectedObjective.id);
                    if (ixObj >= 0) {
                      this.setSelectedObjective(objectives[ixObj]);
                    } else {
                      this.setSelectedObjective(objectives[0]);
                    }
                  }
                } else {
                  this.setSelectedObjective(undefined);
                }
              } else {
                this.setSelectedObjective(undefined);
              }
            }
          }
        }
      })
    );
  }

  setSelectedWorkPlan(selectedWorkPlan: WorkPlan) {
    this.store.update({
      selectedWorkPlan
    });
  }

  updateWorkPlanBudgetList(budgets: WorkPlanBudget[]) {
    return this.http.put(`${this.workPlanBudgetUrl}/list`, budgets).pipe(
      tap(() => {
        this.fetchWorkPlansByWorkflowId(budgets[0].workPlan.workflow.id).subscribe();
      })
    );
  }

  setSelectedObjective(selectedObjective: Objective) {
    this.store.update({
      selectedObjective
    });
  }

  // setSelectedWorkPlanAndObjective(selectedWorkPlan: WorkPlan, selectedObjective: Objective) {
  //   this.store.update({
  //     selectedWorkPlan,
  //     selectedObjective
  //   });
  // }

  private setWorkflow(workflow: GrantWorkflow) {
    this.store.update({
      workflow
    });
  }

  private setWorkPlans(workPlans: WorkPlan[]) {
    this.store.update({
      workPlans
    });
  }

  // getWorkflowByOrgId(orgId: number): Observable<GrantWorkflow[]> {
  //   const url = `${environment.apiUrl}/grant-workflow/history?organizationId=${orgId}`;
  //   return this.http.get<GrantWorkflow[]>(url);
  // }


  //
  // create(grantWindow: GrantWindow) {
  //   return this.http.post<GrantWindow>(this.url, grantWindow).pipe(
  //     tap(() => {
  //       this.getAllNewGrantWindows().subscribe();
  //     })
  //   );
  // }
  //
  // updateGrantWindowStatus(grantWindow: GrantWindow, status: string) {
  //   const url = `${this.url}/${grantWindow.id}/status/${status}`;
  //   return this.http.put(url, undefined).pipe(tap(() => {
  //   }));
  // }
  //

  // addNewObjective(objectiveName: string, selectedWorkPlan: WorkPlan) {
  //   selectedWorkPlan.objectives = selectedWorkPlan.objectives || [];
  //
  //   const objective = createObjective({name: objectiveName, description: ''});
  //   selectedWorkPlan.objectives.push(objective);
  //
  //   this.setSelectedObjective(objective);
  // }

  // removeObjective() {
  //   // TODO add code here
  // }
  //
  // addNewActivity(activityName: string) {
  //   // TODO add code here
  // }
  //
  // removeActivity(activityIndex: number) {
  //   // TODO add code here
  // }
}
