import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../../../shared/stores/user/user.model';

@Component({
  selector: 'app-landing',
  templateUrl: './applicant-landing.component.html',
  styleUrls: ['./applicant-landing.component.scss']
})
export class ApplicantLandingComponent implements OnInit {

  @Input() user: User;

  constructor() {
  }

  ngOnInit() {
  }
}
