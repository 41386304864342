import {Injectable} from '@angular/core';
import {FormBuilderQuestionStore} from './form-builder-question.store';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../../../environments/environment';
import {FormBuilderQuestion, FormBuilderQuestionDTO} from './form-builder-question.model';
import {tap} from 'rxjs/operators';
import {FormBuilderService} from '../../../store/form-builder.service';

@Injectable({providedIn: 'root'})
export class FormBuilderQuestionService {

  url = `${environment.apiUrl}/dynamic-form-question`;

  constructor(private store: FormBuilderQuestionStore,
              private formBuilderService: FormBuilderService,
              private http: HttpClient) {
  }

  // get(): Observable<FormBuilderQuestion[]> {
  //   const url = `${this.url}`;
  //   return this.http.get<FormBuilderQuestion[]>(url);
  // }
  //
  // getOne(id: number): Observable<FormBuilderQuestion> {
  //   const url = `${this.url}/${id}`;
  //   return this.http.get<FormBuilderQuestion>(url);
  // }

  create(formId: number, question: FormBuilderQuestion): Observable<FormBuilderQuestionDTO> {
    const url = `${this.url}?formId=${formId}`;
    return this.http.post<FormBuilderQuestionDTO>(url, question).pipe(tap(() => {
      this.formBuilderService.getOne(formId);
    }));
  }

  update(formId: number, question: FormBuilderQuestion): Observable<FormBuilderQuestionDTO> {
    const url = `${this.url}/${question.id}?formId=${formId}`;
    return this.http.put<FormBuilderQuestionDTO>(url, question).pipe(tap(() => {
      this.formBuilderService.getOne(formId);
    }));
  }
 // TODO add unique

  delete(formId: number) {
    const url = `${this.url}/${formId}`;
    return this.http.delete(url).pipe(
      tap(() => {
        this.formBuilderService.getForms().subscribe();
      })
    );
  }
}
