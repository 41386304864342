import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {sidePanelYesNoAnswers} from 'app/modules/grant-review/constants/side-panel-answers.const';
import {User} from 'app/shared/stores/user/user.model';
import {CoordinatorUtil} from '../../../utils/coordinator-util';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';

@Component({
  selector: 'app-site-visit',
  templateUrl: './site-visit.component.html',
  styleUrls: ['./site-visit.component.scss']
})
export class SiteVisitComponent implements OnInit {

  @Input() coordinators: User[];
  @Input() siteVisitForm: FormGroup;
  @Input() orgId: number;
  @Input() windowCode: string;
  @Input() isReadOnly: boolean;

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  siteVisitAnswers = sidePanelYesNoAnswers;
  coordinatorList: ChoiceInputItem[];
  additionalParameters: any[];

  dropdownStates = {
    organisationStructure: false,
    serviceDelivery: false,
    communityRelationships: false,
    financial: false,
    recentChanges: false
  };

  constructor() {
  }

  ngOnInit() {
    this.additionalParameters = [
      {key: 'grantWindowCode', value: this.windowCode},
      {key: 'orgId', value: this.orgId}
    ];
    this.coordinatorList = CoordinatorUtil.convertToChoiceInputItem(this.coordinators);
  }

}
