import {FundingRequestAnswer, FundingRequestType} from '../../model/funding-request.model';
import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const monthsToCompleteAnswers: Array<ChoiceInputItem> = [
  {
    label: '12 months',
    value: '12'
  },
  {
    label: '24 months',
    value: '24'
  },
];

export const requestedFundingAnswers: Array<FundingRequestAnswer> = [
  {
    label: 'Training',
    value: FundingRequestType.TRAINING,
  },
  {
    label: 'Support groups',
    value: FundingRequestType.SUPPORT_GROUPS
  },
  {
    label: `Adherence clubs`,
    value: FundingRequestType.ADHERENCE_CLUBS,
  },
  {
    label: `HIV testing and counseling services`,
    value: FundingRequestType.HIV_TESTING
  },
  {
    label: 'Home based care',
    value: FundingRequestType.HOME_BASED_CARE
  },
  {
    label: 'Counseling',
    value: FundingRequestType.COUNSELING
  },
  {
    label: 'Community mobilization and awareness',
    value: FundingRequestType.COMMUNITY_MOBILIZATION
  },
  {
    label: 'Clinical services',
    value: FundingRequestType.CLINICAL_SERVICES
  },
  {
    label: 'Camps (disclosure and/or life skills)',
    value: FundingRequestType.CAMPS
  },
  {
    label: 'Targeted prevention/education programs',
    value: FundingRequestType.TARGETED_PREVENTION
  },
  {
    label: 'Programs, processes, or system development for the organization',
    value: FundingRequestType.SYSTEM_DEVELOPMENT
  },
  {
    label: 'Other - please specify in the comment field below',
    value: FundingRequestType.OTHER
  },
];
