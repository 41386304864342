<div class="flex-around w-95">
  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="structureIsValid"
    [title]="{label: 'Organisation Structure', value: 'organizational structure'}"
    [isActive]="activeChevron === 'organizational structure'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="humanResourcesIsValid"
    [title]="{label: 'Human Resources', value: 'human resources'}"
    [isActive]="activeChevron === 'human resources'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="governanceIsValid"
    [title]="{label: 'Governance', value: 'governance'}"
    [isActive]="activeChevron === 'governance'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="managementIsValid"
    [title]="{label: 'Financial Management', value: 'financial management'}"
    [isActive]="activeChevron === 'financial management'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="situationIsValid"
    [title]="{label: 'Financial Situation', value: 'financial situation'}"
    [isActive]="activeChevron === 'financial situation'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="mneReportingAndPlanningIsValid"
    [title]="{label: 'M&E Reporting And Planning', value: 'mne'}"
    [isActive]="activeChevron === 'mne'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="marketingAndPromotionIsValid"
    [title]="{label: 'Marketing And Promotion', value: 'marketing and promotion'}"
    [isActive]="activeChevron === 'marketing and promotion'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="networkAndLinkagesIsValid"
    [title]="{label: 'Network And Linkages', value: 'network and linkages'}"
    [isActive]="activeChevron === 'network and linkages'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="innovationIsValid"
    [title]="{label: 'Innovation And Technology', value: 'innovation'}"
    [isActive]="activeChevron === 'innovation'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>

  <app-chevron
    class="progress-bar-item"
    [appValid]="capacitySpectrumIsValid"
    [isValid]="aspirationalIsValid"
    [title]="{label: 'Aspirational', value: 'aspirational'}"
    [isActive]="activeChevron === 'aspirational'"
    (selected)="changeActiveChevron($event)"
  ></app-chevron>
</div>
