import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {FormBuilderStore} from '../components/form-builder-list/form-builder/store/form-builder.store';
import {HttpClient} from '@angular/common/http';
import {createFormBuilderDTO, FormBuilderDTO} from '../components/form-builder-list/form-builder/store/form-builder.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class QuestionService {
  url = `${environment.apiUrl}/dynamic-form`;

  constructor(protected store: FormBuilderStore,
              private http: HttpClient) {
  }

  getAuditLog(formBuilderId: number): Observable<any> {
    const url = `${this.url}/get-audit-log/${formBuilderId}`;
    return this.http.get<any>(url);
  }

  getForm(formBuilderId: number): Observable<FormBuilderDTO> {
    const url = `${this.url}/${formBuilderId}`;
    return this.http.get<FormBuilderDTO>(url).pipe(map(item => {
      item.questions = item.questions.sort((a, b) => a.order - b.order);
      return createFormBuilderDTO(item);
    }));

    // I'm just keeping this here for reference.
    // const formTemplate: DynamicForm = new DynamicForm({
    //   theme: 'dark',
    //   style: 'default',
    //   questions: [
    //     new RadioQuestion({
    //       key: 'rating',
    //       label: 'Rating',
    //       options: [
    //         {label: 'solid', value: 'Solid'},
    //         {label: 'great', value: 'Great'},
    //         {label: 'good', value: 'Good'},
    //         {label: 'unproven', value: 'Unproven'}
    //       ],
    //       value: 'Unproven',
    //       order: 3
    //     }),
    //
    //     new CheckboxQuestion({
    //       key: 'organisationType',
    //       label: 'Organisation Type',
    //       options: organisationTypeAnswers,
    //       value: ['NPO'],
    //       order: 4
    //     }),
    //
    //     new TextboxQuestion({
    //       key: 'firstName',
    //       label: 'First Name',
    //       value: 'Luqmaan',
    //       required: true,
    //       order: 1
    //     }),
    //
    //     new TextareaQuestion({
    //       key: 'description',
    //       label: 'Description',
    //       value: '',
    //       required: true,
    //       order: 5
    //     }),
    //
    //     new TextboxQuestion({
    //       key: 'emailAddress',
    //       label: 'Email',
    //       // type: 'email',
    //       order: 2
    //     }),
    //
    //     new NumberQuestion({
    //       key: 'budget',
    //       label: 'Budget',
    //       required: true,
    //       order: 6
    //     }),
    //
    //     new DateQuestion({
    //       key: 'inceptionDate',
    //       label: 'Inception Date',
    //       required: true,
    //       order: 7
    //     }),
    //   ]
    // });

    // formTemplate.questions = formTemplate.questions.sort((a, b) => a.order - b.order);

    // return of(formTemplate);
  }
}
