<div [class]="style + '-input-group position-relative'" [class.input-is-grey]="isGrey">
  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">
    <p
      [class]="style + '-input-label label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>
  <div
    [class.disabled]="isGrey"
    [class]="style + '-input readonly-input'"
    [class.borderless]="!hasBorder"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
  >
    <ng-container *ngFor="let value of values">
      <p
        class="whitespace-normal font-weight-normal font-size-small"
        [ngClass]="value === 'Select an option...' ? ['text-grey', 'border-none'] : ''"
      >
        {{value}}
      </p>
    </ng-container>
  </div>
</div>
