import {Injectable} from '@angular/core';
import {ReviewStore} from './review.store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ReviewModel} from './review.model';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ReviewService {

  constructor(protected store: ReviewStore,
              private http: HttpClient) {
  }

  getReviewDetails(grantWorkflowId: number) {
    const url = `${environment.apiUrl}/review?grantWorkflowId=${grantWorkflowId}`;
    return this.http.get<ReviewModel>(url).pipe(
      tap((review) => {
        this.store.update(review);
      })
    );
  }

  createReviewDetails(reviewDetails: ReviewModel) {
    const url = `${environment.apiUrl}/review`;
    return this.http.post<ReviewModel>(url, reviewDetails).pipe(
      tap((review) => {
        this.store.add(review);
      })
    );
  }

  updateReviewDetails(reviewDetails: ReviewModel) {
    const url = `${environment.apiUrl}/review/${reviewDetails.id}`;
    return this.http.put<ReviewModel>(url, reviewDetails).pipe(
      tap((review) => {
        this.store.update(review);
      })
    );
  }

}
