<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="networkAndLinkagesForm">
    <app-input-select-single
      *ngIf="referralAndLinkageAnswers?.length"
      [label]="'Which statement best describes the organisation’s referral and linkage system/process? '"
      [items]="referralAndLinkageAnswers"
      [id]="'referralAndLinkage'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="referralAndLinkageSystem"
    ></app-input-select-single>

    <app-input-checkbox
      *ngIf="membersInvolvementAnswers?.length"
      [label]="'How are community members involved in the organisation? (check all that apply)'"
      [choices]="membersInvolvementAnswers"
      [id]="'membersInvolvement'"
      [name]="'membersInvolvement'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther(
      'OTHER',
        $event,
        networkAndLinkagesForm.controls.membersInvolvementList,
        networkAndLinkagesForm.controls.membersInvolvementOther
      )"
      formControlName="membersInvolvementList"
    ></app-input-checkbox>

    <div *ngIf="networkAndLinkagesForm?.value?.membersInvolvementList?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'membersInvolvementOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="membersInvolvementOther"
      ></app-input-text>
    </div>

    <app-input-checkbox
      *ngIf="communityLinkageAnswers?.length"
      [label]="'What kinds of community linkages does the organisation have? (check all that apply)'"
      [choices]="communityLinkageAnswers"
      [id]="'communityLinkage'"
      [name]="'communityLinkage'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther(
      'OTHER',
        $event,
        networkAndLinkagesForm.controls.communityLinkageList,
        networkAndLinkagesForm.controls.communityLinkageOther
      )"
      formControlName="communityLinkageList"
    ></app-input-checkbox>

    <div *ngIf="networkAndLinkagesForm?.value?.communityLinkageList?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'communityLinkageOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="communityLinkageOther"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="communityForumFrequencyAnswers?.length"
      [label]="'How often is the organisation represented at some type of community forum?'"
      [items]="communityForumFrequencyAnswers"
      [id]="'communityForumFrequency'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="communityForumFrequency"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="communityProfileAnswers?.length"
      [label]="'Which statement best describes the organisation’s profile in the community? '"
      [items]="communityProfileAnswers"
      [id]="'communityProfile'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="communityProfile"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="communityEngagementAnswers?.length"
      [label]="'How does the organisation engage with community leaders?'"
      [items]="communityEngagementAnswers"
      [id]="'communityEngagement'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="communityEngagement"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="organisationRelationshipAnswers?.length"
      [label]="'Which statement best describes the organisation\'s relationship with other organisations?'"
      [items]="organisationRelationshipAnswers"
      [id]="'organisationRelationship'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="organisationRelationship"
    ></app-input-select-single>

    <app-input-select-single
      [label]="'Is the organisation receiving any monitoring, capacity building or mentorship support from an organisation or company? '"
      [items]="booleanChoices"
      [id]="'hasCapacityBuilding'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hasCapacityBuilding"
    ></app-input-select-single>

    <div *ngIf="networkAndLinkagesForm?.value?.hasCapacityBuilding === true" class="ml-5">
      <app-input-text
        [label]="'Organisation or Company Name'"
        [id]="'capacityBuildingCompanyName'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="capacityBuildingCompanyName"
      ></app-input-text>

      <app-input-text
        [label]="'Explain the type of support provided'"
        [id]="'capacityBuildingSupportProvidedExplanation'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="capacityBuildingSupportProvidedExplanation"
      ></app-input-text>
    </div>

    <app-input-select-single
      [label]="'Does the organisation have a corporate social investment (CSI) partnership(s)?'"
      [items]="booleanChoices"
      [id]="'hasCSI'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hasCSI"
    ></app-input-select-single>

    <div *ngIf="networkAndLinkagesForm?.value?.hasCSI === true" class="ml-5">
      <app-input-text
        [label]="'Organisation or Company Name'"
        [id]="'csiCompanyName'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="csiCompanyName"
      ></app-input-text>

      <app-input-text
        [label]="'Explain the type of support provided'"
        [id]="'csiSupportProvidedExplanation'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="csiSupportProvidedExplanation"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="governmentRelationshipAnswers?.length"
      [label]="'Which statement best describes the organisation\'s relationship with relevant government department(s)?'"
      [items]="governmentRelationshipAnswers"
      [id]="'governmentRelationship'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="governmentRelationship"
    ></app-input-select-single>
  </form>
</app-spinner>
