<div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <p class="text-secondary disabled">MENU</p>
    </div>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li *ngIf="user.funderId && user.isSimSubmitted" [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/investor/dashboard"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        [routerLink]="'/funder-profile/profile/' + user.funderId"
      >Profile</a>
    </li>

    <li *ngIf="user.funderId" [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        [routerLink]="'/social-investment-menu/' + user.funderId"
      >Social Investment Menu</a>
    </li>

    <li *ngIf="user.funderId && user.isSimSubmitted" [ngbNavItem]="4">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/investor/role-assignment"
      >Role Assignment</a>
    </li>

    <li *ngIf="user.funderId && user.isSimSubmitted">
      <ngb-accordion activeIds="ngb-panel-0">
        <ngb-panel title="Investment Opportunities" class="dropdown-toggle">
          <ng-template ngbPanelContent>
            <div [ngbNavItem]="7">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/new"
              >
                New
              </a>
            </div>
            <div [ngbNavItem]="8">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/active"
              >
                Current
              </a>
            </div>
            <div [ngbNavItem]="9">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/shortlist"
              >
                Shortlist
              </a>
            </div>
            <div [ngbNavItem]="10">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/review"
              >
                Review
              </a>
            </div>
            <div [ngbNavItem]="11">
              <a
                ngbNavLink
                class="sidebar-link disabled"
                disabled
                routerLink="/grant-window/create"
              >
                Create Investment Opportunity
              </a>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </li>

    <ng-container *ngIf="!env.production && user.funderId && user.isSimSubmitted">
      <li *authenticatedWithRole="['ROLE_FUNDER', 'ROLE_GRANT_MANAGER']">
        <ngb-accordion activeIds="ngb-panel-1">
          <ngb-panel title="Forms" class="dropdown-toggle">
            <ng-template ngbPanelContent>
              <div [ngbNavItem]="12">
                <a
                  ngbNavLink
                  class="sidebar-link"
                  routerLink="/form-builder/list"
                >
                  Application Forms
                </a>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </li>
    </ng-container>

  </ul>
</div>
