import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ModalService} from 'app/shared/modules/shared-common/services/modal/modal.service';

@Component({
  selector: 'app-submit-application',
  templateUrl: './submit-application.component.html',
  styleUrls: ['./submit-application.component.scss']
})
export class SubmitApplicationComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  submitForm: FormGroup;

  @Output() data = new EventEmitter<any>();

  submittedBy: string;
  verificationOptions = [
    {
      label: 'verification',
      value: true
    }
  ];
  submitted: boolean;

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.submitForm = this.formBuilder.group({
      submittedBy: ['', Validators.required],
      verification: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;
    const data: { [k: string]: any } = {};
    if (this.submitForm.value.verification[0] && this.submitForm.value.submittedBy) {
      data.submittedBy = this.submitForm.value.submittedBy;
      data.submitVerification = this.submitForm.value.verification[0];
      this.data.emit(data);
      this.closeModal();
    }
  }

  closeModal() {
    this.modalService.closeModal();
  }

}
