import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface ApplicationState {
}

export function createInitialState(): ApplicationState {
  return {
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'application',
})
export class ApplicationStore extends Store<ApplicationState> {

  constructor() {
    super(createInitialState());
  }
}
