import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from '../../shared/modules/form-elements/form-elements.module';
import {SharedCommonModule} from '../../shared/modules/shared-common/shared-common.module';
import {FormRendererComponent} from './components/form-renderer/form-renderer.component';
import {DynamicFormQuestionComponent} from './components/form-renderer/dymanic-form/dynamic-form-question/dynamic-form-question.component';
import {DynamicFormComponent} from './components/form-renderer/dymanic-form/dynamic-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilderComponent} from './components/form-builder-list/form-builder/form-builder.component';
import {FormBuilderListComponent} from './components/form-builder-list/form-builder-list.component';
import {
  FormBuilderQuestionComponent
} from './components/form-builder-list/form-builder/components/form-builder-question/form-builder-question.component';
// tslint:disable-next-line:max-line-length
import {FormBuilderChoiceComponent} from './components/form-builder-list/form-builder/components/form-builder-question/components/form-builder-choice/form-builder-choice.component';
import {DataTableModule} from '../../shared/modules/data-table/data-table.module';
import {FormBuilderRoutingModule} from './form-builder-routing.module';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../shared/modules/alert/alert.module';
import {FormHistoryComponent} from './components/form-history/form-history.component';
import { TemplateListComponent } from './template/template-list/template-list.component';
import { TemplateFormBuilderComponent } from './template/template-list/template-form-builder/template-form-builder.component';


@NgModule({
  declarations: [
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    FormRendererComponent,
    FormBuilderComponent,
    FormBuilderListComponent,
    FormBuilderQuestionComponent,
    FormBuilderChoiceComponent,
    FormHistoryComponent,
    TemplateListComponent,
    TemplateFormBuilderComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormElementsModule,
    SharedCommonModule,
    NgbModule,
    DataTableModule,
    FormBuilderRoutingModule,
    ToastContainerModule,
    AlertModule
  ],
  exports: [
    FormBuilderQuestionComponent,
    FormBuilderChoiceComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent
  ],
  entryComponents: [
    FormBuilderQuestionComponent,
    FormBuilderChoiceComponent
  ]
})
export class FormBuilderModule {
}
