import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MblTableComponent} from './components/mbl-table/mbl-table.component';
import {MblTablePaginationComponent} from './components/mbl-table-pagination/mbl-table-pagination.component';
import {TableFilterComponent} from './components/table-filter/table-filter.component';
import {FormsModule} from '@angular/forms';
import {ColspanPipe} from './pipes/colspan.pipe';
import {SharedCommonModule} from '../shared-common/shared-common.module';
import {FormElementsModule} from '../form-elements/form-elements.module';

@NgModule({
  declarations: [
    MblTableComponent,
    MblTablePaginationComponent,
    TableFilterComponent,
    ColspanPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedCommonModule,
    FormElementsModule
  ],
  exports: [
    MblTableComponent,
    MblTablePaginationComponent,
    TableFilterComponent,
  ]
})
export class DataTableModule {
}
