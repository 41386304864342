import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../shared/models/base-entity.model';
import {Organisation} from '../../../../org-profile/store/organisation.model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  createCSOrganisationalStructure, createCSOrganisationalStructureForm,
  CSOrganizationalStructure
} from '../components/cs-organisational-structure/store/cs-organisational-structure.model';
import {createCSMNE, createCSMNEForm, CSMNE} from '../components/cs-mne/store/cs-mne.model';
import {
  createCSHumanResources,
  createCSHumanResourcesForm,
  CSHumanResources
} from '../components/cs-human-resources/store/cs-human-resources.model';
import {createCSGovernance, createCSGovernanceForm, CSGovernance} from '../components/cs-governance/store/cs-governance.model';
import {
  createCSMarketingAndPromotion,
  createCSMarketingAndPromotionForm,
  CSMarketingAndPromotion
} from '../components/cs-marketing-and-promotion/store/cs-marketing-and-promotion.model';
import {createCSAspirational, createCSAspirationalForm, CSAspirational} from '../components/cs-aspirational/store/cs-aspirational.model';
import {
  createCSNetworkAndLinkages,
  createCSNetworkAndLinkagesForm,
  CSNetworkAndLinkages
} from '../components/cs-network-and-linkages/store/cs-network-and-linkages.model';
import {
  createCSFinancialSituation,
  createCSFinancialSituationForm,
  CSFinancialSituation
} from '../components/cs-financial-situation/store/cs-financial-situation.model';
import {
  createCSFinancialManagement, createCSFinancialManagementForm,
  CSFinancialManagement
} from '../components/cs-financial-management/store/cs-financial-management.model';
import {createCSInnovation, createCSInnovationForm, CSInnovation} from '../components/cs-innovation/store/cs-innovation.model';
import {Subject} from 'rxjs';

export interface SingleScoreDTO {
  score: number;
  max: number;
}

export interface CapacitySpectrumScoreDTO {
  organizationalStructure: SingleScoreDTO;
  humanResources: SingleScoreDTO;
  governance: SingleScoreDTO;
  financialManagement: SingleScoreDTO;
  financialSituation: SingleScoreDTO;
  mne: SingleScoreDTO;
  marketingAndPromotion: SingleScoreDTO;
  networkAndLinkages: SingleScoreDTO;
  innovationAndTechnology: SingleScoreDTO;

  totalScore: SingleScoreDTO;
}

export interface CapacitySpectrumDTO {
  capacitySpectrum: CapacitySpectrum;
  score: CapacitySpectrumScoreDTO;
}

export interface CapacitySpectrum extends BaseEntity {
  organisation: Organisation;
  status: string;
  submitted: boolean;
  organizationalStructure: CSOrganizationalStructure;
  humanResources: CSHumanResources;
  governance: CSGovernance;
  financialManagement: CSFinancialManagement;
  financialSituation: CSFinancialSituation;
  mneReportingAndPlanning: CSMNE;
  marketingAndPromotion: CSMarketingAndPromotion;
  networkAndLinkages: CSNetworkAndLinkages;
  aspirational: CSAspirational;
  innovationAndTechnology: CSInnovation;
  // supportingDocumentation: CSSupportingDocumentation;
}

export function createCapacitySpectrum(params: Partial<CapacitySpectrum>) {
  return Object.assign({}, createBaseEntity(params), {
    organisation: params && params.organisation,
    status: params && params.status || 'NEW',
    submitted: params && params.submitted,
    organizationalStructure: createCSOrganisationalStructure(params && params.organizationalStructure),
    humanResources: createCSHumanResources(params && params.humanResources),
    governance: createCSGovernance(params && params.governance),
    financialManagement: createCSFinancialManagement(params && params.financialManagement),
    financialSituation: createCSFinancialSituation(params && params.financialSituation),
    mneReportingAndPlanning: createCSMNE(params && params.mneReportingAndPlanning),
    marketingAndPromotion: createCSMarketingAndPromotion(params && params.marketingAndPromotion),
    networkAndLinkages: createCSNetworkAndLinkages(params && params.networkAndLinkages),
    aspirational: createCSAspirational(params && params.aspirational),
    innovationAndTechnology: createCSInnovation(params && params.innovationAndTechnology),
    // supportingDocumentation: createCSSupportingDocumentation(params && params.supportingDocumentation),
  }) as CapacitySpectrum;
}

export function createCapacitySpectrumForm(destroy$: Subject<boolean>,
                                           formBuilder: FormBuilder,
                                           params: Partial<CapacitySpectrum>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    organisation: [params && params.organisation, Validators.required],
    status: [params && params.status || 'NEW', Validators.required],
    submitted: [params && params.submitted],
    organizationalStructure: createCSOrganisationalStructureForm(destroy$, formBuilder, params && params.organizationalStructure),
    humanResources: createCSHumanResourcesForm(destroy$, formBuilder, params && params.humanResources),
    governance: createCSGovernanceForm(destroy$, formBuilder, params && params.governance),
    financialManagement: createCSFinancialManagementForm(destroy$, formBuilder, params && params.financialManagement),
    financialSituation: createCSFinancialSituationForm(destroy$, formBuilder, params && params.financialSituation),
    mneReportingAndPlanning: createCSMNEForm(destroy$, formBuilder, params && params.mneReportingAndPlanning),
    marketingAndPromotion: createCSMarketingAndPromotionForm(formBuilder, params && params.marketingAndPromotion),
    networkAndLinkages: createCSNetworkAndLinkagesForm(destroy$, formBuilder, params && params.networkAndLinkages),
    aspirational: createCSAspirationalForm(formBuilder, params && params.aspirational),
    innovationAndTechnology: createCSInnovationForm(destroy$, formBuilder, params.innovationAndTechnology),
    // supportingDocumentation: createCSSupportingDocumentationForm(formBuilder, params && params.supportingDocumentation),
  }));
}

export function checkForValue(
  value: string | boolean,
  selectedValue: string | boolean,
  control: AbstractControl,
  otherControl: AbstractControl
) {
  if (selectedValue === value) {
    if (Array.isArray(control.value)) {
      if (!control.value.includes(value)) {
        otherControl.patchValue('');
      }
    } else {
      if (control.value !== value) {
        otherControl.patchValue('');
      }
    }
  }
}
