import {Component, Input, OnInit} from '@angular/core';
import {FormBuilderDTO} from '../../../../../form-builder/components/form-builder-list/form-builder/store/form-builder.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-capture',
  templateUrl: './form-capture.component.html',
  styleUrls: ['./form-capture.component.scss']
})
export class FormCaptureComponent implements OnInit {

  @Input() formTemplate: FormBuilderDTO;
  @Input() form: FormGroup;
  @Input() isReadOnly: boolean;

  constructor() {
  }

  ngOnInit() {
  }
}
