import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent implements OnInit, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  @Input() iconClassAppend?: string;
  @Input() stringPrepend?: string;
  @Input() stringAppend?: string;
  @Input() urlFragment?: string;
  @Input() uniqueAdditionalParam?: string;
  @Input() uniqueRules?: any;
  @Input() uniqueId?: number;
  @Input() uniqueDescription?: string;
  @Input() clickableIcon?: boolean;
  @Input() markUnTouched?: boolean;

  // Default
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() showDisabledValue = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isUnique = false;
  @Input() maxLength = 45;
  @Input() minLength = 0;
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() theme: 'dark' | 'light' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() class: string;
  @Input() uniqueError = 'That value is already in use. Please try another';

  @Output() onIconClick = new EventEmitter<boolean>();

  public valueSrc: string;

  set value(val: any) {
    if (val !== undefined && this.valueSrc !== val) {
      this.valueSrc = val;
      this.onChange(val);
      this.onTouch();
    }
  }

  get value(): any {
    return this.valueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isReadOnly = isDisabled;
  }

  onChange(value?: string) {
  }

  onTouch() {
  }

  onClickCallback() {
    if (this.clickableIcon) {
      this.onIconClick.emit(true);
    }
  }
}
