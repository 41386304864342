import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {CoordinatorService} from '../../../../../../../coordinator/store/coordinator.service';
import {ChoiceInputItem} from '../../../../../../../../shared/modules/form-elements/models/form-elements.model';

@Component({
  selector: 'app-assign-coordinator',
  templateUrl: './assign-coordinator.component.html',
  styleUrls: ['./assign-coordinator.component.scss']
})
export class AssignCoordinatorComponent implements OnInit {
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() coordinator: string;
  @Input() orgId: number;
  @Input() windowId: number;

  @Output() coordinatorChange: EventEmitter<string> = new EventEmitter<string>();

  options: NgbModalOptions;
  coordinators: ChoiceInputItem[];
  loadingCoordinators: boolean;
  showError: boolean;
  errorText: string;

  constructor(private modalService: ModalService,
              private coordinatorService: CoordinatorService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Assign Coordinator',
      centered: true
    };
    this.fetchList();
  }

  fetchList() {
    this.loadingCoordinators = true;

    this.coordinatorService.getAllByWindowId(this.windowId).subscribe(coordinators => {
      this.showError = false;
      this.errorText = '';
      if (coordinators && coordinators.length) {
        this.coordinators = coordinators.map(user => {
          return {
            label: user.firstName + ' ' + user.lastName,
            value: user.username,
          };
        });
      }

      this.loadingCoordinators = false;
    }, () => {
      this.showError = true;
      this.errorText = 'Unable to fetch Coordinator list';
      this.loadingCoordinators = false;
    });
  }

  openAssignCoordinatorModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  addAssignedCoordinators() {
    if (!this.loadingCoordinators) {
      if (!this.coordinator || this.coordinator.trim() === '') {
        this.deleteAssignedCoordinators();
      } else {
        this.coordinatorService.addAssignedCoordinators(this.coordinator, this.windowId, this.orgId).subscribe(() => {
          this.showError = false;
          this.errorText = '';
          // this.toastrService.success('Coordinator assigned', undefined, {
          //   timeOut: 5000
          // });
          this.coordinatorChange.emit(this.coordinator);
          this.closeModal();
        }, () => {
          this.showError = true;
          this.errorText = 'Failed to assign coordinator';
        });
      }
    } else {
      this.showError = true;
      this.errorText = 'Coordinator list is not loaded';
    }
  }

  private deleteAssignedCoordinators() {
    this.coordinatorService.deleteAssignedCoordinators(this.windowId, this.orgId).subscribe(() => {
      this.showError = false;
      this.errorText = '';
      // this.toastrService.success('Assigned coordinator deleted', undefined, {
      //   timeOut: 5000
      // });
      this.coordinatorChange.emit(undefined);
      this.closeModal();
    }, () => {
      this.showError = true;
      this.errorText = 'Failed to delete assigned';
    });
  }

}
