import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextComponent} from './components/input-text/input-text.component';
import {InputTextareaComponent} from './components/input-textarea/input-textarea.component';
import {NgbDatepickerModule, NgbDropdownModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {InputRadioComponent} from './components/input-radio/input-radio.component';
import {InputCheckboxComponent} from './components/input-checkbox/input-checkbox.component';
import {InputNumberComponent} from './components/input-number/input-number.component';
import {TextMaskModule} from 'angular2-text-mask';
import {InputSelectSingleComponent} from './components/input-select-single/input-select-single.component';
import {InputSelectMultiComponent} from './components/input-select-multi/input-select-multi.component';
import {InputDateComponent} from './components/input-date/input-date.component';
import {SharedCommonModule} from '../shared-common/shared-common.module';
import {InputTextDisabledComponent} from './components/input-text/input-text-disabled/input-text-disabled.component';
import {InputTextareaDisabledComponent} from './components/input-textarea/input-textarea-disabled/input-textarea-disabled.component';
import {InputCheckboxDisabledComponent} from './components/input-checkbox/input-checkbox-disabled/input-checkbox-disabled.component';
import {FormControlTextComponent} from './components/input-text/form-control-text/form-control-text.component';
import {FormControlTextareaComponent} from './components/input-textarea/form-control-textarea/form-control-textarea.component';
import {FormControlNumberComponent} from './components/input-number/form-control-number/form-control-number.component';
import {FormControlCheckboxComponent} from './components/input-checkbox/form-control-checkbox/form-control-checkbox.component';
import {FormControlRadioComponent} from './components/input-radio/form-control-radio/form-control-radio.component';
import {InputRadioDisabledComponent} from './components/input-radio/input-radio-disabled/input-radio-disabled.component';
import {
  FormControlSelectSingleComponent
} from './components/input-select-single/form-control-select-single/form-control-select-single.component';
import {InputSelectDisabledComponent} from './components/input-select-single/input-select-disabled/input-select-disabled.component';
import {
  FormControlSelectMultiComponent
} from './components/input-select-multi/form-control-select-multi/form-control-select-multi.component';
import {FormControlDateComponent} from './components/input-date/form-control-date/form-control-date.component';
import {InputDateDisabledComponent} from './components/input-date/input-date-disabled/input-date-disabled.component';
import {
  InputSelectMultiDisabledComponent
} from './components/input-select-multi/input-select-multi-disabled/input-select-multi-disabled.component';
import {WidgetCheckTwoComponent} from './components/widgets/widget-check-two/widget-check-two.component';
import {
  FormControlCheckTwoComponent
} from './components/widgets/widget-check-two/form-control-check-two/form-control-check-two.component';
import {
  WidgetCheckTwoDisabledComponent
} from './components/widgets/widget-check-two/widget-check-two-disabled/widget-check-two-disabled.component';
import {ItemGridDirective} from './directives/item-grid.directive';
import { InputNumberDisabledComponent } from './components/input-number/input-number-disabled/input-number-disabled.component';

@NgModule({
  declarations: [
    InputTextComponent,
    InputTextareaComponent,
    InputSelectSingleComponent,
    InputSelectMultiComponent,
    InputRadioComponent,
    InputCheckboxComponent,
    InputNumberComponent,
    InputDateComponent,
    InputTextDisabledComponent,
    InputTextareaDisabledComponent,
    InputCheckboxDisabledComponent,
    InputSelectDisabledComponent,
    InputSelectMultiDisabledComponent,
    FormControlTextComponent,
    FormControlTextareaComponent,
    FormControlNumberComponent,
    FormControlCheckboxComponent,
    FormControlRadioComponent,
    InputRadioDisabledComponent,
    FormControlSelectSingleComponent,
    FormControlSelectMultiComponent,
    FormControlDateComponent,
    InputDateDisabledComponent,
    WidgetCheckTwoComponent,
    FormControlCheckTwoComponent,
    WidgetCheckTwoDisabledComponent,
    ItemGridDirective,
    InputNumberDisabledComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDropdownModule,
    TextMaskModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    SharedCommonModule
  ],
  exports: [
    InputTextComponent,
    InputTextareaComponent,
    InputSelectSingleComponent,
    InputRadioComponent,
    InputCheckboxComponent,
    InputSelectMultiComponent,
    InputCheckboxComponent,
    InputNumberComponent,
    InputDateComponent,
    TextMaskModule,
    InputTextDisabledComponent,
    InputTextareaDisabledComponent,
    InputRadioDisabledComponent,
    InputCheckboxDisabledComponent,
    InputNumberDisabledComponent,
    InputSelectDisabledComponent,
    InputSelectMultiDisabledComponent,
    InputDateDisabledComponent,
    WidgetCheckTwoComponent,
  ]
})
export class FormElementsModule {
}
