import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {GrantWindow} from './grant-window.model';
import {CombinedGrant} from './combined-grant.model';
import {OtherFunding} from '../external-grants-store/other-funding.model';

export interface GrantWindowState {
  activeGrantWindow: GrantWindow;
  activeExternalFunding: OtherFunding;
  newList: GrantWindow[];
  currentList: GrantWindow[];
  shortlist: GrantWindow[];
  reviewList: GrantWindow[];
  currentCombinedList: CombinedGrant[];
}

export function createInitialState(): GrantWindowState {
  return {
    activeGrantWindow: undefined,
    activeExternalFunding: undefined,
    newList: [],
    currentList: [],
    shortlist: [],
    reviewList: [],
    currentCombinedList: []
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'grantWindow',
})
export class GrantWindowStore extends Store<GrantWindowState> {

  constructor() {
    super(createInitialState());
  }
}
