import {NullUtil} from '../../../../../shared/utils/null-util';
import {Eligibility} from '../store/eligibility.model';

export class EligibilityValidation {
  static isValid(entity: Eligibility): boolean {
    return NullUtil.isNotEmptyStringList(entity.implementingDistricts)
      && entity.directRecipientOfUsGovernment !== undefined
      && entity.subRecipientOfPepfarFunding !== undefined
      && entity.servingHiv !== undefined;
  }
}
