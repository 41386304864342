<h1 class="text-center">Submit Application</h1>
<hr class="dropdown-divider w-75 mx-auto">
<div class="card">
  <div class="card-body bg-blue-50">
    <form [formGroup]="submitForm">

      <app-input-text
        [label]="'What is the monthly average of the overhead/administrative costs of the organization including personnel costs?'"
        [id]="'submittedBy'"
        [theme]="theme"
        [style]="style"
        [maxLength]="255"
        [isRequired]="true"
        formControlName="submittedBy"
      ></app-input-text>

      <div class="form-group checkbox-box" [class.danger]="submitted && !submitForm.controls.verification">
        <div class="form-check">
          <app-input-checkbox
            [choices]="verificationOptions"
            [label]="'Who will be targeted with counseling services?'"
            [name]="'verification'"
            [id]="'verification'"
            [isRequired]="true"
            [showActiveBorder]="false"
            [theme]="theme"
            [style]="style"
            formControlName="verification"
          ></app-input-checkbox>

          <label class="form-check-label"
                 [class.label-margin]="submitted && !submitForm.controls.verification">
            By checking this box, I certify that the information entered in this application is accurate and true and I
            understand that
            the organization may be asked to verify any and all information contained within this document as part of
            the selection process.
            <span class="ml-2" *ngIf="submitted && !submitForm.controls.verification">
              <small class="text-danger">(required)</small>
            </span>
          </label>
        </div>

        <div class="checkbox-or-radio-error-append" *ngIf="submitted && !submitForm.controls.verification">
            <span class="checkbox-or-radio-error">
              <i class="fas fa-exclamation fa-lg text-danger"></i>
            </span>
        </div>
      </div>

      <div class="buttons">
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-outline-primary" (click)="onSubmit()">Submit Application</button>
      </div>

    </form>
  </div>
</div>
