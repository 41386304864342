import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {
  requestedFundingAnswers
} from '../../../application/funding-request/constants/funding-request-answers/funding-request-question-answers.const';
import {FormValidationService} from '../../../../../../services/form-validation/form-validation.service';
import {checkForValue} from '../../../../../organisation/capacity-spectrum/components/capacity-spectrum/store/capacity-spectrum.model';

@Component({
  selector: 'app-custom-funding-request',
  templateUrl: './custom-funding-request.component.html',
  styleUrls: ['./custom-funding-request.component.scss']
})
export class CustomFundingRequestComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() fundingRequestForm: FormGroup;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  requestedFunding = requestedFundingAnswers;

  constructor(public formValidation: FormValidationService) {
  }

  ngOnInit() {
  }

  checkOther(value: string | boolean, selectedValue: string, controlParent: AbstractControl, controlChild: AbstractControl) {
    checkForValue(value, selectedValue, controlParent, controlChild);
  }
}
