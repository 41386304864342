export class NullUtil {
  static isEmptyList(list: any[]): boolean {
    return list === undefined || list == null || list.length === 0;
  }

  static isNotEmptyList(list: any[]): boolean {
    return !this.isEmptyList(list);
  }

  static isEmptyStringList(list: string[]): boolean {
    if (list === undefined || list == null || list.length === 0) {
      return true;
    }
    return list.length === 1 && this.isEmpty(list[0]);
  }

  static isNotEmptyStringList(list: string[]): boolean {
    return !this.isEmptyStringList(list);
  }

  static isEmpty(str: string): boolean {
    return !(str !== undefined && str != null && str.trim().length > 0);
  }

  static isNotEmpty(str: string): boolean {
    return !this.isEmpty(str);
  }

  static isEmptyAny(any: any): boolean {
    return !(any !== undefined && any != null);
  }

  static isNotEmptyAny(any: any): boolean {
    return !this.isEmptyAny(any);
  }

}
