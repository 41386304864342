import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {createFormBuilderChoiceForm, FormBuilderChoice} from './store/form-builder-choice.model';
import {NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {LoadingService} from '../../../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {FormBuilderChoiceService} from './store/form-builder-choice.service';
import {createFormBuilderQuestion} from '../../store/form-builder-question.model';
import {AlertService} from '../../../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-form-builder-choice',
  templateUrl: './form-builder-choice.component.html',
  styleUrls: ['./form-builder-choice.component.scss']
})
export class FormBuilderChoiceComponent implements OnInit {

  @Input() option: FormBuilderChoice;
  @Input() isCreating: boolean;
  @Input() isEditable: boolean;
  @Input() questionId: number;

  @Output() refresh = new EventEmitter<{}>();

  options: NgbModalOptions;
  profileForm: FormGroup;

  constructor(private modalService: ModalService,
              public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private loadingService: LoadingService,
              private alertService: AlertService,
              private formBuilderChoiceService: FormBuilderChoiceService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: `${(this.isCreating ? 'Add' : 'Edit')} Option`,
      centered: true
    };
    this.buildForm();
  }

  buildForm() {
    if (this.isCreating) {
      this.profileForm = createFormBuilderChoiceForm(this.formBuilder, {
        dynamicFormQuestion: createFormBuilderQuestion({id: this.questionId})
      });
    } else {
      if (this.profileForm) {
        this.profileForm.patchValue(this.option);
      } else {
        this.profileForm = createFormBuilderChoiceForm(this.formBuilder, this.option);
      }
    }
  }

  closeModal(reason?: string) {
    this.activeModal.close(reason);
  }

  createOrUpdateOption(form: FormGroup) {
    this.alertService.resetAlert();
    if (this.formIsValid(form)) {
      this.loadingService.setIsLoading(true);

      const value: FormBuilderChoice = form.value;
      if (value.id) {
        this.updateOption(value);
      } else {
        this.createOption(value);
      }

    } else {
      // const firstNameValid = form.controls.firstName.valid;
      // const lastNameValid = form.controls.lastName.valid;
      // const usernameValid = form.controls.username.valid;
      //
      // if (!firstNameValid || !lastNameValid || !usernameValid) {
      this.alertService.setAlert('Complete all required fields', 'error', 'formBuilderChoice');
      // }
      this.loadingService.setIsLoading(false);
    }
  }

  private formIsValid(form: FormGroup): boolean {
    return form.valid;
  }

  private createOption(option: FormBuilderChoice) {
    this.formBuilderChoiceService.create(option).subscribe(saved => {
      this.loadingService.setIsLoading(false);
      this.isCreating = false;
      this.option = saved;
      this.buildForm();
      this.refresh.emit({
        status: 'created',
        option: saved
      });
      this.loadingService.setIsLoading(false);
      this.closeModal('saved');
    }, () => {
      this.loadingService.setIsLoading(false);
      this.alertService.setAlert('Failed to create option', 'error', 'formBuilderChoice');
    });
  }

  private updateOption(option: FormBuilderChoice) {
    this.formBuilderChoiceService.update(option).subscribe(saved => {
      this.loadingService.setIsLoading(false);
      this.option = saved;
      this.buildForm();
      this.refresh.emit({
        status: 'updated',
        option: saved
      });
      this.loadingService.setIsLoading(false);
      this.closeModal('saved');
    }, () => {
      this.loadingService.setIsLoading(false);
      this.alertService.setAlert('Failed to update option', 'error', 'formBuilderChoice');
    });
  }
}
