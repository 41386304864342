import {Pipe, PipeTransform} from '@angular/core';
import {ChoiceInputItem} from '../../../form-elements/models/form-elements.model';

@Pipe({
  name: 'multipleChoiceInputType'
})
export class MultipleChoiceInputTypePipe implements PipeTransform {

  transform(values: string[], choices: ChoiceInputItem[]): string[] {
    return values.map(val => {
      return this.getTypeByValue(val, choices);
    });
  }

  getTypeByValue(code: string, choiceInputItem: ChoiceInputItem[]): string {
    const found = choiceInputItem.find(item => item.value === code);
    if (found) {
      return found.label;
    }

    return 'Unknown';
  }

}
