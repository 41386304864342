import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-cs-financial-situation-funder',
  templateUrl: './cs-financial-situation-funder.component.html',
  styleUrls: ['./cs-financial-situation-funder.component.scss']
})
export class CsFinancialSituationFunderComponent implements OnInit {

  @Input() funderForm: FormGroup;
  @Input() formSubmitted: boolean;

  childFormTouched: boolean;

  constructor() {
  }

  ngOnInit() {
    this.childFormTouched = this.funderForm.touched;
  }

}
