import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'workPlanStatus'
})
export class WorkPlanStatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'ACTIVE':
        return 'in progress (coordinator)';
      case 'WITH_APPLICANT':
        return 'in progress (implementer)';
      case 'SUBMITTED':
        return 'submitted';
      case 'APPROVED':
        return 'approved';
    }
  }

}
