import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {GrantWorkflow} from '../../../../../grant-workflow/store/grant-workflow.model';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../../../../../shared/modules/shared-common/services/modal/modal.service';

@Component({
  selector: 'app-work-plan-messages',
  templateUrl: './work-plan-messages.component.html',
  styleUrls: ['./work-plan-messages.component.scss']
})
export class WorkPlanMessagesComponent implements OnInit {

  @ViewChild('messagesReview', {static: true}) messagesReview: ElementRef;
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() workflow: GrantWorkflow;

  chatExpanded: boolean;
  options: NgbModalOptions;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Work Plan Messages',
      centered: true,
    };
  }

  openMessagesModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  scroll(direction: string) {
    if (this.messagesReview) {
      // Buttons to scroll the chat in cases where it might be too long to manually scroll
      direction === 'up' ?
        this.messagesReview.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        }) :
        this.messagesReview.nativeElement.scrollTo({
          top: 1500,
          behavior: 'smooth'
        });
    }
  }
}
