<div class="wrapper" #top>
    <div class="page-header clear-filter">
        <div class="page-header-image"></div>
        <div class="w-100 flex-center flex-column">
            <h1 class="text-light mb-4 mt-4">About</h1>
            <div class="card bg-dark">
                <div class="card-body col-lg-10 col-md-10 col-12 mx-auto">
                    <p class="text-center mt-4">
                        SoCapX or the Social Capital Exchange is a community driven, social enterprise platform
                        that aims to improve development intervention by improving visibility and collaboration
                        while also contributing towards greater efficiency, effectiveness and sustainability
                        within the sector.
                    </p>
                    <p class="text-center mt-4">
                        The platform delivers value through a hybrid partnership model, leveraging cutting
                        edge technology, direct engagement and most importantly strategic partnership.
                    </p>

                    <p class="text-center p-4">
                        We recognise that in order to achieve this we need to deliver a balanced value model,
                        where all stakeholders within the platform derive consistent, tangible value by
                        participating on the platform.
                        These stakeholders include:
                    </p>
                    <img src="../../../../../assets/img/Scocapx1-about2.png" alt="">
                    <p class="mt-4">
                        The technology stack is the cornerstone of the SoCapX value delivery model, it
                        incorporates a number of dynamic functional components:
                    </p>
                    <p class="mt-2"><span class="font-weight-normal">User Management</span> - Multi-level permission user access</p>
                    <p class="mt-2"><span class="font-weight-normal">Integrated User Support Channels</span> - allows tiered support , accelerated
                        data-gathering efforts and accelerated partner on-boarding</p>

                    <p class="mt-2"><span class="font-weight-normal">Software Applications</span> - Grant Management, Monitoring and Evaluation,
                        Self assessment, Searchable Knowledge Base, Directory Management</p>

                    <p class="mt-2 font-weight-normal">Application Programming Interfaces</p>
                    <p class="mt-2 font-weight-normal">Business Intelligence and Reporting</p>
                    <p class="mt-2"><span class="font-weight-normal">Integration Layer</span> – Third party system integration capability</p>
                    <p class="mt-2"><span class="font-weight-normal">Security and Storage</span> – Managed access, data security, redundancy & continuity</p>
                    <p class="mt-2"><span class="font-weight-normal">Blockchain</span> – Distributed Unique User Identity, Attributes and Rating (DUID) *</p>
                    <p class="mt-2"><span class="font-weight-normal">Machine Learning and AI</span> – Adaptive user experience *</p>
                    <p class="mt-2"><span class="font-weight-normal">Transaction management</span> – Online payment processing, Social Impact Bonds *</p>
                </div>
            </div>
        </div>
        <div class="content-separator"></div>
    <div class="p-4 bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-10 ml-auto mr-auto text-center">
                    <p class="text-light mt-4">
                            <span class="font-weight-normal">SoCapX</span> has been established as a social enterprise with a self funding model to ensure
                            sustainability and independence. Independence is a key requirement to provide an
                            agnostic community service for collective good. While <span class="font-weight-normal">SoCapX</span> has, and will continue
                            to partner with organisations and investors to build the platform capabilities,
                            this is a non-exclusive and non-prescriptive basis. We aim to align ourselves with
                            partners that are committed to our principles of:
                    </p>
                    <p class="text-light mt-4 font-weight-normal">Collective Purpose</p>

                    <p class="text-light mt-4 font-weight-normal">Collaboration</p>

                    <p class="text-light mt-4 font-weight-normal">People focused</p>

                    <p class="text-light mt-4 font-weight-normal">Sustainability centered</p>

                    <p class="text-light mt-4 font-weight-normal">Committed to efficiency and effectiveness.</p>
                    <img src="../../../../../assets/img/Scocapx2-about1.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="flex-center p-4">
        <button class="btn btn-outline-warning" (click)="scrollToTarget(top)">
            <span class="flex-center">
                <span class="mr-1">To top</span>
                <i class="fas fa-arrow-alt-circle-up"></i>
            </span>
        </button></div>
    </div>
</div>
