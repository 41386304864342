import {Injectable} from '@angular/core';
import {ApplicationStore} from './application.store';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {Application} from './application.model';

@Injectable({providedIn: 'root'})
export class ApplicationService {

  private url = `${environment.apiUrl}/application`;

  constructor(protected store: ApplicationStore,
              private http: HttpClient) {
  }

  getApplicationByWorkflowId(workflowId: number): Observable<Application> {
    const url = `${this.url}?grantWorkflowId=${workflowId}`;
    return this.http.get<Application>(url).pipe(
      tap(item => {
        return item;
      })
    );
  }

  createApplicationByWorkflowId(application: Application,
                                workflowId: number,
                                isSubmitting?: boolean,
                                username?: string,
                                fromReview?: boolean) {
    let url = `${this.url}?grantWorkflowId=${workflowId}`;
    if (isSubmitting) {
      url = `${url}&isSubmitting=${isSubmitting}`;
    }
    if (username) {
      url = `${url}&username=${username}`;
    }
    if (fromReview) {
      url = `${url}&fromReview=${fromReview}`;
    }
    return this.http.post<Application>(url, application).pipe(
      tap(() => {
        return;
      })
    );
  }

  updateApplication(application: Application,
                    applicationId: number,
                    isSubmitting: boolean,
                    username: string,
                    fromReview?: boolean) {
    let url = `${this.url}/${applicationId}?isSubmitting=${isSubmitting ? 'true' : 'false'}`;
    if (username) {
      url = `${url}&username=${username}`;
    }
    if (fromReview) {
      url = `${url}&fromReview=${fromReview}`;
    }
    return this.http.put<Application>(url, application).pipe(
      tap(() => {
        return;
      })
    );
  }

  resetApplication(workflowId: number) {
    const url = `${this.url}/reset-submitted?grantWorkflowId=${workflowId}`;
    return this.http.put<any>(url, undefined).pipe(
      tap(() => {
        return;
      })
    );
  }

}
