import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {WorkPlanAndTargetsState, WorkPlanAndTargetsStore} from './work-plan-and-targets.store';

@Injectable({providedIn: 'root'})
export class WorkPlanAndTargetsQuery extends Query<WorkPlanAndTargetsState> {

  private workflow$ = this.select(state => state.workflow);
  private workPlans$ = this.select(state => state.workPlans);
  private selectedWorkPlan$ = this.select(state => state.selectedWorkPlan);
  private selectedObjective$ = this.select(state => state.selectedObjective);

  constructor(protected store: WorkPlanAndTargetsStore) {
    super(store);
  }

  getWorkflowObservable() {
    return this.workflow$;
  }

  getWorkPlansObservable() {
    return this.workPlans$;
  }

  getSelectedWorkPlanObservable() {
    return this.selectedWorkPlan$;
  }

  getSelectedObjectiveObservable() {
    return this.selectedObjective$;
  }

}
