<ngx-charts-bar-vertical-2d
  [view]="view"
  [scheme]="colorScheme"
  [results]="chartData"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [legendTitle]="legendTitle"
  [roundEdges]="false"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)">
</ngx-charts-bar-vertical-2d>
<!--  (select)="onSelect($event)"-->
