import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {GovernanceEndpointType} from '../store/cs-governance.model';

@Injectable({
  providedIn: 'root'
})
export class CsGovernanceAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/governance`;

  public managementStructureSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $managementStructure = this.managementStructureSource.asObservable();

  public decisionMakingSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $decisionMaking = this.decisionMakingSource.asObservable();

  public successionPlanningSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $successionPlanning = this.successionPlanningSource.asObservable();

  public founderRelationshipSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $founderRelationship = this.founderRelationshipSource.asObservable();

  public directorsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $directors = this.directorsSource.asObservable();

  public policyElementSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $policyElement = this.policyElementSource.asObservable();

  public boardMemberProcessSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $boardMemberProcess = this.boardMemberProcessSource.asObservable();

  public boardMemberTermSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $boardMemberTerm = this.boardMemberTermSource.asObservable();

  public meetingMinutesSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $meetingMinutes = this.meetingMinutesSource.asObservable();

  public reportFrequencySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $reportFrequency = this.reportFrequencySource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.managementStructureSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case GovernanceEndpointType.MANAGEMENT_STRUCTURE: {
          this.managementStructureSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.DECISION_MAKING: {
          this.decisionMakingSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.SUCCESSION_PLANNING: {
          this.successionPlanningSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.FOUNDER_RELATIONSHIP: {
          this.founderRelationshipSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.DIRECTORS: {
          this.directorsSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.POLICY_ELEMENT: {
          this.policyElementSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.BOARD_MEMBER_PROCESS: {
          this.boardMemberProcessSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.BOARD_MEMBER_TERM: {
          this.boardMemberTermSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.MEETING_MINUTES: {
          this.meetingMinutesSource.next(answerListDTO);
          break;
        }
        case GovernanceEndpointType.REPORT_FREQUENCY: {
          this.reportFrequencySource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
