import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FinancialManagementEndpointType} from '../store/cs-financial-management.model';

@Injectable({
  providedIn: 'root'
})
export class CsFinancialManagementAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/financial-management`;

  public budgetCreationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $budgetCreation = this.budgetCreationSource.asObservable();

  public financialPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $financialPolicy = this.financialPolicySource.asObservable();

  public financialPolicyElementSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $financialPolicyElement = this.financialPolicyElementSource.asObservable();

  public assetInventorySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $assetInventory = this.assetInventorySource.asObservable();

  public pettyCashSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $pettyCash = this.pettyCashSource.asObservable();

  public timeSheetSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $timeSheet = this.timeSheetSource.asObservable();

  public accountingSystemSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $accountingSystem = this.accountingSystemSource.asObservable();

  public financialTrackingSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $financialTracking = this.financialTrackingSource.asObservable();

  public financialControlSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $financialControl = this.financialControlSource.asObservable();

  public paymentMethodSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $paymentMethod = this.paymentMethodSource.asObservable();

  public durationOfFinancialRecordsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $durationOfFinancialRecords = this.durationOfFinancialRecordsSource.asObservable();

  public annualAuditSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $annualAudit = this.annualAuditSource.asObservable();

  public annualFinancialSystemSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $annualFinancialSystem = this.annualFinancialSystemSource.asObservable();

  public financialManagementTeamSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $financialManagementTeam = this.financialManagementTeamSource.asObservable();

  public headOfFinanceQualificationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $headOfFinanceQualification = this.headOfFinanceQualificationSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.budgetCreationSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDto of list) {
      switch (answerListDto.enumType) {
        case FinancialManagementEndpointType.BUDGET_CREATION: {
          this.budgetCreationSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.FINANCIAL_POLICY: {
          this.financialPolicySource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.FINANCIAL_POLICY_ELEMENT: {
          this.financialPolicyElementSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.ASSET_INVENTORY: {
          this.assetInventorySource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.PETTY_CASH: {
          this.pettyCashSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.TIME_SHEET: {
          this.timeSheetSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.ACCOUNTING_SYSTEM: {
          this.accountingSystemSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.FINANCIAL_TRACKING: {
          this.financialTrackingSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.FINANCIAL_CONTROL: {
          this.financialControlSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.PAYMENT_METHOD: {
          this.paymentMethodSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.DURATION_OF_FINANCIAL_RECORDS: {
          this.durationOfFinancialRecordsSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.ANNUAL_AUDIT: {
          this.annualAuditSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.ANNUAL_FINANCIAL_SYSTEM: {
          this.annualFinancialSystemSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.FINANCIAL_MANAGEMENT_TEAM: {
          this.financialManagementTeamSource.next(answerListDto);
          break;
        }
        case FinancialManagementEndpointType.HEAD_OF_FINANCE_QUALIFICATION: {
          this.headOfFinanceQualificationSource.next(answerListDto);
          break;
        }
      }
    }
  }
}
