import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {CustomApplicationStore} from './custom-application.store';
import {CustomApplication} from './custom-application.model';

@Injectable({providedIn: 'root'})
export class CustomApplicationService {

  private url = `${environment.apiUrl}/custom-application`;

  constructor(protected store: CustomApplicationStore,
              private http: HttpClient) {
  }

  getCustomApplicationByWorkflowId(workflowId: number): Observable<CustomApplication> {
    const url = `${this.url}?grantWorkflowId=${workflowId}`;
    return this.http.get<CustomApplication>(url).pipe(
      tap(item => {
        return item;
      })
    );
  }

  createCustomApplicationByWorkflowId(customApplication: CustomApplication,
                                      workflowId: number,
                                      isSubmitting?: boolean,
                                      username?: string,
                                      fromReview?: boolean) {
    let url = `${this.url}?grantWorkflowId=${workflowId}`;
    if (isSubmitting) {
      url = `${url}&isSubmitting=${isSubmitting}`;
    }
    if (username) {
      url = `${url}&username=${username}`;
    }
    if (fromReview) {
      url = `${url}&fromReview=${fromReview}`;
    }
    return this.http.post<CustomApplication>(url, customApplication).pipe(
      tap(() => {
        return;
      })
    );
  }

  updateCustomApplication(customApplication: CustomApplication,
                          customApplicationId: number,
                          isSubmitting: boolean,
                          username: string,
                          fromReview?: boolean) {
    let url = `${this.url}/${customApplicationId}?isSubmitting=${isSubmitting ? 'true' : 'false'}`;
    if (username) {
      url = `${url}&username=${username}`;
    }
    if (fromReview) {
      url = `${url}&fromReview=${fromReview}`;
    }
    return this.http.put<CustomApplication>(url, customApplication).pipe(
      tap(() => {
        return;
      })
    );
  }

  resetCustomApplication(workflowId: number) {
    const url = `${this.url}/reset-submitted?grantWorkflowId=${workflowId}`;
    return this.http.put<any>(url, undefined).pipe(
      tap(() => {
        return;
      })
    );
  }

}
