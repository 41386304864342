import {Injectable} from '@angular/core';
import {Organisation} from './organisation.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface OrganisationState extends EntityState<Organisation> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'organisation',
})
export class OrganisationStore extends EntityStore<OrganisationState> {

  constructor() {
    super();
  }

}

