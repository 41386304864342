import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {ObjectiveService} from '../store/objective.service';
import {createObjectiveForm} from '../store/objective.model';
import {WorkPlan} from '../../../../../../store/work-plan-and-targets.model';
import {AlertService} from '../../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-add-objective',
  templateUrl: './add-objective.component.html',
  styleUrls: ['./add-objective.component.scss']
})
export class AddObjectiveComponent implements OnInit, OnChanges {

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() workPlan: WorkPlan;
  @Input() isReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;

  options: NgbModalOptions;

  profileForm: FormGroup;
  errorText: string;

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private objectiveService: ObjectiveService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.workPlan && changes.workPlan.currentValue) {
      this.profileForm = createObjectiveForm(this.formBuilder, {
        workPlan: changes.workPlan.currentValue
      });
    }
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Add Objective',
      centered: true,
      size: 'lg'
    };
  }

  openObjectiveModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  createObjective() {
    if (this.profileForm.valid) {
      this.objectiveService.create(this.profileForm.value).subscribe(() => {
        this.profileForm.reset();
        this.closeModal();
      }, () => {
        this.errorText = 'Failed to create Objective';
        this.alertService.setAlert(this.errorText, 'error', 'addObjective');
      });
    } else {
      this.errorText = 'Please complete all fields before saving.';
      this.alertService.setAlert(this.errorText, 'error', 'addObjective');
    }
  }
}
