import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MarketingAndPromotionEndpointType} from '../store/cs-marketing-and-promotion.model';

@Injectable({
  providedIn: 'root'
})
export class CsMarketingAndPromotionAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/marketing-and-promotion`;

  public marketingStrategySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $marketingStrategy = this.marketingStrategySource.asObservable();

  public communicationFrequencySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $communicationFrequency = this.communicationFrequencySource.asObservable();

  public storyFrequencySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $storyFrequency = this.storyFrequencySource.asObservable();

  public marketingStaffSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $marketingStaff = this.marketingStaffSource.asObservable();

  public logoSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $logo = this.logoSource.asObservable();

  public brandingPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $brandingPolicy = this.brandingPolicySource.asObservable();

  public brochureSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $brochure = this.brochureSource.asObservable();

  public websiteSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $website = this.websiteSource.asObservable();

  public socialMediaSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $socialMedia = this.socialMediaSource.asObservable();

  public newsletterSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $newsletter = this.newsletterSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.marketingStrategySource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case MarketingAndPromotionEndpointType.MARKETING_STRATEGY: {
          this.marketingStrategySource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.COMMUNICATION_FREQUENCY: {
          this.communicationFrequencySource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.STORY_FREQUENCY: {
          this.storyFrequencySource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.MARKETING_STAFF: {
          this.marketingStaffSource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.LOGO: {
          this.logoSource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.BRANDING_POLICY: {
          this.brandingPolicySource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.BROCHURE: {
          this.brochureSource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.WEBSITE: {
          this.websiteSource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.SOCIAL_MEDIA: {
          this.socialMediaSource.next(answerListDTO);
          break;
        }
        case MarketingAndPromotionEndpointType.NEWSLETTER: {
          this.newsletterSource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
