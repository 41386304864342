import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-work-plan-and-targets',
  templateUrl: './work-plan-and-targets.component.html',
  styleUrls: ['./work-plan-and-targets.component.scss']
})
export class WorkPlanAndTargetsComponent implements OnInit {

  @ViewChild('pageTop', {static: true}) pageTop: ElementRef<HTMLElement>;

  currentTab = 'workPlan';

  constructor() {
  }

  ngOnInit() {
  }

  changeTab(tabName: string) {
    this.currentTab = tabName;
    this.pageTop.nativeElement.scrollTo({
      top: 0
    });
  }
}
