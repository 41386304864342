<div #pageTop>
  <div class="w-100 card p-4">
    <button class="close-modal" type="button" (click)="closeModal()">
      <i class="far fa-times-circle"></i>
    </button>
    <form class="form" [formGroup]="profileForm">

      <div class="card-body">
        <app-spinner [loadingObs]="loadingService.getIsLoading()">
          <h4 class="category text-primary text-center">
            {{isCreating ? 'Add' : 'Edit'}} Question
          </h4>

          <hr class="dropdown-divider border-primary w-50 mx-auto mb-2">

          <app-alert
            class="w-100"
            [id]="'formBuilderQuestion'"
          ></app-alert>

          <app-input-number
            class="w-45"
            [label]="'Order'"
            [id]="'order'"
            [isRequired]="true"
            [style]="'default'"
            [theme]="'light'"
            [isReadOnly]="!isEditable"
            [isUnique]="true"
            [uniqueId]="profileForm.value?.id"
            [urlFragment]="'dynamic-form-question/order'"
            [uniqueAdditionalParam]="'&formId=' + formId"
            [uniqueError]="'This order number already exists. Please try another.'"
            formControlName="order"
          ></app-input-number>

          <app-input-text
            [label]="'Question'"
            [id]="'label'"
            [style]="'default'"
            [theme]="'light'"
            [isRequired]="true"
            [isReadOnly]="!isEditable"
            placeholder="Example: What are you requesting funding for?"
            formControlName="label"
          ></app-input-text>

          <app-input-text
            [label]="'Identifier'"
            [id]="'key'"
            [style]="'default'"
            [theme]="'light'"
            [isRequired]="true"
            [isReadOnly]="!isEditable"
            [isUnique]="true"
            [uniqueId]="profileForm.value?.id"
            [urlFragment]="'dynamic-form-question/key'"
            [uniqueAdditionalParam]="'&formId=' + formId"
            [uniqueError]="'This identifier already exists. Please try another.'"
            placeholder="Example: requestedFunding"
            formControlName="key"
          ></app-input-text>

          <app-input-radio
            class="w-30"
            [label]="'Mandatory Question'"
            [id]="'required'"
            [name]="'required'"
            [choices]="requiredChoices"
            [style]="'default'"
            [theme]="'light'"
            [isRequired]="true"
            [isReadOnly]="!isEditable"
            formControlName="required"
          ></app-input-radio>

          <app-input-radio
            [id]="'controlType'"
            [name]="'controlType'"
            [label]="'Answer Type'"
            [choices]="controlTypeChoices"
            [theme]="'light'"
            [style]="'default'"
            [isRequired]="true"
            [isReadOnly]="!isEditable"
            formControlName="controlType"
          ></app-input-radio>
        </app-spinner>
      </div>

      <!-- Options -->
      <ng-container *ngIf="hasOptions()">
        <div class="w-100 card">
          <div class="card-body">
            <div class="flex-around bg-dark">
              <div class="w-33"></div>
              <div class="w-33 flex-center">
                <h4 class="text-light text-center">
                  Options
                </h4>
              </div>
              <div class="w-33 flex-end">
                <button
                  *ngIf="isEditable"
                  class="btn btn-primary btn-sm mr-2"
                  (click)="openOptionModal(undefined, true)"
                >
                  Add Option
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 tables">
                <app-mbl-table
                  #table
                  [columns]="columns"
                  [rows]="questionDTO.options"
                ></app-mbl-table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="flex-center flex-column">
        <div class="flex-center" [hidden]="!hasSaved">
          <p class="mr-2 font-weight-normal">Saved Successfully</p>
          <i class="far fa-check-circle text-success"></i>
        </div>
        <div class="flex-center">
          <button class="btn btn-danger btn-lg mr-2" type="button" (click)="closeModal()">Close</button>
          <button *ngIf="isEditable" class="btn btn-primary btn-lg" (click)="createOrUpdateQuestion(profileForm)">Save</button>
        </div>
      </div>
    </form>

  </div>
</div>


<ng-template #actionTmpl let-row="row" let-value="value">
  <div class="dropdown btn-group text-center">
    <a ngbDropdown class="nav-item dropdown">
      <div class="nav-link dropdown-toggle user-toggle" ngbDropdownToggle>
        <button class="table-action-btn view">
          <i class="fas fa-ellipsis-h"></i>
        </button>
      </div>

      <div ngbDropdownMenu class="dropdown-menu bg-dark text-light">

        <a class="dropdown-item cursor-pointer text-light text-center" (click)="openOptionModal(row, false)">
          <p>Edit</p>
        </a>

        <hr class="dropdown-divider w-75 mx-auto">

        <a class="dropdown-item cursor-pointer text-light" (click)="delete(row)">
          <p>Delete</p>
        </a>

      </div>
    </a>
  </div>
</ng-template>
