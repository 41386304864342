import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {validateFormGroup} from '../../../../../../shared/utils/form-validation.util';

@Injectable({
  providedIn: 'root'
})
export class CapacitySpectrumValidationService {

  public capacitySpectrumValidationSource = new BehaviorSubject<boolean>(null);
  public $capacitySpectrumValid = this.capacitySpectrumValidationSource.asObservable();

  public orgStructureValidSource = new BehaviorSubject<boolean>(null);
  public $orgStructureValid = this.orgStructureValidSource.asObservable();

  public humanResourcesValidSource = new BehaviorSubject<boolean>(null);
  public $humanResourcesValid = this.humanResourcesValidSource.asObservable();

  public governanceValidSource = new BehaviorSubject<boolean>(null);
  public $governanceValid = this.governanceValidSource.asObservable();

  public financialManagementValidSource = new BehaviorSubject<boolean>(null);
  public $financialManagementValid = this.financialManagementValidSource.asObservable();

  public financialSituationValidSource = new BehaviorSubject<boolean>(null);
  public $financialSituationValid = this.financialSituationValidSource.asObservable();

  public mneValidSource = new BehaviorSubject<boolean>(null);
  public $mneValid = this.mneValidSource.asObservable();

  public marketingAndPromotionValidSource = new BehaviorSubject<boolean>(null);
  public $marketingAndPromotionValid = this.marketingAndPromotionValidSource.asObservable();

  public networkAndLinkagesValidSource = new BehaviorSubject<boolean>(null);
  public $networkAndLinkagesValid = this.networkAndLinkagesValidSource.asObservable();

  public innovationValidSource = new BehaviorSubject<boolean>(null);
  public $innovationValid = this.innovationValidSource.asObservable();

  public aspirationalValidSource = new BehaviorSubject<boolean>(null);
  public $aspirationalValid = this.aspirationalValidSource.asObservable();

  // public supportingDocValidSource = new BehaviorSubject<boolean>(null);
  // public $supportingDocsValid = this.supportingDocValidSource.asObservable();

  public capacitySavedSource = new BehaviorSubject<boolean>(null);
  public $capacitySaved = this.capacitySavedSource.asObservable();

  constructor() {
  }

  validateEntity(entity: any,
                 type: 'capacitySpectrum' | 'organizationalStructure' | 'humanResources' | 'governance' |
                   'mne' | 'marketingAndPromotion' | 'networkAndLinkages' | 'aspirational' |
                   'financialManagement' | 'financialSituation' | 'innovation') {
    let valid: boolean;
    if (entity) {
      switch (type) {
        case 'capacitySpectrum':
          valid = this.validateAll(entity);
          this.capacitySpectrumValidationSource.next(valid);
          break;
        case 'organizationalStructure':
          valid = validateFormGroup(entity);
          this.orgStructureValidSource.next(valid);
          break;
        case 'humanResources':
          valid = validateFormGroup(entity);
          this.humanResourcesValidSource.next(valid);
          break;
        case 'governance':
          valid = validateFormGroup(entity);
          this.governanceValidSource.next(valid);
          break;
        case 'financialManagement':
          valid = validateFormGroup(entity);
          this.financialManagementValidSource.next(valid);
          break;
        case 'financialSituation':
          valid = validateFormGroup(entity);
          this.financialSituationValidSource.next(valid);
          break;
        case 'mne':
          valid = validateFormGroup(entity);
          this.mneValidSource.next(valid);
          break;
        case 'marketingAndPromotion':
          valid = validateFormGroup(entity);
          this.marketingAndPromotionValidSource.next(valid);
          break;
        case 'networkAndLinkages':
          valid = validateFormGroup(entity);
          this.networkAndLinkagesValidSource.next(valid);
          break;
        case 'innovation':
          valid = validateFormGroup(entity);
          this.innovationValidSource.next(valid);
          break;
        case 'aspirational':
          valid = validateFormGroup(entity);
          this.aspirationalValidSource.next(valid);
          break;
        // case 'supportingDocuments':
        //   valid = validateFormGroup(entity);
        //   this.supportingDocValidSource.next(valid);
        //   break;
        default:
          break;
      }
    }
  }


  validateAll(entity: any): boolean {
    if (entity && entity.controls) {
      return validateFormGroup(entity.controls.organizationalStructure as FormGroup)
        && validateFormGroup(entity.controls.humanResources as FormGroup)
        && validateFormGroup(entity.controls.governance as FormGroup)
        && validateFormGroup(entity.controls.financialManagement as FormGroup)
        && validateFormGroup(entity.controls.financialSituation as FormGroup)
        && validateFormGroup(entity.controls.mneReportingAndPlanning as FormGroup)
        && validateFormGroup(entity.controls.marketingAndPromotion as FormGroup)
        && validateFormGroup(entity.controls.networkAndLinkages as FormGroup)
        && validateFormGroup(entity.controls.innovationAndTechnology as FormGroup)
        && validateFormGroup(entity.controls.aspirational as FormGroup);
    }
  }

  validateAllEntities(entity: FormGroup) {
    const capacitySpectrumValid = validateFormGroup(entity);
    this.capacitySpectrumValidationSource.next(capacitySpectrumValid);

    const orgStructureValid = validateFormGroup(entity.controls.organizationalStructure as FormGroup);
    this.orgStructureValidSource.next(orgStructureValid);

    const humanResourcesValid = validateFormGroup(entity.controls.humanResources as FormGroup);
    this.humanResourcesValidSource.next(humanResourcesValid);

    const governanceValid = validateFormGroup(entity.controls.governance as FormGroup);
    this.governanceValidSource.next(governanceValid);

    const financialManagementValid = validateFormGroup(entity.controls.financialManagement as FormGroup);
    this.financialManagementValidSource.next(financialManagementValid);

    const financialSituationValid = validateFormGroup(entity.controls.financialSituation as FormGroup);
    this.financialSituationValidSource.next(financialSituationValid);

    const mneValid = validateFormGroup(entity.controls.mneReportingAndPlanning as FormGroup);
    this.mneValidSource.next(mneValid);

    const marketingAndPromotionValid = validateFormGroup(entity.controls.marketingAndPromotion as FormGroup);
    this.marketingAndPromotionValidSource.next(marketingAndPromotionValid);

    const networkAndLinkagesValid = validateFormGroup(entity.controls.networkAndLinkages as FormGroup);
    this.networkAndLinkagesValidSource.next(networkAndLinkagesValid);

    const innovationValid = validateFormGroup(entity.controls.innovationAndTechnology as FormGroup);
    this.innovationValidSource.next(innovationValid);

    const aspirationalValid = validateFormGroup(entity.controls.aspirational as FormGroup);
    this.aspirationalValidSource.next(aspirationalValid);

  }

  unValidateAll() {
    this.capacitySpectrumValidationSource.next(undefined);
    this.orgStructureValidSource.next(undefined);
    this.humanResourcesValidSource.next(undefined);
    this.governanceValidSource.next(undefined);
    this.financialManagementValidSource.next(undefined);
    this.financialSituationValidSource.next(undefined);
    this.mneValidSource.next(undefined);
    this.marketingAndPromotionValidSource.next(undefined);
    this.networkAndLinkagesValidSource.next(undefined);
    this.innovationValidSource.next(undefined);
    this.aspirationalValidSource.next(undefined);
  }
}
