<!--<div class="position-relative">-->
<!--  <div class="toggle widget flex-start">-->
<!--    <div class="toggle-item">-->
<!--    <span class="check">-->
<!--      <ng-container *ngIf="value === true">-->
<!--        <i class="fas fa-check text-light"></i>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="value !== true">-->
<!--        <i class="fas fa-times text-light"></i>-->
<!--      </ng-container>-->
<!--    </span>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="position-relative">
  <label [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']">
    {{label}}
  </label>

  <fieldset>
    <div class="toggle widget flex-start">
      <label class="toggle-item widget-disabled">
        <span class="check widget-disabled">
          <ng-container *ngIf="value === true">
            <i class="fas fa-check text-light"></i>
          </ng-container>
          <ng-container *ngIf="value !== true">
            <i class="fas fa-times text-light"></i>
          </ng-container>
        </span>
      </label>
    </div>
  </fieldset>
</div>
