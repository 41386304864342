import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {animateExpansionPanel} from '../animations/animate-expansion-panel.animation';

@Component({
  selector: 'app-accordion-two',
  templateUrl: './accordion-two.component.html',
  styleUrls: ['./accordion-two.component.scss'],
  animations: [animateExpansionPanel]
})
export class AccordionTwoComponent implements OnInit {
  @ViewChild('templateAnchor', {static: true}) templateAnchor: ElementRef<HTMLElement>;

  @Input() title: string;
  @Input() status?: string;

  // Set initial state to prevent flash of open content on initial page load.
  @Input() panelState = 'initial';

  constructor() {
  }

  ngOnInit() {
  }

  changePanelState() {
    switch (this.panelState) {
      case 'initial':
        this.panelState = 'open';
        this.templateAnchor.nativeElement.scrollIntoView({behavior: 'smooth'});
        break;
      case 'open':
        this.panelState = 'closed';
        break;
      case 'closed':
        this.panelState = 'open';
        this.templateAnchor.nativeElement.scrollIntoView({behavior: 'smooth'});
        break;
      default:
        break;
    }
  }

}
