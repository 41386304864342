<div [class.esx-page]="!fromApplicationHistory" [class.wrapper]="!fromApplicationHistory">

  <div *ngIf="!fromGrantReview && !fromApplicationHistory" class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Application Funding Request for {{grantWindow.name}} {{grantWindow.year}}
                from {{grantWindow.funder | funderName}}
                <button
                  type="button"
                  class="intro-text text-right btn-link"
                  placement="bottom-right"
                  [ngbPopover]="theIntroText"
                  [popoverClass]="'popover-width-700-light'"
                >
                  <i class="far fa-question-circle text-info text-shadow"></i>
                </button>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="text-center">
      Application closes on {{grantWindow.projectedApplicationEndDate | date:'medium'}} SAST
    </p>

  </div>

  <div
    [class.w-100]="!fromApplicationHistory"
    [class.mx-auto]="!fromApplicationHistory"
    [class.esx-content]="!fromApplicationHistory && !fromGrantReview"
  >
    <div
      class="p-1"
      [class.card]="!fromApplicationHistory"
      [class.mt-2]="!fromApplicationHistory"
    >

      <p *ngIf="fromApplicationHistory">
        <strong>Grant:</strong> {{grantWindow.name}} {{grantWindow.year}}
        <small>[{{grantWindow.funder | funderName}}]</small>
      </p>

      <h4 class="text-center" *ngIf="fromGrantReview">
        <span class="text-capitalize">Application
          <small *ngIf="customApplication">
            - Score[{{customApplication.applicationScore.score}} / {{customApplication.applicationScore.max}}]
          </small>
        </span>
      </h4>
      <hr class="w-75 mx-auto border-primary" *ngIf="fromGrantReview">

      <app-alert
        class="w-100"
        [id]="'customApplication'"
      ></app-alert>
      <app-spinner [loadingBool]="loading">

        <ng-container *ngIf="customApplicationForm">
          <form [formGroup]="customApplicationForm" class="w-95 mx-auto">

            <div class="d-flex justify-content-end">
              <ng-container *ngIf="!fromApplicationHistory; else historyButtons">
                <ng-container *ngIf="!isReadOnly; else scoreButton">

                  <ng-container *ngIf="!isNotApplicant; else adminButtons">
                    <button
                      class="btn btn-sm btn-success btn-outline-dark"
                      [disabled]="isReadOnly"
                      (click)="save()"
                    >
                      Save Application
                    </button>
                    <button
                      *ngIf="customApplicationForm.valid"
                      class="btn btn-sm btn-primary btn-outline-dark"
                      [disabled]="isReadOnly"
                      (click)="openSubmitModal(customApplicationForm)"
                    >
                      Submit Application
                    </button>

                    <!--// Create a mock button because the tooltip doesn't show if the button's [disabled] flag is true-->
                    <span
                      *ngIf="customApplicationForm.invalid"
                      class="btn mock-button"
                      placement="bottom-left"
                      ngbTooltip="Unable to submit application form. All mandatory fields must be completed first."
                    >
                  Submit Application
                </span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

            <app-form-capture
              [form]="customApplicationForm.controls.formCapture.controls.answers | asFormGroup"
              [formTemplate]="customApplication.formCapture.form"
              [isReadOnly]="isReadOnly || fromApplicationHistory"
            ></app-form-capture>

            <br>
            <h4 class="text-center">
              <span class="text-capitalize">Funding Request</span>
            </h4>
            <hr>

            <app-custom-funding-request
              [fundingRequestForm]="customApplicationForm.controls.fundingRequest | asFormGroup"
              [isReadOnly]="isReadOnly"
              [fromReview]="fromGrantReview"
            ></app-custom-funding-request>

          </form>
        </ng-container>
      </app-spinner>
    </div>
  </div>
</div>

<ng-template #scoreButton>
  <button
    *ngIf="isNotApplicant && customApplication.applicationScore"
    type="button"
    class="btn btn-secondary btn-sm"
    placement="bottom-right"
    [ngbPopover]="scoreInfo"
    [popoverClass]="'popover-width-700-light'"
  >
    Score
    <span class="badge badge-light pt-1">
      {{scorePercentageUtil.scoreDTOAsPercentageString(customApplication.applicationScore)}} %
    </span>
  </button>
</ng-template>

<ng-template #adminButtons>
  <button
    *ngIf="customApplication.applicationScore"
    type="button"
    class="btn btn-secondary btn-sm"
    placement="bottom-right"
    [ngbPopover]="scoreInfo"
    [popoverClass]="'popover-width-700-light'"
  >
    Score
    <span class="badge badge-light pt-1">
      {{scorePercentageUtil.scoreDTOAsPercentageString(customApplication.applicationScore)}} %
    </span>
  </button>
  <button class="btn btn-outline-primary" (click)="save()">
    Save Application
  </button>
</ng-template>

<ng-template #historyButtons>
  <button class="btn btn-sm btn-primary btn-outline-dark" (click)="downloadPdf()">Save as PDF</button>
</ng-template>

<ng-template #scoreInfo>
  <div class="mr-auto">

    <h4 class="text-shadow">
      Score
    </h4>
  </div>

  <table class="table table-striped table-bordered">
    <thead class="bg-dark text-light">
    <tr>
      <td class="text-light">Score</td>
      <td class="text-light">Total</td>
      <td class="text-light">Percentage</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{customApplication.applicationScore?.score || 0}}</td>
      <td>{{customApplication.applicationScore?.max || 0}}</td>
      <td>{{scorePercentageUtil.scoreDTOAsPercentageString(customApplication.applicationScore)}} %</td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #theIntroText>
  <div class="intro-text-wrapper">

  </div>
  <p>
    You need to complete the following section to detail what you would do with the funding
    you are requesting and how much funding you would need to complete the planned activities.
    The information you enter in this section should match the proposed project description
    that you wrote above. Please remember, all requested funding must be used to provide care
    and support for PLHIV, targeted HIV testing and counseling services and/or evidence based
    prevention programs.
  </p>

  <p>
    Note: For each of the planned activities you are requesting funding for, you are required
    to attach quotes and/or cost calculations in the supporting documentation section at the
    end of the application.
  </p>

  <p>
    You will need to provide your budget according to the following categories. Here are some
    examples of the types of items that can be included in each category:
  </p>
  <ul>
    <li>Equipment / furniture – These are products that you invest in and keep, such as containers,
      gazebos, cooler boxes, computers, etc.
    </li>
    <li>
      Personnel – This is any cost you want to pay to a person to provide a specific service,
      such as adherence club facilitators, community health workers, program directors, etc.
    </li>
    <li>
      Travel / transport – This is any cost that you want to be used for travel, including public
      transport, petrol costs, etc. Note: in the supporting documentation section, ensure you
      provide an attachment which shows how you calculated your travel costs.
    </li>
    <li>
      Supplies / Materials – These are consumables such as workbooks, gloves, venue hire, testing
      incentives, etc.
    </li>
    <li>
      Administration – These are operational costs, such as rent, electricity, airtime, etc. Note:
      This should not be more than 10% of the total budget.
    </li>
    <li>Training – This is the cost of any type of training.</li>
  </ul>
</ng-template>

<ng-template #submitModal>
  <app-submit-application (data)="handleSubmitApplication($event)"></app-submit-application>
</ng-template>

<ng-template #saveBeforeLeaving>
  <div class="col-sm-6 col-lg-10 mx-auto m-3">
    <h3 class="text-danger text-center">ATTENTION!</h3>
    <p class="text-center">You have not saved the application, are you sure you wish to exit?</p>
    <hr class="dropdown-divider">
    <div class="w-100 d-flex justify-content-end">
      <button class="btn btn-danger mx-2" type="button" (click)="leaveApplication()">Exit Anyway</button>
      <button class="btn btn-success mx-2" type="button" autofocus (click)="closeModal()">Stay</button>
    </div>
  </div>
</ng-template>
