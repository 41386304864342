import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  peopleReachedAnswers, frequencyOfMeetingsAnswers
} from '../../constants/funding-request-answers/support-groups-question-answers.const';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';

@Component({
  selector: 'app-support-groups',
  templateUrl: './support-groups.component.html',
  styleUrls: ['./support-groups.component.scss']
})
export class SupportGroupsComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() supportGroupsForm: FormGroup;
  @Input() formValid: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  peopleReachedOptions = peopleReachedAnswers;
  frequencyOfMeetingsOptions = frequencyOfMeetingsAnswers;
  numberMask: any;

  durationOfTypeOptions = [
    {
      label: 'Days',
      value: 'Days'
    },
    {
      label: 'Months',
      value: 'Months'
    }
  ];

  constructor() {
  }

  ngOnInit() {
    this.numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      allowNegative: false,
      includeThousandsSeparator: false
    });
  }

}
