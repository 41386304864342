import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {sidePanelYesNoAnswers} from 'app/modules/grant-review/constants/side-panel-answers.const';

@Component({
  selector: 'app-internal-verification',
  templateUrl: './internal-verification.component.html',
  styleUrls: ['./internal-verification.component.scss']
})
export class InternalVerificationComponent implements OnInit {

  @Input() internalVerificationForm: FormGroup;
  @Input() isReadOnly: boolean;

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  internalVerificationAnswers = sidePanelYesNoAnswers;

  constructor() {
  }

  ngOnInit() {
  }

}
