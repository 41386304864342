import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {GrantWorkflow, WorkPlanStatusType} from '../../../../grant-workflow/store/grant-workflow.model';
import {Observable, Subscription} from 'rxjs';
import {WorkPlan, WorkPlanMessageActionType} from '../../../store/work-plan-and-targets.model';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ChartData} from '../../../../../shared/modules/charts/models/charts.model';
import {ActivatedRoute} from '@angular/router';
import {GrantWorkflowService} from '../../../../grant-workflow/store/grant-workflow.service';
import {WorkPlanAndTargetsService} from '../../../store/work-plan-and-targets.service';
import {WorkPlanAndTargetsQuery} from '../../../store/work-plan-and-targets.query';
import {ModalService} from '../../../../../shared/modules/shared-common/services/modal/modal.service';
import {SessionQuery} from '../../../../../shared/stores/session/session.query';
import {WorkPlanStatusBadgeUtil} from '../../../../../shared/utils/work-plan-status-badge-util';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {WorkPlanMessageModalComponent} from './work-plan-messages/work-plan-message-modal/work-plan-message-modal.component';

@Component({
  selector: 'app-work-plan',
  templateUrl: './work-plan.component.html',
  styleUrls: ['./work-plan.component.scss']
})
export class WorkPlanComponent implements OnInit, OnDestroy {

  workflowId: number;
  workflow: GrantWorkflow;
  workflowSubscription: Subscription;
  workPlanSubscription: Subscription;
  workPlans: WorkPlan[];
  selectedWorkPlan: WorkPlan;
  workPlanDropdownList: ChoiceInputItem[];
  paramSubscription: Subscription;
  isLoading: Observable<boolean>;
  options: NgbModalOptions;

  isReadOnly: boolean;
  applicantReadOnly: boolean;
  coordinatorReadOnly: boolean;

  budgetRequested = 0;
  budgetAllocated = 0;
  totalsChartData: ChartData<number>[];

  constructor(public route: ActivatedRoute,
              private workflowService: GrantWorkflowService,
              private workPlanAndTargetsService: WorkPlanAndTargetsService,
              private workPlanAndTargetsQuery: WorkPlanAndTargetsQuery,
              private modalService: ModalService,
              private sessionQuery: SessionQuery,
              public workPlanStatusBadgeUtil: WorkPlanStatusBadgeUtil,
              private ref: ChangeDetectorRef,
              private loadingService: LoadingService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();

    this.options = {
      centered: true,
      scrollable: true
    };

    this.paramSubscription = this.route.params.subscribe(params => {
      this.loadingService.setIsLoading(true);
      this.workflowId = params.workflowId;
      this.fetchWorkPlans();
      this.fetchWorkflow();
    });

    this.workPlanSubscription = this.workPlanAndTargetsQuery.getWorkPlansObservable().subscribe(workPlans => {
      this.workPlans = workPlans;
      this.deriveBudgetTotalsAndSetList();
      this.setChartData();
    });

    this.workPlanAndTargetsQuery.getSelectedWorkPlanObservable().subscribe(item => {
      this.selectedWorkPlan = item;
    });

    this.workflowSubscription = this.workPlanAndTargetsQuery.getWorkflowObservable().subscribe(workflow => {
      this.workflow = workflow;

      if (this.workflow) {
        // SET READ ONLY RULES
        const userRoles = this.sessionQuery.getUser().roles;
        if (this.workflow.workPlanStatus === 'APPROVED' || this.workflow.workPlanStatus === 'SUBMITTED') {
          if (userRoles.includes('ROLE_APPLICANT') || userRoles.includes('ROLE_GRANT_COORDINATOR')) {
            this.isReadOnly = true;
          }
        }
        if (userRoles.includes('ROLE_APPLICANT')) {
          if (this.workflow.workPlanStatus !== 'WITH_APPLICANT') {
            this.applicantReadOnly = true;
          }
        } else if (userRoles.includes('ROLE_GRANT_COORDINATOR')) {
          if (this.workflow.workPlanStatus === 'WITH_APPLICANT') {
            this.coordinatorReadOnly = true;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.workPlanSubscription) {
      this.workPlanSubscription.unsubscribe();
    }
    if (this.workflowSubscription) {
      this.workflowSubscription.unsubscribe();
    }
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  openModal(action: WorkPlanMessageActionType, status: WorkPlanStatusType) {
    const modalRef = this.modalService.openStackedModal(WorkPlanMessageModalComponent, this.options, [
      {key: 'action', value: action},
      {key: 'status', value: status},
      {key: 'workflowId', value: this.workflowId},
    ]);

    modalRef.result.then(reason => {
      // This runs when the modal is closed with the close() function
      if (reason === 'saved') {
        this.fetchWorkPlans();
        this.fetchWorkflow();
      }
    }).catch(() => {
      // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
    });
  }

  applicantSubmit() {
    this.openModal(WorkPlanMessageActionType.APPLICANT_SUBMITTED, WorkPlanStatusType.ACTIVE);
  }

  assignToApplicant() {
    this.openModal(WorkPlanMessageActionType.TO_APPLICANT, WorkPlanStatusType.WITH_APPLICANT);
  }

  submitForApproval() {
    this.openModal(WorkPlanMessageActionType.SUBMITTED_FOR_APPROVAL, WorkPlanStatusType.SUBMITTED);
  }

  reject() {
    this.openModal(WorkPlanMessageActionType.REJECTED, WorkPlanStatusType.ACTIVE);
  }

  approve() {
    this.openModal(WorkPlanMessageActionType.APPROVED, WorkPlanStatusType.APPROVED);
  }

  unApprove() {
    this.openModal(WorkPlanMessageActionType.UNAPPROVED, WorkPlanStatusType.SUBMITTED);
  }

  private fetchWorkPlans() {
    this.loadingService.setIsLoading(true);
    // fetch workPlans
    this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(this.workflowId).subscribe(() => {
      // Oly stop loading when the workPlans have arrived
      this.loadingService.setIsLoading(false);
    });
  }

  private fetchWorkflow() {
    this.loadingService.setIsLoading(true);
    // fetch workflow
    this.workPlanAndTargetsService.fetchWorkflowById(this.workflowId).subscribe();
  }

  private deriveBudgetTotalsAndSetList() {
    this.budgetRequested = 0;
    this.budgetAllocated = 0;

    const workPlanDropdownList: ChoiceInputItem[] = [];
    this.workPlans.forEach(workPlan => {
      workPlan.workPlanBudgets.forEach(budget => {
        this.budgetRequested += +budget.amount || 0;
      });

      workPlan.objectives.forEach(objective => {
        objective.activities.forEach(activity => {
          this.budgetAllocated += +activity.cost || 0;
        });
      });

      workPlanDropdownList.push({
        label: workPlan.name,
        value: workPlan
      });
    });

    this.workPlanDropdownList = workPlanDropdownList;
    this.ref.detectChanges();
  }

  private setChartData() {
    this.totalsChartData = [
      {name: 'Budget Allocated', value: this.budgetAllocated || 0},
      {name: 'Budget Available', value: (this.budgetRequested - this.budgetAllocated) || 0},
    ];
  }

  valueFormatting(value) {
    return `R ${value}`;
  }

  selectWorkPlan(workPlan: WorkPlan) {
    this.workPlanAndTargetsService.setSelectedWorkPlan(workPlan);
  }
}
