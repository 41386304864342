import {Injectable} from '@angular/core';
import {GrantWindowStore} from './grant-window.store';
import {tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {GrantWindow} from './grant-window.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {GrantWorkflow} from 'app/modules/grant-workflow/store/grant-workflow.model';
import {CombinedGrant} from './combined-grant.model';
import {OtherFunding} from '../external-grants-store/other-funding.model';
import {SessionQuery} from '../../../shared/stores/session/session.query';

@Injectable({providedIn: 'root'})
export class GrantWindowService {

  private url = `${environment.apiUrl}/grant-window`;
  public selectedGrantWindow: GrantWindow;
  public gwcActiveChevronSrc = new BehaviorSubject<string>('stepOne');

  constructor(protected store: GrantWindowStore,
              private sessionQuery: SessionQuery,
              private http: HttpClient) {
  }

  getActiveChevron(): Observable<string> {
    return this.gwcActiveChevronSrc.asObservable();
  }

  setActiveChevron(step: string) {
    this.gwcActiveChevronSrc.next(step);
  }

  getAllNewGrantWindows() {
    const funderId = this.sessionQuery.getUser().funderId;
    return this.getAllGrantWindowsByStatusAndFunder('NEW', funderId).pipe(
      tap(list => {
        this.setNewList(list);
      })
    );
  }

  getAllActiveGrantWindows(): Observable<GrantWindow[]> {
    const funderId = this.sessionQuery.getUser().funderId;
    return this.getAllGrantWindowsByStatusAndFunder('APPLICATION', funderId).pipe(
      tap(list => {
        this.setCurrentList(list);
      })
    );
  }

  getAllShortlistGrantWindows(): Observable<GrantWindow[]> {
    const funderId = this.sessionQuery.getUser().funderId;
    return this.getAllGrantWindowsByStatusAndFunder('SHORTLIST', funderId).pipe(
      tap(list => {
        this.setShortlist(list);
      })
    );
  }

  getAllReviewGrantWindows(): Observable<GrantWindow[]> {
    const funderId = this.sessionQuery.getUser().funderId;
    return this.getAllGrantWindowsByStatusAndFunder('REVIEW', funderId).pipe(
      tap(list => {
        this.setReview(list);
      })
    );
  }

  getWorkflowByOrgId(orgId: number): Observable<GrantWorkflow[]> {
    const url = `${environment.apiUrl}/grant-workflow/history?organizationId=${orgId}`;
    return this.http.get<GrantWorkflow[]>(url);
  }

  create(grantWindow: GrantWindow) {
    return this.http.post<GrantWindow>(this.url, grantWindow).pipe(
      tap(() => {
        this.getAllNewGrantWindows().subscribe();
      })
    );
  }

  update(grantWindow: GrantWindow) {
    const url = `${this.url}/${grantWindow.id}`;
    return this.http.put<GrantWindow>(url, grantWindow).pipe(
      tap(() => {
        this.getAllNewGrantWindows().subscribe();
      })
    );
  }

  updateGrantWindowStatus(grantWindow: GrantWindow, status: string) {
    const url = `${this.url}/${grantWindow.id}/status/${status}`;
    return this.http.put(url, undefined).pipe(tap(() => {
      this.updateActiveGrantWindowStatus(grantWindow, status);
      switch (status) {
        case 'APPLICATION':
          this.getAllNewGrantWindows().subscribe();
          this.getAllActiveGrantWindows().subscribe();
          break;
        case 'SHORTLIST':
          this.getAllActiveGrantWindows().subscribe();
          this.getAllShortlistGrantWindows().subscribe();
          break;
        case'REVIEW':
          this.getAllShortlistGrantWindows().subscribe();
          this.getAllReviewGrantWindows().subscribe();
          break;
        case'REPORTING':
          this.getAllReviewGrantWindows().subscribe();
          // this.getAllReportingGrantWindows().subscribe();
          break;
        case'CLOSED':
          // this.getAllReportingGrantWindows().subscribe();
          // this.getAllClosedGrantWindows().subscribe();
          break;
      }
    }));
  }

  private getAllGrantWindowsByStatusAndFunder(status: string, funderId?: number) {
    let url = `${this.url}/byFunder?status=${status}`;
    if (funderId) {
      url += `&funderId=${funderId}`;
    }
    return this.http.get<GrantWindow[]>(url);
  }

  getAllCombinedGrantWindowsByStatusAndCategories(status: string, categories?: string[], funderId?: number) {
    let url = `${this.url}/combined/byCategory?status=${status}`;
    if (categories) {
      url += `&categories=${categories}`;
    }
    if (funderId) {
      url += `&funderId=${funderId}`;
    }
    return this.http.get<CombinedGrant[]>(url).pipe(
      tap(list => {
        this.setCurrentCombinedList(list);
      })
    );
  }

  private setNewList(list: GrantWindow[]) {
    this.store.update({
      newList: list
    });
  }

  private setCurrentList(list: GrantWindow[]) {
    this.store.update({
      currentList: list
    });
  }

  private setShortlist(list: GrantWindow[]) {
    this.store.update({
      shortlist: list
    });
  }

  private setReview(list: GrantWindow[]) {
    this.store.update({
      reviewList: list
    });
  }

  private setCurrentCombinedList(list: CombinedGrant[]) {
    this.store.update({
      currentCombinedList: list
    });
  }

  setActiveGrantWindow(grantWindow: GrantWindow) {
    this.store.update({
      activeGrantWindow: grantWindow
    });
  }

  setActiveExternalFunding(externalFunding: OtherFunding) {
    this.store.update({
      activeExternalFunding: externalFunding
    });
  }

  updateActiveGrantWindowStatus(grantWindow: GrantWindow, status: string) {
    this.store.update({
      activeGrantWindow: Object.assign({}, grantWindow, {status})
    });
  }

  getAllByOrganisationWhereWorkPlanIsOpen(organisationId: number): Observable<GrantWindow[]> {
    const url = `${this.url}/work-plan?organisationId=${organisationId}`;
    return this.http.get<GrantWindow[]>(url);
  }
}
