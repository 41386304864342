/*!

=========================================================
* Now UI Kit Pro Angular - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-angular
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {enableProdMode} from '@angular/core';
import {enableAkitaProdMode} from '@datorama/akita';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {akitaConfig} from '@datorama/akita';
import {environment} from './environments/environment';

akitaConfig({
  resettable: true
});

if (environment.production) {
  enableProdMode();
  // enableAkitaProdMode(); // Worried that this might damage some stuff. Need to test in prod mode locally.
}

platformBrowserDynamic().bootstrapModule(AppModule);
