import {Component, OnInit, OnDestroy} from '@angular/core';
import {SessionQuery} from '../../../../stores/session/session.query';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  toggled: boolean;
  sidebarSubscription: Subscription;

  constructor(public sessionQuery: SessionQuery,
              private sidebarService: SidebarService) {
  }

  ngOnDestroy(): void {
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarService.getToggled().subscribe(toggled => {
      this.toggled = toggled;
    });
  }


  toggleSidebar(e) {
    e.preventDefault();
    this.sidebarService.setToggled(!this.toggled);
  }

}
