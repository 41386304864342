import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() loadingObs: Observable<boolean>;
  @Input() loadingBool: boolean;
  @Input() sizeInRem = 20;

  constructor() {
  }

  ngOnInit() {
  }

}
