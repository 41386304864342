import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbDatepickerModule, NgbTooltipModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from '../../shared/modules/form-elements/form-elements.module';
import {SharedCommonModule} from '../../shared/modules/shared-common/shared-common.module';
import {AccordionTwoModule} from '../../shared/modules/accordion-two/accordion-two.module';
import {GrantWorkflowRoutingModule} from './grant-workflow-routing.module';
import {EligibilityComponent} from './application-phase/eligibility/eligibility.component';
import {IneligibleComponent} from './application-phase/ineligible/ineligible.component';
import {ApplicationAgreementComponent} from './application-phase/application-agreement/application-agreement.component';
import {ApplicationConfirmationComponent} from './application-phase/application-confirmation/application-confirmation.component';
import {ApplicationComponent} from './application-phase/application/application.component';
import {FundingRequestComponent} from './application-phase/application/funding-request/funding-request.component';
import {AccordionModule} from '../../shared/modules/accordion/accordion.module';
import {
  AdherenceClubsComponent
} from './application-phase/application/funding-request/categories/adherence-clubs/adherence-clubs.component';
import {CampsComponent} from './application-phase/application/funding-request/categories/camps/camps.component';
import {
  ClinicalServicesComponent
} from './application-phase/application/funding-request/categories/clinical-services/clinical-services.component';
import {
  CommunityMobilizationComponent
} from './application-phase/application/funding-request/categories/community-mobilization/community-mobilization.component';
import {CounselingComponent} from './application-phase/application/funding-request/categories/counseling/counseling.component';
import {HivTestingComponent} from './application-phase/application/funding-request/categories/hiv-testing/hiv-testing.component';
import {HomeBasedCareComponent} from './application-phase/application/funding-request/categories/home-based-care/home-based-care.component';
import {
  OtherFundingRequestComponent
} from './application-phase/application/funding-request/categories/other-funding-request/other-funding-request.component';
import {SupportGroupsComponent} from './application-phase/application/funding-request/categories/support-groups/support-groups.component';
import {
  SystemDevelopmentComponent
} from './application-phase/application/funding-request/categories/system-development/system-development.component';
import {
  TargetedPreventionComponent
} from './application-phase/application/funding-request/categories/targeted-prevention/targeted-prevention.component';
import {TrainingComponent} from './application-phase/application/funding-request/categories/training/training.component';
import {
  FundingRequestBudgetComponent
} from './application-phase/application/funding-request/categories/funding-request-budget/funding-request-budget.component';
import {SubmitApplicationComponent} from './application-phase/application/submit-application/submit-application.component';
import {
  FundingRequestBudgetTotalComponent
} from './application-phase/application/funding-request/categories/funding-request-budget-total/funding-request-budget-total.component';
import {CustomApplicationComponent} from './application-phase/custom-application/custom-application.component';
import {FormBuilderModule} from '../form-builder/form-builder.module';
import {FormCaptureComponent} from './application-phase/custom-application/components/form-capture/form-capture.component';
import {
  CustomFundingRequestComponent
} from './application-phase/custom-application/components/custom-funding-request/custom-funding-request.component';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../shared/modules/alert/alert.module';


@NgModule({
  declarations: [
    ApplicationAgreementComponent,
    ApplicationConfirmationComponent,
    ApplicationComponent,
    CustomApplicationComponent,
    EligibilityComponent,
    FundingRequestComponent,
    IneligibleComponent,

    AdherenceClubsComponent,
    CampsComponent,
    ClinicalServicesComponent,
    CommunityMobilizationComponent,
    CounselingComponent,
    HivTestingComponent,
    HomeBasedCareComponent,
    OtherFundingRequestComponent,
    SupportGroupsComponent,
    SystemDevelopmentComponent,
    TargetedPreventionComponent,
    TrainingComponent,
    FundingRequestBudgetComponent,
    SubmitApplicationComponent,
    FundingRequestBudgetTotalComponent,
    FormCaptureComponent,
    CustomFundingRequestComponent,
  ],
    imports: [
        CommonModule,
        GrantWorkflowRoutingModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        FormsModule,
        FormElementsModule,
        SharedCommonModule,
        AccordionTwoModule,
        NgbDatepickerModule,
        AccordionModule,
        NgbModule,
        FormBuilderModule,
        ToastContainerModule,
        AlertModule
    ],
  exports: [
    ApplicationComponent,
    CustomApplicationComponent,
    EligibilityComponent,
  ]
})
export class GrantWorkflowModule {
}
