<div class="row w-100">

  <div class="col-6">
    <label class="multi-lines-text" [class.d-none]="!showLabel">
      {{ label }}
    </label>
  </div>

  <div class="col-2">
    <div class="input-group">
      <button
        type="button"
        (click)="fileInput.click();"
        class="btn btn-secondary"
        [disabled]='isReadOnly'
      >
    <span class="fa fa-upload fa-1x text-center">
      Upload <span *ngIf="label && !showLabel">{{label}}</span>
    </span>
      </button>
      <span class="uploader">
        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" hidden/>
      </span>
      <span *ngIf="uploading">
        <i class="mt-2 fa fa-spinner fa-spin text-primary"></i>
      </span>
    </div>
  </div>

  <div class="col-4">

    <div *ngIf="!isReadOnly && getFilename(document)" class="row">
      <a
        href="javascript:void(0)"
        class="text-danger fa fa-trash fa-1x mt-1"
        (click)="showDeleteConfirmModal = true"
      ></a>
      <a
        class="files text-center"
        href="javascript:void(0)"
        (click)="downloadFile(document)"
        target="_blank"
      >
        {{ getFilename(document) }}
      </a>
    </div>

    <div *ngIf="isReadOnly" class="row unhidden">
      <p class="text-center">
        &nbsp; {{ getFilename(document) }}
      </p>
    </div>

    <div *ngIf="!getFilename(document)">
      <p class="text-center disabled">Please upload a file</p>
    </div>

    <app-alert
      class="w-100"
      [id]="'uploadSingleDoc'"
    ></app-alert>

  </div>
</div>

<app-confirmation-modal
  *ngIf="showDeleteConfirmModal"
  [title]="'Delete Confirm'"
  [message]="'Are you sure you want to delete this file?'"
  (isConfirmed)="deleteConfirmation($event)"
></app-confirmation-modal>
