import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum HumanResourcesEndpointType {
  STAFF_PERIOD = 'STAFF_PERIOD',
  QUALIFICATION = 'QUALIFICATION',
  EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
  CONFORM_TO_LABOUR_LAW = 'CONFORM_TO_LABOUR_LAW',
  JOB_DESCRIPTION = 'JOB_DESCRIPTION',
  RETENTION_PLAN = 'RETENTION_PLAN',
  HR_POLICY = 'HR_POLICY',
  HR_POLICY_ELEMENTS = 'HR_POLICY_ELEMENTS',
  RECRUITMENT_PROCESS = 'RECRUITMENT_PROCESS',
  TRAINING_FREQUENCY = 'TRAINING_FREQUENCY',
  INTERNAL_COMMUNICATION = 'INTERNAL_COMMUNICATION'
}

export interface CSHumanResources extends BaseEntity {
  permanentPaidStaff: number;
  stipendWorkers: number;
  volunteers: number;
  qualification: string;
  averageStaffPeriod: string;
  employmentContract: string;
  conformToLabourLaw: string;
  jobDescription: string;
  retentionPlan: string;
  hrPolicy: string;
  hrPolicyElements: string[];
  hrPolicyElementsOther: string; // if hrPolicyElements === 'OTHER'
  recruitmentProcess: string;
  trainingFrequency: string;
  internalCommunication: string;
  hasContractors: boolean;
  contractorAgreement: boolean; // if hasContractors === true
}

export function createCSHumanResources(params: Partial<CSHumanResources>) {
  return Object.assign({}, createBaseEntity(params), {
    permanentPaidStaff: params && params.permanentPaidStaff,
    stipendWorkers: params && params.stipendWorkers,
    volunteers: params && params.volunteers,
    qualification: params && params.qualification,
    averageStaffPeriod: params && params.averageStaffPeriod,
    employmentContract: params && params.employmentContract,
    conformToLabourLaw: params && params.conformToLabourLaw,
    jobDescription: params && params.jobDescription,
    retentionPlan: params && params.retentionPlan,
    hrPolicy: params && params.hrPolicy,
    hrPolicyElements: params && params.hrPolicyElements,
    hrPolicyElementsOther: params && params.hrPolicyElementsOther,
    recruitmentProcess: params && params.recruitmentProcess,
    trainingFrequency: params && params.trainingFrequency,
    internalCommunication: params && params.internalCommunication,
    hasContractors: params && params.hasContractors,
    contractorAgreement: params && params.contractorAgreement,
  }) as CSHumanResources;
}

export function createCSHumanResourcesForm(destroy$: Subject<boolean>,
                                           formBuilder: FormBuilder,
                                           params: Partial<CSHumanResources>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    permanentPaidStaff: [params && params.permanentPaidStaff, Validators.required],
    stipendWorkers: [params && params.stipendWorkers, Validators.required],
    volunteers: [params && params.volunteers, Validators.required],
    qualification: [params && params.qualification, Validators.required],
    averageStaffPeriod: [params && params.averageStaffPeriod, Validators.required],
    employmentContract: [params && params.employmentContract, Validators.required],
    conformToLabourLaw: [params && params.conformToLabourLaw, Validators.required],
    jobDescription: [params && params.jobDescription, Validators.required],
    retentionPlan: [params && params.retentionPlan, Validators.required],
    hrPolicy: [params && params.hrPolicy, Validators.required],
    hrPolicyElements: [params && params.hrPolicyElements, Validators.required],
    hrPolicyElementsOther: [params && params.hrPolicyElementsOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('hrPolicyElements').value && formGroup.get('hrPolicyElements').value.includes('OTHER');
      })
    ],
    recruitmentProcess: [params && params.recruitmentProcess, Validators.required],
    trainingFrequency: [params && params.trainingFrequency, Validators.required],
    internalCommunication: [params && params.internalCommunication, Validators.required],
    hasContractors: [params && params.hasContractors, Validators.required],
    contractorAgreement: [params && params.contractorAgreement,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasContractors').value === true)
    ],
  }));

  formGroup.get('hrPolicyElements').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('hrPolicyElementsOther').updateValueAndValidity();
    });

  formGroup.get('hasContractors').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('contractorAgreement').updateValueAndValidity();
    });

  return formGroup;
}
