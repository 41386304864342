import {FormBuilder, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../shared/models/base-entity.model';
import {
  createFormBuilderQuestionDTOList,
  FormBuilderQuestionDTO
} from '../components/form-builder-question/store/form-builder-question.model';
import {Funder} from '../../../../../funder/funder-profile/store/funder.model';

export interface FormBuilderModel extends BaseEntity {
  name: string;
  theme: 'light' | 'dark';
  style: 'default' | 'bordered';
  template: boolean;
  funder: Funder;
}

export function createFormBuilder(params: Partial<FormBuilderModel>) {
  return Object.assign({}, createBaseEntity(params), {
    name: params && params.name,
    theme: params && params.theme,
    style: params && params.style,
    template: params && params.template,
    funder: params && params.funder,
  }) as FormBuilderModel;
}

export function createFormBuilderForm(formBuilder: FormBuilder, params: Partial<FormBuilderDTO>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params && params.name || '', Validators.required],
    theme: [params && params.theme || 'light', Validators.required],
    style: [params && params.style || 'default', Validators.required],
    template: [params && params.template],
    funder: [params && params.funder],
  }));
}

export interface FormBuilderDTO extends BaseEntity {
  name: string;
  theme: 'light' | 'dark';
  style: 'default' | 'bordered';
  template: boolean;
  funder: Funder;
  isEditable: boolean;
  questions: FormBuilderQuestionDTO[];
}

export function createFormBuilderDTO(params: Partial<FormBuilderDTO>) {
  return Object.assign({}, createBaseEntity(params), {
    name: params.name,
    theme: params.theme,
    style: params.style,
    funder: params.funder,
    template: params.template,
    isEditable: params.isEditable,
    questions: createFormBuilderQuestionDTOList(params.questions),
  }) as FormBuilderDTO;
}
