<form [formGroup]="siteVisitFinancialForm">
  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'annualOperatingBudgetIsCorrect'"
    [label]="'Annual operating budget'"
    [isRequired]="true"
    [id]="'annualOperatingBudgetIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="annualOperatingBudgetIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'budgetCreationIsCorrect'"
    [label]="'Budget creation'"
    [isRequired]="true"
    [id]="'budgetCreationIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="budgetCreationIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'inventoryOfAssetsIsCorrect'"
    [label]="'Inventory of assets'"
    [isRequired]="true"
    [id]="'inventoryOfAssetsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="inventoryOfAssetsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'overheadCostIsCorrect'"
    [label]="'Overhead Costs'"
    [isRequired]="true"
    [id]="'overheadCostIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="overheadCostIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'budgetTrackingIsCorrect'"
    [label]="'Budget tracking'"
    [isRequired]="true"
    [id]="'budgetTrackingIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="budgetTrackingIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'financialControlsIsCorrect'"
    [label]="'Financial controls'"
    [isRequired]="true"
    [id]="'financialControlsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="financialControlsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'financialPolicyIsCorrect'"
    [label]="'Financial policy'"
    [isRequired]="true"
    [id]="'financialPolicyIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="financialPolicyIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'vatRegistrationIsCorrect'"
    [label]="'VAT registration'"
    [isRequired]="true"
    [id]="'vatRegistrationIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="vatRegistrationIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'recordRetentionIsCorrect'"
    [label]="'Record retention'"
    [isRequired]="true"
    [id]="'recordRetentionIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="recordRetentionIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'auditIsCorrect'"
    [label]="'Audit'"
    [isRequired]="true"
    [id]="'auditIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="auditIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'incomeGenerationIsCorrect'"
    [label]="'Income generation'"
    [isRequired]="true"
    [id]="'incomeGenerationIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="incomeGenerationIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'communityContributionsIsCorrect'"
    [label]="'Community contributions'"
    [isRequired]="true"
     [id]="'communityContributionsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="communityContributionsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'debtStatus'"
    [label]="'Debt status'"
    [isRequired]="true"
    [id]="'debtStatus'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="debtStatus"
  ></app-widget-check-two>

  <app-input-textarea
    [label]="''"
    [id]="'debtStatusComment'"
    [isRequired]="true"
    [placeholder]="'debt status comment'"
    [theme]="theme"
    [style]="style"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [isGrey]="false"
    [hasBorder]="false"
    formControlName="debtStatusComment"
  ></app-input-textarea>
</form>
