import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MneEndpointType} from '../store/cs-mne.model';

@Injectable({
  providedIn: 'root'
})
export class CsMneAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/mne`;

  public planSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $plan = this.planSource.asObservable();

  public planElementSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $planElement = this.planElementSource.asObservable();

  public monitorSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $monitor = this.monitorSource.asObservable();

  public programReportingSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $programReporting = this.programReportingSource.asObservable();

  public usageOfReportsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $usageOfReports = this.usageOfReportsSource.asObservable();

  public programBudgetSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $programBudget = this.programBudgetSource.asObservable();

  public budgetCheckFrequencySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $budgetCheckFrequency = this.budgetCheckFrequencySource.asObservable();

  public trainedMneSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $trainedMne = this.trainedMneSource.asObservable();

  public feedbackProcedureSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $feedbackProcedure = this.feedbackProcedureSource.asObservable();

  public strategicPlanSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $strategicPlan = this.strategicPlanSource.asObservable();

  public linkedStrategicPlanSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $linkedStrategicPlan = this.linkedStrategicPlanSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.planSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case MneEndpointType.PLAN: {
          this.planSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.PLAN_ELEMENT: {
          this.planElementSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.MONITOR: {
          this.monitorSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.PROGRAM_REPORTING: {
          this.programReportingSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.USAGE_OF_REPORTS: {
          this.usageOfReportsSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.PROGRAM_BUDGET: {
          this.programBudgetSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.BUDGET_CHECK_FREQUENCY: {
          this.budgetCheckFrequencySource.next(answerListDTO);
          break;
        }
        case MneEndpointType.TRAINED_MNE: {
          this.trainedMneSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.FEEDBACK_PROCEDURE: {
          this.feedbackProcedureSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.STRATEGIC_PLAN: {
          this.strategicPlanSource.next(answerListDTO);
          break;
        }
        case MneEndpointType.LINKED_STRATEGIC_PLAN: {
          this.linkedStrategicPlanSource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
