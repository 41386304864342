import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {validateFormGroup} from '../../../../shared/utils/form-validation.util';

@Injectable({
  providedIn: 'root'
})
export class OrgCapacityValidationService {

  public orgCapacityValidationSource = new BehaviorSubject<boolean>(null);
  public $orgCapacityValid = this.orgCapacityValidationSource.asObservable();

  public orgStructureValidSource = new BehaviorSubject<boolean>(null);
  public $orgStructureValid = this.orgStructureValidSource.asObservable();

  public serviceDeliveryValidSource = new BehaviorSubject<boolean>(null);
  public $serviceDeliveryValid = this.serviceDeliveryValidSource.asObservable();

  public programsProvidedValidSource = new BehaviorSubject<boolean>(null);
  public $programsProvidedValid = this.programsProvidedValidSource.asObservable();

  public communityRelationshipsValidSource = new BehaviorSubject<boolean>(null);
  public $communityRelationshipsValid = this.communityRelationshipsValidSource.asObservable();

  public financialManagementValidSource = new BehaviorSubject<boolean>(null);
  public $financialManagementValid = this.financialManagementValidSource.asObservable();

  public financialSituationValidSource = new BehaviorSubject<boolean>(null);
  public $financialSituationValid = this.financialSituationValidSource.asObservable();

  public supportingDocValidSource = new BehaviorSubject<boolean>(null);
  public $supportingDocsValid = this.supportingDocValidSource.asObservable();

  public capacitySavedSource = new BehaviorSubject<boolean>(null);
  public $capacitySaved = this.capacitySavedSource.asObservable();

  constructor() {
  }

  validateEntity(entity: any,
                 type: 'orgCapacity' | 'organizationalStructure' | 'serviceDelivery' | 'programsProvided' |
                   'communityRelationships' | 'financialManagement' | 'financialSituation' | 'supportingDocuments') {
    let valid: boolean;
    if (entity) {
      switch (type) {
        case 'orgCapacity':
          valid = this.validateAll(entity);
          this.orgCapacityValidationSource.next(valid);
          break;
        case 'organizationalStructure':
          valid = validateFormGroup(entity);
          this.orgStructureValidSource.next(valid);
          break;
        case 'serviceDelivery':
          valid = validateFormGroup(entity);
          this.serviceDeliveryValidSource.next(valid);
          break;
        case 'programsProvided':
          valid = validateFormGroup(entity);
          this.programsProvidedValidSource.next(valid);
          break;
        case 'communityRelationships':
          valid = validateFormGroup(entity);
          this.communityRelationshipsValidSource.next(valid);
          break;
        case 'financialManagement':
          valid = validateFormGroup(entity);
          this.financialManagementValidSource.next(valid);
          break;
        case 'financialSituation':
          valid = validateFormGroup(entity);
          this.financialSituationValidSource.next(valid);
          break;
        case 'supportingDocuments':
          valid = validateFormGroup(entity);
          this.supportingDocValidSource.next(valid);
          break;
        default:
          break;
      }
    }
  }


  validateAll(entity: any): boolean {
    if (entity && entity.controls) {
      return validateFormGroup(entity.controls.organizationalStructure as FormGroup)
        && validateFormGroup(entity.controls.serviceDelivery as FormGroup)
        && validateFormGroup(entity.controls.programsProvided as FormGroup)
        && validateFormGroup(entity.controls.communityRelationships as FormGroup)
        && validateFormGroup(entity.controls.financialManagement as FormGroup)
        && validateFormGroup(entity.controls.financialSituation as FormGroup)
        && validateFormGroup(entity.controls.supportingDocumentation as FormGroup);
    }
  }

  validateAllEntities(entity: FormGroup) {
    const orgCapacityValid = validateFormGroup(entity);
    this.orgCapacityValidationSource.next(orgCapacityValid);

    const orgStructureValid = validateFormGroup(entity.controls.organizationalStructure as FormGroup);
    this.orgStructureValidSource.next(orgStructureValid);

    const serviceDeliveryValid = validateFormGroup(entity.controls.serviceDelivery as FormGroup);
    this.serviceDeliveryValidSource.next(serviceDeliveryValid);

    const programsProvidedValid = validateFormGroup(entity.controls.programsProvided as FormGroup);
    this.programsProvidedValidSource.next(programsProvidedValid);

    const communityRelationshipsValid = validateFormGroup(entity.controls.communityRelationships as FormGroup);
    this.communityRelationshipsValidSource.next(communityRelationshipsValid);

    const financialManagementValid = validateFormGroup(entity.controls.financialManagement as FormGroup);
    this.financialManagementValidSource.next(financialManagementValid);

    const financialSituationValid = validateFormGroup(entity.controls.financialSituation as FormGroup);
    this.financialSituationValidSource.next(financialSituationValid);

    const supportingDocsValid = validateFormGroup(entity.controls.supportingDocumentation as FormGroup);
    this.supportingDocValidSource.next(supportingDocsValid);
  }

  unValidateAll() {
    this.orgCapacityValidationSource.next(undefined);
    this.orgStructureValidSource.next(undefined);
    this.serviceDeliveryValidSource.next(undefined);
    this.programsProvidedValidSource.next(undefined);
    this.communityRelationshipsValidSource.next(undefined);
    this.financialManagementValidSource.next(undefined);
    this.financialSituationValidSource.next(undefined);
    this.supportingDocValidSource.next(undefined);
  }
}
