<div *ngIf="!rows || rows.length < 1" class="text-center"><strong class="text-mute">No Data Available</strong></div>
<table class="table table-striped table-bordered table-hover table-responsive-sm" *ngIf="!(!rows || rows.length < 1)">
  <thead class="bg-dark text-light">
  <tr>
    <td [attr.colspan]="columns | colspan: useBulkActions">
      <div class="float-left result_height">{{ count }} Results</div>
      <ng-container *ngIf="useBulkActions">
        <div class="float-left ml-2 result_height">
          &nbsp; | &nbsp;{{selectedRowCount}} Selected
        </div>
        <div class="float-left ml-2">
          <select
            name="bulkActions"
            id="bulkActions"
            class="form-control-sm"
            [(ngModel)]="bulkActionsSelect"
          >
            <option *ngFor="let action of bulkActionsArray" value="{{action.function}}">{{action.name}}</option>
          </select>
        </div>
        <div class="float-left ml-3" style="margin-top: -.09rem">
          <button (click)="bulkActionsParent()" class="btn btn-info btn-sm">Bulk Action</button>
        </div>
      </ng-container>
    </td>
  </tr>
  <tr class="w-100">
    <ng-container *ngFor="let column of columns">
      <td *ngIf="useBulkActions" class="align-middle">
        <input type="checkbox" class="form-control" [(ngModel)]="allSelected" (click)="selectAll()">
      </td>
      <!--// If there are multiple columns, we want to loop through that array and show a heading for each, else we just want to show a single heading-->
      <ng-container *ngIf="!column?.columns?.length > 0">
        <ng-container [ngTemplateOutlet]="colHeading" [ngTemplateOutletContext]="{column: column}"></ng-container>
      </ng-container>

    </ng-container>
  </tr>
  </thead>

  <tbody>
  <tr
    *ngFor="let row of pagedRows"
    [class.table-primary]="row === selected && selectable" (click)="selectRow(row)"
  >
    <td *ngIf="useBulkActions" class="align-middle w-3">
      <input type="checkbox" class="form-control" [(ngModel)]="row.selected" (change)="countSelected()">
    </td>

    <ng-container *ngFor="let column of columns">

      <ng-container
        *ngIf="!column.rows"
        [ngTemplateOutlet]="colContent"
        [ngTemplateOutletContext]="{column: column, row: row}"
      ></ng-container>

      <!--// If there are multiple columns, we want to loop through that array and show content for each-->
      <ng-container *ngIf="column?.rows?.length > 0">
        <table class="table table-bordered table-hover table-responsive-sm mb-0">
          <!--// TODO: Find a way to reduce the height to 0 or hide the header without messing up the column widths-->
          <thead [class.v-hidden]="row !== rows[0]" [class.line-height-0]="row !== rows[0]">
          <tr>
            <td></td>
            <ng-container *ngFor="let column of columns">
              <ng-container *ngIf="column?.rows?.length > 0">
                <ng-container *ngFor="let col of column.rows[0].columns">
                  <ng-container
                    [ngTemplateOutlet]="colHeading"
                    [ngTemplateOutletContext]="{column: col}"
                  ></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let nestedRow of column.rows">
            <tr>
              <td>{{nestedRow.name}}</td>
              <ng-container *ngFor="let col of nestedRow.columns">
                <ng-container
                  [ngTemplateOutlet]="colContent"
                  [ngTemplateOutletContext]="{column: col, row: row}"
                ></ng-container>
              </ng-container>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-container>

    </ng-container>
  </tr>
  </tbody>

  <tfoot class="bg-dark text-light">
  <tr>
    <td [attr.colspan]="columns | colspan: useBulkActions" class="py-2">
      <span *ngIf="numberOfPages > 1" class="float-right pr-3">
        <app-mbl-table-pagination
          [count]="count"
          [limit]="limit"
          [offset]="offset"
          [numberOfPages]="numberOfPages"
          (page)='setPage($event)'
        >
        </app-mbl-table-pagination>
      </span>
    </td>
  </tr>
  </tfoot>
</table>


<ng-template #colHeading let-column="column">
  <td
    class="py-3 text-center align-middle"
    [ngStyle]="{'width': column?.width, 'text-align': column?.align}"
    (click)="sortByColumn(column)"
  >
    <strong>{{ column.name }}</strong>
    <span
      *ngIf="column.sort"
      [class]="column.sortDirection === 'forward' ? 'fa fa-sort-up' :
            column.sortDirection === 'reverse' ? 'fa fa-sort-down' : 'fa fa-sort'"
    >
      </span>
  </td>
</ng-template>

<ng-template #colContent let-column="column" let-row="row">
  <td
    [ngStyle]="{'text-align': column.align}"
    class="align-middle"
  >
    <span *ngIf="!column.cellTemplate">{{ row[column.prop || column.name.toLowerCase()] }}</span>
    <ng-template
        #cellTemplate
        *ngIf="column.cellTemplate"
        [ngTemplateOutlet]="column.cellTemplate"
        [ngTemplateOutletContext]="{ row: row }"
    >
    </ng-template>
  </td>
</ng-template>
