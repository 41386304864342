<div class="page-header auth-page-header">
  <div class="auth-header-image"></div>
  <div class="register-content">
    <div class="container h-100">
      <div class="w-100 card card-login bg-light p-4 opacity-8">
        <div class="card-header text-center">
          <div class="logo-container flex-center">
            <img
              class="register-logo logo"
              src="../../../../../assets/img/solinta/SoCapX_Logo_RGB_NoBG_small.png"
              alt="logo"
            >
          </div>
        </div>
        <div class="card-body">
          <h3 class="category text-primary text-center">
            PRIVACY NOTICE
          </h3>
          <hr class="dropdown-divider border-primary w-75 mx-auto mb-2">

          <div class="text-dark">
            <h4 class="font-weight-bold my-1">Your Data is Secure</h4>
            <p class="mb-4">
              We have put in place appropriate security measures to prevent your personal information from
              being accidentally or maliciously destroyed, used, altered, disclosed, or accessed in an
              unauthorised way. In addition, we limit access to your personal information to those employees,
              agents, contractors and other third parties who have a business need-to-know. We have put in
              place procedures to deal with any suspected data security breach, and will notify you and any
              applicable regulator of a suspected breach where we are legally required to do so.
            </p>

            <h4 class="font-weight-bold my-1">Your Data Is Only Used For Your Assessment</h4>
            <p class="mb-4">
              The responses that you provide during your assessment(s) will be used solely for the purposes of
              providing assessments, and to provide you with technical support. Your data may be shared with
              some of our sub-processors (e.g. cloud storage) for us to be able to provide this service.
            </p>

            <h4 class="font-weight-bold my-1">You Will Be Profiled According To Your Data</h4>
            <p class="mb-4">
              To provide the assessment service, we will use your personal data to perform profiling. We have
              a legitimate interest in performing profiling, because this is the most appropriate way to provide
              our service. We have appropriate safeguards in place to protect your rights and freedoms. Such
              profiling will never refer to any special category data (such as ethnicity, gender etc).
              We will ask you for data that is necessary for us to provide you with access to your assigned
              assessment(s). If you fail to provide this information when requested, the company that initiated
              your assessment(s) may not be able to process your application successfully.
            </p>

            <h4 class="font-weight-bold my-1">You Must Read Our Full Privacy Notice</h4>
            <p class="mb-4">
              This is just a short version of our full Privacy Notice below, and contains only the key
              information. For more information regarding how we comply with data protection law and what
              your rights are, please read our full Privacy Notice below.
              If you have any questions relating to this privacy notice, please contact
              <a href="mailto:info@socialcapitalexchange.org" class="text-info">
                info@socialcapitalexchange.org
              </a>.
            </p>
          </div>
        </div>
      </div>

      <div class="w-100 card card-login bg-light p-4 mt-5 opacity-8">
        <div class="card-body">
          <h2 class="category text-primary text-center">
            SoCapX Privacy Notice
          </h2>
          <hr class="dropdown-divider border-primary w-75 mx-auto mb-2">
          <p class="text-center text-dark">Last updated 01 October 2020</p>
          <div class="text-dark">
            <h4 class="font-weight-bold my-1">Introduction</h4>
            <p class="mb-4">
              Welcome to SoCapX's privacy notice.
            </p>
            <p class="mb-4">
              SoCapX respects your privacy and is committed to protecting your personal data. This privacy notice
              explains how we look after your personal data when you visit our website (including browsing, taking an
              assessment, or setting up tests as a client) and tell you about your privacy rights and how the law
              protects you.
            </p>
            <p class="mb-4">
              This privacy notice is provided in a layered format so you can click through to the specific areas set out
              below. Please also use the Glossary to understand the meaning of some of the terms used in this privacy
              notice.
            </p>

            <h4 class="font-weight-bold my-1">
              1. IMPORTANT INFORMATION ABOUT WHO WE ARE
            </h4>
            <div class="ml-4">
              <h5 class="my-1 font-weight-bold">Contact Details</h5>
              <p>Our full details are:</p>
              <div class="flex-start flex-align-start flex-column mb-3">
                <p>Full name of legal entity: <span class="font-weight-bold">SoCapX (Pty) Ltd></span></p>
                <p>Name or title of: <span class="font-weight-bold">Data Protection Officer</span></p>
                <p>Email address: <span class="font-weight-bold">info@socialcapitalexchange.org</span></p>
                <p>Postal address:</p>
                <p>Telephone number:</p>
              </div>

              <h5 class="my-1 font-weight-bold">Data Controllers</h5>
              <ul class="list-style-bullet font-weight-normal mb-3">
                <li>
                  <p>
                    Where a company or similar organisation using our products or services (the "Client") has instructed
                    us to assess an individual (the "Candidate"), the Client is the data controller and SoCapX (Pty) Ltd
                    of ADDRESS HERE is a data processor.
                  </p>
                </li>
                <li>
                  <p>
                    Where we process the personal data of a representative of a Client for the purposes of accessing or
                    ordering our products or services, SoCapX (Pty) Ltd of ADDRESS HERE is a data controller.
                  </p>
                </li>
              </ul>
              <div class="mb-3">
                <h5 class="my-1 font-weight-bold">
                  Changes to the privacy notice and your duty to inform us of changes
                </h5>
                <p>Historic versions of this document can be obtained by contacting us.</p>
                <p>
                  It is important that the personal data we hold about you is accurate and current. Please keep us
                  informed if your personal data changes during your relationship with us.
                </p>
              </div>
              <div class="mb-3">
                <h5 class="my-1 font-weight-bold">
                  Third Party Links
                </h5>
                <p>
                  This website may include links to third-party websites, plug-ins and applications. Clicking on those
                  links or enabling those connections may allow third parties to collect or share data about you. We do
                  not control these third-party websites and are not responsible for their privacy statements. When you
                  leave our website, we encourage you to read the privacy notice of every website you visit.
                </p>
              </div>
              <div class="mb-3">
                <h5 class="my-1 font-weight-bold">
                  What is our legal basis for processing data?
                </h5>
                <p>
                  We have a legitimate interest to process data on behalf of the Client (the data controller) that has
                  instructed us to process your data for the purposes of psychometric assessment, or to provide our
                  services to you as a Client. The processing we perform is necessary for us to be able to provide our
                  service and we have not identified another less-intrusive way to achieve the same result. We have
                  performed a Legitimate Interests Assessment (LIA) to ensure that the processing carried out does not
                  significantly impact your rights and freedoms. We will only ever use your data provided for the
                  provision of services and for providing support; we will not use your data for any other purpose(s),
                  such as marketing or advertising.
                </p>
              </div>
              <div class="mb-3">
                <h5 class="my-1 font-weight-bold">
                  Data protection principles
                </h5>
                <p>
                  We will comply with data protection law and principles, which means that your data will be:
                </p>
                <ul class="list-style-bullet">
                  <li>
                    <p>Used lawfully, fairly and in a transparent way.</p>
                  </li>
                  <li>
                    <p>
                      Collected only for valid purposes that we have clearly explained to you and not used in any way
                      that is incompatible with those purposes.
                    </p>
                  </li>
                  <li>
                    <p>
                      Relevant to the purposes we have told you about and limited only to those purposes.
                    </p>
                  </li>
                  <li>
                    <p>
                      Accurate and kept up to date.
                    </p>
                  </li>
                  <li>
                    <p>
                      Kept only as long as necessary for the purposes we have told you about.
                    </p>
                  </li>
                  <li>
                    <p>
                      Kept securely.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              2. THE DATA WE COLLECT ABOUT YOU
            </h4>
            <div class="ml-4">
              <div class="mb-3">
                <p>
                  In connection with your assessment and in connection with your professional use of our products or
                  services (in the case of a Client), we will collect, store, and use the following categories of
                  personal information about you:
                </p>
                <ul class="list-style-bullet mb-1">
                  <li>
                    <p>
                      Some of the information you have provided on your application form or account registration,
                      including your name and personal email address.
                    </p>
                  </li>
                  <li>
                    <p>
                      Any information you opt to provide to us personally.
                    </p>
                  </li>
                  <li>
                    <p>
                      Assessment results we calculate based on your responses to our questions.
                    </p>
                  </li>
                </ul>
                <p>
                  We may also collect, store, and use the following "special categories" of more sensitive personal
                  information:
                </p>
                <ul class="list-style-bullet mb-3">
                  <li>
                    <p>
                      In some cases, special category information is transferred to us such as your date of birth,
                      gender, sexual orientation, and ethnicity. These special category data are used only in relation
                      to statistical analyses to ensure that there is no presence of unfair discrimination against
                      legally protected groups.
                    </p>
                  </li>
                  <li>
                    <p>
                      Your disability status may be transferred to us if reasonable adjustments are required during your
                      assessment(s).
                    </p>
                  </li>
                </ul>
                <h5 class="my-1 font-weight-bold">
                  If you fail to provide personal information
                </h5>
                <p>
                  If you fail to provide information when requested, which is necessary for us to provide you with
                  access to our assessment(s), the Client which instructed us to assess you may not be able to process
                  your application successfully.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              3. HOW IS YOUR PERSONAL INFORMATION COLLECTED?
            </h4>
            <div class="ml-4">
              <div class="mb-3">
                <p>We collect personal information about Candidates from the following sources:</p>
                <ul class="list-style-bullet">
                  <li>
                    <p>
                      <span class="font-weight-bold">Yourself,</span> as a User or as a Client.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span class="font-weight-bold">Direct interactions.</span> You may give us your Identity, contact
                      and financial data by filling in forms or by corresponding with us by post, phone, email or
                      otherwise. This includes personal data you provide when you:
                    </p>
                    <ul class="list-style-disc">
                      <li><p>apply for our products or services;</p></li>
                      <li><p>purchase our products or services;</p></li>
                      <li><p>create an account on our website;</p></li>
                      <li><p>complete our assessments;</p></li>
                      <li><p>subscribe to our service or publications;</p></li>
                      <li><p>contact us regarding feedback, queries or customer support;</p></li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <span class="font-weight-bold">Automated technologies or interactions.</span> As you interact with
                      our website, we may automatically collect technical data about your equipment, browsing actions
                      and patterns. We collect this personal data by using cookies, server logs and other similar
                      technologies. Please see our <a>cookie policy</a> for further details.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span class="font-weight-bold">Third parties or publicly available sources.</span> We may receive
                      personal or technical data about you from various third parties as listed in section.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              4. HOW WE USE YOUR PERSONAL DATA
            </h4>
            <div class="ml-4">
              <div class="mb-3">
                <p>We will use the personal information we collect about you to:</p>
                <ul class="list-style-bullet">
                  <li>
                    <p>Assess your performance on the assessment(s) you have been invited to complete.</p>
                  </li>
                  <li>
                    <p>
                      To provide you with necessary credentials to access the platform, provide technical support, and
                      notify you upon receipt of completed assessment(s)
                    </p>
                  </li>
                  <li>
                    <p>
                      Keep records related to your assessment results and make them available to other users for the
                      purposes of resource matching.
                    </p>
                  </li>
                  <li>
                    <p>
                      Keep records related to your account such as assessment results.
                    </p>
                  </li>
                  <li>
                    <p>
                      Comply with legal or regulatory requirements.
                    </p>
                  </li>
                </ul>
                <p>
                  Generally we do not rely on consent as a legal basis for processing your personal data other than in
                  relation to sending third party direct marketing communications to you via email or text message. You
                  have the right to withdraw consent to marketing at any time by contacting us at
                  info@socialcapitalexchange.org.
                </p>
              </div>
              <div class="mb-3">
                <h5 class="my-1 font-weight-bold">
                  Purposes for which we will use your data
                </h5>
                <p>
                  We have set out below, in a table format, a description of all the ways we plan to use your personal
                  data, and which of the legal bases we rely on to do so. We have also identified what our legitimate
                  interests are where appropriate.
                </p>
                <p>
                  Note that we may process your personal data for more than one lawful ground depending on the specific
                  purpose for which we are using your data. Please contact us if you need details about the specific
                  legal ground we are relying on to process your personal data where more than one ground has been set
                  out in the table below.
                </p>
                <div class="w-100 my-2">
                  <table class="table table-bordered table-responsive">
                    <thead class="thead-dark">
                    <tr>
                      <th scope="col">Purpose/Activity</th>
                      <th scope="col">Type of data</th>
                      <th scope="col">Lawful basis for processing including basis of legitimate interest</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>To register you as a Member</td>
                      <td>
                        (a) Identity<br>
                        (b) Contact
                      </td>
                      <td>
                        (a) Performance of, or for entering into, a contract between yourself and the data controller,
                        or between yourself and SoCapX directly <br>
                        (b) Necessary for our legitimate interests
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To process and deliver your order including:<br>
                        (a) Manage payments, fees and charges<br>
                        (b) Collect and recover money owed to us
                      </td>
                      <td>
                        (a) Identity<br>
                        (b) Contact<br>
                        (c) Financial<br>
                        (d) Transaction
                      </td>
                      <td>
                        (a) Performance of a contract with you<br>
                        (b) Necessary for our legitimate interests (to recover debts due to us)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To manage our relationship with you which will include:<br>
                        (a) Notifying you about changes to our terms or privacy policy<br>
                        (b) Asking you to leave a review or take a survey
                      </td>
                      <td>
                        (a) Identity<br>
                        (b) Contact<br>
                        (c) Marketing and Communications
                      </td>
                      <td>
                        (a) Performance of a contract between yourself and the data controller, or between yourself and
                        SoCapX directly<br>
                        (b) Necessary to comply with a legal obligation<br>
                        (c) Necessary for our legitimate interests (to keep our records updated and to improve how
                        customers use our products and services)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To administer and protect our business and this website (including troubleshooting, data
                        analysis, testing, system maintenance, support, reporting and hosting of data)
                      </td>
                      <td>
                        (a) Identity<br>
                        (b) Contact<br>
                        (c) Technical
                      </td>
                      <td>
                        (a) Necessary for our legitimate interests (for running our business, provision of
                        administration and IT services, network security, to prevent fraud and in the context of a
                        business reorganisation or group restructuring exercise)<br>
                        (b) Necessary to comply with a legal obligation
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To use data analytics to improve our website, products/services, marketing, customer
                        relationships and experiences
                      </td>
                      <td>
                        (a) Technical<br>
                        (b) Usage
                      </td>
                      <td>
                        Necessary for our legitimate interests (to define types of customers for our products and
                        services, to provide technical support, to keep our website updated and useful, to develop our
                        business and to inform our marketing strategy)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To make suggestions and recommendations to you about goods or services that may be of interest
                        to you
                      </td>
                      <td>
                        (a) Identity<br>
                        (b) Contact<br>
                        (c) Technical<br>
                        (d) Usage
                      </td>
                      <td>
                        Necessary for our legitimate interests (to develop our products and services and to grow our
                        business)
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mb-3">
                <h5 class="my-1 font-weight-bold">
                  Information about criminal convictions
                </h5>
                <p>
                  We do not envisage that we will process information about criminal convictions.
                </p>
                <p>
                  Although we use statistical algorithms to score your assessments, you will not be subject to decisions
                  that will significantly impact you based on solely automated decision-making. We have a legitimate
                  interest in performing profiling, because this is the most appropriate way to provide our service, and
                  such processing is based on your assessment only.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              5. DATA SHARING
            </h4>
            <div class="ml-4">
              <h5 class="my-1 font-weight-bold">
                Why might we share your personal information with third parties?
              </h5>
              <div class="mb-3">
                <p>
                  We will use the following third third party services to process your personal information for the
                  purposes of providing the psychometric assessment service and storing the results of assessment(s):
                </p>
                <ul class="list-style-bullet">
                  <li>
                    <p>Amazon Web Services Inc.;</p>
                  </li>
                  <li>
                    <p>Atlassian PTY Ltd;</p>
                  </li>
                  <li>
                    <p>Digital Ocean PTY Ltd;</p>
                  </li>
                  <li>
                    <p>Cloudflare Inc.;</p>
                  </li>
                  <li>
                    <p>Google LLC;</p>
                  </li>
                  <li>
                    <p>Microsoft Corporation;</p>
                  </li>
                </ul>
                <p>
                  All our third-party service providers are required to take appropriate security measures to protect
                  your personal information in line with our policies. We do not allow our third-party service providers
                  to use your personal data for their own purposes. We only permit them to process your personal data
                  for specified purposes and in accordance with the instructions of the data controller. All data
                  tspan to third parties is done so in accordance with the EU's <strong class="font-weight-bold">Standard
                  Contractual
                  Clauses (SCC).</strong>
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                Cookies
              </h5>
              <div class="mb-3">
                <p>
                  You can set your browser to refuse all or some browser cookies, or to alert you when websites set or
                  access cookies. If you disable or refuse cookies, please note that some parts of this website our
                  assessment web application may become inaccessible or not function properly. For more information
                  about the cookies we use, please read our <a>cookie policy</a>.
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                Change of purpose
              </h5>
              <div class="mb-3">
                <p>
                  We will only use your personal data for the purposes for which we collected it, unless we reasonably
                  consider that we need to use it for another reason, and that reason is compatible with the original
                  purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible
                  with the original purpose, please contact us.
                </p>
                <p>
                  If we need to use your personal data for an unrelated purpose, we will notify you and we will explain
                  the legal basis which allows us to do so.
                </p>
                <p>
                  Please note that we may process your personal data without your knowledge or consent, in compliance
                  with the above rules, where this is required or permitted by law.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              6. INTERNATIONAL TRANSFERS
            </h4>
            <div class="ml-4">
              <div class="mb-3">
                <p>
                  Some of our external third parties are based outside the European Economic Area (EEA), so their
                  processing of your personal data will involve a transfer of data outside the EEA.
                </p>
                <p>
                  Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is
                  afforded to it by ensuring at least one of the following safeguards is implemented:
                </p>
                <ul class="list-style-bullet">
                  <li>
                    <p>
                      We will only transfer your personal data to countries that have been deemed to provide an adequate
                      level of protection for personal data by the European Commission. For further details, see
                      European Commission: Adequacy of the protection of personal data in non-EU countries.
                    </p>
                  </li>
                  <li>
                    <p>
                      Where we use certain service providers, we use Standard Contractual Clauses (SCC) approved by the
                      European Commission which give personal data the same protection it has in Europe. For further
                      details, see European Commission: Model contracts for the transfer of personal data to third
                      countries.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span class="font-weight-bold">Note:</span> since the Schrems II ruling on 16 July 2020 we no
                      longer rely on the EU-US
                      Privacy Shield
                      for transferring data outside the EEA.
                    </p>
                  </li>
                </ul>
                <p>
                  Please contact us if you want further information on the specific mechanism used by us when
                  transferring your personal data out of the EEA.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              7. DATA SECURITY
            </h4>
            <div class="ml-4">
              <div class="mb-3">
                <p>
                  We have put in place appropriate security measures to prevent your personal information from being
                  accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we
                  limit access to your personal information to those employees, agents, contractors and other third
                  parties who have a business need-to-know. They will only process your personal information on
                  instruction from the data controller, and they are subject to a duty of confidentiality.
                </p>
                <p>
                  We have put in place procedures to deal with any suspected data security breach, and will notify you
                  and any applicable regulator of a suspected breach where we are legally required to do so.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              8. DATA RETENTION
            </h4>
            <div class="ml-4">
              <h5 class="my-1 font-weight-bold">
                How long will you keep my information for?
              </h5>
              <div class="mb-3">
                <p>
                  In the case of Candidates, we will retain your personal information for a period of time specified by
                  the Client that instructed SoCapX to assess you. This is typically 24 months after you have completed
                  our assessment(s), or within twenty business days of receiving from you or the Client a request to
                  delete your data, whichever is the sooner. In the case of Clients, we will retain your personal
                  information for a period of 90 days after you close your account with us, or within ten business days
                  of receiving your request to delete your data, whichever is the sooner.
                </p>
                <p>
                  We retain your personal information for that period either for our internal records that we are
                  obligated to keep, or so that the data controller can show (in the event of a legal claim) that they
                  have not discriminated against candidates on prohibited grounds, and that they have conducted the
                  selection exercise in a fair and transparent way. After this period, we will securely destroy your
                  personal information in accordance with the data controller's instruction, and in accordance with our
                  data retention policy.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              9. YOUR LEGAL RIGHTS
            </h4>
            <div class="ml-4">
              <h5 class="my-1 font-weight-bold">
                Your rights in connection with personal information
              </h5>
              <div class="mb-3">
                <p>Under certain circumstances, by law you have the right to:</p>
                <ul class="list-style-bullet">
                  <li>
                    <p>Request access to your personal information.</p>
                  </li>
                  <li>
                    <p>Request correction of the personal information.</p>
                  </li>
                  <li>
                    <p>Request erasure of your personal information.</p>
                  </li>
                  <li>
                    <p>Object to processing of your personal information.</p>
                  </li>
                  <li>
                    <p>Request the restriction of processing of your personal information.</p>
                  </li>
                  <li>
                    <p>Request the transfer of your personal information to another party.</p>
                  </li>
                  <li>
                    <p>Withdraw consent.</p>
                  </li>
                  <li>
                    <p>Invoke your rights in relation to automated decision-making and profiling.</p>
                  </li>
                </ul>
                <p>
                  If you want to review, verify, correct, or request erasure of your personal information, object to
                  the processing of your personal data, or request that we transfer a copy of your personal information
                  to another party, please contact info@socialcapitalexchange.org.
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                No fee usually required
              </h5>
              <div class="mb-3">
                <p>
                  You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
                  However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
                  Alternatively, we may refuse to comply with your request in these circumstances.
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                What we may need from you
              </h5>
              <div class="mb-3">
                <p>
                  We may need to request specific information from you, to help us confirm your identity, and to ensure
                  your right to access your personal data (or to exercise any of your other rights). This is a security
                  measure to ensure that personal data is not disclosed to any person who has no right to receive it. We
                  may also contact you to ask you for further information in relation to your request, to speed up our
                  response.
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                Time limit to respond
              </h5>
              <div class="mb-3">
                <p>
                  We try to respond to all legitimate requests within one month. Occasionally it may take us longer than
                  a month if your request is particularly complex, or you have made a number of requests. In this case,
                  we will notify you and keep you updated.
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                Data protection officer
              </h5>
              <div class="mb-3">
                <p>
                  We have appointed a data protection officer (DPO) to oversee compliance with this privacy notice. If
                  you have any questions about this privacy notice or how we handle your personal information, please
                  contact the DPO at the following email address: info@socialcapitalexchange.org.
                </p>
              </div>
            </div>

            <h4 class="font-weight-bold my-1">
              10. GLOSSARY
            </h4>
            <div class="ml-4">
              <h5 class="my-1 font-weight-bold">
                Lawful basis
              </h5>
              <div class="mb-3">
                <p>
                  <span class="font-weight-bold">Legitimate Interest</span> means the interest of our business in
                  conducting and managing our
                  business, to enable us to give you the best service/product, and the best and most secure experience.
                  We make sure we consider and balance any potential impact on you (both positive and negative) and your
                  rights before we process your personal data for our legitimate interests. We do not use your personal
                  data for activities where our interests are overridden by the impact on you (unless we have your
                  consent or are otherwise required or permitted to by law). You can obtain further information about
                  how we assess our legitimate interests against any potential impact on you in respect of specific
                  activities by contacting us.
                </p>
                <p>
                  <span class="font-weight-bold">Performance of Contract</span> means processing your data where it is
                  necessary for the
                  performance of a contract to which you are a party, or to take steps at your request before entering
                  into such a contract
                </p>
                <p>
                  <span class="font-weight-bold">Comply with a legal or regulatory obligation</span> means processing
                  your personal data
                  where it is necessary for compliance with a legal or regulatory obligation that we are subject to.
                </p>
              </div>

              <h5 class="my-1 font-weight-bold">
                Third parties
              </h5>
              <div class="mb-3">
                <p>Third parties include:</p>
                <ul class="list-style-bullet">
                  <li>
                    <p>
                      Service providers who act as sub-processors based in either the EEA, in an adequate country, or
                      non-adequate countries provided they comply with EU's Standard Contractual Clauses, who provide
                      and system administration services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Professional advisers acting as processors including lawyers, auditors and insurers based in the
                      South Africa provided they comply with the Standard Contractual Clauses, who provide consultancy,
                      legal, insurance and accounting services.
                    </p>
                  </li>
                </ul>
              </div>

              <h5 class="my-1 font-weight-bold">
                Your legal rights
              </h5>
              <div class="mb-3">
                <p>
                  <span class="font-weight-bold">Request access</span> to your personal data (commonly known as a "data
                  subject access
                  request"). This enables you to receive a copy of the personal data we hold about you, and to check
                  that we are lawfully processing it.
                </p>
                <p>
                  <span class="font-weight-bold">Request correction</span> of the personal data that we hold about you.
                  This enables you to
                  have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the
                  accuracy of the new data you provide to us.
                </p>
                <p>
                  <span class="font-weight-bold">Request erasure</span> of your personal data. This enables you to ask
                  us to delete or remove
                  personal data where there is no good reason for us continuing to process it. You also have the right
                  to ask us to delete or remove your personal data where you have successfully exercised your right to
                  object to processing (see below), where we may have processed your information unlawfully or where we
                  are required to erase your personal data to comply with local law. Note, however, that we may not
                  always be able to comply with your request of erasure for specific legal reasons which will be
                  notified to you, if applicable, at the time of your request.
                </p>
                <p>
                  <span class="font-weight-bold">Object to processing</span> of your personal data where we are relying
                  on a legitimate
                  interest (or those of a third party) and there is something about your particular situation which
                  makes you want to object to processing on this ground as you feel it impacts on your fundamental
                  rights and freedoms. You also have the right to object where we are processing your personal data for
                  direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate
                  grounds to process your information which override your rights and freedoms.
                </p>
                <p>
                  <span class="font-weight-bold">Withdraw consent at any time</span> where relying on consent to process
                  your personal data.
                  However, this will not affect the lawfulness of any processing carried out before you withdraw your
                  consent. If you withdraw your consent, we may not be able to provide certain products or services to
                  you. We will advise you if this is the case at the time you withdraw your consent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
