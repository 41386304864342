import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedCommonModule} from '../../shared/modules/shared-common/shared-common.module';
import {FormElementsModule} from '../../shared/modules/form-elements/form-elements.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageUploadModule} from '../../shared/modules/image-upload/image-upload.module';
import {DocumentUploadModule} from '../../shared/modules/file-upload/document-upload.module';
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import {ServicesComponent} from './components/services/services.component';
import {AboutComponent} from './components/about/about.component';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {RouterModule} from '@angular/router';
import {FaqComponent} from './components/faq/faq.component';
import {PublicLandingComponent} from './components/landing-container/public-landing/public-landing.component';
import {LandingContainerComponent} from './components/landing-container/landing-container.component';
import {ApplicantLandingComponent} from './components/landing-container/applicant-landing/applicant-landing.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../shared/modules/alert/alert.module';
import {PrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';


@NgModule({
  declarations: [
    ContactUsComponent,
    ServicesComponent,
    AboutComponent,
    FaqComponent,
    SubscribeComponent,
    AboutComponent,
    LandingContainerComponent,
    PublicLandingComponent,
    ApplicantLandingComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    SharedCommonModule,
    FormElementsModule,
    ImageUploadModule,
    DocumentUploadModule,
    FormElementsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxCaptchaModule,
    ToastContainerModule,
    AlertModule
  ],
  exports: [
    LandingContainerComponent,
  ]
})
export class PublicModule {
}
