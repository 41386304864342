import {Pipe, PipeTransform} from '@angular/core';
import {organisationTypeAnswers} from '../../../../../modules/constants/profile-category-answers.const';

@Pipe({
  name: 'organisationType'
})
export class OrganisationTypePipe implements PipeTransform {

  organisationTypeAnswers = organisationTypeAnswers;

  transform(values: string[]): string[] {
    return values.map(val => {
      return this.getTypeByValue(val);
    });
  }

  getTypeByValue(code: string): string {
    const found = this.organisationTypeAnswers.find(item => item.value === code);
    if (found) {
      return found.label;
    }

    return 'Unknown';
  }

}
