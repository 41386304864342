import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {GrantWorkflowService} from './store/grant-workflow.service';
import {GrantWorkflow} from './store/grant-workflow.model';

const getStepUrl = (id: number, step: string) => {
  switch (step) {
    case 'ELIGIBILITY': {
      return `/grant-workflow/${id}/eligibility`;
    }
    case 'INELIGIBLE': {
      return `/grant-workflow/${id}/ineligible`;
    }
    case 'APPLICATION': {
      return `/grant-workflow/${id}/application`;
    }
    case 'APPLICATION_SUBMITTED': {
      return `/grant-workflow/${id}/application-confirmation`;
    }
    default: {
      return '/landing';
    }
  }
};


@Injectable({
  providedIn: 'root'
})
export class GrantWorkflowNavigator {

  constructor(public router: Router,
              public toastrService: ToastrService,
              private grantWorkflowService: GrantWorkflowService) {
  }

  routeToComponent(grantWindowId: number, orgId: number) {
    this.grantWorkflowService.fetchWorkflowByGrantWindowIdAndOrgId(grantWindowId, orgId).subscribe(
      (workflow: GrantWorkflow) => {
        if (workflow) {
          this.validateAndRoute(workflow);
        } else {
          // 2. if no workflow is found or if not agreed then create one and then fetch it
          this.router.navigateByUrl(`/grant-workflow/application-agreement/${grantWindowId}/${orgId}`);
        }
      }, () => {
        this.toastrService.error('Failed to fetch Grant Workflow', 'Grant Workflow Error');
      });
  }

  routeToComponentByWorkflowId(workflowId: number) {
    this.grantWorkflowService.fetchWorkflowById(workflowId).subscribe(
      (workflow: GrantWorkflow) => {
        this.validateAndRoute(workflow);
      }, () => {
        this.toastrService.error(`Failed to fetch Grant Workflow by id: ${workflowId}`, 'Grant Workflow Error');
      });
  }

  private validateAndRoute(workflow: GrantWorkflow) {
    if (workflow.hasAgreed) {
      this.router.navigateByUrl(getStepUrl(workflow.id, workflow.step));
    } else {
      this.toastrService.error('Failed to agree to terms and conditions of application', 'Grant Workflow Error');
    }
  }
}
