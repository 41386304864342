import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsGovernanceAnswerService} from './services/cs-governance-answer.service';
import {take} from 'rxjs/operators';
import {booleanChoices} from '../../../../../../constants/boolean-answers.const';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-governance',
  templateUrl: './cs-governance.component.html',
  styleUrls: ['./cs-governance.component.scss']
})
export class CsGovernanceComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() governanceForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  // Question Answers.
  managementStructureAnswers = [];
  decisionMakingAnswers = [];
  successionPlanningAnswers = [];
  founderRelationshipAnswers = [];
  directorsAnswers = [];
  policyElementAnswers = [];
  boardMemberProcessAnswers = [];
  boardMemberTermAnswers = [];
  meetingMinutesAnswers = [];
  reportFrequencyAnswers = [];
  booleanChoices = booleanChoices;

  managementStructureSubscription: Subscription;
  decisionMakingSubscription: Subscription;
  successionPlanningSubscription: Subscription;
  founderRelationshipSubscription: Subscription;
  directorsSubscription: Subscription;
  policyElementSubscription: Subscription;
  boardMemberProcessSubscription: Subscription;
  boardMemberTermSubscription: Subscription;
  meetingMinutesSubscription: Subscription;
  reportFrequencySubscription: Subscription;
  childFormTouched: boolean;

  constructor(private loadingService: LoadingService,
              private csGovernanceAnswerService: CsGovernanceAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.governanceForm.touched;

    this.populateAnswers();
  }

  public checkOther(value: string, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy(): void {
    if (this.managementStructureSubscription) {
      this.managementStructureSubscription.unsubscribe();
    }
    if (this.decisionMakingSubscription) {
      this.decisionMakingSubscription.unsubscribe();
    }
    if (this.successionPlanningSubscription) {
      this.successionPlanningSubscription.unsubscribe();
    }
    if (this.founderRelationshipSubscription) {
      this.founderRelationshipSubscription.unsubscribe();
    }
    if (this.directorsSubscription) {
      this.directorsSubscription.unsubscribe();
    }
    if (this.policyElementSubscription) {
      this.policyElementSubscription.unsubscribe();
    }
    if (this.boardMemberProcessSubscription) {
      this.boardMemberProcessSubscription.unsubscribe();
    }
    if (this.boardMemberTermSubscription) {
      this.boardMemberTermSubscription.unsubscribe();
    }
    if (this.meetingMinutesSubscription) {
      this.meetingMinutesSubscription.unsubscribe();
    }
    if (this.reportFrequencySubscription) {
      this.reportFrequencySubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csGovernanceAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.managementStructureSubscription = this.csGovernanceAnswerService.$managementStructure.subscribe(answers => {
      if (answers) {
        this.managementStructureAnswers = answers.choices;
      }
    });

    this.decisionMakingSubscription = this.csGovernanceAnswerService.$decisionMaking.subscribe(answers => {
      if (answers) {
        this.decisionMakingAnswers = answers.choices;
      }
    });

    this.successionPlanningSubscription = this.csGovernanceAnswerService.$successionPlanning.subscribe(answers => {
      if (answers) {
        this.successionPlanningAnswers = answers.choices;
      }
    });

    this.founderRelationshipSubscription = this.csGovernanceAnswerService.$founderRelationship.subscribe(answers => {
      if (answers) {
        this.founderRelationshipAnswers = answers.choices;
      }
    });

    this.directorsSubscription = this.csGovernanceAnswerService.$directors.subscribe(answers => {
      if (answers) {
        this.directorsAnswers = answers.choices;
      }
    });

    this.policyElementSubscription = this.csGovernanceAnswerService.$policyElement.subscribe(answers => {
      if (answers) {
        this.policyElementAnswers = answers.choices;
      }
    });

    this.boardMemberProcessSubscription = this.csGovernanceAnswerService.$boardMemberProcess.subscribe(answers => {
      if (answers) {
        this.boardMemberProcessAnswers = answers.choices;
      }
    });

    this.boardMemberTermSubscription = this.csGovernanceAnswerService.$boardMemberTerm.subscribe(answers => {
      if (answers) {
        this.boardMemberTermAnswers = answers.choices;
      }
    });

    this.meetingMinutesSubscription = this.csGovernanceAnswerService.$meetingMinutes.subscribe(answers => {
      if (answers) {
        this.meetingMinutesAnswers = answers.choices;
      }
    });

    this.reportFrequencySubscription = this.csGovernanceAnswerService.$reportFrequency.subscribe(answers => {
      if (answers) {
        this.reportFrequencyAnswers = answers.choices;
      }
    });
  }
}
