<form [formGroup]="siteVisitOrgStructureForm">
  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'numberOfWorkersIsCorrect'"
    [label]="'Number of people working at the organization'"
    [isRequired]="true"
    [id]="'numberOfWorkersIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="numberOfWorkersIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'hrPolicyIsCorrect'"
    [label]="'HR policy'"
    [isRequired]="true"
    [id]="'hrPolicyIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="hrPolicyIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'jobDescriptionsIsCorrect'"
    [label]="'Job descriptions'"
    [isRequired]="true"
    [id]="'jobDescriptionsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="jobDescriptionsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'boardStructureIsCorrect'"
    [label]="'Board structure and involvement'"
    [isRequired]="true"
    [id]="'boardStructureIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="boardStructureIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'reportsIsCorrect'"
    [label]="'Organizational reports'"
    [isRequired]="true"
    [id]="'reportsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="reportsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'constitutionIsCorrect'"
    [label]="'Constitution'"
    [isRequired]="true"
    [id]="'constitutionIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="constitutionIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'mAndEIsCorrect'"
    [label]="'M&E system to monitor activities and beneficiaries'"
    [isRequired]="true"
    [id]="'mAndEIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="monitoringAndEIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'leaseAgreementIsCorrect'"
    [label]="'Lease agreement'"
    [isRequired]="true"
    [id]="'leaseAgreementIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="leaseAgreementIsCorrect"
  ></app-widget-check-two>
</form>
