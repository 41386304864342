import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {CancelConfirmGuard} from '../../../shared/guards/cancel-confirm/cancel-confirm.guard';
import {CapacitySpectrumComponent} from './components/capacity-spectrum/capacity-spectrum.component';
import {CapacitySpectrumResultsComponent} from './components/capacity-spectrum-results/capacity-spectrum-results.component';

const routes: Routes = [
  {path: 'edit/:orgId', component: CapacitySpectrumComponent, canDeactivate: [CancelConfirmGuard]},
  {path: 'results', component: CapacitySpectrumResultsComponent},
  {path: 'results/:orgId', component: CapacitySpectrumResultsComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CapacitySpectrumRoutingModule { }
