<div *ngIf="selectedWorkPlan" class="card">
  <div class="border-bottom-0 ml-0 mr-0 pt-2 pb-2 border">
    <div class="w-100 flex-center text-uppercase">
      <strong>Total funding requested for
        <u>{{selectedWorkPlan.name}}</u>
      </strong>
    </div>
    <div *ngIf="selectedWorkPlan?.workPlanBudgets" class="flex-end">
      <app-update-budget
        *authenticatedWithRole="['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']"
        [isReadOnly]="isReadOnly"
        [coordinatorReadOnly]="coordinatorReadOnly"
        [workPlan]="selectedWorkPlan"
      ></app-update-budget>
    </div>
  </div>


  <div *ngIf="selectedWorkPlan?.workPlanBudgets" class="charts-dash">

    <div class="budget-chart">
      <div class="w-100 h-100 border">
        <div class="card-body">
          <app-grouped-vertical-bar-chart
            *ngIf="budgetChartData && budgetChartData[0].name"
            [chartData]="budgetChartData"
            [view]="[1200, 200]"
            [xAxisLabel]="'Categories'"
            [yAxisLabel]="'Amount'"
            [legendTitle]="'Budget'"
          ></app-grouped-vertical-bar-chart>
        </div>
      </div>
    </div>

  </div>

</div>


<app-objective
  [isReadOnly]="isReadOnly"
  [applicantReadOnly]="applicantReadOnly"
  [coordinatorReadOnly]="coordinatorReadOnly"
></app-objective>
