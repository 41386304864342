import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../shared/models/base-entity.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  createFormBuilderDTO, FormBuilderDTO
} from '../../../../../../form-builder/components/form-builder-list/form-builder/store/form-builder.model';
import {QuestionControlService} from '../../../../../../form-builder/store/question-control.service';

export interface FormCapture extends BaseEntity {
  form: FormBuilderDTO;
  answers: FormCaptureAnswer[];
}

export function createFormCapture(params: Partial<FormCapture>) {
  return Object.assign({}, createBaseEntity(params), {
    form: params && createFormBuilderDTO(params.form),
    answers: params && createFormCaptureAnswerList(params.answers),
  }) as FormCapture;
}

export function createFormCaptureForm(formBuilder: FormBuilder, qcs: QuestionControlService, params?: Partial<FormCapture>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    form: createFormBuilderDTO(params && params.form),
    // answers: createFormCaptureAnswerForms(formBuilder, params && params.answers),
    answers: qcs.toFormGroup(params && params.form),
  }));
}


export interface FormCaptureAnswer extends BaseEntity {
  key: string;
  value: any;
  score?: number;
}

export function createFormCaptureAnswerList(params: Partial<FormCaptureAnswer>[]) {
  const list: FormCaptureAnswer[] = [];
  if (params) {
    for (const param of params) {
      list.push(createFormCaptureAnswer(param));
    }
  }
  return list;
}

export function createFormCaptureAnswer(params: Partial<FormCaptureAnswer>) {
  return Object.assign({}, createBaseEntity(params), {
    key: params && params.key,
    value: params && params.value,
  }) as FormCaptureAnswer;
}


export function createFormCaptureAnswerForms(formBuilder: FormBuilder, params?: Partial<FormCaptureAnswer>[]): FormGroup[] {
  const list: FormGroup[] = [];
  if (params) {
    for (const param of params) {
      list.push(createFormCaptureAnswerForm(formBuilder, param));
    }
  }
  return list;
}

export function createFormCaptureAnswerForm(formBuilder: FormBuilder, params?: Partial<FormCaptureAnswer>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    key: [params && params.key || '', Validators.required],
    value: [params && params.value || '', Validators.required],
  }));
}
