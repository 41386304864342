import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  createWorkPlanBudgetListForm,
  WorkPlan,
} from '../../../../../store/work-plan-and-targets.model';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {WorkPlanAndTargetsService} from '../../../../../store/work-plan-and-targets.service';
import {InputMask} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';
import {AlertService} from '../../../../../../../shared/modules/alert/services/alert.service';
import {WorkPlanCategoriesPipe} from '../../../../../../../shared/modules/shared-common/pipes/work-plan-categories/work-plan-categories.pipe';

@Component({
  selector: 'app-update-budget',
  templateUrl: './update-budget.component.html',
  styleUrls: ['./update-budget.component.scss']
})
export class UpdateBudgetComponent implements OnInit {
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() workPlan: WorkPlan;
  @Input() isReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;

  options: NgbModalOptions;
  profileForm: FormGroup;
  mask: InputMask;

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private workPlanCategoriesPipe: WorkPlanCategoriesPipe,
              private workPlanAndTargetsService: WorkPlanAndTargetsService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Budget Requested',
      centered: true,
      size: 'lg'
    };

    this.mask = {integerLimit: 7};

    this.profileForm = createWorkPlanBudgetListForm(this.formBuilder, this.workPlan);
  }

  openModal(event: Event) {
    if (!this.isReadOnly && !this.coordinatorReadOnly) {
      this.modalService.openBasicModal(this.modalContent, this.options);
    } else {
      // Prevent the click event from visually affecting the anchor tag
      event.stopImmediatePropagation();
    }
  }

  closeModal() {
    this.modalService.closeModal();
  }

  update() {
    if (this.profileForm.valid) {
      this.workPlanAndTargetsService.updateWorkPlanBudgetList(this.profileForm.value.budgets).subscribe(() => {
        this.closeModal();
      }, error => {
        const errorText = (error && error.error && error.error.message) || 'Failed to update budget';
        this.alertService.setAlert(errorText, 'error', 'update-budget-form');
      });
    } else {
      this.alertService.setAlert('Please complete all required questions before submitting.', 'error', 'update-budget-form');
    }
  }

  getCategory(category: string) {
    return this.workPlanCategoriesPipe.transform(category);
  }
}
