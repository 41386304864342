<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="innovationForm">
    <app-input-select-single
      *ngIf="computerAccessAnswers?.length"
      [label]="'Who in the organisation has regular access to a laptop or desktop computer?'"
      [items]="computerAccessAnswers"
      [id]="'computerAccess'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="computerAccess"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="internetAccessAnswers?.length"
      [label]="'Which statement best describes the organisation\'s access to the internet?'"
      [items]="internetAccessAnswers"
      [id]="'internetAccess'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="internetAccess"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="internetConnectionAnswers?.length"
      [label]="'How does the organisation connect to the internet?'"
      [items]="internetConnectionAnswers"
      [id]="'internetConnection'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        'OTHER',
        $event,
        innovationForm.controls.internetConnection,
        innovationForm.controls.internetConnectionOther
      )"
      formControlName="internetConnection"
    ></app-input-select-single>

    <div *ngIf="innovationForm?.value?.internetConnection === 'OTHER'" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'internetConnectionOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="internetConnectionOther"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="itEquipmentAnswers?.length"
      [label]="'Which statement best describes the organisation\'s IT equipment?'"
      [items]="itEquipmentAnswers"
      [id]="'itEquipment'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="itEquipment"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="systemSupportAnswers?.length"
      [label]="'How are the systems and technology used by the organisation supported and maintained?'"
      [items]="systemSupportAnswers"
      [id]="'systemSupport'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="systemSupport"
    ></app-input-select-single>

    <app-input-select-single
      [label]="'Does your organisation have a  IT manager?'"
      [items]="booleanChoices"
      [id]="'hasITManager'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hasITManager"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="itPolicyAnswers?.length"
      [label]="'Does the organisation have an IT policy?'"
      [items]="itPolicyAnswers"
      [id]="'itPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="itPolicy"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="popiPolicyAnswers?.length"
      [label]="'Does the organisation have a privacy / POPI policy?'"
      [items]="popiPolicyAnswers"
      [id]="'popiPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="popiPolicy"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="softwareAnswers?.length"
      [label]="'Does the organisation currently use any of the following software? (check all that apply)'"
      [choices]="softwareAnswers"
      [id]="'softwareList'"
      [name]="'softwareList'"
      [theme]="'light'"
      [style]="'default'"
      [cols]="2"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther(
        'OTHER',
        $event,
        innovationForm.controls.softwareList,
        innovationForm.controls.softwareOther
      )"
      formControlName="softwareList"
    ></app-input-checkbox>

    <div *ngIf="innovationForm?.value?.softwareList?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'softwareOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="softwareOther"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="customSoftwareAnswers?.length"
      [label]="'Does the organisation currently use any custom software?'"
      [items]="customSoftwareAnswers"
      [id]="'customSoftware'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        'YES',
        $event,
        innovationForm.controls.customSoftware,
        innovationForm.controls.customSoftwareExplain
      )"
      formControlName="customSoftware"
    ></app-input-select-single>


    <div *ngIf="innovationForm?.value?.customSoftware === 'YES'" class="ml-5">
      <app-input-text
        [label]="'Please Explain'"
        [id]="'customSoftwareExplain'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="customSoftwareExplain"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="techTrainingAnswers?.length"
      [label]="'Which statement best describes the level of tech tech training provided to staff?'"
      [items]="techTrainingAnswers"
      [id]="'techTraining'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="techTraining"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="managementSkillsAnswers?.length"
      [label]="'How would you rate the average computer literacy / skills of the organisation\'s management staff?'"
      [items]="managementSkillsAnswers"
      [id]="'managementSkills'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="managementSkills"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="adminSkillsAnswers?.length"
      [label]="'How would you rate the average computer literacy / skills of the organisation\'s admin staff?'"
      [items]="adminSkillsAnswers"
      [id]="'adminSkills'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="adminSkills"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="generalStaffSkillsAnswers?.length"
      [label]="'How would you rate the average computer literacy / skills of the organisation\'s program and field staff?'"
      [items]="generalStaffSkillsAnswers"
      [id]="'generalStaffSkills'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="generalStaffSkills"
    ></app-input-select-single>

  </form>
</app-spinner>
