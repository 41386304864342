import {User} from '../../../shared/stores/user/user.model';
import {ChoiceInputItem} from '../../../shared/modules/form-elements/models/form-elements.model';

export class CoordinatorUtil {
  static convertToChoiceInputItem(users: User[]): ChoiceInputItem[] {
    if (users && users.length) {
      return users.map(user => {
        return {
          label: user.firstName + ' ' + user.lastName,
          value: user.username
        };
      });
    } else {
      return [];
    }
  }
}
