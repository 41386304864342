import {Injectable} from '@angular/core';
import {FormBuilderChoiceStore} from './form-builder-choice.store';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FormBuilderChoice} from './form-builder-choice.model';
import {environment} from '../../../../../../../../../../../environments/environment';
import {tap} from 'rxjs/operators';
import {FormBuilderService} from '../../../../../store/form-builder.service';

@Injectable({providedIn: 'root'})
export class FormBuilderChoiceService {

  url = `${environment.apiUrl}/choice-input-item`;

  constructor(protected store: FormBuilderChoiceStore,
              private formBuilderService: FormBuilderService,
              private http: HttpClient) {
  }

  create(choice: FormBuilderChoice): Observable<FormBuilderChoice> {
    const url = `${this.url}`;
    return this.http.post<FormBuilderChoice>(url, choice);
  }

  update(choice: FormBuilderChoice): Observable<FormBuilderChoice> {
    const url = `${this.url}/${choice.id}`;
    return this.http.put<FormBuilderChoice>(url, choice);
  }

  delete(optionId: number) {
    const url = `${this.url}/${optionId}`;
    return this.http.delete(url).pipe(
      tap(() => {
        this.formBuilderService.getForms().subscribe();
      })
    );
  }

}
