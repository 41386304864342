<div class="body-shell">

  <app-navbar></app-navbar>

  <div class="d-flex" id="wrapper" [ngClass]="{'toggled':toggled}">
    <app-admin-sidebar *authenticatedWithRole="['ROLE_ADMIN']"></app-admin-sidebar>
    <app-funder-sidebar *authenticatedWithRole="['ROLE_FUNDER', 'ROLE_GRANT_MANAGER']"></app-funder-sidebar>
    <app-coordinator-sidebar *authenticatedWithRole="['ROLE_GRANT_COORDINATOR']"></app-coordinator-sidebar>
    <app-applicant-sidebar *authenticatedWithRole="['ROLE_APPLICANT']"></app-applicant-sidebar>
    <div id="page-content-wrapper">
      <div class="body-content container-fluid p-0" #pageTop>
        <router-outlet (activate)="onActivate(pageTop)"></router-outlet>
      </div>
    </div>
  </div>

  <div class="footer">
    <hr class="dropdown-divider border-primary w-75">
    <a class="font-size-small cursor-pointer text-dark">©SoCapX</a>
    |
    <a class="font-size-small cursor-pointer text-dark">Legal</a>
    |
    <a class="font-size-small cursor-pointer text-dark" routerLink="privacy">Privacy</a>
    <hr class="dropdown-divider border-primary w-10">
  </div>

</div>
