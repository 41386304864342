import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  peopleTargetedAnswers, frequencyOfVisitsAnswers
} from '../../constants/funding-request-answers/home-based-care-question-answers.const';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';


@Component({
  selector: 'app-home-based-care',
  templateUrl: './home-based-care.component.html',
  styleUrls: ['./home-based-care.component.scss']
})
export class HomeBasedCareComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() homeBasedCareForm: FormGroup;
  @Input() formValid: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  peopleTargetedOptions = peopleTargetedAnswers;
  frequencyOfVisitsOptions = frequencyOfVisitsAnswers;
  numberMask: any;

  constructor() {
  }

  ngOnInit() {
    this.numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      allowNegative: false,
      includeThousandsSeparator: false
    });
  }
}
