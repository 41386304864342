import { Component, OnInit } from '@angular/core';
import {SessionQuery} from '../../../../shared/stores/session/session.query';

@Component({
  selector: 'app-landing-container',
  templateUrl: './landing-container.component.html',
  styleUrls: ['./landing-container.component.scss']
})
export class LandingContainerComponent implements OnInit {

  constructor(public sessionQuery: SessionQuery) { }

  ngOnInit() {
  }

}
