import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true
    }
  ]
})
export class InputTextareaComponent implements OnInit, OnDestroy, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  @Input() markUnTouched?: boolean;

  // Default
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() hasBorder = true;
  @Input() isGrey = true;
  @Input() rows = '4';
  @Input() maxLength = 4500;
  @Input() minLength = 0;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';

  public valueSrc: string;
  valueSubscription: Subscription;

  set value(val: any) {
    if (val !== undefined && this.valueSrc !== val) {
      this.valueSrc = val;
      this.onChange(val);
      this.onTouch();
    }
  }

  get value(): any {
    return this.valueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: string) {
  }

  onTouch() {
  }

}
