import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NetworkAndLinkagesEndpointType} from '../store/cs-network-and-linkages.model';

@Injectable({
  providedIn: 'root'
})
export class CsNetworkAndLinkagesAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/network-and-linkages`;

  public referralAndLinkageSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $referralAndLinkage = this.referralAndLinkageSource.asObservable();

  public membersInvolvementSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $membersInvolvement = this.membersInvolvementSource.asObservable();

  public communityLinkageSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $communityLinkage = this.communityLinkageSource.asObservable();

  public communityForumFrequencySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $communityForumFrequency = this.communityForumFrequencySource.asObservable();

  public communityProfileSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $communityProfile = this.communityProfileSource.asObservable();

  public communityEngagementSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $communityEngagement = this.communityEngagementSource.asObservable();

  public organisationRelationshipSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $organisationRelationship = this.organisationRelationshipSource.asObservable();

  public governmentRelationshipSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $governmentRelationship = this.governmentRelationshipSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.referralAndLinkageSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case NetworkAndLinkagesEndpointType.REFERRAL_AND_LINKAGE: {
          this.referralAndLinkageSource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.MEMBERS_INVOLVEMENT: {
          this.membersInvolvementSource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.COMMUNITY_LINKAGE: {
          this.communityLinkageSource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.COMMUNITY_FORUM_FREQUENCY: {
          this.communityForumFrequencySource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.COMMUNITY_PROFILE: {
          this.communityProfileSource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.COMMUNITY_ENGAGEMENT: {
          this.communityEngagementSource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.ORGANISATION_RELATIONSHIP: {
          this.organisationRelationshipSource.next(answerListDTO);
          break;
        }
        case NetworkAndLinkagesEndpointType.GOVERNMENT_RELATIONSHIP: {
          this.governmentRelationshipSource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
