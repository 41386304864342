import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ReviewDetailsContainerComponent} from './components/review-details-container/review-details-container.component';
import {ReviewComponent} from '../grant-window/components/dashboards/review-phase/review/review.component';

const routes: Routes = [
  {
    path: '',
    component: ReviewComponent,
    data: {heading: 'Review Applicant List'},
    pathMatch: 'full',
  },
  {
    path: 'details/:workflowId/organisation/:orgId',
    component: ReviewDetailsContainerComponent,
    pathMatch: 'full',
    data: {breadcrumb: 'Organisation', entity: 'ORGANISATION', heading: 'Review Details'}
  },
  {
    path: 'work-plan-and-targets/:workflowId/organisation/:orgId',
    data: {breadcrumb: 'Organisation', entity: 'ORGANISATION', heading: 'Work Plan & Targets'},
    loadChildren: () => import('../../modules/work-plan-and-targets/work-plan-and-targets.module').then(m => m.WorkPlanAndTargetsModule),
  },
  {
    path: 'shortlist',
    data: {breadcrumb: 'Shortlist'},
    loadChildren: () => import('../shortlist/shortlist.module').then(m => m.ShortlistModule),
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class GrantReviewRoutingModule {
}
