<div class="card">
  <div class="card-body">
    <h4 class="text-center">
      <span class="text-capitalize">Additional Documents</span>
    </h4>
    <hr class="w-75 mx-auto border-primary">
    <form class="d-flex justify-content-center p-10">

      <div class="form-group">
        <div class="file-upload"
          [class.file-upload-danger]="(formValid === false || documents.id) && !documents.annualOperatingBudget">
          <app-upload-document
            [label]="'A copy of organization’s annual operating budget for the two most recent years'"
            [additionalParameters]="additionalParameters"
            [(documents)]="documents.annualOperatingBudget"
            (documentsChange)="updateDocumentsForm($event, 'annualOperatingBudget')"
          ></app-upload-document>
          <div
            class="file-upload-error-append"
            *ngIf="(formValid === false || documents.id) && !documents.annualOperatingBudget"
          >
          <span class="file-upload-error">
            <i class="fas fa-exclamation fa-lg text-danger"></i>
          </span>
          </div>
        </div>

        <hr class="w-100">

        <div class="file-upload"
             [class.file-upload-danger]="(formValid === false || documents.id) && !documents.bankStatements">
          <app-upload-document
            [label]="'A copy of last month’s bank statements for every account held by your organization'"
            [additionalParameters]="additionalParameters"
            [documents]="documents.bankStatements"
            (documentsChange)="updateDocumentsForm($event, 'bankStatements')"
          ></app-upload-document>
          <div
            class="file-upload-error-append"
            *ngIf="(formValid === false || documents.id) && !documents.bankStatements">
          <span class="file-upload-error">
            <i class="fas fa-exclamation fa-lg text-danger"></i>
          </span>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
