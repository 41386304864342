import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../environments/environment';
import {Observable} from 'rxjs';
import {CapacitySpectrumStore} from './capacity-spectrum.store';
import {CapacitySpectrum, CapacitySpectrumDTO} from './capacity-spectrum.model';

@Injectable({providedIn: 'root'})
export class CapacitySpectrumService {

  private url = `${environment.apiUrl}/capacity-spectrum`;

  constructor(private capacitySpectrumStore: CapacitySpectrumStore,
              private http: HttpClient) {
  }

  get(orgId: number): Observable<CapacitySpectrumDTO> {
    const url = `${this.url}?orgId=${orgId}`;
    return this.http.get<CapacitySpectrumDTO>(url).pipe(
      tap(entity => {
        this.capacitySpectrumStore.add(entity.capacitySpectrum);
      })
    );
  }

  create(capacitySpectrum: CapacitySpectrum, orgId: number): Observable<CapacitySpectrumDTO> {
    const url = `${this.url}?orgId=${orgId}`;
    return this.http.post<CapacitySpectrumDTO>(url, capacitySpectrum).pipe(
      tap(capacity => {
        this.capacitySpectrumStore.add(capacity.capacitySpectrum);
      })
    );
  }

  update(capacitySpectrum: CapacitySpectrum): Observable<CapacitySpectrumDTO> {
    const url = `${this.url}/${capacitySpectrum.id}`;
    return this.http.put<CapacitySpectrumDTO>(url, capacitySpectrum).pipe(
      tap(capacity => {
        this.capacitySpectrumStore.update(capacity.capacitySpectrum.id, capacity.capacitySpectrum);
      })
    );
  }
}
