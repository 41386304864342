<form [formGroup]="fundingRequestForm">
  <fieldset [disabled]="isReadOnly">

    <app-input-checkbox
      [choices]="requestedFunding"
      [label]="'What are you requesting funding for?'"
      [name]="'requestedFunding'"
      [id]="'requestedFunding'"
      [isRequired]="true"
      [theme]="theme"
      [style]="style"
      [hasBorder]="false"
      [isGrey]="false"
      [isReadOnly]="isReadOnly"
      (selected)="checkOther('OTHER', $event, fundingRequestForm.controls.requestedFunding, fundingRequestForm.controls.requestedFundingOther)"
      formControlName="requestedFunding"
    ></app-input-checkbox>

    <div *ngIf="fundingRequestForm.value.requestedFunding?.includes('OTHER')" class="ml-5">
      <app-input-textarea
        [isReadOnly]="isReadOnly"
        [label]="'If other, please specify:'"
        [id]="'requestedFundingOther'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="true"
        [hasBorder]="false"
        [isGrey]="false"
        [rows]="'2'"
        [maxLength]="1000"
        [isRequired]="true"
        formControlName="requestedFundingOther"
      ></app-input-textarea>
    </div>

  </fieldset>

  <app-funding-request-budget-total
    [fundingRequest]="fundingRequestForm"
    [activeFundingRequests]="fundingRequestForm.controls.requestedFunding | asFormGroup"
  ></app-funding-request-budget-total>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('TRAINING')"
    [title]="'Training'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.training.invalid"
  >
    <app-training
      boxShape
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
      [trainingRequestForm]="fundingRequestForm.controls.training | asFormGroup"
    ></app-training>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('SUPPORT_GROUPS')"
    [title]="'Support groups'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.supportGroups.invalid"
  >
    <app-support-groups
      boxShape
      [supportGroupsForm]="fundingRequestForm.controls.supportGroups | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-support-groups>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('ADHERENCE_CLUBS')"
    [title]="'Adherence clubs'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.adherenceClubs.invalid"
  >
    <app-adherence-clubs
      boxShape
      [adherenceClubsForm]="fundingRequestForm.controls.adherenceClubs | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-adherence-clubs>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('HIV_TESTING')"
    [title]="'HIV testing and counseling services'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.hivTesting.invalid"
  >
    <app-hiv-testing
      boxShape
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
      [hivTestingForm]="fundingRequestForm.controls.hivTesting | asFormGroup"
    ></app-hiv-testing>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('HOME_BASED_CARE')"
    [title]="'Home based care'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.homeBasedCare.invalid"
  >
    <app-home-based-care
      boxShape
      [homeBasedCareForm]="fundingRequestForm.controls.homeBasedCare | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-home-based-care>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('COUNSELING')"
    [title]="'Counseling'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.counseling.invalid"
  >
    <app-counseling
      boxShape
      [counselingForm]="fundingRequestForm.controls.counseling | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-counseling>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('COMMUNITY_MOBILIZATION')"
    [title]="'Community mobilization and awareness'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.communityMobilization.invalid"
  >
    <app-community-mobilization
      boxShape
      [communityMobilizationForm]="fundingRequestForm.controls.communityMobilization | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-community-mobilization>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('CLINICAL_SERVICES')"
    [title]="'Clinical services'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.clinicalServices.invalid"
  >
    <app-clinical-services
      boxShape
      [clinicalServicesForm]="fundingRequestForm.controls.clinicalServices | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-clinical-services>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('CAMPS')"
    [title]="'Camps (disclosure and / or life skills)'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.camps.invalid"
  >
    <app-camps
      boxShape
      [campsForm]="fundingRequestForm.controls.camps | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-camps>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('TARGETED_PREVENTION')"
    [title]="'Targeted prevention / education programs'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.targetedPrevention.invalid"
  >
    <app-targeted-prevention
      boxShape
      [targetedPreventionForm]="fundingRequestForm.controls.targetedPrevention | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-targeted-prevention>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('SYSTEM_DEVELOPMENT')"
    [title]="'Programs, processes or system development for the organization'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.systemDevelopment.invalid"
  >
    <app-system-development
      boxShape
      [systemDevelopmentForm]="fundingRequestForm.controls.systemDevelopment | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-system-development>
  </app-accordion>

  <app-accordion
    *ngIf="fundingRequestForm.value.requestedFunding.includes('OTHER')"
    [title]="'Other'"
    [boxShape]="true"
    [error]="fundingRequestForm.controls.otherFundingRequest.invalid"
  >
    <app-other-funding-request
      boxShape
      [otherFundingRequestForm]="fundingRequestForm.controls.otherFundingRequest | asFormGroup"
      [isReadOnly]="isReadOnly"
      [fromReview]="fromReview"
    ></app-other-funding-request>
  </app-accordion>

</form>


<ng-template #directions>
  <h4 class="text-center text-shadow">Directions:</h4>
  <p class="alignment-justify">
    You need to complete the following section to detail what you would do with the funding you are requesting and how
    much funding you would need to complete the planned activities. The information you enter in this section should
    match the proposed project description that you wrote above. Please remember, all requested funding must be used to
    provide care and support for PLHIV, targeted HIV testing and counseling services, evidence based prevention programs
    and/or targeted community mobilization.

    <br><br>

    Note: For each of the planned activities you are requesting funding for, you are required to attach quotes and/or
    cost calculations in the supporting documentation section at the end of the application.

    <br><br>

    You will need to provide your budget according to the following categories.
    Here are some examples of the types of items that can be included in each category:
    <br>
     Equipment / furniture – These are products that you invest in and keep, such as containers, gazebos, cooler boxes,
    computers, etc.
    <br>
     Personnel – This is any cost you want to pay to a person to provide a specific service, such as adherence club
    facilitators, community health workers, program directors, etc.
    <br>
     Travel / transport – This is any cost that you want to be used for travel, including public transport, petrol
    costs, etc.
    <br>
    Note: in the supporting documentation section, ensure you provide an attachment which shows how you calculated your
    travel costs.
    <br>
     Supplies / Materials – These are consumables such as workbooks, gloves, venue hire, testing incentives, etc.
    <br>
     Administration – These are operational costs, such as rent, electricity, airtime, etc.
    <br>
    Note: This should not be more than 10% of the total budget.
    <br>
     Training – This is the cost of any type of training.
  </p>
</ng-template>
