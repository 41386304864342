import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum MneEndpointType {
  PLAN = 'PLAN',
  PLAN_ELEMENT = 'PLAN_ELEMENT',
  MONITOR = 'MONITOR',
  PROGRAM_REPORTING = 'PROGRAM_REPORTING',
  USAGE_OF_REPORTS = 'USAGE_OF_REPORTS',
  PROGRAM_BUDGET = 'PROGRAM_BUDGET',
  BUDGET_CHECK_FREQUENCY = 'BUDGET_CHECK_FREQUENCY',
  TRAINED_MNE = 'TRAINED_MNE',
  FEEDBACK_PROCEDURE = 'FEEDBACK_PROCEDURE',
  STRATEGIC_PLAN = 'STRATEGIC_PLAN',
  LINKED_STRATEGIC_PLAN = 'LINKED_STRATEGIC_PLAN'
}

export interface CSMNE extends BaseEntity {
  plan: string;
  planElements: string[];
  planElementsOther: string; // if planElements contains 'OTHER'
  monitor: string;
  programReporting: string;
  usageOfReports: string;
  programBudget: string;
  budgetCheckFrequency: string; // if programBudget === 'YES'
  hasTrainedMne: string;
  feedbackProcedure: string;
  strategicPlan: string;
  linkedStrategicPlan: string;
}

export function createCSMNE(params: Partial<CSMNE>) {
  return Object.assign({}, createBaseEntity(params), {
    plan: params && params.plan,
    planElements: params && params.planElements,
    planElementsOther: params && params.planElementsOther,
    monitor: params && params.monitor,
    programReporting: params && params.programReporting,
    usageOfReports: params && params.usageOfReports,
    programBudget: params && params.programBudget,
    budgetCheckFrequency: params && params.budgetCheckFrequency,
    hasTrainedMne: params && params.hasTrainedMne,
    feedbackProcedure: params && params.feedbackProcedure,
    strategicPlan: params && params.strategicPlan,
    linkedStrategicPlan: params && params.linkedStrategicPlan,
  }) as CSMNE;
}

export function createCSMNEForm(destroy$: Subject<boolean>, formBuilder: FormBuilder, params: Partial<CSMNE>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    plan: [params && params.plan, Validators.required],
    planElements: [params && params.planElements, Validators.required],
    planElementsOther: [params && params.planElementsOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('planElements').value && formGroup.get('planElements').value.includes('OTHER');
      })
    ],
    monitor: [params && params.monitor, Validators.required],
    programReporting: [params && params.programReporting, Validators.required],
    usageOfReports: [params && params.usageOfReports, Validators.required],
    programBudget: [params && params.programBudget, Validators.required],
    budgetCheckFrequency: [params && params.budgetCheckFrequency,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('programBudget').value && formGroup.get('programBudget').value.includes('YES');
      })
    ],
    hasTrainedMne: [params && params.hasTrainedMne, Validators.required],
    feedbackProcedure: [params && params.feedbackProcedure, Validators.required],
    strategicPlan: [params && params.strategicPlan, Validators.required],
    linkedStrategicPlan: [params && params.linkedStrategicPlan, Validators.required],
  }));

  formGroup.get('planElements').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('planElementsOther').updateValueAndValidity();
    });

  formGroup.get('programBudget').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('budgetCheckFrequency').updateValueAndValidity();
    });

  return formGroup;
}
