import {SingleScoreDTO} from '../../modules/organisation/capacity-spectrum/components/capacity-spectrum/store/capacity-spectrum.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScorePercentageUtil {
  scoreDTOAsPercentageString(scoreDTO: SingleScoreDTO): string {
    if (scoreDTO) {
      return Math.round((scoreDTO.score * 100 / scoreDTO.max) || 0).toString();
    }
    return '0';
  }
  scoreDTOAsPercentage(scoreDTO: SingleScoreDTO): number {
    if (scoreDTO) {
      return Math.round((scoreDTO.score * 100 / scoreDTO.max) || 0);
    }
    return 0;
  }
}

