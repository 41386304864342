<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="governanceForm">
    <app-input-select-single
      *ngIf="managementStructureAnswers && managementStructureAnswers.length"
      [label]="'Which statement best describes the organisation\'s management structure? '"
      [items]="managementStructureAnswers"
      [id]="'managementStructure'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="managementStructure"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="decisionMakingAnswers?.length"
      [label]="'How are staff involved in the organisation\'s decision making? (check all that apply)'"
      [choices]="decisionMakingAnswers"
      [id]="'decisionMakingList'"
      [name]="'decisionMakingList'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther('OTHER', $event, governanceForm.controls.decisionMakingList, governanceForm.controls.decisionMakingListOther)"
      formControlName="decisionMakingList"
    ></app-input-checkbox>

    <div *ngIf="governanceForm?.value?.decisionMakingList?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'decisionMakingListOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="decisionMakingListOther"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="successionPlanningAnswers?.length"
      [label]="'Which statement best describes the organisation\'s succession planning for key leadership positions?'"
      [items]="successionPlanningAnswers"
      [id]="'successionPlanning'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="successionPlanning"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="founderRelationshipAnswers?.length"
      [label]="'Which statement best describes the organisation’s relationship with the founder? ' +
       'If the organisation’s founder is no longer with the organisation, answer this question about ' +
        'the organisation’s relationship with the executive director.'"
      [items]="founderRelationshipAnswers"
      [id]="'founderRelationship'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="founderRelationship"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="directorsAnswers?.length"
      [label]="'Which statement best describes the organisation’s board of directors?'"
      [items]="directorsAnswers"
      [id]="'directors'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="directors"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="policyElementAnswers?.length"
      [label]="'Which of the following elements are included in the organization\'s board charter or policy? (check all that apply)'"
      [choices]="policyElementAnswers"
      [id]="'policyElements'"
      [name]="'policyElements'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther('OTHER', $event, governanceForm.controls.policyElements, governanceForm.controls.policyElementsOther)"
      formControlName="policyElements"
    ></app-input-checkbox>

    <div *ngIf="governanceForm?.value?.policyElements?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'policyElementsOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="policyElementsOther"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="boardMemberProcessAnswers?.length"
      [label]="'Which statement best describes the process of becoming a board member?'"
      [items]="boardMemberProcessAnswers"
      [id]="'boardMemberProcess'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="boardMemberProcess"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="boardMemberTermAnswers?.length"
      [label]="'Are there term limits for board members?'"
      [items]="boardMemberTermAnswers"
      [id]="'boardMemberTerm'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="boardMemberTerm"
    ></app-input-select-single>

    <app-input-select-single
      [label]="'Are board members paid to serve on the board of directors?'"
      [items]="booleanChoices"
      [id]="'areMembersPaidToServe'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="areMembersPaidToServe"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="meetingMinutesAnswers?.length"
      [label]="'Are there minutes taken and archived for all board meetings?'"
      [items]="meetingMinutesAnswers"
      [id]="'meetingMinutes'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="meetingMinutes"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="reportFrequencyAnswers?.length"
      [label]="'How often are organisational reports submitted to the board for review? '"
      [items]="reportFrequencyAnswers"
      [id]="'reportFrequency'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="reportFrequency"
    ></app-input-select-single>
  </form>
</app-spinner>
