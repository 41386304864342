import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsNetworkAndLinkagesAnswerService} from './services/cs-network-and-linkages-answer.service';
import {take} from 'rxjs/operators';
import {booleanChoices} from '../../../../../../constants/boolean-answers.const';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-network-and-linkages',
  templateUrl: './cs-network-and-linkages.component.html',
  styleUrls: ['./cs-network-and-linkages.component.scss']
})
export class CsNetworkAndLinkagesComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() networkAndLinkagesForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  // Question Answers.
  referralAndLinkageAnswers = [];
  membersInvolvementAnswers = [];
  communityLinkageAnswers = [];
  communityForumFrequencyAnswers = [];
  communityProfileAnswers = [];
  communityEngagementAnswers = [];
  organisationRelationshipAnswers = [];
  governmentRelationshipAnswers = [];

  booleanChoices = booleanChoices;

  referralAndLinkageSubscription: Subscription;
  membersInvolvementSubscription: Subscription;
  communityLinkageSubscription: Subscription;
  communityForumFrequencySubscription: Subscription;
  communityProfileSubscription: Subscription;
  communityEngagementSubscription: Subscription;
  organisationRelationshipSubscription: Subscription;
  governmentRelationshipSubscription: Subscription;
  childFormTouched: boolean;


  constructor(private loadingService: LoadingService,
              private csNetworkAndLinkagesAnswerService: CsNetworkAndLinkagesAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.networkAndLinkagesForm.touched;

    this.populateAnswers();
  }

  public checkOther(value: string, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy(): void {
    if (this.referralAndLinkageSubscription) {
      this.referralAndLinkageSubscription.unsubscribe();
    }

    if (this.membersInvolvementSubscription) {
      this.membersInvolvementSubscription.unsubscribe();
    }

    if (this.communityLinkageSubscription) {
      this.communityLinkageSubscription.unsubscribe();
    }

    if (this.communityForumFrequencySubscription) {
      this.communityForumFrequencySubscription.unsubscribe();
    }

    if (this.communityProfileSubscription) {
      this.communityProfileSubscription.unsubscribe();
    }

    if (this.communityEngagementSubscription) {
      this.communityEngagementSubscription.unsubscribe();
    }

    if (this.organisationRelationshipSubscription) {
      this.organisationRelationshipSubscription.unsubscribe();
    }

    if (this.governmentRelationshipSubscription) {
      this.governmentRelationshipSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csNetworkAndLinkagesAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.referralAndLinkageSubscription = this.csNetworkAndLinkagesAnswerService.$referralAndLinkage.subscribe(answers => {
      if (answers) {
        this.referralAndLinkageAnswers = answers.choices;
      }
    });

    this.membersInvolvementSubscription = this.csNetworkAndLinkagesAnswerService.$membersInvolvement.subscribe(answers => {
      if (answers) {
        this.membersInvolvementAnswers = answers.choices;
      }
    });

    this.communityLinkageSubscription = this.csNetworkAndLinkagesAnswerService.$communityLinkage.subscribe(answers => {
      if (answers) {
        this.communityLinkageAnswers = answers.choices;
      }
    });

    this.communityForumFrequencySubscription = this.csNetworkAndLinkagesAnswerService.$communityForumFrequency.subscribe(answers => {
      if (answers) {
        this.communityForumFrequencyAnswers = answers.choices;
      }
    });

    this.communityProfileSubscription = this.csNetworkAndLinkagesAnswerService.$communityProfile.subscribe(answers => {
      if (answers) {
        this.communityProfileAnswers = answers.choices;
      }
    });

    this.communityEngagementSubscription = this.csNetworkAndLinkagesAnswerService.$communityEngagement.subscribe(answers => {
      if (answers) {
        this.communityEngagementAnswers = answers.choices;
      }
    });

    this.organisationRelationshipSubscription = this.csNetworkAndLinkagesAnswerService.$organisationRelationship.subscribe(answers => {
      if (answers) {
        this.organisationRelationshipAnswers = answers.choices;
      }
    });

    this.governmentRelationshipSubscription = this.csNetworkAndLinkagesAnswerService.$governmentRelationship.subscribe(answers => {
      if (answers) {
        this.governmentRelationshipAnswers = answers.choices;
      }
    });
  }
}
