<div class="wrapper esx-page">

  <div class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Service Matrix
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto esx-content">
    <div class="h-100 mt-2">

      <div class="profile-actions float-right">
        <button
          class="btn btn-sm btn-primary btn-outline-dark profile-btn"
          [routerLink]="'/service-matrix/edit/' + organisationId"
        >
          Update
        </button>
      </div>

      <nav class="profile-tabs">
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'targetPopulations'"
          (click)="changeTab('targetPopulations')"
        >
          Target Populations
        </button>
        |
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'impactAreas'"
          (click)="changeTab('impactAreas')"
        >
          Impact Areas
        </button>
        |
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'activities'"
          (click)="changeTab('activities')"
        >
          Activities
        </button>
      </nav>

      <app-alert class="w-100" [id]="'service-matrix'"></app-alert>
      <app-spinner [loadingObs]="isLoading">

        <div class="wrapper">
          <div class="w-100 mx-auto">
<!--            [class.mt-6]="fromReview" -->
            <div class="h-100" *ngIf="serviceMatrix">
              <div class="card-body">

                <ng-container [ngSwitch]="currentTab">

                  <ng-container *ngSwitchCase="'targetPopulations'">
                    <h4 class="text-center">Target Populations</h4>
                    <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="flex-start flex-align-start flex-column">

                          <app-input-checkbox-disabled
                            *ngIf="serviceMatrix && targetPopulationsTypeItems"
                            class="w-100"
                            [choices]="targetPopulationsTypeItems"
                            [cols]="1"
                            [id]="'targetPopulations'"
                            [style]="'default'"
                            [theme]="'light'"
                            [isGrey]="false"
                            [hasBorder]="false"
                            [values]="serviceMatrix.targetPopulations"
                          ></app-input-checkbox-disabled>

                        </div>
                      </div>
                    </div>
                  </ng-container>


                  <ng-container *ngSwitchCase="'impactAreas'">
                    <h4 class="text-center">Impact Areas</h4>
                    <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="flex-start flex-align-start flex-column">

                          <app-input-checkbox-disabled
                            *ngIf="serviceMatrix && impactAreaSubCategoryList"
                            class="w-100"
                            [choices]="impactAreaSubCategoryList"
                            [cols]="1"
                            [id]="'targetPopulations'"
                            [style]="'default'"
                            [theme]="'light'"
                            [isGrey]="false"
                            [hasBorder]="false"
                            [values]="serviceMatrix.impactAreaSubCategories"
                          ></app-input-checkbox-disabled>

                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="'activities'">
                    <h4 class="text-center">Activities</h4>
                    <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="flex-align-start flex-column">

                          <div class="row">
                            <div class="col-6 text-center m-auto">
                              <label>Activity</label>
                            </div>
                            <div class="col-6 text-center m-auto">
                              <label>Links to Impact Area</label>
                            </div>
                          </div>

                          <div *ngIf="serviceMatrix?.activities" class="card-body">
                            <ng-container
                              *ngFor="
                            let activity of serviceMatrix.activities;
                            let lix = index
                          "
                            >
                              <div class="row">
                                <div class="col-6">
                                  <app-input-text-disabled
                                    class="w-95"
                                    [id]="'activity' + lix"
                                    [style]="'default'"
                                    [theme]="'light'"
                                    [hasBorder]="false"
                                    [isGrey]="false"
                                    [value]="activity.activity"
                                  ></app-input-text-disabled>
                                </div>

                                <div class="col-6">
                                  <app-input-select-disabled
                                    *ngIf="impactAreaSubCategoryList?.length"
                                    [id]="'activityImpactAreaSubCategory' + lix"
                                    [theme]="'light'"
                                    [style]="'bordered'"
                                    [hasBorder]="false"
                                    [isGrey]="false"
                                    [value]="activity.impactAreaSubCategory | singleChoiceInputType : impactAreaSubCategoryList"
                                  ></app-input-select-disabled>
                                </div>
                              </div>
                            </ng-container>
                          </div>

                        </div>
                      </div>
                    </div>
                  </ng-container>


                </ng-container>
              </div>
            </div>
          </div>
        </div>

      </app-spinner>
    </div>
  </div>
</div>
