<ng-container *ngIf="!isReadOnly; else disabledInput">
  <app-form-control-radio
    [label]="label"
    [name]="name"
    [id]="id"
    [choices]="choices"
    [formSubmitted]="formSubmitted"
    [showLabel]="showLabel"
    [showErrors]="showErrors"
    [showOptional]="showOptional"
    [isRequired]="isRequired"
    [theme]="theme"
    [style]="style"
    [helpLink]="helpLink"
    [cols]="cols"
    (selectedItem)="value"
    (onHelpClick)="onHelpClick.emit()"
    [(ngModel)]="value"
  ></app-form-control-radio>
</ng-container>

<ng-template #disabledInput>
  <app-input-radio-disabled
    [label]="label"
    [theme]="theme"
    [style]="style"
    [isGrey]="isGrey"
    [hasBorder]="hasBorder"
    [value]="value"
    [choices]="choices"
  ></app-input-radio-disabled>
</ng-template>
