<div class="row position-relative mb-3">
  <label
    *ngIf="showLabel"
    class="col-9"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
  >
    {{label}}
  </label>

<!--  <fieldset>-->
    <!-- https://codepen.io/oliviale/pen/xxboXzo -->
    <!-- https://freefrontend.com/css-toggle-switches/ -->
    <div class="col-3 toggle widget flex-start">
      <input [id]="id" class="widget-checkbox" type="checkbox" [name]="name" [(ngModel)]="value"/>
      <label class="toggle-item" [for]="id">
        <span class="check">
          <ng-container *ngIf="value === true">
            <i class="fas fa-check text-light"></i>
          </ng-container>
          <ng-container *ngIf="value !== true">
            <i class="fas fa-times text-light"></i>
          </ng-container>
        </span>
      </label>
    </div>
<!--  </fieldset>-->
</div>
