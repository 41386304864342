<button
  type="button"
  class="btn-sm btn-info float-right"
  (click)="openAssignCoordinatorModal()"
>
  <i class="fas fa-plus"></i>
</button>

<ng-template #modalContent>
  <div>
    <div class="w-100 card p-4">
      <button class="close-modal" type="button" (click)="closeModal()">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="card-header text-center">
        <h5>Assign Coordinator</h5>
      </div>
      <div class="w-100 flex-center" *ngIf="showError">
        <div class="w-25 error-box flex-center">
          <button class="toast-close-button" (click)="showError = false">
            <i class="far fa-times-circle text-light"></i>
          </button>
          {{errorText}}
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="w-95 mx-auto">
          <app-input-select-single
            [items]="coordinators"
            [(ngModel)]="coordinator"
            [showOptional]="false"
            [disabled]="loadingCoordinators"
            [clearValueOnItemsChange]="false"
          ></app-input-select-single>
        </div>
        <span *ngIf="loadingCoordinators" class="ml-3 mt-4">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">
          Cancel
        </button>
        <button class="btn btn-outline-primary" type="button" (click)="addAssignedCoordinators()">
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
