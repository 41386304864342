<div class="mbl-chart">
  <ngx-charts-advanced-pie-chart
      #pieChart
      [scheme]="colorScheme"
      [results]="chartData"
      [view]="view"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
  ></ngx-charts-advanced-pie-chart>
</div>
