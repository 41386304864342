<form [formGroup]="siteVisitForm">
  <app-input-text
    [label]="'Name of person(s) present:'"
    [id]="'personPresent'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [isGrey]="false"
    [hasBorder]="false"
    formControlName="personPresent"
  ></app-input-text>

  <app-input-date
    [label]="'Date of visit'"
    [id]="'dateOfVisit'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [isGrey]="false"
    [hasBorder]="false"
    formControlName="dateOfVisit"
  ></app-input-date>

  <app-input-select-single
    [label]="'Conducted by:'"
    [items]="coordinatorList"
    [id]="'coordinator'"
    [theme]="theme"
    [style]="style"
    [isRequired]="true"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [isGrey]="false"
    [hasBorder]="false"
    formControlName="coordinator"
  ></app-input-select-single>

  <app-widget-check-two
    [name]="'hasGpsCoordinates'"
    [label]="'GPS co-ordinates:'"
    [isRequired]="true"
    [id]="'hasGpsCoordinates'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    [isReadOnly]="isReadOnly"
    [showDisabledValue]="true"
    [isGrey]="false"
    [hasBorder]="false"
    formControlName="hasGpsCoordinates"
  ></app-widget-check-two>

  <div class="review-details-item"
       (click)="dropdownStates.organisationStructure = !dropdownStates.organisationStructure">
    <div class="flex-between">
      <p class="font-weight-normal">Organisation Structure</p>
      <small>Click to expand</small>
    </div>
  </div>
  <div class="side-panel-item-details" *ngIf="dropdownStates.organisationStructure">
    <div class="p-4">
      <app-site-visit-org-structure
        [siteVisitOrgStructureForm]="siteVisitForm.controls.organizationStructure | asFormGroup"
        [isReadOnly]="isReadOnly"
      ></app-site-visit-org-structure>
    </div>
  </div>

  <div class="review-details-item" (click)="dropdownStates.serviceDelivery = !dropdownStates.serviceDelivery ">
    <div class="flex-between">
      <p class="font-weight-normal">Service Delivery</p>
      <small>Click to expand</small>
    </div>
  </div>
  <div class="side-panel-item-details" *ngIf="dropdownStates.serviceDelivery ">
    <div class="p-4">
      <app-site-visit-service-delivery
        [siteVisitServiceDeliveryForm]="siteVisitForm.controls.serviceDelivery | asFormGroup"
        [isReadOnly]="isReadOnly"
      ></app-site-visit-service-delivery>
    </div>
  </div>

  <div class="review-details-item"
       (click)="dropdownStates.communityRelationships = !dropdownStates.communityRelationships">
    <div class="flex-between">
      <p class="font-weight-normal">Community Relationships</p>
      <small>Click to expand</small>
    </div>
  </div>
  <div class="side-panel-item-details" *ngIf="dropdownStates.communityRelationships ">
    <div class="p-4">
      <app-site-visit-community-relationships
        [siteVisitCommunityRelationshipsForm]="siteVisitForm.controls.communityRelationships | asFormGroup"
        [isReadOnly]="isReadOnly"
      ></app-site-visit-community-relationships>
    </div>
  </div>

  <div class="review-details-item" (click)="dropdownStates.financial = !dropdownStates.financial">
    <div class="flex-between">
      <p class="font-weight-normal">Financial</p>
      <small>Click to expand</small>
    </div>
  </div>
  <div class="side-panel-item-details" *ngIf="dropdownStates.financial">
    <div class="p-4">
      <app-site-visit-financial
        [siteVisitFinancialForm]="siteVisitForm.controls.financial | asFormGroup"
        [isReadOnly]="isReadOnly"
      ></app-site-visit-financial>
    </div>
  </div>

  <div class="review-details-item" (click)="dropdownStates.recentChanges = !dropdownStates.recentChanges">
    <div class="flex-between">
      <p class="font-weight-normal">Recent Changes</p>
      <small>Click to expand</small>
    </div>
  </div>
  <div class="side-panel-item-details" *ngIf="dropdownStates.recentChanges">
    <div class="p-4">
      <app-site-visit-recent-changes
        [siteVisitRecentChangesForm]="siteVisitForm.controls.recentChanges | asFormGroup"
        [isReadOnly]="isReadOnly"
      ></app-site-visit-recent-changes>
    </div>
  </div>

  <app-upload-document
    [label]="'Site notes'"
    [documents]="siteVisitForm.value.siteNotes"
    [additionalParameters]="additionalParameters"
    [isReadOnly]="isReadOnly"
  ></app-upload-document>
</form>
