<div class="h-100">
  <div class="card-body">

    <app-spinner [loadingBool]="loading">
      <div *ngIf="totals" class="charts-dash">
        <div class="disaggregation-chart">
          <div class="card w-100 h-100 border">
            <div class="card-body">
              <app-pie-chart
                *ngIf="chartData && chartData[0].value >= 0"
                type="disaggregation"
                [chartData]="chartData"
                [view]="[1000, 150]"
                [filterType]="filterType"
                (selected)="changeFilter($event)"
              ></app-pie-chart>
            </div>
          </div>
        </div>

        <div class="work-plan-submitted-chart">
          <div class="card w-100 h-100 border">
            <div class="card-body">
              <app-pie-chart
                *ngIf="workPlanSubmittedChartData && workPlanSubmittedChartData[0].value >= 0"
                type="workPlanSubmitted"
                [chartData]="workPlanSubmittedChartData"
                [view]="[300, 150]"
                [filterType]="filterType"
                (selected)="changeFilter($event)"
              ></app-pie-chart>
            </div>
          </div>
        </div>

        <div class="work-plan-approved-chart">
          <div class="card w-100 h-100 border">
            <div class="card-body">
              <app-pie-chart
                *ngIf="workPlanApprovedChartData && workPlanApprovedChartData[0].value >= 0"
                type="workPlanApproved"
                [chartData]="workPlanApprovedChartData"
                [view]="[600, 150]"
                [filterType]="filterType"
                (selected)="changeFilter($event)"
              ></app-pie-chart>
            </div>
          </div>
        </div>


        <div class="cards-chart">
          <div class="card w-100 h-100 border">
            <div class="card-body">

              <div class="threshold-input-range mb-5">
                <app-cards-chart
                  [data]="totalFunding"
                  [view]="[270, 300]"
                ></app-cards-chart>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div *ngIf="masterList" class="review-table">

        <div class="w-100 mt-1 mb-1 text-center">

          <ng-container *authenticatedWithRole="['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER']">

            <button
              class="btn btn-dark btn-sm"
              (click)="goShortlistDashboard()"
            >
              Shortlist Dashboard
            </button>

            <button
              class="btn btn-outline-primary btn-sm ml-3"
              (click)="navReports()"
            >
              Reports
            </button>

            <button
              class="btn btn-outline-warning btn-sm ml-3"
              (click)="openRegretMailModal()"
            >
              Send Regret Mail
            </button>

          </ng-container>

          <button
            class="btn btn-outline-secondary btn-sm ml-3"
            (click)="clearFilter()"
          >
            Clear Filter
          </button>

        </div>

        <app-alert
          class="w-100"
          [id]="'reviewDash'"
        ></app-alert>

        <app-review-user-table
          [windowId]="windowId"
          [filterType]="filterType"
          [masterList]="masterList"
          (applicationFunding)="updateApplicationFunding($event)"
          (projectedFunding)="updateProjectedFunding($event)"
          [(clear)]="clearFilterTrigger"
        ></app-review-user-table>

      </div>
    </app-spinner>
  </div>
</div>

<ng-template #bulkEmailTemplate>
  <app-email [mail]="regretMail"></app-email>
</ng-template>
