import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionQuery} from '../../../../stores/session/session.query';
import {User} from '../../../../stores/user/user.model';
import {SessionService} from '../../../../stores/session/session.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-applicant-sidebar',
  templateUrl: './applicant-sidebar.component.html',
  styleUrls: ['./applicant-sidebar.component.scss']
})
export class ApplicantSidebarComponent implements OnInit, OnDestroy {
  activeId = 1;
  user: User;
  userSubscription: Subscription;

  constructor(private sessionQuery: SessionQuery,
              private sessionService: SessionService,
              private router: Router) { }

  ngOnInit(): void {
    this.userSubscription = this.sessionQuery.getUserObservable().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  routeToCapacitySpectrum() {
    if (this.user.isCapacitySpectrumSubmitted) {
      this.router.navigateByUrl(`capacity-spectrum/results`).then();
    } else {
      this.sessionService.routeHome();
    }
  }

  routeToServiceMatrix() {
    if (this.user.serviceMatrixId) {
      this.router.navigateByUrl(`service-matrix/results`).then();
    } else {
      this.sessionService.routeHome();
    }
  }
}
