import {Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {Review, ReviewDashboardTotals} from '../store/review-dashboard.model';
import {ReviewDashboardService} from '../store/review-dashboard.service';
import {ModalService} from 'app/shared/modules/shared-common/services/modal/modal.service';
import {Mail} from 'app/modules/email/store/email.model';
import {EmailService} from 'app/modules/email/store/email.service';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../../../shared/modules/alert/services/alert.service';
import {ChartData, FilterType} from '../../../../../../shared/modules/charts/models/charts.model';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  @ViewChild('bulkEmailTemplate', {static: true}) bulkEmailTemplate: TemplateRef<HTMLElement>;

  windowId: number;
  loading: boolean;
  totalApplicationFunding: number;
  totalProjectedFunding: number;
  filterType: FilterType = 'shortlisted';
  masterList: Array<Review>;
  totals: ReviewDashboardTotals;
  regretMail: Mail;
  options: NgbModalOptions;
  clearFilterTrigger: boolean;

  chartData: ChartData<number>[];
  workPlanSubmittedChartData: ChartData<number>[];
  workPlanApprovedChartData: ChartData<number>[];

  totalFundingData = [
    {name: 'Total Application Funding', value: 0},
    {name: 'Projected Application Funding', value: 0},
  ];
  totalFundingSrc = new BehaviorSubject<ChartData<number>[]>(this.totalFundingData);
  totalFunding = this.totalFundingSrc.asObservable();

  constructor(public route: ActivatedRoute,
              public router: Router,
              private alertService: AlertService,
              private reviewDashboardService: ReviewDashboardService,
              private modalService: ModalService,
              private mailService: EmailService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.windowId = this.route.snapshot.params.windowId;

    this.fetchList();

    this.mailService.regretTemplate(this.windowId).subscribe(template => {
      this.regretMail = template;
    });
    this.options = {
      ariaLabelledBy: 'Send Email',
      size: 'lg',
      centered: true
    };
  }

  updateApplicationFunding(amount: number) {
    this.totalApplicationFunding = amount;
    this.totalFundingData = this.totalFundingSrc.getValue();
    this.totalFundingData[0].value = amount;
    this.totalFundingSrc.next(this.totalFundingData);
  }

  updateProjectedFunding(amount: number) {
    this.totalProjectedFunding = amount;
    this.totalFundingData = this.totalFundingSrc.getValue();
    this.totalFundingData[1].value = amount;
    this.totalFundingSrc.next(this.totalFundingData);
  }

  handleLoading(isLoading: boolean) {
    this.loading = isLoading;
    this.ref.detectChanges();
  }

  changeFilter(filter: FilterType) {
    this.filterType = filter;
  }

  goShortlistDashboard() {
    this.router.navigate([`../shortlist`], {relativeTo: this.route}).then();
  }

  navReports() {

  }

  openRegretMailModal() {
    this.modalService.openBasicModal(this.bulkEmailTemplate, this.options);
  }

  clearFilter() {
    // Each time the value of clearFilter changes,
    // it will trigger a `set` function in the
    // review manager table component on the @Input()
    this.clearFilterTrigger = true;
    this.changeFilter('shortlisted');
  }

  private fetchList() {
    this.handleLoading(true);

    this.reviewDashboardService.getReviewList(this.windowId).subscribe((reviewList: Review[]) => {
      this.masterList = reviewList;
      this.generateDashboardTotals(this.masterList);

      this.handleLoading(false);
    }, () => {
      this.alertService.setAlert('Unable to fetch Review list data', 'error', 'reviewUserTable');
      this.handleLoading(false);
    });
  }

  private generateDashboardTotals(list: Array<Review>) {
    let inReview = 0;
    let declined = 0;
    let assessmentSubmitted = 0;
    let recommended = 0;
    let workPlanSubmitted = 0;
    let workPlanApproved = 0;

    list.forEach(item => {
      if (!item.reviewStep || item.reviewStep === 'IN_REVIEW') {
        inReview += 1;
      }
      if (item.reviewStep === 'DECLINED') {
        declined += 1;
      }
      if (item.reviewStep === 'ASSESSMENT_SUBMITTED') {
        assessmentSubmitted += 1;
      }
      if (item.reviewStep === 'RECOMMENDED') {
        recommended += 1;
      }
      if (item.workPlanStatus === 'SUBMITTED') {
        workPlanSubmitted += 1;
      }
      if (item.workPlanStatus === 'APPROVED') {
        workPlanApproved += 1;
      }
    });

    this.totals = {
      inReview,
      declined,
      assessmentSubmitted,
      recommended,
      workPlanSubmitted,
      workPlanApproved
    };
    this.setChartData(this.totals);
  }

  private setChartData(totals: ReviewDashboardTotals) {
    this.chartData = [
      {name: 'In Review', value: totals.inReview || 0},
      {name: 'Assessment Submitted', value: totals.assessmentSubmitted || 0},
      {name: 'Final Recommendation', value: totals.recommended || 0},
      {name: 'Declined', value: totals.declined || 0},
    ];

    this.workPlanSubmittedChartData = [
      {name: 'Submitted', value: totals.workPlanSubmitted || 0},
      {name: 'Not Submitted', value: (totals.inReview - totals.workPlanSubmitted) || 0},
    ];

    this.workPlanApprovedChartData = [
      {name: 'Approved', value: totals.workPlanApproved || 0},
      {name: 'Not Approved', value: (totals.workPlanSubmitted - totals.workPlanApproved) || 0},
    ];
  }
}
