<button type="button" class="btn btn-lg btn-outline-primary" (click)="openSubscribeModal()">
  Subscribe to the SoCapX Newsletter here
</button>

<ng-template #modalContent>
  <div>
    <div class="w-100 card card-login bg-dark p-4 opacity-8">
      <button class="close-modal" type="button" (click)="closeModal()">
        <i class="far fa-times-circle"></i>
      </button>
      <form class="form" [formGroup]="profileForm" (ngSubmit)="createSubscription()">
        <div class="w-95 mx-auto">
          <h4 class="category text-primary text-center">
            Subscribe to news letter
          </h4>
          <hr class="dropdown-divider border-primary w-50 mx-auto mb-4">
          <app-alert
            class="w-100"
            [id]="'subscribe'"
          ></app-alert>
          <app-input-text
            [label]="'First Name'"
            [id]="'firstName'"
            [style]="style"
            [theme]="theme"
            [isRequired]="true"
            formControlName="firstName"
          ></app-input-text>

          <app-input-text
            [label]="'Last Name'"
            [id]="'lastName'"
            [style]="style"
            [theme]="theme"
            [isRequired]="true"
            formControlName="lastName"
          ></app-input-text>

          <app-input-text
            [label]="'Email'"
            [id]="'email'"
            [style]="style"
            [theme]="theme"
            [isRequired]="true"
            formControlName="email"
          ></app-input-text>
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">Cancel</button>
          <button class="btn btn-outline-primary" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>

</ng-template>
