import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {ModalService} from '../../../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {Objective} from '../../store/objective.model';
import {ActivityService} from '../store/activity.service';
import {createActivityForm} from '../store/activity.model';
import {budgetCategoryAnswers, paymentEvidenceAnswers} from '../activity.const';
import {FormValidationService} from '../../../../../../../../../services/form-validation/form-validation.service';
import {AlertService} from '../../../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() objective: Objective;
  @Input() isReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;
  @Input() applicantReadOnly: boolean;

  options: NgbModalOptions;

  profileForm: FormGroup;

  paymentEvidenceOptions = _.cloneDeep(paymentEvidenceAnswers);
  budgetCategoryOptions = _.cloneDeep(budgetCategoryAnswers);

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private activityService: ActivityService,
              private alertService: AlertService,
              public formValidationService: FormValidationService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Add Activity',
      centered: true,
      size: 'lg',
      scrollable: true
    };

    this.profileForm = createActivityForm(this.formBuilder, {
      objective: this.objective
    });
  }

  openActivityModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  createActivity() {
    if (this.profileForm.valid) {
      this.activityService.create(this.profileForm.value).subscribe(() => {
        this.profileForm.reset();
        this.closeModal();
      }, error => {
        this.alertService.setAlert('Create Activity Failed', 'error', 'addActivity');
      });
    } else {
      this.alertService.setAlert('Please complete all fields before saving.', 'error', 'addActivity');
    }
  }
}
