<div *ngIf="formTemplate" class="wrapper esx-page">

  <div class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Preview: {{formTemplate.name}}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto esx-content">
    <div class="h-100 mt-2">

      <app-spinner [loadingBool]="loading">

        <div class="card-body" [class.bg-dark]="isThemeDark()">

          <app-dynamic-form *ngIf="formTemplate" [formTemplate]="formTemplate"></app-dynamic-form>

        </div>
      </app-spinner>
    </div>
  </div>
</div>
