import {ChoiceInputItem} from '../../shared/modules/form-elements/models/form-elements.model';

export const booleanChoices: ChoiceInputItem[] = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  },
];
