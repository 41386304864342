<div class="wrapper esx-page">

  <div class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                History of Application Form
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto esx-content">
    <div class="h-100 mt-2">

      <app-spinner [loadingBool]="loading">

        <div class="card-body">
          <div class="row">
            <div class="col-12 tables">
              <app-mbl-table
                #table
                [columns]="columns"
                [rows]="rows"
              ></app-mbl-table>
            </div>
          </div>
        </div>
      </app-spinner>
    </div>
  </div>
</div>

<ng-template #funderTmpl let-row="row" let-value="value">
  <ng-container *ngIf="row.funder">
    <span>{{row.funder | funderName}}</span>
  </ng-container>
  <ng-container *ngIf="!row.funder">
    <span>SoCapX</span>
  </ng-container>
</ng-template>

<ng-template #dateChangedTmpl let-row="row" let-value="value">
  {{row.modifiedOn | date: 'short'}}
</ng-template>
