import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../../../shared/models/base-entity.model';

export interface CSFinancialSituationFunder extends BaseEntity {
  name: string;
  period: string;
  amount: number;
  contactName: string;
  contactEmail: string;
}

export function createCSFinancialSituationFunder(params: Partial<CSFinancialSituationFunder>) {
  return Object.assign({}, createBaseEntity(params), {
    name: params && params.name,
    period: params && params.period,
    amount: params && params.amount,
    contactName: params && params.contactName,
    contactEmail: params && params.contactEmail,
  }) as CSFinancialSituationFunder;
}

export function createCSFinancialSituationFunderListForm(
  formBuilder: FormBuilder,
  params: Partial<CSFinancialSituationFunder[]>
): FormArray {
  const list = new FormArray([]);
  if (params) {
    params.forEach(funder => {
      list.push(createCSFinancialSituationFunderForm(formBuilder, funder));
    });
  }
  return list;
}

export function createCSFinancialSituationFunderForm(formBuilder: FormBuilder, params: Partial<CSFinancialSituationFunder>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params && params.name, Validators.required],
    period: [params && params.period, Validators.required],
    amount: [params && params.amount, Validators.required],
    contactName: [params && params.contactName, Validators.required],
    contactEmail: [params && params.contactEmail, Validators.required],
  }));
}
