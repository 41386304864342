import {style, transition, animate, state, trigger} from '@angular/animations';

export const animateExpansionPanel =
  trigger('animateExpansionPanel', [
    state('initial', style({
      maxHeight: '0',
      opacity: 0
    })),
    state('open', style({
      maxHeight: '126rem',
      opacity: 1,
      transition: '400ms 300ms ease-in'
    })),
    state('closed', style({
      maxHeight: '0',
      opacity: 0,
      transition: '400ms 300ms ease-in'
    })),
    transition('*=>open', animate('250ms')),
    transition('*=>closed', animate('250ms'))
  ]);
