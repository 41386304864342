import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {ValidationService} from '../../../services/validation.service';

@Component({
  selector: 'app-form-control-textarea',
  templateUrl: './form-control-textarea.component.html',
  styleUrls: ['./form-control-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTextareaComponent),
      multi: true
    }
  ]
})
export class FormControlTextareaComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {

  @ViewChild('ngModel', {static: true}) ngModel: NgModel;

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  @Input() markUnTouched?: boolean;

  // Default
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() rows = '4';
  @Input() maxLength = 4500;
  @Input() minLength = 0;
  @Input() theme = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';

  @Output() childValue = new EventEmitter<string>();

  public valueSrc: string;

  hasError: 'null' | 'length' | '';
  valueSubscription: Subscription;

  set value(val: any) {
    if (val !== undefined && this.valueSrc !== val) {
      this.valueSrc = val;
      this.childValue.emit(val);
      this.onChange(val);
      this.onTouch();
    }
  }

  get value(): any {
    return this.valueSrc;
  }

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.ngModel) {
      this.valueSubscription = this.ngModel.valueChanges.subscribe(value => {
        this.validate();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.formSubmitted) {
      this.validate();
    }
    if (changes && changes.isRequired) {
      this.validate();
    }
    if (changes && changes.markUnTouched && changes.markUnTouched.currentValue === true) {
      this.ngModel.control.markAsUntouched();
      this.validate();
    }
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: string) {
  }

  onTouch() {
  }

  validate() {
    if (this.isRequired) {
      this.hasError = ValidationService.controlHasNullError(this.ngModel.control, this.formSubmitted, this.ref) ? 'null' : '';
    } else {
      this.hasError = '';
    }
    if (this.maxLength && this.hasError !== 'null') {
      this.hasError = ValidationService.controlHasLengthError(this.ngModel.control, this.maxLength, 'max') ? 'length' : '';
    }
  }

}
