<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="orgStructureForm">

    <app-input-select-single
      *ngIf="missionAnswers && missionAnswers.length"
      [label]="'Does the organisation have a mission?'"
      [items]="missionAnswers"
      [id]="'hasMission'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkForValue(
        'YES',
        $event,
        orgStructureForm.controls.hasMission,
        orgStructureForm.controls.mission
      )"
      formControlName="hasMission"
    ></app-input-select-single>
    <div *ngIf="orgStructureForm.value.hasMission === 'YES'" class="ml-5">
      <app-input-text
        [label]="'(if yes) State the organisation\'s mission'"
        [id]="'mission'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="mission"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="visionAnswers && visionAnswers.length"
      [label]="'Does the organisation have a vision?'"
      [items]="visionAnswers"
      [id]="'hasVision'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkForValue(
        'YES',
        $event,
        orgStructureForm.controls.hasVision,
        orgStructureForm.controls.vision
      )"
      formControlName="hasVision"
    ></app-input-select-single>
    <div *ngIf="orgStructureForm.value.hasVision === 'YES'" class="ml-5">
      <app-input-text
        [label]="'(if yes) State the organisation\'s vision'"
        [id]="'vision'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="vision"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="valueAndCultureAnswers && valueAndCultureAnswers.length"
      [label]="'Which statement best describes the organisation\'s values and culture?'"
      [items]="valueAndCultureAnswers"
      [id]="'valueAndCulture'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="valueAndCulture"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="missionAndVisionAnswers && missionAndVisionAnswers.length"
      [label]="'Which statement best describes the use of the organisation\'s mission, vision and/or values?'"
      [items]="missionAndVisionAnswers"
      [id]="'useOfMissionAndVision'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="useOfMissionAndVision"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="theoryOfChangeAnswers && theoryOfChangeAnswers.length"
      [label]="'Which statement best describes the organisation\'s theory of change?'"
      [items]="theoryOfChangeAnswers"
      [id]="'theoryOfChange'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="theoryOfChange"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="constitutionAnswers && constitutionAnswers.length"
      [label]="'Which statement best describes the organisation’s constitution?'"
      [items]="constitutionAnswers"
      [id]="'constitution'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="constitution"
    ></app-input-select-single>


    <app-input-select-single
      *ngIf="premisesContractAnswers && premisesContractAnswers.length"
      [label]="'Does the organisation lease or own the premise where the office/centre is located?'"
      [items]="premisesContractAnswers"
      [id]="'premisesContract'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="premisesContract"
    ></app-input-select-single>
    <div *ngIf="orgStructureForm.value.premisesContract === 'LEASE'" class="ml-5">
      <app-input-select-single
        *ngIf="leaseContractAnswers && leaseContractAnswers.length"
        [label]="'(if lease is selected) Is the lease contract current?'"
        [items]="leaseContractAnswers"
        [id]="'isLeaseRentalContractCurrent'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="isLeaseRentalContractCurrent"
      ></app-input-select-single>
      <app-input-select-single
        *ngIf="leaseHolderAnswers && leaseHolderAnswers.length"
        [label]="'(if lease is selected) Who is the lease with?'"
        [items]="leaseHolderAnswers"
        [id]="'leaseHolder'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="leaseHolder"
      ></app-input-select-single>
    </div>


    <app-input-select-single
      *ngIf="premisesTypeAnswers && premisesTypeAnswers.length"
      [label]="'Which statement best describes the organisation\'s premises (office and service delivery space)?'"
      [items]="premisesTypeAnswers"
      [id]="'premisesType'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="premisesType"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="insuranceCoverageAnswers && insuranceCoverageAnswers.length"
      [label]="'Which statement best describes the organisation\'s insurance coverage?'"
      [items]="insuranceCoverageAnswers"
      [id]="'insuranceCoverage'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="insuranceCoverage"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="manageSuppliesAnswers && manageSuppliesAnswers.length"
      [label]="'Which statement best describes the organisation\'s procedure to manage supplies?'"
      [items]="manageSuppliesAnswers"
      [id]="'manageSupplies'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="manageSupplies"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="tracksInformationAnswers && tracksInformationAnswers.length"
      [label]="'Does the organisation have a filing system (hard copy and/or electronic) that tracks how and where all information is stored?'"
      [items]="tracksInformationAnswers"
      [id]="'tracksInformation'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="tracksInformation"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="securityOfClientInformationAnswers && securityOfClientInformationAnswers.length"
      [label]="'Which statement best describes the organisation\'s measures to ensure confidentiality and security of all client information?'"
      [items]="securityOfClientInformationAnswers"
      [id]="'securityOfClientInformation'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="securityOfClientInformation"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="recordKeepingPolicyAnswers && recordKeepingPolicyAnswers.length"
      [label]="'Which statement best describes the organisation\'s record keeping policy and procedure?'"
      [items]="recordKeepingPolicyAnswers"
      [id]="'recordKeepingPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="recordKeepingPolicy"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="staffProtectionPolicyAnswers && staffProtectionPolicyAnswers.length"
      [label]="'Which statement best describes the organisation\'s policy protecting all staff and beneficiaries/clients from stigma and discrimination?'"
      [items]="staffProtectionPolicyAnswers"
      [id]="'staffProtectionPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="staffProtectionPolicy"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="serviceDeliveryStandardsAnswers && serviceDeliveryStandardsAnswers.length"
      [label]="'Which statement best describes the organisation\'s service delivery standards?'"
      [items]="serviceDeliveryStandardsAnswers"
      [id]="'serviceDeliveryStandards'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="serviceDeliveryStandards"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="informedOfServiceDeliveryStandardsAnswers && informedOfServiceDeliveryStandardsAnswers.length"
      [label]="'Are clients/beneficiaries informed of the organisation\'s service delivery standards?'"
      [items]="informedOfServiceDeliveryStandardsAnswers"
      [id]="'informedOfServiceDeliveryStandards'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="informedOfServiceDeliveryStandards"
    ></app-input-select-single>

  </form>
</app-spinner>
