<div class="mbl-chart">
  <ngx-charts-number-card
    cardColor="#343a40"
    [scheme]="colorScheme"
    [results]="chartData"
    [view]="view"
    [animations]="false"
    [valueFormatting]="format"
  ></ngx-charts-number-card>
</div>
