import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionQuery} from '../../stores/session/session.query';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, OnDestroy {

  isLoggedInSubscription: Subscription;

  constructor(private sessionQuery: SessionQuery,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isAuthenticated: boolean;

    this.isLoggedInSubscription = this.sessionQuery.isLoggedIn().subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.router.navigateByUrl('/authentication/login');
      }
      isAuthenticated = isLoggedIn;
    });

    return isAuthenticated;
  }

  ngOnDestroy() {
    this.isLoggedInSubscription.unsubscribe();
  }

}
