import {Component, OnInit, Inject, ChangeDetectorRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';
import {ReCaptcha2Component} from 'ngx-captcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('captchaElem', {static: true}) captchaElem: ReCaptcha2Component;

  contactForm: FormGroup;
  formSubmitted: boolean;
  loading: boolean;

  theme: 'light' | 'dark' = 'light';
  style: 'default' | 'bordered' = 'bordered';
  recaptchaType: 'image' | 'audio';
  markControlUntouched: boolean;

  showSuccessMessage: boolean;
  siteKey: string;

  size: 'compact' | 'normal' = 'normal';
  lang = 'en';

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  constructor(private formBuilder: FormBuilder,
              private alertService: AlertService,
              private httpClient: HttpClient,
              private cdr: ChangeDetectorRef,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.contactForm = this.createContactForm();
    if (this.document.location.host.includes('localhost')) {
      this.siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    } else {
      this.siteKey = '6LfA794UAAAAAN05voH--KR6lB5LUxdGxqi-cyov';
    }
  }

  createContactForm() {
    return this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  onSubmit(form: FormGroup) {
    this.formSubmitted = true;
    if (form.valid) {
      this.loading = true;
      this.reset();
      const url = `${environment.apiUrl}/contact-us`;

      const headers = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');

      this.httpClient.post<any>(url, form.value, {headers}).subscribe(() => {
        this.alertService.setAlert('Success: message sent', 'success', 'contactUs');
        this.contactForm.reset(this.createContactForm());
        this.contactForm.markAsUntouched();
        this.markControlUntouched = true;
        this.formSubmitted = false;
        this.loading = false;
      }, () => {
        this.alertService.setAlert('Failed: Unable to send message', 'error', 'contactUs');
        this.loading = false;
      });
    } else {
      this.alertService.setAlert('Please complete all fields before sending', 'error', 'contactUs');
    }
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleError(): void {
    this.handleReset();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }
}
