import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {FormBuilderQuestionService} from './store/form-builder-question.service';
import {createFormBuilderQuestionForm, FormBuilderQuestion, FormBuilderQuestionDTO} from './store/form-builder-question.model';
import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';
import {choiceInputItems} from '../../../../../store/dynamic-form.model';
import {booleanChoices} from '../../../../../../constants/boolean-answers.const';
import {Column} from '../../../../../../../shared/modules/data-table/models/mbl-table.model';
import {FormBuilderChoiceService} from './components/form-builder-choice/store/form-builder-choice.service';
import {FormBuilderChoiceComponent} from './components/form-builder-choice/form-builder-choice.component';
import {FormBuilderChoice} from './components/form-builder-choice/store/form-builder-choice.model';
import {QuestionControlType} from '../../../../../store/question-base.model';
import {TruncateUtil} from '../../../../../../../shared/utils/truncate-util';
import {Subscription} from 'rxjs';
import {AlertService} from '../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-form-builder-question',
  templateUrl: './form-builder-question.component.html',
  styleUrls: ['./form-builder-question.component.scss']
})
export class FormBuilderQuestionComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('actionTmpl', {static: true}) actionTmpl: TemplateRef<any>;
  @ViewChild('pageTop', {static: true}) pageTop: ElementRef;

  @Input() questionDTO: FormBuilderQuestionDTO;
  @Input() isCreating: boolean;
  @Input() isEditable: boolean;
  @Input() formId: number;
  @Input() order: number;

  @Output() refresh = new EventEmitter<boolean>();

  options: NgbModalOptions;
  profileForm: FormGroup;

  requiredChoices: ChoiceInputItem[] = booleanChoices;
  controlTypeChoices: ChoiceInputItem[] = choiceInputItems;

  columns: Column[];
  hasError: boolean;
  hasSaved: boolean;

  componentInstanceSubscription: Subscription;

  constructor(private modalService: ModalService,
              public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              public loadingService: LoadingService,
              private formBuilderQuestionService: FormBuilderQuestionService,
              private formBuilderChoiceService: FormBuilderChoiceService) {
  }

  ngOnInit() {
    this.options = {
      // ariaLabelledBy: `${(this.isCreating ? 'Add' : 'Edit')} Option`,
      centered: true,
      scrollable: true
    };

    this.populateColumns();

    this.buildForm();
  }

  ngOnDestroy() {
    if (this.componentInstanceSubscription) {
      this.componentInstanceSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO this doesn't work atm coz we can't update the inputs when using openStackedModal
    if (changes.questionDTO) {
      this.buildForm();
    }
  }

  buildForm() {
    if (this.isCreating) {
      this.profileForm = createFormBuilderQuestionForm(this.formBuilder, {
        order: this.order
      });
    } else {
      if (this.profileForm) {
        this.profileForm.patchValue(this.questionDTO);
      } else {
        this.profileForm = createFormBuilderQuestionForm(this.formBuilder, this.questionDTO);
      }
    }
  }

  closeModal(reason?: string) {
    this.activeModal.close(reason);
  }

  private populateColumns() {
    this.columns = [
      {name: 'Label', prop: 'label', width: '15%', sort: true},
      {name: 'Value', prop: 'value', width: '15%', sort: true},
      {name: 'Score', prop: 'score', width: '15%', sort: true},
      {name: 'Action', prop: 'action', width: '5%', cellTemplate: this.actionTmpl},
    ];
  }

  createOrUpdateQuestion(form: FormGroup) {
    this.alertService.resetAlert();
    if (form.valid) {
      this.loadingService.setIsLoading(true);

      const value: FormBuilderQuestion = form.value;
      if (value.id) {
        this.updateQuestion(value);
      } else {
        this.createQuestion(value);
      }

    } else {
      this.loadingService.setIsLoading(false);
    }
  }

  private createQuestion(question: FormBuilderQuestion) {
    this.formBuilderQuestionService.create(this.formId, question).subscribe(saved => {
      this.loadingService.setIsLoading(false);
      this.isCreating = false;
      this.questionDTO = saved;
      this.buildForm();
      // this.setHasSaved();
      // this.closeModal();
    }, error => {
      this.loadingService.setIsLoading(false);
      this.pageTop.nativeElement.scrollIntoView({behavior: 'smooth'});
      this.alertService.setAlert(TruncateUtil.truncate(error.error.message, 100), 'error', 'formBuilderQuestion');
    });
  }

  private updateQuestion(question: FormBuilderQuestion) {
    this.formBuilderQuestionService.update(this.formId, question).subscribe(saved => {
      this.loadingService.setIsLoading(false);
      this.questionDTO = saved;
      this.buildForm();
      // this.setHasSaved();
    }, error => {
      this.alertService.setAlert(TruncateUtil.truncate(error.error.message, 100), 'error', 'formBuilderQuestion');
      this.loadingService.setIsLoading(false);
      this.pageTop.nativeElement.scrollIntoView({behavior: 'smooth'});
    });
  }

  hasOptions() {
    return this.questionDTO && this.questionDTO.id &&
      (this.profileForm.value.controlType === QuestionControlType.checkbox ||
        this.profileForm.value.controlType === QuestionControlType.radio);
  }

  delete(row) {
    this.formBuilderChoiceService.delete(row.id).subscribe(() => {
        this.alertService.setAlert('Deleted...', 'success', 'formBuilderQuestion');
      },
      error => {
        const msg = error && error.error && error.error.message || 'Failed to delete.';
        this.alertService.setAlert(TruncateUtil.truncate(msg, 100), 'error', 'formBuilderQuestion');
      });
  }

  openOptionModal(row: FormBuilderChoice, isCreating: boolean) {
    const modalRef = this.modalService.openStackedModal(FormBuilderChoiceComponent, this.options, [
      {key: 'option', value: row},
      {key: 'isCreating', value: isCreating},
      {key: 'isEditable', value: this.isEditable},
      {key: 'questionId', value: this.questionDTO.id},
    ]);

    this.componentInstanceSubscription = modalRef.componentInstance.refresh.subscribe(result => {
      if (result) {
        if (result.status === 'created') {
          const deepClone = JSON.parse(JSON.stringify(this.questionDTO));
          deepClone.options.push(result.option);
          this.questionDTO = deepClone;
        } else if (result.status === 'updated') {
          const deepClone = JSON.parse(JSON.stringify(this.questionDTO));
          deepClone.options = deepClone.options.map(option => {
            if (option.id === result.option.id) {
              option = result.option;
            }
            return option;
          });
          this.questionDTO = deepClone;
        }
      }
    });

    modalRef.result.then(reason => {
      // This runs when the modal is closed with the close() function
      if (reason === 'saved') {
        this.refresh.emit(true);
      }
    }).catch(() => {
      // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
    });
  }

  private setHasSaved(): void {
    this.hasSaved = true;
    setTimeout(() => {
      this.hasSaved = false;
    }, 5000);
  }
}
