import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface GrantWorkflowState {
}

export function createInitialState(): GrantWorkflowState {
  return {
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'grantWorkflow',
})
export class GrantWorkflowStore extends Store<GrantWorkflowState> {

  constructor() {
    super(createInitialState());
  }
}
