import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {FormBuilderDTO, FormBuilderModel} from './form-builder.model';

export interface FormBuilderState {
  active: FormBuilderDTO;
  list: FormBuilderModel[];
  templateList: FormBuilderModel[];
}

export function createInitialState(): FormBuilderState {
  return {
    active: undefined,
    list: [],
    templateList: [],
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'dynamic-form'})
export class FormBuilderStore extends Store<FormBuilderState> {

  constructor() {
    super(createInitialState());
  }

}

