<div [class]="style + '-input-group'">
  <label
    *ngIf="showLabel"
    [class]="style + '-input-label'"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    [for]="id"
  >{{label}}</label>
  <textarea
    #ngModel=ngModel
    [class]="style + '-input'"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    [class.border-danger]="hasError === 'null'"
    [class.border-warning]="hasError === 'length'"
    [required]="isRequired"
    [maxLength]="maxLength"
    [id]="id"
    [name]="id"
    [rows]="rows"
    [placeholder]="placeholder"
    [attr.aria-label]="label"
    (blur)="validate()"
    [(ngModel)]="value"
    autocomplete="off"
  ></textarea>
  <div class="input-validation-notices mt-1" *ngIf="showErrors">
    <ng-container *ngIf="!isRequired && showOptional">
      <div class="validation-notice optional-notice">
        <p class="font-size-sm font-weight-bold">This field is optional</p>
      </div>
    </ng-container>
    <div class="validation-notice null-notice" [class.invisible]="hasError !== 'null'">
      <p class="font-size-sm font-weight-bold text-danger">This field is required!</p>
    </div>
    <div class="validation-notice length-notice" [class.invisible]="hasError !== 'length'">
      <p class="font-size-sm font-weight-bold text-warning">Max character limit reached</p>
    </div>
  </div>
</div>
