import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {
  programsAnswers, peopleTargetedAnswers
} from '../../constants/funding-request-answers/targeted-prevention-question-answers.const';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {FormValidationService} from '../../../../../../../services/form-validation/form-validation.service';
import {checkForValue} from '../../../../../../organisation/capacity-spectrum/components/capacity-spectrum/store/capacity-spectrum.model';

@Component({
  selector: 'app-targeted-prevention',
  templateUrl: './targeted-prevention.component.html',
  styleUrls: ['./targeted-prevention.component.scss']
})
export class TargetedPreventionComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() targetedPreventionForm: FormGroup;
  @Input() formValid: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  programsOptions = programsAnswers;
  peopleTargetedOptions = peopleTargetedAnswers;
  numberMask: any;
  durationOfTypeOptions = [
    {
      label: 'Days',
      value: 'Days'
    },
    {
      label: 'Months',
      value: 'Months'
    }
  ];

  constructor(public formValidationService: FormValidationService) {
  }

  ngOnInit() {
    this.numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      allowNegative: false,
      includeThousandsSeparator: false
    });
  }

  checkOther(value: string | boolean, selectedValue: string, controlParent: AbstractControl, controlChild: AbstractControl) {
    checkForValue(value, selectedValue, controlParent, controlChild);
  }
}
