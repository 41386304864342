import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {SessionQuery} from '../../../../stores/session/session.query';
import {User} from '../../../../stores/user/user.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-funder-sidebar',
  templateUrl: './funder-sidebar.component.html',
  styleUrls: ['./funder-sidebar.component.scss']
})
export class FunderSidebarComponent implements OnInit, OnDestroy {
  activeId = 1;
  user: User;
  userSubscription: Subscription;

  env = environment;

  constructor(private sessionQuery: SessionQuery) {
  }

  ngOnInit(): void {
    this.userSubscription = this.sessionQuery.getUserObservable().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
