<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    HIV testing and counseling services are defined as community, mobile, facility
    and/or home based service through which people are counseled, tested for HIV,
    given their test result and, if positive, are linked to HIV treatment services.
  </p>
  <form [formGroup]="hivTestingForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="typeOfHivTestingOptions"
        [label]="'What type of HIV testing and counseling services will you use this funding for?'"
        [id]="'hiv-test-typeOfHivTesting'"
        [name]="'hiv-test-typeOfHivTesting'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="typeOfHivTesting"
        (selected)="checkOther('other', $event, hivTestingForm.controls.typeOfHivTesting, hivTestingForm.controls.typeOfHivTestingOther)"
      ></app-input-checkbox>

      <div *ngIf="hivTestingForm.value.typeOfHivTesting?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'hiv-test-typeOfHivTestingOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="typeOfHivTestingOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be targeted for HIV testing and counseling services?'"
        [id]="'hiv-test-peopleTargeted'"
        [name]="'hiv-test-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
      ></app-input-checkbox>

      <app-input-number
        [label]="'How many people will be targeted for HIV testing and counseling services?'"
        [id]="'hiv-test-numberOfPeopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeopleTargeted"
      ></app-input-number>

      <app-input-select-single
        [label]="'How often will HIV testing and counseling services be offered?'"
        [items]="frequencyOfTestingOptions"
        [id]="'hiv-test-frequencyOfTesting'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        [isRequired]="true"
        formControlName="frequencyOfTesting"
      ></app-input-select-single>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'hiv-test-durationOfTesting'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfTesting"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'hiv-test-durationOfTestingType'"
            [isRequired]="true"
            [name]="'hiv-test-durationOfTestingType'"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfTestingType"
          ></app-input-radio>
        </div>
      </div>

      <app-input-checkbox
        [choices]="testKitSupplierOptions"
        [label]="'Who will supply the test kits?'"
        [id]="'hiv-test-testKitSupplier'"
        [name]="'hiv-test-testKitSupplier'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="testKitSupplier"
        (selected)="checkOther('other', $event, hivTestingForm.controls.testKitSupplier, hivTestingForm.controls.testKitSupplierOther)"
      ></app-input-checkbox>

      <div *ngIf="hivTestingForm.value.testKitSupplier?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'hiv-test-testKitSupplierOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="testKitSupplierOther"
        ></app-input-textarea>
      </div>

      <div>
        <p>How much funding do you need to provide these HIV testing and counseling services?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="hivTestingForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
