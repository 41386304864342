import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum OrganisationalStructureEndpointType {
  MISSION = 'MISSION',
  VISION = 'VISION',
  VALUE_AND_CULTURE = 'VALUE_AND_CULTURE',
  MISSION_AND_VISION = 'MISSION_AND_VISION',
  THEORY_OF_CHANGE = 'THEORY_OF_CHANGE',
  CONSTITUTION = 'CONSTITUTION',
  PREMISES_CONTRACT = 'PREMISES_CONTRACT',
  LEASE_CONTRACT = 'LEASE_CONTRACT',
  LEASE_HOLDER = 'LEASE_HOLDER',
  PREMISES_TYPE = 'PREMISES_TYPE',
  INSURANCE_COVERAGE = 'INSURANCE_COVERAGE',
  MANAGE_SUPPLIES = 'MANAGE_SUPPLIES',
  TRACKS_INFORMATION = 'TRACKS_INFORMATION',
  SECURITY_OF_CLIENT_INFORMATION = 'SECURITY_OF_CLIENT_INFORMATION',
  RECORD_KEEPING_POLICY = 'RECORD_KEEPING_POLICY',
  STAFF_PROTECTION_POLICY = 'STAFF_PROTECTION_POLICY',
  SERVICE_DELIVERY_STANDARDS = 'SERVICE_DELIVERY_STANDARDS',
  INFORMED_OF_SERVICE_DELIVERY_STANDARDS = 'INFORMED_OF_SERVICE_DELIVERY_STANDARDS'
}

export interface CSOrganizationalStructure extends BaseEntity {
  hasMission: string;
  mission: string; // if hasMission === 'YES'
  hasVision: string;
  vision: string; // if hasVision === 'YES'
  valueAndCulture: string;
  useOfMissionAndVision: string;
  theoryOfChange: string;
  constitution: string;

  // lease
  premisesContract: string;
  isLeaseRentalContractCurrent: string; // if premisesContract = 'LEASE'
  leaseHolder: string; // if premisesContract = 'LEASE'
  // end lease

  premisesType: string;
  insuranceCoverage: string;
  manageSupplies: string;
  tracksInformation: string;
  securityOfClientInformation: string;
  recordKeepingPolicy: string;
  staffProtectionPolicy: string;
  serviceDeliveryStandards: string;
  informedOfServiceDeliveryStandards: string;
}

export function createCSOrganisationalStructure(params: Partial<CSOrganizationalStructure>) {
  return Object.assign({}, createBaseEntity(params), {
    hasMission: params && params.hasMission,
    mission: params && params.mission,
    hasVision: params && params.hasVision,
    vision: params && params.vision,
    valueAndCulture: params && params.valueAndCulture,
    useOfMissionAndVision: params && params.useOfMissionAndVision,
    theoryOfChange: params && params.theoryOfChange,
    constitution: params && params.constitution,
    premisesContract: params && params.premisesContract,
    isLeaseRentalContractCurrent: params && params.isLeaseRentalContractCurrent,
    leaseHolder: params && params.leaseHolder,
    premisesType: params && params.premisesType,
    insuranceCoverage: params && params.insuranceCoverage,
    manageSupplies: params && params.manageSupplies,
    tracksInformation: params && params.tracksInformation,
    securityOfClientInformation: params && params.securityOfClientInformation,
    recordKeepingPolicy: params && params.recordKeepingPolicy,
    staffProtectionPolicy: params && params.staffProtectionPolicy,
    serviceDeliveryStandards: params && params.serviceDeliveryStandards,
    informedOfServiceDeliveryStandards: params && params.informedOfServiceDeliveryStandards
  }) as CSOrganizationalStructure;
}

export function createCSOrganisationalStructureForm(destroy$: Subject<boolean>,
                                                    formBuilder: FormBuilder,
                                                    params: Partial<CSOrganizationalStructure>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    hasMission: [params && params.hasMission, Validators.required],
    mission: [params && params.mission,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasMission').value === 'YES')
    ],
    hasVision: [params && params.hasVision, Validators.required],
    vision: [params && params.vision,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasVision').value === 'YES')
    ],
    valueAndCulture: [params && params.valueAndCulture, Validators.required],
    useOfMissionAndVision: [params && params.useOfMissionAndVision, Validators.required],
    theoryOfChange: [params && params.theoryOfChange, Validators.required],
    constitution: [params && params.constitution, Validators.required],

    // Lease
    premisesContract: [params && params.premisesContract, Validators.required],
    isLeaseRentalContractCurrent: [params && params.isLeaseRentalContractCurrent,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('premisesContract').value === 'LEASE')],
    leaseHolder: [params && params.leaseHolder,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('premisesContract').value === 'LEASE')
    ],
    // end Lease

    premisesType: [params && params.premisesType, Validators.required],
    insuranceCoverage: [params && params.insuranceCoverage, Validators.required],
    manageSupplies: [params && params.manageSupplies, Validators.required],
    tracksInformation: [params && params.tracksInformation, Validators.required],
    securityOfClientInformation: [params && params.securityOfClientInformation, Validators.required],
    recordKeepingPolicy: [params && params.recordKeepingPolicy, Validators.required],
    staffProtectionPolicy: [params && params.staffProtectionPolicy, Validators.required],
    serviceDeliveryStandards: [params && params.serviceDeliveryStandards, Validators.required],
    informedOfServiceDeliveryStandards: [params && params.informedOfServiceDeliveryStandards, Validators.required],
  }));

  formGroup.get('hasMission').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('mission').updateValueAndValidity();
    });

  formGroup.get('hasVision').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('vision').updateValueAndValidity();
    });

  formGroup.get('premisesContract').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('isLeaseRentalContractCurrent').updateValueAndValidity();
      formGroup.get('leaseHolder').updateValueAndValidity();
    });

  return formGroup;
}
