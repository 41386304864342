<app-spinner [loadingObs]="isLoading">
  <div class="flex-center">
    <div class="h-100 w-100 mx-auto">
      <div *ngIf="workPlans" class="charts-dash">

        <div class="total-chart">
          <div class="w-100 h-100 border">
            <div class="card-body">
              <app-simple-pie-chart
                *ngIf="totalsChartData && totalsChartData[0].value >= 0"
                [chartData]="totalsChartData"
                [view]="[400, 200]"
                [totalHeading]="'Budget Requested'"
                [valueFormatting]="valueFormatting"
              ></app-simple-pie-chart>
            </div>
          </div>
        </div>

        <div class="messages-chart">
          <div class="card h-100">
            <div class="card-body py-3 px-2">
              <div class="flex-center pt-3">
                <span
                  *ngIf="workflow" class="badge badge-pill"
                  [ngClass]="workPlanStatusBadgeUtil.getClass(workflow.workPlanStatus)"
                >
                  {{workflow.workPlanStatus | workPlanStatus}}
                </span>
              </div>

              <div class="flex-center pt-3">
                <app-work-plan-messages [workflow]="workflow"></app-work-plan-messages>
              </div>
            </div>
          </div>
        </div>

        <div class="funding-request-chart">
          <div class="card h-100">
            <div class="card-body py-3 px-2">
              <div class="flex-center">
                <!-- Applicant -->
                <ng-container *authenticatedWithRole="['ROLE_APPLICANT']">
                  <button
                    *ngIf="workflow?.workPlanStatus === 'WITH_APPLICANT'"
                    class="btn btn-primary btn-outline-light btn-sm"
                    type="button"
                    (click)="applicantSubmit()"
                  >
                    Submit
                  </button>
                </ng-container>

                <!-- GC -->
                <ng-container
                  *authenticatedWithRole="['ROLE_GRANT_COORDINATOR', 'ROLE_GRANT_MANAGER', 'ROLE_FUNDER', 'ROLE_ADMIN']"
                >
                  <ng-container *ngIf="workflow?.workPlanStatus === 'ACTIVE'">
                    <button class="btn btn-primary btn-outline-light btn-sm" type="button"
                            (click)="assignToApplicant()">
                      Assign to Applicant
                    </button>
                    <button
                      *ngIf="workflow?.workPlanHasBeenWithApplicant"
                      class="btn btn-success btn-outline-light btn-sm"
                      type="button"
                      (click)="submitForApproval()"
                    >
                      Submit For Approval
                    </button>
                  </ng-container>
                </ng-container>

                <!-- GM -->
                <ng-container *authenticatedWithRole="['ROLE_GRANT_MANAGER', 'ROLE_FUNDER', 'ROLE_ADMIN']">
                  <ng-container *ngIf="workflow?.workPlanStatus === 'SUBMITTED'">
                    <button class="btn btn-danger btn-outline-light btn-sm" type="button" (click)="reject()">
                      Reject
                    </button>
                    <button class="btn btn-primary btn-outline-light btn-sm" type="button"
                            (click)="approve()">Approve
                    </button>
                  </ng-container>
                  <ng-container *ngIf="workflow?.workPlanStatus === 'APPROVED'">
                    <button class="btn btn-danger btn-outline-light btn-sm" type="button"
                            (click)="unApprove()">
                      Un-Approve
                    </button>
                  </ng-container>
                </ng-container>
              </div>

              <div class="flex-center py-3">
                <app-input-select-single
                  class="w-75"
                  [items]="workPlanDropdownList"
                  [clearValueOnItemsChange]="false"
                  [id]="'funding-request'"
                  [label]="'Select Funding Request'"
                  [style]="'default'"
                  [theme]="'light'"
                  [showOptional]="false"
                  [allowDelete]="false"
                  [hasBorder]="false"
                  [isGrey]="false"
                  (selectedItem)="selectWorkPlan($event)"
                  [(ngModel)]="selectedWorkPlan"
                ></app-input-select-single>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="work-plan-content">
    <app-work-plan-funding-request
      [isReadOnly]="isReadOnly"
      [applicantReadOnly]="applicantReadOnly"
      [coordinatorReadOnly]="coordinatorReadOnly"
    ></app-work-plan-funding-request>
  </div>
</app-spinner>
