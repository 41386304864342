<div class="wrapper">
  <div class="w-100 mx-auto">
    <div class="h-100" [class.mt-6]="fromReview" *ngIf="currentOrg">
      <div class="card-body">


        <ng-container [ngSwitch]="currentTab">
          <ng-container *ngSwitchCase="'generalInfo'">
            <h4 class="text-center">General Info</h4>
            <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
            <div class="row">
              <div class="col-5">
                <div class="flex-start flex-align-start flex-column">
                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Organisation Name'"
                    [id]="'legalName'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.legalName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Trading Name'"
                    [id]="'tradingName'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.tradingName"
                  ></app-input-text-disabled>

                  <app-input-date-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Organisation Inception Date'"
                    [id]="'npoRegDate'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.inceptionDate | date : 'longDate'"
                  ></app-input-date-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Registration Number'"
                    [id]="'regNumber'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.regNumber"
                  ></app-input-text-disabled>

                  <app-input-date-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Registration Date'"
                    [id]="'regDate'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.regDate | date : 'longDate'"
                  ></app-input-date-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    *authenticatedWithRole="['ROLE_ADMIN']"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Organisation Code'"
                    [id]="'code'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.code"
                  ></app-input-text-disabled>
                </div>
              </div>
              <div class="col-4">
                <div class="w-85 mx-auto">
                  <app-input-checkbox-disabled
                    [label]="'Implementer Type'"
                    [isGrey]="false"
                    [hasBorder]="false"
                    [choices]="organisationTypeItems"
                    [values]="currentOrg.value.organisationType"
                    [id]="'organisationType'"
                    [style]="'default'"
                    [theme]="'light'"
                  ></app-input-checkbox-disabled>
                </div>
              </div>
              <div class="col-3 flex-start flex-column">
                <label>Organisation Logo</label>
                <img class="profile-image" src="assets/img/placeholder.jpg" alt="profile-photo">
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'contactDetails'">
            <h4 class="text-center">Organisation contact details</h4>
            <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">

            <div class="form-grid">

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Contact Number'"
                [id]="'contactNo'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.contactNo"
              ></app-input-text-disabled>

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Website'"
                [id]="'websitePrimary'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.website"
              ></app-input-text-disabled>

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Email Address'"
                [id]="'email'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.email"
              ></app-input-text-disabled>

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Address Line 1'"
                [id]="'addressOne'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.address1"
              ></app-input-text-disabled>

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Address Line 2'"
                [id]="'addressTwo'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.address2"
              ></app-input-text-disabled>

              <app-input-select-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Province'"
                [id]="'province'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.provinceCode | province | async"
              ></app-input-select-disabled>

              <app-input-select-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'District'"
                [id]="'district'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.districtCode | district | async"
              ></app-input-select-disabled>

              <app-input-select-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Sub District'"
                [id]="'subDistrict'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.subDistrictCode | subDistrict | async"
              ></app-input-select-disabled>

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'City'"
                [id]="'city'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.city"
              ></app-input-text-disabled>

              <app-input-text-disabled
                class="w-100"
                [hasBorder]="false"
                [isGrey]="false"
                [label]="'Postal Code'"
                [id]="'postalCode'"
                [style]="'default'"
                [theme]="'light'"
                [value]="currentOrg.value.orgContact.postalCode"
              ></app-input-text-disabled>

            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'contactPersons'">
            <h4 class="text-center">Implementer contact details</h4>
            <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
            <div class="row">
              <div class="col-6">
                <h4 class="text-center">Primary contact details</h4>
                <hr class="dropdown-divider w-75 mx-auto border-primary mb-2">
                <div class="flex-between align-items-start flex-column">
                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'First Name'"
                    [id]="'firstNamePrimary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.firstName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Preferred Name'"
                    [id]="'website'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.middleName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Last Name'"
                    [id]="'lastNamePrimary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.lastName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Position'"
                    [id]="'positionPrimary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.position"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Landline'"
                    [id]="'contactNoPrimary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.contactDetails.contactNo"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Cellphone'"
                    [id]="'mobileNoPrimary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.contactDetails.mobileNo"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Email'"
                    [id]="'emailPrimary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.primaryContact.contactDetails.email"
                  ></app-input-text-disabled>
                </div>
              </div>
              <div class="col-6">
                <h4 class="text-center">Secondary contact details</h4>
                <hr class="dropdown-divider w-75 mx-auto border-primary mb-2">
                <div class="flex-between align-items-start flex-column">
                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'First Name'"
                    [id]="'firstNameSecondary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.firstName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Preferred Name'"
                    [id]="'middleNameSecondary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.middleName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Last Name'"
                    [id]="'lastNameSecondary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.lastName"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Position'"
                    [id]="'position'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.position"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Landline'"
                    [id]="'contactNoSecondary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.contactDetails.contactNo"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Cellphone'"
                    [id]="'mobileNoSecondary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.contactDetails.mobileNo"
                  ></app-input-text-disabled>

                  <app-input-text-disabled
                    class="w-100"
                    [hasBorder]="false"
                    [isGrey]="false"
                    [label]="'Email'"
                    [id]="'emailSecondary'"
                    [style]="'default'"
                    [theme]="'light'"
                    [value]="currentOrg.value.secondaryContact.contactDetails.email"
                  ></app-input-text-disabled>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
