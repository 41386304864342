import {FormBuilder, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../../../shared/models/base-entity.model';
import {FormBuilderQuestion} from '../../../store/form-builder-question.model';

export interface FormBuilderChoice extends BaseEntity {
  dynamicFormQuestion: FormBuilderQuestion;
  value: string;
  label: string;
  score: number;
}

export function createFormBuilderChoiceList(params: Partial<FormBuilderChoice>[]) {
  const list: FormBuilderChoice[] = [];
  for (const param of params) {
    list.push(createFormBuilderChoice(param));
  }

  return list;
}

export function createFormBuilderChoice(params: Partial<FormBuilderChoice>) {
  return Object.assign({}, createBaseEntity(params), {
    dynamicFormQuestion: params.dynamicFormQuestion,
    value: params.value,
    label: params.label,
    score: params.score,
  }) as FormBuilderChoice;
}

export function createFormBuilderChoiceForm(formBuilder: FormBuilder, params: Partial<FormBuilderChoice>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    dynamicFormQuestion: [params && params.dynamicFormQuestion, Validators.required],
    value: [params && params.value, Validators.required],
    label: [params && params.label, Validators.required],
    score: [params && params.score],
  }));
}
