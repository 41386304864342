import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { sidePanelYesNoAnswers } from 'app/modules/grant-review/constants/side-panel-answers.const';

@Component({
  selector: 'app-site-visit-recent-changes',
  templateUrl: './site-visit-recent-changes.component.html',
  styleUrls: ['./site-visit-recent-changes.component.scss']
})
export class SiteVisitRecentChangesComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() siteVisitRecentChangesForm: FormGroup;
  @Input() isReadOnly: boolean;

  siteVisitRecentChangesAnswers = sidePanelYesNoAnswers;

  constructor() { }

  ngOnInit() {
  }

}
