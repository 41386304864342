import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {budgetCategoryAnswers, paymentEvidenceAnswers} from './activity.const';
import * as _ from 'lodash';
import {Objective} from '../store/objective.model';
import {ActivityService} from './store/activity.service';
import {Activity} from './store/activity.model';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('expanded', style({height: '*', visibility: 'visible'})),
      state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityComponent implements OnInit, OnChanges {

  @Input() isReadOnly: boolean;
  @Input() applicantReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;
  @Input() selectedObjective: Objective;

  paymentEvidenceOptions = _.cloneDeep(paymentEvidenceAnswers);
  budgetCategoryOptions = _.cloneDeep(budgetCategoryAnswers);

  dataSource: ActivityElement[] = [];
  columnsToDisplay = ['name', 'description', 'timeframe', 'responsiblePerson', 'costCategory', 'cost', 'actions'];
  expandedElement: ActivityElement;

  constructor(public activityService: ActivityService) {
  }

  ngOnInit() {
    // Only this component is editable by the applicant when they have the work plan
    // if (this.workPlanService.workPlanIsWithApplicant && this.ROLE_APPLICANT) {
    //   this.isReadOnly = false;
    // }

    this.dataSource = this.generateDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedObjective) {
      this.selectedObjective = changes.selectedObjective.currentValue;
      this.dataSource = [...this.generateDataSource()];
    }
  }

  deleteActivity(activity: Activity) {
    this.activityService.delete(activity).subscribe();
  }

  private generateDataSource(): ActivityElement[] {
    if (this.selectedObjective && this.selectedObjective.activities) {
      return this.selectedObjective.activities.map(item => {
        return {
          name: item.name,
          description: item.description,
          monitoringEvaluation: item.monitoringEvaluation,
          paymentEvidence: item.paymentEvidence,
          paymentEvidenceOther: item.paymentEvidenceOther,
          timeframe: item.timeframe,
          responsiblePerson: item.responsiblePerson,
          costCategory: item.costCategory,
          cost: item.cost,
          explanationOfCost: item.explanationOfCost,
          item
        };
      });
    }
    return [];
  }
}

export interface ActivityElement {
  name: string;
  description: string;
  monitoringEvaluation: string;
  paymentEvidence: string[];
  paymentEvidenceOther: string;
  timeframe: string;
  responsiblePerson: string;
  costCategory: string;
  cost: number;
  explanationOfCost: string;
  item: Activity;
}
