<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="humanResourcesForm">

    <app-input-number
      [label]="'How many people permanently work in the organisation and are paid a salary?'"
      [id]="'permanentPaidStaff'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="permanentPaidStaff"
    ></app-input-number>

    <app-input-number
      [label]="'How many people are placed in the organisation (care workers, EPWP, etc.) and receive a stipend?'"
      [id]="'stipendWorkers'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="stipendWorkers"
    ></app-input-number>

    <app-input-number
      [label]="'How many people in the organisation are volunteers and receive no pay of any kind?'"
      [id]="'volunteers'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="volunteers"
    ></app-input-number>

    <app-input-select-single
      *ngIf="qualificationAnswers && qualificationAnswers.length"
      [label]="'What is the average level of education/qualification for the permanent members of the staff?'"
      [items]="qualificationAnswers"
      [id]="'qualification'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="qualification"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="staffPeriodAnswers && staffPeriodAnswers.length"
      [label]="'On average, how long have team members (including all staff and volunteers) been with the organisation?'"
      [items]="staffPeriodAnswers"
      [id]="'averageStaffPeriod'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="averageStaffPeriod"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="employmentContractAnswers && employmentContractAnswers.length"
      [label]="'Do all paid team members have a written employment contract that states the duration of the employment and the amount the person will be paid?'"
      [items]="employmentContractAnswers"
      [id]="'employmentContract'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="employmentContract"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="conformToLabourLawAnswers && conformToLabourLawAnswers.length"
      [label]="'Do staff salaries and benefits conform to national labour laws?'"
      [items]="conformToLabourLawAnswers"
      [id]="'conformToLabourLaw'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="conformToLabourLaw"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="jobDescriptionAnswers && jobDescriptionAnswers.length"
      [label]="'Which statement best describes team members’ job descriptions?'"
      [items]="jobDescriptionAnswers"
      [id]="'jobDescription'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="jobDescription"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="retentionPlanAnswers && retentionPlanAnswers.length"
      [label]="'Which statement best describes the organisation\'s staff retention plan?'"
      [items]="retentionPlanAnswers"
      [id]="'retentionPlan'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="retentionPlan"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="hrPolicyAnswers && hrPolicyAnswers.length"
      [label]="'Which statement best describes the organisation’s HR policy?'"
      [items]="hrPolicyAnswers"
      [id]="'hrPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hrPolicy"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="hrPolicyElementsAnswers && hrPolicyElementsAnswers.length"
      [choices]="hrPolicyElementsAnswers"
      [label]="'Which of the following elements are included in the organisation\'s HR policy? (check all that apply)'"
      [id]="'hrPolicyElements'"
      [name]="'hrPolicyElements'"
      [theme]="theme"
      [style]="style"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      (selected)="checkOther('OTHER', $event, humanResourcesForm.controls.hrPolicyElements, humanResourcesForm.controls.hrPolicyElementsOther)"
      formControlName="hrPolicyElements"
    ></app-input-checkbox>

    <div *ngIf="humanResourcesForm.value.hrPolicyElements?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If Other (please specify)'"
        [id]="'hrPolicyElementsOther'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="hrPolicyElementsOther"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="recruitmentProcessAnswers && recruitmentProcessAnswers.length"
      [label]="'Which statement best describes the organisation\'s recruitment process?'"
      [items]="recruitmentProcessAnswers"
      [id]="'recruitmentProcess'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="recruitmentProcess"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="trainingFrequencyAnswers && trainingFrequencyAnswers.length"
      [label]="'How often does the organisation conduct staff workshops or training, either formal or in-house, to ensure that all team members are supported and delivering up-to-date, consistent, high quality service?'"
      [items]="trainingFrequencyAnswers"
      [id]="'trainingFrequency'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="trainingFrequency"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="internalCommunicationAnswers && internalCommunicationAnswers.length"
      [label]="'Which statement best describes the organisation\'s internal communication?'"
      [items]="internalCommunicationAnswers"
      [id]="'internalCommunication'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="internalCommunication"
    ></app-input-select-single>


    <app-input-select-single
      [label]="'Does the organisation use contractors/consultants?'"
      [items]="booleanChoices"
      [id]="'hasContractors'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(true, $event, humanResourcesForm.controls.hasContractors, humanResourcesForm.controls.contractorAgreement)"
      formControlName="hasContractors"
    ></app-input-select-single>
    <div *ngIf="humanResourcesForm.value.hasContractors === true" class="ml-5">
      <app-input-select-single
        [label]="'If yes: Is there a written scope of work which details deliverables, timelines and payment amount and terms for each contractor/consultant?'"
        [items]="booleanChoices"
        [id]="'contractorAgreement'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="contractorAgreement"
      ></app-input-select-single>
    </div>
  </form>
</app-spinner>
