import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HumanResourcesEndpointType} from '../store/cs-human-resources.model';

@Injectable({
  providedIn: 'root'
})
export class CsHumanResourcesAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/human-resources`;

  public staffPeriodSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $staffPeriod = this.staffPeriodSource.asObservable();

  public qualificationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $qualification = this.qualificationSource.asObservable();

  public employmentContractSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $employmentContract = this.employmentContractSource.asObservable();

  public conformToLabourLawSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $conformToLabourLaw = this.conformToLabourLawSource.asObservable();

  public jobDescriptionSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $jobDescription = this.jobDescriptionSource.asObservable();

  public retentionPlanSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $retentionPlan = this.retentionPlanSource.asObservable();

  public hrPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $hrPolicy = this.hrPolicySource.asObservable();

  public hrPolicyElementsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $hrPolicyElements = this.hrPolicyElementsSource.asObservable();

  public recruitmentProcessSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $recruitmentProcess = this.recruitmentProcessSource.asObservable();

  public trainingFrequencySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $trainingFrequency = this.trainingFrequencySource.asObservable();

  public internalCommunicationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $internalCommunication = this.internalCommunicationSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.staffPeriodSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case HumanResourcesEndpointType.STAFF_PERIOD: {
          this.staffPeriodSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.QUALIFICATION: {
          this.qualificationSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.EMPLOYMENT_CONTRACT: {
          this.employmentContractSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.CONFORM_TO_LABOUR_LAW: {
          this.conformToLabourLawSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.JOB_DESCRIPTION: {
          this.jobDescriptionSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.RETENTION_PLAN: {
          this.retentionPlanSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.HR_POLICY: {
          this.hrPolicySource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.HR_POLICY_ELEMENTS: {
          this.hrPolicyElementsSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.RECRUITMENT_PROCESS: {
          this.recruitmentProcessSource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.TRAINING_FREQUENCY: {
          this.trainingFrequencySource.next(answerListDTO);
          break;
        }
        case HumanResourcesEndpointType.INTERNAL_COMMUNICATION: {
          this.internalCommunicationSource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
