import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsMarketingAndPromotionAnswerService} from './services/cs-marketing-and-promotion-answer.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-cs-marketing-and-promotion',
  templateUrl: './cs-marketing-and-promotion.component.html',
  styleUrls: ['./cs-marketing-and-promotion.component.scss']
})
export class CsMarketingAndPromotionComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() marketingAndPromotionForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  // Question Answers.
  marketingStrategyAnswers = [];
  communicationFrequencyAnswers = [];
  storyFrequencyAnswers = [];
  marketingStaffAnswers = [];
  logoAnswers = [];
  brandingPolicyAnswers = [];
  brochureAnswers = [];
  websiteAnswers = [];
  socialMediaAnswers = [];
  newsletterAnswers = [];

  marketingStrategySubscription: Subscription;
  communicationFrequencySubscription: Subscription;
  storyFrequencySubscription: Subscription;
  marketingStaffSubscription: Subscription;
  logoSubscription: Subscription;
  brandingPolicySubscription: Subscription;
  brochureSubscription: Subscription;
  websiteSubscription: Subscription;
  socialMediaSubscription: Subscription;
  newsletterSubscription: Subscription;
  childFormTouched: boolean;


  constructor(private loadingService: LoadingService,
              private csMarketingAndPromotionAnswerService: CsMarketingAndPromotionAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.marketingAndPromotionForm.touched;

    this.populateAnswers();
  }

  ngOnDestroy(): void {
    if (this.marketingStrategySubscription) {
      this.marketingStrategySubscription.unsubscribe();
    }

    if (this.communicationFrequencySubscription) {
      this.communicationFrequencySubscription.unsubscribe();
    }

    if (this.storyFrequencySubscription) {
      this.storyFrequencySubscription.unsubscribe();
    }

    if (this.marketingStaffSubscription) {
      this.marketingStaffSubscription.unsubscribe();
    }

    if (this.logoSubscription) {
      this.logoSubscription.unsubscribe();
    }

    if (this.brandingPolicySubscription) {
      this.brandingPolicySubscription.unsubscribe();
    }

    if (this.brochureSubscription) {
      this.brochureSubscription.unsubscribe();
    }

    if (this.websiteSubscription) {
      this.websiteSubscription.unsubscribe();
    }

    if (this.socialMediaSubscription) {
      this.socialMediaSubscription.unsubscribe();
    }

    if (this.newsletterSubscription) {
      this.newsletterSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csMarketingAndPromotionAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.marketingStrategySubscription = this.csMarketingAndPromotionAnswerService.$marketingStrategy.subscribe(answers => {
      if (answers) {
        this.marketingStrategyAnswers = answers.choices;
      }
    });

    this.communicationFrequencySubscription = this.csMarketingAndPromotionAnswerService.$communicationFrequency.subscribe(answers => {
      if (answers) {
        this.communicationFrequencyAnswers = answers.choices;
      }
    });

    this.storyFrequencySubscription = this.csMarketingAndPromotionAnswerService.$storyFrequency.subscribe(answers => {
      if (answers) {
        this.storyFrequencyAnswers = answers.choices;
      }
    });

    this.marketingStaffSubscription = this.csMarketingAndPromotionAnswerService.$marketingStaff.subscribe(answers => {
      if (answers) {
        this.marketingStaffAnswers = answers.choices;
      }
    });

    this.logoSubscription = this.csMarketingAndPromotionAnswerService.$logo.subscribe(answers => {
      if (answers) {
        this.logoAnswers = answers.choices;
      }
    });

    this.brandingPolicySubscription = this.csMarketingAndPromotionAnswerService.$brandingPolicy.subscribe(answers => {
      if (answers) {
        this.brandingPolicyAnswers = answers.choices;
      }
    });

    this.brochureSubscription = this.csMarketingAndPromotionAnswerService.$brochure.subscribe(answers => {
      if (answers) {
        this.brochureAnswers = answers.choices;
      }
    });

    this.websiteSubscription = this.csMarketingAndPromotionAnswerService.$website.subscribe(answers => {
      if (answers) {
        this.websiteAnswers = answers.choices;
      }
    });

    this.socialMediaSubscription = this.csMarketingAndPromotionAnswerService.$socialMedia.subscribe(answers => {
      if (answers) {
        this.socialMediaAnswers = answers.choices;
      }
    });

    this.newsletterSubscription = this.csMarketingAndPromotionAnswerService.$newsletter.subscribe(answers => {
      if (answers) {
        this.newsletterAnswers = answers.choices;
      }
    });
  }

}
