<app-table-filter
  [columns]="filterColumns"
  [rows]="cachedRows"
  (onChange)="onChangeData($event)"
></app-table-filter>

<app-mbl-table
  #table
  [columns]="columns"
  [rows]="rows"
></app-mbl-table>

<app-alert
  class="w-100"
  [id]="'reviewUserTable'"
></app-alert>

<ng-template #organisationTmpl let-row="row" let-value="value">
  <p class="m-0">
    {{truncateName.truncate(row.legalName, 40)}}
    <span
      *ngIf="!row.reviewStep || row.reviewStep === 'IN_REVIEW'"
      class="badge badge-pill badge-success">
      In Review
    </span>

    <span
      *ngIf="row.reviewStep === 'DECLINED'"
      class="badge badge-pill badge-danger">
      Declined
    </span>

    <span
      *ngIf="row.reviewStep === 'ASSESSMENT_SUBMITTED'"
      class="badge badge-pill badge-primary">
      Assessment Submitted
    </span>

    <span
      *ngIf="row.reviewStep === 'RECOMMENDED'"
      class="badge badge-pill badge-warning">
      Recommended
    </span>
  </p>
</ng-template>

<ng-template #provinceTmpl let-row="row" let-value="value">
  <p class="m-0">
    {{row.province | province | async}}
  </p>
</ng-template>

<ng-template #districtTmpl let-row="row" let-value="value">
  <p class="m-0">
    {{row.district | district | async}}
  </p>
</ng-template>

<ng-template #subDistrictTmpl let-row="row" let-value="value">
  <p class="m-0">
    {{row.subDistrict | subDistrict | async}}
  </p>
</ng-template>

<ng-template #currencyApplicationFunding let-row="row" let-value="value">
  <p class="m-0">
    {{row.applicationFunding | currency:'R'}}
  </p>
</ng-template>

<ng-template #currencyProjectedFunding let-row="row" let-value="value">
  <p class="m-0">
    {{row.projectedFunding | currency:'R'}}
  </p>
</ng-template>

<ng-template #coordinatorsTmpl let-row="row" let-value="value">
  <p class="m-0 ml-2">
    <span *ngIf="row.coordinator">{{row.coordinator}}</span>
    <span *ngIf="!row.coordinator" class="text-grey">unassigned</span>
    <app-assign-coordinator
      [(coordinator)]="row.coordinator"
      [orgId]="row.orgId"
      [windowId]="windowId"
    ></app-assign-coordinator>
  </p>
</ng-template>

<ng-template #scoreTmpl let-row="row" let-value="value">
  <div class="d-flex justify-content-center align-items-center">
    <p class="text-left my-0 mr-2">
      <button
        *ngIf="row.scoreDTO"
        type="button"
        class="btn btn-link"
        placement="left"
        [ngbPopover]="scoreInfo"
        [popoverClass]="'popover-width-700-light'"
      >
        {{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.totalScore)}} %
      </button>
    </p>

    <ng-template #scoreInfo>
      <div class="mr-auto">

        <h4 class="text-shadow">
          Capacity Spectrum Score
          <a class="btn btn-link float-right mr-1 p-0 m-0 mt-2 font-link" (click)="download()">
            Download
            <i class="fa fa-download"></i>
          </a>
        </h4>
      </div>

      <table id="report" class="table table-striped table-bordered">
        <thead class="bg-dark text-light">
        <tr>
          <td class="text-light">Elements</td>
          <td class="text-light">Status</td>
          <td class="text-light">Score</td>
          <td class="text-light">Percentage</td>
        </tr>
        </thead>
        <tbody>
        <!-- https://stackoverflow.com/questions/38582293/how-to-declare-a-variable-in-a-template-in-angular -->
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.organizationalStructure) as status">
          <td>Organisation Structure</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.organizationalStructure?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.organizationalStructure)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.humanResources) as status">
          <td>Human Resources</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.humanResources?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.humanResources)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.governance) as status">
          <td>Governance</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.governance?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.governance)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.financialManagement) as status">
          <td>Financial Management</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.financialManagement?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.financialManagement)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.financialSituation) as status">
          <td>Financial Situation</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.financialSituation?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.financialSituation)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.mne) as status">
          <td>M&E Reporting and Planning</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.mne?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.mne)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.marketingAndPromotion) as status">
          <td>Marketing and Promotion</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.marketingAndPromotion?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.marketingAndPromotion)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.networkAndLinkages) as status">
          <td>Network and Linkages</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.networkAndLinkages?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.networkAndLinkages)}} %</td>
        </tr>
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.innovationAndTechnology) as status">
          <td>Innovation and Technology</td>
          <td>
            <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
          </td>
          <td>{{row.scoreDTO.innovationAndTechnology?.score || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.innovationAndTechnology)}} %</td>
        </tr>
        </tbody>
        <tfoot class="bg-dark text-light">
        <tr *ngVar="capacitySpectrumUtil.getStatus(row.scoreDTO.totalScore) as status">
          <td class="text-light">Total</td>
          <td class="text-light"><span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span></td>
          <td class="text-light">{{row.scoreDTO.totalScore?.score || 0}}</td>
          <td class="text-light">{{scorePercentageUtil.scoreDTOAsPercentageString(row.scoreDTO.totalScore)}} %</td>
        </tr>
        </tfoot>
      </table>
    </ng-template>

  </div>
</ng-template>

<ng-template #appScoreTmpl let-row="row" let-value="value">
  <div class="d-flex justify-content-center align-items-center">
    <p class="text-left my-0 mr-2">
      <button
        *ngIf="row.appScore"
        type="button"
        class="btn btn-link"
        placement="left"
        [ngbPopover]="appScoreInfo"
        [popoverClass]="'popover-width-700-light'"
      >
        {{scorePercentageUtil.scoreDTOAsPercentageString(row.appScore)}} %
      </button>
    </p>

    <ng-template #appScoreInfo>
      <div class="mr-auto">

        <h4 class="text-shadow">
          Application Score
        </h4>
      </div>

      <table class="table table-striped table-bordered">
        <thead class="bg-dark text-light">
        <tr>
          <td class="text-light">Score</td>
          <td class="text-light">Total</td>
          <td class="text-light">Percentage</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{row.appScore?.score || 0}}</td>
          <td>{{row.appScore?.max || 0}}</td>
          <td>{{scorePercentageUtil.scoreDTOAsPercentageString(row.appScore)}} %</td>
        </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
</ng-template>


<ng-template #budgetTmpl let-row="row" let-value="value">
  <p class="m-0">
    {{row.budget | tierName }}
  </p>
</ng-template>

<ng-template #tableActionsTemplate let-row="row">
  <div class="dropdown btn-group">
    <a ngbDropdown class="nav-item dropdown">
      <div class="nav-link dropdown-toggle user-toggle" ngbDropdownToggle>
        <button class="table-action-btn view">
          <i class="fas fa-ellipsis-h"></i>
        </button>
      </div>

      <div ngbDropdownMenu class="dropdown-menu bg-dark text-light">
        <ng-container *authenticatedWithRole="['ROLE_ADMIN']">
          <a class="dropdown-item cursor-pointer text-light"
             [routerLink]="['/grant-window/list/admin/review', windowId, 'details', row.workflowId, 'organisation', row.orgId]"
             routerLinkActive="router-link-active">
            <i class="fas fa-user"></i>
            <p>Details</p>
          </a>
          <a
            class="dropdown-item cursor-pointer text-light"
            [routerLink]="['/grant-window/list/admin/review', windowId, 'work-plan-and-targets', row.workflowId, 'organisation', row.orgId]"
            routerLinkActive="router-link-active"
          >
            <i class="fas fa-user"></i>
            <p>Work Plan and Targets</p>
          </a>
        </ng-container>

        <ng-container *authenticatedWithRole="['ROLE_FUNDER', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']">
          <a class="dropdown-item cursor-pointer text-light"
             [routerLink]="['/grant-window/list/funder/review', windowId, 'details', row.workflowId, 'organisation', row.orgId]"
             routerLinkActive="router-link-active">
            <i class="fas fa-user"></i>
            <p>Details</p>
          </a>
          <a
            class="dropdown-item cursor-pointer text-light"
            [routerLink]="['/grant-window/list/funder/review', windowId, 'work-plan-and-targets', row.workflowId, 'organisation', row.orgId]"
            routerLinkActive="router-link-active"
          >
            <i class="fas fa-user"></i>
            <p>Work Plan and Targets</p>
          </a>
        </ng-container>
      </div>
    </a>
  </div>
</ng-template>
