import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-service-matrix-verification',
  templateUrl: './service-matrix-verification.component.html',
  styleUrls: ['./service-matrix-verification.component.scss']
})
export class ServiceMatrixVerificationComponent implements OnInit {

  @Input() serviceMatrixVerificationForm: FormGroup;
  @Input() isReadOnly: boolean;

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  constructor() { }

  ngOnInit(): void {
  }

}
