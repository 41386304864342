import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {ModalService} from '../../../../../shared/modules/shared-common/services/modal/modal.service';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {CancelConfirmHelperService} from '../../../../../shared/guards/cancel-confirm/cancel-confirm-helper.service';
import {
  CapacitySpectrum,
  CapacitySpectrumDTO,
  CapacitySpectrumScoreDTO,
  createCapacitySpectrum,
  createCapacitySpectrumForm
} from './store/capacity-spectrum.model';
import {CapacitySpectrumQuery} from './store/capacity-spectrum.query';
import {CapacitySpectrumService} from './store/capacity-spectrum.service';
import {CapacitySpectrumValidationService} from './validations/capacity-spectrum-validation.service';
import {SessionService} from '../../../../../shared/stores/session/session.service';
import * as html2pdf from 'html2pdf.js/dist/html2pdf';
import {ScorePercentageUtil} from '../../../../../shared/utils/score-percentage-util';
import {CapacitySpectrumUtil} from '../../../../../shared/utils/capacity-spectrum-util';

@Component({
  selector: 'app-capacity-spectrum',
  templateUrl: './capacity-spectrum.component.html',
  styleUrls: ['./capacity-spectrum.component.scss']
})
export class CapacitySpectrumComponent implements OnInit, OnChanges, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('saveBeforeLeaving', {static: true}) saveBeforeLeaving: TemplateRef<HTMLElement>;
  @ViewChild('pageTop', {static: true}) pageTop: ElementRef<HTMLElement>;
  @ViewChild('saveModal', {static: true}) saveModal: TemplateRef<HTMLElement>;

  @Input() fromReview: boolean;
  @Input() isReadOnly: boolean;
  @Input() selection = 'organizational structure';

  @Output() fetchedCapacityForm = new EventEmitter<FormGroup>();

  capacitySpectrum: CapacitySpectrum;
  capacitySpectrumForm: FormGroup;
  orgId: number;
  isAdmin: boolean;

  score: CapacitySpectrumScoreDTO;

  appSavedSubscription: Subscription;
  paramsSubscription: Subscription;

  hasSaved: boolean;
  warningDismissed = false;
  exitUrl: string;
  modalOptions: NgbModalOptions;
  saveModalOptions: NgbModalOptions;

  isLoading: Observable<boolean>;


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public validationService: CapacitySpectrumValidationService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private alertService: AlertService,
    private capacitySpectrumService: CapacitySpectrumService,
    private capacitySpectrumQuery: CapacitySpectrumQuery,
    public scorePercentageUtil: ScorePercentageUtil,
    public capacitySpectrumUtil: CapacitySpectrumUtil,
    private cancelConfirmHelper: CancelConfirmHelperService,
    private sessionService: SessionService) {
  }

  ngOnInit() {
    this.cancelConfirmHelper.mustConfirmLogout = true;
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.orgId = params.orgId;
    });

    this.appSavedSubscription = this.validationService.$capacitySaved.subscribe(saved => {
      this.hasSaved = saved;
    });

    this.isLoading = this.loadingService.getIsLoading();

    this.fetchCapacitySpectrum();

    this.modalOptions = {
      size: 'lg',
      centered: true,
      windowClass: 'modalWidth-50',
      keyboard: false,
      backdrop: 'static'
    };

    this.saveModalOptions = {
      centered: true,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selection && !changes.selection.firstChange) {
      this.changeSection(changes.selection.currentValue);
    }
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
    this.appSavedSubscription.unsubscribe();
    // If the user has not saved their orgCapacity, wipe out all validation errors on destroy.
    if (!this.hasSaved) {
      this.validationService.unValidateAll();
    }
    this.validationService.capacitySavedSource.next(false);

    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  // This function is being called from the `CancelConfirm` guard in `shared/guards`.
  openSaveModal() {
    if (!this.warningDismissed) {
      this.modalService.openBasicModal(this.saveBeforeLeaving, this.modalOptions);
    }
  }

  changeSection(activeSection) {
    if (activeSection) {
      this.selection = activeSection;
      this.pageTop.nativeElement.scrollTo({
        top: 0
      });
    }
  }

  submit() {
    if (this.capacitySpectrumForm.valid) {
      this.capacitySpectrumForm.controls.submitted.patchValue(true);
      this.save();
    } else {
      this.validationService.capacitySavedSource.next(true);
      this.alertService.setAlert('Please complete all required questions before submitting.', 'error', 'capacitySpectrum');
    }
  }

  save() {
    this.loadingService.setIsLoading(true);
    // create or update.
    this.capacitySpectrum = createCapacitySpectrum(this.capacitySpectrumForm.value);
    this.capacitySpectrum.id ? this.update() : this.create();
  }

  checkApplicationValidity() {
    this.validationService.validateEntity(this.capacitySpectrumForm.value, 'capacitySpectrum');
  }

  cancel() {
    this.router.navigateByUrl(`/capacity-spectrum/results/${this.orgId}`).then();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  stay() {
    this.cancelConfirmHelper.isLoggingOut = false;
    this.closeModal();
  }

  leaveApplication() {
    this.warningDismissed = true;
    this.closeModal();
    this.router.navigate([this.exitUrl]).then();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  // downloadPdf() {
  //   // DownloadPdfUtil.downloadPdf('orgCapacity', this.grantApplication, this.grantApplication.grantWorkflow.organisation);
  //   const content = [];
  //   content.push(
  //     DownloadPDFUtil.generateGrantWindowPDF(this.orgCapacity.grantWorkflow.grantWindow),
  //     DownloadPDFUtil.generateApplicationPDF(this.orgCapacity));
  //   DownloadPDFUtil.downloadPDF(this.orgCapacity.grantWorkflow.organisation, content);
  // }

  download() {
    const element = document.querySelector('#report');
    const opt = {
      jsPDF: {orientation: 'landscape'}
    };

    /* const worker = */
    html2pdf().set(opt).from(element).save();
  }

  hasNextSection() {
    // section: 'organizational structure' | 'human resources' | 'governance' | 'financial management' |
    // 'financial situation' | 'mne' | 'marketing and promotion' | 'network and linkages' | 'innovation' |
    // 'aspirational'
    return this.selection !== 'aspirational';
  }

  hasPreviousSection() {
    // section: 'organizational structure' | 'human resources' | 'governance' | 'financial management' |
    // 'financial situation' | 'mne' | 'marketing and promotion' | 'network and linkages' | 'innovation' |
    // 'aspirational'
    return this.selection !== 'organizational structure';
  }

  nextSection() {
    switch (this.selection) {
      case 'organizational structure': {
        this.changeSection('human resources');
        break;
      }
      case 'human resources': {
        this.changeSection('governance');
        break;
      }
      case 'governance': {
        this.changeSection('financial management');
        break;
      }
      case 'financial management': {
        this.changeSection('financial situation');
        break;
      }
      case 'financial situation': {
        this.changeSection('mne');
        break;
      }
      case 'mne': {
        this.changeSection('marketing and promotion');
        break;
      }
      case 'marketing and promotion': {
        this.changeSection('network and linkages');
        break;
      }
      case 'network and linkages': {
        this.changeSection('innovation');
        break;
      }
      case 'innovation': {
        this.changeSection('aspirational');
        break;
      }

    }
  }

  previousSection() {
    switch (this.selection) {
      case 'human resources': {
        this.changeSection('organizational structure');
        break;
      }
      case 'governance': {
        this.changeSection('human resources');
        break;
      }
      case 'financial management': {
        this.changeSection('governance');
        break;
      }
      case 'financial situation': {
        this.changeSection('financial management');
        break;
      }
      case 'mne': {
        this.changeSection('financial situation');
        break;
      }
      case 'marketing and promotion': {
        this.changeSection('mne');
        break;
      }
      case 'network and linkages': {
        this.changeSection('marketing and promotion');
        break;
      }
      case 'innovation': {
        this.changeSection('network and linkages');
        break;
      }
      case 'aspirational': {
        this.changeSection('innovation');
        break;
      }
    }
  }

  private fetchCapacitySpectrum() {
    this.loadingService.setIsLoading(true);
    // Fetch capacitySpectrum or create new
    if (this.orgId) {
      this.capacitySpectrumService.get(this.orgId).subscribe((result: CapacitySpectrumDTO) => {
        if (result && result.capacitySpectrum) {
          this.score = result.score;

          this.capacitySpectrumForm = createCapacitySpectrumForm(this.destroy$, this.formBuilder, result.capacitySpectrum);
          this.capacitySpectrum = createCapacitySpectrum(result.capacitySpectrum);
          // this.markFormGroupTouched(this.capacitySpectrumForm);
          this.validationService.validateAllEntities(this.capacitySpectrumForm);
          this.fetchedCapacityForm.emit(this.capacitySpectrumForm);
          this.loadingService.setIsLoading(false);
        } else {
          this.capacitySpectrumForm = createCapacitySpectrumForm(this.destroy$, this.formBuilder, {});
          this.capacitySpectrum = createCapacitySpectrum({});
          // this.markFormGroupTouched(this.capacitySpectrumForm);
          this.fetchedCapacityForm.emit(this.capacitySpectrumForm);
          this.loadingService.setIsLoading(false);
        }
      });
    } else {
      this.capacitySpectrumForm = createCapacitySpectrumForm(this.destroy$, this.formBuilder, {});
      this.capacitySpectrum = createCapacitySpectrum({});
      // this.markFormGroupTouched(this.capacitySpectrumForm);
      this.fetchedCapacityForm.emit(this.capacitySpectrumForm);
      this.loadingService.setIsLoading(false);
    }
  }

  private create() {
    this.capacitySpectrumService.create(this.capacitySpectrum, this.orgId).subscribe((entity: CapacitySpectrumDTO) => {
      this.afterSaveActions(entity);
    }, () => {
      this.alertService.setAlert('Failed to save Capacity', 'error', 'capacitySpectrum');
    });
  }

  private update() {
    this.capacitySpectrumService.update(this.capacitySpectrum).subscribe((entity: CapacitySpectrumDTO) => {
      this.afterSaveActions(entity);
    }, () => {
      this.alertService.setAlert('Failed to save Capacity', 'error', 'capacitySpectrum');
    });
  }

  private afterSaveActions(entity: CapacitySpectrumDTO) {
    this.score = entity.score;

    this.capacitySpectrum = createCapacitySpectrum(entity.capacitySpectrum);
    this.capacitySpectrumForm.patchValue(this.capacitySpectrum);

    this.validationService.capacitySavedSource.next(true);
    this.loadingService.setIsLoading(false);

    const submitted = this.capacitySpectrumForm.value.submitted;
    if (submitted) {
      // update User
      this.sessionService.setSubmittedCapacitySpectrumOnUser();
    } else {
      // show popup
      this.modalService.openBasicModal(this.saveModal, this.saveModalOptions);
    }

    this.sessionService.routeHome();
  }
}
