<div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <p class="text-secondary disabled">MENU</p>
    </div>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/dashboard"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/user/list"
      >Implementers</a>
    </li>

    <li [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/funder-management/list"
      >Investors</a>
    </li>

    <li [ngbNavItem]="4">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/grant-window/external-grants"
      >Add External Funding</a>
    </li>

    <li>
      <ngb-accordion activeIds="ngb-panel-0">
        <ngb-panel title="Investment Opportunities" class="dropdown-toggle">
          <ng-template ngbPanelContent>
            <div [ngbNavItem]="7">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/admin/new"
              >New</a>
            </div>
            <div [ngbNavItem]="8">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/admin/active"
              >Current</a>
            </div>
            <div [ngbNavItem]="9">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/admin/shortlist"
              >Shortlist</a>
            </div>
            <div [ngbNavItem]="10">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/admin/review"
              >Review</a>
            </div>
            <div [ngbNavItem]="11">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/create"
              >Create Investment Opportunity</a>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </li>

    <li>
      <ngb-accordion activeIds="ngb-panel-1">
        <ngb-panel title="Forms" class="dropdown-toggle">
          <ng-template ngbPanelContent>
            <div [ngbNavItem]="12">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/form-builder/template/list"
              >
                Template Forms
              </a>
            </div>
            <div [ngbNavItem]="13">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/form-builder/list"
              >
                Application Forms
              </a>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </li>

  </ul>
</div>
