import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MblTableComponent} from '../../../../shared/modules/data-table/components/mbl-table/mbl-table.component';
import {Subscription} from 'rxjs';
import {FormBuilderModel} from '../../components/form-builder-list/form-builder/store/form-builder.model';
import {Column} from '../../../../shared/modules/data-table/models/mbl-table.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {FormBuilderService} from '../../components/form-builder-list/form-builder/store/form-builder.service';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';
import {FormBuilderQuery} from '../../components/form-builder-list/form-builder/store/form-builder.query';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit, OnDestroy {

  @ViewChild('table', {static: true}) table: MblTableComponent;
  @ViewChild('actionTmpl', {static: true}) actionTmpl: TemplateRef<any>;
  @ViewChild('funderTmpl', {static: true}) funderTmpl: TemplateRef<any>;

  formListSubscription: Subscription;

  rows: FormBuilderModel[];
  cachedRows: FormBuilderModel[];
  columns: Column[];
  loading: boolean;
  fromForm: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public sessionQuery: SessionQuery,
              private formBuilderService: FormBuilderService,
              private alertService: AlertService,
              private formBuilderQuery: FormBuilderQuery) {
  }

  ngOnInit() {
    this.fromForm = this.route.snapshot.params.link;

    this.loading = true;
    this.populateColumns();
    this.fetch();
  }

  ngOnDestroy() {
    if (this.formListSubscription) {
      this.formListSubscription.unsubscribe();
    }
  }

  fetch() {
    this.formBuilderService.getTemplates().subscribe(() => {
      this.loading = false;
    }, () => {
      this.loading = false;
      this.alertService.setAlert('Failed to get Forms', 'error', 'formBuilderList');
    });

    this.formListSubscription = this.formBuilderQuery.getTemplateListObservable().subscribe(list => {
      if (list && list.length) {
        this.rows = list;
        this.cachedRows = [...this.rows];
      }
    });
  }

  private populateColumns() {
    this.columns = [
      {name: 'Form Name', prop: 'name', width: '40%', sort: true},
      {name: 'Actions', prop: 'action', width: '20%', cellTemplate: this.actionTmpl},
    ];
  }

  delete(row) {
    this.formBuilderService.delete(row.id).pipe(take(1)).subscribe(() => {
      this.fetch();
    }, () => {
      this.alertService.setAlert('Failed to delete form', 'error', 'formBuilderList');
    });
  }

  cloneAsTemplate(id: number) {
    this.loading = true;
    this.formBuilderService.clone(id).pipe(take(1)).subscribe(response => {
      this.loading = false;
      this.router.navigateByUrl(`/form-builder/template/details/${response.id}`).then();
    });
  }

  convertToForm(id: number) {
    this.loading = true;
    this.formBuilderService.clone(id, true).pipe(take(1)).subscribe(response => {
      this.loading = false;
      this.router.navigateByUrl(`/form-builder/details/${response.id}`).then();
    });
  }
}
