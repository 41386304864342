import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const peopleTargetedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'people living with HIV',
    value: 'peopleWithHiv'
  },
  {
    label: 'orphans and vulnerable children',
    value: 'b'
  },
  {
    label: `HIV positive children`,
    value: 'hivChildren'
  }
];

export const frequencyOfVisitsAnswers: Array<ChoiceInputItem> = [
  {
    label: 'daily',
    value: 'daily'
  },
  {
    label: 'weekly',
    value: 'weekly'
  },
  {
    label: `bi-weekly`,
    value: 'biWeekly'
  },
  {
    label: `monthly`,
    value: 'monthly'
  },
  {
    label: 'bi-monthly',
    value: 'biMonthly'
  },
  {
    label: 'quarterly',
    value: 'quarterly'
  },
];
