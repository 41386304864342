<!--<section [class]="fromGrantReview ? '' : 'col-lg-10 col-sm-12 mx-auto'">-->

  <div class="wrapper esx-page">

    <div *ngIf="!fromGrantReview" class="esx-header">
      <div class="flex-center h-100">
        <div class="card header-card">
          <div class="card-body p-0">
            <div class="header">
              <div class="header-title">
                <h4 class="text-primary text-center">
                  Eligibility Funding Request for {{selectedGrantWindow.name}}
                  {{selectedGrantWindow.year}} from {{selectedGrantWindow.funder | funderName}}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center">
        Application closes on {{selectedGrantWindow.projectedApplicationEndDate | date:'medium'}} SAST
      </p>
      <!-- Breadcrumbs -->
      <!--    <div class="breadcrumb-container">-->
      <!--      <app-breadcrumb></app-breadcrumb>-->
      <!--    </div>-->

    </div>

    <div class="w-100 mx-auto" [class.esx-content]="!fromGrantReview">
      <div class="card p-1" [class.mt-2]="!fromGrantReview">
          <h4 class="text-center" *ngIf="fromGrantReview">
            <span class="text-capitalize">Eligibility</span>
          </h4>
          <hr class="w-75 mx-auto border-primary" *ngIf="fromGrantReview">
          <app-spinner [loadingBool]="loading">
            <ng-container *ngIf="profileForm && districts">
              <form [formGroup]="profileForm" (ngSubmit)="save(true)" class="w-95 mx-auto">
                <app-alert
                  class="w-100"
                  [id]="'eligibility'"
                ></app-alert>
                <fieldset>

                  <!-- TODO split into columns -->
                  <div>
                    <app-input-checkbox
                      [label]="'For the application you are about to submit, ' +
                     'please check which district(s) you area planning to implement the project in:'"
                      [id]="'implementingDistricts'"
                      [choices]="districts"
                      [isRequired]="true"
                      [theme]="theme"
                      [style]="style"
                      [isGrey]="false"
                      [hasBorder]="false"
                      [isReadOnly]="isReadOnly"
                      formControlName="implementingDistricts"
                    ></app-input-checkbox>
                  </div>

                  <app-input-radio
                    [label]="'Are you currently a direct recipient of funding from any U.S. Government source?'"
                    [name]="'directRecipientOfUsGovernment'"
                    [id]="'directRecipientOfUsGovernment'"
                    [isRequired]="true"
                    [choices]="toggleOptions"
                    [theme]="theme"
                    [style]="style"
                    [isGrey]="false"
                    [hasBorder]="false"
                    [showDisabledValue]="true"
                    [isReadOnly]="isReadOnly"
                    formControlName="directRecipientOfUsGovernment"
                  ></app-input-radio>

                  <app-input-radio
                    [label]="'Are you currently a sub-recipient of any PEPFAR funding?'"
                    [name]="'subRecipientOfPepfarFunding'"
                    [id]="'subRecipientOfPepfarFunding'"
                    [choices]="toggleOptions"
                    [isRequired]="true"
                    [theme]="theme"
                    [style]="style"
                    [isGrey]="false"
                    [hasBorder]="false"
                    [showDisabledValue]="true"
                    [isReadOnly]="isReadOnly"
                    formControlName="subRecipientOfPepfarFunding"
                  ></app-input-radio>

                  <app-input-radio
                    [label]="'Are you serving HIV-affected/infected populations?'"
                    [name]="'servingHiv'"
                    [id]="'servingHiv'"
                    [isRequired]="true"
                    [theme]="theme"
                    [style]="style"
                    [isGrey]="false"
                    [hasBorder]="false"
                    [showDisabledValue]="true"
                    [choices]="toggleOptions"
                    [isReadOnly]="isReadOnly"
                    formControlName="servingHiv"
                  ></app-input-radio>

                </fieldset>

                <div class="alert alert-danger w-75 mx-auto my-2" *ngIf="!fromGrantReview">
                  <h6 class="m-0">Please ensure you have answered all the above questions correctly before
                    proceeding</h6>
                </div>

                <div class="d-flex justify-content-end" *ngIf="!fromGrantReview">
                  <button class="btn btn-outline-primary mr-2" [disabled]="isReadOnly" (click)="save()">Save</button>
                  <button class="btn btn-outline-primary" [disabled]="isReadOnly" type="submit">Submit</button>
                </div>

              </form>
            </ng-container>
          </app-spinner>
<!--        </div>-->
      </div>
    </div>
  </div>


  <ng-template #saveBeforeLeaving>
    <div class="col-sm-6 col-lg-10 mx-auto m-3">
      <h3 class="text-danger text-center">ATTENTION!</h3>
      <p class="text-center">You have not saved the Eligibility section, are you sure you wish to exit?</p>
      <hr class="dropdown-divider">
      <div class="w-100 d-flex justify-content-end">
        <button class="btn btn-danger mx-2" type="button" (click)="leaveApplication()">Exit Anyway</button>
        <button class="btn btn-success mx-2" type="button" autofocus (click)="closeModal()">Stay</button>
      </div>
    </div>
  </ng-template>
<!--</section>-->
