import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsFinancialManagementAnswerService} from './services/cs-financial-management-answer.service';
import {take} from 'rxjs/operators';
import {booleanChoices} from 'app/modules/constants/boolean-answers.const';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-financial-management',
  templateUrl: './cs-financial-management.component.html',
  styleUrls: ['./cs-financial-management.component.scss']
})
export class CsFinancialManagementComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() financialManagementForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  booleanChoices = booleanChoices;

  // Question Answers.
  budgetCreationAnswers;
  financialPolicyAnswers;
  financialPolicyElementAnswers;
  assetInventoryAnswers;
  pettyCashAnswers;
  timeSheetAnswers;
  accountingSystemAnswers;
  financialTrackingAnswers;
  financialControlAnswers;
  paymentMethodAnswers;
  durationOfFinancialRecordsAnswers;
  annualAuditAnswers;
  annualFinancialSystemAnswers;
  financialManagementTeamAnswers;
  headOfFinanceQualificationAnswers;

  budgetCreationSubscription: Subscription;
  financialPolicySubscription: Subscription;
  financialPolicyElementSubscription: Subscription;
  assetInventorySubscription: Subscription;
  pettyCashSubscription: Subscription;
  timeSheetSubscription: Subscription;
  accountingSystemSubscription: Subscription;
  financialTrackingSubscription: Subscription;
  financialControlSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  durationOfFinancialRecordsSubscription: Subscription;
  annualAuditSubscription: Subscription;
  annualFinancialSystemSubscription: Subscription;
  financialManagementTeamSubscription: Subscription;
  headOfFinanceQualificationSubscription: Subscription;
  childFormTouched: boolean;


  constructor(private loadingService: LoadingService,
              private csFinancialManagementAnswerService: CsFinancialManagementAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.financialManagementForm.touched;

    this.populateAnswers();
  }

  public checkOther(value: string | boolean, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy(): void {
    if (this.budgetCreationSubscription) {
      this.budgetCreationSubscription.unsubscribe();
    }

    if (this.financialPolicySubscription) {
      this.financialPolicySubscription.unsubscribe();
    }

    if (this.financialPolicyElementSubscription) {
      this.financialPolicyElementSubscription.unsubscribe();
    }

    if (this.assetInventorySubscription) {
      this.assetInventorySubscription.unsubscribe();
    }

    if (this.pettyCashSubscription) {
      this.pettyCashSubscription.unsubscribe();
    }

    if (this.timeSheetSubscription) {
      this.timeSheetSubscription.unsubscribe();
    }

    if (this.accountingSystemSubscription) {
      this.accountingSystemSubscription.unsubscribe();
    }

    if (this.financialTrackingSubscription) {
      this.financialTrackingSubscription.unsubscribe();
    }

    if (this.financialControlSubscription) {
      this.financialControlSubscription.unsubscribe();
    }

    if (this.paymentMethodSubscription) {
      this.paymentMethodSubscription.unsubscribe();
    }

    if (this.durationOfFinancialRecordsSubscription) {
      this.durationOfFinancialRecordsSubscription.unsubscribe();
    }

    if (this.annualAuditSubscription) {
      this.annualAuditSubscription.unsubscribe();
    }

    if (this.annualFinancialSystemSubscription) {
      this.annualFinancialSystemSubscription.unsubscribe();
    }

    if (this.financialManagementTeamSubscription) {
      this.financialManagementTeamSubscription.unsubscribe();
    }

    if (this.headOfFinanceQualificationSubscription) {
      this.headOfFinanceQualificationSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csFinancialManagementAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.budgetCreationSubscription = this.csFinancialManagementAnswerService.$budgetCreation.subscribe(answers => {
      if (answers) {
        this.budgetCreationAnswers = answers.choices;
      }
    });

    this.financialPolicySubscription = this.csFinancialManagementAnswerService.$financialPolicy.subscribe(answers => {
      if (answers) {
        this.financialPolicyAnswers = answers.choices;
      }
    });

    this.financialPolicyElementSubscription = this.csFinancialManagementAnswerService.$financialPolicyElement.subscribe(answers => {
      if (answers) {
        this.financialPolicyElementAnswers = answers.choices;
      }
    });

    this.assetInventorySubscription = this.csFinancialManagementAnswerService.$assetInventory.subscribe(answers => {
      if (answers) {
        this.assetInventoryAnswers = answers.choices;
      }
    });

    this.pettyCashSubscription = this.csFinancialManagementAnswerService.$pettyCash.subscribe(answers => {
      if (answers) {
        this.pettyCashAnswers = answers.choices;
      }
    });

    this.timeSheetSubscription = this.csFinancialManagementAnswerService.$timeSheet.subscribe(answers => {
      if (answers) {
        this.timeSheetAnswers = answers.choices;
      }
    });

    this.accountingSystemSubscription = this.csFinancialManagementAnswerService.$accountingSystem.subscribe(answers => {
      if (answers) {
        this.accountingSystemAnswers = answers.choices;
      }
    });

    this.financialTrackingSubscription = this.csFinancialManagementAnswerService.$financialTracking.subscribe(answers => {
      if (answers) {
        this.financialTrackingAnswers = answers.choices;
      }
    });

    this.financialControlSubscription = this.csFinancialManagementAnswerService.$financialControl.subscribe(answers => {
      if (answers) {
        this.financialControlAnswers = answers.choices;
      }
    });

    this.paymentMethodSubscription = this.csFinancialManagementAnswerService.$paymentMethod.subscribe(answers => {
      if (answers) {
        this.paymentMethodAnswers = answers.choices;
      }
    });

    this.durationOfFinancialRecordsSubscription = this.csFinancialManagementAnswerService.$durationOfFinancialRecords.subscribe(answers => {
      if (answers) {
        this.durationOfFinancialRecordsAnswers = answers.choices;
      }
    });

    this.annualAuditSubscription = this.csFinancialManagementAnswerService.$annualAudit.subscribe(answers => {
      if (answers) {
        this.annualAuditAnswers = answers.choices;
      }
    });

    this.annualFinancialSystemSubscription = this.csFinancialManagementAnswerService.$annualFinancialSystem.subscribe(answers => {
      if (answers) {
        this.annualFinancialSystemAnswers = answers.choices;
      }
    });

    this.financialManagementTeamSubscription = this.csFinancialManagementAnswerService.$financialManagementTeam.subscribe(answers => {
      if (answers) {
        this.financialManagementTeamAnswers = answers.choices;
      }
    });

    this.headOfFinanceQualificationSubscription = this.csFinancialManagementAnswerService.$headOfFinanceQualification.subscribe(answers => {
      if (answers) {
        this.headOfFinanceQualificationAnswers = answers.choices;
      }
    });
  }
}
