import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmailStore} from './email.store';
import {Mail, createMail} from './email.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class EmailService {

  constructor(private emailStore: EmailStore,
              private http: HttpClient,
              private formBuilder: FormBuilder,
              private toastr: ToastrService) {
  }

  regretTemplate(windowId: number): Observable<Mail> {
    return this.getRecipients(windowId)
      .pipe(map(recipientList => {
        const recipients = recipientList.map(item => item.name);
        return createMail({
          recipients,
          subject: '[Social Capital Exchange] Regret Letter',
          message: `Dear Applicant

          Thank you for your recent application to the Social Capital Exchange Program.
          We appreciate the work that you are doing and the fact that you took the time to apply.
          However, we received hundreds of applications and unfortunately, your application was not successful.

          In an effort to assist all applicants continue to develop and improve,
          we have included some links to resources and information which you might find useful:
          1. https://socialcapitalexchange.org/resources/Capacitate-Sustainability-Handbook.pdf
          2. https://socialcapitalexchange.org/resources/Engage-Monitoring-and-Evaluation-Handbook.pdf
          3. https://www.hivsharespace.net/about
          4. http://www.stigmaindex.org/south-africa
          5. https://www.measureevaluation.org/our-work/ovc/monitoring-outcomes-of-pepfar-orphans-and-vulnerable-children-programs

          Since you are registered on the Social Capital Exchange website, https://socialcapitalexchange.org,
          you will automatically be notified when applications open for the next funding cycle.
          Feel free to contact us if you have any further questions regarding your recent application and/or
          if you would like a copy of your completed application form. We wish you success in your continued work.

Kind Regards,
Social Capital Exchange Grants Team`
        });
      }, () => {
        // TODO: Find out what's happening here
        // this.toastr.error('Failed to fetch mail recipients', 'Error');
      }));
  }

  sendEmail(email: Mail): Observable<Mail> {
    const url = `${environment.apiUrl}/email`;
    return this.http.put<Mail>(url, email);
  }

  private getRecipients(windowId: number): Observable<any> {
    const url = `${environment.apiUrl}/grant-window/${windowId}/non-final-shortlist-recipients`;
    return this.http.get<any>(url);
  }
}
