<!--<section class=" col-lg-8 col-sm-12 mx-auto">-->
  <div class="wrapper esx-page">

    <div class="esx-header">
      <div class="flex-center h-100">
        <div class="card header-card">
          <div class="card-body p-0">
            <div class="header">
              <div class="header-title">
                <h4 class="text-primary text-center">
                  Application Agreement
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-95 mx-auto">
      <div class="card mt-4">

        <!--  <h4 class="text-center card-title mt-3">APPLICATION AGREEMENT</h4>-->
        <!--  <div class="card">-->
        <div class="card-body bg-blue-50">
          <p>In order to apply to the HIV and AIDS Community Grants Program, you need to
            complete the online application form. Please note, you must complete the online
            application in order to be considered for funding, no hand delivered, posted
            or emailed applications using an old or printed version of the application
            will be accepted. The only way to apply to this program is through this online
            application.

          </p>

          <p>
            To complete the online application form, you need to answer the questions on the
            following pages. All questions that are outlined in red are mandatory. <b><u>You will
            not be able to submit your application until all of the mandatory questions have
            been answered</u></b>. To help ensure you complete all mandatory questions, there is a
            color-coded progress bar at the top of the application which shows the seven
            sections that need to be completed:
          </p>
          <ul>
            <li>If the section is white, it means you have not yet started the section.</li>
            <li>If the section is blue it means that it is currently active/being worked on.</li>
            <li>
              If the section is red, it means that there are errors/mandatory questions which
              have not been answered.
            </li>
            <li>
              If the section is green, it means that it is valid and complete/all mandatory
              questions have been answered.
            </li>
          </ul>


          <p>
            You can move back and forth between sections as much as you like. Click on the
            ‘Save Application’ button at the bottom of the screen at any time and all of
            the information you have entered will be saved. After saving, you can close
            the application at any time and all of your information will appear the next
            time you log on. <b><u>The deadline for applications is 17:00 SAST on April 15, 2020</u></b>,
            therefore, your application must be fully complete AND submitted by April 15,
            2020. You will not be able to access your application after the deadline.
          </p>

          <p>
            As you complete the application form, if you need additional help, please use
            the information icons where present, or else click on the “home” button at the
            top of the page to navigate back to the “frequently asked questions” for
            additional assistance and explanation.
          </p>

          <p>
            There are seven sections in the application which you will need to complete.
            The sections are:
          </p>

          <ul>
            <li>Organization structure</li>
            <li>Service delivery</li>
            <li>Community relationships</li>
            <li>Financial management</li>
            <li>Financial situation</li>
            <li>Funding request</li>
            <li>Supporting documentation</li>
          </ul>

          <p>
            The last section of the application will ask you to upload required supporting
            documents. All of the documents listed below are required and therefore, you will
            not be able to submit your application, until you have uploaded these documents.
            Please note, you will be able to upload files up to 5MB in size, so you will need
            to reduce any large files. The required documents you will need to include with
            the application are:

          </p>

          <ul>
            <li>
              A short overview of your organisation's services and history (maximum of two pages)
            </li>
            <li>
              A copy of your NPO registration from the Department of Social Development
            </li>
            <li>
              Quotes for equipment, supplies and training requested in the budget and/or cost
              calculations for items that are not quotable such as transport and airtime
            </li>
            <li>
              One letter of reference from community stakeholders/partners who are not formally
              part of your project or organisation
            </li>

            <li>
              One letter of reference from a South African Government Department (this can be at
              a local, district or provincial level)
            </li>
            <li>
              Completed Application for Federal Assistance SF-424 Form - click
              <a
                href="https://apply07.grants.gov/apply/forms/sample/SF424_2_1-V2.1.pdf"
                target="_blank"
              >
                here
              </a>
              to download this form now
            </li>
          </ul>

          <p>Important: In the application, the first question in the ‘organization structure’
            section asks you for your organization’s DUNS number. A DUNS number is a unique
            nine-digit identification number that is assigned to your organization and it is
            required for any organization applying for funding from the U.S. Government. Please
            see the ‘frequently asked questions’ page, as the question ‘How do I get a DUNS
            number?” provides step-by-step instructions for how to apply for a DUNS number.
            Under normal circumstances a DUNS number is issued within 1-2 business days when
            using the online process, but we recommend applying for your DUNS number as soon
            as possible as your application will be ineligible if you do not have a DUNS number.</p>

          <input
            type="checkbox"
            id="agreement"
            [(ngModel)]='agreed'
            #myAgreement='ngModel'
            required
          >
          <span>
        I understand the requirements to apply to the HIV and AIDS Community Grants Program
        and I understand that I will not be able to complete and submit an application form
        until I complete the online application form and attach the required documents that
        are listed here. I also understand that the deadline to submit an application is 17:00 SAST
        on April 15, 2020.
      </span>
          <br>

          <app-alert
            class="w-100"
            [id]="'appAgreement'"
          ></app-alert>

          <button class="btn btn-success" [disabled]="myAgreement.invalid" (click)="submit()">Apply Now</button>
        </div>
      </div>
    </div>
  </div>
<!--</section>-->
