import {Component, Input, OnInit} from '@angular/core';
import {ChartData} from '../models/charts.model';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-cards-chart',
  templateUrl: './cards-chart.component.html',
  styleUrls: ['./cards-chart.component.scss']
})
export class CardsChartComponent implements OnInit {
  @Input() view: any[];
  @Input() data: Observable<ChartData<number>[]>;

  chartData: { name: string, value: number }[] = [];
  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  colorScheme = {
    domain: [
      '#55902a',
      '#0091ea',
      '#e65600'
    ]
  };

  constructor() {
  }

  ngOnInit(): void {
    this.data.subscribe(data => {
      this.chartData = [...data];
    });
  }

  format(data: ChartData) {
    const value = data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return new Intl.NumberFormat('en-ZA', {
    //   style: 'currency',
    //   currency: 'ZAR',
    // }).format(data.value);

    return `R ${value}`;
  }
}
