import {Component, OnInit} from '@angular/core';
import {SessionQuery} from '../../../../../shared/stores/session/session.query';
import {
  CapacitySpectrumDTO,
  CapacitySpectrumScoreDTO,
} from '../capacity-spectrum/store/capacity-spectrum.model';
import {CapacitySpectrumService} from '../capacity-spectrum/store/capacity-spectrum.service';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SessionService} from '../../../../../shared/stores/session/session.service';
import * as html2pdf from 'html2pdf.js/dist/html2pdf';
import {ScorePercentageUtil} from '../../../../../shared/utils/score-percentage-util';
import {CapacitySpectrumUtil} from '../../../../../shared/utils/capacity-spectrum-util';


@Component({
  selector: 'app-capacity-spectrum-results',
  templateUrl: './capacity-spectrum-results.component.html',
  styleUrls: ['./capacity-spectrum-results.component.scss']
})
export class CapacitySpectrumResultsComponent implements OnInit {

  isLoading: Observable<boolean>;
  organisationId: number;
  score: CapacitySpectrumScoreDTO;

  chartOptions: any = {
    legend: {
      position: 'right',
      labels: {
        display: true,
        fontSize: 16
      }
    },
    tooltips: {enabled: true},

    // TODO see https://emn178.github.io/chartjs-plugin-labels/samples/demo/
    //  to add labels in the pies (https://github.com/emn178/chartjs-plugin-labels)
    // labels: {
    //   render: 'value'
    // }

    // TODO see https://stackblitz.com/edit/doughnut-chart-6iuguq?file=app%2Fapp.module.ts
    //  to add labels in the pies
    // pieceLabel: {
    //   render(args) {
    //     console.log('in here');
    //     const label = args.label;
    //     const value = args.value;
    //     return value;
    //   }
    // }
  };
  chartLabels = [
    'Organisational Structure', 'Human Resources', 'Governance', 'Financial Management',
    'Financial Situation', 'M&E', 'Marketing and Promotion', 'Network and Linkages', 'Innovation and Technology'
  ];
  chartColors: Array<any> = [
    {
      backgroundColor: [
        'rgb(230, 86, 0)', // orange warning = Organisational Structure
        'rgb(230, 150, 0)', // another orange shade = Human Resources
        'rgb(0, 145, 234)', // blue info = Governance
        'rgb(85, 144, 42)', // green success = Financial Management
        'rgb(178, 7, 7)', // red danger = Financial Situation
        'rgb(7, 7, 178)', // dark blue = M&E
        'rgb(7, 178, 7)', // another green = Marketing and Promotion
        'rgb(150, 230, 0)', // light green = Network and Linkages
        'rgb(186, 0, 230)', // purple = Innovation and Technology
      ]
    }
  ];
  chartData = [];

  constructor(private route: ActivatedRoute,
              private sessionQuery: SessionQuery,
              private sessionService: SessionService,
              private alertService: AlertService,
              private loadingService: LoadingService,
              public scorePercentageUtil: ScorePercentageUtil,
              public capacitySpectrumUtil: CapacitySpectrumUtil,
              private capacitySpectrumService: CapacitySpectrumService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    const orgId = this.route.snapshot.params.orgId;
    this.organisationId = orgId || this.sessionQuery.getUser().organisationId;

    this.fetchCapacitySpectrum();
  }

  private fetchCapacitySpectrum() {
    this.loadingService.setIsLoading(true);
    if (this.organisationId) {
      this.capacitySpectrumService.get(this.organisationId).subscribe((result: CapacitySpectrumDTO) => {
        if (result && result.capacitySpectrum) {
          this.score = result.score;
          this.populateChart();
          this.loadingService.setIsLoading(false);
        } else {
          this.alertService.setAlert('Failed to fetch Capacity Spectrum Results', 'error', 'spectrum');
          this.loadingService.setIsLoading(false);
        }
      });
    } else {
      this.alertService.setAlert('Failed to fetch Capacity Spectrum Results, no profile found', 'error', 'spectrum');
      this.loadingService.setIsLoading(false);
    }
  }

  download() {
    const element = document.querySelector('#report');
    const opt = {
      jsPDF: {orientation: 'landscape'}
    };

    /* const worker = */
    html2pdf().set(opt).from(element).save();
  }

  private populateChart() {
    this.chartData = [
      this.score.organizationalStructure.score,
      this.score.humanResources.score,
      this.score.governance.score,
      this.score.financialManagement.score,
      this.score.financialSituation.score,
      this.score.mne.score,
      this.score.marketingAndPromotion.score,
      this.score.networkAndLinkages.score,
      this.score.innovationAndTechnology.score
    ];
  }

  routeHome() {
    this.sessionService.routeHome();
  }
}
