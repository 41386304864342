import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertConfig, AlertGlobalConfig, AlertMessage, AlertType, createAlertConfig} from '../models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  get globalConfig(): AlertGlobalConfig {
    return this._globalConfig;
  }

  set globalConfig(value: AlertGlobalConfig) {
    this._globalConfig = value;
  }

  private message = new BehaviorSubject<AlertMessage>(undefined);
  protected _globalConfig: AlertGlobalConfig = {
    defaultTimeout: 4000,
    autoDismiss: true,
    maxAlertsOpen: 1
  };

  constructor() {
  }

  getAlert(): Observable<AlertMessage> {
    return this.message.asObservable();
  }

  setAlert(message: string, alertType: AlertType, id: string, config?: AlertConfig) {
    config = createAlertConfig({...config});
    const alert = {message, alertType, id, config};
    this.message.next(alert);
  }

  resetAlert() {
    this.message.next(undefined);
  }
}
