import {ChoiceInputItem} from '../../shared/modules/form-elements/models/form-elements.model';

export const profileCategoryAnswers: ChoiceInputItem[] = [
  {
    label: 'Early Childhood Development (Aged 1 to 7)',
    value: 'ECD'
  },
  {
    label: 'Children (Aged 7 to 18)',
    value: 'children'
  },
  {
    label: 'Youth (Aged 18 to 30)',
    value: 'youth'
  },
  {
    label: 'Elderly and Aged Care (Aged 60+)',
    value: 'elderly'
  },
  {
    label: 'Employment and Up-skilling',
    value: 'employment'
  },
  {
    label: 'Hospice',
    value: 'hospice'
  },
  {
    label: 'HIV/AIDs',
    value: 'HIV/AIDs'
  },
  {
    label: 'COVID19 Relief Support',
    value: 'COVID19'
  },
  {
    label: 'Other Communicable Disease (Eg: TB, Malaria, etc)',
    value: 'communicableDisease'
  },
  {
    label: 'Other Illness (Eg: Cancer, Diabetes, Epilispsy, Rare Diseases etc)',
    value: 'otherIllness'
  },
  {
    label: 'Food Security',
    value: 'foodSecurity'
  },
  {
    label: 'Gender Based Violence',
    value: 'GBV'
  },
  {
    label: 'LGBTQ+',
    value: 'LGBTQ+'
  },
  {
    label: 'Human Rights',
    value: 'humanRights'
  },
  {
    label: 'Environment and Wildlife',
    value: 'environmentAndWildlife'
  },
  {
    label: 'Animal Cruelty',
    value: 'animalCruelty'
  },
  {
    label: 'Safe  and Clean Water',
    value: 'safeCleanWater'
  },
  {
    label: 'SME Organisation',
    value: 'SME'
  },
];

export const organisationTypeAnswers: ChoiceInputItem[] = [
  {
    label: 'Community Based Organisation / Voluntary Association',
    value: 'communityBasedOrganisation'
  },
  {
    label: 'Faith Based Organisation',
    value: 'faithBasedOrganisation'
  },
  {
    label: 'Registered Not-for-Profit Organisation (NPO)',
    value: 'NPO'
  },
  {
    label: 'Voluntary Association',
    value: 'voluntary'
  },
  {
    label: 'Registered Not for Profit Company (Section 21)',
    value: 'npoSection21'
  },
  {
    label: 'Registered Early Childhood Development Centre',
    value: 'earlyChildhood'
  },
  {
    label: 'Social Enterprise (For Profit)',
    value: 'socialEnterprise'
  },
  {
    label: 'SME - Social enterprise',
    value: 'SME_SOCIAL'
  },
  {
    label: 'SME - Commercial enterprise',
    value: 'SME_COMMERCIAL'
  }
];
