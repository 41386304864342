import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrganisationRoutingModule} from './organisation-routing.module';
import {OrganisationProfileComponent} from './components/organisation-profile/organisation-profile.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {EditProfileComponent} from './components/edit-profile/edit-profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from '../../../shared/modules/form-elements/form-elements.module';
import {OrgProfileContainerComponent} from './components/org-profile-container/org-profile-container.component';
import {SharedCommonModule} from '../../../shared/modules/shared-common/shared-common.module';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../../shared/modules/alert/alert.module';


@NgModule({
  declarations: [
    OrganisationProfileComponent,
    EditProfileComponent,
    OrgProfileContainerComponent
  ],
  exports: [
    OrgProfileContainerComponent
  ],
    imports: [
        CommonModule,
        OrganisationRoutingModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        FormsModule,
        FormElementsModule,
        SharedCommonModule,
        ToastContainerModule,
        AlertModule
    ]
})
export class OrganisationModule {
}
