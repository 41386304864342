<form [formGroup]="budgetRequestForm" class="mx-4">

  <app-input-number
    [label]="'Supplies/material:'"
    [id]="'suppliesAndMaterials'"
    [theme]="theme"
    [style]="style"
    [usePipe]="true"
    [showDisabledValue]="isReadOnly"
    [hasBorder]="false"
    [isGrey]="false"
    [isReadOnly]="isReadOnly"
    formControlName="suppliesAndMaterials"
  ></app-input-number>

  <app-input-number
    [label]="'Equipment/furniture:'"
    [id]="'equipmentAndFurniture'"
    [theme]="theme"
    [style]="style"
    [usePipe]="true"
    [showDisabledValue]="isReadOnly"
    [hasBorder]="false"
    [isGrey]="false"
    [isReadOnly]="isReadOnly"
    formControlName="equipmentAndFurniture"
  ></app-input-number>

  <!--  TODO label = {{ isTraining ? 'Personnel/ Training Provider:' : 'Personnel:'}} -->
  <app-input-number
    [label]="'Personnel'"
    [id]="'personnel'"
    [theme]="theme"
    [style]="style"
    [usePipe]="true"
    [showDisabledValue]="isReadOnly"
    [hasBorder]="false"
    [isGrey]="false"
    [isReadOnly]="isReadOnly"
    formControlName="personnel"
  ></app-input-number>

  <app-input-number
    [label]="'Travel/transport:'"
    [id]="'travelOrTransport'"
    [theme]="theme"
    [style]="style"
    [usePipe]="true"
    [showDisabledValue]="isReadOnly"
    [hasBorder]="false"
    [isGrey]="false"
    [isReadOnly]="isReadOnly"
    formControlName="travelOrTransport"
  ></app-input-number>

  <app-input-number
    [label]="'Administration:'"
    [id]="'administration'"
    [theme]="theme"
    [style]="style"
    [usePipe]="true"
    [showDisabledValue]="isReadOnly"
    [hasBorder]="false"
    [isGrey]="false"
    [isReadOnly]="isReadOnly"
    formControlName="administration"
  ></app-input-number>
  <!-- </div>
</div> -->

</form>
