import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from '../../../shared/modules/form-elements/form-elements.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedCommonModule} from '../../../shared/modules/shared-common/shared-common.module';
import {ToastContainerModule} from 'ngx-toastr';
import {AlertModule} from '../../../shared/modules/alert/alert.module';
import {ServiceMatrixRoutingModule} from './service-matrix-routing.module';
import {ServiceMatrixResultsComponent} from './components/service-matrix-results/service-matrix-results.component';
import {ServiceMatrixComponent} from './components/service-matrix/service-matrix.component';
import {TreeviewModule} from 'ngx-treeview';


@NgModule({
  declarations: [ServiceMatrixResultsComponent, ServiceMatrixComponent],
    exports: [
        ServiceMatrixComponent
    ],
  imports: [
    CommonModule,
    FormElementsModule,
    ServiceMatrixRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedCommonModule,
    ToastContainerModule,
    TreeviewModule,
    AlertModule,
  ]
})
export class ServiceMatrixModule {
}
