<div class="mbl-accordion">
  <div class="mbl-accordion-header"
       [class.header-variant]="panelState === 'closed'"
       [class.text-new]="status === 'NEW'"
       [class.text-current]="status === 'APPLICATION'"
       [class.text-review]="status === 'REVIEW'"
       [class.text-reporting]="status === 'REPORTING'"
       [class.text-previous]="status === 'CLOSED'"
       (click)="changePanelState()">
    <div class="ac-header-text">
      <h3 class="text-center">{{title}}</h3>
      <ng-container *ngIf="panelState === 'open'; then openText; else closedText"></ng-container>
    </div>
    <div class="ac-icon">
      <ng-container *ngIf="panelState === 'open'; then openIcon; else closedIcon"></ng-container>
    </div>
  </div>
  <div class="mbl-accordion-content"
       [class.box-new]="status === 'NEW'"
       [class.box-current]="status === 'APPLICATION'"
       [class.box-review]="status === 'REVIEW'"
       [class.box-reporting]="status === 'REPORTING'"
       [class.box-previous]="status === 'CLOSED'"
       [class.expanded]="panelState === 'open'"
       [@animateExpansionPanel]="panelState">
    <ng-content></ng-content>
    <div #templateAnchor></div>
  </div>
</div>

<ng-template #closedText>
  <p class="mt-1 mb-0 ml-2">(Click to expand)</p>
</ng-template>

<ng-template #openText>
  <p class="mt-1 mb-0 ml-2">(Click to collapse)</p>
</ng-template>

<ng-template #closedIcon>
  <i class="fas fa-chevron-down"></i>
</ng-template>

<ng-template #openIcon>
  <i class="fas fa-chevron-up"></i>
</ng-template>
