<button
  class="btn btn-secondary"
  [class.disabled]="isReadOnly || coordinatorReadOnly"
  (click)="openModal($event)"
>
  Update Requested Budgets
</button>

<ng-template #modalContent>
  <div>
    <div class="w-100 card card-login p-4">
      <button class="close-modal" type="button" (click)="closeModal()">
        <i class="far fa-times-circle"></i>
      </button>
      <form class="form" [formGroup]="profileForm" (ngSubmit)="update()">
        <div class="w-95 mx-auto">
          <h4 class="category text-primary text-center">
            Work Plan Budget Requested for {{ workPlan.name }}
          </h4>

          <app-alert
            class="w-100"
            [id]="'update-budget-form'"
          ></app-alert>

          <hr class="dropdown-divider border-primary w-50 mx-auto mb-4">


          <div formArrayName="budgets" class="card-body">
            <ng-container *ngIf="profileForm.value.budgets?.length > 0;">
              <ng-container
                *ngFor="let budget of profileForm.get('budgets').controls; let lix = index"
                [formGroupName]="lix">
                <div class="row">
                  <div class="col-12">
                    <app-input-number
                      class="w-45"
                      [label]="getCategory(budget.value.category)"
                      [id]="'name' + lix"
                      [numberMask]="mask"
                      [style]="'bordered'"
                      [isRequired]="true"
                      formControlName="amount"
                    ></app-input-number>
                  </div>

                </div>
              </ng-container>
            </ng-container>
          </div>


        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">Cancel</button>
          <button class="btn btn-outline-primary" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>

</ng-template>
