<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Home based care is defined as caregivers, social workers, nurses and/or other
    healthcare workers providing HIV-related care and support services to PLHIV in
    their own homes.
  </p>
  <form [formGroup]="homeBasedCareForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be targeted for home based care?'"
        [id]="'hbc-peopleTargeted'"
        [name]="'hbc-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
      ></app-input-checkbox>

      <app-input-number
        [label]="'How many people will be reached with home based care?'"
        [id]="'hbc-numberOfPeopleReached'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeopleReached"
      ></app-input-number>

      <app-input-number
        [label]="'How many caregivers will provide the home based care services?'"
        [id]="'hbc-numberOfCaregivers'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfCaregivers"
      ></app-input-number>

      <app-input-select-single
        [label]="'On average, how often will each client be visited by a caregiver?'"
        [items]="frequencyOfVisitsOptions"
        [id]="'hbc-frequencyOfVisits'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        [isRequired]="true"
        formControlName="frequencyOfVisits"
      ></app-input-select-single>

      <div>
        <p>How much funding do you need to provide this home based care?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="homeBasedCareForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
