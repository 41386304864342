import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {ServiceMatrixStore} from './service-matrix.store';
import {ServiceMatrix} from './service-matrix.model';
import {AnswerDto, AnswerListDto} from '../../../../shared/models/answer-dto.model';


@Injectable({providedIn: 'root'})
export class ServiceMatrixService {

  private url = `${environment.apiUrl}/service-matrix`;

  constructor(private serviceMatrixStore: ServiceMatrixStore,
              private http: HttpClient) {
  }

  get(orgId: number): Observable<ServiceMatrix> {
    const url = `${this.url}?orgId=${orgId}`;
    return this.http.get<ServiceMatrix>(url).pipe(
      tap(entity => {
        this.serviceMatrixStore.add(entity);
      })
    );
  }

  create(serviceMatrix: ServiceMatrix, orgId: number): Observable<ServiceMatrix> {
    const url = `${this.url}?orgId=${orgId}`;
    return this.http.post<ServiceMatrix>(url, serviceMatrix).pipe(
      tap(capacity => {
        this.serviceMatrixStore.add(capacity);
      })
    );
  }

  update(serviceMatrix: ServiceMatrix): Observable<ServiceMatrix> {
    const url = `${this.url}/${serviceMatrix.id}`;
    return this.http.put<ServiceMatrix>(url, serviceMatrix).pipe(
      tap(capacity => {
        this.serviceMatrixStore.update(capacity.id, capacity);
      })
    );
  }


  getImpactArea(): Observable<AnswerDto[]> {
    const url = `${environment.apiUrl}/impact-area/sub-category`;
    return this.http.get<AnswerDto[]>(url);
  }

  getPopulationType(): Observable<AnswerListDto[]>  {
    const url = `${environment.apiUrl}/enum/service-matrix`;
    return this.http.get<AnswerListDto[]>(url);
  }
}
