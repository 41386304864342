import {Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges} from '@angular/core';
import {InternalCommentHistory} from '../../../../grant-workflow/store/grant-workflow.model';

@Component({
  selector: 'app-internal-comment-history',
  templateUrl: './internal-comment-history.component.html',
  styleUrls: ['./internal-comment-history.component.scss']
})
export class InternalCommentHistoryComponent implements OnInit, OnChanges {

  @ViewChild('messagesInternal') messagesInternal: ElementRef;

  @Input() internalCommentHistoryList: InternalCommentHistory[];
  @Input() showInternalCommentHistory: boolean;

  chatExpanded: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Scroll the chat history to the bottom when the button is clicked to show internal history
    if (changes.showInternalCommentHistory && changes.showInternalCommentHistory.currentValue) {
      // using setTimeout because otherwise it tries to scroll before the DOM is built
      setTimeout(() => {
        this.scroll('down');
      }, 50);
    }
  }

  scroll(direction: string) {
    // Buttons to scroll the chat in cases where it might be too long to manually scroll
    direction === 'up' ?
      this.messagesInternal.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      }) :
      this.messagesInternal.nativeElement.scrollTo({
        top: 1500,
        behavior: 'smooth'
      });
  }

}
