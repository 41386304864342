import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {User} from '../../../shared/stores/user/user.model';

export interface CoordinatorState extends EntityState<User> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'coordinator',
  idKey: 'username'
})
export class CoordinatorStore extends EntityStore<CoordinatorState> {

  constructor() {
    super();
  }

}

