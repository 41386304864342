import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, take, tap} from 'rxjs/operators';
import {LoadingService} from '../../modules/shared-common/services/loading/loading.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private loadingService: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => take(1)),

      catchError((error: HttpErrorResponse) => {
        if (!environment.production) {
          console.log(error);
        }
        this.loadingService.setIsLoading(false);
        return throwError(error);
      })
    );
  }

  isAuthentication(url: string): boolean {
    switch (true) {
      case url.includes('/user/register'):
      case url.includes('/user/forgotPassword'):
      case url.includes('/user/resetPassword'):
      case url.includes('/user/self'):
        return true;
    }
  }
}
