import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { sidePanelYesNoAnswers } from '../../../../constants/side-panel-answers.const';

@Component({
  selector: 'app-site-visit-community-relationships',
  templateUrl: './site-visit-community-relationships.component.html',
  styleUrls: ['./site-visit-community-relationships.component.scss']
})
export class SiteVisitCommunityRelationshipsComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() siteVisitCommunityRelationshipsForm: FormGroup;
  @Input() isReadOnly: boolean;

  siteVisitCommunityRelationshipsAnswers = sidePanelYesNoAnswers;

  constructor() { }

  ngOnInit() {
  }

}
