import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AdvancedPieChartComponent} from '@swimlane/ngx-charts';
import {MultiChartData} from '../models/charts.model';


@Component({
  selector: 'app-grouped-vertical-bar-chart',
  templateUrl: './grouped-vertical-bar-chart.component.html',
  styleUrls: ['./grouped-vertical-bar-chart.component.scss']
})
export class GroupedVerticalBarChartComponent implements OnInit {
  @ViewChild('pieChart') pieChart: ElementRef<AdvancedPieChartComponent>;

  @Input() chartData: MultiChartData<number>[];
  @Input() view: any[];
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() legendTitle: string;

  labels: any;
  colorScheme: {};

  // options
  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  gradient = true;
  showXAxisLabel = true;
  showYAxisLabel = true;

  constructor() {
  }

  ngOnInit(): void {
    this.colorScheme = {
      domain:
        [
          '#e65600',
          '#0091ea',
          '#55902a',
          '#b20707',
          '#e69600',
        ]
    };
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
