<div class="page-header">
  <div class="auth-header-image"></div>
  <div class="content-center">
    <div class="container h-100">
      <div class="col-md-10 mx-auto h-100">

        <app-spinner [loadingBool]="loading" [sizeInRem]="20">
          <div class="w-100 card card-login bg-light p-1 opacity-8">
            <form class="form" [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm)">
              <div class="card-header text-center">
                <div class="logo-container flex-center">
                  <img
                    class="logo"
                    src="../../../../../assets/img/solinta/SoCapX_Logo_RGB_NoBG_small.png"
                    alt="logo"
                  >
                </div>
              </div>
              <div class="card-body">
                <h4 class="category text-primary">
                  Get In Touch
                </h4>

                <hr class="dropdown-divider border-primary w-50 mx-auto mb-4">

                <app-alert class="w-100" [id]="'contactUs'"></app-alert>

                <app-input-text
                  [label]="'First Name'"
                  [id]="'firstName'"
                  [style]="style"
                  [theme]="theme"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [markUnTouched]="markControlUntouched"
                  formControlName="firstName"
                ></app-input-text>

                <app-input-text
                  [label]="'Last Name'"
                  [id]="'lastName'"
                  [style]="style"
                  [theme]="theme"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [markUnTouched]="markControlUntouched"
                  formControlName="lastName"
                ></app-input-text>

                <app-input-text
                  [label]="'Email'"
                  [id]="'email'"
                  [style]="style"
                  [theme]="theme"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [markUnTouched]="markControlUntouched"
                  formControlName="email"
                ></app-input-text>

                <app-input-text
                  [label]="'Subject'"
                  [id]="'subject'"
                  [style]="style"
                  [theme]="theme"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [markUnTouched]="markControlUntouched"
                  formControlName="subject"
                ></app-input-text>

                <app-input-textarea
                  [label]="'Message'"
                  [id]="'message'"
                  [rows]="'4'"
                  [theme]="theme"
                  [style]="style"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [markUnTouched]="markControlUntouched"
                  formControlName="message"
                ></app-input-textarea>

                <ngx-recaptcha2
                  #captchaElem
                  class="d-block"
                  [siteKey]="siteKey"
                  (reset)="handleReset()"
                  (expire)="handleExpire()"
                  (error)="handleError()"
                  (load)="handleLoad()"
                  (success)="handleSuccess($event)"
                  [useGlobalDomain]="false"
                  [size]="size"
                  [hl]="lang"
                  [theme]="theme"
                  [type]="recaptchaType"
                  formControlName="recaptcha"
                >
                </ngx-recaptcha2>

                <button class="btn btn-primary btn-lg" type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </app-spinner>
      </div>
    </div>
  </div>
</div>

<!--<div class="wrapper">-->
<!--  <div class="page-header clear-filter">-->
<!--    <div class="page-header-image"></div>-->
<!--    <div class="w-100 p-3 flex-center" *ngIf="!showSuccessMessage; else showMessage">-->
<!--      <div class="card bg-dark col-12 col-md-10 col-lg-8">-->
<!--        <h2 class="text-light text-center mt-3 mx-3 w-25 mx-auto text-shadow">Get in touch</h2>-->
<!--        <div class="card-body">-->
<!--          <app-spinner [loadingBool]="loading">-->

<!--            <app-alert-->
<!--              class="w-100"-->
<!--              [id]="'contactUs'"-->
<!--            ></app-alert>-->

<!--            <form-->
<!--              [formGroup]="contactForm"-->
<!--              (ngSubmit)="onSubmit(contactForm)"-->
<!--            >-->
<!--              <app-input-text-->
<!--                [label]="'First Name'"-->
<!--                [id]="'firstName'"-->
<!--                [style]="style"-->
<!--                [theme]="theme"-->
<!--                [isRequired]="true"-->
<!--                [formSubmitted]="formSubmitted"-->
<!--                [markUnTouched]="markControlUntouched"-->
<!--                formControlName="firstName"-->
<!--              ></app-input-text>-->

<!--              <app-input-text-->
<!--                [label]="'Last Name'"-->
<!--                [id]="'lastName'"-->
<!--                [style]="style"-->
<!--                [theme]="theme"-->
<!--                [isRequired]="true"-->
<!--                [formSubmitted]="formSubmitted"-->
<!--                [markUnTouched]="markControlUntouched"-->
<!--                formControlName="lastName"-->
<!--              ></app-input-text>-->

<!--              <app-input-text-->
<!--                [label]="'Email'"-->
<!--                [id]="'email'"-->
<!--                [style]="style"-->
<!--                [theme]="theme"-->
<!--                [isRequired]="true"-->
<!--                [formSubmitted]="formSubmitted"-->
<!--                [markUnTouched]="markControlUntouched"-->
<!--                formControlName="email"-->
<!--              ></app-input-text>-->

<!--              <app-input-text-->
<!--                [label]="'Subject'"-->
<!--                [id]="'subject'"-->
<!--                [style]="style"-->
<!--                [theme]="theme"-->
<!--                [isRequired]="true"-->
<!--                [formSubmitted]="formSubmitted"-->
<!--                [markUnTouched]="markControlUntouched"-->
<!--                formControlName="subject"-->
<!--              ></app-input-text>-->

<!--              <app-input-textarea-->
<!--                [label]="'Message'"-->
<!--                [id]="'message'"-->
<!--                [rows]="'6'"-->
<!--                [theme]="theme"-->
<!--                [style]="style"-->
<!--                [isRequired]="true"-->
<!--                [formSubmitted]="formSubmitted"-->
<!--                [markUnTouched]="markControlUntouched"-->
<!--                formControlName="message"-->
<!--              ></app-input-textarea>-->

<!--              <ngx-recaptcha2-->
<!--                #captchaElem-->
<!--                class="d-block"-->
<!--                [siteKey]="siteKey"-->
<!--                [useGlobalDomain]="false"-->
<!--                [theme]="theme"-->
<!--                [type]="recaptchaType"-->
<!--                formControlName="recaptcha"-->
<!--              >-->
<!--              </ngx-recaptcha2>-->

<!--              <div class="flex-start">-->
<!--                <button class="btn btn-outline-primary" type="submit">Send Message</button>-->
<!--              </div>-->
<!--            </form>-->
<!--          </app-spinner>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<ng-template #showMessage>-->
<!--  <div class="success-alert" *ngIf="showSuccessMessage">-->
<!--    <div class="alert alert-success flex-center">-->
<!--      <p class="font-weight-normal">Your message has been sent successfully. Thanks for getting in touch!</p>-->
<!--      <div class="close-alert-button">-->
<!--        <button class="table-action-btn view" (click)="showSuccessMessage = false">-->
<!--          <i class="far fa-times-circle fa-2x btn-close"></i>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
