import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {FileUpload} from '../../models/file-upload.model';
import {environment} from '../../../../../../environments/environment';
import {SessionQuery} from '../../../../stores/session/session.query';
import {AlertService} from '../../../alert/services/alert.service';

class MapModel {
  key: string;
  value: string;

  constructor(options?: any) {
    this.key = options && options.key;
    this.value = options && options.value;
  }
}

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef<HTMLInputElement>;

  @Input() label: string;
  @Input() additionalParameters: MapModel[];
  @Input() documents: FileUpload[];
  @Input() isReadOnly: boolean;
  @Output() documentsChange: EventEmitter<FileUpload[]> = new EventEmitter<FileUpload[]>();

  uploader: FileUploader;
  uploading = false;
  showDeleteConfirmModal: boolean;
  selectedIndex: number;

  constructor(
    private sessionQuery: SessionQuery,
    private alertService: AlertService) {
  }

  ngOnInit() {
    this.showDeleteConfirmModal = false;
    this.uploaderInit();
  }

  uploaderInit() {
    const uploaderURL = `${environment.apiUrl}/upload/single`;

    this.uploader = new FileUploader({url: uploaderURL, autoUpload: true});

    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
      this.uploader.authToken = 'Basic ' + this.sessionQuery.getToken();
      if (this.additionalParameters && this.additionalParameters.length > 0) {
        this.uploader.options.additionalParameter = {};
        this.additionalParameters.forEach(additionalParameter => {
          this.uploader.options.additionalParameter[additionalParameter.key] = additionalParameter.value;
        });
      }
      this.uploading = true;
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number) => {
      this.documents = this.documents || [];
      this.documents.push(JSON.parse(response));

      this.uploading = false;
      this.documentsChange.emit(this.documents);
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploading = false;

      if (status === 413 || response.includes('Maximum upload size exceeded')) {
        this.alertService.setAlert('File size exceeds maximum size (5MB) allowed.', 'error', 'uploadDocument');
      } else {
        this.alertService.setAlert(`An error occurred while uploading the file. [${status}]`, 'error', 'uploadDocument');
      }

    };
  }

  extractFileName(file) {
    if (file) {
      const splitString = file.split('/');
      return splitString[splitString.length - 1];
    }
    return '';
  }

  getDownloadUrl(file) {
    const fileName = this.extractFileName(file);
    return `${environment.apiUrl}/resources/ext/${fileName}`;
  }

  getFilename(document) {
    if (document.meta) {
      return document.meta.name;
    }
    return this.extractFileName(document);
  }

  downloadFile(doc) {
    fetch(this.getDownloadUrl(doc.value))
      .then(res => res.blob())
      .then(blob => {
        const a = document.createElement('a');
        const fileURL = URL.createObjectURL(blob);
        a.href = fileURL;
        a.download = this.getFilename(doc);
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      });
  }

  deleteConfirmation(isConfirmed: boolean, index: number) {
    this.showDeleteConfirmModal = false;
    if (isConfirmed) {
      this.documents.splice(index, 1);
      this.fileInput.nativeElement.value = '';
      this.documentsChange.emit(this.documents);
    }
  }
}
