<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    This category should only be used if none of the other categories align in any way
    with the type of activity funding is being requested for.
  </p>
  <form [formGroup]="otherFundingRequestForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="targetedPopulation"
        [label]="'Who will benefit? (check all that apply)'"
        [id]="'other-peopleTargeted'"
        [name]="'other-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
      ></app-input-checkbox>

      <div>
        <p>How much funding do you need to complete this activity?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="otherFundingRequestForm.controls.budget | asFormGroup"
        ></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
