<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Community mobilization and awareness are defined as strategically planned activities
    which are targeted to a specific population for the purpose of preventing HIV and/or
    changing negative behaviors related to HIV, such as stigma/discrimination and gender
    based violence.
  </p>
  <form [formGroup]="communityMobilizationForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="typesOfCommunityMobilizationOptions"
        [label]="' What type(s) of community mobilization and awareness activities are you planning to implement?'"
        [id]="'cm-typesOfCommunityMobilization'"
        [name]="'cm-typesOfCommunityMobilization'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="typesOfCommunityMobilization"
        (selected)="checkOther('other', $event, communityMobilizationForm.controls.typesOfCommunityMobilization, communityMobilizationForm.controls.typesOfCommunityMobilizationOther)"
      ></app-input-checkbox>

      <div *ngIf="communityMobilizationForm.value.typesOfCommunityMobilization?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'cm-typesOfCommunityMobilizationOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="typesOfCommunityMobilizationOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="locationConductedOptions"
        [label]="'Where will the community mobilization and awareness activities be conducted?'"
        [id]="'cm-locationConducted'"
        [name]="'cm-locationConducted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="locationConducted"
        (selected)="checkOther('other', $event, communityMobilizationForm.controls.locationConducted, communityMobilizationForm.controls.locationConductedOther)"
      ></app-input-checkbox>

      <div *ngIf="communityMobilizationForm.value.locationConducted?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'cm-locationConductedOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="locationConductedOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="focusedTopicsOptions"
        [label]="'What topic(s) will these community mobilization and awareness activities focus on?'"
        [id]="'cm-focusedTopics'"
        [name]="'cm-focusedTopics'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="focusedTopics"
        (selected)="checkOther('other', $event, communityMobilizationForm.controls.focusedTopics, communityMobilizationForm.controls.focusedTopicsOther)"
      ></app-input-checkbox>

      <div *ngIf="communityMobilizationForm.value.focusedTopics?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'cm-focusedTopicsOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="focusedTopicsOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be targeted for community mobilization?'"
        [id]="'cm-peopleTargeted'"
        [name]="'cm-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
        (selected)="checkOther('other', $event, communityMobilizationForm.controls.peopleTargeted, communityMobilizationForm.controls.peopleTargetedOther)"
      ></app-input-checkbox>

      <div *ngIf="communityMobilizationForm.value.peopleTargeted?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [isRequired]="true"
          [label]="'If other, please specify:'"
          [id]="'cm-peopleTargetedOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          formControlName="peopleTargetedOther"
        ></app-input-textarea>
      </div>

      <app-input-select-single
        [label]="'How often will community mobilization and awareness take place?'"
        [items]="frequencyOfCommunityMobiOptions"
        [id]="'cm-frequencyOfCommunityMobilization'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        [isRequired]="true"
        formControlName="frequencyOfCommunityMobilization"
      ></app-input-select-single>

      <app-input-number
        [label]="'How many people will be targeted through this community mobilization and awareness?'"
        [id]="'cm-numberOfPeopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeopleTargeted"
      ></app-input-number>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'cm-durationOfCommunityMobilization'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfCommunityMobilization"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'cm-durationOfCommunityMobilizationType'"
            [isRequired]="true"
            [name]="'cm-durationOfCommunityMobilizationType'"
            [theme]="theme"
            [style]="style"
            [hasBorder]="!fromReview"
            [isGrey]="!fromReview"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfCommunityMobilizationType"
          ></app-input-radio>
        </div>
      </div>

      <div>
        <p>How much funding do you need to conduct this community mobilization and awareness?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="communityMobilizationForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
