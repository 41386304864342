import {AfterViewInit, Component, ElementRef, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {ChartData, FilterType} from '../models/charts.model';
import {FilterTypePipe} from '../../shared-common/pipes/chartsFilterType/filter-type.pipe';
import { AdvancedPieChartComponent } from '@swimlane/ngx-charts/lib/pie-chart/advanced-pie-chart.component';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, AfterViewInit {
  @ViewChild('pieChart') pieChart: ElementRef<AdvancedPieChartComponent>;

  @Input() chartData: ChartData<number>[];
  @Input() type?: 'shortlist' | 'reviewShortlist' | 'disaggregation' | 'workPlanSubmitted' | 'workPlanApproved' | 'application';
  @Input() view: any[];
  @Input() set filterType(value: FilterType) {
    this._filterType = value;
    // Make sure that only one chart can have a highlighted segment at a time
    // Running this onChanges throws `expression changed after it has been checked` errors
    if (this.chartData.findIndex(item => this.filterTypePipe.transform(item.name, 'toValue') === value) === -1) {
      this.labelsArray = this.labelsArray.map(label => {
        label.style.background = 'transparent';
        label.style.padding = '0';
        label.children[0].style.height = '48px';
        return label;
      });
    }
  }

  get filterType(): FilterType {
    return this._filterType;
  }

  @Output() selected = new EventEmitter<any>();
  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  labels: any;
  labelsArray: any[] = [];
  colorScheme: {};
  filterTypePipe = new FilterTypePipe();

  _filterType: FilterType;

  constructor() {
  }

  ngOnInit(): void {
    this.colorScheme = {
      domain: (this.type === 'disaggregation' || this.type === 'application') ?
        [
          '#e65600',
          '#0091ea',
          '#55902a',
          '#b20707',
          '#e69600',
        ] :
        [
          '#55902a',
          '#b20707',
        ]
    };
  }

  ngAfterViewInit() {
    // The `type` returned by the @ViewChild says that this.pieChart should be `ElementRef`.
    // When using the object however, the type is completely different :/
    // console.log(this.pieChart) // AdvancedPieChartComponent(?) = {
    //   activate, activeEntries, animations, chartElement, etc
    // }
    if (this.type === 'disaggregation') {
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
        'Total Submitted';
    } else if (this.type === 'reviewShortlist') {
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
        'In Review';
    } else if (this.type === 'shortlist') {
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
        'Total Submitted';
    } else if (this.type === 'workPlanSubmitted') {
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
        'Total Work Plans';
    } else if (this.type === 'workPlanApproved') {
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
        'Total Work Plans Submitted';
    } else if (this.type === 'application') {
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
        'Total Applying';
    }
    this.labels =
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[2].children[0];
    this.chartData.forEach((item, idx) => {
      this.labelsArray.push(this.labels.children[idx]);
    });
    this.setLabelStyle(this.filterType);
  }

  setLabelStyle(name: string): void {
    const value = this.filterTypePipe.transform(name, 'toLabel');
    if (value) {
      this.labelsArray.filter(label => {
        const styleName = label.children[0].style[0];
        if (label.children[2].innerHTML === value) {
          const color = label.children[0].style[styleName].slice(0, label.children[0].style[styleName].length - 1);
          label.style.background = `${color}, 0.4)`;
          label.style.padding = '0 .25rem 0 0';
          label.children[0].style.height = '89px';
        } else {
          label.style.background = 'transparent';
          label.style.padding = '0';
          label.children[0].style.height = '48px';
        }
        return label;
      });
    }
  }

  onSelect(data): void {
    const value = this.filterTypePipe.transform(data.name, 'toValue');
    this.labelsArray = this.labelsArray.map(label => {
      const styleName = label.children[0].style[0];
      if (label.children[2].innerHTML === data.name) {
        const color = label.children[0].style[styleName].slice(0, label.children[0].style[styleName].length - 1);
        label.style.background = `${color}, 0.4)`;
        label.style.padding = '0 .25rem 0 0';
        label.children[0].style.height = '89px';
      } else {
        label.style.background = 'transparent';
        label.style.padding = '0';
        label.children[0].style.height = '48px';
      }
      return label;
    });
    if (value) {
      this.selected.emit(value);
    }
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
