import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {WorkPlan} from '../../../../store/work-plan-and-targets.model';
import {WorkPlanAndTargetsQuery} from '../../../../store/work-plan-and-targets.query';
import {Subscription} from 'rxjs';
import {MultiChartData} from '../../../../../../shared/modules/charts/models/charts.model';
import {WorkPlanCategoriesPipe} from '../../../../../../shared/modules/shared-common/pipes/work-plan-categories/work-plan-categories.pipe';

@Component({
  selector: 'app-work-plan-funding-request',
  templateUrl: './work-plan-funding-request.component.html',
  styleUrls: ['./work-plan-funding-request.component.scss'],
})
export class WorkPlanFundingRequestComponent implements OnInit, OnDestroy {

  @Input() isReadOnly: boolean;
  @Input() applicantReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;

  selectedWorkPlan: WorkPlan;
  numberMask: any;
  workPlanSubscription: Subscription;
  budgetChartData: MultiChartData<number>[];

  constructor(private workPlanAndTargetsQuery: WorkPlanAndTargetsQuery,
              private workPlanCategoriesPipe: WorkPlanCategoriesPipe) {
  }

  ngOnInit() {
    this.numberMask = createNumberMask({
      prefix: '',
      allowDecimal: true,
    });

    this.workPlanSubscription = this.workPlanAndTargetsQuery.getSelectedWorkPlanObservable().subscribe(wp => {
      if (wp) {
        this.selectedWorkPlan = wp;
        this.setChartData();
      }
    });
  }

  ngOnDestroy() {
    this.workPlanSubscription.unsubscribe();
  }

  private setChartData() {
    const chartData: MultiChartData[] = [];
    this.selectedWorkPlan.workPlanBudgets.forEach(budget => {
      const allocatedAmount = this.getAllocatedAmount(budget.category);

      chartData.push({
        name: this.workPlanCategoriesPipe.transform(budget.category),
        series: [
          {
            name: 'Total budget requested',
            value: budget.amount || 0,
          },
          {
            name: 'Amount allocated',
            value: allocatedAmount,
          },
          {
            name: 'Amount still available',
            value: (budget.amount || 0) - allocatedAmount,
          }
        ]
      });
    });

    this.budgetChartData = chartData;
  }

  private getAllocatedAmount(category: string) {
    let amount = 0;
    this.selectedWorkPlan.objectives.forEach(objective => {
      objective.activities.forEach(activity => {
        if (category === activity.costCategory) {
          amount += +activity.cost || 0;
        }
      });
    });

    return amount;
  }
}
