import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionStore} from './session.store';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {resetStores} from '@datorama/akita';
import {CancelConfirmHelperService} from '../../guards/cancel-confirm/cancel-confirm-helper.service';
import {User} from '../user/user.model';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class SessionService {

  constructor(private sessionStore: SessionStore,
              private httpClient: HttpClient,
              private router: Router,
              private cancelConfirmHelper: CancelConfirmHelperService) {
  }

  login(credentials: { username, password }) {
    const url = `${environment.apiUrl}/user/self`;
    const authToken = btoa(credentials.username + ':' + credentials.password);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + authToken);

    return this.httpClient.get<any>(url, {headers}).pipe(
      tap(currentUser => {
        this.sessionStore.login({
          user: currentUser,
          name: 'Session',
          token: authToken
        });
        this.routeHome();
      })
    );
  }

  routeHome() {
    const user = this.sessionStore.getValue().user;
    if (user.roles.includes('ROLE_FUNDER') || user.roles.includes('ROLE_GRANT_MANAGER') || user.roles.includes('ROLE_GRANT_COORDINATOR')) {
      if (!user.funderId) {
        this.router.navigateByUrl('/funder-profile/profile/new');
      } else if (user.funderId && !user.isSimSubmitted) {
        this.router.navigateByUrl(`/social-investment-menu/${user.funderId}`);
      } else {
        this.router.navigateByUrl('/investor/dashboard');
      }
    } else if (user.roles.includes('ROLE_APPLICANT')) {
      if (!user.organisationId) {
        this.router.navigateByUrl('/org-profile/profile/new');
      } else if (user.organisationId && !user.isCapacitySpectrumSubmitted) {
        this.router.navigateByUrl(`/capacity-spectrum/edit/${user.organisationId}`);
      } else if (user.organisationId && user.isCapacitySpectrumSubmitted && !user.serviceMatrixId) {
        this.router.navigateByUrl(`/service-matrix/edit/${user.organisationId}`);
      } else {
        this.router.navigateByUrl('/implementer/dashboard');
      }
    } else if (user.roles.includes('ROLE_ADMIN')) {
      this.router.navigateByUrl('/admin/dashboard');
    }
  }

  logout() {
    this.cancelConfirmHelper.isLoggingOut = true;
    if (this.canLogOut()) {
      this.sessionStore.logout();
      resetStores();
      this.cancelConfirmHelper.isLoggingOut = false;
    }
  }

  setOrgIdOnUser(id: number) {
    this.sessionStore.setOrgIdOnUser(id);
  }

  setFunderIdOnUser(id: number) {
    this.sessionStore.setFunderIdOnUser(id);
  }

  canLogOut(): boolean {
    if (this.cancelConfirmHelper.mustConfirmLogout) {
      return this.cancelConfirmHelper.hasConfirmedCancel;
    } else {
      return true;
    }
  }

  impersonate(impersonatingUser: string) {
    const url = `${environment.apiUrl}/impersonate?username=${impersonatingUser}`;

    return this.httpClient.get<any>(url).pipe(
      tap((user: User) => {
        this.sessionStore.updateUser(user);
      })
    );
  }

  unImpersonate() {
    const url = `${environment.apiUrl}/user/exit-impersonation`;

    return this.httpClient.get<any>(url).pipe(
      tap((user: User) => {
        this.sessionStore.updateUser(user);
      })
    );
  }

  setSubmittedCapacitySpectrumOnUser() {
    this.sessionStore.setSubmittedCapacitySpectrumOnUser();
  }

  setServiceMatrixOnUser(serviceMatrixId: number) {
    this.sessionStore.setServiceMatrixOnUser(serviceMatrixId);
  }

  setSubmittedSIMOnUser() {
    this.sessionStore.setSubmittedSIMOnUser();
  }
}

