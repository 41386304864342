import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const typeOfTrainingAnswers: Array<ChoiceInputItem> = [
  {
    label: 'social auxiliary work',
    value: 'socialAuxWrok'
  },
  {
    label: 'early childhood development',
    value: 'childhoodDevelopment'
  },
  {
    label: `youth and child care work`,
    value: 'youthCareWork'
  },
  {
    label: `counseling`,
    value: 'counseling'
  },
  {
    label: 'auxiliary nursing',
    value: 'auxNursing'
  },
  {
    label: 'HIV testing',
    value: 'hivTesting'
  },
  {
    label: 'community health worker',
    value: 'communityHealthWorker'
  },
  {
    label: 'HIV prevention',
    value: 'hivPrevenstion'
  },
  {
    label: 'adherence support (IACT or other)',
    value: 'adherenceSupport'
  },
  {
    label: 'stigma and discrimination',
    value: 'stigmaDiscrimination'
  },
  {
    label: 'program management',
    value: 'programManagement'
  },
  {
    label: 'monitoring and evaluation',
    value: 'monitoringEvaluation'
  },
  {
    label: 'Other - please specify in the comment field below',
    value: 'other'
  },
];


