import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../shared/models/base-entity.model';
import {GrantWorkflow} from '../../../store/grant-workflow.model';
import {createFundingRequest, FundingRequest} from '../funding-request/model/funding-request.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createFundingRequestForm} from '../funding-request/model/create-form.function';

export interface Application extends BaseEntity {
  grantWorkflow: GrantWorkflow;
  status: string;
  fundingRequest: FundingRequest;
  submittedBy: string;
  submitVerification: boolean;
}

export function createApplication(params: Partial<Application>) {
  return Object.assign({}, createBaseEntity(params), {
    grantWorkflow: params && params.grantWorkflow,
    status: params && params.status,
    fundingRequest: params && createFundingRequest(params.fundingRequest),
    submittedBy: params && params.submittedBy,
    submitVerification: params && params.submitVerification,
  }) as Application;
}

export function createApplicationForm(formBuilder: FormBuilder, params?: Partial<Application>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    grantWorkflow: [params && params.grantWorkflow || '', Validators.required],
    status: [params && params.status || '', Validators.required],
    submittedBy: [params && params.submittedBy || ''],
    submitVerification: [params && params.submitVerification || ''],
    fundingRequest: createFundingRequestForm(formBuilder, params && params.fundingRequest),
  }));
}

export interface ApplicationValidationModel {
  isValidFundingRequest: boolean;
  isValidSubmit: boolean;
  isValid: boolean;
}


export function createApplicationValidationModel(params: Partial<ApplicationValidationModel>) {
  return {
    isValidFundingRequest: params && params.isValidFundingRequest,
    isValidSubmit: params && params.isValidSubmit,
    isValid: params && params.isValid,
  } as ApplicationValidationModel;
}
