import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  createOrganisation,
  createOrganisationForm,
  Organisation
} from '../../store/organisation.model';
import {OrganisationQuery} from '../../store/organisation.query';
import {OrganisationService} from '../../store/organisation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {GeoLocationService} from '../../../../../services/geo-location/geo-location.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {SessionService} from '../../../../../shared/stores/session/session.service';
import {SessionQuery} from '../../../../../shared/stores/session/session.query';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../../../../shared/modules/shared-common/services/modal/modal.service';

@Component({
  selector: 'app-org-profile-container',
  templateUrl: './org-profile-container.component.html',
  styleUrls: ['./org-profile-container.component.scss']
})
export class OrgProfileContainerComponent implements OnInit, OnChanges, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('pageTop', {static: true}) pageTop: ElementRef<HTMLElement>;
  @ViewChild('infoModal', {static: true}) infoModal: TemplateRef<HTMLElement>;

  @Input() fromReview: boolean;
  @Input() isReadOnly: boolean;
  @Input() isEditingProfile: boolean;
  @Input() isSavingProfile: boolean;

  @Output() completedSaving = new EventEmitter<boolean>();

  currentTab = 'generalInfo';
  paramSubscription: Subscription;
  organisationSubscription: Subscription;

  currentOrg: Organisation;
  profileForm: FormGroup;
  editing: boolean;
  isSaving = false;
  isLoading: Observable<boolean>;
  hasData: boolean;
  modalOptions: NgbModalOptions;
  createdId: number;

  constructor(private organisationQuery: OrganisationQuery,
              private organisationService: OrganisationService,
              private sessionService: SessionService,
              private sessionQuery: SessionQuery,
              private geoLocationService: GeoLocationService,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private modalService: ModalService,
              private loadingService: LoadingService,
              private router: Router) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.modalOptions = {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    };
    this.fetchOrganisation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isEditingProfile && !changes.isEditingProfile.firstChange) {
      this.editing = changes.isEditingProfile.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();

    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.organisationSubscription) {
      this.organisationSubscription.unsubscribe();
    }
  }

  public toggleEditProfile(pageTop: HTMLElement) {
    this.editing = !this.editing;
    // Fetch the Org Profile fresh only when toggling *from* edit profile
    if (!this.editing) {
      this.fetchOrganisation();
    }
    pageTop.scrollTo({
      top: 0
    });
  }

  public createOrUpdate() {
    this.isSaving = true;

    if (this.profileForm.valid) {
      this.loadingService.setIsLoading(true);
      if (this.profileForm.value.id) {
        this.updateOrganisationProfile(this.profileForm.value.id);
      } else {
        this.createOrganisationProfile();
      }
    } else {
      // nothing for now
    }
  }

  public closeModal() {
    this.modalService.closeModal();
    this.router.navigateByUrl(`capacity-spectrum/edit/${this.createdId}`);
  }

  changeTab(tabName: string) {
    this.currentTab = tabName;
    this.pageTop.nativeElement.scrollTo({
      top: 0
    });
  }

  private fetchOrganisation() {
    const orgId = this.route.snapshot.params.orgId;
    if (orgId === 'new') {
      // Go straight to edit profile if none has been saved
      this.hasData = false;
      this.editing = !this.editing;
      this.currentOrg = createOrganisation({});
      this.profileForm = createOrganisationForm(this.destroy$, this.formBuilder, this.currentOrg);
      return;
    }

    // Always do an API call to ensure up-to-date data.
    this.organisationService.get<Organisation>(orgId).subscribe(() => {
    }, () => {
      this.alertService.setAlert('Failed to fetch Profile', 'error', 'profileContainer');
    });

    this.organisationSubscription = this.organisationQuery.selectEntity(orgId).subscribe(organisation => {
      if (organisation) {
        this.currentOrg = organisation;
        this.profileForm = createOrganisationForm(this.destroy$, this.formBuilder, this.currentOrg);
      }
    });

    this.hasData = true;
    this.loadingService.setIsLoading(false);
  }

  private updateOrganisationProfile(id: number) {
    this.organisationService.update<Organisation>(id, this.profileForm.value).subscribe(() => {
      this.toggleEditProfile(this.pageTop.nativeElement);
      this.loadingService.setIsLoading(false);
      this.isSaving = false;
      this.completedSaving.emit(true);
    }, () => {
      this.alertService.setAlert('Failed to update profile', 'error', 'profileContainer');
      this.loadingService.setIsLoading(false);
      this.isSaving = false;
      this.completedSaving.emit(true);
    });
  }

  private createOrganisationProfile() {
    this.organisationService.add<Organisation>(this.profileForm.value).subscribe((response) => {
      this.createdId = response.id;
      this.organisationService.setActive(this.createdId);
      this.sessionService.setOrgIdOnUser(this.createdId);
      this.modalService.openBasicModal(this.infoModal, this.modalOptions);
    }, () => {
      this.alertService.setAlert('Failed to create Profile', 'error', 'profileContainer');
      this.loadingService.setIsLoading(false);
      this.isSaving = false;
      this.completedSaving.emit(true);
    });
  }
}
