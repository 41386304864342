import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticatedGuard} from './shared/guards/authentication-guard/authenticated.guard';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {AboutComponent} from './modules/public/components/about/about.component';
import {ServicesComponent} from './modules/public/components/services/services.component';
import {ContactUsComponent} from './modules/public/components/contact-us/contact-us.component';
import {FaqComponent} from './modules/public/components/faq/faq.component';
import {LandingContainerComponent} from './modules/public/components/landing-container/landing-container.component';
import {AdminGuard} from './shared/guards/admin-guard/admin.guard';
import {PrivacyPolicyComponent} from './modules/public/components/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {path: 'landing', component: LandingContainerComponent},
  {path: 'about', component: AboutComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'privacy', component: PrivacyPolicyComponent},
  {
    path: 'form-builder',
    loadChildren: () => import('./modules/form-builder/form-builder.module').then(m => m.FormBuilderModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'authentication',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'implementer',
    loadChildren: () => import('./modules/implementer/implementer.module').then(m => m.ImplementerModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'investor',
    loadChildren: () => import('./modules/investor/investor.module').then(m => m.InvestorModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'org-profile',
    loadChildren: () => import('./modules/organisation/org-profile/organisation.module').then(m => m.OrganisationModule),
    canActivate: [AuthenticatedGuard]
  },
  // {
  //   path: 'org-capacity',
  //   loadChildren: () => import('./modules/organisation/org-capacity/org-capacity.module').then(m => m.OrgCapacityModule),
  //   canActivate: [AuthenticatedGuard]
  // },
  {
    path: 'capacity-spectrum',
    loadChildren: () => import('./modules/organisation/capacity-spectrum/capacity-spectrum.module').then(m => m.CapacitySpectrumModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'service-matrix',
    loadChildren: () => import('./modules/organisation/service-matrix/service-matrix.module').then(m => m.ServiceMatrixModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'funder-profile',
    loadChildren: () => import('./modules/funder/funder-profile/funder-profile.module').then(m => m.FunderProfileModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'social-investment-menu/:investorId',
    loadChildren: () => import(
      './modules/funder/social-investment-menu/social-investment-menu.module'
      ).then(m => m.SocialInvestmentMenuModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'funder-management',
    loadChildren: () => import('./modules/funder-management/funder-management.module').then(m => m.FunderManagementModule),
    canActivate: [AuthenticatedGuard]
  },
  // {
  //   path: 'manager',
  //   loadChildren: () => import('./modules/manager/manager.module').then(m => m.ManagerModule),
  //   canActivate: [AuthenticatedGuard]
  // },
  // {
  //   path: 'coordinator',
  //   loadChildren: () => import('./modules/coordinator/coordinator.module').then(m => m.CoordinatorModule),
  //   canActivate: [AuthenticatedGuard]
  // },
  {
    path: 'grant-window',
    loadChildren: () => import('./modules/grant-window/grant-window.module').then(m => m.GrantWindowModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'grant-workflow',
    loadChildren: () => import('./modules/grant-workflow/grant-workflow.module').then(m => m.GrantWorkflowModule),
    canActivate: [AuthenticatedGuard]
  },
  // {
  //   path: 'work-plan-and-targets',
  //   loadChildren: () => import('./modules/work-plan-and-targets/work-plan-and-targets.module').then(m => m.WorkPlanAndTargetsModule),
  //   canActivate: [AuthenticatedGuard]
  // },
  // {
  //   path: 'grant-review',
  //   loadChildren: () => import('./modules/grant-review/grant-review.module').then(m => m.GrantReviewModule),
  //   canActivate: [AuthenticatedGuard]
  // },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'docs',
    loadChildren: () => import('./modules/documentation/documentation.module').then(m => m.DocumentationModule),
  },
  // {path: 'nucleoicons', component: NucleoiconsComponent},
  {path: '', pathMatch: 'full', redirectTo: '/landing'},

  // {path: 'presentation', component: PresentationComponent},
  // { path: 'components',           component: ComponentsComponent },
  // { path: 'sections',             component: SectionsComponent },
  // { path: 'examples/aboutus',     component: AboutusComponent },
  // { path: 'examples/blogpost',    component: BlogpostComponent },
  // { path: 'examples/blogposts',   component: BlogpostsComponent },
  // { path: 'examples/contactus',   component: ContactusComponent },
  // { path: 'examples/ecommerce',   component: EcommerceComponent },
  // { path: 'examples/landing',     component: LandingComponent },
  // { path: 'examples/login',       component: LoginComponent },
  // { path: 'examples/pricing',     component: PricingComponent },
  // { path: 'examples/productpage', component: ProductpageComponent },
  // { path: 'examples/profile',     component: ProfileComponent },
  // { path: 'examples/register',    component: RegisterComponent }

  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule {
}
