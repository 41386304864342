import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {WorkPlan} from '../../../../../../store/work-plan-and-targets.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Activity} from '../activity/store/activity.model';

export interface Objective extends BaseEntity {
  workPlan: WorkPlan;
  name?: string;
  description?: string;
  additionalDescription?: string;
  activities?: Activity[];
}

export function createObjective(params: Partial<Objective>) {
  return Object.assign({}, createBaseEntity(params), {
    workPlan: params && params.workPlan,
    name: params && params.name,
    description: params && params.description,
    additionalDescription: params && params.additionalDescription,
    activities: params && params.activities,
  }) as Objective;
}

export function createObjectiveForm(formBuilder: FormBuilder, params?: Partial<Objective>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    workPlan: [params && params.workPlan, Validators.required],
    name: [params && params.name || '', Validators.required],
    description: [params && params.description || ''],
    additionalDescription: [params && params.additionalDescription || '']
  }));
}
