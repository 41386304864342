import {Component, Input, OnInit} from '@angular/core';
import {QuestionControlType} from '../../../../store/question-base.model';
import {FormGroup} from '@angular/forms';
import {
  FormBuilderQuestionDTO
} from '../../../form-builder-list/form-builder/components/form-builder-question/store/form-builder-question.model';

@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss']
})
export class DynamicFormQuestionComponent implements OnInit {

  @Input() question: FormBuilderQuestionDTO;
  @Input() form: FormGroup;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'default';
  @Input() isReadOnly: boolean;

  QuestionControlType = QuestionControlType;

  constructor() {
  }

  ngOnInit() {
  }

  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
}
