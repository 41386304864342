import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../shared/models/base-entity.model';
import {FundingRequestType} from '../../grant-workflow/application-phase/application/funding-request/model/funding-request.model';
import {GrantWorkflow} from '../../grant-workflow/store/grant-workflow.model';
import {Objective} from '../components/work-plan-and-targets/work-plan/funding-request/objective/store/objective.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

export enum WorkPlanMessageActionType {
  TO_APPLICANT = 'TO_APPLICANT',
  APPLICANT_SUBMITTED = 'APPLICANT_SUBMITTED',
  SUBMITTED_FOR_APPROVAL = 'SUBMITTED_FOR_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED'
}

export enum MessageUserType {
  ADMIN = 'ADMIN',
  FUNDER = 'FUNDER',
  MANAGER = 'MANAGER',
  COORDINATOR = 'COORDINATOR',
  APPLICANT = 'APPLICANT'
}

export interface WorkPlanCommentHistory extends BaseEntity {
  workflow: GrantWorkflow;
  action: WorkPlanMessageActionType;
  username: string;
  userType: MessageUserType;
  date: any;
  message: string;
  isOpen?: boolean; // ui only field
}

export function createWorkPlanCommentHistory(params: Partial<WorkPlanCommentHistory>) {
  return Object.assign({}, createBaseEntity(params), {
    workflow: params && params.workflow,
    action: params && params.action,
    username: params && params.username,
    userType: params && params.userType,
    date: params && params.date,
    message: params && params.message,
    isOpen: params && params.isOpen,
  }) as WorkPlanCommentHistory;
}

export function createWorkPlanCommentHistoryForm(formBuilder: FormBuilder, params?: Partial<WorkPlanCommentHistory>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    workflow: [params && params.workflow, Validators.required],
    action: [params && params.action, Validators.required],
    username: [params && params.username, Validators.required],
    userType: [params && params.userType, Validators.required],
    date: [params && params.date, Validators.required],
    message: [params && params.message, Validators.required],
  }));
}

export interface WorkPlan extends BaseEntity {
  name?: string;
  categoryType?: FundingRequestType;
  workPlanBudgets?: WorkPlanBudget[];
  objectives?: Objective[];
  workflow: GrantWorkflow;
}

export interface WorkPlanBudget extends BaseEntity {
  workPlan: WorkPlan;
  category?: string;
  amount?: number;
}

export function createWorkPlanBudget(params: Partial<WorkPlanBudget>) {
  return Object.assign({}, createBaseEntity(params), {
    workPlan: params && params.workPlan,
    category: params && params.category,
    amount: params && params.amount,
  }) as WorkPlanBudget;
}

export function createWorkPlanBudgetForm(formBuilder: FormBuilder, params?: Partial<WorkPlanBudget>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    workPlan: [params && params.workPlan, Validators.required],
    category: [params && params.category || '', Validators.required],
    amount: [params && params.amount || 0, Validators.required],
  }));
}

export function createWorkPlanBudgetListFormArray(formBuilder: FormBuilder, params?: Partial<WorkPlanBudget[]>): FormArray {
  const budgets: FormArray = formBuilder.array([]);
  if (params && params.length > 0) {
    params.forEach(param => {
      budgets.push(createWorkPlanBudgetForm(formBuilder, param));
    });
  }
  return budgets;
}

export function createWorkPlanBudgetListForm(formBuilder: FormBuilder,
                                             params: Partial<WorkPlan>): FormGroup {
  const budgets = createWorkPlanBudgetListFormArray(formBuilder, (params && params.workPlanBudgets));

  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    budgets,
  }));
}
