import {Component, OnInit} from '@angular/core';
import {QuestionService} from '../../store/question.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilderDTO} from '../form-builder-list/form-builder/store/form-builder.model';

@Component({
  selector: 'app-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss']
})
export class FormRendererComponent implements OnInit {
  formTemplate: FormBuilderDTO;
  loading: boolean;
  formBuilderId: number;

  constructor(private route: ActivatedRoute,
              private service: QuestionService) {
  }

  ngOnInit() {
    this.formBuilderId = this.route.snapshot.params.formBuilderId;
    this.fetch();
    this.service.getAuditLog(this.formBuilderId).subscribe(() => {
    });
  }

  isThemeDark(): boolean {
    return this.formTemplate.theme === 'dark';
  }

  private fetch() {
    this.loading = true;
    this.service.getForm(this.formBuilderId).subscribe(formTemplate => {
      this.loading = false;
      this.formTemplate = formTemplate;
    });
  }
}
