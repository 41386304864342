import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FinancialSituationEndpointType} from '../store/cs-financial-situation.model';

@Injectable({
  providedIn: 'root'
})
export class CsFinancialSituationAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/financial-situation`;

  public annualOperatingBudgetSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $annualOperatingBudget = this.annualOperatingBudgetSource.asObservable();

  public financialSituationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $financialSituation = this.financialSituationSource.asObservable();

  public fundingMethodSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $fundingMethod = this.fundingMethodSource.asObservable();

  public incomeGenerationActivitySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $incomeGenerationActivity = this.incomeGenerationActivitySource.asObservable();

  public costSharingSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $costSharing = this.costSharingSource.asObservable();

  public fundingSourceSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $fundingSource = this.fundingSourceSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.annualOperatingBudgetSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDto of list) {
      switch (answerListDto.enumType) {
        case FinancialSituationEndpointType.ANNUAL_OPERATING_BUDGET: {
          this.annualOperatingBudgetSource.next(answerListDto);
          break;
        }
        case FinancialSituationEndpointType.FINANCIAL_SITUATION: {
          this.financialSituationSource.next(answerListDto);
          break;
        }
        case FinancialSituationEndpointType.FUNDING_METHOD: {
          this.fundingMethodSource.next(answerListDto);
          break;
        }
        case FinancialSituationEndpointType.INCOME_GENERATION_ACTIVITY: {
          this.incomeGenerationActivitySource.next(answerListDto);
          break;
        }
        case FinancialSituationEndpointType.COST_SHARING: {
          this.costSharingSource.next(answerListDto);
          break;
        }
        case FinancialSituationEndpointType.FUNDING_SOURCE: {
          this.fundingSourceSource.next(answerListDto);
          break;
        }
      }
    }
  }
}
