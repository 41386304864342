import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../../stores/session/session.service';
import {User} from '../../../../stores/user/user.model';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {LoadingService} from '../../services/loading/loading.service';
import {SessionQuery} from '../../../../stores/session/session.query';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss']
})
export class UserNavigationComponent implements OnInit, OnDestroy {

  user: User;
  isImpersonating: boolean;

  userSubscription: Subscription;

  constructor(private sessionService: SessionService,
              private sessionQuery: SessionQuery,
              private loadingService: LoadingService,
              private router: Router) {
  }

  ngOnInit() {
    this.userSubscription = this.sessionQuery.getUserObservable().subscribe(user => {
      this.user = user;
      this.isImpersonating = this.user.isImpersonating;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  logout() {
    this.router.navigateByUrl('/authentication/login').then(() => {
      this.sessionService.logout();
    });
  }

  unImpersonate() {
    this.loadingService.setIsLoading(false);
    this.sessionService.unImpersonate().pipe(take(1)).subscribe(() => {
      this.router.navigateByUrl('/admin/dashboard').then(() => {
        this.loadingService.setIsLoading(false);
      });
    }, () => {
      this.loadingService.setIsLoading(false);
    });
  }
}
