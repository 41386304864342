<button
  class="btn btn-primary float-right"
  [disabled]="isReadOnly || coordinatorReadOnly || applicantReadOnly"
  [class.disabled]="isReadOnly || coordinatorReadOnly || applicantReadOnly"
  (click)="openActivityModal()"
>
  Add Activity
</button>

<ng-template #modalContent>
  <div>
    <div class="w-100 card card-login p-4">
      <button class="close-modal" type="button" (click)="closeModal()">
        <i class="far fa-times-circle"></i>
      </button>
      <form class="form" [formGroup]="profileForm" (ngSubmit)="createActivity()">
        <div class="w-95 mx-auto">
          <h4 class="category text-primary text-center">
            Add Activity
          </h4>
          <hr class="dropdown-divider border-primary w-50 mx-auto mb-4">

          <app-alert
            class="w-100"
            [id]="'addActivity'"
          ></app-alert>

          <app-input-text
            class="w-45"
            [label]="'Name'"
            [type]="'text'"
            [id]="'name'"
            [isRequired]="true"
            [style]="'bordered'"
            formControlName="name"
          ></app-input-text>

          <app-input-textarea
            class="w-45"
            [label]="'Description'"
            [id]="'description'"
            [style]="'bordered'"
            [maxLength]="6000"
            formControlName="description"
          ></app-input-textarea>

          <app-input-textarea
            class="w-45"
            [label]="'Monitoring & Evaluation'"
            [id]="'monitoringEvaluation'"
            [style]="'bordered'"
            [maxLength]="4000"
            formControlName="monitoringEvaluation"
          ></app-input-textarea>

          <app-input-checkbox
            class="w-45"
            [id]="'paymentEvidence'"
            [label]="'Payment Evidence'"
            [style]="'bordered'"
            [choices]="paymentEvidenceOptions"
            formControlName="paymentEvidence"
            (selected)="formValidationService.setValidations(profileForm.value.paymentEvidence, profileForm.controls.paymentEvidenceOther, 'OTHER')"
          ></app-input-checkbox>

          <app-input-textarea
            class="w-45"
            [id]="'paymentEvidence'"
            [label]="'Payment Evidence Other'"
            [style]="'bordered'"
            [maxLength]="250"
            [hasBorder]="false"
            [isReadOnly]="!profileForm.value.paymentEvidence.includes('OTHER')"
            formControlName="paymentEvidenceOther"
          ></app-input-textarea>

          <app-input-text
            class="w-45"
            [id]="'timeframe'"
            [label]="'Time frame'"
            [style]="'bordered'"
            [maxLength]="1000"
            formControlName="timeframe"
          ></app-input-text>

          <app-input-text
            class="w-45"
            [id]="'responsiblePerson'"
            [label]="'Responsible Person'"
            [style]="'bordered'"
            formControlName="responsiblePerson"
          ></app-input-text>

          <app-input-radio
            class="w-45"
            [id]="'budgetCategory'"
            [label]="'Budget Category'"
            [style]="'bordered'"
            [choices]="budgetCategoryOptions"
            [isRequired]="true"
            formControlName="costCategory"
          ></app-input-radio>

          <app-input-number
            class="w-45"
            [id]="'cost'"
            [label]="'Cost'"
            [style]="'bordered'"
            [numberMask]="{}"
            formControlName="cost"
          ></app-input-number>

          <app-input-textarea
            class="w-45"
            [id]="'explanationOfCost'"
            [label]="'Explanation Of Cost'"
            [style]="'bordered'"
            [maxLength]="2000"
            formControlName="explanationOfCost"
          ></app-input-textarea>

        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">Cancel</button>
          <button class="btn btn-outline-primary" type="submit">Save</button>
        </div>

      </form>
    </div>
  </div>
</ng-template>
