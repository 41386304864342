import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ServiceMatrixState, ServiceMatrixStore} from './service-matrix.store';

@Injectable({providedIn: 'root'})
export class ServiceMatrixQuery extends QueryEntity<ServiceMatrixState> {

  constructor(protected store: ServiceMatrixStore) {
    super(store);
  }

}
