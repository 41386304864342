import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MblTableComponent} from '../../../../shared/modules/data-table/components/mbl-table/mbl-table.component';
import {Subscription} from 'rxjs';
import {Column} from '../../../../shared/modules/data-table/models/mbl-table.model';
import {FormBuilderService} from './form-builder/store/form-builder.service';
import {FormBuilderModel} from './form-builder/store/form-builder.model';
import {FormBuilderQuery} from './form-builder/store/form-builder.query';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-form-builder-list',
  templateUrl: './form-builder-list.component.html',
  styleUrls: ['./form-builder-list.component.scss']
})
export class FormBuilderListComponent implements OnInit, OnDestroy {

  @ViewChild('table', {static: true}) table: MblTableComponent;
  @ViewChild('actionTmpl', {static: true}) actionTmpl: TemplateRef<any>;
  @ViewChild('funderTmpl', {static: true}) funderTmpl: TemplateRef<any>;

  formListSubscription: Subscription;

  rows: FormBuilderModel[];
  cachedRows: FormBuilderModel[];
  columns: Column[];
  loading: boolean;

  constructor(private router: Router,
              public sessionQuery: SessionQuery,
              private formBuilderService: FormBuilderService,
              private alertService: AlertService,
              private formBuilderQuery: FormBuilderQuery) {
  }

  ngOnInit() {
    this.loading = true;
    this.populateColumns();
    this.fetch();
  }

  ngOnDestroy() {
    if (this.formListSubscription) {
      this.formListSubscription.unsubscribe();
    }
  }

  fetch() {
    this.formBuilderService.getForms().subscribe(() => {
      this.loading = false;
    }, () => {
      this.loading = false;
      this.alertService.setAlert('Failed to get Forms', 'error', 'formBuilderList');
    });

    this.formListSubscription = this.formBuilderQuery.getListObservable().subscribe(list => {
      if (list && list.length) {
        this.rows = list;
        this.cachedRows = [...this.rows];
      }
    });
  }

  private populateColumns() {
    this.columns = [
      {name: 'Form Name', prop: 'name', width: '40%', sort: true},
      {name: 'Actions', prop: 'action', width: '20%', cellTemplate: this.actionTmpl},
    ];

    const isAdmin = this.sessionQuery.hasRole(['ROLE_ADMIN']);
    if (isAdmin) {
      const funderColumn = {name: 'Owner', prop: 'funder.id', cellTemplate: this.funderTmpl, width: '40%', sort: true};
      this.columns.splice(1, 0, funderColumn);
    }
  }

  delete(row) {
    this.formBuilderService.delete(row.id).pipe(take(1)).subscribe(() => {
      this.fetch();
    }, (e) => {
      this.alertService.setAlert('Failed to delete form.\n' + e?.error?.message, 'error', 'formBuilderList');
    });
  }

  clone(id: number) {
    this.loading = true;
    this.formBuilderService.clone(id).pipe(take(1)).subscribe(response => {
      this.loading = false;
      this.router.navigateByUrl(`/form-builder/details/${response.id}`).then();
    });
  }
}
