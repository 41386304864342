<form [formGroup]="siteVisitServiceDeliveryForm">
  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'referralAndLinkageIsCorrect'"
    [label]="'Referral and linkage system'"
    [isRequired]="true"
    [id]="'referralAndLinkageIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="referralAndLinkageIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'childSafeGuardingPolicyIsCorrect'"
    [label]="'Child safeguarding policy'"
    [isRequired]="true"
    [id]="'childSafeGuardingPolicyIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="childSafeGuardingPolicyIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'beneficiaryRightsIsCorrect'"
    [label]="'Beneficiary rights and/or stigma and discrimination policy'"
    [isRequired]="true"
    [id]="'beneficiaryRightsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="beneficiaryRightsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'staffTrainingBeneficiaryRightsIsCorrect'"
    [label]="'Staff training on beneficiary rights/stigma'"
    [isRequired]="true"
    [id]="'staffTrainingBeneficiaryRightsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="staffTrainingBeneficiaryRightsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'adequateMeasuresIsCorrect'"
    [label]="'Adequate confidentiality measures for all beneficiary information'"
    [isRequired]="true"
    [id]="'adequateMeasuresIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="adequateMeasuresIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'dataQualityIsCorrect'"
    [label]="'Data quality assurance process'"
    [isRequired]="true"
    [id]="'dataQualityIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="dataQualityIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'frequencyOfStaffTrainingIsCorrect'"
    [label]="'Frequency of staff training and support'"
    [isRequired]="true"
    [id]="'frequencyOfStaffTrainingIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="frequencyOfStaffTrainingIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'numberOfBeneficiariesIsCorrect'"
    [label]="'Number of beneficiaries (verify in Testing, CBCTS and/or Prevention)'"
    [isRequired]="true"
    [id]="'numberOfBeneficiariesIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="numberOfBeneficiariesIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'evidenceOfServicesIsCorrect'"
    [label]="'Evidence of services (verify in Testing, CBCTS and/or Prevention)'"
    [id]="'evidenceOfServicesIsCorrect'"
    formControlName="evidenceOfServicesIsCorrect"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'htsSuppliesIsCorrect'"
    [label]="'HTS safety supplies'"
    [theme]="theme"
    [style]="style"
    [id]="'htsSuppliesIsCorrect'"
    [showLabel]="true"
    formControlName="htsSuppliesIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'htsProtocolsIsCorrect'"
    [label]="'HTS protocols'"
    [theme]="theme"
    [style]="style"
    [id]="'htsProtocolsIsCorrect'"
    [showLabel]="true"
    formControlName="htsProtocolsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'htsProvidersIsCorrect'"
    [label]="'Observation of HTS providers'"
    [theme]="theme"
    [style]="style"
    [id]="'htsProvidersIsCorrect'"
    [showLabel]="true"
    formControlName="htsProvidersIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'writtenProceduresIsCorrect'"
    [label]="'Written standard operating procedures'"
    [theme]="theme"
    [style]="style"
    [id]="'writtenProceduresIsCorrect'"
    [showLabel]="true"
    formControlName="writtenProceduresIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'artTrackingIsCorrect'"
    [label]="'ART defaulter tracking'"
    [theme]="theme"
    [style]="style"
    [id]="'artTrackingIsCorrect'"
    [showLabel]="true"
    formControlName="artTrackingIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'preventionMaterialsIsCorrect'"
    [label]="'Evidence based prevention materials'"
    [theme]="theme"
    [style]="style"
    [id]="'preventionMaterialsIsCorrect'"
    [showLabel]="true"
    formControlName="preventionMaterialsIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'groupSizeIsCorrect'"
    [label]="'Group size'"
    [theme]="theme"
    [style]="style"
    [id]="'groupSizeIsCorrect'"
    [showLabel]="true"
    formControlName="groupSizeIsCorrect"
  ></app-widget-check-two>
</form>
