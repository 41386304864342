import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const programsAnswers: Array<ChoiceInputItem> = [
  {
    label: 'Zazi',
    value: 'zazi'
  },
  {
    label: 'Stepping Stones',
    value: 'steppingStones'
  },
  {
    label: `Creating Futures`,
    value: 'creatingFutures'
  },
  {
    label: `KidzAlive`,
    value: 'kidzAlive'
  },
  {
    label: 'Families Matter!',
    value: 'familiesMatter'
  },
  {
    label: 'YOLO (You Only Live Once)',
    value: 'yolo'
  },
  {
    label: 'Vhutshilo 1',
    value: 'vhutshilo1'
  },
  {
    label: 'Vhutshilo 2',
    value: 'vhutshilo2'
  },
  {
    label: 'Vhutshilo 3',
    value: 'vhutshilo3'
  },
  {
    label: `Let's Talk`,
    value: 'letsTalk'
  },
  {
    label: 'Healthy Choices',
    value: 'healthyChoices'
  },
  {
    label: 'SASA!',
    value: 'sasa'
  },
  {
    label: 'Abangane',
    value: 'abangane'
  },
  {
    label: 'Man2Man',
    value: 'man2man'
  },
  {
    label: 'Comprehensive Sexuality Education',
    value: 'cse'
  },
  {
    label: 'Other',
    value: 'other'
  },
];

export const peopleTargetedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: 'men who have sex with men',
    value: 'msm'
  },
  {
    label: 'transgender persons',
    value: 'tp'
  },
  {
    label: 'injecting drug users',
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: 'clients of sex workers',
    value: 'csw'
  },
  {
    label: 'military',
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: 'people living with HIV',
    value: 'peopleWithHiv'
  },
  {
    label: 'clients of sex workers',
    value: 'csw'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
  {
    label: 'adults 25+ / general population',
    value: 'adultPopulation'
  },
  {
    label: 'parents',
    value: 'parents'
  },
  {
    label: 'caregivers for PLHIV',
    value: 'caregivers'
  },
  {
    label: 'HIV positive children',
    value: 'hivChildren'
  },
  {
    label: 'none of the above, the organization provides general population testing',
    value: 'none'
  }
];
