import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-capacity-spectrum-verification',
  templateUrl: './capacity-spectrum-verification.component.html',
  styleUrls: ['./capacity-spectrum-verification.component.scss']
})
export class CapacitySpectrumVerificationComponent implements OnInit {

  @Input() capacitySpectrumVerificationForm: FormGroup;
  @Input() isReadOnly: boolean;

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  constructor() { }

  ngOnInit(): void {
  }

}
