<div class="wrapper">
  <div class="w-100 mx-auto">
    <div class="h-100" [class.mt-6]="fromReview">
      <div class="card-body" *ngIf="profileForm">
        <form [formGroup]="profileForm">
          <app-alert
            class="w-100"
            [id]="'editProfile'"
          ></app-alert>
          <ng-container [ngSwitch]="currentTab">
            <ng-container *ngSwitchCase="'generalInfo'">
              <h4 class="text-center">General Info</h4>
              <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
              <div class="row">
                <div class="col-5">
                  <div class="flex-between align-items-start flex-column flex-wrap">

                    <app-input-text
                      class="w-100"
                      [label]="'Legal Name'"
                      [type]="'text'"
                      [id]="'legalName'"
                      [isRequired]="true"
                      [formSubmitted]="formSubmitted"
                      [style]="'default'"
                      [theme]="'light'"
                      formControlName="legalName"
                    ></app-input-text>

                    <app-input-text
                      class="w-100"
                      [label]="'Trading Name'"
                      [type]="'text'"
                      [id]="'tradingName'"
                      [style]="'default'"
                      [theme]="'light'"
                      formControlName="tradingName"
                    ></app-input-text>

                    <app-input-date
                      class="w-100"
                      [label]="'Organisation Inception Date'"
                      [id]="'inceptionDate'"
                      [isRequired]="true"
                      [formSubmitted]="formSubmitted"
                      [style]="'default'"
                      [theme]="'light'"
                      formControlName="inceptionDate"
                    ></app-input-date>

                    <label class="default-input-label text-dark bg-white">
                      Required only if NPO, Section 21 or Registered ECD is selected.
                    </label>
                    <div class="border border-dark rounded-border w-100 py-2">
                      <app-input-text
                        class="w-95 text-center m-auto"
                        [label]="'Registration Number'"
                        [id]="'regNumber'"
                        [isRequired]="isRequiredNPODateOrNumber()"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="regNumber"
                      ></app-input-text>

                      <div class="w-95 text-center m-auto">
                        <app-input-date
                          [label]="'Registration Date'"
                          [id]="'regDate'"
                          [isRequired]="isRequiredNPODateOrNumber()"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="regDate"
                        ></app-input-date>
                      </div>
                    </div>

                    <app-input-text
                      *authenticatedWithRole="['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER']"
                      class="w-100"
                      [label]="'Organisation Code'"
                      [id]="'code'"
                      [isUnique]="true"
                      [urlFragment]="'organisation'"
                      [uniqueRules]="orgCodeRegex"
                      [uniqueId]="organisation?.id"
                      [uniqueDescription]="orgCodeDescription"
                      [uniqueError]="'That code is already taken. Please try another.'"
                      [style]="'default'"
                      [theme]="'light'"
                      formControlName="code"
                    ></app-input-text>

                    <div class="flex-start custom-control custom-checkbox mt-3">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="hasConsented"
                        name="hasConsented"
                        required
                        formControlName="hasConsented"
                      />
                      <label for="hasConsented" class="custom-control-label">
                        I have read and agreed to the terms laid out in the
                        <a class="text-primary" routerLink="/privacy">privacy agreement</a>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="w-85 mx-auto">
                    <app-input-checkbox
                      class="w-100"
                      [label]="'Implementer Type'"
                      [choices]="organisationTypeItems"
                      [id]="'organisationType'"
                      [style]="'default'"
                      [theme]="'light'"
                      [cols]="2"
                      [showActiveBorder]="false"
                      [isRequired]="true"
                      [formSubmitted]="formSubmitted"
                      formControlName="organisationType"
                    ></app-input-checkbox>
                  </div>
                </div>
                <div class="col-3 flex-start flex-column">
                  <label>Organisation Logo</label>
                  <img class="profile-image" src="assets/img/placeholder.jpg" alt="profile-photo">
                  <div class="w-100 flex-center">
                    <button disabled class="btn btn-sm btn-accent disabled">Upload (Coming soon)</button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'contactDetails'">
              <h4 class="text-center">Organisation contact details</h4>
              <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
              <div formGroupName="orgContact" class="form-grid">
                <app-input-text
                  class="w-100"
                  [label]="'Contact Number'"
                  [type]="'text'"
                  [id]="'contactNo'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  formControlName="contactNo"
                ></app-input-text>

                <app-input-text
                  class="w-100"
                  [label]="'Website'"
                  [type]="'text'"
                  [id]="'websitePrimary'"
                  [style]="'default'"
                  [theme]="'light'"
                  formControlName="website"
                ></app-input-text>

                <app-input-text
                  class="w-100"
                  [label]="'Email Address'"
                  [type]="'email'"
                  [id]="'email'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  formControlName="email"
                ></app-input-text>


                <app-input-text
                  class="w-100"
                  [label]="'Address Line 1'"
                  [type]="'text'"
                  [id]="'addressOne'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  formControlName="address1"
                ></app-input-text>

                <app-input-text
                  class="w-100"
                  [label]="'Address Line 2'"
                  [type]="'text'"
                  [id]="'addressTwo'"
                  [style]="'default'"
                  [theme]="'light'"
                  formControlName="address2"
                ></app-input-text>

                <app-input-select-single
                  class="w-100"
                  [label]="'Province'"
                  [items]="provinceList"
                  [clearValueOnItemsChange]="false"
                  [id]="'province'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  (selectedItem)="refreshGeoLocation($event, 'province')"
                  formControlName="provinceCode"
                ></app-input-select-single>

                <app-input-select-single
                  class="w-100"
                  [label]="'District'"
                  [items]="districtList"
                  [clearValueOnItemsChange]="false"
                  [id]="'district'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [isReadOnly]="isEmpty(profileForm.value.orgContact.provinceCode)"
                  (selectedItem)="refreshGeoLocation($event, 'district')"
                  formControlName="districtCode"
                ></app-input-select-single>

                <app-input-select-single
                  class="w-100"
                  [label]="'Sub District'"
                  [items]="subDistrictList"
                  [clearValueOnItemsChange]="false"
                  [id]="'subDistrict'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  [isReadOnly]="isEmpty(profileForm.value.orgContact.districtCode)"
                  (selectedItem)="refreshGeoLocation($event, 'subDistrict')"
                  formControlName="subDistrictCode"
                ></app-input-select-single>

                <app-input-text
                  class="w-100"
                  [label]="'City'"
                  [type]="'text'"
                  [id]="'city'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  formControlName="city"
                ></app-input-text>

                <app-input-text
                  class="w-100"
                  [label]="'Postal Code'"
                  [type]="'text'"
                  [id]="'postalCode'"
                  [style]="'default'"
                  [theme]="'light'"
                  [isRequired]="true"
                  [formSubmitted]="formSubmitted"
                  formControlName="postalCode"
                ></app-input-text>

              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'contactPersons'">
              <h4 class="text-center">Implementer contact details</h4>
              <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
              <div class="row">
                <div class="col-6">
                  <h4 class="text-center">Primary contact details</h4>
                  <hr class="dropdown-divider w-75 mx-auto border-primary mb-2">
                  <div formGroupName="primaryContact">
                    <div class="flex-between align-items-start flex-column">
                      <app-input-text
                        class="w-100"
                        [label]="'First Name'"
                        [id]="'firstNamePrimary'"
                        [isRequired]="true"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="firstName"
                      ></app-input-text>

                      <app-input-text
                        class="w-100"
                        [label]="'Preferred Name'"
                        [type]="'middleNamePrimary'"
                        [id]="'website'"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="middleName"
                      ></app-input-text>

                      <app-input-text
                        class="w-100"
                        [label]="'Last Name'"
                        [id]="'lastNamePrimary'"
                        [isRequired]="true"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="lastName"
                      ></app-input-text>

                      <app-input-text
                        class="w-100"
                        [label]="'Position'"
                        [type]="'position'"
                        [id]="'positionPrimary'"
                        [isRequired]="true"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="position"
                      ></app-input-text>
                    </div>

                    <div formGroupName="contactDetails" class="w-100 mx-auto">
                      <div class="flex-between flex-wrap">
                        <app-input-text
                          class="w-100"
                          [label]="'Landline'"
                          [id]="'contactNoPrimary'"
                          [isRequired]="true"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="contactNo"
                        ></app-input-text>

                        <app-input-text
                          class="w-100"
                          [label]="'Cellphone'"
                          [id]="'mobileNoPrimary'"
                          [isRequired]="true"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="mobileNo"
                        ></app-input-text>

                        <app-input-text
                          class="w-100"
                          [label]="'Email'"
                          [type]="'email'"
                          [id]="'emailPrimary'"
                          [isRequired]="true"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="email"
                        ></app-input-text>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <h4 class="text-center">Secondary contact details</h4>
                  <hr class="dropdown-divider w-75 mx-auto border-primary mb-2">
                  <div formGroupName="secondaryContact">
                    <div class="flex-between align-items-start flex-column">
                      <app-input-text
                        class="w-100"
                        [label]="'First Name'"
                        [id]="'firstNameSecondary'"
                        [isRequired]="true"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="firstName"
                      ></app-input-text>

                      <app-input-text
                        class="w-100"
                        [label]="'Preferred Name'"
                        [id]="'middleNameSecondary'"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="middleName"
                      ></app-input-text>

                      <app-input-text
                        class="w-100"
                        [label]="'Last Name'"
                        [id]="'lastNameSecondary'"
                        [isRequired]="true"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="lastName"
                      ></app-input-text>

                      <app-input-text
                        class="w-100"
                        [label]="'Position'"
                        [type]="'position'"
                        [id]="'position'"
                        [isRequired]="true"
                        [formSubmitted]="formSubmitted"
                        [style]="'default'"
                        [theme]="'light'"
                        formControlName="position"
                      ></app-input-text>
                    </div>

                    <div formGroupName="contactDetails" class="w-100 mx-auto">
                      <div class="flex-between flex-wrap">
                        <app-input-text
                          class="w-100"
                          [label]="'Landline'"
                          [id]="'contactNoSecondary'"
                          [isRequired]="true"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="contactNo"
                        ></app-input-text>

                        <app-input-text
                          class="w-100"
                          [label]="'Cellphone'"
                          [id]="'mobileNoSecondary'"
                          [isRequired]="true"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="mobileNo"
                        ></app-input-text>

                        <app-input-text
                          class="w-100"
                          [label]="'Email'"
                          [type]="'email'"
                          [id]="'emailSecondary'"
                          [isRequired]="true"
                          [formSubmitted]="formSubmitted"
                          [style]="'default'"
                          [theme]="'light'"
                          formControlName="email"
                        ></app-input-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </form>
        <div *ngIf="profileForm && !profileForm.value.id" class="w-80 mx-auto">
          <button *ngIf="hasPreviousSection()" class="btn btn-success" (click)="previousSection()">
            << Previous Section
          </button>
          <button *ngIf="hasNextSection()" class="btn btn-success float-right" (click)="nextSection()">
            Next Section >>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
