import {Injectable} from '@angular/core';
import {Country, District, GeoLocation, Province, SubDistrict} from '../../shared/models/geo-location.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeoLocationProvinceChoiceDTO} from '../../modules/funder/social-investment-menu/store/social-investment-menu.model';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  countryList: Observable<Country[]>;
  provinceList: Observable<Province[]>;

  constructor(private httpClient: HttpClient) {
    // We only need to fetch these once as this list will always be the same
    this.countryList = this.getCountryList();
    this.provinceList = this.getProvinceList();
  }

  // ----------------- Country List -----------------
  getCountryList(): Observable<Country[]> {
    const url = `${environment.apiUrl}/country`;

    return this.httpClient.get<Country[]>(url);
  }

  // ----------------- Country By Code -----------------
  getCountryByCode(countryCode: string): Observable<Country> {
    const url = `${environment.apiUrl}/country/by-code?code=${countryCode}`;

    return this.httpClient.get<Country>(url);
  }

  // ----------------- Province List -----------------
  getProvinceList(): Observable<Province[]> {
    const url = `${environment.apiUrl}/province`;

    return this.httpClient.get<Province[]>(url);
  }

  // ----------------- District List -----------------
  getDistrictListByProvinceCode(provinceCode: string): Observable<District[]> {
    const url = `${environment.apiUrl}/district/by-province-code?provinceCode=${provinceCode}`;

    return this.httpClient.get<District[]>(url);
  }

  // ----------------- Sub District List -----------------
  getSubDistrictListByDistrictCode(districtCode: string): Observable<SubDistrict[]> {
    const url = `${environment.apiUrl}/sub-district/by-district-code?districtCode=${districtCode}`;

    return this.httpClient.get<SubDistrict[]>(url);
  }


  // ----------------- Geo Location -----------------
  getGeoLocationByProvinceCode(provinceCode: string): Observable<GeoLocation> {
    const url = `${environment.apiUrl}/geo-location/by-province-code?provinceCode=${provinceCode}`;
    return this.httpClient.get<GeoLocation>(url);
  }

  getGeoLocationByDistrictCode(districtCode: string): Observable<GeoLocation> {
    const url = `${environment.apiUrl}/geo-location/by-district-code?districtCode=${districtCode}`;
    return this.httpClient.get<GeoLocation>(url);
  }

  getGeoLocationBySubDistrictCode(subDistrictCode: string): Observable<GeoLocation> {
    const url = `${environment.apiUrl}/geo-location/by-sub-district-code?subDistrictCode=${subDistrictCode}`;
    return this.httpClient.get<GeoLocation>(url);
  }

  getGeoLocationByAllProvinces(): Observable<GeoLocation[]> {
    const url = `${environment.apiUrl}/geo-location/by-all-provinces`;
    return this.httpClient.get<GeoLocation[]>(url);
  }

  getGeoLocationAllProvincesAndDistricts(): Observable<GeoLocationProvinceChoiceDTO[]> {
    const url = `${environment.apiUrl}/geo-location/by-all-provinces-including-district-lists`;
    return this.httpClient.get<GeoLocationProvinceChoiceDTO[]>(url);
  }
}
