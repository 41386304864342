<div class="wrapper" #top>
    <div class="page-header clear-filter">
      <div class="page-header-image">
      </div>
      <div class="w-100 flex-center flex-column">
        <h1 class="text-light mb-4 m-4">Services</h1>
        <div class="card bg-dark">
          <div class="card-body col-lg-10 col-md-10 col-12 mx-auto">
              <h3 class="text-center mt-4">For Investors:</h3>
            <p class="text-center mt-4">
                Whether you are an individual, corporate or philanthropic investor, SoCapX provides a
                range of products and services that are all designed to decrease your risk and work load,
                while increasing your impact and growing your social investment.
            </p>
            <p class="text-center mt-4">
                A critical component is <span class="font-weight-normal">SoCapX Reporting</span>. All financial transactions, all inputs, all
                progress and all results are monitored and reported on in a real-time, accessible,
                user-friendly manner that adds value to both the implementer and the investor.
                SoCapX Reporting is designed to simultaneously make data capturing easier and more
                accurate, as well as more innovative and efficient in how the data is used once it is
                captured. SoCapX Reporting makes a previously time-consuming, under-utilized exercise,
                into an efficient and dynamic tool which simultaneously derives benefits for implementers,
                 investors and the greater social ecosystem.
            </p>

            <p class="text-center p-4">
                SoCapX also provides <span class="font-weight-normal">Monitoring and Evaluation</span> from system assessments and verification
                points, all intentionally designed to gather useful and relevant information, applicable
                to both the immediate investment and towards reaching the SDGs.
            </p>
            <p class="text-center p-4">
                For corporate investors, SoCapX will manage your <span class="font-weight-normal">corporate social investment (CSI)</span>
                portfolio, and align it to your company’s identity and core ethos.
            </p>

            <h4 class="text-center">Types of Investors</h4>
            <p class="text-center mt-4">
                SoCapX recognizes that we need a various array of resources, including people, skills and
                financial support in the social economy if we are to sustainability address the pressing
                social issues we face. That is why SoCapX caters for three types of investors:</p>
            <p class="text-center m-4">Financial investors are individuals or companies who would like to make a monetary investment.</p>

            <p class="text-center m-4">Material investors are individuals or companies who would like to invest goods or resources.</p>

            <p class="text-center m-4">Skill investors are individuals or companies who would like to invest their time, experience, knowledge and/or skills.</p>
            </div>
        </div>
      </div>
      <div class="content-separator"></div>
      <div class="p-4 bg-dark" #welcome>
        <div class="container">
          <div class="row">
            <div class="col-md-10 ml-auto mr-auto text-center">
              <h3 class="text-light">
                For implementers:
              </h3>
              <p class="text-light mt-4">
                  Whether you are a registered NPO, SME, Community Based Organisation or public benefit organization, SoCapX provides you with a range of services
                  that are all designed to decrease your work load, increase your capacity, increase opportunities for
                  funding and increase the credibility of your data.
              </p>
              <p class="text-light mt-4">
                  Your initial point of entry into SoCapX is the online Organisation Capacity assessment. This assessment
                  allows us to understand and assess your organisational capacity, service capacity and your current
                  needs.
              </p>

              <p class="text-light mt-4">The <span class="font-weight-normal">Organisation Capacity Assessment</span> is designed to measure an implementer’s capacity
                  across several domains: organisational structure, human resources; governance; monitoring and evaluation,
                  relationships, networks and linkage, financial management; and growth potential. These domains comprise
                  all of the standard information that any social investor would want to know prior to making an
                  investment. The Capacity Assessment has two distinct benefits for you. First, individual applications
                  for funding are now targeted to your organisations focus, vision and mission. Second, the Capacity
                  Assessment is designed to help overall organisation reach and performance, as it is connected to the
                  integrated knowledge management system which matches resources and information to each implementer
                  based on their needs highlighted in the Capacity Assessment.</p>

              <p class="text-light mt-4">The <span class="font-weight-normal">Integrated Knowledge Management System</span> provides you with a centralized repository
                  of resources categorized to ensure that the most useful and relevant information is directed to you,
                  based on your profile. The Integrated Knowledge Management System is constantly expanding with relevant,
                  credible and up to date resources.</p>

              <p class="text-light mt-4">SoCapX provides <span class="font-weight-normal">Organisational and Programmatic Capacity Building</span>
                  through a series of
                  web-based and in-person capacity building workshops, events and handbooks available to all implementers
                  and stakeholders. Events will be held in a variety of locations, with each event focusing on a specific
                   topic.</p>

              <p class="text-light mt-4"><span class="font-weight-normal">SoCapX Reporting</span> ensures all financial transactions all programme progress and results are
                  closely monitored and reported on in a real-time, accessible, user-friendly manner that adds value
                  to both the implementer and the investor. The most immediate benefit for you as an implementer,
                  is easier reporting that automatically tracks spend against results so implementers can self-examine
                  and adapt.</p>

              <p class="text-light mt-4">SoCapX also provides <span class="font-weight-normal">Monitoring and Evaluation</span> through system assessments and
                  verification points, all intentionally designed to gather useful and relevant information, applicable
                  to both the immediate investment and the wider system. For you as an implementer, the daily stress
                  and cumbersome nature of monitoring and evaluation is eliminated, converting the process into a
                  continual learning and development process, using the data for improvement and growth.</p>

            </div>
          </div>
        </div>
      </div>
        <div class="flex-center p-4">
          <button class="btn btn-outline-warning" (click)="scrollToTarget(top)">
          <span class="flex-center">
            <span class="mr-1">To top</span>
            <i class="fas fa-arrow-alt-circle-up"></i>
          </span>
          </button>
        </div>
    </div>

  </div>
