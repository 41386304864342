import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChoiceInputItem} from '../../../form-elements/models/form-elements.model';
import {NullUtil} from '../../../../utils/null-util';

const formatDate = (date) => {
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + day + ', ' + year;
};

const getCellValue = (row: any, columnPath: string[], isDate: boolean) => {
  let cellValue = row;
  for (const columnPathItem of columnPath) {
    if (cellValue) {
      cellValue = cellValue[columnPathItem];
    }
  }

  // format the date for 'createdOn' column
  if (cellValue) {
    if (columnPath[0] === 'createdOn' || isDate) {
      cellValue = formatDate(new Date(cellValue));
    }
  }
  return cellValue;
};

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit, OnChanges {
  theme: 'dark' | 'light' = 'dark';
  style: 'default' | 'bordered' = 'default';

  @Input() columns: any[];
  @Input() rows: any[];
  @Output() onChange = new EventEmitter<any[]>();

  cachedRows: any[];
  filterColumn: any;
  filterSearchValue: string;

  items: ChoiceInputItem[];

  filterInput = '';

  loadingResults: boolean;

  constructor() {
  }

  ngOnInit() {
    this.cachedRows = this.rows;
    this.loadingResults = false;

    this.items = this.setItems(this.columns).filter((el) => el.label !== 'Action');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.cachedRows = changes.rows.currentValue;
    }
    if (changes.columns) {
      this.columns = changes.columns.currentValue;
    }
  }

  filterRows(filterColumn: any) {
    this.filterColumn = filterColumn;
    this.loadingResults = true;
    const filterValue = this.filterSearchValue;
    let filterColumnValue = this.filterColumn ? this.filterColumn.prop || this.filterColumn.name.toLowerCase()
      : 'all';
    const pipe = this.filterColumn ? this.filterColumn.pipe : undefined;
    const arrayOfObjectsProp = this.filterColumn ? this.filterColumn.arrayOfObjectsProp : undefined;
    const isDate = this.filterColumn ? this.filterColumn.date : undefined;

    // return full set if search value is cleared
    if (!filterValue || filterValue === '') {
      this.rows = this.cachedRows;
      this.onChange.emit(this.rows);
      this.loadingResults = false;
      return;
    }

    // filter by all columns if column value is set to 'all'
    if (filterColumnValue === 'all') {
      this.rows = this.filterAllColumns(filterValue);
      this.onChange.emit(this.rows);
      this.loadingResults = false;
      return;
    }

    // split filterColumnValue to work for column.props like 'file.name' and 'file.size'
    // and filter our data
    filterColumnValue = filterColumnValue.split('.');
    this.rows = this.filterByColumn(filterColumnValue, filterValue, pipe, arrayOfObjectsProp, isDate);
    this.onChange.emit(this.rows);
    this.loadingResults = false;
  }

  updateFilterSearchValue(event) {
    this.loadingResults = true;
    if (NullUtil.isNotEmpty(event)) {
      this.filterSearchValue = event.toLowerCase();
    } else {
      this.filterSearchValue = '';
    }
    this.filterRows(this.filterColumn);
  }

  filterByColumn(columnPath: string[], filterValue: string, pipe: any, arrayOfObjectsProp: string, isDate: boolean) {
    return this.cachedRows.filter(cachedRow => {
      let cellValue = getCellValue(cachedRow, columnPath, isDate);

      if (pipe) {
        cellValue = pipe.transform(cellValue);
      }

      if (cellValue) {
        if (Array.isArray(cellValue)) {
          const result = cellValue.filter(value => {
            if (typeof value === 'object') {
              return value[arrayOfObjectsProp].toString().toLowerCase().indexOf(filterValue) !== -1;
            } else {
              return value.toString().toLowerCase().indexOf(filterValue) !== -1;
            }
          });
          return result && result.length;
        } else {
          return cellValue.toString().toLowerCase().indexOf(filterValue) !== -1;
        }
      }
    });
  }

  filterAllColumns(filterValue: string) {
    return this.cachedRows.filter(cachedRow => {
      let matched = false;
      for (const column of this.columns) {
        let cellValue;
        if (!column) {
          continue;
        }
        const pipe = column.pipe;
        const arrayOfObjectsProp = column.arrayOfObjectsProp;
        const isDate = column.date;
        if (column.prop) {
          const columnPath = column.prop.split('.');
          cellValue = getCellValue(cachedRow, columnPath, isDate);
        } else {
          cellValue = cachedRow[column.name.toLowerCase()];
        }

        if (pipe) {
          cellValue = pipe.transform(cellValue);
        }

        if (cellValue) {
          if (Array.isArray(cellValue)) {
            const result = cellValue.filter(value => {
              if (typeof value === 'object') {
                // if (value[arrayOfObjectsProp]) {
                return value[arrayOfObjectsProp].toString().toLowerCase().indexOf(filterValue) !== -1;
                // }
              } else {
                return value.toString().toLowerCase().indexOf(filterValue) !== -1;
              }
            });
            if (result && result.length) {
              matched = true;
              break;
            }
          }
          if (cellValue.toString().toLowerCase().indexOf(filterValue) !== -1) {
            matched = true;
            break;
          }
        }
      }
      return matched;
    });
  }

  setItems(columns): ChoiceInputItem[] {
    return columns.map(item => {
      return {
        label: item.name,
        value: item
      };
    });
  }
}
