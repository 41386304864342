<div *ngIf="selectedWorkPlan" class="card card-outline-primary mt-3">
  <div class="flex-between mx-3">
    <h4 class="card-title text-primary text-uppercase"><strong>Objectives</strong></h4>
    <app-add-objective
      *authenticatedWithRole="['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']"
      [isReadOnly]="isReadOnly"
      [coordinatorReadOnly]="coordinatorReadOnly"
      [workPlan]="selectedWorkPlan"
    ></app-add-objective>
  </div>

  <table
    mat-table
    multiTemplateDataRows
    [dataSource]="dataSource"
    class="mat-elevation-z8"
  >

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Name</th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Description</th>
      <td mat-cell *matCellDef="let row"> {{row.description | truncate: 50}} </td>
    </ng-container>

    <!-- Budget Column -->
    <ng-container matColumnDef="budget">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Budget</th>
      <td mat-cell *matCellDef="let row"> {{row.budget | currency: 'R'}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="bg-dark text-light"> Actions</th>
      <td mat-cell *matCellDef="let row">
        <div class="dropdown btn-group">
          <a ngbDropdown class="nav-item dropdown">
            <div class="nav-link dropdown-toggle user-toggle" ngbDropdownToggle>
              <button class="table-action-btn view">
                <i class="fas fa-ellipsis-h"></i>
              </button>
            </div>

            <div ngbDropdownMenu class="dropdown-menu bg-dark text-light">

              <!--                  <ng-container *authenticatedWithoutRole="['ROLE_APPLICANT']">-->
              <!--                    <app-edit-objective [objective]="row.item"></app-edit-objective>-->
              <!--                  </ng-container>-->

              <!--                  <hr class="dropdown-divider w-75 mx-auto">-->

              <a class="dropdown-item cursor-pointer text-light" (click)="routeToObjectiveDetail(row.item)">
                <p>View</p>
              </a>

              <hr class="dropdown-divider w-75 mx-auto">

              <a class="dropdown-item cursor-pointer text-light" (click)="deleteObjective(row.item)">
                <p>Delete</p>
              </a>
            </div>
          </a>
        </div>
      </td>
    </ng-container>


    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="expandable-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <!--              <div class="expandable-element-diagram">-->
          <!--                <div class="expandable-element-position"> {{element.position}} </div>-->
          <!--                <div class="expandable-element-symbol"> {{element.symbol}} </div>-->
          <!--                <div class="expandable-element-name"> {{element.name}} </div>-->
          <!--                <div class="expandable-element-weight"> {{element.weight}} </div>-->
          <!--              </div>-->
          <div class="row expandable-element-description">
            <div class="col-2">
              <strong>Description: </strong>
            </div>
            <div class="col-10">
              {{element.description}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>
          <div class="row expandable-element-description">
            <div class="col-2">
              <strong>Additional Description: </strong>
            </div>
            <div class="col-10">
              {{element.additionalDescription}}
            </div>
            <!--                <span class="expandable-element-description-attribution"> &#45;&#45; Wikipedia </span>-->
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="expandable-element-row"
        [class.expandable-expanded-row]="expandedElement === element"
        (click)="expandedElement = element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>
  </table>

</div>
