import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {take} from 'rxjs/operators';
import {CsFinancialSituationAnswerService} from './services/cs-financial-situation-answer.service';
import {booleanChoices} from '../../../../../../constants/boolean-answers.const';
import {
  createCSFinancialSituationFunderForm,
} from './components/cs-financial-situation-funder/store/cs-financial-situation-funder.model';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-financial-situation',
  templateUrl: './cs-financial-situation.component.html',
  styleUrls: ['./cs-financial-situation.component.scss']
})
export class CsFinancialSituationComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() financialSituationForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  funders: FormArray;

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  // Question Answers.
  annualOperatingBudgetAnswers = [];
  financialSituationAnswers = [];
  fundingMethodAnswers = [];
  incomeGenerationActivityAnswers = [];
  costSharingAnswers = [];
  fundingSourceAnswers = [];
  booleanChoices = booleanChoices;

  annualOperatingBudgetSubscription: Subscription;
  financialSituationSubscription: Subscription;
  fundingMethodSubscription: Subscription;
  incomeGenerationActivitySubscription: Subscription;
  costSharingSubscription: Subscription;
  fundingSourceSubscription: Subscription;
  childFormTouched: boolean;

  constructor(private loadingService: LoadingService,
              private csFinancialSituationAnswerService: CsFinancialSituationAnswerService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.financialSituationForm.touched;

    this.funders = this.financialSituationForm.get('funders') as FormArray;
    this.populateAnswers();
  }

  public addFunder() {
    const funder = createCSFinancialSituationFunderForm(this.formBuilder, {});
    this.funders.push(funder);
  }

  public removeFunder(idx: number) {
    this.funders.removeAt(idx);
  }

  public checkOther(value: string | boolean, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy(): void {
    if (this.annualOperatingBudgetSubscription) {
      this.annualOperatingBudgetSubscription.unsubscribe();
    }
    if (this.financialSituationSubscription) {
      this.financialSituationSubscription.unsubscribe();
    }
    if (this.fundingMethodSubscription) {
      this.fundingMethodSubscription.unsubscribe();
    }
    if (this.incomeGenerationActivitySubscription) {
      this.incomeGenerationActivitySubscription.unsubscribe();
    }
    if (this.costSharingSubscription) {
      this.costSharingSubscription.unsubscribe();
    }
    if (this.fundingSourceSubscription) {
      this.fundingSourceSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csFinancialSituationAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.annualOperatingBudgetSubscription = this.csFinancialSituationAnswerService.$annualOperatingBudget.subscribe(answers => {
      if (answers) {
        this.annualOperatingBudgetAnswers = answers.choices;
      }
    });

    this.financialSituationSubscription = this.csFinancialSituationAnswerService.$financialSituation.subscribe(answers => {
      if (answers) {
        this.financialSituationAnswers = answers.choices;
      }
    });

    this.fundingMethodSubscription = this.csFinancialSituationAnswerService.$fundingMethod.subscribe(answers => {
      if (answers) {
        this.fundingMethodAnswers = answers.choices;
      }
    });

    this.incomeGenerationActivitySubscription = this.csFinancialSituationAnswerService.$incomeGenerationActivity.subscribe(answers => {
      if (answers) {
        this.incomeGenerationActivityAnswers = answers.choices;
      }
    });

    this.costSharingSubscription = this.csFinancialSituationAnswerService.$costSharing.subscribe(answers => {
      if (answers) {
        this.costSharingAnswers = answers.choices;
      }
    });

    this.fundingSourceSubscription = this.csFinancialSituationAnswerService.$fundingSource.subscribe(answers => {
      if (answers) {
        this.fundingSourceAnswers = answers.choices;
      }
    });
  }
}
