<div class="accordion" *ngIf="!boxShape">
  <div class="cx-accordion-title" (click)="expand = !expand" [class.accordion-active]="expand">
    <span *ngIf="!expand"><b>+ </b></span>
    <span *ngIf="expand"><b>- </b></span>
    <span *ngIf="bulletHeading">&#8226;</span>
    <span>{{title}}</span>
    <span *ngIf="title2"><br>{{title2}}</span>
  </div>
  <div class="cx-accordion-content" [class.expand-content]="expand">
    <br>
    <ng-content></ng-content>
  </div>
</div>

<div
  *ngIf="boxShape"
  class="cx-accordion-title-box"
  [ngClass]="activeColor && expand ? 'border-' + activeColor : closedColor && !expand ? 'border-' + closedColor : 'border-dark'"
  [class.border-warning]="error"
  [class.border-secondary]="!error && theme === 'light'"
  [class.border-light]="!error && theme === 'dark'"
  [class.disabled]="disabled"
>
  <div
    class="cx-accordion-title2 py-2 px-4 row text-light"
    [ngClass]="activeColor && expand ? 'bg-' + activeColor : closedColor && !expand ? 'bg-' + closedColor : 'bg-dark'"
    [class.bg-danger]="error"
    (click)="expand = !expand"
  >
    <div class="col-6 text-left">
      <h5 class="m-0">
        {{title}}
      </h5>
    </div>
    <div class="col-6 text-right">
      <h5 class="m-0">
        <small *ngIf="!expand">Click to expand +</small>
        <small *ngIf="expand">Click to collapse -</small>
      </h5>
    </div>
  </div>
  <div class="cx-accordion-content" [class.expand-content]="expand">
    <ng-content select="[boxShape]"></ng-content>
  </div>
</div>
