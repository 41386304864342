import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  ContactDetails,
  createContactDetails,
  createOrganisation,
  Organisation
} from '../../store/organisation.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OrganisationService} from '../../store/organisation.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../../../shared/stores/session/session.service';
import {GeoLocationService} from '../../../../../services/geo-location/geo-location.service';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';
import {NullUtil} from '../../../../../shared/utils/null-util';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {profileCategoryAnswers, organisationTypeAnswers} from '../../../../constants/profile-category-answers.const';
import {GeoLocation} from '../../../../../shared/models/geo-location.model';
import {Subscription} from 'rxjs';
import {ChoiceInputItemUtil} from '../../../../../shared/utils/choice-input-item-util';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnChanges, OnDestroy {

  formSubmitted: boolean;
  provinceList: ChoiceInputItem[];
  districtList: ChoiceInputItem[];
  subDistrictList: ChoiceInputItem[];
  orgCodeRegex = [/[A-Z]/, /[A-Z]/, /[A-Z]/, /[A-Z]/, /\d/, /\d/];
  orgCodeDescription = '4 capital letters followed by 2 numbers.';
  categories = profileCategoryAnswers;
  organisationTypeItems = organisationTypeAnswers;

  geoLocationSubscription: Subscription;
  provinceListSubscription: Subscription;

  @Input() organisation: Organisation;
  @Input() profileForm: FormGroup;
  @Input() isSaving: boolean;
  @Input() fromReview: boolean;
  @Input() currentTab: string;

  @Output() tab = new EventEmitter<string>();

  constructor(private organisationService: OrganisationService,
              private sessionService: SessionService,
              private geoLocationService: GeoLocationService,
              private formBuilder: FormBuilder,
              private loadingService: LoadingService,
              private router: Router,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.loadingService.setIsLoading(true);
    this.provinceListSubscription = this.geoLocationService.provinceList.subscribe(list => {
      this.provinceList = ChoiceInputItemUtil.convertToChoiceInputItem(list);
    });
    this.fetchGeoLocation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isSaving && changes.isSaving.currentValue === true) {
      this.saveProfile(this.profileForm);
    }
  }

  ngOnDestroy() {
    if (this.geoLocationSubscription) {
      this.geoLocationSubscription.unsubscribe();
    }
    this.provinceListSubscription.unsubscribe();
  }

  private fetchGeoLocation() {
    const {
      subDistrictCode: sdCode,
      districtCode: dCode,
      provinceCode: pCode
    } = this.organisation.orgContact;

    if (sdCode) {
      // {fetchBySubDistrict and populate lists and codes}
      this.geoLocationSubscription = this.geoLocationService.getGeoLocationBySubDistrictCode(sdCode).subscribe(result => {
        this.populateGeoLocations(result);
      });
    } else if (dCode) {
      // {fetchByDistrict and populate lists and codes}
      this.geoLocationSubscription = this.geoLocationService.getGeoLocationByDistrictCode(dCode).subscribe(result => {
        this.populateGeoLocations(result);
      });
    } else if (pCode) {
      // {fetchByProvince and populate lists and codes}
      this.geoLocationSubscription = this.geoLocationService.getGeoLocationByProvinceCode(pCode).subscribe(result => {
        this.populateGeoLocations(result);
      });
    } else {
      this.loadingService.setIsLoading(false);
    }
  }

  private populateGeoLocations(geoLocation: GeoLocation) {
    const provinceCode = geoLocation.selectedProvince && geoLocation.selectedProvince.code;
    const districtCode = geoLocation.selectedDistrict && geoLocation.selectedDistrict.code;
    const subDistrictCode = geoLocation.selectedSubDistrict && geoLocation.selectedSubDistrict.code;

    const orgContact: Partial<ContactDetails> = createContactDetails(Object.assign({}, this.organisation.orgContact, {
      provinceCode,
      districtCode,
      subDistrictCode,
    }));

    this.organisation = createOrganisation(Object.assign({}, this.organisation, {orgContact}));

    this.subDistrictList = ChoiceInputItemUtil.convertToChoiceInputItem(geoLocation.subDistrictList);
    this.districtList = ChoiceInputItemUtil.convertToChoiceInputItem(geoLocation.districtList);
    this.loadingService.setIsLoading(false);
  }

  saveProfile(form: FormGroup) {
    this.formSubmitted = true;
    if (!form.valid) {
      this.alertService.setAlert('Please complete all fields before saving.', 'error', 'editProfile');
    }
  }

  refreshGeoLocation(selectedCode: string,
                     geoLocationType: 'province' | 'district' | 'subDistrict') {
    switch (geoLocationType) {
      case 'province': {
        if (NullUtil.isNotEmpty(selectedCode)) {
          // fetch district list
          this.geoLocationService.getDistrictListByProvinceCode(selectedCode).subscribe(results => {
            if (!this.districtList || !this.districtList.length || this.districtList[0].value !== results[0].code) {
              this.districtList = ChoiceInputItemUtil.convertToChoiceInputItem(results);
            }
          });
        } else {
          if (this.districtList && this.districtList.length) {
            this.districtList = [];
          }
          if (this.subDistrictList && this.subDistrictList.length) {
            this.subDistrictList = [];
          }
        }
        break;
      }
      case 'district': {
        if (NullUtil.isNotEmpty(selectedCode)) {
          // fetch sub-district list
          this.geoLocationService.getSubDistrictListByDistrictCode(selectedCode).subscribe(results => {
            if (!this.subDistrictList || !this.subDistrictList.length || this.subDistrictList[0].value !== results[0].code) {
              this.subDistrictList = ChoiceInputItemUtil.convertToChoiceInputItem(results);
            }
          });
        } else {
          if (this.subDistrictList && this.subDistrictList.length) {
            this.subDistrictList = [];
          }
        }
        break;
      }
    }
  }

  isEmpty(code: string) {
    return NullUtil.isEmpty(code);
  }

  isRequiredNPODateOrNumber() {
    return this.profileForm.value.organisationType.includes('NPO') ||
      this.profileForm.value.organisationType.includes('npoSection21') ||
      this.profileForm.value.organisationType.includes('earlyChildhood');
  }

  hasNextSection() {
    // section: 'generalInfo' | 'contactDetails' | 'contactPersons'
    return this.currentTab !== 'contactPersons';
  }

  hasPreviousSection() {
    // section: 'generalInfo' | 'contactDetails' | 'contactPersons'
    return this.currentTab !== 'generalInfo';
  }

  nextSection() {
    switch (this.currentTab) {
      case 'generalInfo': {
        this.tab.emit('contactDetails');
        break;
      }
      case 'contactDetails': {
        this.tab.emit('contactPersons');
        break;
      }
    }
  }

  previousSection() {
    switch (this.currentTab) {
      case 'contactDetails': {
        this.tab.emit('generalInfo');
        break;
      }
      case 'contactPersons': {
        this.tab.emit('contactDetails');
        break;
      }
    }
  }
}
