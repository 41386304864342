import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../shared/models/base-entity.model';
import {GrantWorkflow} from '../../../store/grant-workflow.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface Eligibility extends BaseEntity {
  grantWorkflow: GrantWorkflow;
  status: string;
  implementingDistricts: string[];
  directRecipientOfUsGovernment: boolean;
  subRecipientOfPepfarFunding: boolean;
  servingHiv: boolean;
}

export function createEligibility(params: Partial<Eligibility>) {
  return Object.assign({}, createBaseEntity(params), {
    grantWorkflow: params && params.grantWorkflow,
    status: params && params.status,
    implementingDistricts: params && params.implementingDistricts,
    directRecipientOfUsGovernment: params && params.directRecipientOfUsGovernment,
    subRecipientOfPepfarFunding: params && params.subRecipientOfPepfarFunding,
    servingHiv: params && params.servingHiv,
  }) as Eligibility;
}

export function createEligibilityForm(formBuilder: FormBuilder, params?: Partial<Eligibility>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    grantWorkflow: [params && params.grantWorkflow || '', Validators.required],
    status: [params && params.status || '', Validators.required],
    implementingDistricts: [params && params.implementingDistricts || [], Validators.required],
    directRecipientOfUsGovernment: [params && params.directRecipientOfUsGovernment, Validators.required],
    subRecipientOfPepfarFunding: [params && params.subRecipientOfPepfarFunding, Validators.required],
    servingHiv: [params && params.servingHiv, Validators.required],
  }));
}
