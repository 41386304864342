import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() title: string;
  @Input() title2: string;
  @Input() bulletHeading = false;
  @Input() disabled = false;
  @Input() boxShape = false;
  @Input() expand = false;
  @Input() error = false;
  @Input() theme?: 'dark' | 'light' = 'light';
  @Input() activeColor?: 'primary' | 'accent' | 'secondary' | 'success' | 'warning' | 'danger';
  @Input() closedColor?: 'primary' | 'accent' | 'secondary' | 'success' | 'warning' | 'danger';

  constructor() {
  }

  ngOnInit() {
  }


}
