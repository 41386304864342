import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {OrgCapacityComponent} from '../../../modules/organisation/org-capacity/components/org-capacity.component';
import {CancelConfirmHelperService} from './cancel-confirm-helper.service';
import {SessionService} from '../../stores/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class CancelConfirmGuard implements CanDeactivate<OrgCapacityComponent> {

  constructor(private cancelConfirmHelper: CancelConfirmHelperService,
              private sessionService: SessionService) {
  }

  canDeactivate(
    component: OrgCapacityComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Inject the url the user was attempting to reach back into the component.
    component.exitUrl = nextState.url;
    if (!component.warningDismissed && !component.hasSaved) {
      this.cancelConfirmHelper.hasConfirmedCancel = false;
      component.openSaveModal();
      return false;
    } else {
      this.cancelConfirmHelper.hasConfirmedCancel = true;
      if (this.cancelConfirmHelper.isLoggingOut) {
        this.sessionService.logout();
      }
      return true;
    }
  }

}
