import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {OrgCapacityValidationService} from '../../../../../org-capacity/services/org-capacity-validation.service';
import {FormValidationService} from '../../../../../../../services/form-validation/form-validation.service';
import {CsOrganisationalStructureAnswerService} from './services/cs-organisational-structure-answer.service';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {take} from 'rxjs/operators';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-organisational-structure',
  templateUrl: './cs-organisational-structure.component.html',
  styleUrls: ['./cs-organisational-structure.component.scss']
})
export class CsOrganisationalStructureComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() orgStructureForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  isLoading: Observable<boolean>;
  childFormTouched: boolean;

  // Question Answers.
  missionAnswers;
  visionAnswers;
  valueAndCultureAnswers;
  missionAndVisionAnswers;
  theoryOfChangeAnswers;
  constitutionAnswers;
  premisesContractAnswers;
  leaseContractAnswers;
  leaseHolderAnswers;
  premisesTypeAnswers;
  insuranceCoverageAnswers;
  manageSuppliesAnswers;
  tracksInformationAnswers;
  securityOfClientInformationAnswers;
  recordKeepingPolicyAnswers;
  staffProtectionPolicyAnswers;
  serviceDeliveryStandardsAnswers;
  informedOfServiceDeliveryStandardsAnswers;

  missionSubscription: Subscription;
  visionSubscription: Subscription;
  valueAndCultureSubscription: Subscription;
  missionAndVisionSubscription: Subscription;
  theoryOfChangeSubscription: Subscription;
  constitutionSubscription: Subscription;
  premisesContractSubscription: Subscription;
  leaseContractSubscription: Subscription;
  leaseHolderSubscription: Subscription;
  premisesTypeSubscription: Subscription;
  insuranceCoverageSubscription: Subscription;
  manageSuppliesSubscription: Subscription;
  tracksInformationSubscription: Subscription;
  securityOfClientInformationSubscription: Subscription;
  recordKeepingPolicySubscription: Subscription;
  staffProtectionPolicySubscription: Subscription;
  serviceDeliveryStandardsSubscription: Subscription;
  informedOfServiceDeliveryStandardsSubscription: Subscription;

  constructor(private validationService: OrgCapacityValidationService,
              private csOrganisationalStructureAnswerService: CsOrganisationalStructureAnswerService,
              private loadingService: LoadingService,
              public formValidation: FormValidationService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.orgStructureForm.touched;

    this.populateAnswers();
  }

  public checkForValue(value: string, selectedValue: string, control: AbstractControl, otherControl: AbstractControl) {
    checkForValue(value, selectedValue, control, otherControl);
  }

  ngOnDestroy() {
    // see this for unsubscribing... https://www.digitalocean.com/community/tutorials/angular-takeuntil-rxjs-unsubscribe
    if (this.missionSubscription) {
      this.missionSubscription.unsubscribe();
    }
    if (this.visionSubscription) {
      this.visionSubscription.unsubscribe();
    }
    if (this.valueAndCultureSubscription) {
      this.valueAndCultureSubscription.unsubscribe();
    }
    if (this.missionAndVisionSubscription) {
      this.missionAndVisionSubscription.unsubscribe();
    }
    if (this.theoryOfChangeSubscription) {
      this.theoryOfChangeSubscription.unsubscribe();
    }
    if (this.constitutionSubscription) {
      this.constitutionSubscription.unsubscribe();
    }
    if (this.premisesContractSubscription) {
      this.premisesContractSubscription.unsubscribe();
    }
    if (this.leaseContractSubscription) {
      this.leaseContractSubscription.unsubscribe();
    }
    if (this.leaseHolderSubscription) {
      this.leaseHolderSubscription.unsubscribe();
    }
    if (this.premisesTypeSubscription) {
      this.premisesTypeSubscription.unsubscribe();
    }
    if (this.insuranceCoverageSubscription) {
      this.insuranceCoverageSubscription.unsubscribe();
    }
    if (this.manageSuppliesSubscription) {
      this.manageSuppliesSubscription.unsubscribe();
    }
    if (this.tracksInformationSubscription) {
      this.tracksInformationSubscription.unsubscribe();
    }
    if (this.securityOfClientInformationSubscription) {
      this.securityOfClientInformationSubscription.unsubscribe();
    }
    if (this.recordKeepingPolicySubscription) {
      this.recordKeepingPolicySubscription.unsubscribe();
    }
    if (this.staffProtectionPolicySubscription) {
      this.staffProtectionPolicySubscription.unsubscribe();
    }
    if (this.serviceDeliveryStandardsSubscription) {
      this.serviceDeliveryStandardsSubscription.unsubscribe();
    }
    if (this.informedOfServiceDeliveryStandardsSubscription) {
      this.informedOfServiceDeliveryStandardsSubscription.unsubscribe();
    }

  }

  private populateAnswers() {
    this.csOrganisationalStructureAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.missionSubscription = this.csOrganisationalStructureAnswerService.$mission.subscribe(answers => {
      if (answers) {
        this.missionAnswers = answers.choices;
      }
    });

    this.visionSubscription = this.csOrganisationalStructureAnswerService.$vision.subscribe(answers => {
      if (answers) {
        this.visionAnswers = answers.choices;
      }
    });

    this.valueAndCultureSubscription = this.csOrganisationalStructureAnswerService.$valueAndCulture.subscribe(answers => {
      if (answers) {
        this.valueAndCultureAnswers = answers.choices;
      }
    });

    this.missionAndVisionSubscription = this.csOrganisationalStructureAnswerService.$missionAndVision.subscribe(answers => {
      if (answers) {
        this.missionAndVisionAnswers = answers.choices;
      }
    });

    this.theoryOfChangeSubscription = this.csOrganisationalStructureAnswerService.$theoryOfChange.subscribe(answers => {
      if (answers) {
        this.theoryOfChangeAnswers = answers.choices;
      }
    });

    this.constitutionSubscription = this.csOrganisationalStructureAnswerService.$constitution.subscribe(answers => {
      if (answers) {
        this.constitutionAnswers = answers.choices;
      }
    });

    this.premisesContractSubscription = this.csOrganisationalStructureAnswerService.$premisesContract.subscribe(answers => {
      if (answers) {
        this.premisesContractAnswers = answers.choices;
      }
    });

    this.leaseContractSubscription = this.csOrganisationalStructureAnswerService.$leaseContract.subscribe(answers => {
      if (answers) {
        this.leaseContractAnswers = answers.choices;
      }
    });

    this.leaseHolderSubscription = this.csOrganisationalStructureAnswerService.$leaseHolder.subscribe(answers => {
      if (answers) {
        this.leaseHolderAnswers = answers.choices;
      }
    });

    this.premisesTypeSubscription = this.csOrganisationalStructureAnswerService.$premisesType.subscribe(answers => {
      if (answers) {
        this.premisesTypeAnswers = answers.choices;
      }
    });

    this.insuranceCoverageSubscription = this.csOrganisationalStructureAnswerService.$insuranceCoverage.subscribe(answers => {
      if (answers) {
        this.insuranceCoverageAnswers = answers.choices;
      }
    });

    this.manageSuppliesSubscription = this.csOrganisationalStructureAnswerService.$manageSupplies.subscribe(answers => {
      if (answers) {
        this.manageSuppliesAnswers = answers.choices;
      }
    });

    this.tracksInformationSubscription = this.csOrganisationalStructureAnswerService.$tracksInformation.subscribe(answers => {
      if (answers) {
        this.tracksInformationAnswers = answers.choices;
      }
    });

    this.securityOfClientInformationSubscription =
      this.csOrganisationalStructureAnswerService.$securityOfClientInformation.subscribe(answers => {
        if (answers) {
          this.securityOfClientInformationAnswers = answers.choices;
        }
      });

    this.recordKeepingPolicySubscription = this.csOrganisationalStructureAnswerService.$recordKeepingPolicy.subscribe(answers => {
      if (answers) {
        this.recordKeepingPolicyAnswers = answers.choices;
      }
    });

    this.staffProtectionPolicySubscription = this.csOrganisationalStructureAnswerService.$staffProtectionPolicy.subscribe(answers => {
      if (answers) {
        this.staffProtectionPolicyAnswers = answers.choices;
      }
    });

    this.serviceDeliveryStandardsSubscription = this.csOrganisationalStructureAnswerService.$serviceDeliveryStandards.subscribe(answers => {
      if (answers) {
        this.serviceDeliveryStandardsAnswers = answers.choices;
      }
    });

    this.informedOfServiceDeliveryStandardsSubscription =
      this.csOrganisationalStructureAnswerService.$informedOfServiceDeliveryStandards.subscribe(answers => {
        if (answers) {
          this.informedOfServiceDeliveryStandardsAnswers = answers.choices;
        }
      });
  }
}
