<section [class]="fromGrantReview ? '' : 'col-lg-10 col-sm-12 mx-auto'">
  <div class="wrapper">
    <div class="w-100 card bg-dark" *ngIf="!fromGrantReview">
      <div class="card-body">
        <h4 class="text-light text-center">
            Application Funding Request for {{grantWindow.name}} {{grantWindow.year}} from {{grantWindow.funder | funderName}}
          <button
            type="button"
            class="intro-text text-right"
            placement="bottom-right"
            [ngbPopover]="theIntroText"
            [popoverClass]="'popover-width-700-light'"
          >
            <i class="far fa-question-circle fa-2x text-info text-shadow"></i>
          </button>
        </h4>
        <p class="text-light text-center">Application closes on {{grantWindow.projectedApplicationEndDate | date:'medium'}} SAST</p>
      </div>
    </div>

  <ng-template #theIntroText>
    <div class="intro-text-wrapper">

    </div>
    <p>
      You need to complete the following section to detail what you would do with the funding
      you are requesting and how much funding you would need to complete the planned activities.
      The information you enter in this section should match the proposed project description
      that you wrote above. Please remember, all requested funding must be used to provide care
      and support for PLHIV, targeted HIV testing and counseling services and/or evidence based
      prevention programs.
    </p>

    <p>
      Note: For each of the planned activities you are requesting funding for, you are required
      to attach quotes and/or cost calculations in the supporting documentation section at the
      end of the application.
    </p>

    <p>
      You will need to provide your budget according to the following categories. Here are some
      examples of the types of items that can be included in each category:
    </p>
    <ul>
      <li>Equipment / furniture – These are products that you invest in and keep, such as containers,
        gazebos, cooler boxes, computers, etc.
      </li>
      <li>
        Personnel – This is any cost you want to pay to a person to provide a specific service,
        such as adherence club facilitators, community health workers, program directors, etc.
      </li>
      <li>
        Travel / transport – This is any cost that you want to be used for travel, including public
        transport, petrol costs, etc. Note: in the supporting documentation section, ensure you
        provide an attachment which shows how you calculated your travel costs.
      </li>
      <li>
        Supplies / Materials – These are consumables such as workbooks, gloves, venue hire, testing
        incentives, etc.
      </li>
      <li>
        Administration – These are operational costs, such as rent, electricity, airtime, etc. Note:
        This should not be more than 10% of the total budget.
      </li>
      <li>Training – This is the cost of any type of training.</li>
    </ul>
  </ng-template>

    <div class="w-100 mx-auto">
      <div class="card" [class.mt-4]="!fromGrantReview">
        <app-spinner [loadingBool]="loading">
          <div class="card-body" *ngIf="applicationForm">
            <h4 class="text-center" *ngIf="fromGrantReview">
              <span class="text-capitalize">Application</span>
            </h4>
            <hr class="w-75 mx-auto border-primary" *ngIf="fromGrantReview">

            <app-alert
              class="w-100"
              [id]="'application'"
            ></app-alert>

            <form [formGroup]="applicationForm" class="w-95 mx-auto">

              <app-funding-request
                [fundingRequestForm]="applicationForm.controls.fundingRequest | asFormGroup"
                [isReadOnly]="isReadOnly"
                [fromReview]="fromGrantReview"
                (isValid)="appValid = $event"
              ></app-funding-request>

              <div *ngIf="!fromGrantReview" class="d-flex justify-content-end">

                <ng-container *ngIf="!fromApplicationHistory; else historyButtons">
                  <ng-container *ngIf="!isNotApplicant; else adminButtons">
                    <button class="btn btn-outline-primary" [disabled]="isReadOnly" (click)="save()">
                      Save Application
                    </button>
                    <button
                      *ngIf="appValid"
                      class="btn btn-outline-primary"
                      [disabled]="isReadOnly"
                      (click)="openSubmitModal(applicationForm)"
                    >
                      Submit Application
                    </button>

                    <!--// Create a mock button because the tooltip doesn't show if the button's [disabled] flag is true-->
                    <span
                      *ngIf="!appValid"
                      class="btn mock-button"
                      placement="top"
                      ngbTooltip="Unable to submit application form. All mandatory fields must be completed first."
                    >
                      Submit Application
                    </span>

                  </ng-container>
                </ng-container>
              </div>

            </form>

          </div>
        </app-spinner>
      </div>
    </div>
  </div>
</section>


<ng-template #submitModal>
<!--  TODO add this component see SX-121 -->
 <app-submit-application (data)="handleSubmitApplication($event)"></app-submit-application>
</ng-template>

<ng-template #adminButtons>
  <button class="btn btn-outline-dark mr-2" (click)="goBack()">Go back</button>
  <button [disabled]="isReadOnly" class="btn btn-outline-primary" (click)="save()">
    Save Application
  </button>
</ng-template>

<ng-template #historyButtons>
  <button class="btn btn-outline-dark mr-2" (click)="goBack()">Go back</button>
  <button class="btn btn-outline-primary" (click)="downloadPdf()">Save as PDF</button>
</ng-template>

<ng-template #saveBeforeLeaving>
  <div class="col-sm-6 col-lg-10 mx-auto m-3">
    <h3 class="text-danger text-center">ATTENTION!</h3>
    <p class="text-center">You have not saved the application, are you sure you wish to exit?</p>
    <hr class="dropdown-divider">
    <div class="w-100 d-flex justify-content-end">
      <button class="btn btn-danger mx-2" type="button" (click)="leaveApplication()">Exit Anyway</button>
      <button class="btn btn-success mx-2" type="button" autofocus (click)="closeModal()">Stay</button>
    </div>
  </div>
</ng-template>
