import {Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, SimpleChanges, OnChanges, OnDestroy} from '@angular/core';
import {MblTableComponent} from 'app/shared/modules/data-table/components/mbl-table/mbl-table.component';
import {Review} from '../../store/review-dashboard.model';
import {Subscription} from 'rxjs';
import {TruncateUtil} from 'app/shared/utils/truncate-util';
import {ScorePercentageUtil} from 'app/shared/utils/score-percentage-util';
import {FilterType} from '../../../../../../../shared/modules/charts/models/charts.model';
import * as html2pdf from 'html2pdf.js/dist/html2pdf';
import {CapacitySpectrumUtil} from '../../../../../../../shared/utils/capacity-spectrum-util';
import {SessionQuery} from '../../../../../../../shared/stores/session/session.query';

@Component({
  selector: 'app-review-user-table',
  templateUrl: './review-user-table.component.html',
  styleUrls: ['./review-user-table.component.scss']
})
export class ReviewUserTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() windowId: number;
  @Input() filterType: FilterType;
  @Input() masterList: Array<Review>;

  @Output() applicationFunding: EventEmitter<number> = new EventEmitter<number>();
  @Output() projectedFunding: EventEmitter<number> = new EventEmitter<number>();

  @Input() set clear(value: boolean) {
    if (value && value === true) {
      this.clearFilter();
      this.clear = false;
    }
  }

  @Output() clearChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('table', {static: true}) table: MblTableComponent;
  @ViewChild('organisationTmpl', {static: true}) organisationTmpl: TemplateRef<HTMLElement>;
  @ViewChild('provinceTmpl', {static: true}) provinceTmpl: TemplateRef<HTMLElement>;
  @ViewChild('districtTmpl', {static: true}) districtTmpl: TemplateRef<HTMLElement>;
  @ViewChild('subDistrictTmpl', {static: true}) subDistrictTmpl: TemplateRef<HTMLElement>;
  @ViewChild('currencyApplicationFunding', {static: true}) currencyApplicationFunding: TemplateRef<HTMLElement>;
  @ViewChild('currencyProjectedFunding', {static: true}) currencyProjectedFunding: TemplateRef<HTMLElement>;
  @ViewChild('coordinatorsTmpl', {static: true}) coordinatorsTmpl: TemplateRef<HTMLElement>;
  @ViewChild('tableActionsTemplate', {static: true}) tableActionsTemplate: TemplateRef<HTMLElement>;
  @ViewChild('scoreTmpl', {static: true}) scoreTmpl: TemplateRef<HTMLElement>;
  @ViewChild('appScoreTmpl', {static: true}) appScoreTmpl: TemplateRef<HTMLElement>;
  @ViewChild('scoreTemplate', {static: true}) scoreTemplate: TemplateRef<HTMLElement>;
  @ViewChild('budgetTmpl', {static: true}) budgetTmpl: TemplateRef<HTMLElement>;

  columns: any[];
  filterColumns: any[];
  cachedRows: Array<Review>;
  rows: Array<Review>;
  filteredList: Array<Review>;

  truncateName = TruncateUtil;

  reviewDashboardSubscription: Subscription;

  constructor(public scorePercentageUtil: ScorePercentageUtil,
              public capacitySpectrumUtil: CapacitySpectrumUtil,
              public sessionQuery: SessionQuery) {
  }

  ngOnInit() {
    this.columns = [
      {name: 'Organization', prop: 'legalName', width: '20%', cellTemplate: this.organisationTmpl, sort: true},
      {name: 'Tier', width: '5%', sort: true, align: 'center', cellTemplate: this.budgetTmpl},
      {name: 'Province', prop: 'province', width: '10%', cellTemplate: this.provinceTmpl, sort: true},
      {name: 'District', prop: 'district', width: '5%', cellTemplate: this.districtTmpl, sort: true},
      {name: 'Sub District', prop: 'subDistrict', width: '5%', cellTemplate: this.subDistrictTmpl, sort: true},
      {name: 'Application Funding', prop: 'applicationFunding', width: '5%', sort: true, cellTemplate: this.currencyApplicationFunding},
      {name: 'Projected Funding', prop: 'projectedFunding', width: '5%', sort: true, cellTemplate: this.currencyProjectedFunding},
      {name: 'Coordinator', prop: 'coordinator', cellTemplate: this.coordinatorsTmpl, width: '15%', sort: true},
      {name: 'Application Score', prop: 'appScore', width: '5%', cellTemplate: this.appScoreTmpl},
      {name: 'Capacity Score', prop: 'scoreDTO.totalScore.score', width: '5%', cellTemplate: this.scoreTmpl},
      {name: 'Actions', cellTemplate: this.tableActionsTemplate, width: '5%'},
    ];

    this.filterColumns = this.columns.filter(column => column.name === 'Organization' || column.name === 'Province' ||
      column.name === 'District' || column.name === 'Sub District');

    if (this.sessionQuery.hasRole(['ROLE_GRANT_COORDINATOR'])) {
      this.columns = this.columns.filter(column => column.name !== 'Coordinator');
    }

    this.filterList();
  }

  ngOnDestroy(): void {
    if (this.reviewDashboardSubscription) {
      this.reviewDashboardSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterType !== undefined && this.masterList) {
      this.filterList();
    }
  }

  onChangeData(rows: any[]) {
    this.rows = rows;
    if (this.table) {
      this.table.offset = 0;
    }
  }

  private filterList() {
    switch (this.filterType) {
      case 'inReview': {
        this.filteredList = this.masterList;
        break;
      }
      case 'declined': {
        this.filteredList = this.masterList.filter(row => row.reviewStep === 'DECLINED');
        break;
      }
      case 'assessment': {
        this.filteredList = this.masterList.filter(row => row.reviewStep === 'ASSESSMENT_SUBMITTED');
        break;
      }
      case 'recommended': {
        this.filteredList = this.masterList.filter(row => row.reviewStep === 'RECOMMENDED');
        break;
      }
      case 'workPlanSubmitted': {
        this.filteredList = this.masterList.filter(row => row.workPlanStatus === 'SUBMITTED');
        break;
      }
      case 'workPlanNotSubmitted': {
        this.filteredList = this.masterList.filter(row => row.workPlanStatus !== 'SUBMITTED');
        break;
      }
      case 'workPlanApproved': {
        this.filteredList = this.masterList.filter(row => row.workPlanStatus === 'APPROVED');
        break;
      }
      case 'workPlanNotApproved': {
        this.filteredList = this.masterList.filter(row => row.workPlanStatus !== 'APPROVED' && row.workPlanStatus === 'SUBMITTED');
        break;
      }
      default: {
        this.filteredList = this.masterList;
      }
    }

    this.cachedRows = this.filteredList;
    this.rows = this.filteredList;
    if (this.rows) {
      this.sumApplicationFunding();
      this.sumProjectedFunding();
    }
  }

  sumApplicationFunding() {
    let totalFunding = 0;
    if (this.masterList) {
      this.masterList.forEach(row => {
        totalFunding += row.applicationFunding;
      });
    }
    this.applicationFunding.emit(totalFunding);
  }

  sumProjectedFunding() {
    let projectedFundingTotal = 0;
    if (this.masterList) {
      this.masterList.forEach(row => {
        projectedFundingTotal += row.projectedFunding;
      });
    }
    this.projectedFunding.emit(projectedFundingTotal);
  }


  sort() {
    if (this.rows) {
      this.rows.sort((a, b) => {
        // const aShortlisted = this.isShortlisted(a);
        // const bShortlisted = this.isShortlisted(b);

        // if (!aShortlisted && bShortlisted) {
        //   return 1;
        // }
        // if (!bShortlisted && aShortlisted) {
        //   return -1;
        // }
        return 0;
      });
      this.rows = [...this.rows];
    }
  }

  clearFilter() {
    this.rows = this.masterList;
    this.cachedRows = this.masterList;
  }

  download() {
    const element = document.querySelector('#report');
    const opt = {
      jsPDF: {orientation: 'landscape'}
    };

    /* const worker = */
    html2pdf().set(opt).from(element).save();
  }
}
