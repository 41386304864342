import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const targetedPopulationAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'otherYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: `men who have sex with men`,
    value: 'menSexWithMen'
  },
  {
    label: `transgender persons`,
    value: 'transgenderPersons'
  },
  {
    label: `injecting drug users`,
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: `clients of sex workers`,
    value: 'csw'
  },
  {
    label: `military`,
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: `people living with HIV`,
    value: 'peopleWithHiv'
  },
  {
    label: 'gender based violence victims',
    value: 'genderViolence'
  },
  {
    label: 'adults 25+ / general population',
    value: 'adultPopulation'
  },
  {
    label: 'parents',
    value: 'parents'
  },
  {
    label: `caregivers of PLHIV`,
    value: 'caregivers'
  },
  {
    label: `HIV positive children`,
    value: 'hivChildren'
  }
];
