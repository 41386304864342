<!--<a class="dropdown-item cursor-pointer text-light" (click)="openObjectiveModal()">-->
<!--  Edit-->
<!--</a>-->
<button class="btn btn-primary float-right" (click)="openObjectiveModal()">
  Edit Objective
</button>

<ng-template #modalContent>
  <div>
    <div class="w-100 card card-login p-4">
      <button class="close-modal" type="button" (click)="closeModal()">
        <i class="far fa-times-circle"></i>
      </button>
      <form class="form" [formGroup]="profileForm" (ngSubmit)="updateObjective()">
        <div class="w-95 mx-auto">
          <h4 class="category text-primary text-center">
            Edit Objective
          </h4>
          <hr class="dropdown-divider border-primary w-50 mx-auto mb-4">

          <app-alert
            class="w-100"
            [id]="'editObjective'"
          ></app-alert>

          <app-input-text
            class="w-45"
            [label]="'Name'"
            [type]="'text'"
            [id]="'name'"
            [isRequired]="true"
            [style]="'bordered'"
            formControlName="name"
          ></app-input-text>

          <app-input-textarea
            class="w-45"
            [label]="'Description'"
            [id]="'description'"
            [isRequired]="true"
            [style]="'bordered'"
            formControlName="description"
          ></app-input-textarea>

          <app-input-textarea
            class="w-45"
            [label]="'Additional Description'"
            [id]="'additionalDescription'"
            [isRequired]="true"
            [style]="'bordered'"
            formControlName="additionalDescription"
          ></app-input-textarea>

        </div>


        <div class="d-flex justify-content-center">
          <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">Cancel</button>
          <button class="btn btn-outline-primary" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>

</ng-template>
