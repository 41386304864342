import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {createObjectiveForm, Objective} from '../store/objective.model';
import {LoadingService} from '../../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {WorkPlanAndTargetsQuery} from '../../../../../../store/work-plan-and-targets.query';

@Component({
  selector: 'app-objective-detail',
  templateUrl: './objective-detail.component.html',
  styleUrls: ['./objective-detail.component.scss']
})
export class ObjectiveDetailComponent implements OnInit {
  profileForm: FormGroup;
  selectedObjective: Objective;

  constructor(private formBuilder: FormBuilder,
              private workPlanAndTargetsQuery: WorkPlanAndTargetsQuery,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
      this.loadingService.setIsLoading(true);

      this.workPlanAndTargetsQuery.getSelectedObjectiveObservable().subscribe(element => {
        if (element) {
          this.selectedObjective = element;
          this.profileForm = createObjectiveForm(this.formBuilder, element);
          this.loadingService.setIsLoading(false);
        }
      });

  }

}
