import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ServiceMatrix} from './service-matrix.model';

export interface ServiceMatrixState extends EntityState<ServiceMatrix> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'service-matrix'})
export class ServiceMatrixStore extends EntityStore<ServiceMatrixState> {

  constructor() {
    super();
  }

}

