
  <div class="esx-page">
    <div #pageTop class="esx-content">
      <div class="flex-center">
        <div class="w-100 mx-auto">
          <div class="h-100 p-1 mt-2">

            <nav class="profile-tabs">
              <button
                class="profile-tab-item"
                [class.active-profile-tab]="currentTab === 'workPlan'"
                (click)="changeTab('workPlan')"
              >
                Work Plan
              </button>
              |
              <button
                class="profile-tab-item"
                [class.active-profile-tab]="currentTab === 'targets'"
                (click)="changeTab('targets')"
              >
                Targets
              </button>
              |
              <button
                class="profile-tab-item"
                [class.active-profile-tab]="currentTab === 'indicators'"
                (click)="changeTab('indicators')"
              >
                Indicators
              </button>
            </nav>

            <ng-container [ngSwitch]="currentTab">

              <ng-container *ngSwitchCase="'workPlan'">
                <app-work-plan></app-work-plan>
              </ng-container>

              <ng-container *ngSwitchCase="'targets'">
                <app-targets></app-targets>
              </ng-container>

              <ng-container *ngSwitchCase="'indicators'">
                <app-indicators></app-indicators>
              </ng-container>

            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
