<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="sidebar-heading">
    <p class="text-secondary disabled">MENU</p>
  </div>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li *ngIf="user.funderId && user.isSimSubmitted; else awaitingFunderInitialisation" [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/investor/dashboard"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        [routerLink]="'/funder-profile/profile/' + user.funderId"
      >Profile</a>
    </li>

    <li *ngIf="user.funderId" [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        [routerLink]="'/social-investment-menu/' + user.funderId"
      >Social Investment Menu</a>
    </li>

    <li *ngIf="user.funderId && user.isSimSubmitted">
      <ngb-accordion activeIds="ngb-panel-0">
        <ngb-panel title="Investment Opportunities" class="dropdown-toggle">
          <ng-template ngbPanelContent>
            <div [ngbNavItem]="8">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/active"
              >
                Current
              </a>
            </div>
            <div [ngbNavItem]="9">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/shortlist"
              >
                Shortlist
              </a>
            </div>
            <div [ngbNavItem]="10">
              <a
                ngbNavLink
                class="sidebar-link"
                routerLink="/grant-window/list/funder/review"
              >
                Review
              </a>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </li>

  </ul>
</div>

<ng-template #awaitingFunderInitialisation>
<!--  TODO -->
</ng-template>
