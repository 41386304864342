import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';

export enum MarketingAndPromotionEndpointType {
  MARKETING_STRATEGY = 'MARKETING_STRATEGY',
  COMMUNICATION_FREQUENCY = 'COMMUNICATION_FREQUENCY',
  STORY_FREQUENCY = 'STORY_FREQUENCY',
  MARKETING_STAFF = 'MARKETING_STAFF',
  LOGO = 'LOGO',
  BRANDING_POLICY = 'BRANDING_POLICY',
  BROCHURE = 'BROCHURE',
  WEBSITE = 'WEBSITE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  NEWSLETTER = 'NEWSLETTER'
}

export interface CSMarketingAndPromotion extends BaseEntity {
  marketingStrategy: string;
  communicationFrequency: string;
  storyFrequency: string;
  marketingStaff: string;
  logo: string;
  brandingPolicy: string;
  brochure: string;
  website: string;
  socialMedia: string;
  newsletter: string;
}

export function createCSMarketingAndPromotion(params: Partial<CSMarketingAndPromotion>) {
  return Object.assign({}, createBaseEntity(params), {
    marketingStrategy: params && params.marketingStrategy,
    communicationFrequency: params && params.communicationFrequency,
    storyFrequency: params && params.storyFrequency,
    marketingStaff: params && params.marketingStaff,
    logo: params && params.logo,
    brandingPolicy: params && params.brandingPolicy,
    brochure: params && params.brochure,
    website: params && params.website,
    socialMedia: params && params.socialMedia,
    newsletter: params && params.newsletter,
  }) as CSMarketingAndPromotion;
}

export function createCSMarketingAndPromotionForm(formBuilder: FormBuilder, params: Partial<CSMarketingAndPromotion>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    marketingStrategy: [params && params.marketingStrategy, Validators.required],
    communicationFrequency: [params && params.communicationFrequency, Validators.required],
    storyFrequency: [params && params.storyFrequency, Validators.required],
    marketingStaff: [params && params.marketingStaff, Validators.required],
    logo: [params && params.logo, Validators.required],
    brandingPolicy: [params && params.brandingPolicy, Validators.required],
    brochure: [params && params.brochure, Validators.required],
    website: [params && params.website, Validators.required],
    socialMedia: [params && params.socialMedia, Validators.required],
    newsletter: [params && params.newsletter, Validators.required],
  }));
}
