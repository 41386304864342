import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {
  typesOfCommunityMobilizationAnswers, focusedTopicsAnswers, peopleTargetedAnswers,
  locationConductedAnswers, frequencyOfCommunityMobilization
} from '../../constants/funding-request-answers/community-mobilization-question-answers.const';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {FormValidationService} from '../../../../../../../services/form-validation/form-validation.service';
import {checkForValue} from '../../../../../../organisation/capacity-spectrum/components/capacity-spectrum/store/capacity-spectrum.model';

@Component({
  selector: 'app-community-mobilization',
  templateUrl: './community-mobilization.component.html',
  styleUrls: ['./community-mobilization.component.scss']
})
export class CommunityMobilizationComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() communityMobilizationForm: FormGroup;
  @Input() formValid: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  typesOfCommunityMobilizationOptions = typesOfCommunityMobilizationAnswers;
  locationConductedOptions = locationConductedAnswers;
  focusedTopicsOptions = focusedTopicsAnswers;
  peopleTargetedOptions = peopleTargetedAnswers;
  frequencyOfCommunityMobiOptions = frequencyOfCommunityMobilization;
  numberMask: any;

  durationOfTypeOptions = [
    {
      label: 'Days',
      value: 'Days'
    },
    {
      label: 'Months',
      value: 'Months'
    }
  ];

  constructor(public formValidationService: FormValidationService) {
  }

  ngOnInit() {
    this.numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      allowNegative: false,
      includeThousandsSeparator: false
    });
  }

  checkOther(value: string | boolean, selectedValue: string, controlParent: AbstractControl, controlChild: AbstractControl) {
    checkForValue(value, selectedValue, controlParent, controlChild);
  }
}
