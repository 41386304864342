<div class="w-100 card card-login p-4">
  <button class="close-modal" type="button" (click)="closeModal()">
    <i class="far fa-times-circle"></i>
  </button>
  <form class="form" [formGroup]="profileForm" (ngSubmit)="update(profileForm)">
    <div class="w-95 mx-auto">
      <h4 class="category text-primary text-center">
        Message
      </h4>

      <app-spinner [loadingObs]="isLoading">
        <hr class="dropdown-divider border-primary w-50 mx-auto mb-4">
        <app-alert
          class="w-100"
          [id]="'reviewMessageModal'"
        ></app-alert>

        <app-input-textarea
          [label]="'Message'"
          [id]="'message'"
          [isRequired]="true"
          [showOptional]="false"
          [theme]="'light'"
          [style]="'default'"
          [maxLength]="1000"
          formControlName="message"
        ></app-input-textarea>

      </app-spinner>

    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">Cancel</button>
      <button class="btn btn-outline-primary" type="submit">Submit</button>
    </div>
  </form>
</div>
