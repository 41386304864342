<div class="wrapper esx-page">

  <div class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Template Form
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto esx-content">
    <div class="h-100 mt-2">
      <app-spinner [loadingBool]="loading">
        <div class="card-body mx-auto">
          <app-alert
            class="w-100"
            [id]="'formBuilder'"
          ></app-alert>
          <form [formGroup]="formBuilderForm" (submit)="save(formBuilderForm)">
            <div class="flex-between flex-wrap flex-align-center flex-center">
              <app-input-text
                class="w-50 mx-auto"
                [label]="'Form Name'"
                [id]="'name'"
                [style]="'default'"
                [theme]="'light'"
                [isRequired]="true"
                [isReadOnly]="!isEditable"
                formControlName="name"
              ></app-input-text>
            </div>

            <!-- Questions -->
            <ng-container *ngIf="formEntity?.id">
              <div class="card mt-4">
                <div class="card-body">
                  <div class="flex-around bg-dark">
                    <div class="w-33"></div>
                    <div class="w-33 flex-center">
                      <h4 class="text-light text-center">
                        Questions
                      </h4>
                    </div>
                    <div class="w-33 flex-end">
                      <button
                        *ngIf="isEditable"
                        class="btn btn-primary btn-sm mr-2"
                        (click)="openModal(undefined, true)"
                      >
                        Add Question
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 tables">
                      <app-mbl-table
                        #table
                        [columns]="columns"
                        [rows]="formEntity.questions"
                      ></app-mbl-table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="d-flex justify-content-center">
              <button class="btn btn-danger btn-lg mr-2" type="button" routerLink="/form-builder/template/list">
                Close
              </button>
              <button *ngIf="isEditable" class="btn btn-primary btn-lg" type="submit">Save</button>
              <button
                class="btn btn-success btn-lg ml-2"
                type="button"
                [class.disabled]="formEntity?.questions?.length < 0 || !formEntity?.id"
                [disabled]="formEntity?.questions?.length < 0 || !formEntity?.id"
                [routerLink]="'/form-builder/render/' + formEntity.id"
              >
                Preview
              </button>
            </div>
          </form>

        </div>
      </app-spinner>
    </div>
  </div>
</div>

<ng-template #requiredTmpl let-row="row" let-value="value">
  <p>{{row.required | singleChoiceInputType : booleanChoices}}</p>
</ng-template>

<ng-template #controlTypeTmpl let-row="row" let-value="value">
  <p>{{row.controlType | singleChoiceInputType : controlTypeChoices}}</p>
</ng-template>

<ng-template #actionTmpl let-row="row" let-value="value">
  <div class="dropdown btn-group">
    <a ngbDropdown class="nav-item dropdown">
      <div class="nav-link dropdown-toggle user-toggle" ngbDropdownToggle>
        <button class="table-action-btn view" type="button">
          <i class="fas fa-ellipsis-h"></i>
        </button>
      </div>

      <div ngbDropdownMenu class="dropdown-menu bg-dark text-light">

        <a class="dropdown-item cursor-pointer text-light text-center" (click)="openModal(row, false)">
          <p>Edit</p>
        </a>

        <hr class="dropdown-divider w-75 mx-auto">

        <a class="dropdown-item cursor-pointer text-light" (click)="delete(row)">
          <p>Delete</p>
        </a>

      </div>
    </a>
  </div>
</ng-template>
