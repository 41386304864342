import { ChoiceInputItem } from 'app/shared/modules/form-elements/models/form-elements.model';

export const sidePanelYesNoAnswers: ChoiceInputItem[]  = [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }

];
