import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {User} from 'app/shared/stores/user/user.model';
import {ReviewService} from '../../store/review.service';
import {LoadingService} from 'app/shared/modules/shared-common/services/loading/loading.service';
import {ReviewModel, createReviewForm} from '../../store/review.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ReviewMessageActionType, ReviewStepType} from '../../../grant-workflow/store/grant-workflow.model';
// tslint:disable-next-line:max-line-length
import {ModalService} from '../../../../shared/modules/shared-common/services/modal/modal.service';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ReviewMessageModalComponent} from './review-message-modal/review-message-modal.component';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';
import {take, tap} from 'rxjs/operators';

@Component({
  selector: 'app-review-side-panel',
  templateUrl: './review-side-panel.component.html',
  styleUrls: ['./review-side-panel.component.scss']
})
export class ReviewSidePanelComponent implements OnInit, OnChanges {

  @Input() coordinators: User[];
  @Input() reviewDetails: ReviewModel;
  @Input() workflowId: number;
  @Output() refresh = new EventEmitter<boolean>();
  @Input() isReadOnly: boolean;

  dropdownStates = {
    capacitySpectrum: false,
    serviceMatrix: false,
    internalVerification: false,
    telephoneInterview: false,
    siteVisit: false,
    commentHistory: false,
    additionalDocuments: false
  };
  isLoading: boolean;
  reviewForm: FormGroup;
  coordinatorReviewForm: FormGroup;
  options: NgbModalOptions;

  constructor(private reviewService: ReviewService,
              private loadingService: LoadingService,
              private modalService: ModalService,
              private alertService: AlertService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.options = {
      centered: true,
      scrollable: true
    };

    this.reviewForm = createReviewForm(this.formBuilder, this.reviewDetails);
    this.coordinatorReviewForm = this.reviewForm.controls.coordinatorReview as FormGroup;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.reviewDetails) {
      if (this.reviewForm) {
        this.reviewForm.patchValue(this.reviewDetails);
      } else {
        this.reviewForm = createReviewForm(this.formBuilder, this.reviewDetails);
      }

      this.coordinatorReviewForm = this.reviewForm.controls.coordinatorReview as FormGroup;
    }
  }

  createOrUpdate(isSaving?: boolean) {
    this.isLoading = true;
    if (isSaving) {
      if (this.reviewDetails.id) {
        this.updateReviewDetails().subscribe();
      } else {
        this.createReviewDetails().subscribe();
      }
    } else {
      if (this.reviewDetails.id) {
        return this.updateReviewDetails();
      } else {
        return this.createReviewDetails();
      }
    }
  }

  createReviewDetails() {
    const value = this.reviewForm.value;
    return this.reviewService.createReviewDetails(value).pipe(tap(() => {
      this.refresh.emit(true);
      this.isLoading = false;
    }));
  }

  updateReviewDetails() {
    const value = this.reviewForm.value;
    return this.reviewService.updateReviewDetails(value).pipe(tap(() => {
      this.refresh.emit(true);
      this.isLoading = false;
    }));
  }

  isInReview() {
    return this.reviewDetails && this.reviewDetails.grantWorkflow &&
      (!this.reviewDetails.grantWorkflow.reviewStep ||
        this.reviewDetails.grantWorkflow.reviewStep === 'IN_REVIEW');
  }

  openModal(action: ReviewMessageActionType, step: ReviewStepType) {
    this.createOrUpdate().pipe(take(1)).subscribe(() => {
      const modalRef = this.modalService.openStackedModal(ReviewMessageModalComponent, this.options, [
        {key: 'action', value: action},
        {key: 'step', value: step},
        {key: 'workflowId', value: this.workflowId},
      ]);

      modalRef.result.then(reason => {
        // This runs when the modal is closed with the close() function
        if (reason === 'saved') {
          this.refresh.emit(true);
        }
      }).catch(() => {
        // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
      });
    });
  }

  recommend() {
    this.openModal(ReviewMessageActionType.RECOMMENDED, ReviewStepType.RECOMMENDED);
  }

  reject() {
    this.openModal(ReviewMessageActionType.REJECTED, ReviewStepType.IN_REVIEW);
  }

  removeFromRecommended() {
    this.openModal(ReviewMessageActionType.REMOVED_FROM_RECOMMENDED, ReviewStepType.ASSESSMENT_SUBMITTED);
  }

  submit() {
    this.openModal(ReviewMessageActionType.ASSESSMENT_SUBMITTED, ReviewStepType.ASSESSMENT_SUBMITTED);
  }

  decline() {
    this.openModal(ReviewMessageActionType.DECLINED, ReviewStepType.DECLINED);
  }
}
