import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../../shared/models/base-entity.model';
import {Objective} from '../../store/objective.model';

export interface Activity extends BaseEntity {
  objective: Objective;
  name?: string;
  description?: string;
  monitoringEvaluation?: string;
  timeframe?: string;
  responsiblePerson?: string;
  costCategory?: string;
  cost?: number;
  explanationOfCost?: string;
  paymentEvidence?: Array<string>;
  paymentEvidenceOther?: string;
}


export function createActivity(params: Partial<Activity>) {
  return Object.assign({}, createBaseEntity(params), {
    objective: params && params.objective,
    name: params && params.name,
    description: params && params.description,
    monitoringEvaluation: params && params.monitoringEvaluation,
    timeframe: params && params.timeframe,
    responsiblePerson: params && params.responsiblePerson,
    costCategory: params && params.costCategory,
    cost: params && params.cost,
    explanationOfCost: params && params.explanationOfCost,
    paymentEvidence: params && params.paymentEvidence,
    paymentEvidenceOther: params && params.paymentEvidenceOther,
  }) as Activity;
}

export function createActivityForm(formBuilder: FormBuilder, params?: Partial<Activity>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    objective: [params && params.objective, Validators.required],
    name: [params && params.name || '', Validators.required],
    description: [params && params.description || ''],
    monitoringEvaluation: [params && params.monitoringEvaluation || ''],
    timeframe: [params && params.timeframe || ''],
    responsiblePerson: [params && params.responsiblePerson || ''],
    costCategory: [params && params.costCategory || '', Validators.required],
    cost: [params && params.cost],
    explanationOfCost: [params && params.explanationOfCost || ''],
    paymentEvidence: [params && params.paymentEvidence || []],
    paymentEvidenceOther: [params && params.paymentEvidenceOther]
  }));
}
