<!--<div [class]="style + '-input-group position-relative'" [class.input-is-grey]="isGrey">-->
<!--  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">-->
<!--    <p-->
<!--      [class]="style + '-input-multi-legend label-readonly'"-->
<!--      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"-->
<!--    >{{label}}</p>-->
<!--  </div>-->
<!--  <div-->
<!--    [class.disabled-multi]="isGrey"-->
<!--    [class]="style + '-input-multi-group checkbox-group readonly-input'"-->
<!--    [class.borderless]="!hasBorder"-->
<!--    [ngClass]="theme === 'light' ? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"-->
<!--  >-->
<!--    <ng-container *ngFor="let choice of choices">-->
<!--      <div class="flex-start">-->
<!--        <i-->
<!--          class="far radio-btn-icon"-->
<!--          [class.bg-light]="theme === 'light'"-->
<!--          [class.bg-dark]="theme === 'dark'"-->
<!--          [ngClass]="choice.value === value ? 'fas fa-dot-circle' : 'far fa-circle'"-->
<!--        ></i>-->
<!--        <p-->
<!--          class="font-weight-normal font-size-small"-->
<!--          [ngClass]="theme === 'dark' ? 'text-grey' : 'text-dark'"-->
<!--        >-->
<!--          {{choice.label}}-->
<!--        </p>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--</div>-->



<div
  [class]="style + '-input-group position-relative'"
  [class.input-is-grey]="isGrey"
>
  <div
    [ngClass]="style === 'default' ? 'input-label-text' : ''"
  >
    <p
      [class]="style + '-input-multi-legend label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>
  <div
    class="w-100"
    [class.wrap-checkboxes-small]="choices?.length > 8 && choices?.length < 16"
    [class.wrap-checkboxes-large]="choices?.length > 16"
  >
<!--      [cols]="cols"-->
    <div
      appItemGrid
      [arrayLength]="choices?.length"
      [class.disabled-multi]="isGrey"
      [class]="style + '-input-multi-group checkbox-group readonly-input'"
      [class.borderless]="!hasBorder"
      [ngClass]="theme === 'light' ? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    >
      <ul>
        <ng-container *ngFor="let choice of choices">
          <li
            *ngIf="value === choice.value"
            class="font-size-small"
            [ngClass]="theme === 'dark' ? 'text-grey' : 'text-dark'"
            [innerHTML]="choice.label"
          ></li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
