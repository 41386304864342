import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {OrganisationalStructureEndpointType} from '../store/cs-organisational-structure.model';

@Injectable({
  providedIn: 'root'
})
export class CsOrganisationalStructureAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/organisational-structure`;

  public missionSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $mission = this.missionSource.asObservable();

  public visionSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $vision = this.visionSource.asObservable();

  public valueAndCultureSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $valueAndCulture = this.valueAndCultureSource.asObservable();

  public missionAndVisionSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $missionAndVision = this.missionAndVisionSource.asObservable();

  public theoryOfChangeSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $theoryOfChange = this.theoryOfChangeSource.asObservable();

  public constitutionSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $constitution = this.constitutionSource.asObservable();

  public premisesContractSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $premisesContract = this.premisesContractSource.asObservable();

  public leaseContractSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $leaseContract = this.leaseContractSource.asObservable();

  public leaseHolderSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $leaseHolder = this.leaseHolderSource.asObservable();

  public premisesTypeSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $premisesType = this.premisesTypeSource.asObservable();

  public insuranceCoverageSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $insuranceCoverage = this.insuranceCoverageSource.asObservable();

  public manageSuppliesSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $manageSupplies = this.manageSuppliesSource.asObservable();

  public tracksInformationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $tracksInformation = this.tracksInformationSource.asObservable();

  public securityOfClientInformationSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $securityOfClientInformation = this.securityOfClientInformationSource.asObservable();

  public recordKeepingPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $recordKeepingPolicy = this.recordKeepingPolicySource.asObservable();

  public staffProtectionPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $staffProtectionPolicy = this.staffProtectionPolicySource.asObservable();

  public serviceDeliveryStandardsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $serviceDeliveryStandards = this.serviceDeliveryStandardsSource.asObservable();

  public informedOfServiceDeliveryStandardsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $informedOfServiceDeliveryStandards = this.informedOfServiceDeliveryStandardsSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.missionSource.getValue()) {
      // const types: OrganisationalStructureEndpointType[] = [
      //   OrganisationalStructureEndpointType.MISSION,
      //   OrganisationalStructureEndpointType.VISION
      // ];
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case OrganisationalStructureEndpointType.MISSION: {
          this.missionSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.VISION: {
          this.visionSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.VALUE_AND_CULTURE: {
          this.valueAndCultureSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.MISSION_AND_VISION: {
          this.missionAndVisionSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.THEORY_OF_CHANGE: {
          this.theoryOfChangeSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.CONSTITUTION: {
          this.constitutionSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.PREMISES_CONTRACT: {
          this.premisesContractSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.LEASE_CONTRACT: {
          this.leaseContractSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.LEASE_HOLDER: {
          this.leaseHolderSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.PREMISES_TYPE: {
          this.premisesTypeSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.INSURANCE_COVERAGE: {
          this.insuranceCoverageSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.MANAGE_SUPPLIES: {
          this.manageSuppliesSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.TRACKS_INFORMATION: {
          this.tracksInformationSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.SECURITY_OF_CLIENT_INFORMATION: {
          this.securityOfClientInformationSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.RECORD_KEEPING_POLICY: {
          this.recordKeepingPolicySource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.STAFF_PROTECTION_POLICY: {
          this.staffProtectionPolicySource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.SERVICE_DELIVERY_STANDARDS: {
          this.serviceDeliveryStandardsSource.next(answerListDTO);
          break;
        }
        case OrganisationalStructureEndpointType.INFORMED_OF_SERVICE_DELIVERY_STANDARDS: {
          this.informedOfServiceDeliveryStandardsSource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
