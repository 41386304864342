import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbTooltipModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormElementsModule} from '../../shared/modules/form-elements/form-elements.module';
import {SharedCommonModule} from '../../shared/modules/shared-common/shared-common.module';
import {WorkPlanAndTargetsRoutingModule} from './work-plan-and-targets-routing.module';
import {DataTableModule} from '../../shared/modules/data-table/data-table.module';
import {WorkPlanAndTargetsComponent} from './components/work-plan-and-targets/work-plan-and-targets.component';
import {WorkPlanFundingRequestComponent} from './components/work-plan-and-targets/work-plan/funding-request/work-plan-funding-request.component';
import {ObjectiveComponent} from './components/work-plan-and-targets/work-plan/funding-request/objective/objective.component';
import {ActivityComponent} from './components/work-plan-and-targets/work-plan/funding-request/objective/activity/activity.component';
import {AccordionModule} from '../../shared/modules/accordion/accordion.module';
import {AddObjectiveComponent} from './components/work-plan-and-targets/work-plan/funding-request/objective/add-objective/add-objective.component';
import {AccordionTwoModule} from '../../shared/modules/accordion-two/accordion-two.module';
import {
  EditObjectiveComponent
} from './components/work-plan-and-targets/work-plan/funding-request/objective/edit-objective/edit-objective.component';
import {
  AddActivityComponent
} from './components/work-plan-and-targets/work-plan/funding-request/objective/activity/add-activity/add-activity.component';
import {
  EditActivityComponent
} from './components/work-plan-and-targets/work-plan/funding-request/objective/activity/edit-activity/edit-activity.component';
import {UpdateBudgetComponent} from './components/work-plan-and-targets/work-plan/funding-request/update-budget/update-budget.component';
import {ToastContainerModule} from 'ngx-toastr';
import {WorkPlanStatusPipe} from './pipes/work-plan-status.pipe';
import {WorkPlanMessagesComponent} from './components/work-plan-and-targets/work-plan/work-plan-messages/work-plan-messages.component';
import {
  WorkPlanMessageModalComponent
} from './components/work-plan-and-targets/work-plan/work-plan-messages/work-plan-message-modal/work-plan-message-modal.component';
import {AlertModule} from '../../shared/modules/alert/alert.module';
import {ChartsModule} from '../../shared/modules/charts/charts.module';
import {MatTableModule} from '@angular/material/table';
import { ObjectiveDetailComponent } from './components/work-plan-and-targets/work-plan/funding-request/objective/objective-detail/objective-detail.component';
import { WorkPlanComponent } from './components/work-plan-and-targets/work-plan/work-plan.component';
import { TargetsComponent } from './components/work-plan-and-targets/targets/targets.component';
import { IndicatorsComponent } from './components/work-plan-and-targets/indicators/indicators.component';


@NgModule({
  declarations: [
    WorkPlanAndTargetsComponent,
    WorkPlanFundingRequestComponent,
    ObjectiveComponent,
    ActivityComponent,
    AddObjectiveComponent,
    EditObjectiveComponent,
    AddActivityComponent,
    EditActivityComponent,
    UpdateBudgetComponent,
    WorkPlanStatusPipe,
    WorkPlanMessagesComponent,
    WorkPlanMessageModalComponent,
    ObjectiveDetailComponent,
    WorkPlanComponent,
    TargetsComponent,
    IndicatorsComponent,
  ],
  imports: [
    CommonModule,
    WorkPlanAndTargetsRoutingModule,
    NgbTooltipModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    FormElementsModule,
    SharedCommonModule,
    MatTableModule,
    DataTableModule,
    AccordionModule,
    AccordionTwoModule,
    ToastContainerModule,
    AlertModule,
    ChartsModule
  ],
  exports: [
    WorkPlanMessageModalComponent,
  ],
  entryComponents: [
    WorkPlanMessageModalComponent,
  ]
})
export class WorkPlanAndTargetsModule {
}
