<div class="text-center mt-3">
  <span class="h4">Regret Mail</span>
  <span>
    <button
      type="button"
      class="close close-mbl-modal mr-3"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </span>
</div>

<app-spinner [loadingObs]="loading">
  <div class="w-100 card card-login bg-dark p-4 opacity-8">
    <form *ngIf="mailForm" [formGroup]="mailForm">

      <app-alert
        class="w-100"
        [id]="'email'"
      ></app-alert>

      <app-input-text
        [label]="'Subject'"
        [id]="'subject'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        formControlName="subject"
      ></app-input-text>

      <app-input-textarea
        [label]="'Message'"
        [id]="'message'"
        [isRequired]="true"
        [showOptional]="false"
        [theme]="theme"
        [style]="style"
        [rows]="'13'"
        [maxLength]="4500"
        formControlName="message"
      ></app-input-textarea>

      <br/>

      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-danger mr-2" type="button" (click)="closeModal()">Cancel</button>
        <button class="btn btn-outline-primary" type="submit" (click)="send(mailForm)">Send</button>
      </div>
    </form>
  </div>
</app-spinner>
