import {Enum} from '../models/enum.model';

export class ChoiceInputItemUtil {
    static convertToChoiceInputItem(list: Enum[]) {
      if (list) {
        return list.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
      }
      return [];
    }
}
