import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum InnovationEndpointType {
  COMPUTER_ACCESS = 'COMPUTER_ACCESS',
  INTERNET_ACCESS = 'INTERNET_ACCESS',
  INTERNET_CONNECTION = 'INTERNET_CONNECTION',
  IT_EQUIPMENT = 'IT_EQUIPMENT',
  SYSTEM_SUPPORT = 'SYSTEM_SUPPORT',
  IT_POLICY = 'IT_POLICY',
  POPI_POLICY = 'POPI_POLICY',
  SOFTWARE = 'SOFTWARE',
  CUSTOM_SOFTWARE = 'CUSTOM_SOFTWARE',
  TECH_TRAINING = 'TECH_TRAINING',
  MANAGEMENT_SKILLS = 'MANAGEMENT_SKILLS',
  ADMIN_SKILLS = 'ADMIN_SKILLS',
  GENERAL_STAFF_SKILLS = 'GENERAL_STAFF_SKILLS'
}

export interface CSInnovation extends BaseEntity {
  computerAccess: string;
  internetAccess: string;
  internetConnection: string;
  internetConnectionOther: string; // if internetConnection === 'OTHER'
  itEquipment: string;
  systemSupport: string;
  hasITManager: boolean;
  itPolicy: string;
  popiPolicy: string;
  softwareList: string[];
  softwareOther: string; // if softwareList contains 'OTHER'
  customSoftware: string;
  customSoftwareExplain: string; // if customSoftware === 'YES'
  techTraining: string;
  managementSkills: string;
  adminSkills: string;
  generalStaffSkills: string;

}

export function createCSInnovation(params: Partial<CSInnovation>) {
  return Object.assign({}, createBaseEntity(params), {
    computerAccess: params && params.computerAccess,
    internetAccess: params && params.internetAccess,
    internetConnection: params && params.internetConnection,
    internetConnectionOther: params && params.internetConnectionOther,
    itEquipment: params && params.itEquipment,
    systemSupport: params && params.systemSupport,
    hasITManager: params && params.hasITManager,
    itPolicy: params && params.itPolicy,
    popiPolicy: params && params.popiPolicy,
    softwareList: params && params.softwareList,
    softwareOther: params && params.softwareOther,
    customSoftware: params && params.customSoftware,
    customSoftwareExplain: params && params.customSoftwareExplain,
    techTraining: params && params.techTraining,
    managementSkills: params && params.managementSkills,
    adminSkills: params && params.adminSkills,
    generalStaffSkills: params && params.generalStaffSkills,
  }) as CSInnovation;
}

export function createCSInnovationForm(destroy$: Subject<boolean>, formBuilder: FormBuilder, params: Partial<CSInnovation>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    computerAccess: [params && params.computerAccess, Validators.required],
    internetAccess: [params && params.internetAccess, Validators.required],
    internetConnection: [params && params.internetConnection, Validators.required],
    internetConnectionOther: [params && params.internetConnectionOther,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('internetConnection').value === 'OTHER')
    ],
    itEquipment: [params && params.itEquipment, Validators.required],
    systemSupport: [params && params.systemSupport, Validators.required],
    hasITManager: [params && params.hasITManager, Validators.required],
    itPolicy: [params && params.itPolicy, Validators.required],
    popiPolicy: [params && params.popiPolicy, Validators.required],
    softwareList: [params && params.softwareList, Validators.required],
    softwareOther: [params && params.softwareOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('softwareList').value && formGroup.get('softwareList').value.includes('OTHER');
      })
    ],
    customSoftware: [params && params.customSoftware, Validators.required],
    customSoftwareExplain: [params && params.customSoftwareExplain,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('customSoftware').value === 'YES')
    ],
    techTraining: [params && params.techTraining, Validators.required],
    managementSkills: [params && params.managementSkills, Validators.required],
    adminSkills: [params && params.adminSkills, Validators.required],
    generalStaffSkills: [params && params.generalStaffSkills, Validators.required],
  }));

  formGroup.get('internetConnection').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('internetConnectionOther').updateValueAndValidity();
    });

  formGroup.get('softwareList').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('softwareOther').updateValueAndValidity();
    });

  formGroup.get('customSoftware').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('customSoftwareExplain').updateValueAndValidity();
    });

  return formGroup;
}
