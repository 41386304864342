import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ReviewStore, ReviewState} from './review.store';

@Injectable({providedIn: 'root'})
export class ReviewQuery extends QueryEntity<ReviewState> {

  constructor(protected store: ReviewStore) {
    super(store);
  }

  setActiveReview(reviewId: number) {
    this.store.setActive(reviewId);
  }

}
