import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {WorkPlanAndTargetsComponent} from './components/work-plan-and-targets/work-plan-and-targets.component';
import {ObjectiveDetailComponent} from './components/work-plan-and-targets/work-plan/funding-request/objective/objective-detail/objective-detail.component';

const routes: Routes = [
  {
    path: '',
    component: WorkPlanAndTargetsComponent,
    // data: {breadcrumb: 'Work plan & Targets', heading: 'Work Plan'},
    pathMatch: 'full',
  },
  {
    path: 'objective-details/:objectiveId',
    component: ObjectiveDetailComponent,
    pathMatch: 'full',
    data: {breadcrumb: 'Objective', entity: 'OBJECTIVE', heading: 'Objective Details'}
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class WorkPlanAndTargetsRoutingModule {
}
