import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../../environments/environment';
import {ReviewDashboardStore} from './review-dashboard.store';
import {Observable} from 'rxjs';
import {ReviewDashboardTotals, Review} from './review-dashboard.model';

@Injectable({providedIn: 'root'})
export class ReviewDashboardService {

  constructor(protected store: ReviewDashboardStore,
              private http: HttpClient) {
  }

  // TODO add coordinator username as the second optional param
  //   see endpoint in the back-end
  // TODO delete this unused api
  getReviewDashboard(grantWindowId: number): Observable<ReviewDashboardTotals> {
    const url = `${environment.apiUrl}/grant-window/${grantWindowId}/review-dashboard`;
    return this.http.get<ReviewDashboardTotals>(url);
  }

  getReviewList(grantWindowId: number): Observable<Review[]> {
    const url = `${environment.apiUrl}/grant-workflow/review/list?grantWindowId=${grantWindowId}`;
    return this.http.get<Review[]>(url);
  }
}
