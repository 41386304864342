<div class="row">

  <div class="col-5">
    <label class="multi-lines-text">{{ label }}</label>
  </div>

  <div class="col-3">
    <div class="input-group">
      <button
        type="button"
        (click)="fileInput.click();"
        class="btn btn-secondary"
        [disabled]='isReadOnly'
      >
    <span class="fa fa-upload fa-1x text-center">
      Upload
    </span>
      </button>
      <span class="uploader">
        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" hidden/>
      </span>
      <span *ngIf="uploading">
        <i class="mt-2 fa fa-spinner fa-spin text-primary"></i>
      </span>
    </div>
  </div>

  <div class="col-4">
    <ng-container *ngFor="let doc of documents; let ix = index;">

      <div *ngIf="!isReadOnly" class="row">
        <a
          href="javascript:void(0)"
          class="text-danger fa fa-trash fa-1x mt-1"
          (click)="showDeleteConfirmModal = true; selectedIndex = ix"
        ></a>
        <a
          class="files"
          href="javascript:void(0)"
          (click)="downloadFile(doc)"
          target="_blank"
        >
          {{ getFilename(doc) }}
        </a>
      </div>

      <div *ngIf="isReadOnly" class="row">
        <p>
          &nbsp; {{ getFilename(doc) }}
        </p>
      </div>

    </ng-container>

    <app-alert
      class="w-100"
      [id]="'uploadDocument'"
    ></app-alert>
  </div>
</div>

<app-confirmation-modal
  *ngIf="showDeleteConfirmModal"
  [title]="'Delete Confirm'"
  [message]="'Are you sure you want to delete this file?'"
  (isConfirmed)="deleteConfirmation($event, selectedIndex)"
></app-confirmation-modal>
