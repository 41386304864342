import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {createUser, User} from '../user/user.model';

export interface SessionState {
  name: string;
  user: User;
  token: string;
}

export function createInitialState(): SessionState {
  return {
    name: null,
    user: null,
    token: null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'session'})
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

  login(session: SessionState) {
    this.update(session);
  }

  logout() {
    this.update(createInitialState());
  }

  setOrgIdOnUser(id: number) {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.organisationId = id;
    this.update({
      user: updatedUser
    });
  }

  setFunderIdOnUser(id: number) {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.funderId = id;
    this.update({
      user: updatedUser
    });
  }

  updateUser(user: User) {
    this.update({
      user
    });
  }

  setSubmittedCapacitySpectrumOnUser() {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.isCapacitySpectrumSubmitted = true;
    this.update({
      user: updatedUser
    });
  }

  setServiceMatrixOnUser(serviceMatrixId: number) {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.serviceMatrixId = serviceMatrixId;
    this.update({
      user: updatedUser
    });
  }

  setSubmittedSIMOnUser() {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.isSimSubmitted = true;
    this.update({
      user: updatedUser
    });
  }
}

