<form *ngIf="internalVerificationForm" [formGroup]="internalVerificationForm">
  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'legalNameIsCorrect'"
    [label]="'Legal name of the organization is correct'"
    [id]="'legalNameIsCorrect'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="legalNameIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'npoDateIsCorrect'"
    [label]="'Date of registration on the NPO certificate is more than two years from the application closing date'"
    [id]="'npoDateIsCorrect'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="npoDateIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'relatedToGrantIsCorrect'"
    [label]="'Funding request is related to HIV?'"
    [id]="'relatedToGrantIsCorrect'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="relatedToGrantIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'servicesAndHistoryDocumentIsCorrect'"
    [label]="'All supporting documents submitted are the correct documents'"
    [id]="'servicesAndHistoryDocumentIsCorrect'"
    [isRequired]="true"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="servicesAndHistoryDocumentIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'npoRegistrationDocumentIsCorrect'"
    [label]="'A copy of your NPO registration from the Department of Social Development'"
    [isRequired]="true"
    [id]="'npoRegistrationDocumentIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="npoRegistrationDocumentIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'budgetQuotesDocumentIsCorrect'"
    [label]="'Quotes for equipment, supplies and training requested in the budget and/or cost calculations for items' +
    ' that are not quotable such as transport and airtime'"
    [isRequired]="true"
    [id]="'budgetQuotesDocumentIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="budgetQuotesDocumentIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'communityLetterOfReferenceIsCorrect'"
    [label]="'One letter of reference from community stakeholders/partners who are not formally part of your project or' +
    ' organization'"
    [isRequired]="true"
    [id]="'communityLetterOfReferenceIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="communityLetterOfReferenceIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'saGovernmentLetterOfReferenceIsCorrect'"
    [label]="'One letter of reference from a South African Government Department (this can be local, district or' +
    ' provincial level)'"
    [isRequired]="true"
    [id]="'saGovernmentLetterOfReferenceIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="saGovernmentLetterOfReferenceIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'bankStatementsIsCorrect'"
    [label]="'Copy of last month’s bank statements for every account held by your organization'"
    [isRequired]="true"
    [id]="'bankStatementsIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="bankStatementsIsCorrect"
  ></app-widget-check-two>
</form>
