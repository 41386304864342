import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GrantWindow} from '../../../grant-window/store/grant-window.model';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GrantWorkflowNavigator} from '../../grant-workflow-navigator';
import {ModalService} from '../../../../shared/modules/shared-common/services/modal/modal.service';
import {GrantWindowService} from '../../../grant-window/store/grant-window.service';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {createGrantWorkflow} from '../../store/grant-workflow.model';
import {createCustomApplication, createCustomApplicationForm, CustomApplication} from './store/custom-application.model';
import {CustomApplicationService} from './store/custom-application.service';
import {QuestionControlService} from '../../../form-builder/store/question-control.service';
import {createFormCapture, FormCaptureAnswer} from './components/form-capture/store/form-capture.model';
import {QuestionService} from '../../../form-builder/store/question.service';
import * as _ from 'lodash';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';
import {FormBuilderQuestionDTO} from '../../../form-builder/components/form-builder-list/form-builder/components/form-builder-question/store/form-builder-question.model';
import {QuestionControlType} from '../../../form-builder/store/question-base.model';
import {Subject} from 'rxjs';
import {ScorePercentageUtil} from '../../../../shared/utils/score-percentage-util';

@Component({
  selector: 'app-custom-application',
  templateUrl: './custom-application.component.html',
  styleUrls: ['./custom-application.component.scss']
})
export class CustomApplicationComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('submitModal', {static: true}) submitModal: TemplateRef<HTMLElement>;
  @ViewChild('saveBeforeLeaving', {static: true}) saveBeforeLeaving: TemplateRef<HTMLElement>;

  @Input() workflowId: number;
  @Input() fromGrantReview: boolean;
  @Input() fromApplicationHistory: boolean;
  @Input() isReadOnly: boolean;

  @Output() scrolling = new EventEmitter<boolean>();

  customApplication: CustomApplication;
  customApplicationForm: FormGroup;

  exitUrl: string;

  isNotApplicant: boolean;
  hasSaved: boolean;
  loading: boolean;
  warningDismissed = false;

  grantWindow: GrantWindow;
  modalOptions: NgbModalOptions;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              private grantWorkflowNavigator: GrantWorkflowNavigator,
              private modalService: ModalService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              public scorePercentageUtil: ScorePercentageUtil,
              private grantWindowService: GrantWindowService,
              private customApplicationService: CustomApplicationService,
              private sessionQuery: SessionQuery,
              private qcs: QuestionControlService,
              private questionService: QuestionService) {
  }

  ngOnInit() {
    this.loading = true;
    this.isNotApplicant = this.sessionQuery.hasRole(['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']);
    this.isReadOnly = this.isReadOnly || this.isNotApplicant;

    this.workflowId = this.workflowId || this.route.snapshot.params.workflowId;

    this.grantWindow = this.grantWindowService.selectedGrantWindow;

    this.fetchCustomApplication();

    this.modalOptions = {
      size: 'lg',
      centered: true,
      windowClass: 'modalWidth-50'
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  // This function is NOT unused.
  // It's being called from the `canDeactivateApplication` guard in `shared/guards`.
  // noinspection JSUnusedGlobalSymbols
  openSaveModal() {
    if (!this.warningDismissed) {
      this.modalService.openBasicModal(this.saveBeforeLeaving, this.modalOptions);
    }
  }

  fetchCustomApplication() {
    this.loading = true;
    // Fetch application or create new
    this.customApplicationService.getCustomApplicationByWorkflowId(this.workflowId).subscribe((result: CustomApplication) => {
      if (result) {
        const convertedResult = this.convertAnswersToTypes(result);
        this.customApplication = createCustomApplication(convertedResult);

        if (this.customApplicationForm) {
          this.customApplicationForm.patchValue(this.customApplication.fundingRequest);
        } else {
          this.customApplicationForm =
            createCustomApplicationForm(this.destroy$, this.formBuilder, this.qcs, this.customApplication);
        }
        this.loading = false;
      } else {
        this.questionService.getForm(this.grantWindow.form.id).subscribe(formTemplate => {
          this.loading = false;

          this.customApplication = createCustomApplication({
            grantWorkflow: createGrantWorkflow({id: this.workflowId}),
            status: 'NEW',
            formCapture: createFormCapture({
              form: formTemplate
            })
          });
          this.customApplicationForm =
            createCustomApplicationForm(this.destroy$, this.formBuilder, this.qcs, this.customApplication);
          this.loading = false;
        });
      }
    }, () => {
      this.alertService.setAlert('Unable to fetch Application data', 'error', 'customApplication');
      this.loading = false;
    });
  }

  openSubmitModal(form: FormGroup) {
    if (form.valid) {
      this.modalService.openBasicModal(this.submitModal, this.modalOptions);
    } else {
      // TODO trigger form submitted down to the child forms
      // this.customApplicationForm.markAllAsTouched();
      this.alertService.setAlert('Complete all required fields.', 'error', 'customApplication');
    }
  }

  handleSubmitApplication(values) {
    this.customApplicationForm.controls.submittedBy.patchValue(values.submittedBy);
    this.customApplicationForm.controls.submitVerification.patchValue(values.submitVerification);

    this.save(true);
  }

  save(isSubmitting?: boolean) {
    this.loading = true;

    const customApplication = this.convertAnswersToString(this.customApplicationForm.value);

    if (isSubmitting) {

      if (this.customApplicationForm.invalid) {
        this.alertService.setAlert(
          'Unable to submit Application form.\n Check to see that all fields are correct.',
          'error',
          'customApplication'
          );
        this.loading = false;
        return;
      }
    }

    // create or update.
    this.customApplication.id ?
      this.update(customApplication, this.customApplication.id, isSubmitting) :
      this.create(customApplication, isSubmitting);
    this.hasSaved = true;
  }

  create(customApplication: CustomApplication, isSubmitting?: boolean) {
    const username = this.sessionQuery.getUser().username;
    this.customApplicationService.createCustomApplicationByWorkflowId(
      customApplication,
      this.workflowId,
      isSubmitting,
      username
    ).subscribe(() => {
      if (isSubmitting) {
        this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
      } else {
        this.fetchCustomApplication();
        this.alertService.setAlert('Success: Saved Application', 'success', 'customApplication');
      }
    }, () => {
      if (isSubmitting) {
        this.loading = false;
        this.alertService.setAlert('Unable to post submit Application', 'error', 'customApplication');
      } else {
        this.loading = false;
        this.alertService.setAlert('Unable to create Application', 'error', 'customApplication');
      }
    });
  }

  update(customApplication: CustomApplication, applicationId: number, isSubmitting?: boolean) {
    const username = this.sessionQuery.getUser().username;
    this.customApplicationService.updateCustomApplication(customApplication, applicationId, isSubmitting, username)
      .subscribe(() => {
        if (isSubmitting) {
          this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
        } else {
          this.fetchCustomApplication();
          this.alertService.setAlert('Success: Saved Application', 'success', 'customApplication');
        }
      }, (response) => {
        if (isSubmitting) {
          this.loading = false;
          this.alertService.setAlert('Unable to put submit Application:\n' + response.error.message, 'error', 'customApplication');
        } else {
          this.loading = false;
          this.alertService.setAlert('Unable to update Application:\n' + response.error.message, 'error', 'customApplication');
        }
      });
  }

  goBack() {
    this.location.back();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  leaveApplication() {
    this.warningDismissed = true;
    this.closeModal();
    this.router.navigate([this.exitUrl]).then();
  }

  downloadPdf() {
    // TODO
    // DownloadPdfUtil.downloadPdf('application', this.grantApplication, this.grantApplication.grantWorkflow.organisation);
    // const content = [];
    // content.push(
    //   DownloadPDFUtil.generateGrantWindowPDF(this.grantApplication.grantWorkflow.grantWindow),
    //   DownloadPDFUtil.generateApplicationPDF(this.grantApplication));
    // DownloadPDFUtil.downloadPDF(this.grantApplication.grantWorkflow.organisation, content);
  }

  private convertAnswersToString(customApplication: CustomApplication): CustomApplication {
    const app = _.cloneDeep(customApplication);

    app.formCapture.answers = [];
    const questions: FormBuilderQuestionDTO[] = customApplication.formCapture.form.questions;

    Object.keys(customApplication.formCapture.answers).forEach(key => {
      const originalAnswer = customApplication.formCapture.answers[key];
      const question = questions.find(item => item.key === key);

      let value = originalAnswer;
      let score = 0;
      if (originalAnswer && originalAnswer.length > 0
        && question && question.controlType === QuestionControlType.checkbox) {
        value = originalAnswer.toString();
        score += originalAnswer.reduce((acc, valueItem) => {
          const option = question.options.find(item => item.value === valueItem);
          acc += option.score || 0;
          return acc;
        }, 0);
      }

      if (originalAnswer && question && question.controlType === QuestionControlType.radio) {
          const option = question.options.find(item => item.value === originalAnswer);
          score += option.score || 0;
      }

      const answer: FormCaptureAnswer = {
        key,
        value,
        score,
      };
      app.formCapture.answers.push(answer);
    });

    return app;
  }

  private convertAnswersToTypes(customApplication: CustomApplication): CustomApplication {
    const app = _.cloneDeep(customApplication);

    app.formCapture.answers = [];
    const questions: FormBuilderQuestionDTO[] = customApplication.formCapture.form.questions;

    customApplication.formCapture.answers.forEach(originalAnswer => {
      const question = questions.find(item => item.key === originalAnswer.key);

      let value = originalAnswer.value;
      if (originalAnswer && originalAnswer.value && question && question.controlType === QuestionControlType.checkbox) {
        value = originalAnswer.value.split(',');
        app.formCapture.form.questions = questions.map(item => {
          if (item.key === question.key) {
            item.value = item.value.split(',');
          }
          return item;
        });
      }


      const answer: FormCaptureAnswer = {
        key: originalAnswer.key,
        value,
      };
      app.formCapture.answers.push(answer);
    });

    return app;
  }
}
