import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {CancelConfirmGuard} from '../../../shared/guards/cancel-confirm/cancel-confirm.guard';
import {ServiceMatrixResultsComponent} from './components/service-matrix-results/service-matrix-results.component';
import {ServiceMatrixComponent} from './components/service-matrix/service-matrix.component';

const routes: Routes = [
  {path: 'edit/:orgId', component: ServiceMatrixComponent, canDeactivate: [CancelConfirmGuard]},
  {path: 'results', component: ServiceMatrixResultsComponent},
  {path: 'results/:orgId', component: ServiceMatrixResultsComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ServiceMatrixRoutingModule {
}
