import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {SessionQuery} from '../../../../stores/session/session.query';
import {Roles, User} from '../../../../stores/user/user.model';

@Directive({
  selector: '[authenticatedWithoutRole]'
})
export class AuthenticatedWithoutRoleDirective implements OnInit, OnDestroy {

  @Input() authenticatedWithoutRole: Roles[] | string[];
  userSubscription: Subscription;

  constructor(private el: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private sessionQuery: SessionQuery) {
  }

  ngOnInit() {
    this.viewContainer.clear();
    this.userSubscription = this.sessionQuery.getUserObservable().subscribe(user => {
      switch (true) {
        case (user && !this.hasRole(user)):
          this.createView();
          break;
        case (user && this.authenticatedWithoutRole.includes('Any')):
          this.createView();
          break;
        case (!user && this.authenticatedWithoutRole.includes('Unauthenticated')):
          this.createView();
          break;
        default:
          this.viewContainer.clear();
          break;
      }
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  createView() {
    if (this.viewContainer.length < 1) {
      this.viewContainer.createEmbeddedView(this.el);
    }
  }

  private hasRole(user: User): boolean {
    let matched = false;
    user.roles.forEach(role => {
      if (this.authenticatedWithoutRole.includes(role)) {
        matched = true;
      }
    });

    return matched;
  }
}
