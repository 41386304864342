import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NullUtil} from '../../../../utils/null-util';

const getCellValue = (object: any, columnPath: string[]) => {
  let cellValue = object;
  for (const columnPathItem of columnPath) {
    if (cellValue) {
      cellValue = cellValue[columnPathItem];
    }
  }
  return cellValue;
};

@Component({
  selector: 'app-mbl-table',
  templateUrl: './mbl-table.component.html',
  styleUrls: ['./mbl-table.component.scss']
})
export class MblTableComponent implements OnInit, OnChanges {

  @Input() showCountTop = true;
  @Input() showCountBottom = false;
  @Input() columns: any[];
  @Input() rows: any[];
  @Input() bulkActionsArray: any[];
  @Input() selectable = false;

  @Output() bulkActionsEvent = new EventEmitter();
  @Output() eventEmit = new EventEmitter();
  @Output() selectedRow = new EventEmitter<any>();

  selected: any;

  pagedRowsList: [any];
  pagedRows: { any };
  numberOfPages: number;
  count: number;
  offset = 0;
  limit = 15;
  useBulkActions: boolean;
  bulkActionsSelect: any;
  allSelected = false;
  selectedRowCount: any = 0;

  constructor() {
  }

  ngOnInit() {
    this.pageRows();
    this.useBulkActions = !!(this.bulkActionsArray && this.bulkActionsArray.length);
  }

  pageRows() {
    if (this.rows) {
      this.pagedRowsList = this.rows.reduce((ar, it, i) => {
        const ix = Math.floor(i / this.limit);

        if (!ar[ix]) {
          ar[ix] = [];
        }

        ar[ix].push(it);

        return ar;
      }, []);
      this.numberOfPages = this.pagedRowsList.length;

      this.pagedRows = this.pagedRowsList[this.offset];
      this.count = this.rows.length;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.rows) {
      this.rows = changes.rows.currentValue;
      this.pageRows();
    }
  }

  setPage(offset) {
    this.pagedRows = this.pagedRowsList[offset];
    this.offset = offset;
  }

  sortByColumn(column) {
    if (column.sort) {
      const direction = column.sortDirection;

      // Reset other sort icons
      this.columns.forEach(col => {
        col.sortDirection = undefined;
      });

      // Set icon
      switch (direction) {
        case 'forward': {
          column.sortDirection = 'reverse';
          break;
        }
        case 'reverse':
        default: {
          column.sortDirection = 'forward';
          break;
        }
      }

      // Do Sorting
      this.sort(column);
    }
  }

  sort(column) {
    this.rows.sort((a, b) => {
      const forward = column.sortDirection === 'forward';

      let prop;
      if (NullUtil.isNotEmptyAny(column.prop)) {
        prop = column.prop;
      } else {
        prop = column.name.toLowerCase();
      }
      const columnPath = prop.split('.');
      let varA = getCellValue(a, columnPath);
      let varB = getCellValue(b, columnPath);

      varA = varA === 'unconfirmed' ? undefined : varA;
      varB = varB === 'unconfirmed' ? undefined : varB;

      // property doesn't exist on either object
      if (!varA && !varB) {
        return 0;
      }
      if (!varA) {
        return 1;
      }
      if (!varB) {
        return -1;
      }

      let comparison = 0;
      if (varA.trim() > varB.trim()) {
        comparison = 1;
      } else if (varA.trim() < varB.trim()) {
        comparison = -1;
      }
      return forward ? comparison : comparison * -1;
    });

    this.pageRows();
    this.offset = 0;
  }

  bulkActionsParent() {
    this.bulkActionsEvent.emit(this.bulkActionsSelect);
  }

  selectAll() {
    this.rows.forEach(row => {
      if (this.allSelected) {
        row.selected = false;
      }
      if (!this.allSelected) {
        row.selected = true;
      }
    });
    this.countSelected();
  }

  countSelected() {
    this.selectedRowCount = this.rows.filter(row => row.selected).length;
  }

  selectRow(nestedRow: any) {
    this.selectedRow.emit(nestedRow);
    this.selected = nestedRow;
  }
}
