import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum NetworkAndLinkagesEndpointType {
  REFERRAL_AND_LINKAGE = 'REFERRAL_AND_LINKAGE',
  MEMBERS_INVOLVEMENT = 'MEMBERS_INVOLVEMENT',
  COMMUNITY_LINKAGE = 'COMMUNITY_LINKAGE',
  COMMUNITY_FORUM_FREQUENCY = 'COMMUNITY_FORUM_FREQUENCY',
  COMMUNITY_PROFILE = 'COMMUNITY_PROFILE',
  COMMUNITY_ENGAGEMENT = 'COMMUNITY_ENGAGEMENT',
  ORGANISATION_RELATIONSHIP = 'ORGANISATION_RELATIONSHIP',
  GOVERNMENT_RELATIONSHIP = 'GOVERNMENT_RELATIONSHIP'
}

export interface CSNetworkAndLinkages extends BaseEntity {
  referralAndLinkageSystem: string;
  membersInvolvementList: string[];
  membersInvolvementOther: string; // if membersInvolvementList contains 'OTHER'
  communityLinkageList: string[];
  communityLinkageOther: string; // if communityLinkageList contains 'OTHER'
  communityForumFrequency: string;
  communityProfile: string;
  communityEngagement: string;
  organisationRelationship: string;
  hasCapacityBuilding: boolean;
  capacityBuildingCompanyName: string; // if hasCapacityBuilding === true
  capacityBuildingSupportProvidedExplanation: string; // if hasCapacityBuilding === true
  hasCSI: boolean;
  csiCompanyName: string; // if hasCSI === true
  csiSupportProvidedExplanation: string; // if hasCSI === true
  governmentRelationship: string;
}

export function createCSNetworkAndLinkages(params: Partial<CSNetworkAndLinkages>) {
  return Object.assign({}, createBaseEntity(params), {
    referralAndLinkageSystem: params && params.referralAndLinkageSystem,
    membersInvolvementList: params && params.membersInvolvementList,
    membersInvolvementOther: params && params.membersInvolvementOther,
    communityLinkageList: params && params.communityLinkageList,
    communityLinkageOther: params && params.communityLinkageOther,
    communityForumFrequency: params && params.communityForumFrequency,
    communityProfile: params && params.communityProfile,
    communityEngagement: params && params.communityEngagement,
    organisationRelationship: params && params.organisationRelationship,
    hasCapacityBuilding: params && params.hasCapacityBuilding,
    capacityBuildingCompanyName: params && params.capacityBuildingCompanyName,
    capacityBuildingSupportProvidedExplanation: params && params.capacityBuildingSupportProvidedExplanation,
    hasCSI: params && params.hasCSI,
    csiCompanyName: params && params.csiCompanyName,
    csiSupportProvidedExplanation: params && params.csiSupportProvidedExplanation,
    governmentRelationship: params && params.governmentRelationship,
  }) as CSNetworkAndLinkages;
}

export function createCSNetworkAndLinkagesForm(destroy$: Subject<boolean>,
                                               formBuilder: FormBuilder,
                                               params: Partial<CSNetworkAndLinkages>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    referralAndLinkageSystem: [params && params.referralAndLinkageSystem, Validators.required],
    membersInvolvementList: [params && params.membersInvolvementList, Validators.required],
    membersInvolvementOther: [params && params.membersInvolvementOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('membersInvolvementList').value && formGroup.get('membersInvolvementList').value.includes('OTHER');
      })
    ],
    communityLinkageList: [params && params.communityLinkageList, Validators.required],
    communityLinkageOther: [params && params.communityLinkageOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('communityLinkageList').value && formGroup.get('communityLinkageList').value.includes('OTHER');
      })
    ],
    communityForumFrequency: [params && params.communityForumFrequency, Validators.required],
    communityProfile: [params && params.communityProfile, Validators.required],
    communityEngagement: [params && params.communityEngagement, Validators.required],
    organisationRelationship: [params && params.organisationRelationship, Validators.required],
    hasCapacityBuilding: [params && params.hasCapacityBuilding, Validators.required],
    capacityBuildingCompanyName: [params && params.capacityBuildingCompanyName,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasCapacityBuilding').value === true)
    ],
    capacityBuildingSupportProvidedExplanation: [params && params.capacityBuildingSupportProvidedExplanation,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasCapacityBuilding').value === true)
    ],
    hasCSI: [params && params.hasCSI, Validators.required],
    csiCompanyName: [params && params.csiCompanyName,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasCSI').value === true)
    ],
    csiSupportProvidedExplanation: [params && params.csiSupportProvidedExplanation,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasCSI').value === true)
    ],
    governmentRelationship: [params && params.governmentRelationship, Validators.required],
  }));

  formGroup.get('membersInvolvementList').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('membersInvolvementOther').updateValueAndValidity();
    });

  formGroup.get('communityLinkageList').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('communityLinkageOther').updateValueAndValidity();
    });

  formGroup.get('hasCapacityBuilding').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('capacityBuildingCompanyName').updateValueAndValidity();
      formGroup.get('capacityBuildingSupportProvidedExplanation').updateValueAndValidity();
    });

  formGroup.get('hasCSI').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('csiCompanyName').updateValueAndValidity();
      formGroup.get('csiSupportProvidedExplanation').updateValueAndValidity();
    });

  return formGroup;
}
