import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionTwoComponent} from './accordion-two/accordion-two.component';


@NgModule({
  declarations: [AccordionTwoComponent],
  imports: [
    CommonModule
  ],
  exports: [
    AccordionTwoComponent
  ]
})
export class AccordionTwoModule {
}
