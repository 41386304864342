<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="financialSituationForm">
    <app-input-select-single
      *ngIf="annualOperatingBudgetAnswers?.length"
      [label]="'Organisation’s annual operating budget is between: '"
      [items]="annualOperatingBudgetAnswers"
      [id]="'annualOperatingBudget'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="annualOperatingBudget"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="financialSituationAnswers?.length"
      [label]="'Which statement best describes the organisation\'s financial situation?'"
      [items]="financialSituationAnswers"
      [id]="'financialSituation'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="financialSituation"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="fundingMethodAnswers?.length"
      [label]="'Which funding methods has the organisation tried (successful and unsuccessful) in the past 12 months? (check all that apply)'"
      [choices]="fundingMethodAnswers"
      [id]="'fundingMethods'"
      [name]="'fundingMethods'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther('OTHER', $event, financialSituationForm.controls.fundingMethods, financialSituationForm.controls.fundingMethodsOther)"
      formControlName="fundingMethods"
    ></app-input-checkbox>

    <div *ngIf="financialSituationForm?.value?.fundingMethods?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'fundingMethodsOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="fundingMethodsOther"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="incomeGenerationActivityAnswers?.length"
      [label]="'Which statement best describes the organisation\'s income generating activities?'"
      [items]="incomeGenerationActivityAnswers"
      [id]="'incomeGenerationActivity'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="incomeGenerationActivity"
    ></app-input-select-single>

    <app-input-select-single
      [label]="'Does the organisation have bad debts, creditors that are threatening or taking legal action, ' +
       'prior misuse of funds, or fraud claimed against the organisation and/or members?'"
      [items]="booleanChoices"
      [id]="'hasBadCredit'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        true,
        $event,
        financialSituationForm.controls.hasBadCredit,
        financialSituationForm.controls.badCreditExplain
      )"
      formControlName="hasBadCredit"
    ></app-input-select-single>

    <div *ngIf="financialSituationForm?.value?.hasBadCredit === true" class="ml-5">
      <app-input-text
        [label]="'If yes, please specify'"
        [id]="'hasBadCreditExplain'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="badCreditExplain"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="costSharingAnswers?.length"
      [label]="'Does the organisation have any partnerships that allow for cost-sharing, in-kind trade or savings?'"
      [items]="costSharingAnswers"
      [id]="'hasCostSharing'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        'YES',
        $event,
        financialSituationForm.controls.hasCostSharing,
        financialSituationForm.controls.costSharingExplain
      )"
      formControlName="hasCostSharing"
    ></app-input-select-single>

    <div *ngIf="financialSituationForm?.value?.hasCostSharing === 'YES'" class="ml-5">
      <app-input-text
        [label]="'If yes, please specify'"
        [id]="'hasCostSharingExplain'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="costSharingExplain"
      ></app-input-text>
    </div>

    <app-input-select-single
      *ngIf="fundingSourceAnswers?.length"
      [label]="'Currently, the organisation is funded by how many funding sources?'"
      [items]="fundingSourceAnswers"
      [id]="'fundingSource'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="fundingSource"
    ></app-input-select-single>

    <div class="funding-history">
      <div class="funding-history-label">
        <label>
          List the organisation's funders over the last two calendar years
          <br>
          <small>
            Click the "Add Funder" button to continue adding funders. There is no limit to the number you can enter
          </small>
        </label>
        <button class="btn btn-primary btn-outline-dark" (click)="addFunder()">
          Add Funder
        </button>
      </div>
      <div class="card bg-dark">
        <div class="card-body">
          <ng-container *ngIf="financialSituationForm.value.funders?.length > 0; else noFunders">
            <ng-container formArrayName="funders">
              <ng-container *ngFor="let funder of funders?.controls; let idx = index">
                <div class="flex-center">

                  <a
                    href="javascript:void(0)"
                    class="text-danger fa fa-trash fa-1x mt-4 pt-2 align-self-start"
                    (click)="removeFunder(idx)"
                  ></a>
                  <app-accordion
                    class="w-100 flex-center"
                    [title]="idx + 1 + '.) Please add details:'"
                    [boxShape]="true"
                    [expand]="true"
                    [theme]="'dark'"
                  >
                    <div class="p-2 bg-light w-100 flex-center" boxShape>
                      <app-cs-financial-situation-funder
                        class="w-100"
                        [funderForm]="funder"
                        [formSubmitted]="formSubmitted || childFormTouched"
                      ></app-cs-financial-situation-funder>
                    </div>
                  </app-accordion>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</app-spinner>

<ng-template #noFunders>
  <div class="flex-center">
    <p class="font-weight-normal text-light">
      Add Funder
    </p>
  </div>
</ng-template>
