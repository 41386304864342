import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const typesOfCommunityMobilizationAnswers: Array<ChoiceInputItem> = [
  {
    label: 'workshops',
    value: 'workShops'
  },
  {
    label: 'dialogues',
    value: 'dialogues'
  },
  {
    label: `awareness campaigns`,
    value: 'awarenessCampaigns'
  },
  {
    label: `community meetings`,
    value: 'communityMeetings'
  },
  {
    label: 'health screenings',
    value: 'healthMeetings'
  },
  {
    label: 'other',
    value: 'other'
  }
];

export const focusedTopicsAnswers: Array<ChoiceInputItem> = [
  {
    label: 'HIV awareness and prevention',
    value: 'hivAwareness'
  },
  {
    label: 'treatment literacy',
    value: 'treatment'
  },
  {
    label: 'stigma and discrimination',
    value: 'stigmaDiscrimination'
  },
  {
    label: 'gender based violence',
    value: 'genderViolence'
  },
  {
    label: 'substance abuse',
    value: 'substanceABuse'
  },
  {
    label: 'parenting skills',
    value: 'parentingSkills'
  },
  {
    label: 'TB',
    value: 'tb'
  },
  {
    label: 'male circumcision',
    value: 'maleCircumcision'
  },
  {
    label: 'other',
    value: 'other'
  }
];

export const peopleTargetedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: 'men who have sex with men',
    value: 'msm'
  },
  {
    label: 'transgender persons',
    value: 'tp'
  },
  {
    label: 'injecting drug users',
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: 'clients of sex workers',
    value: 'csw'
  },
  {
    label: 'military',
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: 'people living with HIV',
    value: 'peopleWithHiv'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
  {
    label: 'adults 25+ / general population',
    value: 'adultPopulation'
  },
  {
    label: 'parents',
    value: 'parents'
  },
  {
    label: 'caregivers for PLHIV',
    value: 'caregivers'
  },
  {
    label: 'HIV positive children',
    value: 'hivChildren'
  }
];

export const locationConductedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'door to door',
    value: 'doorToDoor'
  },
  {
    label: 'targeted hotspots',
    value: 'targetedHotspots'
  },
  {
    label: `clinics`,
    value: 'clinics'
  },
  {
    label: `schools`,
    value: 'schools'
  },
  {
    label: 'community events',
    value: 'communityEvents'
  },
  {
    label: 'sporting events',
    value: 'sportingEvents'
  },
  {
    label: 'churches',
    value: 'churches'
  },
  {
    label: 'community halls',
    value: 'communityHalls'
  },
  {
    label: 'other',
    value: 'other'
  }
];

export const frequencyOfCommunityMobilization: Array<ChoiceInputItem> = [
  {
    label: 'daily',
    value: 'dai;y'
  },
  {
    label: 'weekly',
    value: 'weekly'
  },
  {
    label: `monthly`,
    value: 'monthly'
  },
  {
    label: `quarterly`,
    value: 'qurterly'
  },
  {
    label: 'annually',
    value: 'annually'
  }
];
