import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {sidePanelYesNoAnswers} from 'app/modules/grant-review/constants/side-panel-answers.const';
import {User} from 'app/shared/stores/user/user.model';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';
import {CoordinatorUtil} from '../../../utils/coordinator-util';

@Component({
  selector: 'app-telephone-interview',
  templateUrl: './telephone-interview.component.html',
  styleUrls: ['./telephone-interview.component.scss']
})
export class TelephoneInterviewComponent implements OnInit {

  @Input() coordinators: User[];
  @Input() telephoneInterviewForm: FormGroup;
  @Input() orgId: number;
  @Input() windowCode: string;
  @Input() isReadOnly: boolean;

  telephoneInterviewAnswers = sidePanelYesNoAnswers;
  coordinatorList: ChoiceInputItem[];
  additionalParameters: any[];

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';


  constructor() {
  }

  ngOnInit() {
    this.additionalParameters = [
      {key: 'grantWindowCode', value: this.windowCode},
      {key: 'orgId', value: this.orgId}
    ];
    this.coordinatorList = CoordinatorUtil.convertToChoiceInputItem(this.coordinators);
  }

}
