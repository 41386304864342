import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../shared/models/base-entity.model';
import {Organisation} from '../../organisation/org-profile/store/organisation.model';
import {GrantWindow} from '../../grant-window/store/grant-window.model';
import {Score} from '../../grant-window/models/score.model';
import {MessageUserType, WorkPlanCommentHistory} from '../../work-plan-and-targets/store/work-plan-and-targets.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


export enum WorkPlanStatusType {
  ACTIVE = 'ACTIVE',
  WITH_APPLICANT = 'WITH_APPLICANT',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED'
}

export enum ReviewStepType {
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  ASSESSMENT_SUBMITTED = 'ASSESSMENT_SUBMITTED',
  RECOMMENDED = 'RECOMMENDED'
}

export interface GrantWorkflow extends BaseEntity {
  grantWindowId: number;
  orgId: number;
  grantWindow: GrantWindow;
  organisation: Organisation;
  step: string;
  status: string;
  hasAgreed: boolean;

  reviewStep: ReviewStepType;
  internalCommentHistoryList: InternalCommentHistory[];

  workPlanHasBeenWithApplicant: boolean;
  workPlanStatus: WorkPlanStatusType;
  workPlanCommentHistoryList: WorkPlanCommentHistory[];

  dateReportingClosed: any;
  isReportingClosed: boolean;
  score: Score;
}

export function createGrantWorkflow(params: Partial<GrantWorkflow>) {
  return Object.assign({}, createBaseEntity(params), {
    grantWindowId: params && params.grantWindowId,
    orgId: params && params.orgId,
    grantWindow: params && params.grantWindow,
    organisation: params && params.organisation,
    step: params && params.step,
    reviewStep: params && params.reviewStep,
    internalCommentHistoryList: params && params.internalCommentHistoryList,
    status: params && params.status,
    hasAgreed: params && params.hasAgreed,
    workPlanHasBeenWithApplicant: params && params.workPlanHasBeenWithApplicant,
    workPlanStatus: params && params.workPlanStatus,
    workPlanCommentHistoryList: params && params.workPlanCommentHistoryList,
    isReportingClosed: params && params.isReportingClosed,
    dateReportingClosed: params && params.dateReportingClosed,
    score: params && params.score,
  }) as GrantWorkflow;
}


export interface InternalCommentHistory extends BaseEntity {
  workflow: GrantWorkflow;
  action: ReviewMessageActionType;
  username: string;
  userType: MessageUserType;
  date: any;
  message: string;
  isOpen: boolean; // ui field only
}

export enum ReviewMessageActionType {
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  ASSESSMENT_SUBMITTED = 'ASSESSMENT_SUBMITTED',
  REJECTED = 'REJECTED',
  RECOMMENDED = 'RECOMMENDED',
  REMOVED_FROM_RECOMMENDED = 'REMOVED_FROM_RECOMMENDED'
}

export function createInternalCommentHistory(params: Partial<InternalCommentHistory>) {
  return {
    workflow: params && params.workflow,
    action: params && params.action,
    username: params && params.username,
    userType: params && params.userType,
    date: params && params.date,
    message: params && params.message,
  };
}

export function createInternalCommentHistoryForm(formBuilder: FormBuilder, params?: Partial<InternalCommentHistory>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    workflow: [params && params.workflow, Validators.required],
    action: [params && params.action, Validators.required],
    username: [params && params.username, Validators.required],
    userType: [params && params.userType, Validators.required],
    date: [params && params.date, Validators.required],
    message: [params && params.message, Validators.required],
  }));
}
