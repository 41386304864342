<form [formGroup]="siteVisitCommunityRelationshipsForm">
  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'communityInvolvementIsCorrect'"
    [label]="'Community members’ involvement in the organization.'"
    [isRequired]="true"
    [id]="'communityInvolvementIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="communityInvolvementIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'relevantCouncilsAreCorrect'"
    [label]="'Participation in AIDS council'"
    [isRequired]="true"
    [id]="'relevantCouncilsAreCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="relevantCouncilsAreCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'communityLinkagesIsCorrect'"
    [label]="'Community linkages'"
    [isRequired]="true"
    [id]="'communityLinkagesIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="communityLinkagesIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'involvementInCommunityForumIsCorrect'"
    [label]="'Involvement in a community forum'"
    [isRequired]="true"
    [id]="'involvementInCommunityForumIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="involvementInCommunityForumIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'profileIsCorrect'"
    [label]="'Organization’s profile in the community'"
    [isRequired]="true"
    [id]="'profileIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="profileIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'relationshipWithClinicIsCorrect'"
    [label]="'Relationship with local clinic'"
    [isRequired]="true"
    [id]="'relationshipWithClinicIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="relationshipWithClinicIsCorrect"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'engagementWithLeadersIsCorrect'"
    [label]="'Engagement with community leaders'"
    [isRequired]="true"
    [id]="'engagementWithLeadersIsCorrect'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="engagementWithLeadersIsCorrect"
  ></app-widget-check-two>
</form>
