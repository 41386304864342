<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Adherence clubs are defined as regular group meetings of PLHIV for the purpose of
    providing ongoing care and support to assist members with HIV treatment adherence and
    literacy; group activities and attendance must be monitored.
  </p>
  <form [formGroup]="adherenceClubsForm">

    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="typeOfAdherenceClubOptions"
        [label]="'What type of adherence clubs are you planning to implement?'"
        [id]="'ac-typeOfAdherenceClub'"
        [name]="'ac-typeOfAdherenceClub'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="typeOfAdherenceClub"
        (selected)="checkOther('other', $event, adherenceClubsForm.controls.typeOfAdherenceClub, adherenceClubsForm.controls.typeOfAdherenceClubOther)"
      ></app-input-checkbox>

      <div *ngIf="adherenceClubsForm.value.typeOfAdherenceClub?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'ac-typeOfAdherenceClubOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="typeOfAdherenceClubOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="peopleReachedOptions"
        [label]="'What type of adherence clubs are you planning to implement?'"
        [id]="'ac-peopleReached'"
        [name]="'ac-peopleReached'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleReached"
      ></app-input-checkbox>

      <app-input-number
        [label]="' How many adherence clubs will be implemented?'"
        [id]="'ac-numberOfAdherenceClubs'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfAdherenceClubs"
      ></app-input-number>

      <app-input-number
        [label]="'How many people will attend each adherence club?'"
        [id]="'ac-numberOfPeoplePerClub'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeoplePerClub"
      ></app-input-number>

      <app-input-select-single
        [label]="'How often will the adherence clubs meet?'"
        [items]="frequencyOfMeetingsOptions"
        [id]="'ac-frequencyOfMeetings'"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        [isRequired]="true"
        formControlName="frequencyOfMeetings"
      ></app-input-select-single>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'ac-durationOfMeeting'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfMeeting"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'ac-durationOfMeetingType'"
            [isRequired]="true"
            [name]="'ac-trainingDurationType'"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfMeetingType"
          ></app-input-radio>
        </div>
      </div>

      <div>
        <p>How much funding do you need to conduct these adherence clubs?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [budgetRequestForm]="adherenceClubsForm.controls.budget | asFormGroup"
          [isReadOnly]="isReadOnly"
        ></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
