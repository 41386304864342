import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {CsHumanResourcesAnswerService} from './services/cs-human-resources-answer.service';
import {take} from 'rxjs/operators';
import {booleanChoices} from '../../../../../../constants/boolean-answers.const';
import {checkForValue} from '../../store/capacity-spectrum.model';

@Component({
  selector: 'app-cs-human-resources',
  templateUrl: './cs-human-resources.component.html',
  styleUrls: ['./cs-human-resources.component.scss']
})
export class CsHumanResourcesComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() humanResourcesForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();
  @Output() addItem = new EventEmitter<FormGroup>();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  booleanChoices = booleanChoices;

  // Question Answers.
  staffPeriodAnswers;
  qualificationAnswers;
  employmentContractAnswers;
  conformToLabourLawAnswers;
  jobDescriptionAnswers;
  retentionPlanAnswers;
  hrPolicyAnswers;
  hrPolicyElementsAnswers;
  recruitmentProcessAnswers;
  trainingFrequencyAnswers;
  internalCommunicationAnswers;

  staffPeriodSubscription: Subscription;
  qualificationSubscription: Subscription;
  employmentContractSubscription: Subscription;
  conformToLabourLawSubscription: Subscription;
  jobDescriptionSubscription: Subscription;
  retentionPlanSubscription: Subscription;
  hrPolicySubscription: Subscription;
  hrPolicyElementsSubscription: Subscription;
  recruitmentProcessSubscription: Subscription;
  trainingFrequencySubscription: Subscription;
  internalCommunicationSubscription: Subscription;
  childFormTouched: boolean;

  constructor(private loadingService: LoadingService,
              private csHumanResourcesAnswerService: CsHumanResourcesAnswerService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.loadingService.setIsLoading(true);
    this.childFormTouched = this.humanResourcesForm.touched;

    this.populateAnswers();
  }

  ngOnDestroy(): void {
    if (this.staffPeriodSubscription) {
      this.staffPeriodSubscription.unsubscribe();
    }
    if (this.qualificationSubscription) {
      this.qualificationSubscription.unsubscribe();
    }
    if (this.employmentContractSubscription) {
      this.employmentContractSubscription.unsubscribe();
    }
    if (this.conformToLabourLawSubscription) {
      this.conformToLabourLawSubscription.unsubscribe();
    }
    if (this.jobDescriptionSubscription) {
      this.jobDescriptionSubscription.unsubscribe();
    }
    if (this.retentionPlanSubscription) {
      this.retentionPlanSubscription.unsubscribe();
    }
    if (this.hrPolicySubscription) {
      this.hrPolicySubscription.unsubscribe();
    }
    if (this.hrPolicyElementsSubscription) {
      this.hrPolicyElementsSubscription.unsubscribe();
    }
    if (this.recruitmentProcessSubscription) {
      this.recruitmentProcessSubscription.unsubscribe();
    }
    if (this.trainingFrequencySubscription) {
      this.trainingFrequencySubscription.unsubscribe();
    }
    if (this.internalCommunicationSubscription) {
      this.internalCommunicationSubscription.unsubscribe();
    }
  }

  private populateAnswers() {
    this.csHumanResourcesAnswerService.getAnswerLists().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
    });

    this.staffPeriodSubscription = this.csHumanResourcesAnswerService.$staffPeriod.subscribe(answers => {
      if (answers) {
        this.staffPeriodAnswers = answers.choices;
      }
    });

    this.qualificationSubscription = this.csHumanResourcesAnswerService.$qualification.subscribe(answers => {
      if (answers) {
        this.qualificationAnswers = answers.choices;
      }
    });

    this.employmentContractSubscription = this.csHumanResourcesAnswerService.$employmentContract.subscribe(answers => {
      if (answers) {
        this.employmentContractAnswers = answers.choices;
      }
    });

    this.conformToLabourLawSubscription = this.csHumanResourcesAnswerService.$conformToLabourLaw.subscribe(answers => {
      if (answers) {
        this.conformToLabourLawAnswers = answers.choices;
      }
    });

    this.jobDescriptionSubscription = this.csHumanResourcesAnswerService.$jobDescription.subscribe(answers => {
      if (answers) {
        this.jobDescriptionAnswers = answers.choices;
      }
    });

    this.retentionPlanSubscription = this.csHumanResourcesAnswerService.$retentionPlan.subscribe(answers => {
      if (answers) {
        this.retentionPlanAnswers = answers.choices;
      }
    });

    this.hrPolicySubscription = this.csHumanResourcesAnswerService.$hrPolicy.subscribe(answers => {
      if (answers) {
        this.hrPolicyAnswers = answers.choices;
      }
    });

    this.hrPolicyElementsSubscription = this.csHumanResourcesAnswerService.$hrPolicyElements.subscribe(answers => {
      if (answers) {
        this.hrPolicyElementsAnswers = answers.choices;
      }
    });

    this.recruitmentProcessSubscription = this.csHumanResourcesAnswerService.$recruitmentProcess.subscribe(answers => {
      if (answers) {
        this.recruitmentProcessAnswers = answers.choices;
      }
    });

    this.trainingFrequencySubscription = this.csHumanResourcesAnswerService.$trainingFrequency.subscribe(answers => {
      if (answers) {
        this.trainingFrequencyAnswers = answers.choices;
      }
    });

    this.internalCommunicationSubscription = this.csHumanResourcesAnswerService.$internalCommunication.subscribe(answers => {
      if (answers) {
        this.internalCommunicationAnswers = answers.choices;
      }
    });
  }

  checkOther(value: string | boolean, selectedValue: string, hrPolicyElements: AbstractControl, hrPolicyElementsOther: AbstractControl) {
    checkForValue(value, selectedValue, hrPolicyElements, hrPolicyElementsOther);
  }
}
