<!--<div class="alert alert-secondary flex-between">-->
<!--  <div class="w-33 flex-start">-->
<!--    <p>{{reviewDetails ?-->
<!--        truncateOrgName.truncate(reviewDetails.grantWorkflow.organisation.legalName, 40) :-->
<!--        'Organisation Name'-->
<!--    }}</p>-->
<!--  </div>-->
<!--  <div class="flex-center w-33">-->
<!--    <p class="mr-2">-->
<!--      <span-->
<!--        *ngIf="!reviewDetails?.grantWorkflow?.reviewStep || reviewDetails?.grantWorkflow?.reviewStep === 'IN_REVIEW'"-->
<!--        class="badge badge-pill badge-success">-->
<!--        In Review-->
<!--      </span>-->

<!--      <span-->
<!--        *ngIf="reviewDetails?.grantWorkflow?.reviewStep === 'DECLINED'"-->
<!--        class="badge badge-pill badge-danger">-->
<!--        Declined-->
<!--      </span>-->

<!--      <span-->
<!--        *ngIf="reviewDetails?.grantWorkflow?.reviewStep === 'ASSESSMENT_SUBMITTED'"-->
<!--        class="badge badge-pill badge-primary">-->
<!--        Assessment Submitted-->
<!--      </span>-->

<!--      <span-->
<!--        *ngIf="reviewDetails?.grantWorkflow?.reviewStep === 'RECOMMENDED'"-->
<!--        class="badge badge-pill badge-warning">-->
<!--        Recommended-->
<!--      </span>-->
<!--    </p>|-->
<!--    <p class="mx-2">Tier: {{budgetTier | tierName }}</p>|-->
<!--    <p class="ml-2">Score: {{ reviewDetails ? score.scoreAsPercentage(reviewDetails.grantWorkflow.score.gearedScore): 0 }}-->
<!--      </p>-->
<!--  </div>-->
<!--  <div class="flex-end w-33">-->
<!--    <button class="btn btn-sm btn-secondary btn-outline-primary w-35" (click)="goBack()">-->
<!--      Go Back-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->


<app-alert
  class="w-100"
  [id]="'reviewDetailsContainer'"
></app-alert>
<app-spinner [loadingBool]="isLoading">
  <section class="review-details">
    <div class="review-details-main">
      <app-review-details
        [workflowId]="workflowId"
        [reviewStep]="reviewDetails?.grantWorkflow?.reviewStep"
      ></app-review-details>
    </div>
    <div class="review-side-panel">
      <app-review-side-panel
        *ngIf="reviewDetails"
        [coordinators]="coordinators"
        [reviewDetails]="reviewDetails"
        [workflowId]="workflowId"
        [isReadOnly]="isReadOnly"
        (refresh)="fetchReviewDetails()"
      ></app-review-side-panel>
    </div>
  </section>
</app-spinner>
