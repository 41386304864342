<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Training is defined as any qualification, in-house or short-course training that
    improves the quality or quantity of HIV-related services delivered to beneficiaries.
  </p>
  <form [formGroup]="trainingRequestForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="typesOfTrainingItems"
        [label]="'What type(s) of training are you requesting?'"
        [id]="'training-typeOfTraining'"
        [name]="'training-typeOfTraining'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="typeOfTraining"
        (selected)="checkOther('other', $event, trainingRequestForm.controls.typeOfTraining, trainingRequestForm.controls.typeOfTrainingOther)"
      ></app-input-checkbox>

      <div *ngIf="trainingRequestForm.value.typeOfTraining?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'training-typeOfTrainingOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="typeOfTrainingOther"
        ></app-input-textarea>
      </div>

      <app-input-number
        [label]="'How many people will be trained?'"
        [id]="'training-numberOfPeopleTrained'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeopleTrained"
      ></app-input-number>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'training-trainingDuration'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="trainingDuration"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'training-durationOfTrainingType'"
            [isRequired]="true"
            [name]="'training-trainingDurationType'"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="trainingDurationType"
          ></app-input-radio>
        </div>
      </div>

      <div>
        <p>How much funding do you need to complete this training?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [budgetRequestForm]="trainingRequestForm.controls.budget | asFormGroup"
          [isTraining]="true"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
        ></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
