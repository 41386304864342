import {QuestionBase, QuestionControlType} from './question-base.model';
import {ChoiceInputItem} from '../../../shared/modules/form-elements/models/form-elements.model';

export class DynamicForm {
  theme: 'light' | 'dark' = 'dark';
  style: 'default' | 'bordered' = 'bordered';
  questions: QuestionBase<string>[];

  constructor(options: {
    theme?: 'light' | 'dark',
    style?: 'default' | 'bordered',
    questions?: QuestionBase<string>[],
  } = {}) {
    this.theme = options.theme || 'dark';
    this.style = options.style || 'bordered';
    this.questions = options.questions || [];
  }
}

export const choiceInputItems: ChoiceInputItem[] = [
  {
    label: 'Short text',
    value: QuestionControlType.textbox
  },
  {
    label: 'Long text',
    value: QuestionControlType.textarea
  },
  {
    label: 'Multiple Choice (Multiple selections)',
    value: QuestionControlType.checkbox
  },
  {
    label: 'Single Select Check Box',
    value: QuestionControlType.radio
  },
  {
    label: 'Number',
    value: QuestionControlType.number
  },
  {
    label: 'Date',
    value: QuestionControlType.date
  },
];

export const themeChoices: ChoiceInputItem[] = [
  {
    label: 'Light',
    value: 'light'
  },
  {
    label: 'Dark',
    value: 'dark'
  },
];

export const styleChoices: ChoiceInputItem[] = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: 'Bordered',
    value: 'bordered'
  },
];
