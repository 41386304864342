import {FormBuilder, FormGroup} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';

export interface CSAspirational extends BaseEntity {
  // capacitySpectrumId: number;

}

export function createCSAspirational(params: Partial<CSAspirational>) {
  return Object.assign({}, createBaseEntity(params), {
    // capacitySpectrumId: params && params.capacitySpectrumId,
  }) as CSAspirational;
}

export function createCSAspirationalForm(formBuilder: FormBuilder, params: Partial<CSAspirational>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    // capacitySpectrumId: [params && params.capacitySpectrumId],
    // mission: [params && params.mission, Validators.required],
  }));
}
