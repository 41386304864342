import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {organisationTypeAnswers, profileCategoryAnswers} from '../../../../constants/profile-category-answers.const';

@Component({
  selector: 'app-profile',
  templateUrl: './organisation-profile.component.html',
  styleUrls: ['./organisation-profile.component.scss']
})
export class OrganisationProfileComponent implements OnInit {

  @Input() currentOrg: FormGroup;
  @Input() fromReview: boolean;
  @Input() currentTab: string;
  @Output() edit = new EventEmitter<boolean>();

  categories = profileCategoryAnswers;
  organisationTypeItems = organisationTypeAnswers;

  constructor() {
  }

  ngOnInit() {
  }
}
