import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createBaseEntityForm} from 'app/shared/models/base-entity.model';
import {
  AdherenceClubs, Camps, ClinicalServices,
  CommunityMobilization,
  Counseling,
  FundingRequest, FundingRequestBudget,
  HivTesting,
  HomeBasedCare, OtherFundingRequest,
  SupportGroups, SystemDevelopment, TargetedPrevention,
  Training
} from './funding-request.model';
import {FormValidationUtil} from '../../../../../../shared/utils/form-validation-util';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

// @Deprecated - see createFundingRequestFormForCustomApplication() below
export function createFundingRequestForm(formBuilder: FormBuilder, params?: Partial<FundingRequest>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    // projectDescription: [params && params.projectDescription || '', Validators.required],
    // additionalProjectExplanation: [params && params.additionalProjectExplanation || ''],
    // projectAlignmentWithPepfarsMission: [params && params.projectAlignmentWithPepfarsMission || '', Validators.required],
    // monthsToComplete: [params && params.monthsToComplete || '', Validators.required],
    // projectImplementationRisks: [params && params.projectImplementationRisks || '', Validators.required],
    // planToReduceRisks: [params && params.planToReduceRisks || '', Validators.required],
    // requestedFunding: [params && params.requestedFunding || '', Validators.required],
    // requestedFundingOther: [params && params.requestedFundingOther || ''],
    //
    // training: createTrainingForm(formBuilder, params && params.training),
    // supportGroups: createSupportGroupsForm(formBuilder, params && params.supportGroups),
    // adherenceClubs: createAdherenceClubsForm(formBuilder, params && params.adherenceClubs),
    // hivTesting: createHivTestingForm(formBuilder, params && params.hivTesting),
    // homeBasedCare: createHomeBasedCareForm(formBuilder, params && params.homeBasedCare),
    // counseling: createCounselingForm(formBuilder, params && params.counseling),
    // communityMobilization: createCommunityMobilizationForm(formBuilder, params && params.communityMobilization),
    // clinicalServices: createClinicalServicesForm(formBuilder, params && params.clinicalServices),
    // camps: createCampsForm(formBuilder, params && params.camps),
    // targetedPrevention: createTargetedPreventionForm(formBuilder, params && params.targetedPrevention),
    // systemDevelopment: createSystemDevelopmentForm(formBuilder, params && params.systemDevelopment),
    // otherFundingRequest: createOtherFundingRequestForm(formBuilder, params && params.otherFundingRequest),
  }));
}

function updateTraining(formGroup: FormGroup) {
  formGroup.get('training').updateValueAndValidity();
  const childFormGroup = formGroup.controls.training as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('typeOfTraining').updateValueAndValidity();
    childFormGroup.get('numberOfPeopleTrained').updateValueAndValidity();
    childFormGroup.get('trainingDuration').updateValueAndValidity();
    childFormGroup.get('trainingDurationType').updateValueAndValidity();
  }
}

function updateSupportGroups(formGroup: FormGroup) {
  formGroup.get('supportGroups').updateValueAndValidity();
  const childFormGroup = formGroup.controls.supportGroups as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('peopleReached').updateValueAndValidity();
    childFormGroup.get('numberOfSupportGroups').updateValueAndValidity();
    childFormGroup.get('numberOfPeoplePerGroup').updateValueAndValidity();
    childFormGroup.get('frequencyOfMeetings').updateValueAndValidity();
    childFormGroup.get('durationOfMeeting').updateValueAndValidity();
    childFormGroup.get('durationOfMeetingType').updateValueAndValidity();
  }
}

function updateAdherenceClubs(formGroup: FormGroup) {
  formGroup.get('adherenceClubs').updateValueAndValidity();
  const childFormGroup = formGroup.controls.adherenceClubs as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('typeOfAdherenceClub').updateValueAndValidity();
    childFormGroup.get('peopleReached').updateValueAndValidity();
    childFormGroup.get('numberOfAdherenceClubs').updateValueAndValidity();
    childFormGroup.get('numberOfPeoplePerClub').updateValueAndValidity();
    childFormGroup.get('frequencyOfMeetings').updateValueAndValidity();
    childFormGroup.get('durationOfMeeting').updateValueAndValidity();
    childFormGroup.get('durationOfMeetingType').updateValueAndValidity();
  }
}

function updateHivTesting(formGroup: FormGroup) {
  formGroup.get('hivTesting').updateValueAndValidity();
  const childFormGroup = formGroup.controls.hivTesting as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('typeOfHivTesting').updateValueAndValidity();
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
    childFormGroup.get('numberOfPeopleTargeted').updateValueAndValidity();
    childFormGroup.get('frequencyOfTesting').updateValueAndValidity();
    childFormGroup.get('durationOfTesting').updateValueAndValidity();
    childFormGroup.get('durationOfTestingType').updateValueAndValidity();
    childFormGroup.get('testKitSupplier').updateValueAndValidity();
  }
}

function updateHomeBasedCare(formGroup: FormGroup) {
  formGroup.get('homeBasedCare').updateValueAndValidity();
  const childFormGroup = formGroup.controls.homeBasedCare as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
    childFormGroup.get('numberOfPeopleReached').updateValueAndValidity();
    childFormGroup.get('numberOfCaregivers').updateValueAndValidity();
    childFormGroup.get('frequencyOfVisits').updateValueAndValidity();
  }
}

function updateCounseling(formGroup: FormGroup) {
  formGroup.get('counseling').updateValueAndValidity();
  const childFormGroup = formGroup.controls.counseling as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
    childFormGroup.get('numberOfPeopleTargeted').updateValueAndValidity();
    childFormGroup.get('numberOfSessions').updateValueAndValidity();
    childFormGroup.get('durationOfCounseling').updateValueAndValidity();
    childFormGroup.get('durationOfCounselingType').updateValueAndValidity();
    childFormGroup.get('counselingServicesProvider').updateValueAndValidity();
  }
}

function updateCommunityMobilization(formGroup: FormGroup) {
  formGroup.get('communityMobilization').updateValueAndValidity();
  const childFormGroup = formGroup.controls.communityMobilization as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('typesOfCommunityMobilization').updateValueAndValidity();
    childFormGroup.get('locationConducted').updateValueAndValidity();
    childFormGroup.get('focusedTopics').updateValueAndValidity();
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
    childFormGroup.get('frequencyOfCommunityMobilization').updateValueAndValidity();
    childFormGroup.get('numberOfPeopleTargeted').updateValueAndValidity();
    childFormGroup.get('durationOfCommunityMobilization').updateValueAndValidity();
    childFormGroup.get('durationOfCommunityMobilizationType').updateValueAndValidity();
  }
}

function updateClinicalServices(formGroup: FormGroup) {
  formGroup.get('clinicalServices').updateValueAndValidity();
  const childFormGroup = formGroup.controls.clinicalServices as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('typeOfClinicalServices').updateValueAndValidity();
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
    childFormGroup.get('numberOfPeopleTargeted').updateValueAndValidity();
    childFormGroup.get('locationOfService').updateValueAndValidity();
    childFormGroup.get('durationOfClinicalServices').updateValueAndValidity();
    childFormGroup.get('durationOfClinicalServicesType').updateValueAndValidity();
  }
}

function updateCamps(formGroup: FormGroup) {
  formGroup.get('camps').updateValueAndValidity();
  const childFormGroup = formGroup.controls.camps as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('typeOfCamps').updateValueAndValidity();
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
    childFormGroup.get('numberOfCamps').updateValueAndValidity();
    childFormGroup.get('numberOfChildrenOrYouth').updateValueAndValidity();
    childFormGroup.get('numberOfDays').updateValueAndValidity();
    childFormGroup.get('topicsDiscussed').updateValueAndValidity();
  }
}

function updateTargetedPrevention(formGroup: FormGroup) {
  formGroup.get('targetedPrevention').updateValueAndValidity();
  const childFormGroup = formGroup.controls.targetedPrevention as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('programs').updateValueAndValidity();
    childFormGroup.get('peopleReached').updateValueAndValidity();
    childFormGroup.get('numberOfPeople').updateValueAndValidity();
    childFormGroup.get('numberOfPeoplePerGroup').updateValueAndValidity();
    childFormGroup.get('numberOfSessionsPerProgram').updateValueAndValidity();
    childFormGroup.get('durationOfTargetedPrevention').updateValueAndValidity();
    childFormGroup.get('durationOfTargetedPreventionType').updateValueAndValidity();
  }
}

function updateSystemDevelopment(formGroup: FormGroup) {
  formGroup.get('systemDevelopment').updateValueAndValidity();
  const childFormGroup = formGroup.controls.systemDevelopment as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('targetedPopulation').updateValueAndValidity();
    childFormGroup.get('aspectBenefited').updateValueAndValidity();
    childFormGroup.get('durationOfSystemDevelopment').updateValueAndValidity();
    childFormGroup.get('durationOfSystemDevelopmentType').updateValueAndValidity();
  }
}

function updateOtherFundingRequest(formGroup: FormGroup) {
  formGroup.get('otherFundingRequest').updateValueAndValidity();
  const childFormGroup = formGroup.controls.otherFundingRequest as FormGroup;
  if (childFormGroup) {
    childFormGroup.get('peopleTargeted').updateValueAndValidity();
  }
}

export function createFundingRequestFormForCustomApplication(destroy$: Subject<boolean>,
                                                             formBuilder: FormBuilder,
                                                             params?: Partial<FundingRequest>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    requestedFunding: [params && params.requestedFunding || '', Validators.required],
    requestedFundingOther: [params && params.requestedFundingOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('OTHER');
      })],

    training: createTrainingForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('TRAINING');
      },
      params && params.training),

    supportGroups: createSupportGroupsForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('SUPPORT_GROUPS');
      },
      params && params.supportGroups),

    adherenceClubs: createAdherenceClubsForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('ADHERENCE_CLUBS');
      },
      params && params.adherenceClubs),

    hivTesting: createHivTestingForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('HIV_TESTING');
      },
      params && params.hivTesting),

    homeBasedCare: createHomeBasedCareForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('HOME_BASED_CARE');
      },
      params && params.homeBasedCare),

    counseling: createCounselingForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('COUNSELING');
      },
      params && params.counseling),

    communityMobilization: createCommunityMobilizationForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('COMMUNITY_MOBILIZATION');
      },
      params && params.communityMobilization),

    clinicalServices: createClinicalServicesForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('CLINICAL_SERVICES');
      },
      params && params.clinicalServices),

    camps: createCampsForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('CAMPS');
      },
      params && params.camps),

    targetedPrevention: createTargetedPreventionForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('TARGETED_PREVENTION');
      },
      params && params.targetedPrevention),

    systemDevelopment: createSystemDevelopmentForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('SYSTEM_DEVELOPMENT');
      },
      params && params.systemDevelopment),

    otherFundingRequest: createOtherFundingRequestForm(destroy$,
      formBuilder,
      () => () => {
        return formGroup.get('requestedFunding').value && formGroup.get('requestedFunding').value.includes('OTHER');
      },
      params && params.otherFundingRequest),
  }));

  formGroup.get('requestedFunding').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('requestedFundingOther').updateValueAndValidity();

      updateTraining(formGroup);
      updateSupportGroups(formGroup);
      updateAdherenceClubs(formGroup);
      updateHivTesting(formGroup);
      updateHomeBasedCare(formGroup);
      updateCounseling(formGroup);
      updateCommunityMobilization(formGroup);
      updateClinicalServices(formGroup);
      updateCamps(formGroup);
      updateTargetedPrevention(formGroup);
      updateSystemDevelopment(formGroup);
      updateOtherFundingRequest(formGroup);
    });

  return formGroup;
}

export function createTrainingForm(destroy$: Subject<boolean>,
                                   formBuilder: FormBuilder,
                                   predicate: () => {},
                                   params?: Partial<Training>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    typeOfTraining: [params && params.typeOfTraining || [], FormValidationUtil.requiredIfValidator(predicate())],
    typeOfTrainingOther: [params && params.typeOfTrainingOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('typeOfTraining').value && formGroup.get('typeOfTraining').value.includes('other');
      })],
    numberOfPeopleTrained: [params && params.numberOfPeopleTrained, FormValidationUtil.requiredIfValidator(predicate())],
    trainingDuration: [params && params.trainingDuration, FormValidationUtil.requiredIfValidator(predicate())],
    trainingDurationType: [params && params.trainingDurationType || '', FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('typeOfTraining').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('typeOfTrainingOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createSupportGroupsForm(destroy$: Subject<boolean>,
                                        formBuilder: FormBuilder,
                                        predicate: () => {},
                                        params?: Partial<SupportGroups>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    peopleReached: [params && params.peopleReached || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfSupportGroups: [params && params.numberOfSupportGroups, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeoplePerGroup: [params && params.numberOfPeoplePerGroup, FormValidationUtil.requiredIfValidator(predicate())],
    frequencyOfMeetings: [params && params.frequencyOfMeetings || '', FormValidationUtil.requiredIfValidator(predicate())],
    durationOfMeeting: [params && params.durationOfMeeting, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfMeetingType: [params && params.durationOfMeetingType || '', FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));
}

export function createAdherenceClubsForm(destroy$: Subject<boolean>,
                                         formBuilder: FormBuilder,
                                         predicate: () => {},
                                         params?: Partial<AdherenceClubs>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    typeOfAdherenceClub: [params && params.typeOfAdherenceClub || [], FormValidationUtil.requiredIfValidator(predicate())],
    typeOfAdherenceClubOther: [params && params.typeOfAdherenceClubOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('typeOfAdherenceClub').value && formGroup.get('typeOfAdherenceClub').value.includes('other');
      })
    ],
    peopleReached: [params && params.peopleReached || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfAdherenceClubs: [params && params.numberOfAdherenceClubs, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeoplePerClub: [params && params.numberOfPeoplePerClub, FormValidationUtil.requiredIfValidator(predicate())],
    frequencyOfMeetings: [params && params.frequencyOfMeetings || '', FormValidationUtil.requiredIfValidator(predicate())],
    durationOfMeeting: [params && params.durationOfMeeting, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfMeetingType: [params && params.durationOfMeetingType || '', FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('typeOfAdherenceClub').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('typeOfAdherenceClubOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createHivTestingForm(destroy$: Subject<boolean>,
                                     formBuilder: FormBuilder,
                                     predicate: () => {},
                                     params?: Partial<HivTesting>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    typeOfHivTesting: [params && params.typeOfHivTesting || [], FormValidationUtil.requiredIfValidator(predicate())],
    typeOfHivTestingOther: [params && params.typeOfHivTestingOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('typeOfHivTesting').value && formGroup.get('typeOfHivTesting').value.includes('other');
      })
    ],
    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeopleTargeted: [params && params.numberOfPeopleTargeted, FormValidationUtil.requiredIfValidator(predicate())],
    frequencyOfTesting: [params && params.frequencyOfTesting || '', FormValidationUtil.requiredIfValidator(predicate())],
    durationOfTesting: [params && params.durationOfTesting, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfTestingType: [params && params.durationOfTestingType || '', FormValidationUtil.requiredIfValidator(predicate())],
    testKitSupplier: [params && params.testKitSupplier || [], FormValidationUtil.requiredIfValidator(predicate())],
    testKitSupplierOther: [params && params.testKitSupplierOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('testKitSupplier').value && formGroup.get('testKitSupplier').value.includes('other');
      })
    ],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('typeOfHivTesting').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('typeOfHivTestingOther').updateValueAndValidity();
    });

  formGroup.get('testKitSupplier').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('testKitSupplierOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createHomeBasedCareForm(destroy$: Subject<boolean>,
                                        formBuilder: FormBuilder,
                                        predicate: () => {},
                                        params?: Partial<HomeBasedCare>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeopleReached: [params && params.numberOfPeopleReached, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfCaregivers: [params && params.numberOfCaregivers, FormValidationUtil.requiredIfValidator(predicate())],
    frequencyOfVisits: [params && params.frequencyOfVisits || '', FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));
}

export function createCounselingForm(destroy$: Subject<boolean>,
                                     formBuilder: FormBuilder,
                                     predicate: () => {},
                                     params?: Partial<Counseling>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeopleTargeted: [params && params.numberOfPeopleTargeted, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfSessions: [params && params.numberOfSessions, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfCounseling: [params && params.durationOfCounseling, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfCounselingType: [params && params.durationOfCounselingType || '', FormValidationUtil.requiredIfValidator(predicate())],
    counselingServicesProvider: [params && params.counselingServicesProvider || '', FormValidationUtil.requiredIfValidator(predicate())],
    counselingServicesProviderOther: [params && params.counselingServicesProviderOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('counselingServicesProvider').value && formGroup.get('counselingServicesProvider').value.includes('other');
      })
    ],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('counselingServicesProvider').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('counselingServicesProviderOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createCommunityMobilizationForm(destroy$: Subject<boolean>,
                                                formBuilder: FormBuilder,
                                                predicate: () => {},
                                                params?: Partial<CommunityMobilization>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    typesOfCommunityMobilization: [params && params.typesOfCommunityMobilization || [],
      FormValidationUtil.requiredIfValidator(predicate())],
    typesOfCommunityMobilizationOther: [params && params.typesOfCommunityMobilizationOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('typesOfCommunityMobilization').value && formGroup.get('typesOfCommunityMobilization').value.includes('other');
      })
    ],

    locationConducted: [params && params.locationConducted || [], FormValidationUtil.requiredIfValidator(predicate())],
    locationConductedOther: [params && params.locationConductedOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('locationConducted').value && formGroup.get('locationConducted').value.includes('other');
      })
    ],

    focusedTopics: [params && params.focusedTopics || [], FormValidationUtil.requiredIfValidator(predicate())],
    focusedTopicsOther: [params && params.focusedTopicsOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('focusedTopics').value && formGroup.get('focusedTopics').value.includes('other');
      })
    ],

    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    peopleTargetedOther: [params && params.peopleTargetedOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('peopleTargeted').value && formGroup.get('peopleTargeted').value.includes('other');
      })
    ],

    frequencyOfCommunityMobilization: [params && params.frequencyOfCommunityMobilization || '',
      FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeopleTargeted: [params && params.numberOfPeopleTargeted, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfCommunityMobilization: [params && params.durationOfCommunityMobilization,
      FormValidationUtil.requiredIfValidator(predicate())],
    durationOfCommunityMobilizationType: [params && params.durationOfCommunityMobilizationType || '',
      FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('typesOfCommunityMobilization').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('typesOfCommunityMobilizationOther').updateValueAndValidity();
    });

  formGroup.get('locationConducted').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('locationConductedOther').updateValueAndValidity();
    });

  formGroup.get('focusedTopics').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('focusedTopicsOther').updateValueAndValidity();
    });

  formGroup.get('peopleTargeted').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('peopleTargetedOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createClinicalServicesForm(destroy$: Subject<boolean>,
                                           formBuilder: FormBuilder,
                                           predicate: () => {},
                                           params?: Partial<ClinicalServices>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    typeOfClinicalServices: [params && params.typeOfClinicalServices || [], FormValidationUtil.requiredIfValidator(predicate())],
    typeOfClinicalServicesOther: [params && params.typeOfClinicalServicesOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('typeOfClinicalServices').value && formGroup.get('typeOfClinicalServices').value.includes('other');
      })
    ],
    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeopleTargeted: [params && params.numberOfPeopleTargeted, FormValidationUtil.requiredIfValidator(predicate())],
    locationOfService: [params && params.locationOfService || [], FormValidationUtil.requiredIfValidator(predicate())],
    durationOfClinicalServices: [params && params.durationOfClinicalServices, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfClinicalServicesType: [params && params.durationOfClinicalServicesType || '',
      FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('typeOfClinicalServices').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('typeOfClinicalServicesOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createCampsForm(destroy$: Subject<boolean>,
                                formBuilder: FormBuilder,
                                predicate: () => {},
                                params?: Partial<Camps>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    typeOfCamps: [params && params.typeOfCamps || [], FormValidationUtil.requiredIfValidator(predicate())],
    typeOfCampsOther: [params && params.typeOfCampsOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('typeOfCamps').value && formGroup.get('typeOfCamps').value.includes('other');
      })
    ],

    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfCamps: [params && params.numberOfCamps, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfChildrenOrYouth: [params && params.numberOfChildrenOrYouth, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfDays: [params && params.numberOfDays, FormValidationUtil.requiredIfValidator(predicate())],

    topicsDiscussed: [params && params.topicsDiscussed || [], FormValidationUtil.requiredIfValidator(predicate())],
    topicsDiscussedOther: [params && params.topicsDiscussedOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('topicsDiscussed').value && formGroup.get('topicsDiscussed').value.includes('other');
      })
    ],

    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('typeOfCamps').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('typeOfCampsOther').updateValueAndValidity();
    });

  formGroup.get('topicsDiscussed').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('topicsDiscussedOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createTargetedPreventionForm(destroy$: Subject<boolean>,
                                             formBuilder: FormBuilder,
                                             predicate: () => {},
                                             params?: Partial<TargetedPrevention>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    programs: [params && params.programs || [], FormValidationUtil.requiredIfValidator(predicate())],
    programsOther: [params && params.programsOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('programs').value && formGroup.get('programs').value.includes('other');
      })
    ],
    peopleReached: [params && params.peopleReached || [], FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeople: [params && params.numberOfPeople, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfPeoplePerGroup: [params && params.numberOfPeoplePerGroup, FormValidationUtil.requiredIfValidator(predicate())],
    numberOfSessionsPerProgram: [params && params.numberOfSessionsPerProgram, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfTargetedPrevention: [params && params.durationOfTargetedPrevention, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfTargetedPreventionType: [params && params.durationOfTargetedPreventionType,
      FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('programs').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('programsOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createSystemDevelopmentForm(destroy$: Subject<boolean>,
                                            formBuilder: FormBuilder,
                                            predicate: () => {},
                                            params?: Partial<SystemDevelopment>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    targetedPopulation: [params && params.targetedPopulation || [], FormValidationUtil.requiredIfValidator(predicate())],
    aspectBenefited: [params && params.aspectBenefited || [], FormValidationUtil.requiredIfValidator(predicate())],
    aspectBenefitedOther: [params && params.aspectBenefitedOther || '',
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('aspectBenefited').value && formGroup.get('aspectBenefited').value.includes('other');
      })
    ],
    durationOfSystemDevelopment: [params && params.durationOfSystemDevelopment, FormValidationUtil.requiredIfValidator(predicate())],
    durationOfSystemDevelopmentType: [params && params.durationOfSystemDevelopmentType || '',
      FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));

  formGroup.get('aspectBenefited').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('aspectBenefitedOther').updateValueAndValidity();
    });

  return formGroup;
}

export function createOtherFundingRequestForm(destroy$: Subject<boolean>,
                                              formBuilder: FormBuilder,
                                              predicate: () => {},
                                              params?: Partial<OtherFundingRequest>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    peopleTargeted: [params && params.peopleTargeted || [], FormValidationUtil.requiredIfValidator(predicate())],
    budget: createFundingRequestBudgetForm(formBuilder, params && params.budget),
  }));
}

export function createFundingRequestBudgetForm(formBuilder: FormBuilder, params?: Partial<FundingRequestBudget>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    training: [params && params.training],
    suppliesAndMaterials: [params && params.suppliesAndMaterials],
    equipmentAndFurniture: [params && params.equipmentAndFurniture],
    personnel: [params && params.personnel],
    travelOrTransport: [params && params.travelOrTransport],
    administration: [params && params.administration],
  }));
}
