import {Injectable} from '@angular/core';
import {FormBuilderStore} from './form-builder.store';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../environments/environment';
import {createFormBuilderDTO, FormBuilderDTO, FormBuilderModel} from './form-builder.model';
import {tap} from 'rxjs/operators';
import {SessionQuery} from '../../../../../../shared/stores/session/session.query';

@Injectable({providedIn: 'root'})
export class FormBuilderService {

  url = `${environment.apiUrl}/dynamic-form`;

  constructor(protected store: FormBuilderStore,
              private http: HttpClient,
              private sessionQuery: SessionQuery) {
  }

  getForms(): Observable<FormBuilderModel[]> {
    const funderId = this.sessionQuery.getUser().funderId;
    let url = `${this.url}`;
    if (funderId) {
      url += `?funderId=${funderId}`;
    }
    return this.http.get<FormBuilderModel[]>(url).pipe(tap(list => {
      this.setList(list);
    }));
  }

  getTemplates(): Observable<FormBuilderModel[]> {
    const url = `${this.url}?templateForm=true`;
    return this.http.get<FormBuilderModel[]>(url).pipe(tap(list => {
      this.setTemplateList(list);
    }));
  }

  getCompleteFormsByGrantWindow(isAdmin = false, funderId?: number): Observable<FormBuilderModel[]> {
    const currentFunderId = this.sessionQuery.getUser().funderId;
    if (currentFunderId) {
      funderId = currentFunderId;
    }

    let url = `${this.url}/complete-forms?isAdmin=${isAdmin}`;
    if (funderId) {
      url += `&funderId=${funderId}`;
    }
    return this.http.get<FormBuilderModel[]>(url).pipe(tap(list => {
      this.setList(list);
    }));
  }

  getOne(id: number): Observable<FormBuilderDTO> {
    const url = `${this.url}/${id}`;
    return this.http.get<FormBuilderDTO>(url).pipe(tap(item => {
      this.setActive({...createFormBuilderDTO(item)});
    }));
  }

  create(formBuilder: FormBuilderModel): Observable<FormBuilderModel> {
    const url = `${this.url}`;
    return this.http.post<FormBuilderModel>(url, formBuilder).pipe(tap(() => {
      this.getForms().subscribe();
    }));
  }

  update(formBuilder: FormBuilderModel): Observable<FormBuilderModel> {
    const url = `${this.url}/${formBuilder.id}`;
    return this.http.put<FormBuilderModel>(url, formBuilder).pipe(tap(() => {
      this.getForms().subscribe();
    }));
  }

  delete(formId: number) {
    const url = `${this.url}/${formId}`;
    return this.http.delete(url);
  }

  clone(formId: number, asForm?: boolean) {
    let url = `${this.url}/${formId}/clone`;

    const funderId = this.sessionQuery.getUser().funderId;
    if (funderId) {
      url += `?funderId=${funderId}`;
      if (asForm) {
        url += `&asForm=true`;
      }
    } else {
      if (asForm) {
        url += `?asForm=true`;
      }
    }

    return this.http.put<FormBuilderModel>(url, undefined).pipe(tap(() => {
      this.getForms().subscribe();
    }));
  }

  private setList(list: FormBuilderModel[]) {
    this.store.update({list});
  }

  private setTemplateList(templateList: FormBuilderModel[]) {
    this.store.update({templateList});
  }

  private setActive(active: FormBuilderDTO) {
    this.store.update({active});
  }
}
