import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'colspan'
})
export class ColspanPipe implements PipeTransform {

  transform(columns: any[], useBulkActions: boolean): number {
    const cols = columns.find(item => item.rows);
    let columnCount = 0;
    columnCount += columns.length;

    // If there are child columns, include the length of that array as well
    if (cols && cols.rows && cols.rows.length) {
      columnCount += cols.rows[0].columns.length;
    }
    return useBulkActions ? columnCount + 1 : columnCount;
  }

}
