import {Injectable} from '@angular/core';
import {Mail} from './email.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface EmailState extends EntityState<Mail> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'email'})
export class EmailStore extends EntityStore<EmailState> {

  constructor() {
    super();
  }

}
