import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollToTarget(target: HTMLElement) {
    target.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
