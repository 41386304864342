import {Injectable} from '@angular/core';
import {GrantWorkflowStore} from './grant-workflow.store';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {GrantWorkflow, InternalCommentHistory, ReviewStepType} from './grant-workflow.model';
import {District} from '../../../shared/models/geo-location.model';
import {WorkPlanCommentHistory} from '../../work-plan-and-targets/store/work-plan-and-targets.model';

@Injectable({providedIn: 'root'})
export class GrantWorkflowService {

  private url = `${environment.apiUrl}/grant-workflow`;

  constructor(protected store: GrantWorkflowStore,
              private http: HttpClient) {
  }

  fetchWorkflowByGrantWindowIdAndOrgId(grantWindowId: number, orgId: number): Observable<GrantWorkflow> {
    const url = `${this.url}?grantWindowId=${grantWindowId}&organizationId=${orgId}`;
    return this.http.get<GrantWorkflow>(url).pipe(
      tap(item => {
        return item;
      })
    );
  }

  fetchWorkflowById(workflowId: number): Observable<GrantWorkflow> {
    const url = `${this.url}/${workflowId}`;
    return this.http.get<GrantWorkflow>(url).pipe(
      tap(item => {
        return item;
      })
    );
  }

  createWorkflowByGrantWindowIdAndOrgId(grantWindowId: number, orgId: number) {
    const url = `${this.url}?grantWindowId=${grantWindowId}&organizationId=${orgId}&hasAgreed=true`;
    return this.http.post<GrantWorkflow>(url, undefined).pipe(
      tap(() => {
        return;
      })
    );
  }

  getDistrictsByWorkflowId(workflowId: number): Observable<District[]> {
    const url = `${this.url}/${workflowId}/window-districts`;
    return this.http.get<District[]>(url);
  }

  updateWorkPlanStatus(workflowId: number, status: string, body: WorkPlanCommentHistory) {
    const url = `${this.url}/${workflowId}/work-plan-status/${status}`;
    return this.http.put<GrantWorkflow>(url, body).pipe(
      tap(() => {
        return;
      })
    );
  }

  updateReviewStep(workflowId: number, step: ReviewStepType, body: InternalCommentHistory) {
    const url = `${this.url}/${workflowId}/review-step/${step}`;
    return this.http.put<GrantWorkflow>(url, body).pipe(
      tap(() => {
        return;
      })
    );
  }
}
