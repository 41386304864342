import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../../../../shared/models/base-entity.model';
import {
  createCSFinancialSituationFunder,
  createCSFinancialSituationFunderListForm,
  CSFinancialSituationFunder
} from '../components/cs-financial-situation-funder/store/cs-financial-situation-funder.model';
import {FormValidationUtil} from '../../../../../../../../shared/utils/form-validation-util';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export enum FinancialSituationEndpointType {
  ANNUAL_OPERATING_BUDGET = 'ANNUAL_OPERATING_BUDGET',
  FINANCIAL_SITUATION = 'FINANCIAL_SITUATION',
  FUNDING_METHOD = 'FUNDING_METHOD',
  INCOME_GENERATION_ACTIVITY = 'INCOME_GENERATION_ACTIVITY',
  COST_SHARING = 'COST_SHARING',
  FUNDING_SOURCE = 'FUNDING_SOURCE'
}

export interface CSFinancialSituation extends BaseEntity {
  annualOperatingBudget: string;
  financialSituation: string;
  fundingMethods: string[];
  fundingMethodOther: string; // if fundingMethods === 'OTHER'
  incomeGenerationActivity: string;
  hasBadCredit: boolean;
  badCreditExplain: string; // if hasBadCredit === true
  hasCostSharing: string;
  costSharingExplain: string; // if hasCostSharing === 'YES'
  fundingSource: string;
  funders: CSFinancialSituationFunder[];
}

export function createCSFinancialSituation(params: Partial<CSFinancialSituation>) {
  return Object.assign({}, createBaseEntity(params), {
    annualOperatingBudget: params && params.annualOperatingBudget,
    financialSituation: params && params.financialSituation,
    fundingMethods: params && params.fundingMethods,
    fundingMethodOther: params && params.fundingMethodOther,
    incomeGenerationActivity: params && params.incomeGenerationActivity,
    hasBadCredit: params && params.hasBadCredit,
    badCreditExplain: params && params.badCreditExplain,
    hasCostSharing: params && params.hasCostSharing,
    costSharingExplain: params && params.costSharingExplain,
    fundingSource: params && params.fundingSource,
    funders: createFunderList(params && params.funders),
  }) as CSFinancialSituation;
}

export function createCSFinancialSituationForm(destroy$: Subject<boolean>,
                                               formBuilder: FormBuilder,
                                               params: Partial<CSFinancialSituation>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    annualOperatingBudget: [params && params.annualOperatingBudget, Validators.required],
    financialSituation: [params && params.financialSituation, Validators.required],
    fundingMethods: [params && params.fundingMethods, Validators.required],
    fundingMethodOther: [params && params.fundingMethodOther,
      FormValidationUtil.requiredIfValidator(() => {
        return formGroup.get('fundingMethods').value && formGroup.get('fundingMethods').value.includes('OTHER');
      })
    ],
    incomeGenerationActivity: [params && params.incomeGenerationActivity, Validators.required],
    hasBadCredit: [params && params.hasBadCredit, Validators.required],
    badCreditExplain: [params && params.badCreditExplain,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasBadCredit').value === true)
    ],
    hasCostSharing: [params && params.hasCostSharing, Validators.required],
    costSharingExplain: [params && params.costSharingExplain,
      FormValidationUtil.requiredIfValidator(() => formGroup.get('hasCostSharing').value === 'YES')
    ],
    fundingSource: [params && params.fundingSource, Validators.required],
    funders: createCSFinancialSituationFunderListForm(formBuilder, (params && params.funders)),
  }));

  formGroup.get('fundingMethods').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('fundingMethodOther').updateValueAndValidity();
    });

  formGroup.get('hasBadCredit').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('badCreditExplain').updateValueAndValidity();
    });

  formGroup.get('hasCostSharing').valueChanges.pipe(takeUntil(destroy$))
    .subscribe(() => {
      formGroup.get('costSharingExplain').updateValueAndValidity();
    });

  return formGroup;
}

export function createFunderList(funders?: CSFinancialSituationFunder[]): CSFinancialSituationFunder[] {
  return funders && funders.length > 0 ?
    funders.map(funder => createCSFinancialSituationFunder(funder)) :
    [];
}
