import {ChoiceInputItem} from '../../../shared/modules/form-elements/models/form-elements.model';

export enum QuestionControlType {
  'textbox' = 'textbox',
  'textarea' = 'textarea',
  'checkbox' = 'checkbox',
  'radio' = 'radio',
  'number' = 'number',
  'date' = 'date'
}

export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: QuestionControlType;
  // type: string; // no need to use this for checkbox/radios
  options: ChoiceInputItem[]; // used for checkbox/radios old model -> {key: string, value: string}

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: QuestionControlType,
    // type?: string
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || QuestionControlType.textbox;
    // this.type = options.type || '';
  }
}
