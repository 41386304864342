<div [class]="style + '-input-group position-relative'" [class.input-is-grey]="isGrey">
  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">
    <p
      [class]="style + '-input-label label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>
  <p
    [class]="style + '-input readonly-input font-weight-normal font-size-small text-left'"
    [class.borderless]="!hasBorder"
    [class.disabled]="isGrey"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    [textMask]="{mask: mask}"
  >
    <ng-container *ngIf="usePipe">
      {{value | currency : 'R'}}
    </ng-container>
    <ng-container *ngIf="!usePipe">
      {{value}}
    </ng-container>
  </p>
</div>
