import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {FormBuilderStore, FormBuilderState} from './form-builder.store';

@Injectable({providedIn: 'root'})
export class FormBuilderQuery extends Query<FormBuilderState> {

  private list$ = this.select(state => state.list);
  private templateList$ = this.select(state => state.templateList);
  private active$ = this.select(state => state.active);

  constructor(protected store: FormBuilderStore) {
    super(store);
  }

  getListObservable() {
    return this.list$;
  }

  getTemplateListObservable() {
    return this.templateList$;
  }

  getActiveObservable() {
    return this.active$;
  }
}
