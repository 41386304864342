import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GrantWorkflowNavigator} from '../../grant-workflow-navigator';
import {GrantWindowService} from '../../../grant-window/store/grant-window.service';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {CustomApplicationService} from '../custom-application/store/custom-application.service';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-application-confirmation',
  templateUrl: './application-confirmation.component.html',
  styleUrls: ['./application-confirmation.component.css']
})
export class ApplicationConfirmationComponent implements OnInit, OnDestroy {

  workflowId: number;
  isAdmin: boolean;

  constructor(public router: Router,
              public route: ActivatedRoute,
              private grantWindowService: GrantWindowService,
              private customApplicationService: CustomApplicationService,
              private grantWorkflowNavigator: GrantWorkflowNavigator,
              private sessionQuery: SessionQuery,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.workflowId = this.route.snapshot.params.workflowId;
    this.setIsAdmin();
    // this.grantWindowService.showOrgProfile = true;
  }

  ngOnDestroy() {
    // this.grantWindowService.showOrgProfile = false;
  }

  goFaq() {
    // TODO
    // this.router.navigateByUrl('/landing/section/faq');
  }

  private setIsAdmin() {
    this.isAdmin = this.sessionQuery.hasRole(['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER']);
  }

  reset() {
    this.customApplicationService.resetCustomApplication(this.workflowId).subscribe(() => {
      this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
    }, response => {
      this.alertService.setAlert(response, 'error', 'appConfirmation');
    });
  }
}
