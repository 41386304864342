<div class="wrapper esx-page">

  <div class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Capacity Spectrum
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 mx-auto esx-content">
    <div class="h-100 mt-2">

      <div class="profile-actions float-right">
        <button
          class="btn btn-sm btn-primary btn-outline-dark profile-btn"
          [routerLink]="'/capacity-spectrum/edit/' + organisationId"
        >Update
        </button>
      </div>

      <app-alert
        class="w-100"
        [id]="'spectrum'"
      ></app-alert>

      <app-spinner [loadingObs]="isLoading">

        <div class="row p-2 m-2 pt-4 h-100 w-100 overflow-auto">
          <div class="col-4">

            <div class="mr-auto">

              <h4 class="text-shadow">
                Score
                <a class="btn btn-link float-right mr-1 p-0 m-0 mt-2 font-link" (click)="download()">
                  Download
                  <i class="fa fa-download"></i>
                </a>
              </h4>
            </div>

            <table *ngIf="score" id="report" class="table table-striped table-bordered table-sm">
              <thead class="bg-dark text-light">
              <tr>
                <td>Elements</td>
                <td>Status</td>
                <td>Score</td>
                <td>Percentage</td>
              </tr>
              </thead>
              <tbody>
              <!-- https://stackoverflow.com/questions/38582293/how-to-declare-a-variable-in-a-template-in-angular -->
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.organizationalStructure) as status">
                <td>Organisation Structure</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.organizationalStructure?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.organizationalStructure)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.humanResources) as status">
                <td>Human Resources</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.humanResources?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.humanResources)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.governance) as status">
                <td>Governance</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.governance?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.governance)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.financialManagement) as status">
                <td>Financial Management</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.financialManagement?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.financialManagement)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.financialSituation) as status">
                <td>Financial Situation</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.financialSituation?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.financialSituation)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.mne) as status">
                <td>M&E Reporting and Planning</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.mne?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.mne)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.marketingAndPromotion) as status">
                <td>Marketing and Promotion</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.marketingAndPromotion?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.marketingAndPromotion)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.networkAndLinkages) as status">
                <td>Network and Linkages</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.networkAndLinkages?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.networkAndLinkages)}} %</td>
              </tr>
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.innovationAndTechnology) as status">
                <td>Innovation and Technology</td>
                <td>
                  <span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span>
                </td>
                <td>{{score.innovationAndTechnology?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.innovationAndTechnology)}} %</td>
              </tr>
              </tbody>
              <tfoot class="bg-dark text-light">
              <tr *ngVar="capacitySpectrumUtil.getStatus(score.totalScore) as status">
                <td>Total</td>
                <td><span class="badge badge-pill" [ngClass]="status.class">{{status.value}}</span></td>
                <td>{{score.totalScore?.score || 0}}</td>
                <td>{{scorePercentageUtil.scoreDTOAsPercentageString(score.totalScore)}} %</td>
              </tr>
              </tfoot>
            </table>

          </div>

          <div class="col-8">
            <div id="chart-container" class="d-block">
              <canvas
                baseChart
                [data]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [colors]="chartColors"
                [chartType]="'pie'"
              ></canvas>
            </div>
          </div>

        </div>
      </app-spinner>
    </div>
  </div>
</div>
