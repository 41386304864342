import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {monthsToCompleteAnswers, requestedFundingAnswers} from './constants/funding-request-answers/funding-request-question-answers.const';
import {FormValidationService} from '../../../../../services/form-validation/form-validation.service';
import {removeUnderscore} from '../../../../../shared/utils/form-validation.util';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-funding-request',
  templateUrl: './funding-request.component.html',
  styleUrls: ['./funding-request.component.scss']
})
export class FundingRequestComponent implements OnInit, OnDestroy {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() fundingRequestForm: FormGroup;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;

  @Output() isValid = new EventEmitter<boolean>();

  monthsToComplete = monthsToCompleteAnswers;
  requestedFunding = requestedFundingAnswers;

  statusChangesSubscription: Subscription;

  constructor(public formValidation: FormValidationService) {
  }

  ngOnInit() {
    this.statusChangesSubscription = this.fundingRequestForm.statusChanges.subscribe(status => {
      status === 'INVALID' ?
        this.isValid.emit(false) :
        this.isValid.emit(true);
    });
  }

  ngOnDestroy() {
    this.statusChangesSubscription.unsubscribe();
  }

  editFundingRequests(value: any) {
    if (value) {
      this.setChildValidations(value.toLowerCase());
    }
  }

  setChildValidations(value: string) {
    if (value.includes('_')) {
      value = removeUnderscore(value);
    }
    if (value === 'other') {
      this.formValidation.setValidations(
        this.fundingRequestForm.value.requestedFunding,
        this.fundingRequestForm.controls.requestedFundingOther
      );
    } else {
      const values = (this.fundingRequestForm.controls[value] as FormGroup);
      // Remove the BaseEntity and other non-required controls.
      const notRequired = [
        'createdOn',
        'createdBy',
        'modifiedOn',
        'modifiedBy',
        'id',
        'version',
        'budget'
      ];
      const valuesArray = [];
      let valuesObj: {};
      Object.keys(values.controls).forEach(key => {
        if (!notRequired.includes(key)) {
          valuesArray.push(values.controls[key]);
        }
        valuesObj = Object.assign({}, valuesArray);
      });
      const controls: AbstractControl[] = (Object as any).values(valuesObj);
      this.formValidation.setMultipleArrayValidations(
        this.fundingRequestForm.value.requestedFunding,
        controls,
        value
      );
    }
  }
}
