<div class="py-3">
  <button class="btn btn-secondary float-right" (click)="openMessagesModal()">
    View Messages
  </button>
</div>

<ng-template #modalContent>
  <div>
    <div class="w-100 card card-login p-4">
      <button class="close-modal" type="button" (click)="closeModal()">
        <i class="far fa-times-circle"></i>
      </button>

      <div class="flex-center mr-2">
        <div class="scroll_buttons">
          <button
            class="btn-scroll"
            [class.disabled]="workflow?.workPlanCommentHistoryList?.length <= 0"
            [disabled]="workflow?.workPlanCommentHistoryList?.length <= 0"
            (click)="scroll('up')"
          >
            <i class="fas fa-angle-double-up"></i>
          </button>
          <button
            class="btn-scroll btn-not"
            [class.disabled]="workflow?.workPlanCommentHistoryList?.length <= 0"
            [disabled]="workflow?.workPlanCommentHistoryList?.length <= 0"
            (click)="scroll('down')"
          >
            <i class="fas fa-angle-double-down"></i>
          </button>
        </div>
        <div class="msg_history_review w-90 mr-auto">

          <ng-container *ngIf="workflow?.workPlanCommentHistoryList?.length > 0; else noMessages">
            <ng-container *ngFor="let history of workflow?.workPlanCommentHistoryList">
              <ng-container *ngIf="history.userType === 'COORDINATOR'">
                <div class="incoming_msg">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="incoming_msg_img">
                      <img src="https://ptetutorials.com/images/user-profile.png" alt="GC">
                    </div>
                    <p class="m-0 ml-1">{{history.userType}}</p>
                  </div>
                  <div class="received_msg my-2">
                    <div class="received_width_msg_review">
                      <div class="coordinator_chat d-flex align-items-start">
                        <!-- Keep long messages truncated with a button to expand them if needed -->
                        <p [class.truncated]="history.message.length > 45" [class.msg_expand]="history.isOpen">
                          {{history.message}}
                        </p>
                        <span (click)="history.isOpen = !history.isOpen" *ngIf="history.message.length > 45">
                          <i class="fas fa-angle-double-down msg_expand_btn mr-1 mt-2" *ngIf="!history.isOpen"></i>
                          <i class="fas fa-angle-double-up msg_expand_btn mr-1 mt-2" *ngIf="history.isOpen"></i>
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-start">
                        <span class="time_date_review mt-1">{{history.date | date:'short'}}</span>
                        <span class="time_date_review mt-1  text-primary">
                          {{history.action}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.userType === 'APPLICANT'">
                <div class="incoming_msg">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="incoming_msg_img">
                      <img src="https://ptetutorials.com/images/user-profile.png" alt="GC">
                    </div>
                    <p class="m-0 ml-1">{{history.userType}}</p>
                  </div>
                  <div class="received_msg my-2">
                    <div class="received_width_msg_review">
                      <div class="applicant_chat d-flex align-items-start">
                        <!-- Keep long messages truncated with a button to expand them if needed -->
                        <p [class.truncated]="history.message.length > 45" [class.msg_expand]="history.isOpen">
                          {{history.message}}
                        </p>
                        <span (click)="history.isOpen = !history.isOpen" *ngIf="history.message.length > 45">
                      <i class="fas fa-angle-double-down msg_expand_btn mr-1 mt-2" *ngIf="!history.isOpen"></i>
                      <i class="fas fa-angle-double-up msg_expand_btn mr-1 mt-2" *ngIf="history.isOpen"></i>
                    </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-start">
                        <span class="time_date_review mt-1">{{history.date | date:'short'}}</span>
                        <span class="time_date_review mt-1  text-primary">
                      {{history.action}}
                    </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="history.userType === 'MANAGER' || history.userType === 'FUNDER' || history.userType === 'ADMIN'">
                <div class="incoming_msg">
                  <div class="d-flex justify-content-end align-items-center">
                    <p class="m-0 mr-1">{{history.userType}}</p>
                    <div class="incoming_msg_img">
                      <img src="https://ptetutorials.com/images/user-profile.png" alt="GC">
                    </div>
                  </div>
                </div>
                <div class="outgoing_msg mt-1 mb-1">
                  <div class="sent_msg_review">
                    <div class="admin_chat d-flex align-items-start">
                  <span (click)="history.isOpen = !history.isOpen" *ngIf="history.message.length > 45">
                    <i class="fas fa-angle-double-down msg_expand_btn ml-1 mt-2" *ngIf="!history.isOpen"></i>
                    <i class="fas fa-angle-double-up msg_expand_btn ml-1 mt-2" *ngIf="history.isOpen"></i>
                  </span>
                      <!-- Keep long messages truncated with a button to expand them if needed -->
                      <p [class.truncated]="history.message.length > 45" [class.msg_expand]="history.isOpen">
                        {{history.message}}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="time_date_review mt-1">{{history.date | date:'short'}}</span>
                      <span class="time_date_review mt-1 text-primary">
                    {{history.action}}
                  </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <!-- Expand the entire chat window vertically for more in depth browsing -->
      <button
        class="chat_expand_btn"
        [class.disabled]="workflow?.workPlanCommentHistoryList?.length <= 0"
        [disabled]="workflow?.workPlanCommentHistoryList?.length <= 0"
        (click)="chatExpanded = !chatExpanded"
      >
        <i class="fas fa-angle-double-down" *ngIf="!chatExpanded"></i>
        <i class="fas fa-angle-double-up" *ngIf="chatExpanded"></i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #noMessages>
  <div class="w-100 flex-center text-light">
    There are no messages yet.
  </div>
</ng-template>
