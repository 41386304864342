import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../shared-common/services/modal/modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() title: string;
  @Input() message: string;
  @Output() isConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  options: NgbModalOptions;

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Confirmation Modal',
      size: 'sm',
      centered: true,
      beforeDismiss: () => {
        this.isConfirmed.emit(false);

        return true;
      }
    };

    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  confirm() {
    this.isConfirmed.emit(true);
    this.modalService.closeModal();
  }

  closeModal() {
    this.isConfirmed.emit(false);
    this.modalService.closeModal();
  }
}
