import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {budgetCategoryAnswers} from '../../../../../modules/work-plan-and-targets/components/work-plan-and-targets/work-plan/funding-request/objective/activity/activity.const';

@Pipe({
  name: 'work-plan-categories'
})
@Injectable({providedIn: 'root'})
export class WorkPlanCategoriesPipe implements PipeTransform {

  budgetCategoryOptions = budgetCategoryAnswers;

  transform(value: string): string {
    return this.getCategoryByValue(value);
  }

  getCategoryByValue(code: string): string {
    const found = this.budgetCategoryOptions.find(item => item.value === code);
    if (found) {
      return found.label;
    }

    return 'Unknown';
  }

}
