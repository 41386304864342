<form [formGroup]="siteVisitRecentChangesForm">
  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'changesInLeadership'"
    [label]="'Changes in leadership'"
    [isRequired]="true"
    [id]="'changesInLeadership'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="changesInLeadership"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'gainOrLossOfFunders'"
    [label]="'Gain or loss of major funders'"
    [isRequired]="true"
    [id]="'gainOrLossOfFunders'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="gainOrLossOfFunders"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'newProgramsAdded'"
    [label]="'New programs or services have been added'"
    [isRequired]="true"
    [id]="'newProgramsAdded'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="newProgramsAdded"
  ></app-widget-check-two>

  <app-widget-check-two
    [isReadOnly]="isReadOnly"
    [name]="'majorProgramsStopped'"
    [label]="'Major programs or services have been stopped'"
    [isRequired]="true"
    [id]="'majorProgramsStopped'"
    [theme]="theme"
    [style]="style"
    [showLabel]="true"
    formControlName="majorProgramsStopped"
  ></app-widget-check-two>
</form>
