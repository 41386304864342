import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../shared/models/base-entity.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Organisation} from '../../org-profile/store/organisation.model';

export interface ServiceMatrix extends BaseEntity {
  organisation: Organisation;
  targetPopulations: string[];
  impactAreaSubCategories: string[];
  activities: ServiceMatrixActivity[];
}

export function createServiceMatrix(params: Partial<ServiceMatrix>) {
  return Object.assign({}, createBaseEntity(params), {
    organisation: params && params.organisation,
    targetPopulations: params && params.targetPopulations,
    impactAreaSubCategories: params && params.impactAreaSubCategories,
    activities: createServiceMatrixActivityList(params && params.activities),
  }) as ServiceMatrix;
}

export function createServiceMatrixActivityList(activities?: ServiceMatrixActivity[]): ServiceMatrixActivity[] {
  return activities && activities.length > 0 ?
    activities.map(activity => createServiceMatrixActivity(activity)) :
    [];
}

export function createServiceMatrixForm(formBuilder: FormBuilder,
                                        params: Partial<ServiceMatrix>): FormGroup {
  const activities = buildServiceMatrixActivityListForm(formBuilder, (params && params.activities));

  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    organisation: [params && params.organisation, Validators.required],
    targetPopulations: [params && params.targetPopulations, Validators.required],
    impactAreaSubCategories: [params && params.impactAreaSubCategories, Validators.required],
    activities,
  }));
}



// Activity
export interface ServiceMatrixActivity extends BaseEntity {
  activity: string;
  impactAreaSubCategory: string;
}

export function buildServiceMatrixActivityListForm(formBuilder: FormBuilder, params: ServiceMatrixActivity[]): FormArray {
  const activities: FormArray = formBuilder.array([]);
  if (params && params.length > 0) {
    params.forEach(param => {
      activities.push(createServiceMatrixActivityForm(formBuilder, param));
    });
  }
  return activities;
}

export function createServiceMatrixActivity(params: Partial<ServiceMatrixActivity>) {
  return Object.assign({}, createBaseEntity(params), {
    activity: params && params.activity,
    impactAreaSubCategory: params && params.impactAreaSubCategory,
  }) as ServiceMatrixActivity;
}

export function createServiceMatrixActivityForm(formBuilder: FormBuilder, params: Partial<ServiceMatrixActivity>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    activity: [params && params.activity, Validators.required],
    impactAreaSubCategory: [params && params.impactAreaSubCategory, Validators.required],
  }));
}
