<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">

    <div *ngFor="let question of formTemplate.questions">
      <app-dynamic-form-question
        [question]="question"
        [theme]="formTemplate.theme"
        [style]="formTemplate.style"
        [form]="form"
      ></app-dynamic-form-question>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Test Save</button>
    </div>
  </form>

  <div
    *ngIf="payLoad"
    class="form-row"
    [class.text-light]="formTemplate.theme === 'dark'"
  >
    <strong>This form would save the following values</strong><br>{{payLoad | json}}
  </div>
</div>
