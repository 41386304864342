<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="financialManagementForm">

    <app-input-select-single
      *ngIf="budgetCreationAnswers && budgetCreationAnswers.length"
      [label]="'How is the budget created?'"
      [items]="budgetCreationAnswers"
      [id]="'howIsBudgetCreated'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="howIsBudgetCreated"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="financialPolicyAnswers && financialPolicyAnswers.length"
      [label]="'Which statement best describes the organisation’s financial policy?'"
      [items]="financialPolicyAnswers"
      [id]="'financialPolicy'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="financialPolicy"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="financialPolicyElementAnswers && financialPolicyElementAnswers.length"
      [choices]="financialPolicyElementAnswers"
      [label]="'Which of the following elements are included in the organisation\'s financial policy? (check all that apply)'"
      [id]="'financialPolicyElements'"
      [name]="'financialPolicyElements'"
      [theme]="theme"
      [style]="style"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      (selected)="checkOther(
        'OTHER',
        $event,
        financialManagementForm.controls.financialPolicyElements,
        financialManagementForm.controls.financialPolicyElementsOther
      )"
      formControlName="financialPolicyElements"
    ></app-input-checkbox>
    <div *ngIf="financialManagementForm.value.financialPolicyElements?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If Other (please specify)'"
        [id]="'financialPolicyElementsOther'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="financialPolicyElementsOther"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="assetInventoryAnswers && assetInventoryAnswers.length"
      [label]="'Which statement best describes the organisation\'s asset inventory?'"
      [items]="assetInventoryAnswers"
      [id]="'assetInventory'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="assetInventory"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="pettyCashAnswers && pettyCashAnswers.length"
      [label]="'How much is the organisation’s petty cash?'"
      [items]="pettyCashAnswers"
      [id]="'pettyCashAmount'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="pettyCashAmount"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="timeSheetAnswers && timeSheetAnswers.length"
      [label]="'Are timesheets kept for each paid team member?'"
      [items]="timeSheetAnswers"
      [id]="'timeSheet'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="timeSheet"
    ></app-input-select-single>


    <app-input-select-single
      *ngIf="accountingSystemAnswers && accountingSystemAnswers.length"
      [items]="accountingSystemAnswers"
      [label]="'What is the organisation’s financial accounting system?'"
      [id]="'financialAccountingSystem'"
      [theme]="theme"
      [style]="style"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      (selectedItem)="checkOther(
        'OTHER',
        $event,
        financialManagementForm.controls.financialAccountingSystem,
        financialManagementForm.controls.financialAccountingSystemOther
      )"
      formControlName="financialAccountingSystem"
    ></app-input-select-single>

    <div *ngIf="financialManagementForm.value.financialAccountingSystem === 'OTHER'" class="ml-5">
      <app-input-text
        [label]="'If Other (please specify)'"
        [id]="'financialAccountingSystemOther'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="financialAccountingSystemOther"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="financialTrackingAnswers && financialTrackingAnswers.length"
      [label]="'Which statement best describes how the organisation tracks income and expenditure for specific funders and/or projects?'"
      [items]="financialTrackingAnswers"
      [id]="'financialTracking'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="financialTracking"
    ></app-input-select-single>


    <app-input-checkbox
      *ngIf="financialControlAnswers && financialControlAnswers.length"
      [choices]="financialControlAnswers"
      [label]="'The organisation has the following financial controls: (check all that apply)'"
      [id]="'financialControls'"
      [name]="'financialControls'"
      [theme]="theme"
      [style]="style"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      (selected)="checkOther(
        'OTHER',
        $event,
        financialManagementForm.controls.financialControls,
        financialManagementForm.controls.financialControlsOther
      )"
      formControlName="financialControls"
    ></app-input-checkbox>
    <div *ngIf="financialManagementForm.value.financialControls?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If Other (please specify)'"
        [id]="'financialControlsOther'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="financialControlsOther"
      ></app-input-text>
    </div>


    <app-input-select-single
      [label]="'Is the organisation VAT registered, meaning the organisation claims VAT back?'"
      [items]="booleanChoices"
      [id]="'isVatRegistered'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        true,
        $event,
        financialManagementForm.controls.isVatRegistered,
        financialManagementForm.controls.vatRegistrationNumber
      )"
      formControlName="isVatRegistered"
    ></app-input-select-single>
    <div *ngIf="financialManagementForm.value.isVatRegistered === true" class="ml-5">
      <app-input-text
        [label]="'If yes: VAT registration number'"
        [id]="'vatRegistrationNumber'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="vatRegistrationNumber"
      ></app-input-text>
    </div>


    <app-input-number
      [label]="'How many bank accounts does the organisation have?'"
      [id]="'numberOfBankAccounts'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="numberOfBankAccounts"
    ></app-input-number>

    <app-input-select-single
      *ngIf="paymentMethodAnswers && paymentMethodAnswers.length"
      [label]="'How does the organisation pay for most transactions?'"
      [items]="paymentMethodAnswers"
      [id]="'paymentMethod'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="paymentMethod"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="durationOfFinancialRecordsAnswers && durationOfFinancialRecordsAnswers.length"
      [label]="'How long does the organisation keep financial records?'"
      [items]="durationOfFinancialRecordsAnswers"
      [id]="'durationOfFinancialRecords'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="durationOfFinancialRecords"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="annualAuditAnswers && annualAuditAnswers.length"
      [label]="'Does the organisation have an annual financial audit?'"
      [items]="annualAuditAnswers"
      [id]="'hasAnnualAudit'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hasAnnualAudit"
    ></app-input-select-single>


    <app-input-select-single
      [label]="'Has the organisation been audited?'"
      [items]="booleanChoices"
      [id]="'hasBeenAudited'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        true,
        $event,
        financialManagementForm.controls.hasBeenAudited,
        financialManagementForm.controls.auditDate
      )"
      formControlName="hasBeenAudited"
    ></app-input-select-single>
    <div *ngIf="financialManagementForm.value.hasBeenAudited === true" class="ml-5">
      <app-input-date
        [label]="'What is the date of the organisation\'s most recent audit?'"
        [id]="'auditDate'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="auditDate"
      ></app-input-date>
    </div>


    <app-input-select-single
      [label]="'Has the organisation ever received a qualified audit, meaning the audit determined that the organisation\'s finances were not in order?'"
      [items]="booleanChoices"
      [id]="'hasQualifiedAudit'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther(
        true,
        $event,
        financialManagementForm.controls.hasQualifiedAudit,
        financialManagementForm.controls.qualifiedAuditDetails
      )"
      formControlName="hasQualifiedAudit"
    ></app-input-select-single>
    <div *ngIf="financialManagementForm.value.hasQualifiedAudit === true" class="ml-5">
      <app-input-text
        [label]="'If yes, please explain'"
        [id]="'qualifiedAuditDetails'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="qualifiedAuditDetails"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="annualFinancialSystemAnswers && annualFinancialSystemAnswers.length"
      [label]="'Does the organisation have annual financial statements?'"
      [items]="annualFinancialSystemAnswers"
      [id]="'hasAnnualFinancialSystem'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hasAnnualFinancialSystem"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="financialManagementTeamAnswers && financialManagementTeamAnswers.length"
      [label]="'Which statement best describes the functionality of the financial management team?'"
      [items]="financialManagementTeamAnswers"
      [id]="'financialManagementTeam'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="financialManagementTeam"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="headOfFinanceQualificationAnswers && headOfFinanceQualificationAnswers.length"
      [label]="'What level of training/qualification does the head of the financial department have?'"
      [items]="headOfFinanceQualificationAnswers"
      [id]="'headOfFinanceQualification'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="headOfFinanceQualification"
    ></app-input-select-single>

  </form>
</app-spinner>
