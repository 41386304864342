import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  createWorkPlanCommentHistory,
  createWorkPlanCommentHistoryForm,
  WorkPlanMessageActionType,
  MessageUserType, WorkPlanCommentHistory,
} from '../../../../../store/work-plan-and-targets.model';
import {createGrantWorkflow, WorkPlanStatusType} from '../../../../../../grant-workflow/store/grant-workflow.model';
import {SessionQuery} from '../../../../../../../shared/stores/session/session.query';
import {FormBuilder, FormGroup} from '@angular/forms';
import {take} from 'rxjs/operators';
import {GrantWorkflowService} from '../../../../../../grant-workflow/store/grant-workflow.service';
import {LoadingService} from '../../../../../../../shared/modules/shared-common/services/loading/loading.service';
import {Observable} from 'rxjs';
import {NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-work-plan-message-modal',
  templateUrl: './work-plan-message-modal.component.html',
  styleUrls: ['./work-plan-message-modal.component.scss']
})
export class WorkPlanMessageModalComponent implements OnInit {

  @Input() action: WorkPlanMessageActionType;
  @Input() status: WorkPlanStatusType;
  @Input() workflowId: number;

  options: NgbModalOptions;
  profileForm: FormGroup;
  isLoading: Observable<boolean>;

  constructor(public activeModal: NgbActiveModal,
              private sessionQuery: SessionQuery,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private workflowService: GrantWorkflowService,
              private loadingService: LoadingService) {
  }

  ngOnInit() {
    this.isLoading = this.loadingService.getIsLoading();
    this.options = {
      ariaLabelledBy: 'Message',
      centered: true
    };
    const username = this.sessionQuery.getUser().username;

    const comment = createWorkPlanCommentHistory({
      workflow: createGrantWorkflow({id: this.workflowId}),
      username,
      userType: this.getUserType(),
      date: Date.now(),
      action: this.action,
      message: ''
    });

    this.profileForm = createWorkPlanCommentHistoryForm(this.formBuilder, comment);
  }

  private getUserType(): MessageUserType {
    const roles = this.sessionQuery.getRoles();

    if (roles.includes('ROLE_ADMIN')) {
      return MessageUserType.ADMIN;
    } else if (roles.includes('ROLE_FUNDER')) {
      return MessageUserType.FUNDER;
    } else if (roles.includes('ROLE_GRANT_MANAGER')) {
      return MessageUserType.MANAGER;
    } else if (roles.includes('ROLE_GRANT_COORDINATOR')) {
      return MessageUserType.COORDINATOR;
    } else if (roles.includes('ROLE_APPLICANT')) {
      return MessageUserType.APPLICANT;
    }
  }

  closeModal(reason?: string) {
    this.activeModal.close(reason);
  }

  update(form: FormGroup) {
    const value: WorkPlanCommentHistory = form.value;

    if (form.valid) {
      this.loadingService.setIsLoading(true);
      this.workflowService.updateWorkPlanStatus(this.workflowId, this.status, value)
        .pipe(take(1)).subscribe(() => {
        this.loadingService.setIsLoading(false);
        this.closeModal('saved');
      }, error => {
        this.alertService.setAlert(
          (error && error.error && error.error.message) || 'Failed to update work plan status',
          'error',
          'wpMessageModal'
          );
      });
    } else {
      this.alertService.setAlert('Message is required.', 'error', 'wpMessageModal');
    }
  }
}
