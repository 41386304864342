import {Pipe, PipeTransform} from '@angular/core';
import {profileCategoryAnswers} from '../../../../../modules/constants/profile-category-answers.const';

@Pipe({
  name: 'categories'
})
export class CategoriesPipe implements PipeTransform {

  profileCategoryAnswers = profileCategoryAnswers;

  transform(values: string[]): string[] {
    return values.map(val => {
      return this.getCategoryByValue(val);
    });
  }

  getCategoryByValue(code: string): string {
    const found = this.profileCategoryAnswers.find(item => item.value === code);
    if (found) {
      return found.label;
    }

    return 'Unknown';
  }

}
