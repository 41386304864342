import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AdditionalDocuments, createAdditionalDocument} from '../../store/review.model';
import {FileUpload} from '../../../../shared/modules/file-upload/models/file-upload.model';

@Component({
  selector: 'app-additional-documents',
  templateUrl: './additional-documents.component.html',
  styleUrls: ['./additional-documents.component.scss']
})
export class AdditionalDocumentsComponent implements OnInit {

  @Input() additionalDocumentsForm: FormGroup;
  @Input() orgId: number;
  @Input() windowCode: string;

  documents: AdditionalDocuments;
  formValid: boolean;
  additionalParameters: any[];

  constructor() {
  }

  ngOnInit() {
    this.additionalParameters = [
      {key: 'grantWindowCode', value: this.windowCode},
      {key: 'orgId', value: this.orgId}
    ];

    if (this.additionalDocumentsForm && this.additionalDocumentsForm.value) {
      this.documents = createAdditionalDocument(this.additionalDocumentsForm.value);
    } else {
      this.documents = createAdditionalDocument({});
    }
  }

  updateDocumentsForm(files: FileUpload[], key: string) {
    if (files.length > 0) {
      this.additionalDocumentsForm.controls[key].patchValue(files[0].value);
    } else {
      this.additionalDocumentsForm.controls[key].patchValue('');
    }
  }

}
