import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SharedCommonModule} from './shared/modules/shared-common/shared-common.module';
import {PublicModule} from './modules/public/public.module';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {PresentationModule} from './template-files/presentation/presentation.module';
import {NucleoiconsComponent} from './template-files/components/nucleoicons/nucleoicons.component';
import {NG_ENTITY_SERVICE_CONFIG} from '@datorama/akita-ng-entity-service';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptorService} from './shared/interceptors/error-interceptor/error-interceptor.service';
import {ToastContainerModule, ToastrModule} from 'ngx-toastr';
import {TokenInterceptorService} from './shared/interceptors/token-interceptor/token-interceptor.service';
import {NotFoundComponent} from './modules/not-found/not-found.component';
// import {OrgCapacityModule} from './modules/organisation/org-capacity/org-capacity.module';
import {FormBuilderModule} from './modules/form-builder/form-builder.module';
// import {CopyOfOrgCapacityModule} from './modules/organisation/copy-of-org-capacity/copy-of-org-capacity.module';
import {WorkPlanAndTargetsModule} from './modules/work-plan-and-targets/work-plan-and-targets.module';
import {GrantReviewModule} from './modules/grant-review/grant-review.module';
import {TreeviewModule} from 'ngx-treeview';

@NgModule({
  declarations: [
    AppComponent,
    NucleoiconsComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule,
    PublicModule,
    FormBuilderModule,
    NgbModule,
    PresentationModule,
    // OrgCapacityModule,
    // CopyOfOrgCapacityModule,
    ToastrModule.forRoot({
      timeOut: 0,
      closeButton: true,
      positionClass: 'inline',
      maxOpened: 1,
      autoDismiss: true
    }),
    ToastContainerModule,
    TreeviewModule.forRoot(),
    HttpClientModule,
    WorkPlanAndTargetsModule,
    GrantReviewModule,
    environment.production ? [] : [AkitaNgDevtools.forRoot(), AkitaNgRouterStoreModule],
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {baseUrl: environment.apiUrl}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    NgbActiveModal
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
