import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const typeOfHivTestingAnswers: Array<ChoiceInputItem> = [
  {
    label: 'mobile testing',
    value: 'mpbileTesting'
  },
  {
    label: 'home-based testing',
    value: 'homeTesting'
  },
  {
    label: `voluntary counseling and testing (VCT)`,
    value: 'vct'
  },
  {
    label: `index testing`,
    value: 'indexTesting'
  },
  {
    label: 'other community based testing',
    value: 'otherTesting'
  },
  {
    label: 'Other - please specify in the comment field below',
    value: 'other'
  },
];

export const peopleTargetedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'female sex workers',
    value: 'fsw'
  },
  {
    label: 'men who have sex with men',
    value: 'msm'
  },
  {
    label: 'transgender persons',
    value: 'transgenderPersons'
  },
  {
    label: 'injecting drug users',
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: 'clients of sex workers',
    value: 'csw'
  },
  {
    label: 'military',
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
  {
    label: 'index clients',
    value: 'indexClients'
  },
  {
    label: 'none of the above, the organization provides general population testing',
    value: 'none'
  }
];

export const frequencyOfTestingAnswers: Array<ChoiceInputItem> = [
  {
    label: 'daily',
    value: 'daily'
  },
  {
    label: 'weekly',
    value: 'weekly'
  },
  {
    label: `bi-weekly`,
    value: 'biWeekly'
  },
  {
    label: `monthly`,
    value: 'monthly'
  },
  {
    label: 'bi-monthly',
    value: 'biMonthly'
  },
  {
    label: 'quarterly',
    value: 'quarterly'
  },
];

export const testKitSupplierAnswers: Array<ChoiceInputItem> = [
  {
    label: 'Department of Health',
    value: 'departmentOfHealth'
  },
  {
    label: 'PEPFAR partner',
    value: 'pepfarPartner'
  },
  {
    label: `This funding request includes test kits`,
    value: 'fundingIncludesKits'
  },
  {
    label: `Other`,
    value: 'other'
  }
];
