import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface Mail {
  recipients: any[];
  subject: string;
  message: string;
}

export function createMail(mail: Partial<Mail>) {
  return {
    recipients: mail && mail.recipients || [],
    subject: mail && mail.subject,
    message: mail && mail.message
  } as Mail;
}

export function createMailForm(formBuilder: FormBuilder, options: Partial<Mail>): FormGroup {
  return formBuilder.group({
    recipients: [options.recipients, Validators.required],
    subject: [options.subject, Validators.required],
    message: [options.message, Validators.required]
  });
}
