import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GrantWorkflowNavigator} from '../../grant-workflow-navigator';
import {GrantWorkflowService} from '../../store/grant-workflow.service';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-application-agreement',
  templateUrl: './application-agreement.component.html',
  styleUrls: ['./application-agreement.component.css']
})
export class ApplicationAgreementComponent implements OnInit {

  windowId: number;
  orgId: number;

  agreed: boolean;

  constructor(public route: ActivatedRoute,
              private grantWorkflowService: GrantWorkflowService,
              private grantWorkflowNavigator: GrantWorkflowNavigator,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.windowId = this.route.snapshot.params.windowId;
    this.orgId = this.route.snapshot.params.orgId;
  }

  submit() {
    if (this.agreed) {
      this.grantWorkflowService.createWorkflowByGrantWindowIdAndOrgId(this.windowId, this.orgId).subscribe(
        () => {
          this.grantWorkflowNavigator.routeToComponent(this.windowId, this.orgId);
        }, () => {
          this.alertService.setAlert('Failed to create Grant Workflow', 'error', 'appAgreement');
        });
    } else {
      this.alertService.setAlert('Required to agree to terms and conditions', 'error', 'appAgreement');
    }
  }
}
