import {ChoiceInputItem} from '../../../../../../../shared/modules/form-elements/models/form-elements.model';

export const peopleReachedAnswers: Array<ChoiceInputItem> = [
  {
    label: 'children, youth and adolescents',
    value: 'childrenYouthAdolescents'
  },
  {
    label: 'parents of OVC',
    value: 'parentsOvc'
  },
  {
    label: `female sex workers`,
    value: 'fsw'
  },
  {
    label: `men who have sex with men`,
    value: 'menSexWithMen'
  },
  {
    label: 'trasngender persons',
    value: 'tp'
  },
  {
    label: 'injecting drug users',
    value: 'injectingUsers'
  },
  {
    label: 'migrant workers',
    value: 'migrantWorkers'
  },
  {
    label: 'prisoners',
    value: 'prisoners'
  },
  {
    label: 'clients of sex workers',
    value: 'csw'
  },
  {
    label: 'military',
    value: 'military'
  },
  {
    label: 'non-injecting drug users',
    value: 'nonInjectingUsers'
  },
  {
    label: 'people living with HIV',
    value: 'peopleWithHiv'
  },
  {
    label: 'caregivers for PLHIV',
    value: 'caregivers'
  },
  {
    label: 'gender based violence victims',
    value: 'genderVictims'
  },
];

export const frequencyOfMeetingsAnswers: Array<ChoiceInputItem> = [
  {
    label: 'daily',
    value: 'daily'
  },
  {
    label: 'weekly',
    value: 'weekly'
  },
  {
    label: `bi-weekly`,
    value: 'biWeekly'
  },
  {
    label: `monthly`,
    value: 'monthly'
  },
  {
    label: 'bi-monthly',
    value: 'biMonthly'
  },
  {
    label: 'quarterly',
    value: 'quarterly'
  },
];
