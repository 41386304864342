import {Injectable} from '@angular/core';
import {OrganisationStore, OrganisationState} from './organisation.store';
import {NgEntityService, NgEntityServiceConfig} from '@datorama/akita-ng-entity-service';

@NgEntityServiceConfig({
  resourceName: 'organisation'
})

@Injectable({providedIn: 'root'})
export class OrganisationService extends NgEntityService<OrganisationState> {

  constructor(protected store: OrganisationStore) {
    super(store);
  }

  setActive(orgId: number) {
    this.store.setActive(orgId);
  }
}
