<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Clinical services are defined as any HIV-related medical services which are delivered
    in a clinic or health facility and are administered by a trained health professional.
  </p>
  <form [formGroup]="clinicalServicesForm">
    <fieldset [disabled]="isReadOnly">
      <app-input-checkbox
        [choices]="typeOfClinicalServicesOptions"
        [label]="'What type(s) of clinical services will you offer?'"
        [id]="'cs-typeOfClinicalServices'"
        [name]="'cs-typeOfClinicalServices'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="typeOfClinicalServices"
        (selected)="checkOther('other', $event, clinicalServicesForm.controls.typeOfClinicalServices, clinicalServicesForm.controls.typeOfClinicalServicesOther)"
      ></app-input-checkbox>

      <div *ngIf="clinicalServicesForm.value.typeOfClinicalServices?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'cs-typeOfClinicalServicesOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="typeOfClinicalServicesOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be targeted for clinical services?'"
        [id]="'cs-peopleTargeted'"
        [name]="'cs-peopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleTargeted"
      ></app-input-checkbox>

      <app-input-number
        [label]="'How many people will be targeted for clinical services?'"
        [id]="'cs-numberOfPeopleTargeted'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeopleTargeted"
      ></app-input-number>

      <app-input-checkbox
        [choices]="locationOfServiceOptions"
        [label]="'Who will be targeted for clinical services?'"
        [id]="'cs-locationOfService'"
        [name]="'cs-locationOfService'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="locationOfService"
      ></app-input-checkbox>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'cs-durationOfClinicalServices'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfClinicalServices"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'cs-durationOfClinicalServicesType'"
            [isRequired]="true"
            [name]="'cs-durationOfClinicalServicesType'"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfClinicalServicesType"
          ></app-input-radio>
        </div>
      </div>

      <div>
        <p>How much funding do you need to provide these clinical services?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="clinicalServicesForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
