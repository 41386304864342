<app-spinner [loadingObs]="isLoading">
  <form [formGroup]="mneForm">
    <app-input-select-single
      *ngIf="planAnswers?.length"
      [label]="'Which statement best describes the organisation\'s M&E plan?'"
      [items]="planAnswers"
      [id]="'plan'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="plan"
    ></app-input-select-single>

    <app-input-checkbox
      *ngIf="planElementAnswers?.length"
      [label]="'The organisation\'s M&E plan has which of the following elements: (check all that apply)'"
      [choices]="planElementAnswers"
      [id]="'planElements'"
      [name]="'planElements'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selected)="checkOther('OTHER', $event, mneForm.controls.planElements, mneForm.controls.planElementsOther)"
      formControlName="planElements"
    ></app-input-checkbox>

    <div *ngIf="mneForm?.value?.planElements?.includes('OTHER')" class="ml-5">
      <app-input-text
        [label]="'If other, please specify'"
        [id]="'planElementsOther'"
        [theme]="'light'"
        [style]="'default'"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [showOptional]="false"
        formControlName="planElementsOther"
      ></app-input-text>
    </div>


    <app-input-select-single
      *ngIf="monitorAnswers?.length"
      [label]="'Which statement best describes how the organisation monitors activities and beneficiaries? '"
      [items]="monitorAnswers"
      [id]="'monitor'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="monitor"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="programReportingAnswers?.length"
      [label]="'Which statement best describes the organisation’s program reporting?'"
      [items]="programReportingAnswers"
      [id]="'programReporting'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="programReporting"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="usageOfReportsAnswers?.length"
      [label]="'Which statement best describes how the organisation’s reports are used to inform the organisation’s planning and budget?'"
      [items]="usageOfReportsAnswers"
      [id]="'usageOfReports'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="usageOfReports"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="programBudgetAnswers?.length"
      [label]="'Does the organisation create program-specific budgets?'"
      [items]="programBudgetAnswers"
      [id]="'programBudget'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      (selectedItem)="checkOther('YES', $event, mneForm.controls.programBudget, mneForm.controls.budgetCheckFrequency)"
      formControlName="programBudget"
    ></app-input-select-single>


    <div *ngIf="mneForm?.value?.programBudget === 'YES'" class="ml-5">
      <app-input-select-single
        *ngIf="budgetCheckFrequencyAnswers?.length"
        [label]="'How often are program budget(s) checked against program activities? '"
        [items]="budgetCheckFrequencyAnswers"
        [id]="'budgetCheckFrequency'"
        [theme]="theme"
        [style]="style"
        [class]="'mt-0'"
        [formSubmitted]="formSubmitted || childFormTouched"
        [isRequired]="true"
        [isReadOnly]="isReadOnly"
        [showDisabledValue]="fromReview"
        [showOptional]="false"
        [hasBorder]="!fromReview"
        [isGrey]="!fromReview"
        formControlName="budgetCheckFrequency"
      ></app-input-select-single>
    </div>

    <app-input-select-single
      *ngIf="trainedMneAnswers?.length"
      [label]="'Does the organisation have a staff member who is trained/qualified in monitoring and evaluation?'"
      [items]="trainedMneAnswers"
      [id]="'hasTrainedMne'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="hasTrainedMne"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="feedbackProcedureAnswers?.length"
      [label]="'Does the organisation have a specific procedure for receiving feedback from beneficiaries?'"
      [items]="feedbackProcedureAnswers"
      [id]="'feedbackProcedure'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="feedbackProcedure"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="strategicPlanAnswers?.length"
      [label]="'Which statement best describes the organisation\'s strategic plan?'"
      [items]="strategicPlanAnswers"
      [id]="'strategicPlan'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="strategicPlan"
    ></app-input-select-single>

    <app-input-select-single
      *ngIf="linkedStrategicPlanAnswers?.length"
      [label]="'Is the strategic plan linked to the operational budget?'"
      [items]="linkedStrategicPlanAnswers"
      [id]="'linkedStrategicPlan'"
      [theme]="theme"
      [style]="style"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="fromReview"
      [showOptional]="false"
      [hasBorder]="!fromReview"
      [isGrey]="!fromReview"
      formControlName="linkedStrategicPlan"
    ></app-input-select-single>
  </form>
</app-spinner>
