import {Component, Input, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GrantWorkflowNavigator} from '../../grant-workflow-navigator';
import {EligibilityValidation} from './validation/eligibility-validation';
import {ModalService} from '../../../../shared/modules/shared-common/services/modal/modal.service';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {createEligibility, createEligibilityForm, Eligibility} from './store/eligibility.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {createGrantWorkflow} from '../../store/grant-workflow.model';
import {EligibilityService} from './store/eligibility.service';
import {ChoiceInputItem} from '../../../../shared/modules/form-elements/models/form-elements.model';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {take} from 'rxjs/operators';
import {ChoiceInputItemUtil} from '../../../../shared/utils/choice-input-item-util';
import {GrantWorkflowService} from '../../store/grant-workflow.service';
import {GrantWindow} from '../../../grant-window/store/grant-window.model';
import {GrantWindowService} from '../../../grant-window/store/grant-window.service';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-eligibility',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.scss']
})
export class EligibilityComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @ViewChild('saveBeforeLeaving', {static: true}) saveBeforeLeaving: TemplateRef<HTMLElement>;

  @Input() workflowId: number;
  @Input() fromGrantReview: boolean;
  @Input() isReadOnly: boolean;

  eligibility: Eligibility;
  selectedGrantWindow: GrantWindow;

  districts: ChoiceInputItem[] = [];

  toggleOptions: ChoiceInputItem[] = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ];

  warningDismissed = false;
  exitUrl: string;

  modalOptions: NgbModalOptions;

  isNotApplicant: boolean;

  loading: boolean;
  profileForm: FormGroup;

  constructor(public router: Router,
              public route: ActivatedRoute,
              private mblModal: ModalService,
              private eligibilityService: EligibilityService,
              private grantWorkflowService: GrantWorkflowService,
              private grantWindowService: GrantWindowService,
              private sessionQuery: SessionQuery,
              private grantWorkflowNavigator: GrantWorkflowNavigator,
              private formBuilder: FormBuilder,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.loading = true;

    this.workflowId = this.workflowId || this.route.snapshot.params.workflowId;

    this.selectedGrantWindow = this.grantWindowService.selectedGrantWindow;

    this.isNotApplicant = this.sessionQuery.hasRole(['ROLE_GRANT_MANAGER', 'ROLE_FUNDER', 'ROLE_ADMIN', 'ROLE_GRANT_COORDINATOR']);
    this.isReadOnly = this.isReadOnly || this.isNotApplicant;

    this.fetchDistricts();
    this.fetch();

    this.modalOptions = {
      size: 'lg',
      centered: true,
      windowClass: 'modalWidth-50'
    };
  }

  fetch() {
    this.loading = true;
    // Fetch eligibility or create new
    this.eligibilityService.getEligibilityByWorkflowId(this.workflowId).subscribe(result => {
      if (result) {
        this.eligibility = createEligibility(result);
        this.profileForm = createEligibilityForm(this.formBuilder, this.eligibility);
        this.loading = false;
      } else {
        const grantWorkflow = createGrantWorkflow({id: this.workflowId});
        this.eligibility = createEligibility({
          grantWorkflow,
          status: 'NEW'
        });
        this.profileForm = createEligibilityForm(this.formBuilder, this.eligibility);
        this.loading = false;
      }
    }, () => {
      this.alertService.setAlert('Unable to fetch Eligibility data', 'error', 'eligibility');
    });
  }

  save(isSubmitting?: boolean) {
    this.loading = true;

    if (isSubmitting && !EligibilityValidation.isValid(this.profileForm.value)) {
      this.alertService.setAlert(
        'Unable to submit Eligibility form.\n Check to see that all fields are correct.',
        'error',
        'eligibility'
      );
      this.loading = false;
      return;
    }
    // create or update.
    this.eligibility.id ? this.update(isSubmitting) : this.create(isSubmitting);
  }

  create(isSubmitting?: boolean) {
    this.eligibilityService.createEligibilityByWorkflowId(this.profileForm.value, this.workflowId, isSubmitting).subscribe(() => {
      if (isSubmitting) {
        this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
      } else {
        this.fetch();
        this.alertService.setAlert('Success: Saved Eligibility', 'success', 'eligibility', {
          timeOut: 5000
        });
      }
    }, (response) => {
      if (isSubmitting) {
        this.loading = false;
        this.alertService.setAlert('Unable to post submit Eligibility:\n' + response.error.message, 'error', 'eligibility');
      } else {
        this.loading = false;
        this.alertService.setAlert('Unable to create Eligibility:\n' + response.error.message, 'error', 'eligibility');
      }
    });
  }

  update(isSubmitting?: boolean) {
    this.eligibilityService.updateEligibility(this.profileForm.value, isSubmitting).subscribe(() => {
      if (isSubmitting) {
        this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
      } else {
        this.fetch();
        this.alertService.setAlert('Success: Saved Eligibility', 'success', 'eligibility',  {
          timeOut: 5000
        });
      }
    }, (response) => {
      if (isSubmitting) {
        this.loading = false;
        this.alertService.setAlert('Unable to put submit Eligibility:\n' + response.error.message, 'error', 'eligibility');
      } else {
        this.loading = false;
        this.alertService.setAlert('Unable to update Eligibility:\n' + response.error.message, 'error', 'eligibility');
      }
    });
  }

  // This function is NOT unused.
  // It's being called from the `canDeactivateApplication` guard in `shared/guards`.
  // noinspection JSUnusedGlobalSymbols
  openSaveModal() {
    if (!this.warningDismissed) {
      this.mblModal.openBasicModal(this.saveBeforeLeaving, this.modalOptions);
    }
  }

  leaveApplication() {
    this.warningDismissed = true;
    this.closeModal();
    this.router.navigate([this.exitUrl]).then();
  }

  closeModal() {
    this.mblModal.closeModal();
  }

  private fetchDistricts() {
    this.grantWorkflowService.getDistrictsByWorkflowId(this.workflowId).pipe(take(1)).subscribe(districts => {
      if (districts && districts.length) {
        const districtList = ChoiceInputItemUtil.convertToChoiceInputItem(districts);
        districtList.push({
          label: 'None of the above',
          value: 'none'
        });

        this.districts = districtList;
      }
    }, error => {
      this.alertService.setAlert('Unable to get districts', 'error', 'eligibility');
    });
  }
}
