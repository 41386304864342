<div class="wrapper">
  <div class="page-header clear-filter">
    <div class="page-header-image">
    </div>
    <div class="w-100 flex-center flex-column">
<!--      <img-->
<!--        class="primary-logo"-->
<!--        alt=""-->
<!--        src="../../../../../../assets/img/solinta/SoCapX_Logo_CMYK_CMYK.jpg"-->
<!--        width="600"-->
<!--      >-->
      <img
        class="primary-logo"
        alt=""
        src="../../../../../../assets/img/solinta/SoCapX%20Logo_Vector(White_FillAndOutline).png"
        width="600"
      >
    </div>
  </div>
  <div class="p-4 bg-dark" #welcome>
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h2 class="text-light">
            SoCap<span class="text-primary">X</span>
          </h2>
          <h5 class="text-light">
            Welcome to the SoCapX Platform.
          </h5>
          <p class="text-light">
            The Social Capital Exchange is a value driven, social enterprise platform
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="testimonials-1 section-image">
    <div class="section-content">
      <div class="ml-auto mr-auto text-center w-100">
        <div class="flex-around">
          <div class="card card-blog m-4">
            <div class="card-body h-100">
              <h4 class="category text-primary">Implementer / Applicant</h4>
              <div class="flex-around">
                <button class="btn btn-lg btn-info" routerLink="/authentication/login">Login</button>
                <button class="btn btn-lg btn-info" routerLink="/authentication/register">Register</button>
              </div>
            </div>
          </div>
          <div class="card card-blog m-4">
            <div class="card-body h-100">
              <h4 class="category text-primary">Investor / Funder</h4>
              <div class="flex-center">
                <button class="btn btn-lg btn-info" routerLink="/contact-us">Contact us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
