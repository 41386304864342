<ng-container *ngIf="funderForm">
  <form [formGroup]="funderForm">
    <app-input-text
      [label]="'Funder Name'"
      [id]="'funderName'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [showOptional]="false"
      formControlName="name"
    ></app-input-text>

    <app-input-text
      [label]="'Period of funding'"
      [id]="'funderPeriod'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [showOptional]="false"
      formControlName="period"
    ></app-input-text>

    <app-input-number
      [label]="'Amount of funding'"
      [id]="'funderAmount'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [showOptional]="false"
      [stringPrepend]="'R'"
      formControlName="amount"
    ></app-input-number>

    <app-input-text
      [label]="'Primary contact name'"
      [id]="'contactName'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [showOptional]="false"
      formControlName="contactName"
    ></app-input-text>

    <app-input-text
      [label]="'Primary contact email'"
      [type]="'email'"
      [id]="'contactEmail'"
      [theme]="'light'"
      [style]="'default'"
      [formSubmitted]="formSubmitted || childFormTouched"
      [isRequired]="true"
      [showOptional]="false"
      formControlName="contactEmail"
    ></app-input-text>
  </form>
</ng-container>
