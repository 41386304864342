import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, Validators} from '@angular/forms';
import {removeUnderscore} from '../../shared/utils/form-validation.util';
import {CommunityBasedCare, HivPrevention} from '../../modules/organisation/org-capacity/store/org-capacity.model';
import {NullUtil} from '../../shared/utils/null-util';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor() {
  }

  private static hasChildAndYouth(array: string[]) {
    return array.includes('childrenYouthAdolescents');
  }

  static hasChildrenAndYouth(programsProvided: string[], hivPrevention: HivPrevention, communityBasedCare: CommunityBasedCare): boolean {
    let hasHivPrevention;
    let hasCommunityBasedCare;

    if (programsProvided) {
      if (programsProvided.includes('hivPrevention')) {
        hasHivPrevention = FormValidationService.hasChildAndYouth(hivPrevention.targetedPopulations);
      }

      if (programsProvided.includes('communityBasedCareTreatmentSupport')) {
        hasCommunityBasedCare = FormValidationService.hasChildAndYouth(communityBasedCare.targetedPopulations);
      }
    }

    return hasHivPrevention && hasCommunityBasedCare;
  }

  private static setErrors(control: AbstractControl) {
    if (typeof control.value === 'string') {
      if (NullUtil.isEmpty(control.value)) {
        control.setErrors(Validators.required);
      }
    } else {
      if (NullUtil.isEmptyAny(control.value)) {
        control.setErrors(Validators.required);
      }
    }
  }

  setFieldValidations(form: FormGroup,
                      formControlName: string,
                      formControlOther?: string,
                      formControlsAffected?: string[],
                      matchedValue?: string | boolean) {
    const value = form.value[formControlName];
    let controlsAffected = [];

    if (formControlOther) {
      this.setValidations(value, form.controls[formControlOther], matchedValue || 'other');
    }

    if (formControlsAffected) {
      controlsAffected = formControlsAffected.map(control => {
        return form.controls[control];
      });
      this.setMultipleValidations(value, controlsAffected, matchedValue);
    }
  }

  setValidations(value: string | any[] | boolean,
                 control: AbstractControl,
                 matchedValue?: any,
                 newValue?: any) {
    if (typeof value === 'string') {
      this.setStringValidation(value, control, matchedValue, newValue);
    } else if (typeof value === 'boolean') {
      this.setBooleanValidations(value, control, matchedValue, newValue);
    } else if (Array.isArray(value)) {
      this.setArrayValidation(value, control, matchedValue, newValue);
    }
  }

  setStringValidation(value: string,
                      control: AbstractControl,
                      matchedValue: string,
                      newValue?: any) {
    const val = value.toLowerCase();
    if (val === matchedValue) {
      control.setValidators(Validators.required);
      FormValidationService.setErrors(control);
    } else {
      control.clearValidators();
      control.setErrors(null);
      // control.patchValue(newValue || '');
    }
  }

  setArrayValidation(value: any[],
                     control: AbstractControl,
                     matchedValue: any,
                     newValue?: any) {
    if (value.includes(matchedValue)) {
      control.setValidators(Validators.required);
      FormValidationService.setErrors(control);
      // control.patchValue(newValue || '');
    } else {
      control.clearValidators();
      control.setErrors(null);
      // control.patchValue(newValue || '');
    }
  }

  setBooleanValidations(value: boolean,
                        control: AbstractControl,
                        matchedValue: boolean,
                        newValue?: any) {
    if (value === matchedValue) {
      control.setValidators(Validators.required);
      FormValidationService.setErrors(control);
    } else {
      control.clearValidators();
      control.setErrors(null);
      control.patchValue(newValue || '');
    }
  }

  setMultipleValidations(value: string | boolean,
                         controls: AbstractControl[],
                         matchedValue: string | boolean,
                         newValue?: any) {
    if (controls) {
      controls.forEach(control => {
        if (((typeof value === 'string' || typeof value === 'boolean') && value === matchedValue)) {
          control.setValidators(Validators.required);
          FormValidationService.setErrors(control);
        } else if (Array.isArray(value) && value.includes(matchedValue)) {
          control.setValidators(Validators.required);
          FormValidationService.setErrors(control);
        } else {
          control.clearValidators();
          control.setErrors(null);
          // control.patchValue(newValue || '');
        }
      });
    }
  }

  setMultipleArrayValidations(values: string[],
                              controls: AbstractControl[],
                              matchedValue: string,
                              newValue?: string) {
    if (values) {
      values = values.map(value => removeUnderscore(value.toLowerCase()));
    }
    controls.forEach(control => {
      if (values.includes(matchedValue)) {
        control.setValidators(Validators.required);
        FormValidationService.setErrors(control);
      } else {
        control.clearValidators();
        control.setErrors(null);
        // control.patchValue(newValue || '');
      }
    });
  }
}
