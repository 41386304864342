import {BaseEntity, createBaseEntity} from '../../../../../../shared/models/base-entity.model';

export enum FundingRequestType {
  TRAINING = 'TRAINING',
  SUPPORT_GROUPS = 'SUPPORT_GROUPS',
  ADHERENCE_CLUBS = 'ADHERENCE_CLUBS',
  HIV_TESTING = 'HIV_TESTING',
  HOME_BASED_CARE = 'HOME_BASED_CARE',
  COUNSELING = 'COUNSELING',
  COMMUNITY_MOBILIZATION = 'COMMUNITY_MOBILIZATION',
  CLINICAL_SERVICES = 'CLINICAL_SERVICES',
  CAMPS = 'CAMPS',
  TARGETED_PREVENTION = 'TARGETED_PREVENTION',
  SYSTEM_DEVELOPMENT = 'SYSTEM_DEVELOPMENT',
  OTHER = 'OTHER',
}

export interface FundingRequest extends BaseEntity {
  projectDescription: string;
  additionalProjectExplanation?: string;
  projectAlignmentWithPepfarsMission: string;
  monthsToComplete: string;
  projectImplementationRisks: string;
  planToReduceRisks: string;
  requestedFunding: Array<FundingRequestType>;
  requestedFundingOther: string;
  training: Training;
  supportGroups: SupportGroups;
  adherenceClubs: AdherenceClubs;
  hivTesting: HivTesting;
  homeBasedCare: HomeBasedCare;
  counseling: Counseling;
  communityMobilization: CommunityMobilization;
  clinicalServices: ClinicalServices;
  camps: Camps;
  targetedPrevention: TargetedPrevention;
  systemDevelopment: SystemDevelopment;
  otherFundingRequest: OtherFundingRequest;
}

export function createFundingRequest(params: Partial<FundingRequest>) {
  return Object.assign({}, createBaseEntity(params), {
    projectDescription: params && params.projectDescription,
    additionalProjectExplanation: params && params.additionalProjectExplanation,
    projectAlignmentWithPepfarsMission: params && params.projectAlignmentWithPepfarsMission,
    monthsToComplete: params && params.monthsToComplete,
    projectImplementationRisks: params && params.projectImplementationRisks,
    planToReduceRisks: params && params.planToReduceRisks,
    requestedFunding: params && params.requestedFunding || [],
    requestedFundingOther: params && params.requestedFundingOther,
    training: params && createTraining(params.training),
    supportGroups: params && createSupportGroups(params.supportGroups),
    adherenceClubs: params && createAdherenceClubs(params.adherenceClubs),
    hivTesting: params && createHivTesting(params.hivTesting),
    homeBasedCare: params && createHomeBasedCare(params.homeBasedCare),
    counseling: params && createCounseling(params.counseling),
    communityMobilization: params && createCommunityMobilization(params.communityMobilization),
    clinicalServices: params && createClinicalServices(params.clinicalServices),
    camps: params && createCamps(params.camps),
    targetedPrevention: params && createTargetedPrevention(params.targetedPrevention),
    systemDevelopment: params && createSystemDevelopment(params.systemDevelopment),
    otherFundingRequest: params && createOtherFundingRequest(params.otherFundingRequest),
  }) as FundingRequest;
}

export interface FundingRequestBudget extends BaseEntity {
  training: number;
  suppliesAndMaterials: number;
  equipmentAndFurniture: number;
  personnel: number;
  travelOrTransport: number;
  administration: number;
}

export function createFundingRequestBudget(params: Partial<FundingRequestBudget>) {
  return Object.assign({}, createBaseEntity(params), {
    training: params && params.training,
    suppliesAndMaterials: params && params.suppliesAndMaterials,
    equipmentAndFurniture: params && params.equipmentAndFurniture,
    personnel: params && params.personnel,
    travelOrTransport: params && params.travelOrTransport,
    administration: params && params.administration,
  }) as FundingRequestBudget;
}

export class FundingRequestAnswer {
  label: string;
  value: FundingRequestType;
  model?: string;
  category?: string;
}

export interface Training extends BaseEntity {
  typeOfTraining: Array<string>;
  typeOfTrainingOther: string;
  numberOfPeopleTrained: number;
  trainingDuration: number;
  trainingDurationType: string;
  budget: FundingRequestBudget;
}

export function createTraining(params: Partial<Training>) {
  return Object.assign({}, createBaseEntity(params), {
    typeOfTraining: params && params.typeOfTraining || [],
    typeOfTrainingOther: params && params.typeOfTrainingOther,
    numberOfPeopleTrained: params && params.numberOfPeopleTrained,
    trainingDuration: params && params.trainingDuration,
    trainingDurationType: params && params.trainingDurationType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as Training;
}

export interface SupportGroups extends BaseEntity {
  peopleReached: Array<string>;
  numberOfSupportGroups: number;
  numberOfPeoplePerGroup: number;
  frequencyOfMeetings: string;
  durationOfMeeting: number;
  durationOfMeetingType: string;
  budget: FundingRequestBudget;
}

export function createSupportGroups(params: Partial<SupportGroups>) {
  return Object.assign({}, createBaseEntity(params), {
    peopleReached: params && params.peopleReached,
    numberOfSupportGroups: params && params.numberOfSupportGroups,
    numberOfPeoplePerGroup: params && params.numberOfPeoplePerGroup,
    frequencyOfMeetings: params && params.frequencyOfMeetings,
    durationOfMeeting: params && params.durationOfMeeting,
    durationOfMeetingType: params && params.durationOfMeetingType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as SupportGroups;
}

export interface AdherenceClubs extends BaseEntity {
  typeOfAdherenceClub: Array<string>;
  typeOfAdherenceClubOther: string;
  peopleReached: Array<string>;
  numberOfAdherenceClubs: number;
  numberOfPeoplePerClub: number;
  frequencyOfMeetings: string;
  durationOfMeeting: number;
  durationOfMeetingType: string;
  budget: FundingRequestBudget;
}

export function createAdherenceClubs(params: Partial<AdherenceClubs>) {
  return Object.assign({}, createBaseEntity(params), {
    typeOfAdherenceClub: params && params.typeOfAdherenceClub,
    typeOfAdherenceClubOther: params && params.typeOfAdherenceClubOther,
    peopleReached: params && params.peopleReached,
    numberOfAdherenceClubs: params && params.numberOfAdherenceClubs,
    numberOfPeoplePerClub: params && params.numberOfPeoplePerClub,
    frequencyOfMeetings: params && params.frequencyOfMeetings,
    durationOfMeeting: params && params.durationOfMeeting,
    durationOfMeetingType: params && params.durationOfMeetingType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as AdherenceClubs;
}

export interface HivTesting extends BaseEntity {
  typeOfHivTesting: Array<string>;
  typeOfHivTestingOther: string;
  peopleTargeted: Array<string>;
  numberOfPeopleTargeted: number;
  frequencyOfTesting: string;
  durationOfTesting: number;
  durationOfTestingType: string;
  testKitSupplier: Array<string>;
  testKitSupplierOther: string;
  budget: FundingRequestBudget;
}

export function createHivTesting(params: Partial<HivTesting>) {
  return Object.assign({}, createBaseEntity(params), {
    typeOfHivTesting: params && params.typeOfHivTesting,
    typeOfHivTestingOther: params && params.typeOfHivTestingOther,
    peopleTargeted: params && params.peopleTargeted,
    numberOfPeopleTargeted: params && params.numberOfPeopleTargeted,
    frequencyOfTesting: params && params.frequencyOfTesting,
    durationOfTesting: params && params.durationOfTesting,
    durationOfTestingType: params && params.durationOfTestingType,
    testKitSupplier: params && params.testKitSupplier,
    testKitSupplierOther: params && params.testKitSupplierOther,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as HivTesting;
}

export interface HomeBasedCare extends BaseEntity {
  peopleTargeted: Array<string>;
  numberOfPeopleReached: number;
  numberOfCaregivers: number;
  frequencyOfVisits: string;
  budget: FundingRequestBudget;
}

export function createHomeBasedCare(params: Partial<HomeBasedCare>) {
  return Object.assign({}, createBaseEntity(params), {
    peopleTargeted: params && params.peopleTargeted,
    numberOfPeopleReached: params && params.numberOfPeopleReached,
    numberOfCaregivers: params && params.numberOfCaregivers,
    frequencyOfVisits: params && params.frequencyOfVisits,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as HomeBasedCare;
}

export interface Counseling extends BaseEntity {
  peopleTargeted: Array<string>;
  numberOfPeopleTargeted: number;
  numberOfSessions: number;
  durationOfCounseling: number;
  durationOfCounselingType: string;
  counselingServicesProvider: string;
  counselingServicesProviderOther: string;
  budget: FundingRequestBudget;
}

export function createCounseling(params: Partial<Counseling>) {
  return Object.assign({}, createBaseEntity(params), {
    peopleTargeted: params && params.peopleTargeted,
    numberOfPeopleTargeted: params && params.numberOfPeopleTargeted,
    numberOfSessions: params && params.numberOfSessions,
    durationOfCounseling: params && params.durationOfCounseling,
    durationOfCounselingType: params && params.durationOfCounselingType,
    counselingServicesProvider: params && params.counselingServicesProvider,
    counselingServicesProviderOther: params && params.counselingServicesProviderOther,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as Counseling;
}

export interface CommunityMobilization extends BaseEntity {
  typesOfCommunityMobilization: Array<string>;
  typesOfCommunityMobilizationOther: string;
  locationConducted: Array<string>;
  locationConductedOther: string;
  focusedTopics: Array<string>;
  focusedTopicsOther: string;
  peopleTargeted: Array<string>;
  peopleTargetedOther: string;
  frequencyOfCommunityMobilization: string;
  numberOfPeopleTargeted: number;
  durationOfCommunityMobilization: number;
  durationOfCommunityMobilizationType: string;
  budget: FundingRequestBudget;
}

export function createCommunityMobilization(params: Partial<CommunityMobilization>) {
  return Object.assign({}, createBaseEntity(params), {
    typesOfCommunityMobilization: params && params.typesOfCommunityMobilization,
    typesOfCommunityMobilizationOther: params && params.typesOfCommunityMobilizationOther,
    locationConducted: params && params.locationConducted,
    locationConductedOther: params && params.locationConductedOther,
    focusedTopics: params && params.focusedTopics,
    focusedTopicsOther: params && params.focusedTopicsOther,
    peopleTargeted: params && params.peopleTargeted,
    peopleTargetedOther: params && params.peopleTargetedOther,
    frequencyOfCommunityMobilization: params && params.frequencyOfCommunityMobilization,
    numberOfPeopleTargeted: params && params.numberOfPeopleTargeted,
    durationOfCommunityMobilization: params && params.durationOfCommunityMobilization,
    durationOfCommunityMobilizationType: params && params.durationOfCommunityMobilizationType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as CommunityMobilization;
}

export interface ClinicalServices extends BaseEntity {
  typeOfClinicalServices: Array<string>;
  typeOfClinicalServicesOther: string;
  peopleTargeted: Array<string>;
  numberOfPeopleTargeted: number;
  locationOfService: Array<string>;
  durationOfClinicalServices: number;
  durationOfClinicalServicesType: string;
  budget: FundingRequestBudget;
}

export function createClinicalServices(params: Partial<ClinicalServices>) {
  return Object.assign({}, createBaseEntity(params), {
    typeOfClinicalServices: params && params.typeOfClinicalServices,
    typeOfClinicalServicesOther: params && params.typeOfClinicalServicesOther,
    peopleTargeted: params && params.peopleTargeted,
    numberOfPeopleTargeted: params && params.numberOfPeopleTargeted,
    locationOfService: params && params.locationOfService,
    durationOfClinicalServices: params && params.durationOfClinicalServices,
    durationOfClinicalServicesType: params && params.durationOfClinicalServicesType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as ClinicalServices;
}

export interface Camps extends BaseEntity {
  typeOfCamps: Array<string>;
  typeOfCampsOther: string;
  peopleTargeted: Array<string>;
  numberOfCamps: number;
  numberOfChildrenOrYouth: number;
  numberOfDays: number;
  topicsDiscussed: Array<string>;
  topicsDiscussedOther: string;
  budget: FundingRequestBudget;
}

export function createCamps(params: Partial<Camps>) {
  return Object.assign({}, createBaseEntity(params), {
    typeOfCamps: params && params.typeOfCamps,
    typeOfCampsOther: params && params.typeOfCampsOther,
    peopleTargeted: params && params.peopleTargeted,
    numberOfCamps: params && params.numberOfCamps,
    numberOfChildrenOrYouth: params && params.numberOfChildrenOrYouth,
    numberOfDays: params && params.numberOfDays,
    topicsDiscussed: params && params.topicsDiscussed,
    topicsDiscussedOther: params && params.topicsDiscussedOther,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as Camps;
}

export interface TargetedPrevention extends BaseEntity {
  programs: Array<string>;
  programsOther: string;
  peopleReached: Array<string>;
  numberOfPeople: number;
  numberOfPeoplePerGroup: number;
  numberOfSessionsPerProgram: number;
  durationOfTargetedPrevention: number;
  durationOfTargetedPreventionType: string;
  budget: FundingRequestBudget;
}

export function createTargetedPrevention(params: Partial<TargetedPrevention>) {
  return Object.assign({}, createBaseEntity(params), {
    programs: params && params.programs,
    programsOther: params && params.programsOther,
    peopleReached: params && params.peopleReached,
    numberOfPeople: params && params.numberOfPeople,
    numberOfPeoplePerGroup: params && params.numberOfPeoplePerGroup,
    numberOfSessionsPerProgram: params && params.numberOfSessionsPerProgram,
    durationOfTargetedPrevention: params && params.durationOfTargetedPrevention,
    durationOfTargetedPreventionType: params && params.durationOfTargetedPreventionType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as TargetedPrevention;
}

export interface SystemDevelopment extends BaseEntity {
  targetedPopulation: Array<string>;
  aspectBenefited: Array<string>;
  aspectBenefitedOther: string;
  durationOfSystemDevelopment: number;
  durationOfSystemDevelopmentType: string;
  budget: FundingRequestBudget;
}

export function createSystemDevelopment(params: Partial<SystemDevelopment>) {
  return Object.assign({}, createBaseEntity(params), {
    targetedPopulation: params && params.targetedPopulation,
    aspectBenefited: params && params.aspectBenefited,
    aspectBenefitedOther: params && params.aspectBenefitedOther,
    durationOfSystemDevelopment: params && params.durationOfSystemDevelopment,
    durationOfSystemDevelopmentType: params && params.durationOfSystemDevelopmentType,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as SystemDevelopment;
}

export interface OtherFundingRequest extends BaseEntity {
  peopleTargeted: Array<string>;
  budget: FundingRequestBudget;
}

export function createOtherFundingRequest(params: Partial<OtherFundingRequest>) {
  return Object.assign({}, createBaseEntity(params), {
    peopleTargeted: params && params.peopleTargeted,
    budget: params && createFundingRequestBudget(params.budget) || createFundingRequestBudget({}),
  }) as OtherFundingRequest;
}
