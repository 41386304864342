import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WorkPlan} from '../../../../../store/work-plan-and-targets.model';
import {WorkPlanAndTargetsQuery} from '../../../../../store/work-plan-and-targets.query';
import {WorkPlanAndTargetsService} from '../../../../../store/work-plan-and-targets.service';
import {Objective} from './store/objective.model';
import {Subscription} from 'rxjs';
import {ObjectiveService} from './store/objective.service';
import {ActivityService} from './activity/store/activity.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('expanded', style({height: '*', visibility: 'visible'})),
      state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ObjectiveComponent implements OnInit, OnDestroy {

  @Input() isReadOnly: boolean;
  @Input() applicantReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;
  workPlanDisabled: boolean; // TODO this.ROLE_COORDINATOR && this.workPlanStatus !== 'ACTIVE'

  selectedWorkPlan: WorkPlan;
  selectedObjective: Objective;

  editableForApplicant: boolean;
  objectiveEditable: boolean;
  selectedObjectiveId: number;
  workPlanSubscription: Subscription;

  dataSource: ObjectiveElement[] = [];
  columnsToDisplay = ['name', 'description', 'budget', 'actions'];
  expandedElement: ObjectiveElement;

  constructor(public route: ActivatedRoute,
              public router: Router,
              private workPlanAndTargetsQuery: WorkPlanAndTargetsQuery,
              private workPlanAndTargetsService: WorkPlanAndTargetsService,
              private objectiveService: ObjectiveService,
              private activityService: ActivityService) {
  }

  ngOnInit() {
    this.workPlanSubscription = this.workPlanAndTargetsQuery.getSelectedWorkPlanObservable().subscribe(workPlan => {
      this.selectedWorkPlan = workPlan;
      this.dataSource = this.generateDataSource();
    });

    // TODO for applicant
    // if (this.workPlanService.workPlanIsWithApplicant && this.ROLE_APPLICANT) {
    //   this.editableForApplicant = true;
    // }
  }

  ngOnDestroy() {
    this.workPlanSubscription.unsubscribe();
  }

  generateBudget(objective: Objective): number {
    let budget = 0;

    objective.activities.forEach(activity => {
      budget += +activity.cost || 0;
    });

    return budget;
  }

  deleteObjective(objective: Objective) {
    this.objectiveService.delete(objective).subscribe();
  }

  selectObjective(objective: Objective) {
    if (this.selectedObjectiveId !== objective.id) {
      this.selectedObjective = objective;
      this.selectedObjectiveId = objective.id;
      this.workPlanAndTargetsService.setSelectedObjective(objective);
    }
  }

  private generateDataSource(): ObjectiveElement[] {
    if (this.selectedWorkPlan && this.selectedWorkPlan.objectives) {
      return this.selectedWorkPlan.objectives.map(item => {
        return {
          name: item.name,
          description: item.description,
          budget: this.generateBudget(item),
          additionalDescription: item.additionalDescription,
          item
        };
      });
    }
  }

  routeToObjectiveDetail(item: Objective) {
    this.selectObjective(item);
    this.router.navigate([`../objective-details/${item.id}`], {relativeTo: this.route}).then();
  }
}

export interface ObjectiveElement {
  name: string;
  description: string;
  budget: number;
  additionalDescription: string;
  item: Objective;
}
