import {Injectable} from '@angular/core';
import {EligibilityStore} from './eligibility.store';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {Eligibility} from './eligibility.model';

@Injectable({providedIn: 'root'})
export class EligibilityService {

  private url = `${environment.apiUrl}/eligibility`;

  constructor(protected store: EligibilityStore,
              private http: HttpClient) {
  }

  getEligibilityByWorkflowId(workflowId: number): Observable<Eligibility> {
    const url = `${this.url}?grantWorkflowId=${workflowId}`;
    return this.http.get<Eligibility>(url).pipe(
      tap(item => {
        return item;
      })
    );
  }


  createEligibilityByWorkflowId(eligibility: Eligibility, workflowId: number, isSubmitting: boolean) {
    let url = `${this.url}?grantWorkflowId=${workflowId}`;
    if (isSubmitting) {
      url += `&isSubmitting=${isSubmitting}`;
    }
    return this.http.post<Eligibility>(url, eligibility).pipe(
      tap(() => {
        return;
      })
    );
  }

  updateEligibility(eligibility: Eligibility, isSubmitting: boolean) {
    let url = `${this.url}/${eligibility.id}`;
    if (isSubmitting) {
      url += `?isSubmitting=${isSubmitting}`;
    }
    return this.http.put<Eligibility>(url, eligibility).pipe(
      tap(() => {
        return;
      })
    );
  }


  getIneligibleReasons(workflowId: number) {
    const url = `${this.url}/ineligible-reasons?grantWorkflowId=${workflowId}`;
    return this.http.get<string[]>(url).pipe(
      tap(item => {
        return item;
      })
    );
  }

  resetIneligible(workflowId: number) {
    const url = `${this.url}/reset-ineligible?grantWorkflowId=${workflowId}`;
    return this.http.put<any>(url, undefined).pipe(
      tap(() => {
        return;
      })
    );
  }
}
