import {Component, Input, OnInit} from '@angular/core';
import {targetedPopulationAnswers} from '../../constants/funding-request-answers/other-funding-request-question-answers.const';
import {FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-other-funding-request',
  templateUrl: './other-funding-request.component.html',
  styleUrls: ['./other-funding-request.component.scss']
})
export class OtherFundingRequestComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() otherFundingRequestForm: FormGroup;
  @Input() formValid: boolean;
  @Input() isReadOnly: boolean;
  @Input() fromReview: boolean;


  targetedPopulation = targetedPopulationAnswers;

  constructor() {
  }

  ngOnInit() {
    this.otherFundingRequestForm.controls.peopleTargeted.setValidators(Validators.required);
  }
}
