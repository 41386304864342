import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Objective} from './objective.model';

export interface ObjectiveState extends EntityState<Objective> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'objective'
})
export class ObjectiveStore extends EntityStore<ObjectiveState> {

  constructor() {
    super();
  }

}

