import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CapacitySpectrumValidationService} from '../../validations/capacity-spectrum-validation.service';

@Component({
  selector: 'app-capacity-spectrum-progress-bar',
  templateUrl: './capacity-spectrum-progress-bar.component.html',
  styleUrls: ['./capacity-spectrum-progress-bar.component.scss']
})
export class CapacitySpectrumProgressBarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() activeChevron;
  @Input() capacitySpectrumForm: FormGroup;
  @Output() activeChevronChange: EventEmitter<string> = new EventEmitter();

  capacitySpectrumSubscription: Subscription;
  orgStructureSubscription: Subscription;
  humanResourcesSubscription: Subscription;
  governanceSubscription: Subscription;
  financialManagementSubscription: Subscription;
  financialSituationSubscription: Subscription;
  mneReportingAndPlanningSubscription: Subscription;
  marketingAndPromotionSubscription: Subscription;
  networkAndLinkagesSubscription: Subscription;
  innovationSubscription: Subscription;
  aspirationalSubscription: Subscription;
  // documentationSubscription: Subscription;

  capacitySpectrumIsValid: boolean;
  structureIsValid: boolean;
  humanResourcesIsValid: boolean;
  governanceIsValid: boolean;
  managementIsValid: boolean;
  situationIsValid: boolean;
  mneReportingAndPlanningIsValid: boolean;
  marketingAndPromotionIsValid: boolean;
  networkAndLinkagesIsValid: boolean;
  innovationIsValid: boolean;
  aspirationalIsValid: boolean;
  // documentationIsValid: boolean;


  constructor(private validationService: CapacitySpectrumValidationService) {
  }

  ngOnInit() {
    this.handleSubscriptions('subscribe');
  }

  ngOnDestroy() {
    this.handleSubscriptions('unsubscribe');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.capacitySpectrumForm && changes.capacitySpectrumForm.currentValue) {
      this.validationService.validateEntity(this.capacitySpectrumForm.value, 'capacitySpectrum');
    }
    if (changes && changes.activeChevron && this.capacitySpectrumForm) {
      this.validationService.validateEntity(this.capacitySpectrumForm.value, 'capacitySpectrum');
      if (changes.activeChevron.previousValue) {
        this.validateCurrentForm(changes.activeChevron.previousValue);
      }
    }
  }

  changeActiveChevron(active: string) {
    this.validateCurrentForm(this.activeChevron);
    this.activeChevron = active;
    this.activeChevronChange.emit(active);
  }

  validateCurrentForm(currentForm: 'organizational structure' | 'human resources' | 'governance' |
    'financial management' | 'financial situation' | 'mne' | 'marketing and promotion' |
    'network and linkages' | 'aspirational' | 'innovation') {
    const controls = this.capacitySpectrumForm.controls;
    switch (currentForm) {
      case 'organizational structure':
        this.validationService.validateEntity(controls.organizationalStructure as FormGroup, 'organizationalStructure');
        break;
      case 'human resources':
        this.validationService.validateEntity(controls.humanResources as FormGroup, 'humanResources');
        break;
      case 'governance':
        this.validationService.validateEntity(controls.governance as FormGroup, 'governance');
        break;
      case 'financial management':
        this.validationService.validateEntity(controls.financialManagement as FormGroup, 'financialManagement');
        break;
      case 'financial situation':
        this.validationService.validateEntity(controls.financialSituation as FormGroup, 'financialSituation');
        break;
      case 'mne':
        this.validationService.validateEntity(controls.mneReportingAndPlanning as FormGroup, 'mne');
        break;
      case 'marketing and promotion':
        this.validationService.validateEntity(controls.marketingAndPromotion as FormGroup, 'marketingAndPromotion');
        break;
      case 'network and linkages':
        this.validationService.validateEntity(controls.networkAndLinkages as FormGroup, 'networkAndLinkages');
        break;
      case 'innovation':
        this.validationService.validateEntity(controls.innovationAndTechnology as FormGroup, 'innovation');
        break;
      case 'aspirational':
        this.validationService.validateEntity(controls.aspirational as FormGroup, 'aspirational');
        break;
      // case 'supporting documentation':
      //   this.validationService.validateEntity(controls.supportingDocumentation as FormGroup, 'supportingDocuments');
      //   break;
    }
  }

  handleSubscriptions(type: string) {
    if (type === 'subscribe') {
      this.subscribeToObservables();
    } else if (type === 'unsubscribe') {
      this.unsubscribeFromObservables();
    }
  }


  subscribeToObservables() {
    // Full Application
    this.capacitySpectrumSubscription = this.validationService.$capacitySpectrumValid.subscribe(capacitySpectrumValid => {
      this.capacitySpectrumIsValid = capacitySpectrumValid;
    });
    // Organization Structure
    this.orgStructureSubscription = this.validationService.$orgStructureValid.subscribe(structureValid => {
      this.structureIsValid = structureValid;
    });
    // Human Resources
    this.humanResourcesSubscription = this.validationService.$humanResourcesValid.subscribe(humanResourcesValid => {
      this.humanResourcesIsValid = humanResourcesValid;
    });
    // Governance
    this.governanceSubscription = this.validationService.$governanceValid.subscribe(governanceValid => {
      this.governanceIsValid = governanceValid;
    });
    // Financial Management
    this.financialManagementSubscription = this.validationService.$financialManagementValid.subscribe(managementValid => {
      this.managementIsValid = managementValid;
    });
    // Financial Situation
    this.financialSituationSubscription = this.validationService.$financialSituationValid.subscribe(situationValid => {
      this.situationIsValid = situationValid;
    });
    // M&E
    this.mneReportingAndPlanningSubscription = this.validationService.$mneValid.subscribe(mneValid => {
      this.mneReportingAndPlanningIsValid = mneValid;
    });
    // Marketing And Promotion
    this.marketingAndPromotionSubscription = this.validationService.$marketingAndPromotionValid.subscribe(marketingAndPromotionValid => {
      this.marketingAndPromotionIsValid = marketingAndPromotionValid;
    });
    // Network And Linkages
    this.networkAndLinkagesSubscription = this.validationService.$networkAndLinkagesValid.subscribe(networkAndLinkagesValid => {
      this.networkAndLinkagesIsValid = networkAndLinkagesValid;
    });
    // Innovation
    this.innovationSubscription = this.validationService.$innovationValid.subscribe(innovationValid => {
      this.innovationIsValid = innovationValid;
    });
    // Aspirational
    this.aspirationalSubscription = this.validationService.$aspirationalValid.subscribe(aspirationalValid => {
      this.aspirationalIsValid = aspirationalValid;
    });
    // Supporting Documents
    // this.documentationSubscription = this.validationService.$supportingDocsValid.subscribe(documentationValid => {
    //   this.documentationIsValid = documentationValid;
    // });
  }

  unsubscribeFromObservables() {
    this.capacitySpectrumSubscription.unsubscribe();
    this.orgStructureSubscription.unsubscribe();
    this.humanResourcesSubscription.unsubscribe();
    this.governanceSubscription.unsubscribe();
    this.financialManagementSubscription.unsubscribe();
    this.financialSituationSubscription.unsubscribe();
    this.mneReportingAndPlanningSubscription.unsubscribe();
    this.marketingAndPromotionSubscription.unsubscribe();
    this.networkAndLinkagesSubscription.unsubscribe();
    this.innovationSubscription.unsubscribe();
    this.aspirationalSubscription.unsubscribe();
    // this.documentationSubscription.unsubscribe();
    this.validationService.unValidateAll();
  }
}
