import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AdvancedPieChartComponent} from '@swimlane/ngx-charts';
import {ChartData} from '../models/charts.model';

@Component({
  selector: 'app-simple-pie-chart',
  templateUrl: './simple-pie-chart.component.html',
  styleUrls: ['./simple-pie-chart.component.scss']
})
export class SimplePieChartComponent implements OnInit, AfterViewInit {
  @ViewChild('pieChart') pieChart: ElementRef<AdvancedPieChartComponent>;

  @Input() chartData: ChartData<number>[];
  @Input() totalHeading: string;
  @Input() view: any[];

  @Input() valueFormatting: (value: any) => string;

  gradient = true;
  labels: any;
  labelsArray: any[] = [];
  colorScheme: {};

  constructor() {
  }

  ngOnInit(): void {
    this.colorScheme = {
      domain:
        [
          '#e65600',
          '#0091ea',
          '#55902a',
          '#b20707',
          '#e69600',
        ]
    };
  }

  ngAfterViewInit() {
    // The `type` returned by the @ViewChild says that this.pieChart should be `ElementRef`.
    // When using the object however, the type is completely different :/
    // console.log(this.pieChart) // AdvancedPieChartComponent(?) = {
    //   activate, activeEntries, animations, chartElement, etc
    // }

    // @ts-ignore
    this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[1].innerHTML =
      this.totalHeading;

    this.labels =
      // @ts-ignore
      this.pieChart.chartElement.nativeElement.children[0].children[1].children[0].children[0].children[2].children[0];
    this.chartData.forEach((item, idx) => {
      this.labelsArray.push(this.labels.children[idx]);
    });
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
