import {createGrantWorkflow, GrantWorkflow} from '../../grant-workflow/store/grant-workflow.model';
import {FileUpload} from '../../../shared/modules/file-upload/models/file-upload.model';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../shared/models/base-entity.model';

export interface ReviewModel extends BaseEntity {
  grantWorkflow: GrantWorkflow;
  coordinatorReview: CoordinatorReview;
}

export interface CoordinatorReview extends BaseEntity {
  capacitySpectrumVerification: CapacitySpectrumVerification;
  serviceMatrixVerification: ServiceMatrixVerification;
  internalVerification: InternalVerification;
  telephoneInterview: TelephoneInterview;
  siteVisit: SiteVisit;
  additionalDocument: AdditionalDocuments;
}

export interface AdditionalDocuments extends BaseEntity {
  annualOperatingBudget: FileUpload[];
  bankStatements: FileUpload[];
}

export interface CapacitySpectrumVerification extends BaseEntity {

}

export interface ServiceMatrixVerification extends BaseEntity {

}

export interface InternalVerification extends BaseEntity {
  legalNameIsCorrect: boolean;
  npoDateIsCorrect: boolean;
  relatedToGrantIsCorrect: boolean;
  servicesAndHistoryDocumentIsCorrect: boolean;
  npoRegistrationDocumentIsCorrect: boolean;
  budgetQuotesDocumentIsCorrect: boolean;
  communityLetterOfReferenceIsCorrect: boolean;
  saGovernmentLetterOfReferenceIsCorrect: boolean;
  bankStatementsIsCorrect: boolean;
}

export interface TelephoneInterview extends BaseEntity {
  personInterviewed: string;
  dateOfCall: any;
  coordinator: string;
  contactDetailsIsCorrect: boolean;
  fundingRequestDiscussed: boolean;
  fundingRequestNotes: string;
  telephoneNotes: any;
}

export interface SiteVisit extends BaseEntity {
  personPresent: string;
  dateOfVisit: any;
  coordinator: string;
  hasGpsCoordinates: boolean;
  siteNotes: [];
  organizationStructure: SiteVisitOrgStructure;
  serviceDelivery: SiteVisitServiceDelivery;
  communityRelationships: SiteVisitCommunityRelationships;
  financial: Financial;
  recentChanges: SiteVisitRecentChanges;
}

export interface SiteVisitOrgStructure extends BaseEntity {
  numberOfWorkersIsCorrect: boolean;
  hrPolicyIsCorrect: boolean;
  jobDescriptionsIsCorrect: boolean;
  boardStructureIsCorrect: boolean;
  reportsIsCorrect: boolean;
  constitutionIsCorrect: boolean;
  monitoringAndEIsCorrect: boolean;
  leaseAgreementIsCorrect: boolean;
}

export interface SiteVisitServiceDelivery extends BaseEntity {
  referralAndLinkageIsCorrect: boolean;
  childSafeGuardingPolicyIsCorrect: boolean;
  beneficiaryRightsIsCorrect: boolean;
  staffTrainingBeneficiaryRightsIsCorrect: boolean;
  adequateMeasuresIsCorrect: boolean;
  dataQualityIsCorrect: boolean;
  frequencyOfStaffTrainingIsCorrect: boolean;
  numberOfBeneficiariesIsCorrect: boolean;
  evidenceOfServicesIsCorrect: boolean;
  htsSuppliesIsCorrect: boolean;
  htsProtocolsIsCorrect: boolean;
  htsProvidersIsCorrect: boolean;
  writtenProceduresIsCorrect: boolean;
  artTrackingIsCorrect: boolean;
  preventionMaterialsIsCorrect: boolean;
  groupSizeIsCorrect: boolean;
}

export interface SiteVisitCommunityRelationships extends BaseEntity {
  communityInvolvementIsCorrect: boolean;
  relevantCouncilsAreCorrect: boolean;
  communityLinkagesIsCorrect: boolean;
  involvementInCommunityForumIsCorrect: boolean;
  profileIsCorrect: boolean;
  relationshipWithClinicIsCorrect: boolean;
  engagementWithLeadersIsCorrect: boolean;
}

export interface Financial extends BaseEntity {
  annualOperatingBudgetIsCorrect: boolean;
  budgetCreationIsCorrect: boolean;
  inventoryOfAssetsIsCorrect: boolean;
  overheadCostIsCorrect: boolean;
  budgetTrackingIsCorrect: boolean;
  financialControlsIsCorrect: boolean;
  financialPolicyIsCorrect: boolean;
  vatRegistrationIsCorrect: boolean;
  recordRetentionIsCorrect: boolean;
  auditIsCorrect: boolean;
  incomeGenerationIsCorrect: boolean;
  communityContributionsIsCorrect: boolean;
  debtStatus: boolean;
  debtStatusComment: string;
}

export interface SiteVisitRecentChanges extends BaseEntity {
  changesInLeadership: boolean;
  gainOrLossOfFunders: boolean;
  newProgramsAdded: boolean;
  majorProgramsStopped: boolean;
}

export function createReview(params: Partial<ReviewModel>) {
  return Object.assign({}, createBaseEntity(params), {
    grantWorkflow: createGrantWorkflow(params && params.grantWorkflow || {}),
    coordinatorReview: createCoordinatorReview(params && params.coordinatorReview || {})
  }) as ReviewModel;
}

export function createCoordinatorReview(params: Partial<CoordinatorReview>) {
  return Object.assign({}, createBaseEntity(params), {
    internalVerification: createInternalVerification(params && params.internalVerification || {}),
    telephoneInterview: createTelephoneInterview(params && params.telephoneInterview || {}),
    siteVisit: createSiteVisit(params && params.siteVisit || {}),
    additionalDocument: createAdditionalDocument(params && params.additionalDocument || {}),
  }) as CoordinatorReview;
}

export function createAdditionalDocument(params: Partial<AdditionalDocuments>) {
  return Object.assign({}, createBaseEntity(params), {
    annualOperatingBudget: params && params.annualOperatingBudget || [],
    bankStatements: params && params.bankStatements || []
  }) as AdditionalDocuments;
}

export function createTelephoneInterview(params: Partial<TelephoneInterview>) {
  return Object.assign({}, createBaseEntity(params), {
    personInterviewed: params && params.personInterviewed,
    dateOfCall: params && params.dateOfCall,
    coordinator: params && params.coordinator,
    contactDetailsIsCorrect: params && params.contactDetailsIsCorrect,
    fundingRequestDiscussed: params && params.fundingRequestDiscussed,
    fundingRequestNotes: params && params.fundingRequestNotes,
    telephoneNotes: params && params.telephoneNotes
  }) as TelephoneInterview;
}

export function createInternalVerification(params: Partial<InternalVerification>) {
  return Object.assign({}, createBaseEntity(params), {
    legalNameIsCorrect: params && params.legalNameIsCorrect,
    npoDateIsCorrect: params && params.npoDateIsCorrect,
    relatedToGrantIsCorrect: params && params.relatedToGrantIsCorrect,
    servicesAndHistoryDocumentIsCorrect: params && params.servicesAndHistoryDocumentIsCorrect,
    npoRegistrationDocumentIsCorrect: params && params.npoRegistrationDocumentIsCorrect,
    budgetQuotesDocumentIsCorrect: params && params.budgetQuotesDocumentIsCorrect,
    communityLetterOfReferenceIsCorrect: params && params.communityLetterOfReferenceIsCorrect,
    saGovernmentLetterOfReferenceIsCorrect: params && params.saGovernmentLetterOfReferenceIsCorrect,
    bankStatementsIsCorrect: params && params.bankStatementsIsCorrect
  }) as InternalVerification;
}

export function createSiteVisit(params: Partial<SiteVisit>) {
  return Object.assign({}, createBaseEntity(params), {
    personPresent: params && params.personPresent,
    dateOfVisit: params && params.dateOfVisit,
    coordinator: params && params.coordinator,
    hasGpsCoordinates: params && params.hasGpsCoordinates,
    organizationStructure: createSiteVisitOrgStructure(params && params.organizationStructure),
    serviceDelivery: createSiteVisitServiceDelivery(params && params.serviceDelivery),
    communityRelationships: createSiteVisitCommunityRelationships(params && params.communityRelationships),
    financial: createFinancial(params && params.financial),
    recentChanges: createSiteVisitRecentChanges(params && params.recentChanges),
    siteNotes: params && params.siteNotes
  }) as SiteVisit;
}

export function createSiteVisitOrgStructure(params: Partial<SiteVisitOrgStructure>) {
  return Object.assign({}, createBaseEntity(params), {
    numberOfWorkersIsCorrect: params && params.numberOfWorkersIsCorrect,
    hrPolicyIsCorrect: params && params.hrPolicyIsCorrect,
    jobDescriptionsIsCorrect: params && params.jobDescriptionsIsCorrect,
    boardStructureIsCorrect: params && params.boardStructureIsCorrect,
    reportsIsCorrect: params && params.reportsIsCorrect,
    constitutionIsCorrect: params && params.constitutionIsCorrect,
    monitoringAndEIsCorrect: params && params.monitoringAndEIsCorrect,
    leaseAgreementIsCorrect: params && params.leaseAgreementIsCorrect
  }) as SiteVisitOrgStructure;
}

export function createSiteVisitServiceDelivery(params: Partial<SiteVisitServiceDelivery>) {
  return Object.assign({}, createBaseEntity(params), {
    referralAndLinkageIsCorrect: params && params.referralAndLinkageIsCorrect,
    childSafeGuardingPolicyIsCorrect: params && params.childSafeGuardingPolicyIsCorrect,
    beneficiaryRightsIsCorrect: params && params.beneficiaryRightsIsCorrect,
    staffTrainingBeneficiaryRightsIsCorrect: params && params.staffTrainingBeneficiaryRightsIsCorrect,
    adequateMeasuresIsCorrect: params && params.adequateMeasuresIsCorrect,
    dataQualityIsCorrect: params && params.dataQualityIsCorrect,
    frequencyOfStaffTrainingIsCorrect: params && params.frequencyOfStaffTrainingIsCorrect,
    numberOfBeneficiariesIsCorrect: params && params.numberOfBeneficiariesIsCorrect,
    evidenceOfServicesIsCorrect: params && params.evidenceOfServicesIsCorrect,
    htsSuppliesIsCorrect: params && params.htsSuppliesIsCorrect,
    htsProtocolsIsCorrect: params && params.htsProtocolsIsCorrect,
    htsProvidersIsCorrect: params && params.htsProvidersIsCorrect,
    writtenProceduresIsCorrect: params && params.writtenProceduresIsCorrect,
    artTrackingIsCorrect: params && params.artTrackingIsCorrect,
    preventionMaterialsIsCorrect: params && params.preventionMaterialsIsCorrect,
    groupSizeIsCorrect: params && params.groupSizeIsCorrect
  }) as SiteVisitServiceDelivery;
}

export function createSiteVisitCommunityRelationships(params: Partial<SiteVisitCommunityRelationships>) {
  return Object.assign({}, createBaseEntity(params), {
    communityInvolvementIsCorrect: params && params.communityInvolvementIsCorrect,
    relevantCouncilsAreCorrect: params && params.relevantCouncilsAreCorrect,
    communityLinkagesIsCorrect: params && params.communityLinkagesIsCorrect,
    involvementInCommunityForumIsCorrect: params && params.involvementInCommunityForumIsCorrect,
    profileIsCorrect: params && params.profileIsCorrect,
    relationshipWithClinicIsCorrect: params && params.relationshipWithClinicIsCorrect,
    engagementWithLeadersIsCorrect: params && params.engagementWithLeadersIsCorrect
  }) as SiteVisitCommunityRelationships;
}

export function createFinancial(params: Partial<Financial>) {
  return Object.assign({}, createBaseEntity(params), {
    annualOperatingBudgetIsCorrect: params && params.annualOperatingBudgetIsCorrect,
    budgetCreationIsCorrect: params && params.budgetCreationIsCorrect,
    inventoryOfAssetsIsCorrect: params && params.inventoryOfAssetsIsCorrect,
    overheadCostIsCorrect: params && params.overheadCostIsCorrect,
    budgetTrackingIsCorrect: params && params.budgetTrackingIsCorrect,
    financialControlsIsCorrect: params && params.financialControlsIsCorrect,
    financialPolicyIsCorrect: params && params.financialPolicyIsCorrect,
    vatRegistrationIsCorrect: params && params.vatRegistrationIsCorrect,
    recordRetentionIsCorrect: params && params.recordRetentionIsCorrect,
    auditIsCorrect: params && params.auditIsCorrect,
    incomeGenerationIsCorrect: params && params.incomeGenerationIsCorrect,
    communityContributionsIsCorrect: params && params.communityContributionsIsCorrect,
    debtStatus: params && params.debtStatus,
    debtStatusComment: params && params.debtStatusComment,
  }) as Financial;
}

export function createSiteVisitRecentChanges(params: Partial<SiteVisitRecentChanges>) {
  return Object.assign({}, createBaseEntity(params), {
    changesInLeadership: params && params.changesInLeadership,
    gainOrLossOfFunders: params && params.gainOrLossOfFunders,
    newProgramsAdded: params && params.newProgramsAdded,
    majorProgramsStopped: params && params.majorProgramsStopped
  }) as SiteVisitRecentChanges;
}

export function createReviewForm(formBuilder: FormBuilder, params: Partial<ReviewModel>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    grantWorkflow: [params && params.grantWorkflow, Validators.required],
    coordinatorReview: createCoordinatorReviewForm(formBuilder, params && params.coordinatorReview),
  }));
}

export function createCoordinatorReviewForm(formBuilder: FormBuilder, params: Partial<CoordinatorReview>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    capacitySpectrumVerification: createCapacitySpectrumVerificationForm(formBuilder, params && params.capacitySpectrumVerification),
    serviceMatrixVerification: createServiceMatrixVerificationForm(formBuilder, params && params.serviceMatrixVerification),
    internalVerification: createInternalVerificationForm(formBuilder, params && params.internalVerification),
    telephoneInterview: createTelephoneInterviewForm(formBuilder, params && params.telephoneInterview),
    siteVisit: createSiteVisitForm(formBuilder, params && params.siteVisit),
    additionalDocument: createAdditionalDocumentsForm(formBuilder, params && params.additionalDocument),
  }));
}

export function createAdditionalDocumentsForm(formBuilder: FormBuilder, params: Partial<AdditionalDocuments>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    annualOperatingBudget: [params && params.annualOperatingBudget, Validators.required],
    bankStatements: [params && params.bankStatements, Validators.required]
  }));
}

export function createCapacitySpectrumVerificationForm(formBuilder: FormBuilder, params: Partial<CapacitySpectrumVerification>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {}));
}

export function createServiceMatrixVerificationForm(formBuilder: FormBuilder, params: Partial<ServiceMatrixVerification>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {}));
}

export function createInternalVerificationForm(formBuilder: FormBuilder, params: Partial<InternalVerification>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    legalNameIsCorrect: [params && params.legalNameIsCorrect, Validators.required],
    npoDateIsCorrect: [params && params.npoDateIsCorrect, Validators.required],
    relatedToGrantIsCorrect: [params && params.relatedToGrantIsCorrect, Validators.required],
    servicesAndHistoryDocumentIsCorrect: [params && params.servicesAndHistoryDocumentIsCorrect, Validators.required],
    npoRegistrationDocumentIsCorrect: [params && params.npoRegistrationDocumentIsCorrect, Validators.required],
    budgetQuotesDocumentIsCorrect: [params && params.budgetQuotesDocumentIsCorrect, Validators.required],
    communityLetterOfReferenceIsCorrect: [params && params.communityLetterOfReferenceIsCorrect, Validators.required],
    saGovernmentLetterOfReferenceIsCorrect: [params && params.saGovernmentLetterOfReferenceIsCorrect, Validators.required],
    bankStatementsIsCorrect: [params && params.bankStatementsIsCorrect, Validators.required],
  }));
}

export function createTelephoneInterviewForm(formBuilder: FormBuilder, params: Partial<TelephoneInterview>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    personInterviewed: [params && params.personInterviewed, Validators.required],
    dateOfCall: [params && params.dateOfCall, Validators.required],
    coordinator: [params && params.coordinator, Validators.required],
    contactDetailsIsCorrect: [params && params.contactDetailsIsCorrect, Validators.required],
    fundingRequestDiscussed: [params && params.fundingRequestDiscussed, Validators.required],
    fundingRequestNotes: [params && params.fundingRequestNotes, Validators.required],
    telephoneNotes: [params && params.telephoneNotes, Validators.required],
  }));
}

export function createSiteVisitForm(formBuilder: FormBuilder, params: Partial<SiteVisit>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    personPresent: [params && params.personPresent, Validators.required],
    dateOfVisit: [params && params.dateOfVisit, Validators.required],
    coordinator: [params && params.coordinator, Validators.required],
    hasGpsCoordinates: [params && params.hasGpsCoordinates, Validators.required],
    organizationStructure: createSiteVisitOrgStructureForm(formBuilder, params && params.organizationStructure),
    serviceDelivery: createSiteVisitServiceDeliveryForm(formBuilder, params && params.serviceDelivery),
    communityRelationships: createSiteVisitCommunityRelationshipsForm(
      formBuilder, params && params.communityRelationships),
    financial: createFinancialForm(formBuilder, params && params.financial),
    recentChanges: createSiteVisitRecentChangesForm(formBuilder, params && params.recentChanges),
    siteNotes: [params && params.siteNotes, Validators.required]
  }));
}

export function createSiteVisitOrgStructureForm(formBuilder: FormBuilder, params: Partial<SiteVisitOrgStructure>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    numberOfWorkersIsCorrect: [params && params.numberOfWorkersIsCorrect, Validators.required],
    hrPolicyIsCorrect: [params && params.hrPolicyIsCorrect, Validators.required],
    jobDescriptionsIsCorrect: [params && params.jobDescriptionsIsCorrect, Validators.required],
    boardStructureIsCorrect: [params && params.boardStructureIsCorrect, Validators.required],
    reportsIsCorrect: [params && params.reportsIsCorrect, Validators.required],
    constitutionIsCorrect: [params && params.constitutionIsCorrect, Validators.required],
    monitoringAndEIsCorrect: [params && params.monitoringAndEIsCorrect, Validators.required],
    leaseAgreementIsCorrect: [params && params.leaseAgreementIsCorrect, Validators.required],
  }));
}

export function createSiteVisitServiceDeliveryForm(formBuilder: FormBuilder, params: Partial<SiteVisitServiceDelivery>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    referralAndLinkageIsCorrect: [params && params.referralAndLinkageIsCorrect, Validators.required],
    childSafeGuardingPolicyIsCorrect: [params && params.childSafeGuardingPolicyIsCorrect, Validators.required],
    beneficiaryRightsIsCorrect: [params && params.beneficiaryRightsIsCorrect, Validators.required],
    staffTrainingBeneficiaryRightsIsCorrect: [params && params.staffTrainingBeneficiaryRightsIsCorrect, Validators.required],
    adequateMeasuresIsCorrect: [params && params.adequateMeasuresIsCorrect, Validators.required],
    dataQualityIsCorrect: [params && params.dataQualityIsCorrect, Validators.required],
    frequencyOfStaffTrainingIsCorrect: [params && params.frequencyOfStaffTrainingIsCorrect, Validators.required],
    numberOfBeneficiariesIsCorrect: [params && params.numberOfBeneficiariesIsCorrect, Validators.required],
    evidenceOfServicesIsCorrect: [params && params.evidenceOfServicesIsCorrect, Validators.required],
    htsSuppliesIsCorrect: [params && params.htsSuppliesIsCorrect, Validators.required],
    htsProtocolsIsCorrect: [params && params.htsProtocolsIsCorrect, Validators.required],
    htsProvidersIsCorrect: [params && params.htsProvidersIsCorrect, Validators.required],
    writtenProceduresIsCorrect: [params && params.writtenProceduresIsCorrect, Validators.required],
    artTrackingIsCorrect: [params && params.artTrackingIsCorrect, Validators.required],
    preventionMaterialsIsCorrect: [params && params.preventionMaterialsIsCorrect, Validators.required],
    groupSizeIsCorrect: [params && params.groupSizeIsCorrect, Validators.required]
  }));
}

export function createSiteVisitCommunityRelationshipsForm(formBuilder: FormBuilder, params: Partial<SiteVisitCommunityRelationships>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    communityInvolvementIsCorrect: [params && params.communityInvolvementIsCorrect, Validators.required],
    relevantCouncilsAreCorrect: [params && params.relevantCouncilsAreCorrect, Validators.required],
    communityLinkagesIsCorrect: [params && params.communityLinkagesIsCorrect, Validators.required],
    involvementInCommunityForumIsCorrect: [params && params.involvementInCommunityForumIsCorrect, Validators.required],
    profileIsCorrect: [params && params.profileIsCorrect, Validators.required],
    relationshipWithClinicIsCorrect: [params && params.relationshipWithClinicIsCorrect, Validators.required],
    engagementWithLeadersIsCorrect: [params && params.engagementWithLeadersIsCorrect, Validators.required]
  }));
}

export function createFinancialForm(formBuilder: FormBuilder, params: Partial<Financial>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    annualOperatingBudgetIsCorrect: [params && params.annualOperatingBudgetIsCorrect, Validators.required],
    budgetCreationIsCorrect: [params && params.budgetCreationIsCorrect, Validators.required],
    inventoryOfAssetsIsCorrect: [params && params.inventoryOfAssetsIsCorrect, Validators.required],
    overheadCostIsCorrect: [params && params.overheadCostIsCorrect, Validators.required],
    budgetTrackingIsCorrect: [params && params.budgetTrackingIsCorrect, Validators.required],
    financialControlsIsCorrect: [params && params.financialControlsIsCorrect, Validators.required],
    financialPolicyIsCorrect: [params && params.financialPolicyIsCorrect, Validators.required],
    vatRegistrationIsCorrect: [params && params.vatRegistrationIsCorrect, Validators.required],
    recordRetentionIsCorrect: [params && params.recordRetentionIsCorrect, Validators.required],
    auditIsCorrect: [params && params.auditIsCorrect, Validators.required],
    incomeGenerationIsCorrect: [params && params.incomeGenerationIsCorrect, Validators.required],
    communityContributionsIsCorrect: [params && params.communityContributionsIsCorrect, Validators.required],
    debtStatus: [params && params.debtStatus, Validators.required],
    debtStatusComment: [params && params.debtStatusComment, Validators.required],
  }));
}

export function createSiteVisitRecentChangesForm(formBuilder: FormBuilder, params: Partial<SiteVisitRecentChanges>) {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    changesInLeadership: [params && params.changesInLeadership, Validators.required],
    gainOrLossOfFunders: [params && params.gainOrLossOfFunders, Validators.required],
    newProgramsAdded: [params && params.newProgramsAdded, Validators.required],
    majorProgramsStopped: [params && params.majorProgramsStopped, Validators.required]
  }));
}
