import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mbl-table-pagination',
  templateUrl: './mbl-table-pagination.component.html',
  styleUrls: ['./mbl-table-pagination.component.scss']
})
export class MblTablePaginationComponent implements OnInit {
  @Input() count: number;
  @Input() offset: number;
  @Input() limit: number;
  @Input() numberOfPages: number;
  @Output() page: EventEmitter<number> = new EventEmitter<number>();

  numberOfPaginationButtons = 5;
  totalPages: number;

  constructor() { }

  ngOnInit() {
    this.totalPages = this.count / this.limit;
    this.numberOfPaginationButtons = this.totalPages > 5 ? 5 : this.totalPages;
  }

  arrayOfNumbers(): any[] {
    return Array(this.numberOfPaginationButtons);
  }

  setPage(offset) {
    this.page.emit(offset);
  }
}
