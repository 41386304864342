import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormBuilderListComponent} from './components/form-builder-list/form-builder-list.component';
import {FormBuilderComponent} from './components/form-builder-list/form-builder/form-builder.component';
import {FormRendererComponent} from './components/form-renderer/form-renderer.component';

import {FormHistoryComponent} from './components/form-history/form-history.component';
import {TemplateListComponent} from './template/template-list/template-list.component';
import {TemplateFormBuilderComponent} from './template/template-list/template-form-builder/template-form-builder.component';

const routes: Routes = [
  {path: 'list', component: FormBuilderListComponent},

  {path: 'details/:formBuilderId', component: FormBuilderComponent},
  {path: 'render/:formBuilderId', component: FormRendererComponent},
  {path: 'history/:formBuilderId', component: FormHistoryComponent},

  // Template
  {path: 'template/list', redirectTo: 'template/list/', pathMatch: 'full'},
  {path: 'template/list/:link', component: TemplateListComponent},

  {path: 'template/details/:formBuilderId', component: TemplateFormBuilderComponent},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class FormBuilderRoutingModule {
}
