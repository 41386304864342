import {Pipe, PipeTransform} from '@angular/core';
import {Funder} from '../../../../../modules/funder/funder-profile/store/funder.model';
import {getFunderName} from '../../../../utils/funder-name.util';

@Pipe({
  name: 'funderName'
})
export class FunderNamePipe implements PipeTransform {

  transform(funder: Funder): string {
    return getFunderName(funder);
  }

}
