import {Pipe, PipeTransform} from '@angular/core';
import {filterTypes} from '../../../charts/models/charts.model';

@Pipe({
  name: 'filterType'
})
export class FilterTypePipe implements PipeTransform {

  transform(value: string, direction: 'toLabel' | 'toValue'): string | undefined {
    return direction === 'toLabel' ?
      filterTypes.find(filterType => filterType.value === value)?.label :
      filterTypes.find(filterType => filterType.label === value)?.value;
  }

}
