import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-landing',
  templateUrl: './public-landing.component.html',
  styleUrls: ['./public-landing.component.scss']
})
export class PublicLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
