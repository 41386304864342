import {SingleScoreDTO} from '../../modules/organisation/capacity-spectrum/components/capacity-spectrum/store/capacity-spectrum.model';
import {Injectable} from '@angular/core';

interface Status {
  value: string;
  class: string;
}

@Injectable({
  providedIn: 'root'
})
export class CapacitySpectrumUtil {
  getStatus(scoreDTO: SingleScoreDTO): Status {
    if (scoreDTO && scoreDTO.score && scoreDTO.max) {
      const percentage = scoreDTO.score * 100 / scoreDTO.max;
      if (percentage >= 85) {
        return {value: 'Innovative', class: 'badge-light'};
      } else if (percentage >= 68) {
        return {value: 'Advanced', class: 'badge-primary'};
      } else if (percentage >= 51) {
        return {value: 'Developed', class: 'badge-success'};
      } else if (percentage >= 34) {
        return {value: 'Stable', class: 'badge-secondary'};
      } else if (percentage >= 17) {
        return {value: 'Vulnerable', class: 'badge-warning'};
      }
    }
    return {value: 'Unstable', class: 'badge-danger'};
  }
}

