import {Component, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GrantWorkflowNavigator} from '../../grant-workflow-navigator';
import {GrantWindowService} from '../../../grant-window/store/grant-window.service';
import {Location} from '@angular/common';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '../../../../shared/modules/shared-common/services/modal/modal.service';
import {Application, createApplication, createApplicationForm} from './store/application.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApplicationService} from './store/application.service';
import {SessionQuery} from '../../../../shared/stores/session/session.query';
import {createGrantWorkflow} from '../../store/grant-workflow.model';
import {GrantWindow} from '../../../grant-window/store/grant-window.model';
import {AlertService} from '../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
  @ViewChild('submitModal', {static: true}) submitModal: TemplateRef<HTMLElement>;
  @ViewChild('saveBeforeLeaving', {static: true}) saveBeforeLeaving: TemplateRef<HTMLElement>;

  @Input() workflowId: number;
  @Input() fromGrantReview: boolean;
  @Input() fromApplicationHistory: boolean;
  @Input() isReadOnly: boolean;

  @Output() scrolling = new EventEmitter<boolean>();

  grantApplication: Application;
  applicationForm: FormGroup;

  exitUrl: string;

  appValid: boolean;
  isNotApplicant: boolean;
  hasSaved: boolean;
  loading: boolean;
  warningDismissed = false;

  grantWindow: GrantWindow;
  modalOptions: NgbModalOptions;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              private grantWorkflowNavigator: GrantWorkflowNavigator,
              private modalService: ModalService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private grantWindowService: GrantWindowService,
              private applicationService: ApplicationService,
              private sessionQuery: SessionQuery) {
  }

  ngOnInit() {
    this.loading = true;
    this.isNotApplicant = this.sessionQuery.hasRole(['ROLE_ADMIN', 'ROLE_FUNDER', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']);
    this.isReadOnly = this.isReadOnly || this.isNotApplicant;

    this.workflowId = this.workflowId || this.route.snapshot.params.workflowId;

    this.grantWindow = this.grantWindowService.selectedGrantWindow;

    this.fetchApplication();

    this.modalOptions = {
      size: 'lg',
      centered: true,
      windowClass: 'modalWidth-50'
    };
  }

  // This function is NOT unused.
  // It's being called from the `canDeactivateApplication` guard in `shared/guards`.
  // noinspection JSUnusedGlobalSymbols
  openSaveModal() {
    if (!this.warningDismissed) {
      this.modalService.openBasicModal(this.saveBeforeLeaving, this.modalOptions);
    }
  }

  fetchApplication() {
    this.loading = true;
    // Fetch application or create new
    this.applicationService.getApplicationByWorkflowId(this.workflowId).subscribe((result: Application) => {
      if (result) {
        this.grantApplication = createApplication(result);
        this.applicationForm = this.applicationForm || createApplicationForm(this.formBuilder, this.grantApplication);
        this.loading = false;
      } else {
        this.grantApplication = createApplication({
          grantWorkflow: createGrantWorkflow({id: this.workflowId}),
          status: 'NEW'
        });
        this.applicationForm = createApplicationForm(this.formBuilder, this.grantApplication);
        this.loading = false;
      }
    }, () => {
      this.alertService.setAlert('Unable to fetch Application data', 'error', 'application');
      this.loading = false;
    });
  }

  openSubmitModal(form: FormGroup) {
    if (form.valid) {
      this.modalService.openBasicModal(this.submitModal, this.modalOptions);
    } else {
      this.alertService.setAlert('Complete all required fields.', 'error', 'application');
    }
  }

  handleSubmitApplication(values) {
    this.applicationForm.controls.submittedBy.patchValue(values.submittedBy);
    this.applicationForm.controls.submitVerification.patchValue(values.submitVerification);

    this.save(true);
  }

  save(isSubmitting?: boolean) {
    this.loading = true;

    if (isSubmitting) {

      if (!this.appValid) {
        this.alertService.setAlert(
          'Unable to submit Application form.\n Check to see that all fields are correct.',
          'error',
          'application'
        );
        this.loading = false;
        return;
      }
    }

    // create or update.
    this.grantApplication.id ? this.update(this.grantApplication.id, isSubmitting) : this.create(isSubmitting);
    this.hasSaved = true;
  }

  create(isSubmitting?: boolean) {
    const username = this.sessionQuery.getUser().username;
    this.applicationService.createApplicationByWorkflowId(
      this.applicationForm.value,
      this.workflowId,
      isSubmitting,
      username
    ).subscribe(() => {
      if (isSubmitting) {
        this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
      } else {
        this.fetchApplication();
        this.alertService.setAlert('Success: Saved Application', 'success', 'application', {
          timeOut: 5000
        });
      }
    }, (response) => {
      if (isSubmitting) {
        this.loading = false;
        this.alertService.setAlert('Unable to post submit Application', 'error', 'application');
      } else {
        this.loading = false;
        this.alertService.setAlert('Unable to create Application', 'error', 'application');
      }
    });
  }

  update(applicationId: number, isSubmitting?: boolean) {
    const username = this.sessionQuery.getUser().username;
    this.applicationService.updateApplication(this.applicationForm.value, applicationId, isSubmitting, username).subscribe(() => {
      if (isSubmitting) {
        this.grantWorkflowNavigator.routeToComponentByWorkflowId(this.workflowId);
      } else {
        this.fetchApplication();
        this.alertService.setAlert('Success: Saved Application', 'success', 'application',  {
          timeOut: 5000
        });
      }
    }, (response) => {
      if (isSubmitting) {
        this.loading = false;
        this.alertService.setAlert('Unable to put submit Application:\n' + response.error.message, 'error', 'application');
      } else {
        this.loading = false;
        this.alertService.setAlert('Unable to update Application:\n' + response.error.message, 'error', 'application');
      }
    });
  }

  goBack() {
    this.location.back();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  leaveApplication() {
    this.warningDismissed = true;
    this.closeModal();
    this.router.navigate([this.exitUrl]).then();
  }

  downloadPdf() {
    // TODO
    // DownloadPdfUtil.downloadPdf('application', this.grantApplication, this.grantApplication.grantWorkflow.organisation);
    // const content = [];
    // content.push(
    //   DownloadPDFUtil.generateGrantWindowPDF(this.grantApplication.grantWorkflow.grantWindow),
    //   DownloadPDFUtil.generateApplicationPDF(this.grantApplication));
    // DownloadPDFUtil.downloadPDF(this.grantApplication.grantWorkflow.organisation, content);
  }
}

