<div class="wrapper" [class.esx-page]="!fromReview">

  <div *ngIf="!fromReview" class="esx-header">
    <div class="flex-center h-100">
      <div class="card header-card">
        <div class="card-body p-0">
          <div class="header">
            <div class="header-title">
              <h4 class="text-primary text-center">
                Service Matrix
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div #pageTop class="w-100 mx-auto" [class.esx-content]="!fromReview">
    <div class="h-100 p-1" [class.mt-2]="!fromReview">

<!--      <h4 class="text-center" *ngIf="fromReview">-->
<!--        <span class="text-capitalize">Service Matrix</span>-->
<!--      </h4>-->
<!--      <hr class="w-75 mx-auto border-primary" *ngIf="fromReview">-->

      <nav class="profile-tabs">

        <button
          class="profile-tab-item width-13-rem"
          [class.active-profile-tab]="currentTab === 'targetPopulations'"
          [class.text-danger]="currentTab !== 'targetPopulations' && (formSubmitted && serviceMatrixForm?.controls?.targetPopulations?.invalid)"
          [class.font-weight-bolder]="currentTab !== 'targetPopulations' && (formSubmitted && serviceMatrixForm?.controls?.targetPopulations?.invalid)"
          (click)="changeTab('targetPopulations')"
        >
          Target Populations
        </button>
        |
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'impactAreas'"
          [class.text-danger]="currentTab !== 'impactAreas' && (formSubmitted && serviceMatrixForm.controls.impactAreaSubCategories?.invalid)"
          [class.font-weight-bolder]="currentTab !== 'impactAreas' && (formSubmitted && serviceMatrixForm.controls.impactAreaSubCategories?.invalid)"
          (click)="changeTab('impactAreas')"
        >
          Impact Areas
        </button>
        |
        <button
          class="profile-tab-item"
          [class.active-profile-tab]="currentTab === 'activities'"
          [class.text-danger]="currentTab !== 'activities' && (formSubmitted && serviceMatrixForm?.controls?.activities?.invalid)"
          [class.font-weight-bolder]="currentTab !== 'activities' && (formSubmitted && serviceMatrixForm?.controls?.activities?.invalid)"
          (click)="changeTab('activities')"
        >
          Activities
        </button>
      </nav>

      <div *ngIf="!fromReview" class="profile-actions float-right">
        <button
          class="btn btn-sm btn-primary btn-outline-dark profile-btn"
          (click)="save()"
        >
          Save
        </button>
        <button
          class="btn btn-sm btn-danger btn-outline-dark profile-btn"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>

      <app-alert
        class="w-100"
        [id]="'service-matrix-form'"
      ></app-alert>
      <app-spinner [loadingObs]="isLoading">

        <div class="wrapper">
          <div class="w-100 mx-auto">
            <div class="h-100">
              <div class="card-body" *ngIf="serviceMatrixForm">
                <form [formGroup]="serviceMatrixForm">

                  <!--                Using [hidden] so that validations are visible without the tab selected -->
                  <div [hidden]="currentTab !== 'targetPopulations'">
                    <h4 class="text-center">Target Populations</h4>
                    <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="w-100 mx-auto">
                          <app-input-checkbox
                            *ngIf="targetPopulationsTypeItems"
                            class="w-100"
                            [label]="'Target Populations'"
                            [choices]="targetPopulationsTypeItems"
                            [id]="'targetPopulations'"
                            [style]="'default'"
                            [theme]="'light'"
                            [cols]="2"
                            [showActiveBorder]="false"
                            [isRequired]="true"
                            [isReadOnly]="isReadOnly"
                            [isGrey]="false"
                            [hasBorder]="false"
                            [formSubmitted]="formSubmitted"
                            formControlName="targetPopulations"
                          ></app-input-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div [hidden]="currentTab !== 'impactAreas'">
                    <h4 class="text-center">Impact Areas</h4>
                    <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
                    <div class="row">
                      <div class="col-5 offset-1">
                        <div *ngIf="impactAreaTypeItems && impactAreaTypeItems.length" class="w-100 mx-auto">
                          <ngx-treeview
                            [config]="config"
                            [items]="impactAreaTypeItems"
                            (selectedChange)="impactAreaChange($event);"
                          ></ngx-treeview>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div [hidden]="currentTab !== 'activities'">
                    <h4 class="text-center">Activities</h4>
                    <hr class="dropdown-divider w-75 mx-auto border-primary mb-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="w-100 mx-auto">
                          <div class="card">
                            <div class="card-header">
                              <div class="row">
                                <div class="col-5 text-center m-auto">
                                  <label>Activity</label>
                                </div>
                                <div class="col-5 text-center m-auto">
                                  <label>Links to Impact Area</label>
                                </div>
                                <div *ngIf="!isReadOnly" class="col-2 text-center m-auto">
                                  <button class="text-center btn btn-success" (click)="addActivity()">
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>


                            <div formArrayName="activities" class="card-body">
                              <ng-container *ngIf="serviceMatrixForm.value.activities?.length > 0;">
                                <ng-container
                                  *ngFor="let activity of serviceMatrixForm.get('activities').controls; let lix = index"
                                  [formGroupName]="lix">
                                  <div class="row">
                                    <div class="col-5">
                                      <app-input-text
                                        class="w-95"
                                        [id]="'activity' + lix"
                                        [isRequired]="true"
                                        [isReadOnly]="isReadOnly"
                                        [isGrey]="false"
                                        [hasBorder]="false"
                                        [formSubmitted]="formSubmitted"
                                        [style]="'default'"
                                        [theme]="'light'"
                                        formControlName="activity"
                                      ></app-input-text>
                                    </div>

                                    <div class="col-5">
                                      <app-input-select-single
                                        *ngIf="impactAreaSubCategorySelectedList?.length"
                                        [items]="impactAreaSubCategorySelectedList"
                                        [id]="'impactAreaSubCategory'"
                                        [theme]="'light'"
                                        [style]="'bordered'"
                                        [formSubmitted]="formSubmitted"
                                        [isRequired]="true"
                                        [clearValueOnItemsChange]="false"
                                        [hasBorder]="false"
                                        [isGrey]="false"
                                        [isReadOnly]="isReadOnly"
                                        [showDisabledValue]="true"
                                        formControlName="impactAreaSubCategory"
                                      ></app-input-select-single>
                                    </div>
                                    <div *ngIf="!isReadOnly" class="col-2 text-center m-auto">
                                      <a
                                        *ngIf="serviceMatrixForm.value.activities?.length > 1"
                                        href="javascript:void(0)"
                                        class="text-danger fa fa-trash fa-1x pb-2"
                                        (click)="removeActivity(lix)"
                                      ></a>
                                    </div>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>


      </app-spinner>
    </div>
  </div>
</div>

<ng-template #saveBeforeLeaving>
  <div class="card card-raised">
    <div class="card-header text-center">
      <h3 class="card-title text-uppercase text-danger font-weight-bold">
        Attention!
      </h3>
    </div>
    <div class="card-body text-center">
      <p class="font-weight-normal">You have not saved your Service Matrix.</p>
      <p class="font-weight-normal">Are you sure you want to leave?</p>
    </div>
    <div class="flex-center">
      <button class="btn btn-outline-success mx-3" (click)="stay()">Stay</button>
      <button class="btn btn-outline-danger mx-3" (click)="leaveApplication()">Leave</button>
    </div>
  </div>
</ng-template>

<ng-template #impactAreaWarning let-modal>
  <div class="card card-raised">
    <div class="card-header text-center">
      <h3 class="card-title text-uppercase text-danger font-weight-bold">
        Attention!
      </h3>
    </div>
    <div class="card-body text-center">
      <p class="font-weight-normal">
        Activities linked to any deselected Impact Areas would need to be re-linked.
      </p>
      <!--      <p class="font-weight-normal">Are you sure you want to continue?</p>-->
    </div>
    <div class="flex-center">
      <button class="btn btn-outline-success mx-3" (click)="modal.close('ok')">Ok</button>
    </div>
  </div>
</ng-template>
