import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss']
})
export class ReviewDetailsComponent implements OnInit {

  @Input() isReadOnly: boolean;
  @Input() workflowId: number;
  @Input() reviewStep: string;

  isEditingSummary: boolean;
  isSavingSummary: boolean;
  currentTab = 'summary';

  constructor() {
  }

  ngOnInit() {
  }

}
