import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {ObjectiveService} from '../store/objective.service';
import {createObjectiveForm, Objective} from '../store/objective.model';
import {AlertService} from '../../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-edit-objective',
  templateUrl: './edit-objective.component.html',
  styleUrls: ['./edit-objective.component.scss']
})
export class EditObjectiveComponent implements OnInit {

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() objective: Objective;

  options: NgbModalOptions;

  profileForm: FormGroup;

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private objectiveService: ObjectiveService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Edit Objective',
      centered: true,
      size: 'lg'
    };

    this.profileForm = createObjectiveForm(this.formBuilder, this.objective);
  }

  openObjectiveModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  updateObjective() {
    if (this.profileForm.valid) {
      this.objectiveService.update(this.profileForm.value).subscribe(() => {
        this.closeModal();
      }, () => {
        this.alertService.setAlert('Failed to update Objective', 'error', 'editObjective');
      });
    } else {
      this.alertService.setAlert('Please complete all fields before saving.', 'error', 'editObjective');
    }
  }
}
