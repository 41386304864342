import { Injectable } from '@angular/core';
import { ReviewModel } from './review.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ReviewState extends EntityState<ReviewModel> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'review' })
export class ReviewStore extends EntityStore<ReviewState> {

  constructor() {
    super();
  }

}

