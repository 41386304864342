import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {FormBuilderChoice} from './form-builder-choice.model';

export interface FormBuilderState extends EntityState<FormBuilderChoice> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'dynamic-form'})
export class FormBuilderChoiceStore extends EntityStore<FormBuilderState> {

  constructor() {
    super();
  }

}

