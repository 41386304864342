import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AnswerListDto} from '../../../../../../../../shared/models/answer-dto.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {InnovationEndpointType} from '../store/cs-innovation.model';

@Injectable({
  providedIn: 'root'
})
export class CsInnovationAnswerService {

  private url = `${environment.apiUrl}/enum/capacity-spectrum/innovation-and-technology`;

  public computerAccessSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $computerAccess = this.computerAccessSource.asObservable();

  public internetAccessSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $internetAccess = this.internetAccessSource.asObservable();

  public internetConnectionSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $internetConnection = this.internetConnectionSource.asObservable();

  public itEquipmentSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $itEquipment = this.itEquipmentSource.asObservable();

  public systemSupportSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $systemSupport = this.systemSupportSource.asObservable();

  public itPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $itPolicy = this.itPolicySource.asObservable();

  public popiPolicySource = new BehaviorSubject<AnswerListDto>(undefined);
  public $popiPolicy = this.popiPolicySource.asObservable();

  public softwareSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $software = this.softwareSource.asObservable();

  public customSoftwareSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $customSoftware = this.customSoftwareSource.asObservable();

  public techTrainingSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $techTraining = this.techTrainingSource.asObservable();

  public managementSkillsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $managementSkills = this.managementSkillsSource.asObservable();

  public adminSkillsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $adminSkills = this.adminSkillsSource.asObservable();

  public generalStaffSkillsSource = new BehaviorSubject<AnswerListDto>(undefined);
  public $generalStaffSkills = this.generalStaffSkillsSource.asObservable();

  constructor(private http: HttpClient) {
  }

  getAnswerLists(): Observable<any> {
    if (!this.computerAccessSource.getValue()) {
      const url = `${this.url}`; // add "?endpointTypes=${types}" for specific types
      return this.http.get<AnswerListDto[]>(url).pipe(tap(list => {
        this.populateAnswers(list);
      }));
    } else {
      return of({});
    }
  }

  private populateAnswers(list: AnswerListDto[]) {
    for (const answerListDTO of list) {
      switch (answerListDTO.enumType) {
        case InnovationEndpointType.COMPUTER_ACCESS: {
          this.computerAccessSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.INTERNET_ACCESS: {
          this.internetAccessSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.INTERNET_CONNECTION: {
          this.internetConnectionSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.IT_EQUIPMENT: {
          this.itEquipmentSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.SYSTEM_SUPPORT: {
          this.systemSupportSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.IT_POLICY: {
          this.itPolicySource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.POPI_POLICY: {
          this.popiPolicySource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.SOFTWARE: {
          this.softwareSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.CUSTOM_SOFTWARE: {
          this.customSoftwareSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.TECH_TRAINING: {
          this.techTrainingSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.MANAGEMENT_SKILLS: {
          this.managementSkillsSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.ADMIN_SKILLS: {
          this.adminSkillsSource.next(answerListDTO);
          break;
        }
        case InnovationEndpointType.GENERAL_STAFF_SKILLS: {
          this.generalStaffSkillsSource.next(answerListDTO);
          break;
        }
      }
    }
  }
}
