<!--// Check both the boolean and observable values before displaying content -->

<ng-container *ngIf="(loadingObs | async); then spinning; else checkLoading"></ng-container>

<ng-template #checkLoading>
  <ng-container *ngIf="loadingBool; then spinning; else content"></ng-container>
</ng-template>

<ng-template #spinning>
  <div class="spinner-box">
    <div class="loader" [ngStyle]="{'width': sizeInRem + 'rem', 'height': sizeInRem + 'rem'}">
      <div class="face">
        <div [ngClass]="sizeInRem < 10 ? 'circle-small' : 'circle-large'"></div>
      </div>
      <div class="face">
        <div [ngClass]="sizeInRem < 10 ? 'circle-small' : 'circle-large'"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
