import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {ModalService} from '../../../../../shared/modules/shared-common/services/modal/modal.service';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {CancelConfirmHelperService} from '../../../../../shared/guards/cancel-confirm/cancel-confirm-helper.service';
import {SessionService} from '../../../../../shared/stores/session/session.service';
import {
  createServiceMatrixActivityForm,
  createServiceMatrixForm,
  ServiceMatrix,
} from '../../store/service-matrix.model';
import {ServiceMatrixService} from '../../store/service-matrix.service';
import {ServiceMatrixQuery} from '../../store/service-matrix.query';
import {AnswerDto} from '../../../../../shared/models/answer-dto.model';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {createOrganisation} from '../../../org-profile/store/organisation.model';

@Component({
  selector: 'app-service-matrix',
  templateUrl: './service-matrix.component.html',
  styleUrls: ['./service-matrix.component.scss']
})
export class ServiceMatrixComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() fromReview: boolean;
  @Input() isReadOnly: boolean;

  @ViewChild('saveBeforeLeaving', {static: true}) saveBeforeLeaving: TemplateRef<HTMLElement>;
  @ViewChild('impactAreaWarning', {static: true}) impactAreaWarning: TemplateRef<HTMLElement>;
  @ViewChild('pageTop', {static: true}) pageTop: ElementRef<HTMLElement>;

  paramsSubscription: Subscription;
  serviceMatrixFormChanges: Subscription;
  currentTab = 'targetPopulations';

  serviceMatrixForm: FormGroup;
  orgId: number;
  formSubmitted: boolean;
  targetPopulationsTypeItems: AnswerDto[];
  impactAreaSubCategoryList: AnswerDto[];
  impactAreaTypeItems: TreeviewItem[];
  impactAreaSubCategorySelectedList: AnswerDto[];

  hasSaved: boolean;
  warningDismissed = false;
  exitUrl: string;
  modalOptions: NgbModalOptions;
  saveModalOptions: NgbModalOptions;

  isLoading: Observable<boolean>;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  });


  constructor(public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              private formBuilder: FormBuilder,
              private loadingService: LoadingService,
              private modalService: ModalService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private serviceMatrixService: ServiceMatrixService,
              private serviceMatrixQuery: ServiceMatrixQuery,
              private cancelConfirmHelper: CancelConfirmHelperService,
              private ref: ChangeDetectorRef,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.cancelConfirmHelper.mustConfirmLogout = true;
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.orgId = params.orgId;
    });

    this.isLoading = this.loadingService.getIsLoading();

    this.hasSaved = true;

    this.fetchTargetPopulations();
    this.fetchServiceMatrix();

    this.modalOptions = {
      size: 'lg',
      centered: true,
      windowClass: 'modalWidth-50',
      keyboard: false,
      backdrop: 'static'
    };

    this.saveModalOptions = {
      centered: true,
    };
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
    // If the user has not saved their orgCapacity, wipe out all validation errors on destroy.

    if (this.serviceMatrixFormChanges) {
      this.serviceMatrixFormChanges.unsubscribe();
    }

    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  // This function is being called from the `CancelConfirm` guard in `shared/guards`.
  openSaveModal() {
    if (!this.warningDismissed) {
      this.modalService.openBasicModal(this.saveBeforeLeaving, this.modalOptions);
    }
  }

  private fetchServiceMatrix() {
    this.loadingService.setIsLoading(true);
    // Fetch capacitySpectrum or create new
    if (this.orgId) {
      this.serviceMatrixService.get(this.orgId).subscribe((result: ServiceMatrix) => {
        if (result) {
          this.serviceMatrixForm = createServiceMatrixForm(this.formBuilder, result);
          this.loadingService.setIsLoading(false);
        } else {
          this.serviceMatrixForm = createServiceMatrixForm(this.formBuilder, {
            organisation: createOrganisation({id: this.orgId})
          });
          this.addActivity();
          this.loadingService.setIsLoading(false);
        }
        this.fetchImpactAreas();
        if (!this.serviceMatrixFormChanges) {
          this.serviceMatrixFormChanges = this.serviceMatrixForm.valueChanges.subscribe(val => {
            // console.log('value', val);
            this.hasSaved = false;
          });
        }
        this.hasSaved = true;
      });
    } else {
      this.loadingService.setIsLoading(false);
      this.alertService.setAlert('Error! No organisation selected', 'error', 'service-matrix-form');
    }
  }

  private fetchImpactAreas() {
    this.serviceMatrixService.getImpactArea().subscribe(list => {
      this.impactAreaSubCategoryList = list.reduce((acc, item) => {
        acc = acc.concat(item.children);
        return acc;
      }, []);
      this.impactAreaTypeItems = this.getTreeViewItems(list);
    });
  }

  private fetchTargetPopulations() {
    this.serviceMatrixService.getPopulationType().subscribe(list => {
      this.targetPopulationsTypeItems = list[0].choices;
    });
  }

  private getTreeViewItems(impactAreas: AnswerDto[]): TreeviewItem[] {
    if (impactAreas && impactAreas.length) {
      return impactAreas.map(item => {
        return new TreeviewItem({
          text: item.label,
          value: item.value,
          checked: this.serviceMatrixForm.controls.impactAreaSubCategories?.value?.includes(item.value),
          disabled: this.isReadOnly,
          children: this.getTreeViewItems(item.children)
        });
      });
    }
    return undefined;
  }

  impactAreaChange(impactAreas: string[]) {
    const list = this.impactAreaSubCategoryList.filter(item => impactAreas.includes(item.value));

    this.serviceMatrixForm.patchValue({
      impactAreaSubCategories: impactAreas
    });

    // for activity dropdown list
    this.impactAreaSubCategorySelectedList = list;
    // this is to detect non-selected tab validations
    this.ref.detectChanges();

    // open warning modal if needed
    const valueList = list.map(item => item.value);

    const activities = this.serviceMatrixForm.get('activities') as FormArray;
    activities.controls.forEach((activity: FormGroup) => {
      const activityValue = activity.controls.impactAreaSubCategory.value;
      if (activityValue && !valueList.includes(activityValue)) {
        const modalRef = this.modalService.openStackedModal(this.impactAreaWarning, this.modalOptions);
        modalRef.result.then(reason => {
          // This runs when the modal is closed with the close() function
          if (reason === 'ok') {
            // console.log('closing modal - ok');
          }
        }).catch(() => {
          // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
          // console.log('closing modal');
        });

        activity.patchValue({
          impactAreaSubCategory: undefined
        });
      }
    });
  }

  save() {
    this.formSubmitted = true;
    if (this.serviceMatrixForm.valid) {
      this.loadingService.setIsLoading(true);
      // create or update.
      this.serviceMatrixForm.value.id ? this.update() : this.create();
    } else {
      this.alertService.setAlert('Please complete all required questions before submitting.', 'error', 'service-matrix-form');
    }
    this.hasSaved = true;
  }

  cancel() {
    this.router.navigateByUrl(`/service-matrix/results/${this.orgId}`).then();
  }

  private create() {
    this.serviceMatrixService.create(this.serviceMatrixForm.value, this.orgId).subscribe((entity: ServiceMatrix) => {
      this.afterSaveActions(entity);
    }, () => {
      this.alertService.setAlert('Failed to save Service Matrix', 'error', 'service-matrix-form');
    });
  }

  private update() {
    this.serviceMatrixService.update(this.serviceMatrixForm.value).subscribe((entity: ServiceMatrix) => {
      this.afterSaveActions(entity);
    }, () => {
      this.alertService.setAlert('Failed to save Service Matrix', 'error', 'service-matrix-form');
    });
  }

  private afterSaveActions(entity: ServiceMatrix) {
    this.serviceMatrixForm.patchValue(entity);
    this.loadingService.setIsLoading(false);

    // update User
    this.sessionService.setServiceMatrixOnUser(entity.id);

    this.warningDismissed = true;
    this.sessionService.routeHome();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  stay() {
    this.cancelConfirmHelper.isLoggingOut = false;
    this.closeModal();
  }

  leaveApplication() {
    this.warningDismissed = true;
    this.closeModal();
    this.router.navigate([this.exitUrl]).then();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  changeTab(tabName: string) {
    this.currentTab = tabName;
    this.pageTop.nativeElement.scrollTo({
      top: 0
    });
  }

  addActivity() {
    const control = this.serviceMatrixForm.controls.activities as FormArray;
    control.push(createServiceMatrixActivityForm(this.formBuilder, {}));
  }

  removeActivity(index: number) {
    const control = this.serviceMatrixForm.controls.activities as FormArray;
    control.removeAt(index);
  }

  // closeImpactAreaModal(reason: string) {
  //   console.log('close function');
  //   this.activeModal.close(reason);
  // }
}
