import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export class DateUtil {
  static convertDateObjectToTimestamp(date: NgbDateStruct, time?: NgbTimeStruct) {
    if (date && (typeof date === 'object')) {
      if (time && (typeof time === 'object')) {
        return new Date(
          date.year,
          date.month - 1,
          date.day,
          time.hour,
          time.minute,
        ).getTime();
      }
      return new Date(date.year, date.month - 1, date.day).getTime();
    }
    return undefined;
  }

  static convertTimestampToDateObject(timestamp: any): NgbDateStruct {
    if (timestamp) {
      const date = new Date(timestamp);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
    return undefined;
  }

  static convertTimestampToTimeObject(timestamp: any): NgbTimeStruct {
    if (timestamp) {
      const date = new Date(timestamp);
      return {
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
      };
    }
    return undefined;
  }

  static convertDateToTimestamp(date: any) {
    return new Date(date).getTime();
  }
}
