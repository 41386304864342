<form [formGroup]="form" class="mx-auto">

  <ng-container [ngSwitch]="question.controlType">

    <app-input-text
      *ngSwitchCase="QuestionControlType.textbox"
      [id]="question.key"
      [label]="question.label"
      [theme]="theme"
      [style]="style"
      [isGrey]="false"
      [hasBorder]="false"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="true"
      [isRequired]="question.required"
      [formControlName]="question.key"
    ></app-input-text>

    <app-input-textarea
      *ngSwitchCase="QuestionControlType.textarea"
      [id]="question.key"
      [label]="question.label"
      [theme]="theme"
      [style]="style"
      [isGrey]="false"
      [hasBorder]="false"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="true"
      [isRequired]="question.required"
      [formControlName]="question.key"
    ></app-input-textarea>

    <app-input-radio
      *ngSwitchCase="QuestionControlType.radio"
      [id]="question.key"
      [name]="question.key"
      [label]="question.label"
      [choices]="question.options"
      [theme]="theme"
      [style]="style"
      [isGrey]="false"
      [hasBorder]="false"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="true"
      [isRequired]="question.required"
      [formControlName]="question.key"
    >
    </app-input-radio>

    <app-input-checkbox
      *ngSwitchCase="QuestionControlType.checkbox"
      [id]="question.key"
      [name]="question.key"
      [label]="question.label"
      [choices]="question.options"
      [theme]="theme"
      [style]="style"
      [isGrey]="false"
      [hasBorder]="false"
      [isReadOnly]="isReadOnly"
      [isRequired]="question.required"
      [formControlName]="question.key"
    ></app-input-checkbox>

    <app-input-number
      *ngSwitchCase="QuestionControlType.number"
      [id]="question.key"
      [label]="question.label"
      [theme]="theme"
      [style]="style"
      [isGrey]="false"
      [hasBorder]="false"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="true"
      [isRequired]="question.required"
      [formControlName]="question.key"
    ></app-input-number>

    <app-input-date
      *ngSwitchCase="QuestionControlType.date"
      [id]="question.key"
      [label]="question.label"
      [theme]="theme"
      [style]="style"
      [isGrey]="false"
      [hasBorder]="false"
      [isReadOnly]="isReadOnly"
      [showDisabledValue]="true"
      [isRequired]="question.required"
      [formControlName]="question.key"
    ></app-input-date>

  </ng-container>

<!--  <div class="errorMessage" *ngIf="!isValid">{{question.label}} is required</div>-->
</form>
