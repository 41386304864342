import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-cs-supporting-documentation',
  templateUrl: './cs-supporting-documentation.component.html',
  styleUrls: ['./cs-supporting-documentation.component.scss']
})
export class CsSupportingDocumentationComponent implements OnInit {

  theme: 'dark' | 'light' = 'light';
  style: 'default' | 'bordered' = 'default';

  @Input() supportingDocumentationForm: FormGroup;
  @Input() orgId: number;
  @Input() workflowId: number;
  @Input() isReadOnly: boolean;
  @Input() formSubmitted: boolean;
  @Input() fromReview: boolean;

  @Output() validate = new EventEmitter();

  formValidSubscription: Subscription;
  formValid: boolean;

  isLoading: Observable<boolean>;

  constructor() { }

  ngOnInit() {
  }

}
