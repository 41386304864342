import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ApplicationConfirmationComponent} from './application-phase/application-confirmation/application-confirmation.component';
import {IneligibleComponent} from './application-phase/ineligible/ineligible.component';
import {ApplicationAgreementComponent} from './application-phase/application-agreement/application-agreement.component';
import {EligibilityComponent} from './application-phase/eligibility/eligibility.component';
// import {ApplicationComponent} from './application-phase/application/application.component';
import {CustomApplicationComponent} from './application-phase/custom-application/custom-application.component';

const routes: Routes = [
  {path: 'application-agreement/:windowId/:orgId', component: ApplicationAgreementComponent},

  {path: ':workflowId/eligibility', component: EligibilityComponent},
  {path: ':workflowId/ineligible', component: IneligibleComponent},

  {path: ':workflowId/application', component: CustomApplicationComponent},
  {path: ':workflowId/application-confirmation', component: ApplicationConfirmationComponent},

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class GrantWorkflowRoutingModule {
}
