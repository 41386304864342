import {FormBuilder, Validators} from '@angular/forms';

export type Roles =
  'ROLE_ADMIN' |
  'ROLE_FUNDER' |
  'ROLE_GRANT_MANAGER' |
  'ROLE_GRANT_COORDINATOR' |
  'ROLE_APPLICANT' |
  'Unauthenticated' |
  'Any';

export interface User {
  id: number;
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  roles: Roles[];
  userStatus: string;
  hostUrl: string;

  // implementer
  organisationId: number;
  isCapacitySpectrumSubmitted: boolean;
  serviceMatrixId: number;

  // investor
  funderId: number;
  isSimSubmitted: boolean;
  numberOfActiveGrants: number;

  isImpersonating: boolean;

  enabled: boolean;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  isSubscribing: boolean;
  recaptcha: any;
}

export function createUser(params?: Partial<User>) {
  return {
    id: params && params.id,
    username: params && params.username,
    email: params && params.email,
    password: params && params.password,
    firstName: params && params.firstName,
    lastName: params && params.lastName,
    isSubscribing: params && params.isSubscribing,
    roles: params && params.roles || [],
    userStatus: params && params.userStatus,
    hostUrl: params && params.hostUrl,
    organisationId: params && params.organisationId,
    isCapacitySpectrumSubmitted: params && params.isCapacitySpectrumSubmitted,
    serviceMatrixId: params && params.serviceMatrixId,
    funderId: params && params.funderId,
    isImpersonating: params && params.isImpersonating,
    enabled: params && params.enabled,
    accountNonExpired: params && params.accountNonExpired,
    accountNonLocked: params && params.accountNonLocked,
    credentialsNonExpired: params && params.credentialsNonExpired,
    recaptcha: params && params.recaptcha
  } as User;
}

export function createLoginForm(formBuilder: FormBuilder, params?: Partial<{ username, password, recaptcha }>) {
  return formBuilder.group({
    username: [params && params.username || '', Validators.required],
    password: [params && params.password || '', Validators.required],
    recaptcha: [params && params.recaptcha || '', Validators.required],
  });
}

export function createRegisterForm(formBuilder: FormBuilder, params?: Partial<User>) {
  return formBuilder.group({
    username: [params && params.username, Validators.required],
    email: [params && params.email],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    isSubscribing: [params && params.isSubscribing || false],
    firstName: [params && params.firstName, Validators.required],
    lastName: [params && params.lastName, Validators.required],
    recaptcha: [params && params.recaptcha || '', Validators.required],
  });
}

export interface ResetUser {
  username: string;
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
  hostUrl: string;
  recaptcha: any;
}

export function createResetUser(params?: Partial<ResetUser>) {
  return {
    username: params && params.username,
    password: params && params.password,
    newPassword: params && params.newPassword,
    newPasswordConfirm: params && params.newPasswordConfirm,
    hostUrl: params && params.hostUrl
  } as ResetUser;
}

export function createResetUserForm(formBuilder: FormBuilder, params?: Partial<ResetUser>) {
  return formBuilder.group({
    username: [params && params.username, Validators.required],
    password: ['', Validators.required],
    newPassword: ['', Validators.required],
    newPasswordConfirm: ['', Validators.required],
    hostUrl: ['']
  });
}

export function createForgotPasswordForm(formBuilder: FormBuilder, params?: Partial<ResetUser>) {
  return formBuilder.group({
    username: [params && params.username, Validators.required],
    recaptcha: [params && params.recaptcha || '', Validators.required],
    hostUrl: ['']
  });
}

export function createForgotPasswordConfirmationForm(formBuilder: FormBuilder, params?: Partial<ResetUser>) {
  return formBuilder.group({
    newPassword: ['', Validators.required],
    newPasswordConfirm: ['', Validators.required],
  });
}


export interface UserDetail {
  username: string;
  firstName: string;
  lastName: string;
  userCreatedOn: number;

  // implementer
  confirmedRegistration: boolean;
  organisationId: number;
  organisationCreatedOn: number;
  organisationName: string;
  organisationDistrict: string;
  capacitySpectrumId: number;
  isCapacitySpectrumSubmitted: boolean;
  serviceMatrixId: number;
}

export interface UserManagement {
  username: string;
  firstName: string;
  lastName: string;

  // investor
  funderId: number;
  legalName: string;
  userCreatedOn: number;
  simId: number;
  isSimSubmitted: boolean;
  funderCreatedOn: number;
}
