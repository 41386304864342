<!--<section class=" col-lg-8 col-sm-12 mx-auto">-->
  <div class="wrapper esx-page">

    <div class="esx-header">
      <div class="flex-center h-100">
        <div class="card header-card">
          <div class="card-body p-0">
            <div class="header">
              <div class="header-title">
                <h4 class="text-primary text-center">
                  Ineligible
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-95 mx-auto">
      <div class="card mt-4">
        <div class="heading-container">
          <h4 class="text-center card-title mt-3">Eligibility Results</h4>
        </div>
        <p>Thank you for your interest in the HIV and Community Grants Program.</p>
        <ng-container *ngIf="ineligibleReasons">
          <p>Unfortunately, your application has not met the following requirements:</p>
          <ul>
            <ng-container *ngFor="let reason of ineligibleReasons">
              <li>{{ reason }}</li>
            </ng-container>
          </ul>
        </ng-container>

        <app-alert
          class="w-100"
          [id]="'ineligible'"
        ></app-alert>

        <button *ngIf="isAdmin" class="btn btn-primary float-right mb-1" (click)="reset()">
          Reset to Eligibility
        </button>
      </div>
    </div>
  </div>
<!--</section>-->
