import {Injectable} from '@angular/core';
import {ActivityStore} from './activity.store';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../../../../../environments/environment';
import {WorkPlanAndTargetsService} from '../../../../../../../store/work-plan-and-targets.service';
import {Activity} from './activity.model';

@Injectable({providedIn: 'root'})
export class ActivityService {

  private url = `${environment.apiUrl}/activity`;

  constructor(protected store: ActivityStore,
              private http: HttpClient,
              private workPlanAndTargetsService: WorkPlanAndTargetsService) {
  }

  create(activity: Activity) {
    return this.http.post(this.url, activity).pipe(
      tap(response => {
        this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(activity.objective.workPlan.workflow.id).subscribe();
      })
    );
  }

  update(activity: Activity) {
    return this.http.put(`${this.url}/${activity.id}`, activity).pipe(
      tap(() => {
        this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(activity.objective.workPlan.workflow.id).subscribe();
      })
    );
  }

  delete(activity: Activity) {
    return this.http.delete(`${this.url}/${activity.id}`).pipe(
      tap(() => {
        this.workPlanAndTargetsService.fetchWorkPlansByWorkflowId(activity.objective.workPlan.workflow.id).subscribe();
      })
    );
  }
}
