import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SessionQuery} from '../../../../../shared/stores/session/session.query';
import {SessionService} from '../../../../../shared/stores/session/session.service';
import {AlertService} from '../../../../../shared/modules/alert/services/alert.service';
import {LoadingService} from '../../../../../shared/modules/shared-common/services/loading/loading.service';
import {ServiceMatrix} from '../../store/service-matrix.model';
import {ServiceMatrixService} from '../../store/service-matrix.service';
import { AnswerDto } from 'app/shared/models/answer-dto.model';

@Component({
  selector: 'app-service-matrix-results',
  templateUrl: './service-matrix-results.component.html',
  styleUrls: ['./service-matrix-results.component.scss']
})
export class ServiceMatrixResultsComponent implements OnInit {

  isLoading: Observable<boolean>;
  organisationId: number;
  serviceMatrix: ServiceMatrix;
  targetPopulationsTypeItems: AnswerDto[];
  impactAreaSubCategoryList: AnswerDto[];
  currentTab = 'targetPopulations';

  constructor(private route: ActivatedRoute,
              private sessionQuery: SessionQuery,
              private sessionService: SessionService,
              private alertService: AlertService,
              private loadingService: LoadingService,
              private serviceMatrixService: ServiceMatrixService) {
  }

  ngOnInit(): void {
    this.isLoading = this.loadingService.getIsLoading();
    const orgId = this.route.snapshot.params.orgId;
    this.organisationId = orgId || this.sessionQuery.getUser().organisationId;

    this.fetchServiceMatrix();
    this.fetchTargetPopulations();
    this.fetchImpactAreas();
  }

  private fetchServiceMatrix() {
    this.loadingService.setIsLoading(true);
    if (this.organisationId) {
      this.serviceMatrixService.get(this.organisationId).subscribe((result: ServiceMatrix) => {
        if (result) {
          this.serviceMatrix = result;
          this.loadingService.setIsLoading(false);
        } else {
          this.alertService.setAlert('Failed to fetch Service Matrix Results', 'error', 'service-matrix');
          this.loadingService.setIsLoading(false);
        }
      });
    } else {
      this.alertService.setAlert('Failed to fetch Capacity Spectrum Results, no profile found', 'error', 'service-matrix');
      this.loadingService.setIsLoading(false);
    }
  }

  private fetchTargetPopulations() {
    this.serviceMatrixService.getPopulationType().subscribe(list => {
      this.targetPopulationsTypeItems = list[0].choices;
    });
  }

  private fetchImpactAreas() {
    this.serviceMatrixService.getImpactArea().subscribe(list => {
      this.impactAreaSubCategoryList = list.reduce((acc, item) => {
        const children = item.children.map(child => {
          child.label = `<Strong>${item.label}</Strong>: ${child.label}`;
          return child;
        });
        acc = acc.concat(children);
        return acc;
      }, []);
    });
  }

  routeHome() {
    this.sessionService.routeHome();
  }

  changeTab(tabName: string) {
    this.currentTab = tabName;
    // this.pageTop.nativeElement.scrollTo({
    //   top: 0
    // });
  }
}
