import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PieChartComponent} from './pie-chart/pie-chart.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {CardsChartComponent} from './cards-chart/cards-chart.component';
import {SimplePieChartComponent} from './simple-pie-chart/simple-pie-chart.component';
import {GroupedVerticalBarChartComponent} from './grouped-vertical-bar-chart/grouped-vertical-bar-chart.component';


@NgModule({
  declarations: [PieChartComponent, CardsChartComponent, SimplePieChartComponent, GroupedVerticalBarChartComponent],
  exports: [PieChartComponent, CardsChartComponent, SimplePieChartComponent, GroupedVerticalBarChartComponent],
  imports: [
    CommonModule,
    NgxChartsModule
  ],
})
export class ChartsModule {
}
