import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionQuery} from '../../stores/session/session.query';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private sessionQuery: SessionQuery,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.sessionQuery.getUser()) {
      this.router.navigateByUrl('/authentication/login');
    }
    return this.sessionQuery.hasRole(['ROLE_ADMIN']);
  }
}
