<div class="bg-blue-50 border border-secondary p-3">
  <p class="fundRequestIntro">
    Targeted prevention/education programs are defined as <b><u>evidence based</u></b> prevention
    programs that focus on preventing HIV, increasing HIV knowledge and/or changing
    negative behaviors related to HIV, such as stigma/discrimination and gender norms.
    (Evidence based means that the program uses a specific curriculum, implementation is
    monitored and can be measured and the program has been proven to yield positive
    results.)
  </p>
  <form [formGroup]="targetedPreventionForm">
    <fieldset [disabled]="isReadOnly">

      <app-input-checkbox
        [choices]="programsOptions"
        [label]="'What program(s) will you be implementing?'"
        [id]="'tp-programs'"
        [name]="'tp-programs'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="programs"
        (selected)="checkOther('other', $event, targetedPreventionForm.controls.programs, targetedPreventionForm.controls.programsOther)"
      ></app-input-checkbox>

      <div *ngIf="targetedPreventionForm.value.programs?.includes('other')" class="ml-5">
        <app-input-textarea
          [isReadOnly]="isReadOnly"
          [label]="'If other, please specify:'"
          [id]="'tp-programsOther'"
          [theme]="theme"
          [style]="style"
          [showDisabledValue]="isReadOnly"
          [hasBorder]="false"
          [isGrey]="false"
          [maxLength]="1000"
          [isRequired]="true"
          formControlName="programsOther"
        ></app-input-textarea>
      </div>

      <app-input-checkbox
        [choices]="peopleTargetedOptions"
        [label]="'Who will be reached with this targeted prevention or education program?'"
        [id]="'tp-peopleReached'"
        [name]="'tp-peopleReached'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="peopleReached"
      ></app-input-checkbox>

      <app-input-number
        [label]="'How many people do you plan to reach with the program, in total?'"
        [id]="'tp-numberOfPeople'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeople"
      ></app-input-number>

      <app-input-number
        [label]="'For implementation, how will you divide the total number, meaning many people will be in each implementation group?'"
        [id]="'tp-numberOfPeoplePerGroup'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfPeoplePerGroup"
      ></app-input-number>

      <app-input-number
        [label]="'For a person to complete the program, how many sessions does that person need to attend? (i.e. How many sessions does it take to implement the full program?)'"
        [id]="'tp-numberOfSessionsPerProgram'"
        [isRequired]="true"
        [theme]="theme"
        [style]="style"
        [showDisabledValue]="isReadOnly"
        [hasBorder]="false"
        [isGrey]="false"
        [isReadOnly]="isReadOnly"
        formControlName="numberOfSessionsPerProgram"
      ></app-input-number>

      <div class="row">
        <div class="col-6">
          <app-input-number
            [label]="'How long will the training take to complete?'"
            [id]="'tp-durationOfTargetedPrevention'"
            [isRequired]="true"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfTargetedPrevention"
          ></app-input-number>
        </div>
        <div class="col-6">
          <app-input-radio
            [choices]="durationOfTypeOptions"
            [id]="'tp-durationOfTargetedPreventionType'"
            [isRequired]="true"
            [name]="'tp-durationOfTargetedPreventionType'"
            [theme]="theme"
            [style]="style"
            [showDisabledValue]="isReadOnly"
            [hasBorder]="false"
            [isGrey]="false"
            [isReadOnly]="isReadOnly"
            formControlName="durationOfTargetedPreventionType"
          ></app-input-radio>
        </div>
      </div>


      <div>
        <p>How much funding do you need to provide these prevention activities?</p>
        <app-funding-request-budget
          [formValid]="formValid"
          [fromReview]="fromReview"
          [isReadOnly]="isReadOnly"
          [budgetRequestForm]="targetedPreventionForm.controls.budget | asFormGroup"></app-funding-request-budget>
      </div>
    </fieldset>
  </form>
</div>
