import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {GrantWorkflow} from '../../grant-workflow/store/grant-workflow.model';
import {WorkPlan} from './work-plan-and-targets.model';
import {Objective} from '../components/work-plan-and-targets/work-plan/funding-request/objective/store/objective.model';

export interface WorkPlanAndTargetsState {
  workflow: GrantWorkflow;
  workPlans: WorkPlan[];
  selectedWorkPlan: WorkPlan;
  selectedObjective: Objective;
}

export function createInitialState(): WorkPlanAndTargetsState {
  return {
    workflow: undefined,
    workPlans: [],
    selectedWorkPlan: undefined,
    selectedObjective: undefined
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'workPlanAndTargets',
})
export class WorkPlanAndTargetsStore extends Store<WorkPlanAndTargetsState> {

  constructor() {
    super(createInitialState());
  }
}
