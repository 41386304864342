import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {ModalService} from '../../../../../../../../../shared/modules/shared-common/services/modal/modal.service';
import {Activity, createActivityForm} from '../store/activity.model';
import {ActivityService} from '../store/activity.service';
import {budgetCategoryAnswers, paymentEvidenceAnswers} from '../activity.const';
import {FormValidationService} from '../../../../../../../../../services/form-validation/form-validation.service';
import {AlertService} from '../../../../../../../../../shared/modules/alert/services/alert.service';

@Component({
  selector: 'app-edit-activity',
  templateUrl: './edit-activity.component.html',
  styleUrls: ['./edit-activity.component.scss']
})
export class EditActivityComponent implements OnInit {
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<HTMLElement>;

  @Input() activity: Activity;
  @Input() isReadOnly: boolean;
  @Input() coordinatorReadOnly: boolean;
  @Input() applicantReadOnly: boolean;

  options: NgbModalOptions;

  profileForm: FormGroup;

  paymentEvidenceOptions = _.cloneDeep(paymentEvidenceAnswers);
  budgetCategoryOptions = _.cloneDeep(budgetCategoryAnswers);

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private activityService: ActivityService,
              private alertService: AlertService,
              public formValidationService: FormValidationService) {
  }

  ngOnInit() {
    this.options = {
      ariaLabelledBy: 'Edit Activity',
      centered: true,
      size: 'lg',
      scrollable: true
    };

    this.profileForm = createActivityForm(this.formBuilder, this.activity);
  }

  openActivityModal() {
    this.modalService.openBasicModal(this.modalContent, this.options);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  updateActivity() {
    if (this.profileForm.valid) {
      this.activityService.update(this.profileForm.value).subscribe(() => {
        this.closeModal();
      }, error => {
        this.alertService.setAlert('Edit Activity Failed', 'error', 'editActivity');
      });
    } else {
      this.alertService.setAlert('Please complete all fields before saving.', 'error', 'editActivity');
    }
  }
}
